import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

export const useLogin = () => {
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);

  const { dispatch } = useAuthContext();
  const api = useAPIwithCookies();

  const login = async (formData) => {
    setIsPending(true);

    try {
      const response = await api.post('/member/login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const user = {
        email: response.data.email,
        access: response.data.access_token,
      };

      localStorage.setItem('access', user.access);
      localStorage.setItem('email', user.email);

      dispatch({ type: 'login', payload: user });
      setIsPending(false);
      if (user) {
        return true;
      }
    } catch (errormsg) {
      setError(JSON.stringify(errormsg));
      setIsPending(false);
      return false;
    }
  };
  return { error, isPending, login };
};
