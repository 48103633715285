import React, { ReactNode, createContext, useEffect, useState } from 'react';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

import { TrainerControl } from '@/components/rete-mlops/components';
import { getSelectedPath } from './trainUtils';

import { useAuthContext } from '@/hooks/common/useAuthContext';
import { TrainSessionItem } from '../routes/mlops/train-detail/TrainDetail';
import { FineTunerControl } from '@/components/rete-mlops/components';

export const TrainContext = createContext<{
  // trainer 나 Finetuner 노드의 컨트롤을 저장 (옵션 때문)
  trainerControl?: TrainerControl | FineTunerControl;
  setTrainerControl: (option: TrainerControl | FineTunerControl) => void;

  // training 중인지 상태 저장
  isTraining: boolean;
  setIsTraining: (val: boolean) => void;

  // train data 저장
  trainData?:
    | {
        convertResult: { code: string; path: string };
        trainProgress: any;
        trainResultData: any;
        trainMetricList: any;
      }
    | Record<string, never>;

  // api 호출하는 함수들
  functions: {
    getCode?: any;
    postModelFlow?: any;
    startTrain?: any;
    getTrainInfo?: any;
    getSharedModel?: any;
    getSharedDataset?: any;
    resetTrainData?: any;
  };

  // train session step 보기
  stepIndex?: any;
  setStepIndex?: any;
  imgIndex?: any;
  setImgIndex?: any;

  // sharedDataset select 박스 미리 불러오기
  sharedDataset?: any;

  // train detail
  openDetail?: any;
  setOpenDetail?: any;

  // train detail에서 선택된 train session
  selected?: TrainSessionItem;
  setSelected?: any;
}>({
  setTrainerControl: () => {
    //
  },
  isTraining: false,
  setIsTraining: () => {
    //
  },
  functions: {},
});

export function TrainContextProvider({ children }: { children: ReactNode }) {
  const api = useAPIwithCookies();
  const { user } = useAuthContext();

  // ---- trainer 관련 시작 ----
  const [isTraining, setIsTraining] = useState(false);
  const [trainerControl, setTrainerControl] = useState<TrainerControl | FineTunerControl>();
  const [convertResult, setConvertResult] = useState({ code: '', path: '' });
  const [trainProgress, setTrainProgress] = useState(0);
  const [trainResultData, setTrainResultData] = useState<any[]>([]);
  const [trainMetricList, setTrainMetricList] = useState<string[]>(['']);

  let trainData:
    | {
        convertResult;
        trainProgress;
        trainResultData;
        trainMetricList;
      }
    | Record<string, never> = {
    convertResult,
    trainProgress,
    trainResultData,
    trainMetricList,
  };

  // utils
  const isInvalidOption = () => {
    return (
      !trainerControl?.props.option.modelName ||
      trainerControl?.props.option.modelName.length < 1 ||
      !trainerControl?.props.option.newWeightName ||
      trainerControl?.props.option.newWeightName.length < 1
    );
  };

  // Trainer Data 설정
  const getReqBody = () => {
    const option = trainerControl?.props.option ?? { nodeId: '' };
    const userEmail = user.email;
    const savedPath = `/cloud/member/${userEmail}/MLOps/model/${option.modelName}.py`;
    const reqBody = {
      key: option.newWeightName,
      model_path: savedPath,
      weight_name: option.newWeightName,
      dataset_path: getSelectedPath(option.selectedDataset, sharedDataset),
      options: {
        trainer_type: option.trainer_type,
        epoch: option.selectedEpoch,
        learning_rate: option.selectedLearningRate,
        batch_size: option.selectedBatchSize,
        loss_function: option.selectedLossFunction,
        optimizer: option.selectedOptimizer,
      },
    };
    return reqBody;
  };

  // fineTuner Data 설정
  const getTuningReqBody = () => {
    const option = trainerControl?.props.option ?? { nodeId: '' };
    const userEmail = user.email;
    const reqBody = {
      key: option.newWeightName,
      weight_name: option.newWeightName,
      node_id: option.nodeId,
      model_path: `/cloud/member/${userEmail}/MLOps/model/${option.modelName}.py`,
      base_weight: option.selectedTrainedWeight,
      dataset_path: `/cloud/shared/dataset/${option.selectedDataset}`,
      options: {
        trainer_type: option.tunerType,
      },
    };
    return reqBody;
  };

  const getSharedModel = async () => {
    const { data } = await api.get(`/mlops/shared_model_list`);
    return data;
  };

  const getSharedDataset = async () => {
    const { data } = await api.get(`/mlops/shared_dataset_list`);
    return data;
  };

  // post and convert
  const postModelFlow = async () => {
    if (isInvalidOption()) {
      alert('The model Name & new Weight Name sholud be more than 1 character');
      return;
    }

    const { data } = await api
      .post(
        `/mlops/model_flow/${trainerControl?.props.option.modelName}`,
        trainerControl?.modelFlow,
      )
      .catch(() => {
        alert(`fail to get Data from 'mlops/model_flow/${trainerControl?.props.option.modelName}'`);
      });

    return setConvertResult(data);
  };

  // getCode (데이터구조를 요청에 담아 전송, pytorch 코드로 변환하여 반환)
  const getCode = async () => {
    const { data } = await api.get(`/mlops/model_code/${getReqBody().model_path}`);
    return data;
  };

  // 학습 시작하기
  const startTrain = async () => {
    if (isInvalidOption()) return;

    const trainingData = '';
    if (trainerControl instanceof TrainerControl) {
      await postModelFlow();
      const { data } = await api.post(`/mlops/train_model/`, getReqBody());
      trainData = data;
    } else {
      const { data } = await api.post('/mlops_finetuning/train_model/', getTuningReqBody());
      trainData = data;
      getTrainInfo(trainerControl?.props.option.newWeightName);
      return;
    }

    if (trainingData === 'already in process') {
      alert(`${trainerControl?.props.option.newWeightName} is already in process`);
    }

    getTrainInfo(trainerControl?.props.option.newWeightName);
  };

  // 학습 진행정도 및 결과 받아오기
  const getTrainInfo = async (key, reqBody = getReqBody()) => {
    setIsTraining(true);

    const data = await api
      .get(`/mlops/process_stat/${key}`, reqBody)
      .then(({ data }) => {
        if (data === `process ${key} is currently not processed`) {
          alert(data);
          setIsTraining(false);
        }

        if (data.progress === 100) {
          alert(key + ': Train Completed');
          setIsTraining(false);
        }

        setTrainMetricList(data.metric_list);
        setTrainProgress(data.progress);
        setTrainResultData(data);
        return data;
      })
      .catch((err) => {
        alert('fail to get process result from the server');
        setIsTraining(false);
        console.error(err);
        return;
      });

    return data;
  };

  const [stepIndex, setStepIndex] = useState(1);
  const [imgIndex, setImgIndex] = useState(1);

  const resetTrainData = () => {
    setConvertResult({ code: '', path: '' });
    setTrainProgress(0);
    setTrainMetricList(['']);
    setTrainResultData([]);
    setIsTraining(false);
    trainData = {};
  };

  const functions = {
    getCode,
    postModelFlow,
    startTrain,
    getTrainInfo,
    getSharedModel,
    getSharedDataset,
    resetTrainData,
    getTuningReqBody,
  };
  // ---- trainer 관련 끝 ----

  // sample 페이지 여부 확인
  const pathname = window.location.href;
  const isSample = pathname.includes('sample');

  // trainerNode의 seletbox 중 하나인 sharedDataset 불러오기 관련
  const [sharedDataset, setSharedDataset] = useState();

  const initSetting = async () => {
    const data = await getSharedDataset();
    setSharedDataset(data);
  };

  useEffect(() => {
    if (!isSample) {
      initSetting();
    }
  }, []);

  // trainDetail 페이지
  const [selected, setSelected] = useState<TrainSessionItem>({});

  // train detail 페이지에서 이미지 모달 여부
  const [openDetail, setOpenDetail] = useState<{
    label: string;
    data: string;
  } | null>(null);

  return (
    <TrainContext.Provider
      value={{
        functions,
        trainData,
        trainerControl,
        setTrainerControl,
        isTraining,
        setIsTraining,
        stepIndex,
        setStepIndex,
        imgIndex,
        setImgIndex,
        sharedDataset,
        openDetail,
        setOpenDetail,
        selected,
        setSelected,
      }}
    >
      {children}
    </TrainContext.Provider>
  );
}
