import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LoginProvider } from './utils/Context';
import HttpsRedirect from 'react-https-redirect';

// 모킹 실행1
async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return
  }
 
  const { worker } = await import('./mocks/browser')
  return worker.start()
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// 모킹 실행2
enableMocking().then(()=> {
  root.render(
    //<React.StrictMode>
    <HttpsRedirect>
      <LoginProvider>
        <App />
      </LoginProvider>
    </HttpsRedirect>  
    //</React.StrictMode>
  );
})

reportWebVitals();
