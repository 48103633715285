import React from 'react';

import DesktopDataHub from './desktop/DesktopDataHub';
import MobileDataHub from './mobile/MobileDataHub';

function DataHub(props: any) {
  return (
    <>
      {/* 데스크탑용 DataHub */}
      <div className='hidden md:block'>
        <DesktopDataHub {...props} />
      </div>

      {/* 모바일용 DataHub */}
      <div className='block md:hidden'>
        <MobileDataHub {...props} />
      </div>
    </>
  );
}

export default DataHub;
