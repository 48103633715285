import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import DataHubBreadCrumb from '@/components/datahub/datahub-body/datahub-breadcrumb';
import DataHubItem from '@/components/datahub/datahub-body/datahub-item';
import EmptyDirImg from '@/components/common/images/EmptyDirImg';
import DataHubDrop from '@/components/datahub/provider/DataHubDrop';
import DataHubMenu from '@/components/datahub/provider/DataHubMenu';

import { useFileFilter } from '@/hooks/dataHub/useFileFilter';
import { useResizeObserver } from '@/hooks/dataHub/useResizeObserver';
import { useSyncPath } from '@/hooks/dataHub/useSyncPath';
import { useSyncSelectedPanel } from '@/hooks/dataHub/useSyncSelectedPanel';

import { DEFAULT_DIR_INFO } from '@/constants/datahub/constant';
import { DataHubBodyProps } from '@/types/datahub/type';
import { IfileNdirList } from '@/types/common/type';

import useDataHubStore from '@/stores/datahub/useDataHubStore';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { uploadChunkFile } from '@/utils/datahub/fileUtils';
import { dropFiles } from '@/apis/datahub/fileApis';

import Loading from '@/components/common/loadings/Loading';

export default function DataHubBody({
  selectedInPanel,
  setSelectedInPanel,
  setStep,
}: DataHubBodyProps) {
  const location = useLocation();
  const {
    dirLoading,
    curDir,
    fileNdirList,
    setEndIndex,
    selected,
    setSelected,
    getDirectory,
    dragTag,
    setLoading,
    setProgress,
  } = useDataHubStore();

  const { keyword, setKeyword, filteredFileNdirList } = useFileFilter(fileNdirList, curDir);

  const elementRef = useRef(null);
  const style = useResizeObserver(elementRef);

  useSyncPath(location);
  useSyncSelectedPanel({
    selectedInPanel,
    setSelectedInPanel,
    fileNdirList,
    selected,
  });

  const renderFileItems = (
    items: IfileNdirList[],
    keyword: string,
    setStep?: (step: number) => void,
  ) => {
    if (!items.length) return <EmptyDirImg label='The Directory is empty' />;

    return items.map((data: IfileNdirList, index: number) => (
      <DataHubItem key={index} keyword={keyword} index={index} setStep={setStep} info={data} />
    ));
  };

  const api = useAPIwithCookies();
  const handleDropFiles = async (files: File[]) => {
    await dropFiles(files, {
      setLoading,
      setProgress,
      uploadChunkFile,
      api,
      curDir,
    });
    getDirectory(curDir, api);
  };

  const { pathname } = useLocation();

  return (
    <DataHubDrop handleDropFiles={handleDropFiles} dragTag={dragTag} pathname={pathname}>
      <div
        className='relative h-[calc(100vh-72px)] w-full bg-white100 dark:bg-black200'
        onClick={() => setSelected([])}
      >
        {dirLoading && <Loading label='loading...' />}

        <div className='relative h-[calc(100vh-72px)] pb-[var(--120px)]'>
          {location.pathname.includes('datahub') && (
            <DataHubBreadCrumb baseDir={''} setKeyword={setKeyword} />
          )}
          <DataHubMenu>
            <div
              id='storage-card-frame' // CSS의 id가 아닌 DataHubMenu의 containerId를 의미
              className='
                mt-[20px] grid grid-cols-2 gap-4
                overflow-auto pb-20
                md:mt-[var(--40px)] md:grid-cols-[repeat(auto-fill,minmax(18rem,1fr))]'
              ref={elementRef}
              style={style}
              onClick={() => {
                setEndIndex(undefined);
              }}
            >
              {curDir !== '' && (
                <DataHubItem keyword={keyword} index={-1} info={DEFAULT_DIR_INFO} />
              )}
              {keyword.length ? (
                renderFileItems(filteredFileNdirList, keyword, setStep)
              ) : fileNdirList.length ? (
                renderFileItems(fileNdirList, keyword, setStep)
              ) : (
                <EmptyDirImg label='The Directory is empty' />
              )}
            </div>
          </DataHubMenu>
        </div>
      </div>
    </DataHubDrop>
  );
}
