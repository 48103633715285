import { ClassicPreset } from 'rete';

export class CustomSocket extends ClassicPreset.Socket {
  constructor() {
    super('Custom');
  }

  isCompatibleWith(socket: ClassicPreset.Socket) {
    return socket instanceof CustomSocket;
  }
}

export class AddonSocket extends ClassicPreset.Socket {
  constructor() {
    super('Addon');
  }

  isCompatibleWith(socket: ClassicPreset.Socket) {
    return socket instanceof AddonSocket;
  }
}

/*
export class AddonSocket2 extends ClassicPreset.Socket {
  constructor() {
    super('Addon2');
  }

  isCompatibleWith(socket: ClassicPreset.Socket) {
    return socket instanceof AddonSocket2;
  }
}

export class AddonSocket3 extends ClassicPreset.Socket {
  constructor() {
    super('Addon3');
  }

  isCompatibleWith(socket: ClassicPreset.Socket) {
    return socket instanceof AddonSocket3;
  }
}

export class AddonSocket4 extends ClassicPreset.Socket {
  constructor() {
    super('Addon4');
  }

  isCompatibleWith(socket: ClassicPreset.Socket) {
    return socket instanceof AddonSocket4;
  }
}
*/
