import PrivacyPolicy from "@/routes/usermanage/PrivacyPolicy";
import React, {useEffect} from "react";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    const current = document.querySelector(`body`);
    current?.classList.add('overflow-auto');
    return () => {
      current?.classList.remove('overflow-auto');
    }
  }, []);

  return (
    <div style={{maxWidth:"1360px", margin:"auto", paddingLeft:'50px', paddingRight:'50px', color:'white'}}>
      <h1 style={{ textAlign: 'center', fontSize: '28px', fontWeight: '800' }}>
        Privacy Policy (개인정보처리방침)
      </h1>
      <div>
        <PrivacyPolicy />
      </div>
    </div>

  );
};

export default PrivacyPolicyPage;