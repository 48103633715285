import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';

// 선택한 NodeId 관리 Context

export const NodeContext = createContext<{
  nodeId?: string;
  setNodeId?: (id: string) => void;
  dynamic?: boolean;
  setDynamic?: Dispatch<SetStateAction<boolean>>;
  expandDetection?: boolean;
  setExpandDetection?: Dispatch<SetStateAction<boolean>>;
  isFocused?: boolean;
  setIsFocused?: any;
}>({
  dynamic: true,
  expandDetection: false,
});

export function NodeProvider({ children }: { children: ReactNode }) {
  const [nodeId, setId] = useState<string>('');
  const [expandDetection, setExpandDetection] = useState<boolean>(false);
  const setNodeId = (id: string): void => {
    setId(id);
  };

  const [dynamic, setDynamic] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const goback = () => {
    window.history.back();
  };
  const goforward = () => {
    window.history.forward();
  };

  useEffect(() => {
    localStorage.setItem('dynamic', dynamic.toString());
    if (isReady) {
      goback();
      setTimeout(() => {
        goforward();
      }, 100);
    }
  }, [dynamic]);

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <NodeContext.Provider
      value={{
        nodeId,
        setNodeId,
        expandDetection,
        setExpandDetection,
        dynamic,
        setDynamic,
        isFocused,
        setIsFocused,
      }}
    >
      {children}
    </NodeContext.Provider>
  );
}
