import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import API from '@/apis/common/apis';
import { ClassicPreset } from 'rete';
import { editor } from '../../../rete';
import { IDataflow } from '../flow';
import { AddonSocket, CustomSocket } from '../../sockets';
// import { updateControl } from '../../../reteUtils';
import { GearNode, TGearProps } from '../addon/tmp';

import { NodeSize } from '../../style/CustomNode';
import { SinkColor } from '../../style/CustomNode';
import CustomControl from '../../style/CustomControl';

export class ViewerNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket; addon: ClassicPreset.Socket },
  Record<string, never>,
  { ctrl: ViewerControl }
> {
  color = SinkColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: string) {
    super('Viewer');
    this.addInput('addon', new ClassicPreset.Input(new AddonSocket()));
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addControl('ctrl', new ViewerControl(process, nodeId ? nodeId : this.id));
  }

  data(inputs: { in: IDataflow[]; addon: IAddonflow[] }) {
    let is_change_node = false;
    let is_change_addon = false;

    if (inputs['in']) {
      const value = inputs['in'][0];
      is_change_node = JSON.stringify(this.controls.ctrl.props.option) !== JSON.stringify(value);
    } else {
      this.controls.ctrl.props.option = undefined;
    }

    if (inputs['addon']) {
      is_change_addon =
        JSON.stringify(this.controls.ctrl.addon) !== JSON.stringify(inputs['addon'][0]);
    }

    if (is_change_node) {
      this.controls.ctrl.setValue(inputs['in'][0]);
    }
    if (is_change_addon) {
      this.controls.ctrl.setAddon(inputs['addon'][0]);
    }

    return {};
  }
}

interface IAddonflow {
  name: string;
  option: any;
  enable: boolean;
}

export class ViewerControl extends ClassicPreset.Control {
  props: {
    option: IDataflow | undefined;
    [key: string]: any;
  };
  addon: IAddonflow;
  contextOpen: boolean;
  // status => 백그라운드에서 처리 상태
  // init: 처음 생성 / in-progress: 처리 중 / completed: 처리 완료 / error: 에러 발생
  status: string;

  constructor(
    public onChange: () => void,
    public nodeId: string,
  ) {
    super();
    this.props = {
      option: undefined,
    };
    this.addon = {
      name: '',
      option: {},
      enable: false,
    };
    this.contextOpen = false;
    this.status = 'init';
  }

  setValue(val: IDataflow) {
    this.props.option = val;
    this.onChange();
  }
  setAddon(val: IAddonflow) {
    this.addon = val;
  }
  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };
  setStatus = (status: string) => {
    this.status = status;
  };
}

export function ViewerComp({ data }: { data: ViewerControl }) {
  const [cookies] = useCookies(['refresh']);
  const api = new API(cookies);
  const params = useParams();

  return (
    <CustomControl
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
      nodeId={data.nodeId}
      label='Viewer'
      iconSource='viewer'
      markerSource='node-sink'
      status={data.status}
      setStatus={data.setStatus}
    />
  );
}
