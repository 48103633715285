import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { CustomThemeContext } from '@/contexts/common/Context';
import { isInt } from '../../../PanelOptionComponent/inputUtils';

const Container = styled.div<{ $theme: 'light' | 'dark' }>`
  width: 100%;
  padding: var(--24px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--12px);

  input {
    padding: var(--12px);
    margin-bottom: var(--12px);
  }

  div.slider-bar {
    width: 100%;
    height: var(--8px);
    border-radius: var(--4px);
    background-color: ${({ $theme }) =>
      $theme === 'dark' ? '#3a3a5b' : '#c4c4d2'};
    position: relative;

    button.slider-button {
      cursor: grab;

      width: var(--24px);
      aspect-ratio: 1/1;
      background-color: #b472f7;
      border-radius: 100%;

      position: absolute;
      top: calc(-1 * var(--24px) / 2 + var(--8px) / 2);
      left: calc(-1 * var(--24px) / 2);

      &.dragging {
        transform: scale(200%);
        background-color: blue;
      }

      &:active {
        cursor: grabbing;
        transform: scale(200%);
        background-color: blue;
      }
    }
  }
`;
type TrainResultImageSliderData = {
  imgNum: any;
  stepIndex: any;
  setStepIndex: any;
  label?: string;
};

export default function TrainResultStepSlider({
  imgNum,
  stepIndex,
  setStepIndex,
  label = 'Current Step',
}: TrainResultImageSliderData) {
  const { theme } = useContext(CustomThemeContext);

  const sliderBtnEl = useRef<HTMLButtonElement>(
    document.createElement('button')
  );
  const sliderBtnEl2 = useRef<HTMLButtonElement>(
    document.createElement('button')
  );
  const sliderDivEl = useRef<HTMLDivElement>(document.createElement('div'));
  const inputEl = useRef<HTMLInputElement>(document.createElement('input'));

  const [currentX, setCurrentX] = useState(0);
  const [error, setError] = useState<string>('');

  // ---- drag 해서 조정 ----
  const drag = (e) => {
    e.target.classList.add('dragging');
    setCurrentX(e.clientX - sliderDivEl.current.getBoundingClientRect().left);
  };

  const dragEnd = (e) => {
    setCurrentX(e.clientX - sliderDivEl.current.getBoundingClientRect().left);
    e.target.classList.remove('dragging');
  };

  useEffect(() => {
    if (currentX <= 0) {
      setCurrentX(1);
    } else if (currentX > sliderDivEl.current?.getBoundingClientRect().width) {
      setCurrentX(sliderDivEl.current?.getBoundingClientRect().width);
    } else {
      setStepIndex(
        Math.ceil(
          (currentX / sliderDivEl.current.getBoundingClientRect().width) *
            imgNum
        )
      );
      inputEl.current.value = Math.ceil(
        (currentX / sliderDivEl.current.getBoundingClientRect().width) * imgNum
      );
    }
    sliderBtnEl.current.setAttribute('style', `left: ${currentX - 12}px`);
    sliderBtnEl2.current.setAttribute('style', `left: ${currentX - 12}px`);
  }, [currentX]);

  // ---- click해서 조정 ----
  const handleSliderBar = (e) => {
    // 클릭 위치 - sliderBar의 위치
    if (sliderBtnEl.current && sliderDivEl) {
      setCurrentX(
        e.clientX - sliderDivEl.current.getBoundingClientRect().left - 12
      );
    }
  };

  // ---- input 입력해서 조정 onChange ----
  const handleChange = (e) => {
    const value = Number(e.target.value);

    if (!isInt(value) || value > imgNum) {
      setError('0 이상, ' + imgNum + ' 이하의 수를 입력해주세요');
      return;
    } else {
      setError('');
    }

    setTimeout(() => {
      if (Number(inputEl.current.value) === value) {
        setStepIndex(value);
      }
    }, 400);
  };

  useEffect(() => {
    // stepIndex =  (currentX / () * (imgNum))
    const newCurrentX =
      (sliderDivEl.current.getBoundingClientRect().width / imgNum) * stepIndex;

    sliderBtnEl.current.setAttribute('style', `left: ${newCurrentX - 12}px`);
    sliderBtnEl2.current.setAttribute('style', `left: ${newCurrentX - 12}px`);
  }, [stepIndex]);

  // ---- 초기 설정 ----
  useEffect(() => {
    setCurrentX(1);
    setStepIndex(1);
  }, [imgNum]);

  return (
    <Container className='slider' $theme={theme}>
      <div>
        <label htmlFor={'current-step' + label}>{label}</label>
        <input
          ref={inputEl}
          type='number'
          className='current-step'
          id='current-step'
          onChange={handleChange}
        />
        {error !== '' && <p className='warning-message'>{error}</p>}
      </div>

      <div ref={sliderDivEl} className='slider-bar' onClick={handleSliderBar}>
        <button
          ref={sliderBtnEl}
          className='slider-button'
          onDrag={drag}
          onDragEnd={dragEnd}
          draggable={true}
        >
          <span className='a11y-hidden'>Slider Button</span>
        </button>
        <button ref={sliderBtnEl2} className='slider-button2'>
          <span className='a11y-hidden'>Moving slider button</span>
        </button>
      </div>
    </Container>
  );
}
