import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import './storage.styles.scss';
import { IGearList, dirType } from './storage.type';
import { useCookies } from 'react-cookie';
import API from '../../../utils/api';
import { useAPIwithCookies } from '../../../hooks/useApiCookies';
import { StorageContext } from '../../../context/StorageContext';
import { CirclePicker } from 'react-color';

//더미데이터
import { dummyProcess } from '../dummy/tagsDummy';
import { tasksProcess } from '../dummy/tasksDummy';
import { CustomThemeContext } from '../../../utils/Context';
import {
  getPipeListData,
  getTagListData,
  postNewTagData,
  deleteSelectTag,
  getTaskList,
} from '../apis/api';
import { truncateText } from '../../../utils/truncateText';
import Swal from 'sweetalert2';

interface StorageModuelPanelProps {
  gearList: IGearList[];
}

interface Pipe {
  pipe_description: string;
  pipe_name: string;
}

interface Data {
  color: string;
  key: string;
  pipes: Record<string, Pipe>;
  title: string;
}

type Item = {
  tag: string;
  color: string;
  pipe: string;
  file_name: string;
  rule: { [key: string]: string };
  progress: number;
  status: string;
};

type GroupedItems = {
  [tag: string]: Item[];
};

type TPanelMode = 'normal' | 'expand' | 'init';

export default function StorageGearPanel({
  gearList,
}: StorageModuelPanelProps) {
  const { dragTag, setDragTag, pinnedIds, setPinnedIds, curDir, getDirectory } =
    useContext(StorageContext);
  const api = useAPIwithCookies();

  //@@@@@@@@@@@@@@@@@@ MSW @@@@@@@@@@@@@@@@@@//
  const [createTitle, setCreateTitle] = useState(''); // 지금 setting 돌아가는 이유는 공백이라서 그럼 ㅇㅇ
  const [color, setColor] = useState('#000000');
  const [pipeList, setPipeList] = useState<any>([]);
  const [taskList, setTaskList] = useState<any>([]);

  const [searchPipe, setSearchPipe] = useState('');
  const [selectPipeList, setSelectPipeList] = useState<any[]>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [selectTag, setSelectTag] = useState<any>([]);

  const [sidePanelText, setSidePanelText] = useState('Create New Tag');
  const [postBtnText, setPostBtnText] = useState('Add');
  const [nowTag, setNowTag] = useState('');

  const getFetchTagList = async () => {
    // tag리스트 받아옴
    // const result = await getTagListData();
    const response = await api.get('/tag/tag_list');
    console.log('태그 리스트', response.data);
    setTagList(response.data);
  };

  //파이프 리스트
  const getFetchPipeList = async () => {
    try {
      const response = await api.get('/pipe/list/' + searchPipe);
      console.log('파이프 리스트 출력', response.data);
      setPipeList(response.data);
    } catch (error) {
      console.error('Error fetching pipe list', error);
    }
  };

  useEffect(() => {
    getFetchPipeList();
    getFetchTagList();
  }, [searchPipe]);
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const groupByTag = (data: { [key: string]: Item }): GroupedItems => {
    return Object.values(data).reduce<GroupedItems>((acc, item) => {
      if (!acc[item.tag]) {
        acc[item.tag] = [];
      }
      acc[item.tag].push(item);
      return acc;
    }, {});
  };
  useEffect(() => {
    const getFetchData3 = async () => {
      // pipe리스트 받아옴
      const response = await api.get(`/scheduler/task_list/${curDir}`);
      // 각 키와 값을 포함하는 형태로 변환
      const data = response.data;
      console.log('원본 : 현재 디렉토리에서 영향을 주는 테스크들', data);
      const groupedItems = groupByTag(data);
      console.log('현재 디렉토리에서 영향을 주는 테스크들', groupedItems);
      setTaskList(groupedItems);
    };
    getFetchData3();
  }, [curDir]);

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleTitleText = (event: ChangeEvent<HTMLInputElement>) => {
    setCreateTitle(event.target.value);
  };

  const handleSelectColorHex = (color: any) => {
    setColor(color.hex);
  };

  const handleSelectColorPicker = (event: ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPipe(event.target.value);
  };

  // 파이프 선택 & 처리 메서드
  const handleSelectPipe = (item: any) => {
    setSelectPipeList((prevItems) => {
      if (!prevItems.includes(item)) {
        return [...prevItems, item];
      }
      return prevItems;
    });
  };

  const handleDeletePipe = (item: any) => {
    setSelectPipeList((prevItems) => prevItems.filter((i) => i !== item));
  };

  // ★★★ 새로운 파이프 생성 => 이거 나중에 받아온 key로 변경
  const handleSendNewTagData = async () => {
    console.log('여기 들옴', selectTag);

    console.log('선택한 파이프 리스트', selectPipeList);
    const pipeKeys = selectPipeList.map((pipe: any) => pipe.pipe_key);

    console.log(createTitle, color, pipeKeys);
    if (postBtnText === 'Add') {
      try {
        const response = await api.post('/tag/create_tag', {
          title: createTitle,
          color: color,
          pipes: pipeKeys,
        });

        if (response.status === 200) {
          getFetchTagList();
          setSidePanelText('Create New Tag');
          setCreateTitle('');
          setColor('#000000');
          setSelectPipeList([]);
          setPostBtnText('Add');
          Swal.fire({
            icon: 'success',
            title: 'Your tag has been created.',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch {
        Swal.fire({
          icon: 'error',
          title: 'Tag creation failed.',
        });
      }
    }

    console.log(selectTag, createTitle, color, pipeKeys);
    if (postBtnText === 'Edit') {
      try {
        const response = await api.post('/tag/modify_tag/' + selectTag, {
          title: createTitle,
          color: color,
          pipes: pipeKeys,
        });
        if (response.status === 200) {
          getFetchTagList();
          setSidePanelText('Create New Tag');
          setCreateTitle('');
          setColor('#000000');
          setSelectPipeList([]);
          setPostBtnText('Add');
          Swal.fire({
            icon: 'success',
            title: 'Tag has been edited.',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch {
        Swal.fire({
          icon: 'error',
          title: 'Tag editing failed.',
        });
      }
    }
  };

  // ★★★ 파이프 삭제 => 새로운 api 필요
  const handleDeleteTag = async (item: string) => {
    console.log('삭제 범위에 들어옴', item);
    try {
      const response = await api.post('/tag/delete_tag/' + item);
      if (response.status === 200) {
        getDirectory();
        getFetchTagList();
        setSidePanelText('Create New Tag');
        setCreateTitle('');
        setColor('#000000');
        setSelectPipeList([]);
        setPostBtnText('Add');
        Swal.fire({
          icon: 'success',
          title: 'Tag has been deleted.',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error fetching pipe list', error);
    }
  };

  // ★★★ 파이프 수정 => 나중에 key 값으로 변경 필요
  const handleEditTag = async (item: Data) => {
    setPanelMode('expand');

    // tag리스트 받아옴
    // console.log("수정들옴1", item);
    setNowTag(item.title);
    if (nowTag === item.title && sidePanelText === 'Modify Tag') {
      // 수정 재 클릭시, modify 취소
      console.log('수정들옴2');

      setSidePanelText('Create New Tag');
      setCreateTitle('');
      setColor('#000000');
      setSelectPipeList([]);
      setPostBtnText('Add');
      return;
    }

    if (nowTag !== item.title && sidePanelText === 'Modify Tag') {
      console.log('수정들옴3');

      setSidePanelText('Modify Tag');
      setCreateTitle(item.title);
      setColor(item.color);
      const transformedPipes = Object.entries(item.pipes).map(
        ([pipeKey, { pipe_name, pipe_description }]) => {
          return pipeList.find((pipe) => pipe['pipe_key'] === pipeKey);
        }
      );
      setSelectPipeList(transformedPipes);
      setPostBtnText('Edit');
      return;
    }

    if (sidePanelText === 'Create New Tag') {
      console.log('수정들옴4');

      setSidePanelText('Modify Tag');
      setSelectTag(item.key);
      setCreateTitle(item.title);
      setColor(item.color);
      const transformedPipes = Object.entries(item.pipes).map(
        ([pipeKey, { pipe_name, pipe_description }]) => {
          return pipeList.find((pipe) => pipe['pipe_key'] === pipeKey);
        }
      );
      setSelectPipeList(transformedPipes);
      setPostBtnText('Edit');
      return;
    }
  };
  //@@@@@@@@@@@@@@@@@@ MSW @@@@@@@@@@@@@@@@@@//

  const { theme } = useContext(CustomThemeContext);
  const [pinned, setPinned] = useState(
    new Array(tasksProcess.length).fill(false)
  );
  // const [pinnedIds, setPinnedIds] = useState<any>([]);
  const [panelMode, setPanelMode] = useState<TPanelMode>('init');
  const [translate, setTranslate] = useState<string>('');

  useEffect(() => {
    if (curDir !== '') {
      setPanelMode('normal');
    }
  }, [curDir]);

  // 패널 레이아웃 width 조정 함수
  const handlePanelMode = () => {
    switch (panelMode) {
      case 'init':
        setPanelMode('normal');
        break;
      case 'normal':
        setPanelMode('expand');
        break;
      case 'expand':
        setPanelMode('normal');
        break;
    }
  };

  useEffect(() => {
    setTranslate(panelMode === 'expand' ? '46rem' : '24.25rem');
  }, [panelMode]);

  const handlePipesPinned = (index: number, event: any) => {
    event.preventDefault();
    const newPin = [...pinned];
    newPin[index] = !newPin[index];
    setPinned(newPin);

    const id = tasksProcess[index].id; // 나중에 오는 식별값 key로 변경 필요 => 현재는 id

    if (newPin[index]) {
      // 해당하는 값이 핀이 된다면,
      setPinnedIds([...pinnedIds, id]);
    } else {
      setPinnedIds(pinnedIds.filter((pinnedId) => pinnedId !== id));
    }
  };

  // console.log(tasksProcess, "입니다.")

  console.log('현재 경로', curDir);

  return (
    <>
      {/* 공간 차지 하며, 레이아웃 유지하기 위함 */}
      <div id='storage-grid-visibility-none'></div>

      {/* 사이드 패널 */}
      <div id='storage-grid-gear-panel-box' style={{ width: translate }}>
        {/* 루트 디렉토리일 때는 expand 됨 && '>' 버튼이 보임 */}
        {curDir === '' && (
          <span
            id='side-panel-arrow'
            onClick={curDir === '' ? handlePanelMode : undefined}
            style={{ cursor: curDir === '' ? 'pointer' : 'default' }}
          >
            <img
              src={
                theme === 'dark'
                  ? '/images/canvas-panel-arrow.svg'
                  : '/images/canvas-panel-arrow-white.svg'
              }
              alt='If click this arrow, the panel will be expanded or shrinked'
              style={{
                transform: panelMode === 'expand' ? 'rotate(180deg)' : '',
              }}
            />
          </span>
        )}
        {/* 루트 디렉토리일 때는 expand 안됨 && '>' 버튼이 안보임 */}
        {curDir !== '' && (
          <span id='side-panel-arrow' style={{ opacity: 0 }}>
            <img
              src={
                theme === 'dark'
                  ? '/images/canvas-panel-arrow.svg'
                  : '/images/canvas-panel-arrow-white.svg'
              }
              alt='If click this arrow, the panel will be expanded or shrinked'
              style={{
                transform: panelMode === 'expand' ? 'rotate(180deg)' : '',
              }}
            />
          </span>
        )}
        <div id='storage-grid-right-panel'>
          {/* 좌측 컨테이너 */}
          <div id='storage-grid-right-container-normal'>
            {curDir === '' && (
              <div id='tag-layout'>
                <div id='tag-element'>
                  <h2>Tags</h2>
                  <div id='tag-list'>
                    {tagList.length === 0 && (
                      <div
                        id='tags-list-comment'
                        onClick={() => handlePanelMode()}
                      >
                        Please register tags
                      </div>
                    )}
                    {tagList.map((tagList: any, index: any) => (
                      <div
                        key={index}
                        id='list-container'
                        draggable='true'
                        onDragStart={(e) => {
                          setDragTag({
                            tagName: tagList.title,
                            valid: true,
                            color: tagList.color,
                            key: tagList.key,
                          });
                          console.log(tagList.title, '드래그 시작');
                        }}
                        onDragEnd={(e) => {
                          setDragTag({
                            tagName: '',
                            valid: false,
                            color: tagList.color,
                            key: tagList.key,
                          });
                          console.log(tagList.title, '드래그 끝');
                        }}
                        onDragOver={(e) => {
                          // setDragTag({ tagName: tagList.title, valid: true, color: tagList.color })
                          console.log(tagList.title, '드래그 중');
                        }}
                        onClick={() => handleEditTag(tagList)}
                      >
                        <ul>
                          {/* 나중에 수정 필요 => 지금은 임시 */}
                          <li
                            id='tag-color'
                            style={{ background: `${tagList.color}` }}
                          ></li>
                          <li id='tag-name'>{tagList.title}</li>
                        </ul>

                        <div id='tag-img-container'>
                          <img
                            src={
                              theme === 'dark'
                                ? '/images/settings.svg'
                                : '/images/settings-white.svg'
                            }
                            alt=''
                            className={
                              createTitle === tagList.title
                                ? 'modifyAnimation'
                                : ''
                            }
                          />
                          <img
                            src={
                              theme === 'dark'
                                ? '/images/delete2.svg'
                                : '/images/delete2-white.svg'
                            }
                            alt=''
                            onClick={() => handleDeleteTag(tagList.key)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {curDir !== '' && (
              <div id='pipe-layout'>
                <div id='pipe-element'>
                  <h2>Tasks</h2>
                  <div id='pipe-list'>
                    {taskList.length === 0 && (
                      <div id='tasks-list-comment'>There is no tasks</div>
                    )}
                    {Object.keys(taskList).map((key) => (
                      <ul key={key}>
                        {taskList[key].map((task, index) => (
                          <div
                            id='tasks-list-comment'
                            key={index}
                            style={{
                              borderBottom: '1px solid #212121',
                              paddingBottom: '20px',
                            }}
                          >
                            {/* 파이프 구분 */}
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  height: '15px',
                                  width: '15px',
                                  borderRadius: '99px',
                                  background: task.color,
                                }}
                              ></div>
                              <div
                                style={{
                                  marginLeft: '5px',
                                  color: task.color,
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                {task.pipe}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              {/* 파이프 속성에 영향을 받는 파이프 */}
                              <div>
                                -{' '}
                                {Object.values(task.rule).map((r, idx) => (
                                  <span key={idx}>
                                    {task.file_name.split('/').pop()}.{r}
                                    {idx <
                                      Object.values(task.rule).length - 1 &&
                                      ' + '}
                                  </span>
                                ))}
                              </div>
                              {/* 상태, viewer, download */}
                              <div id='list-container'>
                                <div id='list-container-element-two'>
                                  {task.status === 'complete' && (
                                    <img src={'/images/check.svg'} alt='' />
                                  )}
                                  {task.status === 'deleted' && (
                                    <img src={'/images/-.svg'} alt='' />
                                  )}
                                  <img
                                    src={
                                      theme === 'dark'
                                        ? '/images/play.svg'
                                        : '/images/play-white.svg'
                                    }
                                    alt=''
                                  />
                                  <img
                                    src={
                                      theme === 'dark'
                                        ? '/images/download-cloud.svg'
                                        : '/images/download-cloud-white.svg'
                                    }
                                    alt=''
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* 우측 컨테이너 */}
          <div id='storage-grid-right-container-expand'>
            <div id='pannel-layout'>
              <div id='panel-inner'>
                <h2>{sidePanelText}</h2>

                <div id='panel-content'>
                  {/*이름*/}
                  <div>
                    <h4>Name</h4>
                    <input
                      id='panel-box'
                      onChange={handleTitleText}
                      value={createTitle}
                    />
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      <h4 id='tag-color-picker-title'>Tag Color</h4>
                      <input
                        type='color'
                        id='tag-color-picker'
                        name='head'
                        value={color}
                        onChange={handleSelectColorPicker}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <CirclePicker
                        onChangeComplete={handleSelectColorHex}
                        width='100%'
                        circleSize={30}
                      />
                    </div>

                    {/*연결된 모듈 search 및 추가*/}
                    <div id='connect-module'>
                      <h4 id='connect-module-title'>Connect Module</h4>
                      <div id='module-content'>
                        <input
                          placeholder='Search for Pipes'
                          id='module-box'
                          onChange={handleSearchText}
                        />
                        {/* <button>필터</button> */}
                      </div>
                      <div id='element-box'>
                        {pipeList.map((pipeList: any, index: any) => {
                          const pipeIdentifier = pipeList;
                          return (
                            <div
                              id='pipe-list'
                              key={index}
                              className={
                                selectPipeList.includes(pipeIdentifier)
                                  ? 'selected-pipe'
                                  : ''
                              }
                              onClick={() => handleSelectPipe(pipeList)}
                              title={`${pipeList.pipe_name} | ${pipeList.pipe_description}`}
                            >
                              <div>
                                {truncateText(pipeList.pipe_name, 10)} |{' '}
                                {truncateText(pipeList.pipe_description, 10)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <h4 id='selected-pipes-title'>Selected Pipes</h4>
                      <ul>
                        {selectPipeList.map(
                          (selectPipeList: any, index: any) => (
                            <li id='element' key={index}>
                              <p>
                                {' '}
                                {truncateText(
                                  selectPipeList.pipe_name,
                                  10
                                )} |{' '}
                                {truncateText(
                                  selectPipeList.pipe_description,
                                  10
                                )}
                              </p>
                              <img
                                src={
                                  theme === 'dark'
                                    ? '/images/x.svg'
                                    : '/images/x-white.svg'
                                }
                                alt=''
                                onClick={() => handleDeletePipe(selectPipeList)}
                              />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <button id='add-button' onClick={handleSendNewTagData}>
                    {postBtnText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
