import React, { useContext, useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomThemeContext } from '@/contexts/common/Context';

import { getTimeString } from '@/components/rete-mlops/components/panel-contents/PanelOptionComponent/inputUtils';
import { WorkspaceContext } from '@/contexts/WorkspaceContext';

const StyledCodeEditorHeader = styled.header`
  padding: var(--12px);
`;

type CodeEditorHeaderProps = {
  code: string;
  modelPath: string;
  setModelPath: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export default function CodeEditorHeader({
  code,
  modelPath,
  setModelPath,
  setLoading,
}: CodeEditorHeaderProps) {
  const { theme } = useContext(CustomThemeContext);
  const { functions } = useContext(WorkspaceContext);

  const { createNewWorkspace, getModelFlowFromCode, updateCode, chageTitle, creaetNewFile } =
    functions;

  const [title, setTitle] = useState('');
  const [initTitle, setInitTitle] = useState('');
  const titleInput = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const location = useLocation();
  const workspace = location.state ? location.state.workspace : undefined;

  // generate button 클릭시 실행
  const handleGenerateModelFlow = async () => {
    let dataStructure;
    let wor_id;
    const newModelPath = await saveCode();

    if (newModelPath === 'already exists') {
      return;
    }

    setLoading(true);
    try {
      dataStructure = await getModelFlowFromCode(newModelPath);
      wor_id = await createNewWorkspace();

      if (workspace) {
        workspace.title = title;
      }

      navigate(`/vience-canvas/mlops/${wor_id}`, {
        state: {
          workspace,
          title,
          dataStructure,
        },
      });
    } catch (error) {
      alert('fail to generate nodes');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // 받아온 path에 저장
  const saveCode = async () => {
    let newModelPath: string = modelPath;

    try {
      if (modelPath && !modelPath.includes('/cloud/shared/model')) {
        // modelPath 있고, shared폴더 파일이 아님
        await updateCode(modelPath, code);
        if (initTitle !== title) {
          // 이름 바꾸기
          newModelPath = await chageTitle(modelPath, initTitle, title);
        }
        alert('save complete');
      } else if (!modelPath || modelPath.includes('/cloud/shared/model')) {
        // modelPath 없거나, shared폴더 파일임
        newModelPath = await creaetNewFile(title, code);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      if (newModelPath === 'already exists') {
        alert('fail: the title is already exists...');
      } else {
        setInitTitle(title);
        setModelPath(newModelPath);
      }
    }
    return newModelPath;
  };

  // 초기설정
  useEffect(() => {
    let splitedPath = modelPath.split('/');
    splitedPath = splitedPath[splitedPath.length - 1].split('.');
    splitedPath.pop();

    const tempInitTitle = splitedPath.join('.');
    setInitTitle(tempInitTitle);

    if (titleInput.current && tempInitTitle.replace(/ /g, '').length) {
      titleInput.current.value = tempInitTitle;
      setTitle(tempInitTitle);
    }
  }, [modelPath]);

  // titleInput.current에 untitled 넣기
  useEffect(() => {
    if (titleInput.current) {
      let initTitle;
      if (workspace) {
        initTitle = workspace.title + '_' + getTimeString();
      } else {
        initTitle = 'untitled_py_' + getTimeString();
      }
      titleInput.current.value = initTitle;
      setTitle(initTitle);
    }
  }, []);

  // title input onChange함수
  const handleTitleChange = (e) => {
    const value = (e.target as HTMLInputElement).value;
    const isSpecial = /[{}[\]/?.,;:|)*~`!^+<>@#$%&\\=('"]/g;

    if (isSpecial.test(value) && titleInput.current) {
      titleInput.current.value = value.replace(isSpecial, '');
    }

    setTimeout(() => {
      if (value === titleInput.current?.value) {
        setTitle(value);
      }
    }, 400);
  };

  return (
    <StyledCodeEditorHeader className='workspace-panel-box'>
      <button
        className='workspace-panel-button workspace-panel-left'
        onClick={() => {
          navigate('/vience-canvas/mlops');
        }}
      >
        <img
          src={
            process.env.PUBLIC_URL + theme === 'dark'
              ? '/images/canvas-back.svg'
              : '/images/canvas-back-white.svg'
          }
          alt=''
        />
        <span>Back</span>
      </button>

      <div className='workspace-panel-middle'>
        <span>Title / &nbsp;</span>
        <input ref={titleInput} onChange={handleTitleChange} />
      </div>

      <div className='workspace-panel-button-wrapper workspace-panel-right'>
        <button className='workspace-panel-button' onClick={saveCode}>
          <img
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/canvas-save.svg'
                : '/images/canvas-save-white.svg'
            }
            alt=''
          />
          <span>Save</span>
        </button>
        <button className='workspace-panel-button' onClick={handleGenerateModelFlow}>
          <img
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/canvas-play.svg'
                : '/images/canvas-play-white.svg'
            }
            alt=''
          />
          <span>Generate Model Flow</span>
        </button>
      </div>
    </StyledCodeEditorHeader>
  );
}
