import React, { useState, useEffect, useContext, useRef, useMemo, memo } from 'react';
import { Editor, useMonaco } from '@monaco-editor/react';
import Swal from 'sweetalert2';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { CustomThemeContext } from '@/contexts/common/Context';
import { CustomProcessingControl } from '../nodes/custom/CustomProcessing';
import { faCropSimple } from '@fortawesome/free-solid-svg-icons';
import { IfileNdirList } from '@/types/common/type';
import CustomProcessingDirectory from './CustomProcessingDirectory';
import CustomProcessingFile from './CustomProcessingFile';
import Carousel from './carousel/Carousel';

interface CustomProcessingPanelProps {
  ctrl: CustomProcessingControl;
  expand: boolean;
  nodeId?: any;
}

interface ImagePath {
  path: string;
  variableName: string;
}
interface Version {
  title: string;
  created_at: string;
}

export const CustomProcessingPanel = memo(({ ctrl, nodeId }: CustomProcessingPanelProps) => {
  const { theme } = useContext(CustomThemeContext);
  const monaco = useMonaco();
  const api = useAPIwithCookies();

  const [checkToggle, setCheckToggle] = useState(true);
  const [code, setCode] = useState(ctrl.props.option.user_code);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    ctrl.props.option.selected_category,
  );
  const editorRef = useRef<any>(null);
  const [isListVisible, setIsListVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('console');

  // Monaco 에디터 테마 설정
  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme('mlops-dark', {
        base: 'vs-dark',
        inherit: true,
        rules: [{ token: 'keyword', foreground: 'b47ef7' }],
        colors: {
          'editor.foreground': '#EDF9FA',
          'editor.background': '#131313',
          'editorCursor.foreground': '#b47ef7',
          'editor.lineHighlightBackground': '#000000',
          'editorLineNumber.foreground': '#b47ef777',
          'editorLineNumber.activeForeground': '#b47ef7',
          'editor.selectionBackground': '#b47ef799',
          'editor.inactiveSelectionBackground': '#b47ef777',
          'editor.wordHighlightBackground': '#b47ef755',
        },
      });

      monaco.editor.defineTheme('mlops-light', {
        base: 'vs',
        inherit: true,
        rules: [{ token: 'keyword', foreground: 'b47ef7' }],
        colors: {
          'editor.foreground': '#202021',
          'editor.background': '#FFFFFF',
          'editorCursor.foreground': '#b47ef7',
          'editor.lineHighlightBackground': '#c3c3c3cb',
          'editorLineNumber.foreground': '#b47ef777',
          'editorLineNumber.activeForeground': '#b47ef7',
          'editor.selectionBackground': '#b57ef75c',
          'editor.inactiveSelectionBackground': '#d6b3ff5f',
          'editor.wordHighlightBackground': '#969adf7b',
        },
      });

      monaco.editor.setTheme(theme === 'dark' ? 'mlops-dark' : 'mlops-light');
    }
  }, [monaco, theme]);

  // init api 관련 상태
  const [consoleContant, setConsoleContant] = useState(''); // console.log 컨텐트
  const [errorContant, setErrorContant] = useState(''); // error 컨텐트
  const [importModuleList, setImportModuleList] = useState(ctrl.props.option.user_import_list); // import 모듈 리스트
  const [inputType, setInputType] = useState<any[]>([]); // inputType 컨텐트
  const [thumnail, setThumnail] = useState<any[]>([]); // thumbnail
  // const [rawData, setRawData] = useState<{ thumbnail: any[]; type: any[] } | null>(null);

  const [inputValidStatus, setInputValidStatus] = useState(ctrl.props.option.input_validity); // input valid status

  const [userPath, setUserPath] = useState<Record<string, string>>(ctrl.props.option.path_list);
  const [userImportModule, setUserImportModule] = useState<string[]>(
    ctrl.props.option.user_import_list,
  ); // user import module
  const [template, setTemplate] = useState([]); // template
  const [versionState, setVersionState] = useState(ctrl.props.option.version_id); // 현재 버전 유지 상태 변수
  const [recentVersionId, setRecentVersionId] = useState(ctrl.props.option.recent_version_id); // 최신 버전 id => 최근에 적용된 preview로 만들어진 version임
  // 버전 리스트 받아오는 거 관련 상태swal
  const [versionList, setVersionList] = useState<Record<string, Version>>(
    ctrl.props.option.version_list,
  );
  const [preview, setPreview] = useState(ctrl.props.option.preview_img); // preview 컨텐트
  const [versionTitle, setVersionTitle] = useState(''); // 버전 타이틀
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);

  // init이 실행되고 난 이후 다른 api가 실행되도록 하는 상태(변수명은 급하게 만듦)
  const [a, setA] = useState<boolean>(false);
  const [b, setB] = useState<boolean>(false);
  // console.log('🔐🔐🔐🔐🔐', ctrl.props.option.version_id, versionState);
  // input 검증. 초기 노드 세팅에 필요한 정보들 생성 api
  const getCustomProcessingInfo = async () => {
    if (ctrl.props.option.path == '' || ctrl.props.option.path == undefined) {
      setPreview([]);
      setInputValidStatus(false);
      return;
    }

    // 컨테이너 체크 api => panel 최초 api
    await api.get(`/custom_code/check_container`);

    const response = await api.post(`/custom_code/init/${ctrl.nodeId}`, {
      image_data: ctrl.props.option.img_path,
      effector: ctrl.props.option.value,
    });
    const data = response.data.result;
    // console.log('🎯📌📌📌 방금 init 호출되었음', data);
    setA(true);
    setConsoleContant(ctrl.props.option.console_log);
    setErrorContant(ctrl.props.option.error);
    setCode(ctrl.props.option.user_code);
    setUserImportModule(ctrl.props.option.user_import_list);
    setUserPath(ctrl.props.option.path_list);

    // setVersionState(ctrl.props.option.recent_version_id);
    setCurrentVersion(ctrl.props.option.version_id);
    setRecentVersionId(ctrl.props.option.recent_version_id);

    setSelectedCategories(ctrl.props.option.selected_category);
    // setPreview(ctrl.props.option.preview_img);
    setCodeSecurity(ctrl.props.option.code_security);
    setCodeCompletion(ctrl.props.option.code_completion);
    setOutputType(ctrl.props.option.outputType);
    setImportModuleList(data.import_module_list);
    setInputValidStatus(data.input_valid_status);
    // const thumbnailAndTypeData = {
    //   thumbnail: data.thumbnail,
    //   type: data.thumbnail,
    // };
    // setRawData(thumbnailAndTypeData);

    setInputType(data.input_type);

    setThumnail(data.thumbnail);
    // if (data.thumbnail && JSON.stringify(thumnail) !== JSON.stringify(data.thumbnail)) {
    //   setThumnail(data.thumbnail);
    // }
  };

  // const thumnail = useMemo(() => rawData?.thumbnail ?? [], [rawData]);
  // const inputType = useMemo(() => rawData?.type ?? [], [rawData]);

  const getCustomProcessingVersion = async () => {
    // if (!ctrl.props.option.value?.length) {
    //   return;
    // }

    const response = await api.get(`/custom_code/version_list/${ctrl.nodeId}`);
    // console.log('✅✅✅✅✅', response);

    const data = response.data.result;
    setVersionList(data);

    // 마지막 키값 가져오기
    const lastKey = Object.keys(data).pop();

    // setVersionTitle도 설정 (title 값 적용)
    if (lastKey) {
      setVersionTitle(data[lastKey].title);
    }
  };

  // 버전 리스트 클릭시 api 호출
  const handleVersionClick = async (key: string) => {
    const isConfirmed = window.confirm('미완성 코드는 저장되지 않습니다. 그래도 진행하시겠습니까?');

    if (!isConfirmed) return;

    // console.log('🤚🤚🤚🤚', key);
    try {
      const response = await api.get(`/custom_code/version_code_info/${ctrl.nodeId}/${key}`);
      const data = response.data.result;
      // console.log('버전 클릭으로 받는 경우', data);

      setConsoleContant(data.console_log);
      setErrorContant(data.error);
      setCode(data.custom_code);
      setUserPath(data.path_list);
      setCurrentVersion(key);
      setVersionState(key);
    } catch (error) {
      console.error('버전 불러오기 실패:', error);
    }
  };

  // 필터링 관련 로직
  const [filterText, setFilterText] = useState(''); // 필터 텍스트 상태

  const filteredList = importModuleList.filter((category: any) =>
    category.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleCategoryInstall = async (installCategory: string) => {
    setIsLoading(true);
    const response = await api.post(`/custom_code/request_install/${ctrl.nodeId}`, [
      installCategory,
    ]);

    const data = response.data.result;
    // console.log(response);
    if (response.status === 200) {
      setImportModuleList(data);
    }
    setIsLoading(false);
  };

  // ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅
  const [fileNdirList_Normal, setFileNdirList_Normal] = useState<IfileNdirList[]>([]);
  const [selected, setSelected] = useState<{
    path: string;
    name: string;
    thumbnail: any;
    dataType?: any;
  }>({ path: '', name: '', thumbnail: '', dataType: '' });

  const [curDir, setCurDir] = useState<string>('');
  const [fileAttribute, setFileAttribute] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getFileDirList = async () => {
    try {
      const response = await api.get('/cloud/list', { cur_dir: '' });
      setFileNdirList_Normal(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFileDirList();
  }, [curDir]);

  // 경로 추가, 변경, API 로직
  const handleAddImagePath = () => {
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    // 여기서 나중에 사진인지 이런거 분기처리 하면 좋을듯 함 ㅇㅇ
    setIsModalOpen(false);

    if (curDir + '/' + selected.name) {
      // 조건은 이거 다르게 가져가야함 지금은 임시
      const uniqueKey = `key_${Date.now()}`;
      setUserPath((prevPaths) => ({
        ...prevPaths,
        [uniqueKey]: curDir + '/' + selected.name,
      }));
    } else {
      alert('정확한 이미지지 경로가 아닙니다.');
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEdit = async (key: string) => {
    const currentValue = userPath[key];
    const newKey = window.prompt('새로운 변수명을 입력하세요:', key);

    if (newKey !== null) {
      setUserPath({
        [newKey]: currentValue,
      });
    }
  };

  // ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅

  const upDatePathList = async () => {
    const response = await api.put(`/custom_code/update_path_list/${ctrl.nodeId}`, userPath);
  };
  // preview 로직
  const [codeSecurity, setCodeSecurity] = useState<boolean>(ctrl.props.option.code_security);
  const [codeCompletion, setCodeCompletion] = useState<boolean>(ctrl.props.option.code_completion);
  const [outputType, setOutputType] = useState(ctrl.props.option.outputType); // outputType 컨텐트

  const [isLoading, setIsLoading] = useState(false);

  const handleCheckAndPreview = async () => {
    setIsLoading(true); // 로딩 시작

    try {
      const response = await api.post(`/custom_code/check/${ctrl.nodeId}`, { code });
      const data = response.data.result;
      setCodeSecurity(data.code_security);

      // console.log('111111111111111');
      if (data.code_security) {
        const { value: title } = await Swal.fire({
          title: 'Enter Version Title',
          input: 'text',
          inputPlaceholder: 'Enter Version Title',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          inputValidator: (value) => {
            if (!value) {
              return 'You must enter a version title!';
            }
          },
          didOpen: () => {
            const input = document.querySelector('.swal2-input') as HTMLInputElement;
            if (input) {
              input.classList.add('dark:text-black');
            }
          },
        });

        if (!title) {
          setIsLoading(false);
          return;
        }
        const pathname = window.location.pathname;
        const wor_id = pathname.split('/canvas/')[1];
        console.log(ctrl.props.option.value);
        const response = await api.post(`/custom_code/preview/${ctrl.nodeId}`, {
          effector: ctrl.props.option.value,
          path_list: userPath,
          custom_code: code,
          title: title,
          wor_id: wor_id,
          img_paths: ctrl.props.option.img_path,
        });
        const data = response.data.result;
        // console.log(data);

        setConsoleContant(data.console_log);
        setErrorContant(data.error);
        setOutputType(data.output_type);

        setPreview(data.preview);

        if (data.code_completion) {
          setCodeCompletion(data.code_completion);
          // 현재 recentVersionId와 currentVersionId는 같은 상태임, 그래서 나중에 백엔드 api가 안정화 된다면, 하나로 합쳐야함
          setRecentVersionId(data.version_id);
          setCurrentVersion(data.version_id);
          setVersionState(data.version_id);
          setB(true);
        } else {
          setCodeCompletion(false);
        }
      }
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };
  // template 받아오는 api

  const getCustomProcessingTemplateList = async () => {
    // const response = await api.get(`/custom_code/template_list/${inputType}`);
    const response = await api.get(`/custom_code/template_list/image`);

    const data = response.data.result;
    setTemplate(data);
  };

  const handleLoadTemplate = async (key) => {
    // const response = await api.get(`/custom_code/template_info/${inputType}/${key}`);
    const response = await api.get(`/custom_code/template_info/image/${key}`);
    const data = response.data.result;
    // console.log(data);
    setCode(data.custom_code);
    setUserPath(data.path_list);

    // 구분 필요한 부분분
    const lines = data.custom_code.split('\n');

    const importLines = lines.filter(
      (line) => line.trim().startsWith('import') || line.trim().startsWith('from'),
    );
    // console.log('템플릿에서 가져온 구분 import', importLines);
    setSelectedCategories(importLines);
    setUserImportModule(importLines);
    setIsListVisible(!isListVisible);
  };
  useEffect(() => {
    setVersionState(ctrl.props.option.version_id);
    setUserImportModule(ctrl.props.option.user_import_list);
    setUserPath(ctrl.props.option.path_list);
    setCode(ctrl.props.option.user_code);
    setConsoleContant(ctrl.props.option.console_log);
    setErrorContant(ctrl.props.option.error);
    setSelectedCategories(ctrl.props.option.selected_category);
    setOutputType(ctrl.props.option.outputType);
    setPreview(ctrl.props.option.preview_img);
    setInputValidStatus(ctrl.props.option.input_validity);
    setCodeSecurity(ctrl.props.option.code_security);
    setCodeCompletion(ctrl.props.option.code_completion);
    setVersionList(ctrl.props.option.version_list);
    setRecentVersionId(ctrl.props.option.recent_version_id);
  }, [ctrl.id]);

  // useEffect(() => {
  //   getCustomProcessingInfo();
  // }, [ctrl.props.option.path]);

  useEffect(() => {
    getCustomProcessingInfo();
  }, [ctrl.id]);

  useEffect(() => {
    getCustomProcessingVersion();
    setB(false);
  }, [b]);

  useEffect(() => {
    if (a) {
      upDatePathList();
    }
  }, [a]);

  useEffect(() => {
    getCustomProcessingTemplateList();
  }, [inputType]);

  useEffect(() => {
    ctrl.setValue({
      version_id: versionState,
      user_import_list: userImportModule,
      path_list: userPath,
      user_code: code,
      console_log: consoleContant,
      error: errorContant,
      selected_category: selectedCategories,
      outputType: outputType,
      preview_img: preview,
      input_validity: inputValidStatus,
      code_security: codeSecurity,
      code_completion: codeCompletion,
      recent_version_id: recentVersionId,
      version_list: versionList,
    });
  }, [
    code,
    versionState,
    userImportModule,
    userPath,
    consoleContant,
    errorContant,
    selectedCategories,
    outputType,
    preview,
    inputValidStatus,
    codeSecurity,
    codeCompletion,
    recentVersionId,
    versionList,
  ]);

  // 1. 활성화를 하면, code에서 해당 모듈이 있는지 봐야한다.
  //    1-1. 있다면, 지워서 제일 상단으로 올린다.
  //    1-2. 없다면, 제일 상단으로 올린다.
  // 2. 취소를 하면, code에서 해당 모듈이 있는지 봐야한다.
  //    2-1. 있다면, 모두 지운다.
  //    2-2. 없다면 그대로 둔다.

  // => 활성화 되는 상태랑 묶어야 할듯 함, 현재 활성화 로직은 어디에 있나? 관련 변수는 filterList, category, userImportModule 등이 있음
  // =>

  const [taskFocusOne, setTaskFocusOne] = useState(false);
  const [taskFocusTwo, setTaskFocusTwo] = useState(true);

  const handleCategoryClick = (category: string) => {
    setTaskFocusOne(!taskFocusOne);

    setSelectedCategories((prev) => {
      // ImportModuleList에 있는 값만 유지하면서 최신화
      const syncedCategories = prev;

      // console.log('여기', category);
      // console.log('여기2', userImportModule.includes(category));
      // console.log('여기3', category === 'import torch.nn as nn');

      // category 토글 (선택/해제)
      const nextSelectedCategories = importModuleList.includes(category)
        ? syncedCategories.includes(category)
          ? syncedCategories.filter((item) => item !== category)
          : [...syncedCategories, category]
        : syncedCategories;

      // console.log('여기4', nextSelectedCategories);

      // 최신화된 상태로 업데이트
      setUserImportModule(nextSelectedCategories);

      return nextSelectedCategories;
    });
  };

  {
    /* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */
  }
  useEffect(() => {
    setUserImportModule(selectedCategories);
    setCode((prevCode) => {
      const lines = prevCode.split('\n');

      // 1. (import 구문/from 구문) 그리고 (일반 코드) 분리
      const importLines = lines
        .filter((line) => line.trim().startsWith('import') || line.trim().startsWith('from'))
        .map((line) => line.replace(/[\n\r]/g, '').trim());
      const otherLines = lines.filter(
        (line) => !(line.trim().startsWith('import') || line.trim().startsWith('from')),
      );

      // 2. 기존 import 구문 중 selectedCategories에 없는 것은 제거
      const filteredImports = importLines.filter((line) =>
        selectedCategories.some((category) => line === category),
      );

      // 3. selectedCategories에 기반한 새로운 import 구문 생성
      const newImports = selectedCategories
        .filter((category) => !importLines.some((line) => line === category)) // 이미 코드에 있는 category는 제외
        .map((category) => `${category}`); // 새로운 import 구문 생성

      // 4. 새로운 import 구문과 기존 일반 코드를 합쳐서 반환
      const newCode = [...newImports, ...filteredImports, ...otherLines].join('\n');

      return newCode;
    });
  }, [taskFocusOne]);

  useEffect(() => {
    const lines = code.split('\n');
    // 1. (import 구문/from 구문) 그리고 (일반 코드) 분리
    const importLines = lines
      .filter((line) => line.trim().startsWith('import') || line.trim().startsWith('from'))
      .map((line) => line.replace(/[\n\r]/g, '').trim());

    setSelectedCategories(importLines);
    setUserImportModule(importLines);
  }, [taskFocusTwo]);

  useEffect(() => {
    setTaskFocusTwo(!taskFocusTwo);

    const apple = code.split('\n');
    const importLines = apple
      .filter((line) => line.trim().startsWith('import') || line.trim().startsWith('from'))
      .map((line) => line.replace(/[\n\r]/g, ''));

    if (selectedCategories.toString() !== importLines.toString()) {
      setSelectedCategories(importLines);
    }
  }, [code]);

  {
    /* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */
  }

  // 드래그 앤 드롭 처리
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const variableName = event.dataTransfer.getData('text');

    if (editorRef.current) {
      const editor = editorRef.current;
      const position = editor.getPosition();
      setCode((prevCode) => {
        const lines = prevCode.split('\n');

        const lineIndex = position.lineNumber - 1;
        lines[lineIndex] =
          lines[lineIndex].slice(0, position.column - 1) +
          variableName +
          lines[lineIndex].slice(position.column - 1);

        return lines.join('\n');
      });
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // input validation이 false이면, side panel content 안보이도록 처리
  // 상태 변화 감지해서 자동으로 리렌더링 되도록 return 안에 inputValidStatus 변수 억지로 포함시킴
  if (!inputValidStatus) {
    return (
      <div className='flex size-full items-center justify-center text-xl font-bold text-[#231f48] dark:text-white'>
        {!inputValidStatus && <>connect valid input</>}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='flex size-full items-center justify-center text-xl font-bold text-[#231f48] dark:text-white'>
        Loading
      </div>
    );
  }

  // console.log('➡️➡️➡️➡️➡️➡️➡️➡️➡️➡️➡️', versionState);

  const handleTitleEdit = async (key: string) => {
    const version = versionList[key] as Version; // Type assertion
    if (!version) return;

    const { value: newTitle } = await Swal.fire({
      title: 'Edit Version Title',
      input: 'text',
      inputLabel: 'Enter a new title',
      inputValue: version.title || '', // Use the asserted type
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      inputValidator: (value) => (!value ? 'Title cannot be empty!' : undefined),
    });

    if (newTitle) {
      try {
        const response = await api.put(`/custom_code/update_version_title/${ctrl.nodeId}`, {
          version_id: key,
          title: newTitle,
        });

        if (response.status === 200) {
          setVersionList((prev) => ({
            ...prev,
            [key]: { ...prev[key], title: newTitle },
          }));
          Swal.fire('Success!', 'Title has been updated.', 'success');
        }
      } catch (error) {
        console.error('🚨 Title update failed:', error);
        Swal.fire('Error!', 'An error occurred while updating the title.', 'error');
      }
    }
  };

  return (
    <>
      {!checkToggle && (
        <div className='absolute inset-0 z-[99] flex items-center justify-center bg-black/70 text-4xl font-bold'>
          CODE CHECK ...
        </div>
      )}
      {/* 노멀 상태일때의 패널 */}
      <div className='mb-16 flex size-full flex-col overflow-y-auto p-5 dark:border-[#0f0f34]'>
        {/* custom processing description */}
        <div className='mb-4'>
          <div className='flex items-center gap-2'>
            <img
              src={
                process.env.PUBLIC_URL +
                (theme === 'dark'
                  ? '/images/rete/node/icon/dataset.svg'
                  : '/images/rete/node/icon/dataset-black.svg')
              }
              alt='Data Selector Icon'
            />
            <div className='text-2xl font-extrabold dark:text-[white]'>Custom Processing</div>
          </div>
          <div className='text-lg font-light mt-1 dark:text-[white]'>Customizing with code</div>
        </div>

        {/* custom processing status */}
        <div
          className='subtitle mt-[var(--30px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          {' '}
          Status
        </div>
        <div className='relative flex flex-col space-y-4'>
          <div className='absolute left-2 top-5 z-[-1] h-[79%] w-px bg-black300 dark:bg-[#ffffff]'></div>
          <div className='flex items-center space-x-4 '>
            <div
              className={`flex size-4 items-center justify-center rounded-full text-white ${inputValidStatus ? 'bg-green-500' : 'bg-red-500'}`}
            ></div>
            <span className='text-lg font-medium dark:text-[#ffffff]'>Input validity</span>
          </div>
          <div className='flex items-center space-x-4'>
            <div
              className={`flex size-4 items-center justify-center rounded-full text-white ${codeSecurity ? 'bg-green-500' : 'bg-red-500'}`}
            ></div>
            <span className='text-lg font-medium dark:text-[#ffffff]'>Code security</span>
          </div>
          <div className='flex items-center space-x-4'>
            <div
              className={`flex size-4 items-center justify-center rounded-full text-white ${codeCompletion ? 'bg-green-500' : 'bg-red-500'}`}
            ></div>
            <span className='text-lg font-medium dark:text-[#ffffff]'>Code completion</span>
          </div>
        </div>

        {/* custom processing preview */}
        <div
          className='subtitle mt-[var(--40px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          {' '}
          Preview
        </div>
        <div className='bg-[#FFFFFF] dark:bg-[#131313] text-[#131313] dark:text-white text-[13px] px-3 py-2 rounded-custom-xl w-fit mt-3'>
          Input Type
        </div>
        {thumnail.length > 0 && <Carousel carouselImage={thumnail} type={inputType} />}
        <div className='bg-[#FFFFFF] dark:bg-[#131313] text-[#131313] dark:text-white text-[13px] px-3 py-2 rounded-custom-xl w-fit mt-6'>
          Output Type
        </div>
        {inputType.length > 0 && <Carousel carouselImage={preview} type={inputType} />}
        {preview.length === 0 && (
          <div className='flex aspect-square w-full items-center justify-center rounded-[7px] bg-[#ffffff] dark:bg-[#131313] *:object-cover'>
            No Preview
          </div>
        )}
        <button
          className='mt-[var(--12px)] h-[2.75rem] min-h-[2.75rem]
                      w-full cursor-pointer rounded-lg bg-purple200
                        text-[16px] text-white100 hover:text-white100 hover:brightness-75'
          onClick={handleCheckAndPreview}
        >
          Check & Preview
        </button>

        {/* custom processing version */}
        <div
          className='subtitle mt-[var(--40px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          Version
        </div>
        <div className='mt-4 h-fit min-h-[200px]   cursor-pointer overflow-auto rounded-[7px] bg-[white] px-2 pb-2 dark:bg-[#131313]'>
          {Object.entries(versionList)
            .reverse()
            .map(([key, value]: any) => (
              <div className='flex justify-between items-center' key={key}>
                <div
                  className={`w-[82%] mt-2 flex items-center space-x-4 rounded-[7px] p-2 transition-all duration-300 
                ${currentVersion === key ? 'bg-[#e3e3e3] dark:bg-[#000000]' : 'hover:bg-[#d1d1d1] dark:hover:bg-gray-700'}`}
                  onClick={() => handleVersionClick(key)}
                >
                  <img
                    src={
                      theme === 'dark'
                        ? '/images/version-icon.svg'
                        : '/images/version-icon-white.svg'
                    }
                    alt=''
                  />
                  <span className='text-lg font-medium dark:text-[#ffffff]'>
                    {value.title} | {new Date(value.created_at).toLocaleDateString()}
                  </span>
                </div>
                <span
                  className='flex items-center justify-center text-[19px] bg-[#e3e3e3] dark:bg-[#000000] w-[16%] h-[100%] rounded-[7px] p-2 mt-2 font-bold text-white'
                  onClick={() => handleTitleEdit(key)}
                >
                  ✏️
                </span>
              </div>
            ))}
        </div>
      </div>

      {/* expand 상태일때의 패널 */}
      <div className='mb-16 flex size-full flex-col overflow-y-auto border-r border-[#0f0f34] p-5'>
        {/* Import module과 image path 레이아웃*/}
        <div className='flex flex-wrap justify-between gap-4 pb-6'>
          {/* Import */}
          <div className='h-auto min-w-[300px] flex-1 rounded-md bg-[#ffffff] p-4 dark:bg-[#131313]'>
            <div className='break-words text-base font-semibold tracking-normal text-[#181818] dark:text-[#ffffff]'>
              Module filter
            </div>
            <div className='mt-2 flex flex-wrap gap-2'>
              {selectedCategories.map((category: string, index: number) => (
                <div
                  key={index}
                  className='rounded-lg bg-[#a7a7a7] px-3 py-1 text-sm dark:bg-[#ffffff] dark:text-black'
                >
                  {category}
                </div>
              ))}
            </div>
            <input
              className='mt-4 w-full rounded-md border border-[#181818] bg-[#ffffff] p-4 dark:border-[#ffffff] dark:bg-[#131313] dark:text-[#ffffff]'
              placeholder='입력하세요'
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)} // 필터 상태 업데이트
            />

            <div className='mt-4 flex h-[200px] flex-wrap gap-2 overflow-auto'>
              {filteredList.length > 0 ? ( // 필터링된 결과가 있는 경우 버튼 렌더링
                filteredList.map((category) => (
                  <button
                    key={category}
                    className={`h-fit max-h-[50px] overflow-auto rounded-md border px-2 py-1 transition ${
                      selectedCategories.includes(category) || userImportModule.includes(category)
                        ? 'bg-[#a7a7a7] dark:bg-white text-[#000000]'
                        : 'bg-[#e3e3e3] dark:bg-[#000000] text-[#131313] dark:text-white'
                    }`}
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category}
                  </button>
                ))
              ) : (
                // 결과가 없을 경우 "Install" 버튼 표시
                <button
                  className='h-fit rounded-md border bg-[#ff5f5f] px-4 py-2 text-white transition'
                  onClick={() => handleCategoryInstall(filterText)}
                >
                  {filterText} Install
                </button>
              )}
            </div>
          </div>

          {/* Image Path */}
          <div className='h-auto min-w-[300px] flex-1 rounded-md  bg-[#ffffff] p-4 dark:bg-[#131313]'>
            <div className='break-words text-base font-semibold tracking-normal text-[#181818] dark:text-[#ffffff]'>
              File path
            </div>
            <div className='max-h-[220px] space-y-2 overflow-auto'>
              {Object.entries(userPath).map(([key, value], index) => (
                <div
                  key={index}
                  className='relative mt-2 flex w-full flex-col space-y-1 rounded-md bg-[#E3E3E3] p-4 text-[#181818] dark:border-white dark:bg-[#000000] dark:text-white' // relative 추가
                  draggable
                  onDragStart={(e) => e.dataTransfer.setData('text', key)} // key를 드래그 데이터로 설정
                >
                  <button
                    className='absolute right-3 top-2 text-sm text-black hover:underline dark:text-white'
                    onClick={() => handleEdit(key)}
                  >
                    Edit
                  </button>
                  <span>
                    <strong>Variable:</strong> {key}
                  </span>
                  <span>
                    <strong>Path:</strong> {value}
                  </span>
                </div>
              ))}
            </div>
            <button
              className='mt-2 flex h-[40px] w-full items-center justify-center rounded-md bg-purple200 text-white'
              onClick={handleAddImagePath}
            >
              + Add Path
            </button>
          </div>
        </div>

        {/* 에디터 */}
        <div className='h-[630px] min-h-[400px]' onDrop={handleDrop}>
          {/* 상태표시줄 */}
          <div className='flex h-[40px] w-full items-center justify-between'>
            <div className='flex h-full items-center bg-[white] dark:bg-[#131313] px-12 text-[black] dark:text-[white]'>
              <span className='mr-1 flex h-full  items-center justify-center font-medium'>
                {currentVersion}
              </span>
            </div>
            <nav
              className='cursor-pointer rounded-[7px] border border-bla dark:border-white100 bg-[white] dark:bg-[#131313] px-5 py-2 text-[black] dark:text-[white]'
              onClick={() => setIsListVisible(!isListVisible)}
            >
              Template
            </nav>
            {isListVisible && (
              <div
                className='absolute max-h-[300px] w-fit cursor-pointer overflow-auto rounded-[7px] border border-[black] dark:border-white100 bg-[white] dark:bg-[#131313] p-2 text-[black] dark:text-[white] shadow-lg'
                style={{ zIndex: 999, marginTop: '340px', marginRight: '20px', right: 0 }}
              >
                <ul>
                  {Object.entries(template).map(([key, value]: any) => (
                    <li
                      key={key}
                      className='flex items-center rounded text-[black] py-2 hover:dark:bg-[#000000] hover:bg-[#D3D3D3] dark:text-[white]'
                      style={{ width: '500px' }}
                      onClick={() => handleLoadTemplate(key)}
                    >
                      <img
                        src={`data:image/png;base64,${value.input}`}
                        alt='input preview'
                        className='ml-2 size-[70px] rounded-[7px]'
                      />
                      <img
                        src={theme === 'dark' ? '/images/right.svg' : '/images/right-black.svg'}
                        alt='arrow'
                        className='ml-2 size-[35px] rounded-[7px]'
                      />

                      <img
                        src={`data:image/png;base64,${value.output}`}
                        alt='output preview'
                        className='ml-2 size-[70px] rounded-[7px]'
                      />
                      <span className='ml-4'>{value.description}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* 코드 작성하는 에디터 */}
          <Editor
            defaultLanguage='python'
            value={code}
            theme={theme === 'dark' ? 'mlops-dark' : 'mlops-light'}
            onChange={(value) => setCode(value || '')}
            onMount={(editor) => {
              editorRef.current = editor;
            }}
            options={{
              fontSize: 16,
              lineHeight: 24,
              roundedSelection: true,
              contextmenu: true,
              wordWrap: 'on',
              fontLigatures: true,
            }}
          />
        </div>
        {/* <div className='mt-4 h-[530px] min-h-[400px] cursor-pointer overflow-auto rounded-[7px] border border-[#181818] px-2 pb-2 dark:border-white'> */}

        <div className='mt-16 h-[530px]'>
          {/* 콘솔 및 에러 */}
          <div className='flex h-[45px]'>
            {/* Console Tab */}
            <div
              className={`flex h-full cursor-pointer items-center rounded-tl-[7px] border border-[#ffffff] dark:border-[#131313] px-12 dark:border-[#181818]
              ${activeTab === 'console' ? 'bg-[#ffffff] dark:bg-[#131313] text-black dark:text-white' : 'bg-[#D3D3D3] text-[#181818] dark:bg-[#282A2C] dark:text-white'}`}
              onClick={() => handleTabClick('console')}
            >
              <span className='mr-1 font-medium'>Console Log</span>
            </div>

            {/* Error Tab */}
            <div
              className={`flex h-full cursor-pointer items-center rounded-tr-[7px] border border-[#ffffff] dark:border-[#131313] px-12 dark:border-[#181818]
              ${activeTab === 'error' ? 'bg-[#ffffff] dark:bg-[#131313] text-black dark:text-white' : 'bg-[#D3D3D3] text-[#181818] dark:bg-[#282A2C] dark:text-white'}`}
              onClick={() => handleTabClick('error')}
            >
              <span className='mr-1 font-medium'>Error</span>
            </div>
          </div>

          {/* Content Section */}
          <div className='h-[calc(100%-45px)] min-h-[310px] grow cursor-pointer overflow-auto rounded-r-[7px] rounded-bl-[7px] border border-[#ffffff] dark:border-[#131313]  bg-[#ffffff] p-2 text-[#181818] dark:bg-[#131313] dark:text-white'>
            {(activeTab === 'console' ? consoleContant : errorContant)
              .split('\n')
              .map((line, index) => (
                <div key={index}>{line.replace(/\t/g, '\u00A0\u00A0\u00A0\u00A0')}</div>
              ))}
          </div>
        </div>

        {isModalOpen && (
          <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/50'>
            <div className='w-96 rounded-lg bg-[#D3D3D3] dark:bg-[#282A2C] p-6 shadow-lg'>
              <div className='flex w-full items-center justify-center'>
                <div className='flex size-[300px]  items-center justify-center rounded-custom-lg bg-[#ffffff] dark:bg-[#131313]'>
                  {(fileAttribute[1] === 'jpg' || 'png') && (
                    <img
                      id='datamanage-thumbnail'
                      src={`data:image/jpeg;base64,${selected.thumbnail}`}
                    />
                  )}
                </div>
              </div>
              <div className='mt-4 flex items-center justify-center text-[black] dark:text-[#ffffff]'>
                {curDir + '/' + selected.name}
              </div>
              <ul className='mt-4'>
                {fileNdirList_Normal.map((data) => {
                  if (data.type === 'dataset' || data.type === 'dir' || data.type === 'model') {
                    return (
                      <CustomProcessingDirectory
                        depth={0}
                        name={data.name}
                        path={''}
                        setSelected={setSelected}
                        dataType={data.type}
                        curDir={curDir}
                        setCurDir={setCurDir}
                        // selected={selected}
                        fileAttribute={fileAttribute}
                        setFileAttribute={setFileAttribute}
                        info={{
                          name: '..',
                          type: 'dir',
                          is_dir: true,
                          thumbnail: 'empty',
                          updated_at: '',
                          path: '',
                        }}
                      />
                    );
                  } else {
                    return (
                      <CustomProcessingFile
                        thumbnail={data.thumbnail}
                        path={''}
                        depth={0}
                        name={data.name}
                        setSelected={setSelected}
                        dataType={data.type}
                        curDir={curDir}
                        setCurDir={setCurDir}
                        fileAttribute={fileAttribute}
                        setFileAttribute={setFileAttribute}
                        info={{
                          name: '..',
                          type: 'dir',
                          is_dir: true,
                          thumbnail: 'empty',
                          updated_at: '',
                          path: '',
                        }}
                      />
                    );
                  }
                })}
              </ul>

              <div className='mt-8 flex justify-evenly space-x-2'>
                <button
                  onClick={handleCancel}
                  className='rounded bg-[white] dark:bg-[black] px-14 py-2 text-[black] dark:text-white'
                >
                  취소
                </button>
                <button
                  onClick={handleConfirm}
                  className='rounded bg-purple200   px-14 py-2 text-white'
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
});
