import React, { useContext, useEffect, useState } from 'react';

import MlopsSidePanelArrow from './MlopsSidePanelArrow';
import MlopsSidePanelHeader from './MlopsSidePanelHeader';
import MlopsSidePanelContent from './MlopsSidePanelContent';

import { CustomThemeContext } from '@/contexts/common/Context';
import { TPanelMode } from '@/types/common/type';

export default function MlopsSidePanel() {
  const { theme } = useContext(CustomThemeContext);
  const [panelMode, setPanelMode] = useState<TPanelMode>('normal');
  const [translate, setTranslate] = useState<string>(
    'translateX(calc(100% - 24rem))'
  );

  const moveMinimap = (panelMode) => {
    const minimap = document.getElementById('minimap');
    switch (panelMode) {
      case 'normal':
        minimap?.style.setProperty('transform', 'translateX(-175%)');
        setTranslate('translateX(calc(100% - 24rem))');
        break;
      case 'expand':
        minimap?.style.setProperty('transform', 'none');
        setTranslate('none');
        break;
      case 'shrink':
        minimap?.style.setProperty('transform', 'none');
        setTranslate('translateX(calc(100% - 28px))');
        break;
    }
  };

  useEffect(() => {
    setPanelMode('normal');
  }, []);

  useEffect(() => {
    moveMinimap(panelMode);
  }, [panelMode]);

  return (
    <div className='side-panel-box' style={{ transform: translate }}>
      <MlopsSidePanelArrow
        theme={theme}
        panelMode={panelMode}
        setPanelMode={setPanelMode}
      />
      <div className='side-panel'>
        <MlopsSidePanelHeader
          theme={theme}
          panelMode={panelMode}
          setPanelMode={setPanelMode}
        />
        <MlopsSidePanelContent setPanelMode={setPanelMode} />
      </div>
    </div>
  );
}
