// library
import React, { useContext, useEffect, useState } from 'react';
import { useRete } from 'rete-react-plugin';
import { useLocation, useParams } from 'react-router-dom';

import { NodeTypes, editor as mlopsEditor } from '@/components/rete-mlops/editor';
import Header from '@/components/common/layouts/header/Header';
import MlopsControlPanel from './mlops-control-panel/MlopsControlPanel';
import MlopsSidePanel from './mlops-side-panel/MlopsSidePanel';
import WorkspacePanel from '@/components/rete/workspace-panel.component';
import LoadingAnimation from '@/components/loadingAnimation/LoadingAnimation';

import { type IWorkspaceSetting } from '../../canvas/workspace/workspace';

import { createEditor } from '@/components/rete-mlops/editor';
import {
  closeAllContext,
  createByDataStructure,
  createByJson,
  createToJson,
  process,
} from '@/components/rete-mlops/reteMlopsUtils';
import { CustomThemeContext, handleReteTheme } from '@/contexts/common/Context';

// data
import { getTimeString } from '@/components/rete-mlops/components/panel-contents/PanelOptionComponent/inputUtils';
import { WorkspaceContext } from '@/contexts/WorkspaceContext';
import { NodeContext } from '@/components/rete/NodeContext';
import { IconBtn } from './toolbar/components/IconBtn';
import { Toolbar } from './toolbar/Toolbar';

export default function MlopsEditor() {
  const [ref, editor] = useRete(createEditor);
  const [title, setTitle] = useState<string>('');
  const [setting, setSetting] = useState<IWorkspaceSetting>({
    category: '',
    description: '',
    ipAddress: '',
  });
  const [editable, setEditable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const params = useParams();
  const location = useLocation();

  const { theme } = useContext(CustomThemeContext);
  const { setSelectedWorkspace } = useContext(WorkspaceContext);
  const { functions } = useContext(WorkspaceContext);
  const { saveWorkspace, getWorkspaceItem } = functions;

  const { dynamic, setDynamic } = useContext(NodeContext);

  const saveCanvas = async () => {
    const json = await createToJson();
    const jsonString = {
      wor_id: params.wor_id,
      nodes: json,
      title: title,
      category: setting.category,
      ipAddress: setting.ipAddress,
      description: setting.description,
    };
    if (!ref.current) return;
    await saveWorkspace(jsonString);
  };

  const getCanvas = async () => {
    const data = await getWorkspaceItem(params.wor_id);
    if (data) {
      setTitle(data.title);
      setSetting({
        category: data.category ?? '',
        description: data.description ?? '',
        ipAddress: data.ipAddress ?? '',
      });

      if (data.nodes !== undefined) {
        await createByJson(JSON.parse(data.nodes));
      }
    }
    handleReteTheme();
  };

  const setCanvas = async () => {
    try {
      // BE에 저장된 Workspace일 때
      await getCanvas();
    } catch (error) {
      //  BE에 저장된 Workspace 가 아니고
      if (location.state) {
        // Template일 때
        await createByDataStructure(location.state.dataStructure);
        const workspace = location.state.workspace;
        const entries: string[][] = Object.entries(workspace?.description ?? [['', '']]);
        const desc = entries.map(([key, value]) => `${key}: ${value}`).join('\n');

        setSelectedWorkspace(workspace);
        setTitle(`${workspace?.title ?? location.state.title}`);
        setSetting({
          category: workspace?.category,
          description: desc,
          ipAddress: '0.0.0.0',
        });
      } else {
        // Template도 아니고 아예 New
        setTitle(`untitled_workspace_${getTimeString()}`);
        setSetting({
          category: 'category',
          description: 'description',
          ipAddress: '0.0.0.0',
        });
      }

      setTimeout(() => {
        editor?.layout(true, 'simple');
      }, 0);
    }
  };

  const setInit = async () => {
    setLoading(true);
    try {
      await setCanvas();
      await process();
      mlopsEditor.addPipe(async (context) => {
        if (['connectioncreated', 'connectionremoved'].includes(context.type)) {
          await process();
        }
        return context;
      });
    } finally {
      setEditable(true);
    }
  };

  // 실행
  useEffect(() => {
    if (editor) {
      setInit();
    }
  }, [editor]);

  useEffect(() => {
    editor?.zoomAt();

    if (editable === true) {
      setTimeout(async () => {
        await saveCanvas();
        setLoading(false);
      }, 500);
    }
  }, [editable]);

  useEffect(() => {
    const interval = setInterval(() => {
      saveCanvas();
    }, 5000);

    return () => clearInterval(interval);
  }, [params, title, setting]);

  const handleDynamic = async () => {
    if (!setDynamic) return;
    if (!window.confirm('close this workspace')) return;

    await saveCanvas();
    setDynamic((prev) => !prev);
  };

  const [trainers, setTrainers] = useState<NodeTypes[]>();
  const handleFindTrainer = (e, node = null) => {
    const newTrainers = editor?.zoomAtTrainer(node);
    setTrainers(newTrainers);
  };

  return (
    <>
      {loading && <LoadingAnimation label={'loading...'} />}

      <div className='canvas-edit' onContextMenu={(e) => e.preventDefault()}>
        <Header />
        <WorkspacePanel
          title={title}
          setTitle={setTitle}
          save={saveCanvas}
          setting={setting}
          setSetting={setSetting}
        />
        <Toolbar $theme={theme}>
          <IconBtn
            property1={dynamic ? 'dynamic-true' : 'dynamic-false'}
            handleClick={handleDynamic}
            checkedItem={dynamic ? 'true' : 'false'}
          />
          <IconBtn
            property1='align-unet'
            handleClick={() => {
              editor?.layout(true, 'unet');
            }}
          />
          <IconBtn
            property1='align-center'
            handleClick={() => {
              editor?.layout(true, 'simple');
            }}
          />
          <IconBtn
            property1='reset-view'
            handleClick={() => {
              editor?.zoomAt();
            }}
          />
          <IconBtn property1='find-trainer' handleClick={handleFindTrainer} items={trainers} />
        </Toolbar>

        <div
          className='main-canvas-content'
          style={{
            height: 'calc(100vh - var(--72px) - 55px)',
          }}
        >
          <MlopsControlPanel />
          <div ref={ref} className='rete main-canvas-middle' onClick={closeAllContext} />
          <MlopsSidePanel />
        </div>
      </div>
    </>
  );
}
