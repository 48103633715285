import React,{ useEffect } from 'react'
import "./project.scss";
import { Link } from "react-router-dom";
import { LandingHeader } from '../../../components/landingHeader/LandingHeader';
import { LineGrid } from "../../../components/lineGrid/LineGrid";
import { LandingFooter } from '../../../components/landingFooter/LandingFooter';
import { LandingSearch } from '../../../components/landingSearch/LandingSearch';
import AOS from "aos";
import "aos/dist/aos.css";

const Project:React.FC = () => {
  useEffect(() => {
    const current = document.querySelector(`body`);
    current?.classList.add('overflow-auto');
    AOS.init({ duration: 1500 });

    return () => {
      current?.classList.remove('overflow-auto');
    }
    
  },[]);

  return (
    <div className="project">
      <LineGrid />
      <div className="frame">
        <LandingHeader />
        <main>
          <h2 data-aos="fade-left">Project</h2>
          <div data-aos="fade-right">
            <LandingSearch />
          </div>
          <div className="project-list">
            <ul>
              <li data-aos="fade-up">
                <Link to="/pathoview">
                  <div className="left">
                    <div className="box">
                      <img src={process.env.PUBLIC_URL + '/images/project-pathoview-img.png'} alt="" className="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="top">
                      <strong>PathoView</strong>
                      <span>병상영상처리</span>
                    </div>
                    <div className="middle">
                      <strong>AI기반 병리 진단 솔루션</strong>
                    </div>
                    <div className="bottom">
                      <span>
                        Project Description
                      </span>
                      <p>
                      - 대용량 데이터의 효율적인 관리 및 활용 <br/>
                      - 각 고객의 분석  프로세스에 최적화된 워크플로우 구축 <br/>
                      - 최신 딥러닝 기술을 통한 데이터 정량화 <br/>
                      - 최신 시각분석 기술을 통한 데이터 시각화 및 분석 <br/>
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                <Link to="/cellview">
                  <div className="left">
                    <div className="box">
                      <img src={process.env.PUBLIC_URL + '/images/project-cellview-img.png'} alt="" className="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="top">
                      <strong>CellView</strong>
                      <span>HCS</span>
                    </div>
                    <div className="middle">
                      <strong>신약 개발 초고속 HCS 솔루션</strong>
                    </div>
                    <div className="bottom">
                      <span>
                        Project Description
                      </span>
                      <p>
                      - 수백장의  현미경 이미지를 효과적으로 관리/처리/시각화 <br/>
                      - 기존 일주일 이상 걸리던 분석 프로세스를 단 15분 안에 고품질로 수행 가능 <br/>
                      - 기존 수작업 기반 프로세스를 최신 딥러닝 기술을 통해 높은 정확도로 빠르게 수행 <br/>
                      - 직관적 시각분석 기술을 통한 효율적 대용량 데이터 분석 <br/>
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                <Link to="/brainview">
                  <div className="left">
                    <div className="box">
                      <img src={process.env.PUBLIC_URL + '/images/project-brainview-img.png'} alt="" className="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="top">
                      <strong>BrainView</strong>
                      <span>뇌영상분석</span>
                    </div>
                    <div className="middle">
                      <strong>AI기반 대규모 현미경 뇌영상 분석 솔루션</strong>
                    </div>
                    <div className="bottom">
                      <span>
                        Project Description
                      </span>
                      <p>
                        - 원숭이 뇌를 실시간으로 이미징 한 영상으로 부터 분석을 위한 데이터를 추출하고, 시각화 하여 뇌구조 분석 및 뇌활동성 분석 수행
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                <Link to="/xrops">
                  <div className="left">
                    <div className="box">
                      <img src={process.env.PUBLIC_URL + '/images/project-xrops-img.png'} alt="" className="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="top">
                      <strong>XROps</strong>
                      <span>VR/AR</span>
                    </div>
                    <div className="middle">
                      <strong>노코드 VR/AR 시각화 솔루션</strong>
                    </div>
                    <div className="bottom">
                      <span>
                        Project Description
                      </span>
                      <p>
                        - 컴퓨터 비전문가도 손쉽게 VR/AR 기반 데이터 시각화 수행 가능 <br/>
                        - 비전, 딥러닝 등의 기술을 통한 데이터 처리와 VR/AR 시각화를 손쉽게 연결하여 고품질 시각화 가능 <br/>
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                <Link to="/teravoxel">
                  <div className="left">
                    <div className="box">
                      <img src={process.env.PUBLIC_URL + '/images/project-teravoxel-img.png'} alt="" className="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="top">
                      <strong>Teravoxel</strong>
                      <span>데이터시각화</span>
                    </div>
                    <div className="middle">
                      <strong>대용량 3차원 데이터 가시화 기술</strong>
                    </div>
                    <div className="bottom">
                      <span>
                        Project Description
                      </span>
                      <p>
                        - 수백기가바이트~테라바이트에 이르는 대용량 3차원 데이터를 다양한 디바이스(VR/AR, 모바일, 데스크탑)에서 인터랙티브하게 시각화 하기 위한 기술 <br/>
                        - 사용자의 시점에 따라 데이터의 정보를 다르게 가시화하는 계층적 가시화 구현 <br/>
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                <Link to="/hieraslide">
                  <div className="left">
                    <div className="box">
                      <img src={process.env.PUBLIC_URL + '/images/project-hieraslide-img.png'} alt="" className="" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="top">
                      <strong>HieraSlide</strong>
                      <span>실시간딥러닝</span>
                    </div>
                    <div className="middle">
                      <strong>대용량 병리영상 분석 실시간 딥러닝 기술</strong>
                    </div>
                    <div className="bottom">
                      <span>
                        Project Description
                      </span>
                      <p>
                      - 병리학자들이 기가픽셀 크기의 병리영상을 딥러닝을 실시간으로 적용하고 분석할 수 있게 함으로써, 데이터 처리 시간을 기다릴 필요 없이 병리학적 판단을 위한 분석을 수행할 수 있게 함
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </main>
      </div>
      <div data-aos="fade-up" data-aos-delay="400">
        <LandingFooter />
      </div>
    </div>
  )
}

export default Project