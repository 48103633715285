import { IfileNdirList } from '@/types/common/type';
import React, { Dispatch, SetStateAction } from 'react';
interface FileProps {
  name: string;
  curDir: string;
  setCurDir: Dispatch<SetStateAction<string>>;
  fileAttribute: any;
  setFileAttribute: any;

  setSelected: Dispatch<
    SetStateAction<{
      path: string;
      name: string;
      thumbnail: string;
      dataType: any;
    }>
  >;
  dataType: any;
  info?: IfileNdirList;
  depth: number;
  path: string;
  thumbnail: string;
  selected?: {
    path: string;
    name: string;
    thumbnail: string;
    dataType: any;
  };
}

export default function CustomProcessingFile({
  name,
  setSelected,
  dataType,
  depth,
  path,
  thumbnail,
  selected,
  curDir,
  info,
  setCurDir,
  fileAttribute,
  setFileAttribute,
}: FileProps) {
  const handleFileClick = () => {
    // const divideNameAndFileType = name.split('.');
    // setFileAttribute(divideNameAndFileType);
    setCurDir(path);

    setSelected({
      path: path,
      name: name,
      thumbnail: thumbnail,
      dataType: dataType,
    });
  };

  return (
    <li style={{ marginLeft: depth !== 0 ? 26 : 0 }}>
      <div
        onClick={handleFileClick}
        className='depth-file  rounded-[5px] py-3 mt-[4px] dark:bg-[#131313] bg-[#ffffff] text-[black] dark:text-[white]'
      >
        {/* <img src={process.env.PUBLIC_URL + '/images/datamanage-icon-arrow-open.svg'} alt="" /> */}
        <div className='datamanage-file-name'>
          <img src={process.env.PUBLIC_URL + '/images/datamanage-icon-file.svg'} alt='' />
          <span>{name}</span>
        </div>
      </div>
    </li>
  );
}
