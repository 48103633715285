import { create } from 'zustand';
import { IfileNdirList } from '@/types/common/type';

interface DataHubState {
  dirLoading: boolean;
  loading: boolean;
  imageLoading: boolean;
  setImageLoading: (loading: boolean) => void;
  setLoading: (loading: boolean) => void;
  progress: { filename: string; progress: number };
  setProgress: (data: { filename: string; progress: number }) => void;
  curDir: string;
  setCurDir: (dir: string) => void;
  fileNdirList: IfileNdirList[];
  getDirectory: (trimmedPath?: any, api?: any) => Promise<void>;
  getImage: () => void;
  startIndex: number;
  setStartIndex: (index: number) => void;
  endIndex?: number;
  setEndIndex: (index: number | undefined) => void;
  selected: string[];
  setSelected: (selected: string[]) => void;
  selectedFile: { [key: string]: string };
  setSelectedFile: (file: { [key: string]: string }) => void;
  target?: string;
  setTarget: (target: string | undefined) => void;
  selectOpen: boolean;
  setSelectOpen: (open: boolean) => void;
  dragTag: { tagName: string; key: string; valid: boolean; color: string };
  setDragTag: (tag: { tagName: string; key: string; valid: boolean; color: string }) => void;
}

const useDataHubStore = create<DataHubState>((set, get) => ({
  dirLoading: false,
  loading: false,
  imageLoading: false,
  setImageLoading: (loading) => set({ imageLoading: loading }),
  setLoading: (loading) => set({ loading }),
  progress: { filename: '', progress: 0 },
  setProgress: (data) => set({ progress: data }),
  curDir: '',
  setCurDir: (dir) => set({ curDir: dir }),
  fileNdirList: [],

  startIndex: 0,
  setStartIndex: (index) => set({ startIndex: index }),
  endIndex: undefined,
  setEndIndex: (index) => {
    set({ endIndex: index });
    const { fileNdirList, startIndex } = get();
    if (index !== undefined) {
      const range =
        startIndex < index
          ? fileNdirList.slice(startIndex, index + 1)
          : fileNdirList.slice(index, startIndex + 1);
      set({ selected: range.map((data) => data.name) });
    }
  },
  selected: [],
  setSelected: (selected) => set({ selected }),
  selectedFile: {},
  setSelectedFile: (file) => set({ selectedFile: file }),
  target: undefined,
  setTarget: (target) => set({ target }),
  selectOpen: false,
  setSelectOpen: (open) => set({ selectOpen: open }),
  dragTag: { tagName: '', key: '', valid: false, color: '' },
  setDragTag: (tag) => set({ dragTag: tag }),

  getDirectory: async (trimmedPath, api) => {
    const loadingTimer = setTimeout(() => {
      set({ dirLoading: true });
    }, 200);
    set({ fileNdirList: [] });

    try {
      const { curDir } = get();

      const response = await api.get('/cloud/list_with_tag_info', {
        email: '',
        mem_id: '',
        cur_dir: trimmedPath || curDir,
        names: [],
        change: '',
        arrange: '',
      });

      clearTimeout(loadingTimer);
      set({ dirLoading: false });

      set({ fileNdirList: response.data });
    } catch (error) {
      clearTimeout(loadingTimer);
      set({ dirLoading: false });
      console.error(error);
    }
  },

  getImage: async () => {
    set({ imageLoading: true });
  },
}));

export default useDataHubStore;
