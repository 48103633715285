import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { default as OSD } from 'openseadragon';

import { ViewerControl } from '../../ViewerNode';
// import { ViewerNode } from '../../ViewerNode';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
// import { NodeContext } from '@/components/rete/NodeContext';
import { CustomThemeContext } from '@/contexts/common/Context';

import renderTree from '@/components/rete/utils/renderTree';
import topNode from '@/components/rete/utils/topNode';
import { createToJson, createToObject } from '@/components/rete/workspace';

// import { TPanelMode, TSelectedType } from '@/types/common/type';

export default function ImageViewerPanel({
  ctrl,
  viewerKey,
  handleOpenPanel,
  loading
}: {
  ctrl: ViewerControl;
  viewerKey: string;
  handleOpenPanel: any;
  loading:boolean;
}) {
  const api = useAPIwithCookies();
  const { theme } = useContext(CustomThemeContext);

  const [viewer, setViewer] = useState<OSD.Viewer>();
  const [tiledImage, setTiledImage] = useState<OSD.TiledImage>();

  // TODO 0 기본 뷰어, 5 module 뷰어 => normal panel 부분 수정 완료 후 적용 필요 부분
  const [viewerType, setViewerType] = useState(0);
  const params = useParams();
  const [inputThumbnail, setInputThumbnail] = useState('');

  // effector을 순회해서 data_selector의 thumbnail을 가져옴
  // TODO vmeta를 만나는 경우(input이 여러개인 경우) 처리 필요
  const getThumbnail = () => {
    if(loading){
      setInputThumbnail(`/images/rete/node/icon/loading.png`);
    }
    else{
      if (ctrl.props.option) {
        let cur_node = ctrl.props.option?.effector;
        while (!cur_node?.node.data_selector) {
          cur_node = cur_node?.input[0];
        }
        setInputThumbnail(`data:image/jpeg;base64,${cur_node.node.data_selector.thumbnail}`);
      }
    }
  };

  useEffect(() => {
    getThumbnail();
  }, [ctrl.nodeId, ctrl.props.option, loading]);

  useEffect(() => {
    if (viewerType !== 0 || viewerKey == '') return;
    const src =
      process.env.REACT_APP_VIENCE_API_KEY +
      '/viewer/module_basic_viewer/' +
      viewerKey +
      '/img.dzi';
    const osdViewer = new OSD.Viewer({
      id: 'osd-viewer',
      prefixUrl: '/images/openseadragon/',
      tileSources: src,
      crossOriginPolicy: 'Anonymous',
      animationTime: 0.5,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 50,
      minZoomLevel: 0.1,
      visibilityRatio: 1,
      zoomPerClick: 1,
      zoomPerScroll: 2,
      showNavigator: true,
      navigatorWidth: '250px',
      navigatorHeight: '250px',
      showFullPageControl: true,
      showHomeControl: true,
      showZoomControl: true,
      degrees: 0,
      showRotationControl: true,
    });

    osdViewer.addHandler('canvas-contextmenu', (e) => {
      e.originalEvent.preventDefault();
    });
    osdViewer.addHandler('open', (e) => {
      setTiledImage(e.eventSource.world.getItemAt(0));
    });
    osdViewer.addHandler('open-failed', (e) => {
      if (e.message.includes('402')) {
        console.error('Tile loading failed.', e.message);
      }
    });

    setViewer(osdViewer);
    return () => osdViewer.destroy();
  }, [viewerKey, viewerType]);

  return (
    <>
      <div
        className='mb-[var(--70px)] h-[calc(100vh-var(--72px)-55px-var(--72px)-50px-20px)]
          w-full overflow-y-auto p-[var(--20px)]'
      >
        <div className='flex items-center gap-2.5'>
          <img
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/rete/node/icon/viewer.svg'
                : '/images/rete/node/icon/viewer-black.svg'
            }
            alt='Viewer Icon'
            className='size-6'
          />
          <div
            className='maintitle break-all text-[24px]
              font-bold tracking-[var(--spacingDefalt)] text-black dark:text-white'
          >
            Viewer
          </div>
        </div>
        <div
          className='description mt-1 flex
            text-[16px] font-light text-gray300 dark:text-white100'
        >
          Click below to open the full viewer
        </div>
        <div
          className='subtitle mt-[var(--30px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          Check Output
        </div>
        <div
          className='mt-[var(--12px)] flex 
            aspect-square w-full cursor-pointer items-center justify-center
            rounded-lg bg-white100 hover:border hover:border-purple200 dark:bg-black300'
          onClick={() => {
            setViewerType(() => 0);
            handleOpenPanel();
          }}
        >
          {inputThumbnail ? (
            <img
              src={inputThumbnail}
              className='size-full rounded-lg object-contain'
              alt='Input thumbnail'
            />
          ) : (
            <p className='text-sm text-gray300'>No Thumbnail Available</p>
          )}
        </div>
        <div
          className='subtitle mt-[var(--30px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          Check Module Info
        </div>
        <button
          className='mt-[var(--12px)] h-11
          w-full cursor-pointer rounded-lg bg-purple200
            text-[16px] text-white100 hover:text-white100 hover:brightness-75'
          onClick={() => {
            setViewerType(() => 5);
            handleOpenPanel();
          }}
        >
          View Module Info
        </button>
      </div>

      <div
        className='grid-area-expand mx-0
          mb-[var(--20px)] flex w-full flex-col rounded-md'
      >
        {viewerType === 0 ? (
          <div
            id='osd-viewer'
            className='block size-full rounded-lg bg-white100
              p-[var(--10px)] text-black300 dark:bg-black300 dark:text-white100'
          />
        ) : (
          <div id='osd-viewer' className='hidden' />
        )}
        {viewerType === 5 &&
          (ctrl.props.option?.effector ? (
            <>
              {topNode(ctrl.props.option.effector)}
              {Array.isArray(ctrl.props.option.effector.input) &&
                ctrl.props.option.effector.input.map((inputNode, idx) => (
                  <React.Fragment key={idx}>
                    <div>{renderTree(inputNode)}</div>
                  </React.Fragment>
                ))}
            </>
          ) : (
            <div></div>
          ))}
      </div>
    </>
  );
}
