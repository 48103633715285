import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import DatasetExplorer
  from '@/components/rete-mlops/components/panel-contents/Dataset/DatasetExplorer';
import TemplateModalTitle from '../workspace/template/TemplateModalTitle';
import TemplateModalDesc from '../workspace/template/TemplateModalDesc';
import File from '@/components/rete/components/nodes/data-selector/panel/items/File';

import { CustomThemeContext } from '@/contexts/common/Context';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

const CodeEditorOptionDiv = styled.div`
  width: 21.875rem;
  flex-shrink: 0;
  padding: var(--20px);
  border-right: var(--1px) solid #181837;

  h3,
  h4 {
    font-weight: 700;
    background-color: #18183715;
    border-radius: var(--4px);
    padding: var(--4px);
    margin: var(--16px) 0 var(--4px);
  }

  div.template-info {
    border: 1px solid #181837;
    min-height: var(--400px);
    border-radius: var(--12px);
    padding: 0 var(--8px) var(--24px);
  }

  .custom-select {
    margin: var(--8px) 0 var(--20px);
    select {
      height: auto;
      background-color: ${(props) =>
        props.$theme === 'light' ? '#e3e3f0' : '#181837'};
      padding: var(--12px) !important;
      border-radius: var(--4px) !important;
    }
  }

  button {
    width: 100%;
    background-color: ${(props) =>
      props.$theme === 'light' ? '#e3e3f0' : '#181837'};
    border-radius: var(--4px);
    padding: var(--12px);
    margin: var(--4px) 0;
  }
`;

const MemoizedDatasetExplorer = React.memo(
  DatasetExplorer,
  datasetExplorerPropsAreEqul
);

function datasetExplorerPropsAreEqul(prevComponent, nextComponent) {
  return (
    prevComponent.selected === nextComponent.selected &&
    prevComponent.fileNdirList === nextComponent.fileNdirList
  );
}

export default function CodeEditorOption({
  code,
  setModelPath,
  getCode,
  getCodeEditor,
  setOptionComplete,
  selected,
  setSelected,
}) {
  const { theme } = useContext(CustomThemeContext);

  const location = useLocation();
  const workspace = location.state?.workspace;

  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [fileNdirList, setFileNdirList] = useState<any[]>([]);
  const [sharedModelList, setSharedModelList] = useState([]);

  const api = useAPIwithCookies();

  const getDirectoryFile = async () => {
    const response = await api.get('/cloud/list', { cur_dir: '' });
    setFileNdirList(response.data);
  };

  const getSharedModelList = async () => {
    const { data } = await api.get(`/mlops/shared_model_list`);
    const modelList = data.key.map((key, i) => {
      const dataName = key;
      const dataPath = data.path[i];

      return {
        abs_path: dataPath,
        is_dir: false,
        name: dataName + '.py',
        path: '/cloud/shared/model',
        thumbnail: 'filled',
        type: 'file',
        updated_at: '',
      };
    });

    setSharedModelList(modelList);
  };

  const handleOptionComplete = () => {
    if (code.length > 1) {
      if (
        !window.confirm(
          'The content you have written will be replaced by the selected file.'
        )
      ) {
        return;
      }
    }
    setOptionComplete(true);
    getCodeEditor();
    const userEmail = localStorage.getItem('email');
    if (selected.path === `/cloud/shared/model`) {
      setModelPath(`${selected.path}/${selected.name}`);
      getCode(`${selected.path}/${selected.name}`);
    } else {
      setModelPath(
        `/cloud/member/${userEmail}${selected.path}/${selected.name}`
      );
      getCode(`/cloud/member/${userEmail}${selected.path}/${selected.name}`);
    }
  };

  useEffect(() => {
    if (
      location.pathname &&
      location.pathname === '/vience-canvas/mlops/template'
    ) {
      setIsTemplate(true);
    }
    getDirectoryFile();
    getSharedModelList();
  }, []);

  return (
    <CodeEditorOptionDiv $theme={theme} className='template-option-section'>
      {isTemplate ? (
        <div>
          <h3>Recommend template</h3>

          <div className='template-info'>
            <TemplateModalTitle workspace={workspace} />
            <TemplateModalDesc workspace={workspace} />
          </div>
        </div>
      ) : (
        <>
          <h3>Select python file in DB</h3>
          {sharedModelList.map((sharedModel) => (
            <File
              key={sharedModel.abs_path}
              thumbnail={sharedModel.thumbnail}
              path={sharedModel.path}
              depth={0}
              name={sharedModel.name}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
          <MemoizedDatasetExplorer
            fileNdirList={fileNdirList}
            selected={selected}
            setSelected={setSelected}
          />
          <button type='button' onClick={handleOptionComplete}>
            Select
          </button>
        </>
      )}
    </CodeEditorOptionDiv>
  );
}
