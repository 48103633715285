import React, { useEffect, useRef, useState } from 'react';
import Header from '@/components/common/layouts/header/Header';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { fetchAllQnAList, fetchMyQnAList, postNewQuestion } from '@/apis/helpcenter/qnaApis';

export default function HelpCenter(): JSX.Element {
  const api = useAPIwithCookies();

  const [allQuestionList, setAllQuestionList] = useState([]);
  const [myQuestionList, setMyQuestionList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [isWriteModalOpen, setIsWriteModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newContent, setNewContent] = useState('');
  const [isPublic, setIsPublic] = useState(true); // true: 공개, false: 비공개

  // 새 질문 작성 api
  const handleSubmit = async () => {
    await postNewQuestion(api, newTitle, newContent, isPublic);
  };

  // 질문 리스트 조회(내가쓴 질문, 전체 질문) api
  const getFetchQnAList = async () => {
    await fetchMyQnAList(api, setMyQuestionList);
    await fetchAllQnAList(api, setAllQuestionList);
  };

  useEffect(() => {
    getFetchQnAList();
  }, []);

  // 모달 관련 처리 동작
  const openModal = (question: any) => setSelectedQuestion(question);
  const closeModal = () => setSelectedQuestion(null);

  const openWriteModal = () => setIsWriteModalOpen(true);
  const closeWriteModal = () => {
    setIsWriteModalOpen(false);
    setNewTitle('');
    setNewContent('');
  };

  // 스크롤 이벤트
  const scrollRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false); // 초기값 false로 설정

  const updateScrollButtons = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollWidth > clientWidth + scrollLeft); // 남은 스크롤이 있으면 true
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = 300;
      scrollRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleResize = () => updateScrollButtons(); // 창 크기 변경 시 업데이트

    updateScrollButtons(); // 🔥 최초 실행 (초기 버튼 상태 반영)

    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', updateScrollButtons);
    }
    window.addEventListener('resize', handleResize); // 반응형 처리

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', updateScrollButtons);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [myQuestionList]); // 🔥 리스트 변경될 때마다 버튼 다시 체크

  return (
    <div className='bg-gray100 dark:bg-black100'>
      <Header />
      <div
        className='flex flex-col overflow-hidden px-0 2xl:px-96'
        style={{ height: 'calc(100vh - 72px)' }}
      >
        {/* 내가 쓴 글 */}
        <h2 className='p-4 pt-12 text-[30px] font-bold dark:text-[white]'>내가 쓴 글</h2>
        <div className='relative p-4'>
          {/* 왼쪽 버튼 (스크롤 가능할 때만 표시) */}
          {canScrollLeft && (
            <button
              className='absolute left-0 top-1/2 z-10 -translate-y-1/2 rounded-full bg-white px-5 py-4 shadow-md dark:bg-black dark:text-white'
              onClick={() => scroll('left')}
            >
              ◀
            </button>
          )}

          <div
            ref={scrollRef}
            className='scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800 flex w-full gap-8 overflow-x-auto scroll-smooth'
          >
            <div
              className='flex h-48 min-w-[280px] shrink-0 cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-black200 p-4 shadow-md dark:border-white'
              onClick={openWriteModal}
            >
              <p className='text-sm font-bold text-black dark:text-white'>+ 새 글 작성</p>
            </div>
            {myQuestionList.map((question: any) => (
              <div
                key={question.id}
                className='h-48 min-w-[280px] shrink-0 cursor-pointer space-y-4 rounded-md bg-white100 p-6 shadow-md dark:bg-black200 dark:text-white'
                onClick={() => openModal(question)}
              >
                <p className='mb-4 text-[20px] font-bold'>
                  {question.title ? `🤚` + question.title : '🤚제목없음'}
                </p>
                <p className='text-[16px]'>답변 여부: {question.status}</p>
                <p className='text-[16px]'>
                  작성 날짜: {new Date(question.created_at).toLocaleString()}
                </p>
                <p className='text-[16px]'>공개 여부: {question.public_yn ? '공개' : '비공개'}</p>
              </div>
            ))}
          </div>

          {/* 오른쪽 버튼 (스크롤 가능할 때만 표시) */}
          {canScrollRight && (
            <button
              className='absolute right-0 top-1/2 z-10 -translate-y-1/2 rounded-full bg-white px-5 py-4 shadow-md dark:bg-black dark:text-white'
              onClick={() => scroll('right')}
            >
              ▶
            </button>
          )}
        </div>
        <div className='mt-[35px] p-4'>
          <div className=' h-[2px] w-full bg-black300 dark:bg-gray-300'></div>
        </div>
        {/* 다른 사람의 질문 */}
        <h2 className='mt-10 p-4 text-[30px] font-bold dark:text-[white] overflow-auto'>
          모든 질문
        </h2>
        <div className='flex-1 overflow-y-auto p-4'>
          <div className='space-y-4'>
            {allQuestionList.map((question: any) => (
              <div
                key={question.id}
                className='cursor-pointer rounded-md bg-white100 p-8 shadow-md dark:bg-black200 dark:text-white'
                onClick={() => openModal(question)}
              >
                <p className='mb-2 text-[20px] font-bold'>
                  {' '}
                  {question.title ? `🤚` + question.title : '🤚제목없음'}
                </p>
                <p className='text-[16px]'>질문 내용: {question.question}</p>
                <p className='text-[16px]'>작성자 닉네임: {question.nickname}</p>
                <p className='text-[16px]'>
                  작성 날짜: {new Date(question.created_at).toLocaleString()}
                </p>
                <p className='text-[16px]'>
                  업데이트 날짜: {new Date(question.updated_at).toLocaleString()}
                </p>
                <p className='text-[16px]'>답변 여부: {question.status}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* 질문 상세 모달 */}
      {selectedQuestion && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='h-fit w-[600px] rounded-md bg-white p-8 shadow-lg dark:bg-black200'>
            <h2 className='mt-4 text-xl font-bold dark:text-white'>
              {selectedQuestion.title ?? '제목없음'}
            </h2>
            <div className='mt-8'>
              <p className='text-sm dark:text-gray-300'>작성 날짜: {selectedQuestion.created_at}</p>
              <p className='text-sm dark:text-gray-300'>
                작성자: {selectedQuestion.nickname || '내 질문'}
              </p>
            </div>
            <div className='mt-4'>
              <p className='text-lg font-bold dark:text-white'>🤚질문 내용</p>
              <p className='text-sm dark:text-gray-300'>{selectedQuestion.question}</p>
            </div>
            {selectedQuestion.answer && (
              <div className='mt-4'>
                <p className='text-lg font-bold dark:text-white'>✅ 답변 내용</p>
                <p className='text-sm dark:text-gray-300'>{selectedQuestion.answer}</p>
              </div>
            )}
            <button
              className='mt-8 rounded-md bg-gray200 px-4 py-2 text-white dark:bg-[white] dark:text-black'
              onClick={closeModal}
            >
              닫기
            </button>
          </div>
        </div>
      )}

      {/* 새 글 작성 모달 */}
      {isWriteModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='h-fit w-[600px] rounded-md bg-white p-8 shadow-lg dark:bg-black200'>
            <h2 className='mt-4 text-xl font-bold dark:text-white'>새 질문 작성</h2>
            <div className='mt-4'>
              <label className='block text-sm font-bold dark:text-white'>제목</label>
              <input
                type='text'
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                className='mt-2 w-full rounded-md border border-black p-2 dark:border-white dark:bg-black200 dark:text-white'
              />
            </div>
            <div className='mt-4'>
              <label className='block text-sm font-bold dark:text-white'>내용</label>
              <textarea
                value={newContent}
                onChange={(e) => setNewContent(e.target.value)}
                className='mt-2 w-full rounded-md border border-black p-2 dark:border-white dark:bg-black200 dark:text-white'
                rows={4}
              />
            </div>
            {/* 토글 버튼 */}
            <div className='mt-4 flex items-center'>
              <label className='mr-4 text-sm font-bold dark:text-white'>공개 여부</label>
              <button
                onClick={() => setIsPublic(!isPublic)}
                className={`rounded-md px-4 py-2 ${
                  isPublic ? 'bg-green-500 text-white' : 'bg-gray-400 text-black'
                }`}
              >
                {isPublic ? '공개' : '비공개'}
              </button>
            </div>

            <div className='mt-8 flex justify-end'>
              <button
                className='mr-2 rounded-md bg-gray200 px-4 py-2 text-white dark:bg-[white] dark:text-black'
                onClick={closeWriteModal}
              >
                취소
              </button>
              <button
                className='rounded-md bg-gray200 px-4 py-2 text-white dark:bg-[white] dark:text-black'
                onClick={handleSubmit}
              >
                작성
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
