import React, { useState, useCallback, useEffect } from 'react';

interface DataHubDropProps {
  children: React.ReactNode;
  handleDropFiles: (files: File[]) => void;
  dragTag: { valid: boolean };
  pathname: string;
}

export default function DataHubDrop({
  children,
  handleDropFiles,
  dragTag,
  pathname,
}: DataHubDropProps) {
  const [inFileDragging, setInFileDragging] = useState(false);
  const [fileDragging, setFileDragging] = useState(false);

  const onDragEnter = useCallback(
    (e: React.DragEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (dragTag.valid || inFileDragging) return;
      setFileDragging(true);
    },
    [dragTag.valid, inFileDragging],
  );

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setFileDragging(false);

      if (inFileDragging) return;

      const dt = e.dataTransfer;
      const files = Array.from(dt.files);
      if (files.length > 0) {
        handleDropFiles(files);
      }
    },
    [inFileDragging, handleDropFiles, pathname],
  );

  const [targetHeight, setTargetHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const targetElement = document.getElementById('storage-card-frame');
      if (targetElement) {
        setTargetHeight(targetElement.clientHeight);
      }
    };

    updateHeight(); // 초기 높이 설정
    window.addEventListener('resize', updateHeight); // 창 크기 변경 감지

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <section
      style={{ height: targetHeight }}
      className={`file-droppable ${fileDragging ? 'drag-prepared min-h-screen' : ''}`}
      onDragStart={() => setInFileDragging(true)}
      onDragEnter={onDragEnter}
      onDragOver={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onDragEnd={() => {
        setFileDragging(false);
        setInFileDragging(false);
      }}
      onDrop={onDrop}
      onMouseLeave={() => setFileDragging(false)}
    >
      <div data-in-file-dragging={inFileDragging} data-set-in-file-dragging={!!setInFileDragging}>
        {children}
      </div>
    </section>
  );
}
