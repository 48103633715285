import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@/contexts/common/Context';
import { CookiesProvider } from 'react-cookie';
import { NodeProvider } from '@/components/rete/NodeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Home from '@/routes/home/home.component';
import Project from '@/routes/home/project/Project';
import Example from '@/routes/home/example/Example';

import Overview from '@/routes/overview/Overview';

import Register from '@/routes/usermanage/Register';
import Login from '@/routes/usermanage/Login';

import DataHub from '@/pages/datahub/DataHub';
import FullImage from '@/components/common/images/FullImage';

import MyWorkspace from '@/routes/canvas/workspace/MyWorkspace';
import Diagram1 from '@/routes/canvas/diagram/diagram1.component';
import CanvasTemplate from '@/routes/canvas/canvasTemplate/CanvasTemplate';

import HelpCenter from '@/pages/HelpCenter';
import Mypage from '@/pages/MyPage';

import IntroVideo from '@/routes/introvideo/introvideo.component';
import Front from '@/routes/front/front.component';
import Main from '@/routes/main/main.component';
import PathoView from '@/routes/demo/pathoview.component';
import CellView from '@/routes/demo/cellview.component';
import XROps from '@/routes/demo/xrops.component';
import BrainView from '@/routes/demo/brainview.component';
import HieraSlide from '@/routes/demo/hieraslide.component';
import Teravoxel from '@/routes/demo/teravoxel.component';
import Recruit from '@/routes/recruit/recruit.component';

import MlopsWorkspace from '@/routes/mlops/workspace/MlopsWorkspace';
import MlopsEditor from '@/routes/mlops/editor/MlopsEditor';
import TrainDetail from '@/routes/mlops/train-detail/TrainDetail';
import MlopsCodeEditor from '@/routes/mlops/code-editor/MlopsCodeEditor';
import { WorkspaceContextProvider } from '@/contexts/WorkspaceContext';
// import { TrainContextProvider } from '@/contexts/TrainContext';
import { useAuthContext } from '@/hooks/common/useAuthContext';
import MlopsEditorSample from '@/routes/mlops/editor/MlopsEditorSample';
import TaskImage from '@/components/common/images/TaskImage';
import CodeEditor from './pages/CodeEditor';
import ReportViewer from './pages/ReportViewer';
import TermsOfUse from '@/pages/TermsOfUsePage';
import PrivacyPolicy from '@/pages/PrivacyPolicyPage';

export const vw = window.innerWidth;
export const vh = window.innerHeight;

const App = () => {
  const { user, isAuthReady } = useAuthContext();
  const MultiContextProvider = ({ contexts, children }) =>
    contexts.reduce(
      (prev, context) =>
        React.createElement(context, {
          children: prev,
        }),
      children,
    );

//   useEffect(() => {
//     const message = 'Bridging computers and sciences. 우리와 함께 성장하고 도전할 분을 기다립니다!';
//     const recruitLink = 'https://vience.io/recruit';
//     const pixelLogo = `
// ██    ██ ██ ███████ ███    ██  ██████  ███████ 
// ██    ██ ██ ██      ████   ██ ██       ██      
// ██    ██ ██ █████   ██ ██  ██ ██       █████   
//  ██  ██  ██ ██      ██  ██ ██ ██       ██      
//   ████   ██ ███████ ██   ████  ██████  ███████ 
//   `;

//     console.log(
//       `%c${pixelLogo}%c\n${message} %c${recruitLink}`,
//       'color: crimson; font-weight: bold; font-size: 10px; line-height: 1.2;',
//       'font-size: 14px; line-height: 1.4; font-weight: bold;',
//       'color: blue; text-decoration: underline; font-size: 14px; font-weight: bold;',
//     );
//   }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY as string}>
      <MultiContextProvider
        contexts={[
          ThemeProvider,
          CookiesProvider,
          NodeProvider,
          // TrainContextProvider,
          WorkspaceContextProvider,
        ]}
      >
        {isAuthReady ? (
          <BrowserRouter>
            <Routes>
              <Route path='/vience-canvas' element={<Home />} />
              <Route path='/vience-canvas/project' element={<Project />} />
              <Route path='/vience-canvas/example' element={<Example />} />
              <Route
                path='/vience-canvas/login'
                element={
                  user?.access ? <Navigate replace={true} to={'/vience-canvas/'} /> : <Login />
                }
              />
              <Route
                path='/vience-canvas/register'
                element={
                  user?.access ? <Navigate replace={true} to={'/vience-canvas/'} /> : <Register />
                }
              />
              <Route
                path='/vience-canvas/privacy-policy'
                element={
                    <PrivacyPolicy />
                }
              />
              <Route
                path='/vience-canvas/terms-of-use'
                element={
                    <TermsOfUse />
                }
              />
              <Route
                path='/vience-canvas/canvas'
                element={
                  user?.access ? (
                    <Diagram1 />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/canvas/:wor_id'
                element={
                  user?.access ? (
                    <Diagram1 />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/workspace'
                element={
                  user?.access ? (
                    <MyWorkspace />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              // 퍼블리시
              <Route path='/publish/:wor_id/:node_id' element={<ReportViewer />} />
              <Route
                path='/vience-canvas/template'
                element={
                  user?.access ? (
                    <CanvasTemplate />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/datahub/*'
                element={
                  user?.access ? (
                    <DataHub />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/overview'
                element={
                  user?.access ? (
                    <Overview />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/image/*'
                element={
                  user?.access ? (
                    <FullImage />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/scheduler/:task_key/img.dzi' // 별표
                element={
                  user?.access ? (
                    <TaskImage />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/codeEditor/*'
                element={
                  user?.access ? (
                    <CodeEditor />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mlops'
                element={
                  user?.access ? (
                    <MlopsWorkspace />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route path='/vience-canvas/mlops/sample' element={<MlopsEditorSample />} />
              <Route
                path='/vience-canvas/mlops/:wor_id'
                element={
                  user?.access ? (
                    <MlopsEditor />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mlops/:wor_id/detail'
                element={
                  user?.access ? (
                    <TrainDetail />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mlops/code-editor'
                element={
                  user?.access ? (
                    <MlopsCodeEditor />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mlops/template'
                element={
                  user?.access ? (
                    <MlopsCodeEditor />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/helpcenter'
                element={
                  user?.access ? (
                    <HelpCenter />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mypage'
                element={
                  user?.access ? (
                    <Mypage />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              {/* <Route
                path='/vience-canvas/helpcenter/:wor_id'
                element={
                  user?.access ? (
                    <HelpCenter />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              /> */}
              <Route path='/' element={<Front />} />
              <Route path='/main' element={<Main />} />
              <Route path='/intro' element={<Main />} />
              <Route path='/pathoview' element={<PathoView />} />
              <Route path='/cellview' element={<CellView />} />
              <Route path='/intro_video' element={<IntroVideo />} />
              <Route path='/xrops' element={<XROps />} />
              <Route path='/brainview' element={<BrainView />} />
              <Route path='/teravoxel' element={<Teravoxel />} />
              <Route path='/hieraslide' element={<HieraSlide />} />
              {/* <Route path='/recruit' element={<Recruit />} /> */}
            </Routes>
          </BrowserRouter>
        ) : (
          <>loading</>
        )}
      </MultiContextProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
