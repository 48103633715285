// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#lasso {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#lasso polygon {
  stroke: #6c7fe9ad;
  stroke-dasharray: 10 5;
  stroke-width: 2;
  fill: rgb(144 161 255 / 21%);
  z-index: 5;
  animation: dash linear 1s infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 15;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/rete/selection/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;EACf,4BAA4B;EAC5B,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":["#lasso {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n}\n\n#lasso polygon {\n  stroke: #6c7fe9ad;\n  stroke-dasharray: 10 5;\n  stroke-width: 2;\n  fill: rgb(144 161 255 / 21%);\n  z-index: 5;\n  animation: dash linear 1s infinite;\n}\n\n@keyframes dash {\n  to {\n    stroke-dashoffset: 15;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
