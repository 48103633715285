import React from "react";

const TermsOfUse = () => {
  return (
    <div style={{ width: "100%", margin: "0 auto", fontSize: "12px"}}>
      {/* 서비스 이용약관 */}
      {/* <h1>서비스 이용약관 (Terms of Use)</h1> */}
      <h2 style={{fontWeight: "800"}}>제1조 (목적)</h2>
      <p>
        이 약관은 ㈜바이언스(이하 “회사”라 함)가 제공하는 VIENCE CANVAS (이하
        “서비스”라 함)와 관련하여, 회사와 서비스 이용자의 권리·의무 및
        책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
      </p>

      <h2 style={{fontWeight: "800"}}>제2조 (용어의 정의)</h2>
      <ol>
        <li>
          “서비스”라 함은 회사가 웹사이트(또는 웹 애플리케이션 등)를 통해
          제공하는 각종 분석, 데이터 처리, 데이터 시각화, 그 외 관련 기능
          일체를 말합니다.
        </li>
        <li>
          “이용자”라 함은 서비스에 접속하여 이 약관에 따라 회사가 제공하는
          서비스를 이용하는 모든 회원 및 비회원을 말합니다.
        </li>
        <li>
          “회원”이라 함은 회사의 서비스에 접속하여 본 약관 및
          개인정보처리방침에 동의하고, 이메일 인증 등 회사가 정한 가입 절차를
          완료하여 이용자 계정을 부여받은 자를 말합니다.
        </li>
        <li>
          “콘텐츠”라 함은 이용자가 서비스를 이용하면서 업로드, 게시, 전송 또는
          제공하는 데이터, 정보, 문서, 이미지, 동영상, 기타 자료 일체를
          말합니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제3조 (약관의 명시, 효력 및 변경)</h2>
      <ol>
        <li>
          회사는 본 약관을 메인화면 및 회원가입 과정에 제공하여 이용자가 알
          수 있도록 합니다.
        </li>
        <li>
          회사는 필요한 경우 관련 법령(약관의 규제에 관한 법률 등)을 위배하지
          않는 범위 내에서 본 약관을 변경할 수 있습니다.
        </li>
        <li>
          회사가 약관을 변경할 경우, 적용일자 및 변경사유를 명시하여 최소 7일
          전(이용자에게 불리하거나 중대한 사항 변경은 30일 전)부터 서비스 내
          공지사항이나 이메일 등을 통해 공지합니다.
        </li>
        <li>
          이용자가 변경된 약관 시행일부터 7일 이내에 명시적으로 거부 의사를
          표시하지 아니하고 서비스를 계속 이용할 경우에는 변경된 약관에 동의한
          것으로 간주합니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제4조 (회원가입 및 계정 관리)</h2>
      <ol>
        <li>
          이용자는 회사가 정한 가입 양식에 이메일, 비밀번호, 이름,
          닉네임(공개 식별자), 소속 타입, 소속 이름, 직업 종류, 서비스 가입
          목적, 마케팅 이메일 수신 동의 여부 등을 기입하고 이메일 인증 절차를
          거쳐 회원가입을 신청합니다.
        </li>
        <li>
          회사는 제1항과 같이 가입을 신청한 이용자 중 아래 각호에 해당하지 않는
          한 회원으로 등록합니다.
          <ul>
            <li>
              가입신청자가 이 약관 또는 개인정보처리방침 등 기타 관련 규정을
              위반하거나 위반한 이력이 있는 경우
            </li>
            <li>허위 정보를 기재하거나 회사가 요구하는 사항을 기재하지 않은 경우</li>
            <li>사회의 안녕질서 또는 선량한 풍속을 저해할 우려가 있는 경우</li>
            <li>기타 회사가 정한 이용요건을 만족하지 못하는 경우</li>
          </ul>
        </li>
        <li>
          회원은 등록된 정보에 변경이 발생한 경우, 즉시 서비스 내 회원정보 수정
          기능을 이용하여 변경사항을 회사에 통지하여야 합니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제5조 (서비스의 제공 및 변경)</h2>
      <ol>
        <li>
          회사는 회원에게 다음 각 호의 기능 또는 서비스를 제공합니다.
          <ul>
            <li>데이터 업로드 및 저장</li>
            <li>데이터 처리 및 분석 기능</li>
            <li>데이터 분석 결과 시각화 및 공유 기능</li>
            <li>기타 회사가 추가 개발하거나 다른 회사와의 제휴를 통해 제공하는 일체의 서비스</li>
          </ul>
        </li>
        <li>
          회사는 업무상 또는 기술상 필요에 따라 서비스의 전부 또는 일부를
          변경하거나 종료할 수 있습니다. 이 경우 회사는 사전에 합리적인
          방법으로 회원에게 고지합니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제6조 (콘텐츠의 권리 및 책임)</h2>
      <ol>
        <li>
          이용자가 서비스 내에 업로드하거나 전송한 콘텐츠에 대한 저작권 및 기타
          지식재산권은 이용자에게 귀속됩니다. 단, 회사는 이용자의 동의를 얻어 서비스 제공, 유지, 보수
          및 개선을 위해 필요한 범위 내에서 이를 사용할 수 있습니다. 회사는 이용자의 동의가 없는 한 
          절대로 이용자의 콘텐츠를 접근하지 않습니다.
        </li>
        <li>
          이용자는 자신이 업로드한 콘텐츠가 제3자의 권리(저작권, 상표권,
          특허권 등)를 침해하지 않도록 주의의무를 다하여야 합니다.
        </li>
        <li>
          회사는 이용자가 업로드한 콘텐츠의 내용에 대한 책임을 지지 않으며,
          제3자가 권리 침해를 주장하는 경우 회사는 해당 콘텐츠의 게시 중단 등
          필요한 조치를 취할 수 있습니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제7조 (이용자의 의무)</h2>
      <ol>
        <li>
          이용자는 아래 각 호의 행위를 하여서는 안 됩니다.
          <ul>
            <li>타인의 계정을 도용하거나, 개인정보를 무단으로 수집, 이용, 제공하는 행위</li>
            <li>회사 또는 제3자의 지식재산권 등 권리를 침해하거나 침해를 유도하는 행위</li>
            <li>서비스를 부정한 방법으로 이용하거나, 정상적인 서비스 운영을 방해하는 행위</li>
            <li>허위정보 또는 타인의 명의를 도용하여 가입하거나 정보를 수정·등록하는 행위</li>
            <li>기타 관련 법령 및 회사의 정책에 위배되는 행위</li>
          </ul>
        </li>
        <li>
          이용자는 관련 법령, 본 약관, 서비스에서 공지하는 이용정책 및
          주의사항 등을 준수해야 합니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제8조 (회사의 의무)</h2>
      <ol>
        <li>
          회사는 관련 법령과 본 약관을 준수하며, 지속적이고 안정적으로 서비스를
          제공하기 위하여 최선을 다합니다.
        </li>
        <li>
          회사는 이용자의 개인정보를 본인이 동의한 목적 및 범위 내에서만
          사용하고, 그 밖의 용도로 제3자에게 제공하지 않습니다. 단, 관련 법령에
          의하여 적법한 절차로 요청받은 경우에는 예외로 합니다.
        </li>
        <li>
          회사는 서비스 이용과 관련하여 이용자로부터 제기된 의견이나 불만사항이
          정당하다고 판단될 경우 적절한 절차를 거쳐 처리합니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제9조 (서비스 이용의 제한 및 종료)</h2>
      <ol>
        <li>
          회사는 이용자가 본 약관 또는 관련 법령을 위반하거나, 정상적인 서비스
          운영에 지장을 초래하는 경우, 사전 통지 후(긴급을 요하는 경우에는 사후
          통지) 이용을 제한하거나 계정을 해지할 수 있습니다.
        </li>
        <li>
          이용자가 회원 탈퇴를 원하는 경우, 서비스 내(My Page) 탈퇴 절차를 통해 언제든지
          탈퇴를 신청할 수 있으며, 회사는 24시간 내에 회원 탈퇴를 처리합니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제10조 (책임의 한계 및 면책)</h2>
      <ol>
        <li>
          회사는 천재지변, 전쟁, 테러, 정전, 화재, 통신장애 등 불가항력적인
          사유로 인하여 서비스를 제공할 수 없는 경우에 대해서 책임을 지지
          않습니다.
        </li>
        <li>
          회사는 이용자가 서비스와 관련하여 기대하는 이익, 결과 등에 대하여
          보증하지 않으며, 이용자가 서비스를 이용함으로 인해 발생한 손해에
          대하여 회사의 귀책사유가 없는 한 책임을 지지 않습니다.
        </li>
        <li>
          회사는 이용자가 업로드한 콘텐츠의 적법성, 정확성, 타당성 등에 대하여
          보증하지 않으며, 이와 관련하여 발생한 분쟁에 대하여 책임을 지지
          않습니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제11조 (준거법 및 관할)</h2>
      <ol>
        <li>본 약관의 해석 및 적용에 관해서는 대한민국 법령을 적용합니다.</li>
        <li>
          서비스 이용과 관련하여 회사와 이용자 간에 분쟁이 발생할 경우, 회사의
          본점 소재지를 관할하는 법원을 전속적 관할법원으로 합니다.
        </li>
      </ol>
      <br />
      <p>
        <strong>부칙</strong>
        <br />
        이 약관은 2025년 3월 6일부터 적용됩니다.
      </p>
    </div>
  );
};

export default TermsOfUse;