import React, { useEffect, useRef, useState } from 'react'
import { ConvTranspose2dControl } from '../../nodes/ConvTranspose2dNode';
import Convolution2dOptionSection from '../Convolution2D/Convolution2dOptionSection';
import { ILayerOption } from '../../flow';

export function ConvTranspose2dPanelContent({ ctrl }: {
  ctrl: ConvTranspose2dControl
}) {
  const [loading, setLoading] = useState(true);

  // down
  const initOption = useRef<ILayerOption>({ nodeId: '' });
  // up
  const [option, setOption] = useState<ILayerOption>({ nodeId: '' });
  
  // 선택된 ctrl.id 바뀔때마다 ctrl에 저장된 옵션으로 바꾸기
  useEffect(() => {
    setLoading(true);

    setOption(ctrl.props.option);
    initOption.current = ctrl.props.option;

    setLoading(false);
  }, [ctrl.nodeId]);

  // 옵션이 바뀌면 ctrl에 저장
  useEffect(()=>{
    if (!loading) {
      ctrl.setValue(option);
    }
  },[option]);

  return (
    <>
      <div className='side-panel-normal'>
        <Convolution2dOptionSection nodeId={ctrl.nodeId} initOption={initOption} setOption={setOption} loading={loading} setLoading={setLoading} />
      </div >

      <div className='side-panel-expand' id='#storage-grid'>
        <div className="inner">
        </div>
      </div>
    </>
  )
}
