import React, { useState, useEffect } from 'react';
import { ClassicPreset, NodeId } from 'rete';
import { IImageFilter } from '../../filter';
import './ImageCrop.scss';
import { useCookies } from 'react-cookie';
import API from '@/apis/common/apis';
import { CustomSocket } from '../../sockets';
import { InputWithLabel } from '../../../../common/CustomInput';
import { AddVxPxToVx } from '@/utils/common/imageUtils';
import { IDataflow } from '../flow';
import '@/components/rete/components/nodes/viewer/panel/side-panel.styles.scss';
import { EffectorColor } from '../../style/CustomNode';
import CustomControl from '../../style/CustomControl';
import { updateControl } from '../../../reteUtils';
import { NodeSize } from '../../style/CustomNode';

export class ImageCropNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: ImageCropControl }
> {
  color = EffectorColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super('ImageCrop');
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new ImageCropControl(process, nodeId ? nodeId : this.id));
  }

  data(inputs: { in: IDataflow[] }): { out: IDataflow } {
    const value = inputs['in'][0];
    const baseInput = value.img_paths;
    const baseInputKey = Object.keys(baseInput)[0];
    const basePath = baseInput[baseInputKey];

    if (this.controls.ctrl.props.option.path !== basePath) {
      this.controls.ctrl.setValue({
        path: basePath,
      });
    }

    return {
      out: {
        img_paths: value.img_paths,
        effector: {
          input: [value.effector],
          node: {
            img_crop: {
              crop: this.controls.ctrl.props.option.crop,
            },
          },
        },
      },
    };
  }
}

export class ImageCropControl extends ClassicPreset.Control {
  props: {
    option: IImageFilter;
    [key: string]: any;
  };
  contextOpen: boolean;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      option: {
        path: undefined,
        crop: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
        },
      },
    };
    this.contextOpen = false;
  }
  setValue(val: IImageFilter) {
    this.props.option = val;
    this.onChange();
  }
  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };
}

export function ImageCropComp({ data }: { data: ImageCropControl }): JSX.Element {
  return (
    <CustomControl
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
      nodeId={data.nodeId}
      label='Image Crop'
      iconSource='imagecrop'
      markerSource='node-effector'
    />
  );
}

interface ImageCropPanelProps {
  ctrl: ImageCropControl;
  expand: boolean;
}

interface ICoord {
  x: number;
  y: number;
}

interface IPosition {
  width: number;
  height: number;
  top: number;
  left: number;
}

const [vw, vh] = [window.innerWidth, window.innerHeight];

export const ImageCropPanel = ({ expand, ctrl }: ImageCropPanelProps) => {
  const [cookies, setCookie] = useCookies(['refresh']);
  const api = new API(cookies);
  const [loading, setLoading] = useState<boolean>(false);
  const [backImgSize, setBackImgSize] = useState<number>(0);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [posS, setPosS] = useState<ICoord>({ x: 0, y: 0 });
  const [posE, setPosE] = useState<ICoord>({ x: 0, y: 0 });
  const [posL, setPosL] = useState<IPosition>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const [dragging, setDragging] = useState<boolean>(false);
  const [imageInfo, setImageInfo] = useState<{
    height: number;
    width: number;
    ratio: number;
  }>({ height: 0, width: 0, ratio: 0 });

  const panelWidthVw = AddVxPxToVx('width', 60, -370);
  const panelHeightVw = AddVxPxToVx('height', 80, 0);

  const getImage = async () => {
    setLoading(true);
    const length = ctrl.props.option.path?.split('/').length;
    let path = '';
    let file;
    if (length) {
      file = ctrl.props.option.path?.split('/')[length - 1];
      for (let i = 0; i < length - 1; i++) {
        path += `/${ctrl.props.option.path?.split('/')[i]}`;
      }
    }
    const response = await api.post('/cloud/image', {
      cur_dir: path,
      names: [file],
    });
    const ratio = response.data.height / response.data.width;
    setImageInfo({
      height: response.data.height,
      width: response.data.width,
      ratio: ratio,
    });
    setImageUrl(response.data.base64);
    if (ratio >= 1) setBackImgSize(panelHeightVw / ratio);
    else setBackImgSize(panelWidthVw * ratio);
    setLoading(false);
  };

  const calculatePosition = (e: any) => {
    const imageEditor = document.getElementById('image-editor');
    if (imageEditor) {
      const editorRect = imageEditor.getBoundingClientRect();
      const x = e.clientX - editorRect.left;
      const y = e.clientY - editorRect.top;

      if (e.type === 'mousedown') {
        setPosS({
          x: Number(
            (
              (x /
                (imageInfo.ratio >= 1
                  ? (vh * (panelHeightVw / 100)) / imageInfo.ratio
                  : vw * (panelWidthVw / 100))) *
              imageInfo.width
            ).toFixed(),
          ),
          y: Number(
            (
              (y /
                (imageInfo.ratio >= 1
                  ? vh * (panelHeightVw / 100)
                  : vw * (panelWidthVw / 100) * imageInfo.ratio)) *
              imageInfo.height
            ).toFixed(),
          ),
        });
      }
      if (e.type === 'mousemove') {
        setPosE({
          x: Number(
            (
              (x /
                (imageInfo.ratio >= 1
                  ? (vh * (panelHeightVw / 100)) / imageInfo.ratio
                  : vw * (panelWidthVw / 100))) *
              imageInfo.width
            ).toFixed(),
          ),
          y: Number(
            (
              (y /
                (imageInfo.ratio >= 1
                  ? vh * (panelHeightVw / 100)
                  : vw * (panelWidthVw / 100) * imageInfo.ratio)) *
              imageInfo.height
            ).toFixed(),
          ),
        });
      }
    }
  };

  useEffect(() => {
    if (ctrl.props.option.path) {
      getImage();
    }

    if (ctrl.props.option.crop) {
      setPosS({ x: ctrl.props.option.crop.x1, y: ctrl.props.option.crop.y1 });
      setPosE({ x: ctrl.props.option.crop.x2, y: ctrl.props.option.crop.y2 });
    }
  }, [ctrl.id, ctrl.props.option.path]);

  useEffect(() => {
    if (!loading) {
      ctrl.props.option.crop = {
        x1: posS.x,
        y1: posS.y,
        x2: posE.x,
        y2: posE.y,
      };
      ctrl.onChange();
      const width = Math.abs(posE.x - posS.x);
      const height = Math.abs(posE.y - posS.y);
      const top = Math.min(posE.y, posS.y);
      const left = Math.min(posE.x, posS.x);
      setPosL({
        width:
          (width *
            (imageInfo.ratio >= 1
              ? (vh * (panelHeightVw / 100)) / imageInfo.ratio
              : vw * (panelWidthVw / 100))) /
          imageInfo.width,
        height:
          (height *
            (imageInfo.ratio >= 1
              ? vh * (panelHeightVw / 100)
              : vw * (panelWidthVw / 100) * imageInfo.ratio)) /
          imageInfo.height,
        left:
          (left *
            (imageInfo.ratio >= 1
              ? (vh * (panelHeightVw / 100)) / imageInfo.ratio
              : vw * (panelWidthVw / 100))) /
          imageInfo.width,
        top:
          (top *
            (imageInfo.ratio >= 1
              ? vh * (panelHeightVw / 100)
              : vw * (panelWidthVw / 100) * imageInfo.ratio)) /
          imageInfo.height,
      });
    }
  }, [posE, posS, loading, ctrl.id]);

  return (
    <>
      {loading ? (
        <p>loading...</p>
      ) : (
        <>
          <div className='side-panel-normal'>
            <p>Image Crop</p>
            <div className='imagecrop-section'>
              <div className='imagecrop-img-frame'>
                <img
                  src={`data:image/jpeg;base64,${imageUrl}`}
                  style={{ border: '2px red dotted' }}
                />
                <p>
                  {imageInfo.width} X {imageInfo.height}
                </p>
              </div>
            </div>
            <div className='imagecrop-section02'>
              <InputWithLabel
                type='number'
                label='x1'
                value={posS.x}
                onChange={(e) => {
                  setPosS({
                    x: e > imageInfo.width ? imageInfo.width : e < 0 ? 0 : e,
                    y: posS.y,
                  });
                }}
                id='crop-input-x1'
                min={0}
                max={imageInfo.width}
                className='crop-input'
              />
              <InputWithLabel
                type='number'
                label='y1'
                value={posS.y}
                onChange={(e) => {
                  setPosS({
                    y: e > imageInfo.height ? imageInfo.height : e < 0 ? 0 : e,
                    x: posS.x,
                  });
                }}
                id='crop-input-y1'
                min={0}
                max={imageInfo.height}
                className='crop-input'
              />
              <InputWithLabel
                type='number'
                label='x2'
                value={posE.x}
                onChange={(e) => {
                  setPosE({
                    x: e > imageInfo.width ? imageInfo.width : e < 0 ? 0 : e,
                    y: posE.y,
                  });
                }}
                id='crop-input-x2'
                min={0}
                max={imageInfo.width}
                className='crop-input'
              />
              <InputWithLabel
                type='number'
                label='y2'
                value={posE.y}
                onChange={(e) => {
                  setPosE({
                    y: e > imageInfo.height ? imageInfo.height : e < 0 ? 0 : e,
                    x: posE.x,
                  });
                }}
                id='crop-input-y2'
                min={0}
                max={imageInfo.height}
                className='crop-input'
              />
            </div>
          </div>
          <div className='side-panel-expand'>
            <div className='crop-expand-frame'>
              <div className='imagecrop-img-frame'>
                <div
                  style={{
                    width: imageInfo.ratio >= 1 ? `${backImgSize}vh` : `${panelWidthVw}vw`,
                    height: imageInfo.ratio >= 1 ? `${panelHeightVw}vh` : `${backImgSize}vw`,
                    backgroundImage: `url(data:image/jpeg;base64,${imageUrl})`,
                  }}
                  draggable={false}
                  id='image-editor'
                  onMouseDown={(e) => {
                    setDragging(true);
                    calculatePosition(e);
                  }}
                  onMouseUp={(e) => setDragging(false)}
                  onMouseMove={(e) => {
                    dragging && calculatePosition(e);
                  }}
                  onMouseLeave={(e) => setDragging(false)}
                >
                  {posL && (
                    <div
                      id='div-crop'
                      style={{
                        position: 'absolute',
                        border: '2px red dashed',
                        ...posL,
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
