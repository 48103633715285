import React from 'react';
import styled from 'styled-components';
import { handleImgError } from '@/utils/common/imageUtils';

const TemplateModalTitleDiv = styled.div`
  width: 100%;

  div {
    width: 100%;
    margin: var(--12px) auto;

    img {
      background-color: white;
      width: 100%;
      aspect-ratio: 2.5/1;
      object-fit: cover;
      border-radius: var(--12px);
    }
  }
`;
export default function TemplateModalTitle({ workspace }) {
  return (
    <TemplateModalTitleDiv>
      <h4 className='workspace-card-title'>
        <span className='a11y-hidden'>task-title </span>
        <strong>{workspace?.title ?? 'undefined task'}</strong>
      </h4>
      <div>
        <img
          src={`data:image/jpeg;base64,${workspace?.thumbnail}`}
          alt={workspace?.title}
          onError={handleImgError}
        />
      </div>
    </TemplateModalTitleDiv>
  );
}
