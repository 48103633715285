import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import TrainResultStepSlider from './TrainResultStepSlider';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { TrainContext } from '@/contexts/TrainContext';
import TrainResultImageItem from './TrainResultImageItem';
import { CustomThemeContext } from '@/contexts/common/Context';

const Container = styled.div<{ $theme: string }>`
  display: flex;
  flex-direction: column;
  gap: var(--24px);

  padding-left: var(--12px);

  ${(props) =>
    props.$theme === 'light'
      ? `
    border-left: 2px solid #D9D9E5;
    `
      : `
    border-left: 2px solid #1B1B47;
  `}

  div.train-result-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--8px);

    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      aspect-ratio: 1/1;

      transition: transform 0.25s ease;
      transform-origin: center center;
    }
  }
`;

export default function TrainResultImage() {
  const { theme } = useContext(CustomThemeContext);
  const [imgData, setImagData] = useState<any[]>([]);
  const [imgNum, setImgNum] = useState(0);

  const { stepIndex, imgIndex, setImgIndex, selected } =
    useContext(TrainContext);
  const api = useAPIwithCookies();

  const getImgData = async () => {
    const res = await api.get(
      `/mlops/train_session_image_num/${selected?.key}`
    );
    setImgNum(res.data);
    const { data } = await api.get(
      `/mlops/train_session_step_images/${selected?.key}/${stepIndex}/${imgIndex}`
    );
    setImagData(Object.entries(data));
    return data;
  };

  useEffect(() => {
    getImgData();
  }, [selected?.key, stepIndex, imgIndex]);

  // scale 조정
  const [scale, setScale] = useState(1);

  const imgStyle = {
    transform: `scale(${scale})`,
  };

  function handleWheel(e) {
    e.preventDefault();
    const delta = e.deltaY * -0.005;
    // 스케일 업데이트, 최소값과 최대값 설정
    setScale((scale) => Math.min(Math.max(0.5, scale + delta), 10));
  }

  useEffect(() => {
    const imgEl =
      document.querySelector('div.train-result-image') ?? document.body;
    imgEl.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      imgEl.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    setScale(1);
  }, [selected?.key]);

  return (
    <Container $theme={theme}>
      <h5>image</h5>
      <div className='train-result-image'>
        {imgData.map(([label, data]) => (
          <TrainResultImageItem label={label} data={data} imgStyle={imgStyle} />
        ))}
      </div>
      <TrainResultStepSlider
        stepIndex={imgIndex}
        setStepIndex={setImgIndex}
        imgNum={imgNum}
        label={'Image Index'}
      />
    </Container>
  );
}
