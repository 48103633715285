import React, { useEffect, useRef, useState } from 'react';
import Header from '@/components/common/layouts/header/Header';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import {
  changePassword,
  fetchMyInfo,
  sendAuthenticationCode,
  updateMyInfo,
  requestDelete,
} from '@/apis/mypage/mypageApis';

export default function Mypage(): JSX.Element {
  const api = useAPIwithCookies();

  const [job, setJob] = useState('');
  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [orgName, setOrgName] = useState('');
  const [organization, setOrganization] = useState('');
  const [purpose, setPurpose] = useState('');
  const [receiveEmail, setReceiveEmail] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');

  // 회원 정보 수정
  const [isEditing, setIsEditing] = useState(false);

  // 인증 코드 필요한 로직의 정보 수정
  const [passwordBtnVaild, setPasswordBtnVaild] = useState(true);
  const [deleteAccountBtnVaild, setDeleteAccountBtnVaild] = useState(true);

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [countdownPasswordVerification, setCountdownPasswordVerification] = useState(0);
  const timerRefPassword = useRef<NodeJS.Timeout | null>(null);

  const [showDeleteAccountForm, setShowDeleteAccountForm] = useState(false);
  const [countdownDeleteAccountVerification, setCountdownDeleteAccountVerification] = useState(0);
  const timerRefDeleteAccount = useRef<NodeJS.Timeout | null>(null);

  const [passwordVerificationCode, setPasswordVerificationCode] = useState('');
  const [deleteAccountVerificationCode, setDeleteAccountVerificationCode] = useState('');

  // 시간

  const getFetchMyPageData = async () => {
    await fetchMyInfo(
      api,
      setJob,
      setName,
      setNickName,
      setOrgName,
      setEmail,
      setOrganization,
      setPurpose,
      setReceiveEmail,
    );
  };

  useEffect(() => {
    getFetchMyPageData();
  }, []);

  // 패스워드에 대한 인증 관리
  const togglePasswordForm = async () => {
    setPasswordBtnVaild(false);
    await sendAuthenticationCode(api);
    setShowDeleteAccountForm(false); // 비번 변경시, 탈퇴폼을 닫음

    setShowPasswordForm(true);
    setPasswordBtnVaild(true);
    setCountdownPasswordVerification(180);

    if (timerRefPassword.current) {
      clearInterval(timerRefPassword.current);
    }

    startCountdownPasswordVerification();
  };

  const startCountdownPasswordVerification = () => {
    timerRefPassword.current = setInterval(() => {
      setCountdownPasswordVerification((prev) => {
        if (prev <= 1) {
          if (timerRefPassword.current) clearInterval(timerRefPassword.current);
          setShowPasswordForm(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // 회원탈퇴에 대한 인증 관리
  const toggleDeleteAccountForm = async () => {
    setDeleteAccountBtnVaild(false);
    await sendAuthenticationCode(api);

    setShowPasswordForm(false); // 비번 변경시, 탈퇴폼을 닫음

    setShowDeleteAccountForm(true); // 폼을 보여줌
    setDeleteAccountBtnVaild(true);
    setCountdownDeleteAccountVerification(180);

    if (timerRefDeleteAccount.current) {
      clearInterval(timerRefDeleteAccount.current);
    }

    startCountdownDeleteAccountVertification();
  };

  const startCountdownDeleteAccountVertification = () => {
    timerRefDeleteAccount.current = setInterval(() => {
      setCountdownDeleteAccountVerification((prev) => {
        if (prev <= 1) {
          if (timerRefDeleteAccount.current) clearInterval(timerRefDeleteAccount.current);
          setShowDeleteAccountForm(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSaveInfo = async () => {
    await updateMyInfo(
      api,
      email,
      job,
      name,
      nickName,
      orgName,
      organization,
      purpose,
      receiveEmail,
    );
    setIsEditing(false);
  };

  const handleSubmitPasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      return;
    }
    await changePassword(api, passwordVerificationCode, newPassword);
    setShowPasswordForm(false);
  };

  const handleSubmitDeleteAccount = async () => {
    await requestDelete(api, deleteAccountVerificationCode);
    setShowDeleteAccountForm(false);
  };

  // test
  return (
    <div className='bg-gray-100 dark:bg-black100'>
      <Header />
      <div
        className='flex flex-col overflow-hidden bg-gray100 px-4 py-8 dark:bg-black100 2xl:px-96'
        style={{ height: 'calc(100vh - 72px)', overflow: 'auto' }}
      >
        <h2 className='py-4 pt-12 text-[30px] font-bold dark:text-[white]'>My Page</h2>

        {/* 유저 정보 */}
        <div className='space-y-4 rounded-lg bg-white100 p-6 shadow dark:bg-black200'>
          <div>
            <h3 className='text-xl font-semibold dark:text-[white]'>👤 My Info</h3>
            <p className='mt-4 dark:text-[white]'>
              <strong>이메일:</strong> {email}
            </p>

            {isEditing ? (
              <div className='mt-4 space-y-4 rounded border-2 border-black200 p-4 dark:border-white dark:text-[white]'>
                <div>
                  <label className='mt-4 block dark:text-[white]'>직업</label>
                  <input
                    type='text'
                    value={job}
                    onChange={(e) => setJob(e.target.value)}
                    className='mt-1 w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
                  />
                </div>
                <div>
                  <label className='mt-4 block dark:text-[white]'>이름</label>
                  <input
                    type='text'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='mt-1 w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
                  />
                </div>
                <div>
                  <label className='mt-4 block dark:text-[white]'>닉네임</label>
                  <input
                    type='text'
                    value={nickName}
                    onChange={(e) => setNickName(e.target.value)}
                    className='mt-1 w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
                  />
                </div>
                <div>
                  <label className='mt-4 block dark:text-[white]'>조직 이름</label>
                  <input
                    type='text'
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    className='mt-1 w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
                  />
                </div>
                <div>
                  <label className='mt-4 block dark:text-[white]'>소속</label>
                  <input
                    type='text'
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    className='mt-1 w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
                  />
                </div>
                <div>
                  <label className='mt-4 block dark:text-[white]'>사용 목적</label>
                  <input
                    type='text'
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                    className='mt-1 w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
                  />
                </div>
                <div>
                  <label className='mt-4 block dark:text-[white]'>이메일 수신 여부</label>
                  <input
                    type='checkbox'
                    checked={receiveEmail}
                    onChange={() => setReceiveEmail((prev) => !prev)}
                    className='mt-1 size-5 cursor-pointer'
                  />
                </div>
                <button
                  onClick={handleSaveInfo}
                  className='mt-4 rounded bg-purple200 px-4 py-2 text-white hover:bg-green-600'
                >
                  저장하기
                </button>
              </div>
            ) : (
              <>
                <p className='dark:text-[white]'>
                  <strong>직업:</strong> {job}
                </p>
                <p className='dark:text-[white]'>
                  <strong>이름:</strong> {name}
                </p>
                <p className='dark:text-[white]'>
                  <strong>닉네임:</strong> {nickName}
                </p>
                <p className='dark:text-[white]'>
                  <strong>조직이름:</strong> {orgName}
                </p>
                <p className='dark:text-[white]'>
                  <strong>소속:</strong> {organization}
                </p>
                <p className='dark:text-[white]'>
                  <strong>사용 목적:</strong> {purpose}
                </p>
                <p className='dark:text-[white]'>
                  <strong>이메일 수신 여부:</strong> {receiveEmail ? 'Y' : 'N'}
                </p>

                <button
                  onClick={() => setIsEditing(true)}
                  className='mt-4 rounded bg-purple200 px-4 py-2 text-white hover:bg-[#673cbd]'
                >
                  정보 수정
                </button>
              </>
            )}
          </div>
        </div>

        {/* 비밀번호 변경 */}
        <div className='mt-8 space-y-4 rounded-lg bg-white100 p-6 shadow dark:bg-black200'>
          <h3 className='text-xl font-semibold dark:text-[white]'>🔐 Change Password</h3>
          <div>
            {passwordBtnVaild && (
              <button
                onClick={togglePasswordForm}
                className='mt-4 rounded bg-purple200 px-4 py-2 text-white hover:bg-[#673cbd]'
              >
                이메일 인증
              </button>
            )}
            {!passwordBtnVaild && (
              <button className='mt-4 flex items-center justify-center rounded bg-purple200 px-4 py-2 text-white hover:bg-[#673cbd]'>
                <svg
                  className='mr-3 size-5 animate-spin text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 00-8 8h4l-3 3-3-3h4z'
                  ></path>
                </svg>
                이메일 발송 중
              </button>
            )}
            {showPasswordForm && (
              <div className='mt-4 rounded bg-gray-100 p-4 dark:bg-black100'>
                <p className='text-red-500'>
                  인증 남은 시간: {Math.floor(countdownPasswordVerification / 60)}분{' '}
                  {countdownPasswordVerification % 60}초
                </p>
              </div>
            )}
          </div>
          {showPasswordForm && (
            <div className='mt-4 space-y-4 rounded border-2 border-black200 p-4 dark:border-white'>
              <p className='dark:text-[white]'>이메일로 전송된 인증코드를 입력하세요.</p>
              <input
                type='text'
                placeholder='인증코드 입력'
                value={passwordVerificationCode}
                onChange={(e) => setPasswordVerificationCode(e.target.value)}
                className='w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
              />
              <input
                type='password'
                placeholder='새 비밀번호'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className='w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
              />
              <input
                type='password'
                placeholder='새 비밀번호 확인'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className='w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
              />
              <button
                onClick={handleSubmitPasswordChange}
                className='mt-4 rounded bg-purple200 px-4 py-2 text-white hover:bg-[#673cbd]'
              >
                비밀번호 변경하기
              </button>
            </div>
          )}
        </div>

        {/* 회원 탈퇴 */}
        <div className='mt-8 space-y-4 rounded-lg bg-white100 p-6  shadow dark:bg-black200'>
          <div>
            <h3 className='text-xl font-semibold dark:text-[white]'>🤚 Leave Us</h3>

            {deleteAccountBtnVaild && (
              <button
                onClick={toggleDeleteAccountForm}
                className='mt-4 rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600'
              >
                이메일 인증
              </button>
            )}
            {!deleteAccountBtnVaild && (
              <button className='mt-4 flex items-center justify-center rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600'>
                <svg
                  className='mr-3 size-5 animate-spin text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 00-8 8h4l-3 3-3-3h4z'
                  ></path>
                </svg>
                이메일 발송 중
              </button>
            )}
            {showDeleteAccountForm && (
              <div className='mt-4 rounded bg-gray-100 p-4 dark:bg-black100'>
                <p className='text-red-500'>
                  인증 남은 시간: {Math.floor(countdownDeleteAccountVerification / 60)}분{' '}
                  {countdownDeleteAccountVerification % 60}초
                </p>
              </div>
            )}

            {showDeleteAccountForm && (
              <div className='mt-4 space-y-4 rounded border-2 border-black200 p-4 dark:border-white'>
                <p className='dark:text-[white]'>이메일로 전송된 인증코드를 입력하세요.</p>
                <input
                  type='text'
                  placeholder='인증코드 입력'
                  value={deleteAccountVerificationCode}
                  onChange={(e) => setDeleteAccountVerificationCode(e.target.value)}
                  className='w-full rounded-md border border-black px-4 py-2 dark:border-white dark:text-[white]'
                />
                <button
                  onClick={handleSubmitDeleteAccount}
                  className='rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600'
                >
                  회원 탈퇴하기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
