import React, { useEffect, useState } from 'react';
import { ClassicScheme, Presets, RenderEmit } from 'rete-react-plugin';
import styled from 'styled-components';

import { copyNodeKeyE, deleteNode } from '@/components/rete/reteUtils';
import { createNode } from '../../rete';

const { RefSocket, RefControl } = Presets.classic;

// rete 주변에 provider를 둘러도 context api의 값 변화를 인지하지 못하는 것 같음 => 그냥 내부적으로 상태 만들었음
export const monacoState = {
  isEnabled: false,
};

export const tabState = {
  isFocused: false,
};

export type TNodeColor = { base: string; sub1: string; sub2: string };

export const SourceColor: TNodeColor = {
  base: '#5622BF',
  sub1: '#8C60C5',
  sub2: '#6923C3',
};
export const CustomColor: TNodeColor = {
  base: '#999900',
  sub1: '#cbcb00',
  sub2: '#999900',
};
export const EffectorColor: TNodeColor = {
  base: '#4C7DCC',
  sub1: '#5C7DCC',
  sub2: '#729BFC',
};
export const SinkColor: TNodeColor = {
  base: '#DB686E',
  sub1: '#ED959A',
  sub2: '#B92931',
};
export const AddonColor: TNodeColor = {
  base: '#197C47',
  sub1: '#45D286',
  sub2: '#0B7B3F',
};
export const NodeSize = { height: 60, width: 230 };

type NodeExtraData = { color?: TNodeColor; theme: 'dark' | 'light' };

export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  background: ${(props) =>
    props.selected ? props.color?.base : props.theme === 'dark' ? '#131313' : '#F4F4FB'};
  border-radius: 20px;
  border: 2px ${(props) => props.color?.base} solid;
  width: ${NodeSize.width}px;
  height: ${NodeSize.height}px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  .title {
    width: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    color: black;
    font-family: sans-serif;
    font-size: 18px;
    text-align: left;
    padding: 8px 0px;
  }
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
  }

  .wrappers {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
  }
  .output {
    display: flex;
    align-items: center;
    transform: translateX(6px);
  }
  .input {
    display: flex;
    align-items: center;
    transform: translateX(-6px);
  }
  .output-socket {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .input-socket {
    text-align: left;
    margin-left: -1px;
    display: flex;
    align-items: center;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    margin: 10px;
    line-height: 10px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${10 + 2 * 10}px);
    vertical-align: middle;
    display: inline-block;
  }
  .control {
    height: 100%;
    width: 182px;
    display: flex;
    margin: 0 auto;
  }
  ${(props) => props.styles && props.styles(props)}
`;

function sortByIndex<T extends [string, undefined | { index?: number }][]>(entries: T) {
  entries.sort((a, b) => {
    const ai = a[1]?.index || 0;
    const bi = b[1]?.index || 0;

    return ai - bi;
  });
}

type Props<S extends ClassicScheme> = {
  data: S['Node'] & NodeExtraData;
  styles?: () => any;
  emit: RenderEmit<S>;
};
export type NodeComponent<Scheme extends ClassicScheme> = (props: Props<Scheme>) => JSX.Element;

export function CustomNode<Scheme extends ClassicScheme>(props: Props<Scheme>) {
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);

  const controls = Object.entries(props.data.controls);
  const selected = props.data.selected || false;
  const { id, color } = props.data;
  sortByIndex(inputs);
  sortByIndex(outputs);
  sortByIndex(controls);
  let theme = localStorage.getItem('theme') as 'light' | 'dark';
  if (theme === null) theme = 'dark';
  const falseCount = Object.entries(props.data.inputs).filter(
    ([key, input]) => !input?.showControl,
  ).length;

  // 삭제
  const handleKeyDownDelete = (event: KeyboardEvent) => {
    if (props.data.selected && event.key === 'Delete' && !monacoState.isEnabled) {
      deleteNode(props.data.id);
    }
  };

  // 복사
  let temp;
  const handleKeyDownCopy = (event: KeyboardEvent) => {
    if (props.data.selected && event.ctrlKey && event.key === 'c') {
      // setCopyNode(props.data.label);
      temp = props.data.label;
    }
  };

  // 붙여넣기
  const handleKeyDownPaste = async (event: KeyboardEvent) => {
    if (props.data.selected && event.ctrlKey && event.key === 'v') {
      if (!monacoState.isEnabled) {
        const nodeNum = (await copyNodeKeyE(temp)) as any;
        createNode(nodeNum, 224);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDownDelete);
    document.addEventListener('keydown', handleKeyDownCopy);
    document.addEventListener('keydown', handleKeyDownPaste);

    return () => {
      document.removeEventListener('keydown', handleKeyDownDelete);
      document.removeEventListener('keydown', handleKeyDownCopy);
      document.removeEventListener('keydown', handleKeyDownPaste);
    };
  }, [props.data.selected]);

  return (
    <NodeStyles
      selected={selected}
      color={color}
      theme={theme}
      styles={props.styles}
      data-testid='node'
      style={{
        height:
          props.data.label === 'DatasetConfiguration' ? `${60 + 20 * falseCount}px` : undefined,
      }}
    >
      {/* Inputs*/}
      {props.data.label !== 'DatasetConfiguration' && (
        <div className='wrapper' style={{ left: 0 }}>
          {inputs.map(
            ([key, input]) =>
              input && (
                <div className='input' key={key} data-testid={`input-${key}`}>
                  <RefSocket
                    name='input-socket'
                    emit={props.emit}
                    side='input'
                    socketKey={key}
                    nodeId={id}
                    payload={input.socket}
                  />
                  {input && (!input.control || !input.showControl) && (
                    <div className='input-title' data-testid='input-title'>
                      {input?.label}
                    </div>
                  )}
                  {input?.control && input?.showControl && (
                    <span className='input-control'>
                      <RefControl
                        key={key}
                        name='input-control'
                        emit={props.emit}
                        payload={input.control}
                      />
                    </span>
                  )}
                </div>
              ),
          )}
        </div>
      )}
      {/* Inputs*/}
      {props.data.label === 'DatasetConfiguration' && (
        <div className='wrappers' style={{ left: 0 }}>
          {inputs.map(([key, input], index) => {
            const InitShowCurrentInput = index === 0 || inputs[index - 1][1]?.showControl === false; // 첫번재 무조건 실행 그리고, 현재 showControl이 false 즉, 연결했으면, === true

            return (
              input &&
              InitShowCurrentInput && ( // 첫 번째 input이나 이전 input의 showControl이 false일 때만 표시
                <div className='input' key={key} data-testid={`input-${key}`}>
                  <RefSocket
                    name='input-socket'
                    emit={props.emit}
                    side='input'
                    socketKey={key}
                    nodeId={id}
                    payload={input.socket}
                  />
                  {input && (!input.control || !input.showControl) && (
                    <div className='input-title' data-testid='input-title'>
                      {input?.label}
                    </div>
                  )}
                  {input?.control && input?.showControl && (
                    <span className='input-control'>
                      <RefControl
                        key={key}
                        name='input-control'
                        emit={props.emit}
                        payload={input.control}
                      />
                    </span>
                  )}
                </div>
              )
            );
          })}
        </div>
      )}

      {/* Controls */}
      {controls.map(([key, control]) => {
        return control ? (
          <RefControl key={key} name='control' emit={props.emit} payload={control} />
        ) : null;
      })}
      <div className='wrapper' style={{ right: 0 }}>
        {/* Outputs */}
        {outputs.map(
          ([key, output]) =>
            output && (
              <div className='output' key={key} data-testid={`output-${key}`}>
                <div className='output-title' data-testid='output-title'>
                  {output?.label}
                </div>
                <RefSocket
                  name='output-socket'
                  side='output'
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
              </div>
            ),
        )}
      </div>
    </NodeStyles>
  );
}
