import './Register.scss';

import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import { Button } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import SelectBox from '../../common/SelectBox';
import { InputWithLabel, InputWithAlert } from '../../common/CustomInput';
import CustomModal from '../../common/CustomModal';
import Mode from '../../components/mode/Mode';
import { useAPIwithCookies } from '../../hooks/useApiCookies';
import useHandleGoogleLogin from '../../hooks/useHandleGoogleLogin';

type TRegisterForm = {
    email: string,
    pw: string,
    name: string,
    mem_id: string,
    receive_yn: boolean,
    organization: string,
    org_name: string,
    job: string,
    purpose: string,
    access_code: string,
}

type TOrganization = '' | 'Business & Company' | 'Medical Institution' | 'University' | 'School' | 'Individual' | 'Others';
type TJob = '' | 'Doctor' | 'Researcher' | 'Developer' | 'Student' | 'Employee' | 'Others';
type TPurpose = '' | 'Data Management' | 'Data Processing' | 'Deep Learning' | 'Benchmarking' | 'Demo';

const organizationOption: { id: string, name: TOrganization }[] = [
    { id: 'register-organization-1', name: 'Business & Company' },
    { id: 'register-organization-2', name: 'Medical Institution' },
    { id: 'register-organization-3', name: 'University' },
    { id: 'register-organization-4', name: 'School' },
    { id: 'register-organization-5', name: 'Individual' },
    { id: 'register-organization-6', name: 'Others' }
];

const occupationOption: { id: string, name: TJob }[] = [
    { id: 'register-job-1', name: 'Doctor' },
    { id: 'register-job-2', name: 'Researcher' },
    { id: 'register-job-3', name: 'Developer' },
    { id: 'register-job-4', name: 'Student' },
    { id: 'register-job-5', name: 'Employee' },
    { id: 'register-job-6', name: 'Others' }
];

const purposeOption: { id: string, name: TPurpose }[] = [
    { id: 'register-purpose-1', name: 'Data Management' },
    { id: 'register-purpose-2', name: 'Data Processing' },
    { id: 'register-purpose-3', name: 'Deep Learning' },
    { id: 'register-purpose-4', name: 'Benchmarking' },
    { id: 'register-purpose-5', name: 'Demo' }
];

export default function Register() {
    const navigate = useNavigate();
    const api = useAPIwithCookies();
    const {googleLogin} = useHandleGoogleLogin();
    
    const [step, setStep] = useState(0);
    const [name, setName] = useState<{ first: string, last: string }>({ first: '', last: '' });
    const [password, setPassword] = useState<{ pw: string, confirm: string }>({ pw: '', confirm: '' });
    const [passwordShown, setPasswordShown] = useState<{ pw: boolean, confirm: boolean }>({ pw: false, confirm: false });
    const [temp, setTemp] = useState<{ email: string, mem_id: string }>({ email: '', mem_id: '' })
    const [availableCheck, setAvailableCheck] = useState<{ email: boolean, mem_id: boolean }>({ email: false, mem_id: false });

    const [emailAlert, setEmailAlert] = useState<{ alert: boolean, alertLabel: string }>({ alert: false, alertLabel: '' });
    const [passwordAlert, setPasswordAlert] = useState<{ alert: boolean, alertLabel: string }>({ alert: false, alertLabel: '' });


    const [accessCode, setAccessCode] = useState<string>('');
    const [accessCodeAlert, setAccessCodeAlert] = useState<{ alert: boolean, alertLabel: string }>({ alert: false, alertLabel: '' });


    //Modal Handle
    const [termModalOpen, setTermModalOpen] = useState<boolean>(false);
    const [policyModalOpen, setPolicyModalOpen] = useState<boolean>(false);

    const checkRegExr = (email: string) => {
        const reg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

        return reg.test(email);
    }

    const [form, setForm] = useState<TRegisterForm>({
        email: '',
        pw: '',
        name: '',
        mem_id: '',
        receive_yn: true,
        organization: '',
        org_name: '',
        job: '',
        purpose: '',
        access_code: '',
    })

    const checkAvailability = async (type: 'email' | 'mem_id') => {
        if (type === 'email') {
            if (!checkRegExr(temp.email)) {
                setEmailAlert({ alert: true, alertLabel: 'Please re-enter your email.' })
                return;
            }
        }
        const response = await api.get('/member/available', { [type]: temp[type] });

        if (response.data === false) {
            setEmailAlert({ alert: true, alertLabel: type === 'email' ? 'Please re-enter your email.' : 'Please re-enter your nickname.' })
        }
        else {
            setAvailableCheck({ ...availableCheck, [type]: true });
            setForm({ ...form, [type]: temp[type] });
            setEmailAlert({ alert: true, alertLabel: 'Available' })
        }
    };

    // const disability = (!availableCheck.email) || (password.pw !== password.confirm) || (form.pw.length === 0);

    const register = async () => {
        try {
            await api.post('/member/join', form);
            alert("Welcome to Vience-Canvas");
            navigate('/vience-canvas/overview');
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleTermModal = () => {
        setTermModalOpen(!termModalOpen);
    }

    const handlePolicyModal = () => {
        setPolicyModalOpen(!policyModalOpen);
    }

    const handleStep = () => {
        //디자인 적용 위해 조건 검사 무시

        api.post('/member/check_access_code', { code: accessCode })
        .then(response =>{
            if(response.data==1){ // access code ok
                if (step === 0) {
                    if (temp.email.length === 0) {
                        setEmailAlert({ alert: true, alertLabel: 'Please enter your email.' });
                        return;
                    }
        
                    if (!availableCheck.email) {
                        setEmailAlert({ alert: true, alertLabel: 'Please check for a duplicate email address.' });
                        return;
                    }
        
                    if ((password.pw.length * password.confirm.length) === 0) {
                        setPasswordAlert({ alert: true, alertLabel: 'Please enter your password.' });
                        return;
                    }
        
                    if (password.pw !== password.confirm) {
                        setPasswordAlert({ alert: true, alertLabel: 'Please re-enter your password.' });
                        return;
                    }
        
        
                    setStep(1);
                }
                else if (step === 1) {
                    if (temp.mem_id.length == 0) {
                        setEmailAlert({ alert: true, alertLabel: 'Please enter your nickname.' });
                        return;
                    }
        
                    if (!availableCheck.mem_id) {
                        setEmailAlert({ alert: true, alertLabel: 'Please check for a duplicate email address.' });
                        return;
                    }
                    register();
                }
        
            }
            else{
                setAccessCodeAlert({alert: true, alertLabel: 'Please enter the verified Access Code.'});
            }


        })
        .catch(error => {
            console.error("Access Code check error");

        });
    }

    useEffect(() => {
        if (password.pw === password.confirm) setForm({ ...form, pw: password.pw })
        else setForm({ ...form, pw: '' })
    }, [password])

    useEffect(() => {
        setForm({ ...form, name: `${name.first} ${name.last}` })
    }, [name])
    
    useEffect(() => {
        setForm({ ...form, access_code: accessCode })
    }, [accessCode])

    return (
        <div id='register' className='register'>
            <Mode />
            {
                step === 0 ?
                    <div className="frame">
                        <div className='register-container'>
                            <p className="prev" onClick={() => { window.history.back() }}>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.9702 12H5.97021" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.9702 19L5.97021 12L12.9702 5" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </p>
                            <div className='titleFrame'>
                                <strong>Create your account</strong>
                                <p>
                                    Note that phone or email verfication may be required for signup.
                                    Your information will only be used to verify your identity for security purposes.
                                    <br />* The current version is in alpha. To sign up, you need a previously provided access code.
                                </p>
                            </div>

                            <form className='register-form' onSubmit={(e) => { e.preventDefault(); }}>

                                <InputWithAlert alert={accessCodeAlert.alert} alertMessage={accessCodeAlert.alertLabel} setAlert={(e) => { setAccessCodeAlert({ ...accessCodeAlert, alert: e }) }}>
                                    <img src={process.env.PUBLIC_URL + '/images/ico-key.svg'} alt="" className="" />
                                    <InputWithLabel value={accessCode} onChange={(e) => { setAccessCode(e) }}
                                        placeholder='Access Code'
                                    >
                                    </InputWithLabel>
                                </InputWithAlert>


                                <InputWithAlert alert={emailAlert.alert} alertMessage={emailAlert.alertLabel} setAlert={(e) => { setEmailAlert({ ...emailAlert, alert: e }) }}>
                                    <img src={process.env.PUBLIC_URL + '/images/ico-mail.svg'} alt="" className="" />
                                    <InputWithLabel value={temp.email} onChange={(e) => { setAvailableCheck({ ...availableCheck, email: false }); setTemp({ ...temp, email: e }) }}
                                        placeholder='Email'
                                    >
                                    </InputWithLabel>
                                    <button className="duplicate" onClick={() => { checkAvailability('email') }}>Duplicate check</button>
                                </InputWithAlert>

                                <InputWithAlert alert={passwordAlert.alert} alertMessage={passwordAlert.alertLabel} setAlert={(e) => { setPasswordAlert({ ...passwordAlert, alert: e }) }}>
                                    <img src={process.env.PUBLIC_URL + '/images/ico-pw.svg'} alt="" className="" />
                                    <InputWithLabel type={passwordShown.pw ? 'text' : 'password'} value={password.pw} onChange={(e) => { setPassword({ ...password, pw: e }) }} placeholder='Password' >
                                    </InputWithLabel>
                                    <button className="passwordShown" style={{ width: 'var(--24px)', height: 'var(--24px)', fontSize: 14 }} onClick={() => { setPasswordShown({ ...passwordShown, pw: !passwordShown.pw }) }}>
                                        <FontAwesomeIcon icon={passwordShown.pw ? faEyeSlash : faEye} />
                                    </button>
                                </InputWithAlert>

                                <InputWithAlert alert={passwordAlert.alert} alertMessage={passwordAlert.alertLabel} setAlert={(e) => { setPasswordAlert({ ...passwordAlert, alert: e }) }}>
                                    <img src={process.env.PUBLIC_URL + '/images/ico-pw.svg'} alt="" className="" />
                                    <InputWithLabel type={passwordShown.confirm ? 'text' : 'password'} value={password.confirm} onChange={(e) => { setPassword({ ...password, confirm: e }) }} placeholder='Password Confirm' >
                                    </InputWithLabel>
                                    <button className="passwordShown" style={{ width: 'var(--24px)', height: 'var(--24px)', fontSize: 14 }} onClick={() => { setPasswordShown({ ...passwordShown, confirm: !passwordShown.confirm }) }}>
                                        <FontAwesomeIcon icon={passwordShown.confirm ? faEyeSlash : faEye} />
                                    </button>
                                </InputWithAlert>

                                <Button className='primaryFill' style={{ marginTop: "var(--20px)" }} onClick={handleStep}>Continue</Button>

                                <div className='userComent'>
                                    <span>Already have an accouny?</span>
                                    <Link to="/vience-canvas/login">
                                        <strong>Sign in</strong>
                                    </Link>
                                </div>
                            </form>

                            <div className='sectionLine'>or</div>

                            {/* <div className='googleButton'>
                                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY as string}>
                                    <GoogleLogin
                                        onSuccess={(res) => { handleGoogleLogin(res) }}
                                        onError={() => { console.log('error') }}
                                    />
                                </GoogleOAuthProvider>
                            </div> */}
                            {/* <div className='google-btn' onClick={() => { googleLogin(); navigate('/vience-canvas/workspace'); }}> */}
                            <div className='google-btn'>
                                <img src={process.env.PUBLIC_URL + '/images/google-logo.svg'} alt="" />
                                <span>Sign in with Google</span>
                            </div>

                        </div>
                    </div>
                    :
                    <div className="frame">

                        <div className='register-container'>
                            <p className="prev" onClick={() => { setStep(0) }}>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.9702 12H5.97021" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.9702 19L5.97021 12L12.9702 5" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </p>
                            <div className='titleFrame'>
                                <strong>Tell us about you</strong>
                            </div>

                            <form className='register-form' onSubmit={(e) => { e.preventDefault(); }}>
                                <div className='register-info-input' >
                                    <InputWithLabel value={name.first} onChange={(e) => { setName({ ...name, first: e }) }} label='First Name' placeholder='First Name' />
                                    <InputWithLabel value={name.last} onChange={(e) => { setName({ ...name, last: e }) }} label='Last Name' placeholder='Last Name' />
                                </div>
                                <InputWithAlert alert={emailAlert.alert} alertMessage={emailAlert.alertLabel} setAlert={(e) => { setEmailAlert({ ...emailAlert, alert: e }) }}>
                                    <InputWithLabel value={temp.mem_id} onChange={(e) => { setAvailableCheck({ ...availableCheck, mem_id: false }); setTemp({ ...temp, mem_id: e }) }}
                                        placeholder='Nickname'
                                    >
                                    </InputWithLabel>
                                    <button className='duplicate' onClick={() => { checkAvailability('mem_id') }}>Duplicate check</button>
                                </InputWithAlert>

                                <div className='register-info-input register-info-frame'>
                                    <SelectBox
                                        options={organizationOption}
                                        onChange={(e) => { setForm({ ...form, organization: (e as TOrganization) }) }}
                                        containerStyle={{ width: "100%" }}
                                        children="Organization"
                                    />
                                </div>
                                <div className='register-info-input'>
                                    <InputWithLabel value={form.org_name} onChange={(e) => { setForm({ ...form, org_name: e }) }} label='Organization Name' placeholder='Organization Name' />
                                </div>
                                <div className='register-info-input register-info-frame'>
                                    <SelectBox
                                        options={occupationOption}
                                        onChange={(e) => { setForm({ ...form, job: (e as TJob) }) }}
                                        containerStyle={{ width: "100%" }}
                                        children="job"
                                    />
                                </div>
                                <div className='register-info-input register-info-frame'>
                                    <SelectBox
                                        options={purposeOption}
                                        onChange={(e) => { setForm({ ...form, purpose: (e as TPurpose) }) }}
                                        containerStyle={{ width: "100%" }}
                                        children="Purpose"
                                    />
                                </div>
                                <div className='checkboxFrame' style={{ margin: "var(--40px) 0 var(--20px)" }}>
                                    <input type='checkbox' id="receive-advertising" defaultChecked value={String(form.receive_yn)} onChange={() => { setForm({ ...form, receive_yn: !form.receive_yn }) }} />
                                    <label htmlFor='receive-advertising'>Receive advertising emails</label>
                                </div>
                                <button onClick={handleStep} className='primaryFill'>Save</button>
                            </form>
                            <div className='use-frame'>
                                <button className='normalOutline' onClick={handleTermModal}>Terms</button>
                                <button className='normalOutline' onClick={handlePolicyModal}>Privacy Policy</button>
                            </div>
                            <CustomModal open={termModalOpen} onClose={handleTermModal} bgShown='dark' bgColor='#010116'>
                                <h1 className="modalTitle" style={{ textAlign: 'center' }}>Terms of Use</h1>
                                <div className="modalContent" style={{ width: 400, height: 600 }}>
                                    제1조 (목적)
                                    ….
                                    제2조 (용어의 정의)
                                    ….
                                </div>
                                <button onClick={handleTermModal} className='primaryFill'>Confirm</button>
                            </CustomModal>
                            <CustomModal open={policyModalOpen} onClose={() => { setPolicyModalOpen(false) }}>
                                <h1 className="modalTitle" style={{ textAlign: 'center' }}>Privacy Policy</h1>
                                <div className="modalContent">
                                    바이언스(이하 ‘당사’)가 취급하는 모든 개인정보는 개인정보 보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 수집·보유·처리되고 있습니다.
                                    <br /><br />
                                    <strong>제1조. 개인정보의 처리목적</strong><br />

                                    당사는 서비스 제공 목적으로 필요에 의한 최소한으로 개인정보를 수집하고 있습니다.

                                    <br /><br />

                                    <strong>제2조. 개인정보의 처리 및 보유 기간</strong><br />

                                    - 처리하는 개인정보 항목: 이메일, 이름, 닉네임, 소속, 직업, 당사 서비스 활용 목적<br />
                                    - 보유기간: 회원 탈퇴 시까지

                                    <br /><br />

                                    <strong>제3조. 개인정보 제3자 제공에 관한 사항</strong><br />

                                    당사는 원칙적으로 정보주체의 개인정보를 수집·이용 목적으로 명시한 범위 내에서 처리하며, 정보주체의 사전 동의 없이는 제3자에게 제공하지 않습니다.<br />
                                </div>
                                <button onClick={handlePolicyModal} className='primaryFill'>Confirm</button>
                            </CustomModal>
                        </div>
                    </div>
            }

        </div>
    )
}