import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { getNameIcon, getThumbnailIcon } from '@/components/rete/utils/rete/utils';
import { CustomThemeContext } from '@/contexts/common/Context';
import { IfileNdirList } from '@/types/common/type';

interface ISelected {
  path: string;
  name: string;
  thumbnail: string;
  type: string;
  dataType?: string;
}

interface DataSelectorCardProps {
  name: string;
  path: string;
  selected: ISelected; // 전역 selected (양쪽 패널 공유)
  setSelected: Dispatch<SetStateAction<ISelected>>;
  info: IfileNdirList;
  onDirChange: (newDir: string) => void; // 디렉토리 더블클릭 시 expand panel 경로 변경 콜백
}

export default function DataSelectorCard({
  name,
  path,
  selected,
  setSelected,
  info,
  onDirChange,
}: DataSelectorCardProps) {
  const { theme } = useContext(CustomThemeContext);
  const navigate = useNavigate();

  // 스크롤 이동을 위해 div에 ref 등록: UI 이슈로 우선 roll back!
  // const cardRef = useRef<HTMLDivElement>(null);

  // ---------------------------------------------------------------------
  // single click -> setSelected
  //     expand panel에서 특정 파일/디렉토리를 클릭했을 때
  //     전역 selected가 바뀌면 => normal panel도 하이라이트가 동기화된다.
  // ---------------------------------------------------------------------
  const handleClick = () => {
    setSelected({
      path,
      name,
      thumbnail: info.thumbnail || '',
      type: info.type,
    });
  };

  // ---------------------------------------------------------------------
  // double click -> 디렉토리 이동
  //     디렉토리 계열(type === 'dir'|'dataset'|'model')이면
  //     더블클릭 시 onDirChange(newDir)를 호출, expand panel에서 path 변경
  // ---------------------------------------------------------------------
  const handleDoubleClick = () => {
    if (
      info.type === 'dir' ||
      info.type === 'dataset' ||
      info.type === 'model' ||
      info.type === 'form_output'
    ) {
      // 상위 디렉토리 이동
      if (info.name === '..') {
        // 현재 경로 path를 / 기준으로 분할해서 배열(seg)을 만든 뒤
        // 마지막 요소(지금 디렉토리 명)를 버리고(join) 나머지를 연결 → 상위 디렉토리 경로(upPath)
        const seg = path.split('/').filter(Boolean);
        const upPath = seg.slice(0, -1).join('/');
        onDirChange(upPath);
      } else {
        // 하위 디렉토리 이동
        const newPath = path ? `${path}/${name}` : name;
        onDirChange(newPath);
      }
    } else if (info.type === 'image' || info.type === 'svs') {
      // 이미지 파일이면 해당 이미지 뷰어로 이동
      const basePath = '/vience-canvas/image';
      const fullPath = path ? `${basePath}/${path}/${info.name}` : `${basePath}/${info.name}`;

      window.open(fullPath, '_blank');
    } else if (info.type === 'vmeta' || info.type === 'file' || info.type === 'weight') {
      const userEmail = localStorage.getItem('email') || '';
      navigate('/vience-canvas/codeEditor', {
        state: {
          workspace: {
            model_path: `/cloud/member/${userEmail}/${path}/${name}`,
            path: path,
            name,
          },
        },
      });
    } else {
      // 나머지 파일은 미리보기 불가
      return;
    }
  };

  // ---------------------------------------------------------------------
  // 보라색 하이라이트 여부
  //     내 전체 경로(myFullPath)와 selectedFullPath가 같으면 => selected
  // ---------------------------------------------------------------------
  const joinPath = (base: string, child: string) => {
    if (!base) return child;
    return base + '/' + child;
  };
  const myFullPath = joinPath(path, name);
  const selectedFullPath = selected.path ? `${selected.path}/${selected.name}` : selected.name;
  const isSelected = myFullPath === selectedFullPath;

  // ---------------------------------------------------------------------
  // (4) 스크롤 이동 로직: UI 이슈로 우선 roll back!
  //     isSelected가 true가 된 시점에 => scrollIntoView 로 화면 중앙에 배치
  // ---------------------------------------------------------------------
  // useEffect(() => {
  //   if (isSelected) {
  //     cardRef.current?.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'center',
  //     });
  //   }
  // }, [isSelected]);

  return (
    <div
      // ref={cardRef} // 스크롤 이동을 위한 ref
      className={`storage-card${isSelected ? '-selected' : ''}`}
      draggable={true}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      <div className='flex w-full items-center justify-between gap-[8px] px-[5px] pb-[5px]'>
        <div
          className='mb-[10px] flex items-center justify-start gap-[10px]
          text-[17px] tracking-[var(--spacingDefalt)] text-black100 dark:text-gray100'
        >
          <img
            src={getNameIcon(info.type, theme)}
            alt={`${info.type} icon`}
            className={`data-title-${info.type} size-6`}
          />
          <span className='line-clamp-1 max-w-14ch overflow-hidden text-ellipsis break-all'>
            {info.name}
          </span>
        </div>
        <div className='storage-card-title-right'>{info.updated_at.slice(0, 10)}</div>
      </div>

      <div className='storage-card-icon-frame' style={{ height: '100%' }}>
        <img
          src={getThumbnailIcon(info.type, info.thumbnail || '')}
          alt={`${info.type} thumbnail`}
          className={`${
            info.type === 'dir' || info.type === 'dataset' || info.type === 'form_output'
              ? 'w-[5.4375rem]'
              : info.type === 'image' || info.type === 'vmeta'
                ? 'h-[4.35975rem] w-[5.34375rem] rounded-custom-xl object-cover'
                : info.type === 'file'
                  ? 'w-[4.4535rem]'
                  : 'h-[4.35975rem] w-[5.34375rem] rounded-custom-xl object-cover'
          }`}
        />
      </div>
    </div>
  );
}
