import React, { useEffect, useState } from 'react';
import { ClassicPreset, NodeId } from 'rete';
import { BarChart, XAxis, YAxis, Legend, Tooltip, Bar, CartesianGrid } from 'recharts';
import { useCookies } from 'react-cookie';

import API from '@/apis/common/apis';
import { CustomSocket } from '../../../sockets';
// import { updateControl } from '@/components/rete-mlops/reteMlopsUtils';

import CustomControl from '@/components/rete-mlops/style/CustomControl';
import { NodeSize } from '@/components/rete-mlops/style';
import { SinkColor } from '../../../style/CustomNode';

export class ChartNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  Record<string, never>,
  { ctrl: ChartControl }
> {
  color = SinkColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super('Chart');
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addControl('ctrl', new ChartControl(process, nodeId ? nodeId : this.id));
  }
  data(inputs: { in: any[] }): any {
    const value = inputs['in'][0];
    const keys = Object.keys(value.img_paths);
    if (
      this.controls.ctrl.props.path1 !== value.img_paths[keys[0]] ||
      this.controls.ctrl.props.path2 !== value.img_paths[keys[1]]
    ) {
      this.controls.ctrl.setValue(value.img_paths[keys[0]], value.img_paths[keys[1]]);
    }
  }
}

export class ChartControl extends ClassicPreset.Control {
  props: {
    path1: string;
    path2: string;
  };
  contextOpen: boolean;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      path1: '',
      path2: '',
    };
    this.contextOpen = false;
  }

  setValue(path1: string, path2: string) {
    this.props = {
      path1: path1,
      path2: path2,
    };
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };
}

export function ChartComp({ data }: { data: ChartControl }) {
  return (
    <CustomControl
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
      label='Chart'
      nodeId={data.nodeId}
      markerSource={'node-sink'}
      iconSource='chart'
    />
  );
}

export function ChartPanel({ ctrl }: { ctrl: ChartControl }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(['refresh']);
  const api = new API(cookies);
  const [features, setFeatures] = useState<any[]>([]);

  const getFeature = async () => {
    setLoading(true);
    const response = await api.get('/feature', {
      path1: ctrl.props.path1,
      path2: ctrl.props.path2,
    });
    setFeatures(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getFeature();
  }, [ctrl.props]);

  return (
    <div>
      {loading ? (
        <p>loading...</p>
      ) : (
        <div>
          <BarChart
            width={500}
            height={300}
            data={features}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={20}
          >
            <XAxis dataKey='name' scale='band' padding={{ left: 10, right: 10 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray='3 3' />
            <Bar dataKey='value' fill='#8884d8' background={{ fill: '#eee' }} />
          </BarChart>
        </div>
      )}
    </div>
  );
}
