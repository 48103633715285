import React, { useContext, useEffect, useState } from 'react';
import './storage.styles.scss';
import { CustomThemeContext } from '../../../utils/Context';
import { useAPIwithCookies } from '../../../hooks/useApiCookies';
import { useNavigate } from 'react-router-dom';

export default function StorageProcessing() {
  const { theme } = useContext(CustomThemeContext);
  const [tagList, setTagList] = useState<any>([]);
  const [taskList, setTaskList] = useState<any>([]);
  const [showTag, setShowTag] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [selectedCategory, setSelectedCategory] = useState<string>('Tag'); // 이거 나중에 기본 값으로 적용 필요
  const [statusFilter, setStatusFilter] = useState('Status'); // 이거 나중에 기본 값으로 적용 필요
  const [loadingStatus, setLoadingStatus] = useState(false);

  const api = useAPIwithCookies();
  const navigate = useNavigate();

  // 태그 리스트 받아오기
  useEffect(() => {
    const getFetchTagList = async () => {
      const response = await api.get('/tag/tag_list');
      console.log('태그 리스트', response.data);

      setTagList(response.data);
    };

    getFetchTagList();
  }, []);

  // 전체, 검색, 카테고리 화 리스트
  useEffect(() => {
    const getAllTaskList = async () => {
      const keyword = searchInput || '';
      const category = selectedCategory === 'Tag' ? '' : selectedCategory;
      const status = statusFilter === 'Status' ? '' : statusFilter;

      console.log(
        '검색이나 카테고리에 변화가 있을때',
        category,
        status,
        keyword
      );

      try {
        const params = new URLSearchParams();
        if (keyword) params.append('search_keywork', keyword);
        if (category) params.append('search_tag_name', category);
        if (status) params.append('search_task_status', status);

        const response = await api.get(
          `/scheduler/task_list?${params.toString()}`
        );
        console.log(response);

        console.log('받아온 리스트', response.data);

        const tasksWithKeys = Object.entries(response.data).map(
          ([key, task]) => {
            if (typeof task === 'object' && task !== null) {
              return {
                id: key,
                ...task,
              };
            } else {
              console.error(`Invalid task data for key ${key}:`, task);
              return { id: key };
            }
          }
        );

        setTaskList(tasksWithKeys);
      } catch (error) {
        console.error('Error fetching task list', error);
      }
    };

    getAllTaskList();

    const intervalId = setInterval(getAllTaskList, 5000);

    return () => clearInterval(intervalId);
  }, [selectedCategory, statusFilter, searchInput]);

  // 입력 추적
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  // 테그 선택 카테고리 + 동일상태 클릭시 초기화
  const handleSelectCategory = (tag: string) => {
    setSelectedCategory(tag === selectedCategory ? 'Tag' : tag); // 동일 상태 클릭 시 초기화
  };
  //카테고리 적용 + 동일상태 클릭시 초기화
  const handleStatusChange = (status: string) => {
    setStatusFilter(status === statusFilter ? 'Status' : status); // 동일 상태 클릭 시 초기화
  };

  // 태그 토글형식으로 바꾸기
  const handleClickTag = () => setShowTag(!showTag);
  const handleClickStatus = () => setShowStatus(!showStatus);

  // 테스크 뷰어
  const handleViewerTask = async (item: any) => {
    setLoadingStatus(true); // 로딩 상태 설정
    try {
      const response = await api.get(`/scheduler/${item}/img.dzi`);
      if (response.status === 200) {
        navigate(`/vience-canvas/scheduler/${item}/img.dzi`);
      }
    } catch (error) {
      console.error('Error viewing task', error);
    }
    setLoadingStatus(false); // 로딩 상태 해제
  };

  // 테스크 다운로드
  const handleDownloadTask = async (item: any) => {
    setLoadingStatus(true); // 로딩 상태 설정
    try {
      const response = await api.get(`/scheduler/file/${item}`);
      if (response.status === 200) {
        const url = `${process.env.REACT_APP_VIENCE_API_KEY}/scheduler/file_download/${response.data}`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file'); // 다운로드할 파일 이름 지정
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error('Error downloading task', error);
    }
    setLoadingStatus(false); // 로딩 상태 해제
  };

  console.log('최종적으로 받은 값', taskList);
  console.log('태그 리스트', tagList);
  return (
    <div id='storage-grid-processing-container'>
      <div id='storage-grid-processing-container-top'>
        <h2 style={{ fontSize: '25px' }}>Search Progress</h2>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <div id='storage-processing-input-container'>
            <button>
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/ico-search.svg'
                    : '/images/ico-search-white.svg'
                }
                alt=''
              />
            </button>
            <input
              type='text'
              placeholder='Search...'
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>

          {/* 카테고리 */}
          <div className='filter-frame'>
            <button type='button' onClick={handleClickTag}>
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/ico-filter.svg'
                    : '/images/ico-filter-white.svg'
                }
                alt=''
              />
              <span>{selectedCategory}</span>
            </button>
            {showTag && (
              <div
                className='progress-category-content-frame'
                onMouseLeave={() => setShowTag(!showTag)}
              >
                <ul>
                  {tagList.map((tag, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelectCategory(tag.title)}
                    >
                      <div
                        style={{
                          background: tag.color,
                        }}
                      ></div>
                      <span>{tag.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {/* 상태 필터 */}
          <div className='filter-frame'>
            <button type='button' onClick={handleClickStatus}>
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/ico-filter.svg'
                    : '/images/ico-filter-white.svg'
                }
                alt=''
              />
              <span>{statusFilter}</span>
            </button>
            {showStatus && (
              <div
                className='progress-category-content-frame'
                onMouseLeave={() => setShowStatus(!showStatus)}
              >
                <ul>
                  <li onClick={() => handleStatusChange('complete')}>
                    <img
                      src='/images/done.svg'
                      alt=''
                      style={{ width: '13px' }}
                    />
                    <span style={{ width: '50px' }}>Complete</span>
                  </li>
                  <li onClick={() => handleStatusChange('wait')}>
                    <img
                      src='/images/done-white.svg'
                      alt=''
                      style={{ width: '13px' }}
                    />
                    <span style={{ width: '50px' }}>Wait</span>
                  </li>
                  <li onClick={() => handleStatusChange('deleted')}>
                    <img
                      src='/images/delete3.svg'
                      alt=''
                      style={{ width: '13px' }}
                    />
                    <span style={{ width: '50px' }}>Deleted</span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div id='storage-grid-processing-container-bottom'>
        {/* 리스트 렌더링 */}
        <div id='storage-grid-processing-header'>
          <div className='list-header'>Tag</div>
          <div className='list-header'>Pipe</div>
          <div className='list-header'>File Name</div>
          <div className='list-header'>Rule</div>
          <div className='list-header'>Progress</div>
          <div className='list-header'>Status</div>
          <div className='list-header'>Viewer</div>
          <div className='list-header'>Download</div>
        </div>
        <div className='storage-grid-items'>
          {taskList.map((task, index) => (
            <div
              key={index}
              className='storage-grid-item'
              style={{
                borderLeft: `10px solid ${task.color || '#00ff95'}`,
                opacity: loadingStatus ? 0.5 : 1,
                pointerEvents: loadingStatus ? 'none' : 'auto',
              }}
            >
              <div className='list-item tag'>{task.tag}</div>
              <div className='list-item pipe'>{task.pipe}</div>
              <div className='list-item file-name'>{task.file_name}</div>
              <div className='list-item rule'>
                {task.rule ? Object.values(task.rule).join(' | ') : 'No rule name'}
              </div>
              <div className='list-item progress'>
                <div id='progress-container'>
                  <div
                    id='progress-bar'
                    style={{
                      width: `${task.progress}%`,
                      background: task.color || '#00ff95',
                    }}
                  ></div>
                </div>
                <div
                  id='progress-value'
                  style={{ color: task.color || '#00ff95' }}
                >
                  {task.progress}%
                </div>
              </div>
              <div className='list-item status'>
                {task.status === 'complete' && (
                  <img src='/images/done.svg' alt='Status Icon' />
                )}
                {task.status === 'wait' && (
                  <img src='/images/done-white.svg' alt='' />
                )}
                {task.status === 'deleted' && (
                  <img src='/images/delete3.svg' alt='' />
                )}
              </div>
              {!loadingStatus && (
                <div className='list-item viewer'>
                  <img
                    src={
                      theme === 'dark'
                        ? '/images/play.svg'
                        : '/images/play-white.svg'
                    }
                    alt='Viewer Icon'
                    onClick={() => handleViewerTask(task.id)}
                  />
                </div>
              )}
              {!loadingStatus && (
                <div className='list-item download'>
                  <img
                    src={
                      theme === 'dark'
                        ? '/images/download-cloud.svg'
                        : '/images/download-cloud-white.svg'
                    }
                    alt='Download Icon'
                    onClick={() => handleDownloadTask(task.id)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
