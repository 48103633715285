import { MenuItemProps, MenuProps } from '@/types/common/type';

import React, { useState } from 'react';

export default function Menu({ event, functions, onClose, containerId }: MenuProps) {
  if (!event) return null;
  const { pageX, pageY } = event;
  const gridDOM = document.getElementById(containerId);
  const gridDOM_pos = gridDOM?.getBoundingClientRect();
  if (gridDOM_pos) {
    const menuWidth = 200;
    const isRightSide = pageX > window.innerWidth * 0.5;

    return (
      <div
        className='absolute w-[150px] text-[15px]'
        style={{
          top: pageY - gridDOM_pos.top,
          left: isRightSide
            ? pageX - gridDOM_pos.left - menuWidth
            : pageX - gridDOM_pos.left,
          }}
      >
        {functions.map((data, index) => (
          <MenuItem
            key={index}
            index={index}
            sub={data.sub}
            data={data}
            onClose={onClose}
            isRightSide={isRightSide}
          />
        ))}
      </div>
    );
  }
  return null;
}

function MenuItem({ sub, data, onClose, index, isRightSide }: MenuItemProps) {
  const [subOpen, setSubOpen] = useState<boolean>(false);

  return (
    <div
      className='m-0 h-[40px] cursor-pointer
      border-x border-gray200 bg-white100
        p-[var(--12px)_var(--10px)_var(--38px)_var(--20px)]
        font-normal tracking-[var(--spacingDefalt)] text-black
        first:rounded-t-custom-lg first:border-x first:border-t
        last:rounded-b-custom-lg last:border-x last:border-b
      hover:bg-gray100 dark:border-black100 dark:bg-black200 dark:text-white100
      dark:hover:bg-gray300'
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation();
        if (sub) {
          setSubOpen(true);
        } else {
          data.on?.();
          onClose(false);
        }
      }}
    >
      {data.label}
      {subOpen && (
        <div
          className='absolute left-[10px] w-[160px] p-[7px] py-[15px]'
          style={{
            top: index * 35,
            left: isRightSide ? -155 : 155,
          }}
        >
          {sub?.map((subData, subIndex) => (
            <MenuItem
              key={subIndex}
              index={subIndex}
              sub={subData.sub}
              data={subData}
              onClose={onClose}
              isRightSide={isRightSide}
            />
          ))}
        </div>
      )}
    </div>
  );
}
