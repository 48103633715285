import '@/styles/datahub/datahub.styles.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomThemeContext } from '@/contexts/common/Context';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

import {
  handleInputChange,
  handleSelectCategory,
  handleStatusChange,
  handleToggleState,
} from '@/utils/datahub/handlers';
// ** 베타테스트 배포를 위해 태그 관련 기능은 임시 주석 처리
// import { downloadTask, fetchTaskList, viewTask } from '@/apis/datahub/taskApis';
// import { fetchTagList } from '@/apis/datahub/tagApis';
import { Tag, Task } from '@/types/datahub/type';

export default function DataHubProgress() {
  const api = useAPIwithCookies();
  const { theme } = useContext(CustomThemeContext);
  const navigate = useNavigate();

  const [tagList, setTagList] = useState<Tag[]>([]);
  const [taskList, setTaskList] = useState<Task[]>([]);
  const [showTag, setShowTag] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [selectedCategory, setSelectedCategory] = useState<string>('Tag');
  const [statusFilter, setStatusFilter] = useState('Status');
  const [loadingStatus, setLoadingStatus] = useState(false);

  const progressHeaderContent = [
    { label: 'Tag', width: '15%' },
    { label: 'Pipe', width: '15%' },
    { label: 'File Name', width: '15%' },
    { label: 'Rule', width: '15%' },
    { label: 'Progress', width: '25%' },
    { label: 'Status', width: '5%' },
    { label: 'Viewer', width: '5%' },
    { label: 'Download', width: '5%' },
  ];

  // 태그 리스트 불러오기
  // const getFetchTagList = async () => {
  //   await fetchTagList(api, setTagList);
  // };

  // 태그 리스트 불러오기 마운트트
  // useEffect(() => {
  //   getFetchTagList();
  // }, []);

  // task 리스트 불러오기(전체, 검색, 카테고리에 따라서)
  // const fetchTaskListData = async () => {
  //   const tasks = (await fetchTaskList(api, searchInput, selectedCategory, statusFilter)) as Task[];
  //   setTaskList(tasks);
  // };

  // task 리스트 불러오기(전체, 검색, 카테고리에 따라서) 마운트
  // useEffect(() => {
  //   fetchTaskListData();

  //   const intervalId = setInterval(fetchTaskListData, 5000);

  //   return () => clearInterval(intervalId);
  // }, [selectedCategory, statusFilter, searchInput]);

  // 테스크 뷰어
  // const handleViewerTask = async (item: string) => {
  //   await viewTask(api, item, setLoadingStatus, navigate);
  // };

  // 테스크 다운로드
  // const handleDownloadTask = async (item: string) => {
  //   await downloadTask(api, item, setLoadingStatus);
  // };

  return (
    <div className='flex h-[calc(100vh-4.5rem)] flex-col'>
      <div>
        <h2 className='text-[20px] font-semibold text-white'>Search Progress</h2>
        <div className='mt-2 flex'>
          <div className='flex w-1/5 rounded-custom-xl border border-black300 dark:border-gray300'>
            <button className='h-full w-[15%] rounded-l-custom-xl p-[10px]'>
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/ico-search.svg'
                    : '/images/ico-search-white.svg'
                }
                alt=''
              />
            </button>
            <input
              type='text'
              placeholder='Search...'
              value={searchInput}
              onChange={handleInputChange(setSearchInput)}
              className='h-full w-[85%] rounded-r-custom-xl font-bold dark:text-white100'
            />
          </div>

          {/* 카테고리 */}
          <div className='text-white'>
            <div
              onClick={handleToggleState(setShowTag)}
              className='ml-[15px] flex h-full w-[150px] items-center justify-center
                rounded-custom-xl border border-black300 px-[20px] dark:border-gray300'
            >
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/ico-filter.svg'
                    : '/images/ico-filter-white.svg'
                }
                alt=''
              />
              <span className='ml-[5px]'>{selectedCategory}</span>
            </div>
            {showTag && (
              <div
                className='absolute z-[999] ml-[15px] border border-black300 dark:border-gray300'
                onMouseLeave={() => setShowTag(!showTag)}
              >
                <ul className='w-[150px] bg-[#F0F2FE] dark:bg-black100'>
                  {tagList.map((tag, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelectCategory(setSelectedCategory)(tag.title)}
                      className='flex items-center justify-center'
                    >
                      <div
                        className='size-[11px] rounded-full'
                        style={{ background: tag.color }}
                      ></div>
                      <span className='w-[50px] p-[10px]'>{tag.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* 상태 필터 */}
          <div className='text-white'>
            <div
              onClick={handleToggleState(setShowStatus)}
              className='ml-[15px] flex h-full w-[150px] items-center justify-center
                rounded-custom-xl border border-black300 px-[20px] dark:border-gray300'
            >
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/ico-filter.svg'
                    : '/images/ico-filter-white.svg'
                }
                alt=''
              />
              <span className='ml-[5px]'>{statusFilter}</span>
            </div>
            {showStatus && (
              <div
                className='absolute z-[999] ml-[15px] border border-black300 dark:border-gray300'
                onMouseLeave={() => setShowStatus(!showStatus)}
              >
                <ul className='w-[150px] bg-[#F0F2FE] dark:bg-black100'>
                  <li
                    onClick={() => handleStatusChange(setStatusFilter)('complete')}
                    className='flex items-center justify-center'
                  >
                    <img src='/images/done.svg' alt='' className='w-[13px]' />
                    <span className='w-[50px] p-[10px]'>Complete</span>
                  </li>
                  <li
                    onClick={() => handleStatusChange(setStatusFilter)('wait')}
                    className='flex items-center justify-center'
                  >
                    <img src='/images/done-white.svg' alt='' className='w-[13px]' />
                    <span className='w-[50px] p-[10px]'>Wait</span>
                  </li>
                  <li
                    onClick={() => handleStatusChange(setStatusFilter)('deleted')}
                    className='flex items-center justify-center'
                  >
                    <img src='/images/delete3.svg' alt='' className='w-[13px]' />
                    <span className='w-[50px] p-[10px]'>Deleted</span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='mt-10 grow overflow-y-auto'>
        {/* 리스트 렌더링 */}
        <div
          className='sticky top-0 z-10 mb-[10px] flex
          bg-white100 p-[10px] text-white dark:bg-black200'
        >
          {progressHeaderContent.map((header, index) => (
            <div
              key={index}
              className={`border-r-2 border-black300 text-center dark:border-white`}
              style={{ width: header.width }}
            >
              {header.label}
            </div>
          ))}
        </div>
        <div>
          {taskList.map((task, index) => (
            <div
              key={index}
              className='mb-[20px] box-border
              flex h-[70px] items-center rounded-custom-xl
                border-r-[10px] border-gray100 bg-gray100
                text-white dark:border-r-black100 dark:bg-black100'
              style={{
                borderLeft: `10px solid ${task.color || '#00ff95'}`,
                opacity: loadingStatus ? 0.5 : 1,
                pointerEvents: loadingStatus ? 'none' : 'auto',
              }}
            >
              <div className='flex w-[15%] items-center justify-center text-center'>
                {task.tag}
              </div>
              <div className='flex w-[15%] items-center justify-center text-center'>
                {task.pipe}
              </div>
              <div className='flex w-[15%] items-center justify-center text-center'>
                {task.file_name}
              </div>
              <div className='flex w-[15%] items-center justify-center text-center'>
                {task.rule ? Object.values(task.rule).join(' | ') : 'No rule name'}
              </div>
              <div className='flex w-1/4 items-center justify-evenly'>
                <div className='w-[70%] rounded-custom-xl bg-white100 dark:bg-black200'>
                  <div
                    className='h-[30px] w-3/5 rounded-custom-xl text-center
                      leading-[30px] transition-all duration-[1.5s] ease-linear'
                    style={{
                      width: `${task.progress}%`,
                      background: task.color || '#00ff95',
                    }}
                  ></div>
                </div>
                <div className='font-bold' style={{ color: task.color || '#00ff95' }}>
                  {task.progress}%
                </div>
              </div>
              <div className='flex w-[5%] items-center justify-center text-center'>
                {task.status === 'complete' && <img src='/images/done.svg' alt='Status Icon' />}
                {task.status === 'wait' && <img src='/images/done-white.svg' alt='' />}
                {task.status === 'deleted' && <img src='/images/delete3.svg' alt='' />}
              </div>
              {!loadingStatus && (
                <div className='flex w-[5%] items-center justify-center text-center'>
                  <img
                    src={theme === 'dark' ? '/images/play.svg' : '/images/play-white.svg'}
                    alt='Viewer Icon'
                    className='cursor-pointer transition-transform
                      duration-[0.3s] ease-in-out hover:scale-[1.2]'
                    // onClick={() => handleViewerTask(task.id)}
                  />
                </div>
              )}
              {!loadingStatus && (
                <div className='flex w-[5%] items-center justify-center text-center'>
                  <img
                    src={
                      theme === 'dark'
                        ? '/images/download-cloud.svg'
                        : '/images/download-cloud-white.svg'
                    }
                    alt='Download Icon'
                    className='cursor-pointer transition-transform
                      duration-[0.3s] ease-in-out hover:scale-[1.2]'
                    // onClick={() => handleDownloadTask(task.id)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
