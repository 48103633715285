import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import API from '@/apis/common/apis';
import { CustomThemeContext } from '@/contexts/common/Context';
import { IfileNdirList } from '@/types/common/type';
import CustomProcessingFile from './CustomProcessingFile';

interface DirectoryProps {
  name: string;
  path: string;
  depth: number;
  dataType: string;
  selected?: {
    path: string;
    name: string;
    thumbnail: string;
    dataType: any;
  };
  info?: IfileNdirList;
  curDir: string;
  setCurDir: Dispatch<SetStateAction<string>>;

  setSelected: Dispatch<
    SetStateAction<{
      path: string;
      name: string;
      thumbnail: string;
      dataType: any;
    }>
  >;

  fileAttribute: any;
  setFileAttribute: any;
}

export default function CustomProcessingDirectory({
  name,
  path,
  depth,
  selected,
  dataType,
  info,
  curDir,
  setCurDir,
  setSelected,
  fileAttribute,
  setFileAttribute,
}: DirectoryProps) {
  const [cookies, setCookie, removeCookie] = useCookies(['refresh']);
  const [listOpen, setListOpen] = useState<boolean>(false);
  const api = new API(cookies);
  const [fileNdirList, setFileNdirList] = useState<IfileNdirList[]>([]);
  const inputDir = `${path}/${name}`;
  const [bgColor, setBgColor] = useState<string>();
  const { theme } = useContext(CustomThemeContext);
  const handleListOpen = () => {
    setCurDir(inputDir);

    setListOpen(!listOpen);
    setSelected({
      path: path,
      name: name,
      thumbnail: name,
      dataType: dataType,
    });
  };

  const getFileDirList = async () => {
    try {
      const response = await api.get('/cloud/list', { cur_dir: inputDir });
      setFileNdirList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (listOpen) getFileDirList();
  }, [listOpen, curDir]);

  useEffect(() => {
    const selectedPath = `${selected?.path}/${selected?.name}`;

    if (selectedPath !== '' && selectedPath === inputDir) {
      setBgColor(theme === 'dark' ? '#181837' : '#E3e3F1');
    } else {
      setBgColor('');
    }
  }, [theme, selected]);

  return (
    <li style={{ marginLeft: depth !== 0 ? 26 : 0, backgroundColor: bgColor }}>
      <div
        className={`depth-directory flex rounded-[5px] py-3 mt-[4px] 
    ${listOpen ? 'bg-[#8B51FF]' : 'dark:bg-[#131313] bg-[white]'} 
    text-[black] dark:text-[white] dark:bg-[#131313]`}
        onClick={handleListOpen}
      >
        {dataType === 'dir' && (
          <img
            src={
              process.env.PUBLIC_URL +
              (listOpen
                ? '/images/datamanage-icon-arrow-open.svg'
                : '/images/datamanage-icon-arrow-close.svg')
            }
            alt=''
          />
        )}
        <div className='datamanage-file-name'>
          {dataType === 'dir' && (
            <img src={process.env.PUBLIC_URL + '/images/datamanage-icon-folder.svg'} alt='' />
          )}
          {dataType !== 'dir' && (
            <img
              src={process.env.PUBLIC_URL + '/images/datamanage-icon-file.svg'}
              alt=''
              style={{ marginLeft: '5px' }}
            />
          )}

          <span
            style={{
              color: listOpen ? 'white' : '',
            }}
          >
            {name}
          </span>
        </div>
      </div>
      {listOpen && (
        <ul>
          {fileNdirList.map((data) => {
            if (data.type === 'dataset' || data.type === 'dir' || data.type === 'model')
              return (
                <CustomProcessingDirectory
                  depth={depth + 1}
                  name={data.name}
                  path={inputDir}
                  selected={selected}
                  setSelected={setSelected}
                  dataType={data.type}
                  curDir={curDir}
                  setCurDir={setCurDir}
                  fileAttribute={undefined}
                  setFileAttribute={undefined}
                />
              );
            else
              return (
                <CustomProcessingFile
                  key={data.name}
                  thumbnail={data.thumbnail}
                  path={inputDir}
                  depth={depth + 1}
                  name={data.name}
                  selected={selected}
                  setSelected={setSelected}
                  curDir={curDir}
                  setCurDir={setCurDir}
                  fileAttribute={fileAttribute}
                  setFileAttribute={setFileAttribute}
                />
              );
          })}
        </ul>
      )}
    </li>
  );
}
