import { useEffect } from 'react';
import { Location } from '@/types/common/type';
import useDataHubStore from '@/stores/datahub/useDataHubStore';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

/**
 * 라우팅 경로와 `curDir` 상태를 동기화하고 디렉토리 데이터를 갱신하는 커스텀 훅
 *
 * @param {Location} location - 현재 라우팅 위치 객체
 */
export const useSyncPath = (location: Location) => {
  const api = useAPIwithCookies();
  const { curDir, setCurDir, setSelectOpen, getDirectory } = useDataHubStore();
  
  useEffect(() => {
    if (!location.pathname.includes('/vience-canvas/datahub')) {
      setSelectOpen(false);
      getDirectory(api);
      // getDirectory();

      return;
    }

    const trimmedPath = decodeURIComponent(
      location.pathname.replace(/^\/vience-canvas\/datahub\/?/, '')
    );

    if (trimmedPath === curDir) {
      setCurDir(trimmedPath);
    }

    setSelectOpen(false);
    getDirectory(trimmedPath, api);

    // getDirectory(trimmedPath);
  }, [location.pathname, curDir, setCurDir]);
};
