import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import TemplateModalTitle from './TemplateModalTitle';
import TemplateModalDesc from './TemplateModalDesc';
import ReactDOM from 'react-dom';
import { CustomThemeContext } from '@/contexts/common/Context';
import PrimaryButton from '@/components/common/buttons/PrimaryButton';

const TemplateModalDiv = styled.div`
  &#template-modal-div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--8px);

    width: 30%;

    border-radius: 16px;
    ${(props) =>
      props.$theme === 'light'
        ? `
      background-color: #D9D9E5;
      color: #000000;
      `
        : `
      background-color: #131336;
      color: #ffffff;
    `};

    box-shadow: 4px 4px 32px #00000060;

    padding: var(--24px);
  }

  button {
    width: 100%;
    margin: var(--24px) 0 0;
    transition: all 0.3s;
  }
`;

const Dim = styled.div`
  top: 0;
  left: 0;

  z-index: 100;
  position: fixed;

  width: 100vw;
  height: 100vh;
  background-color: #00000090;
`;

export default function TemplateModal({ workspace, setTemplateModalOpen }) {
  const navigate = useNavigate();
  const { theme } = useContext(CustomThemeContext);

  return ReactDOM.createPortal(
    <Dim
      onClick={() => setTemplateModalOpen(null)}
      onContextMenu={(e) => e.preventDefault()}
    >
      <TemplateModalDiv
        id='template-modal-div'
        onClick={(e) => e.stopPropagation()}
        $theme={theme}
      >
        <h3 className='a11y-hidden'>TemplateModal</h3>

        <TemplateModalTitle workspace={workspace} />
        <TemplateModalDesc workspace={workspace} />

        <PrimaryButton
          type='button'
          onClick={() => {
            navigate('/vience-canvas/mlops/template', { state: { workspace } });
          }}
        >
          Start with this Template!
        </PrimaryButton>
      </TemplateModalDiv>
    </Dim>,
    document.body,
    'template-modal'
  );
}
