import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { AxiosResponse } from 'axios';

/**
 * 파일을 청크 단위로 업로드하는 함수
 *
 * @param {Object} params - 함수에 필요한 매개변수들
 * @param {File} params.file - 업로드할 파일 객체
 * @param {string} params.path - 업로드 경로
 * @param {Promise<ReturnType<typeof useAPIwithCookies> | void>[]} params.promise -
 * 청크 업로드의 Promise 배열
 * @param {Object} params.api - API 호출을 처리하는 객체
 * @param {Function} params.api.post - POST 요청을 보내는 함수
 * @param {Function} params.onProgress - 업로드 진행 상황을 처리하는 콜백 함수
 */
export const uploadChunkFile = (
  file: File,
  curDir: string,
  promises: Promise<AxiosResponse<{ filename: string }>>[],
  api: ReturnType<typeof useAPIwithCookies>,
  onProgress: (res: AxiosResponse<{ filename: string; progress: number }>) => void,
) => {
  const CHUNK_SIZE = 10 * 1024 * 1024; // CHUNK_SIZE 10Mb
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

  for (let idx = 0; idx < totalChunks; idx++) {
    const start = idx * CHUNK_SIZE;
    const end = Math.min(file.size, start + CHUNK_SIZE);
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append('filename', file.name);
    formData.append('path', curDir);
    formData.append('upfile', chunk);
    formData.append('idx', `${idx}`);
    formData.append('total', `${totalChunks}`);

    const url = '/cloud/chunk';

    const response = api
      .post(url, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res: AxiosResponse<{ filename: string; progress: number }>) => {
        res.data.filename = 'Data Uploading: ' + res.data.filename;
        res.data.progress = (idx + 1) / totalChunks * 100;
        if (onProgress) onProgress(res);
        return res;
      });

    promises.push(response);
  }
};
