import React, { useState, useEffect, useContext, Dispatch, SetStateAction, useRef } from 'react';
import { CustomThemeContext } from '@/contexts/common/Context';
import { getNameIcon } from '@/components/rete/utils/rete/utils';

interface ISelected {
  path: string;
  name: string;
  thumbnail: string;
  type: string;
  dataType?: string;
}

interface FileProps {
  name: string;
  path: string;
  depth: number;
  dataType: string;
  thumbnail: string;
  selected: ISelected;
  setSelected: Dispatch<SetStateAction<ISelected>>;
}

export default function File({
  name,
  path,
  depth,
  dataType,
  thumbnail,
  selected,
  setSelected,
}: FileProps) {
  const { theme } = useContext(CustomThemeContext);

  const [bgColor, setBgColor] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  // 파일의 전체 경로: path + '/' + name (e.g. 'png상위/png하위/1.png')
  function joinPath(base: string, child: string) {
    if (!base) return child;
    return base + '/' + child;
  }
  const myFullPath = joinPath(path, name);

  // ---------------------------------------------------------
  // 파일 클릭 시 => setSelected로 전역 selected 업데이트
  //  - expand panel도 함께 공유하는 selected를 쓰므로 한쪽에서 바꾸면 다른 쪽 패널도 선택이 동기화됨
  // ---------------------------------------------------------
  const handleFileClick = () => {
    setSelected({
      path,
      name,
      thumbnail,
      type: dataType,
    });
  };

  // 보라색 하이라이트: selected.path/name와 일치하면 bgColor 적용
  useEffect(() => {
    if (!selected.name) {
      setBgColor('');
      return;
    }
    const selFullPath = selected.path ? `${selected.path}/${selected.name}` : selected.name;
    if (selFullPath === myFullPath) {
      // 현재 파일이 선택됨
      setBgColor('#8B51FF');
    } else {
      setBgColor('');
    }
  }, [selected, theme, myFullPath]);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [name]);

  return (
    <li style={{ marginLeft: depth !== 0 ? 20 : 0 }}>
      <div className='depth-file' onClick={handleFileClick} style={{ backgroundColor: bgColor }}>
        <div className='datamanage-file-name'>
          <img
            src={getNameIcon(dataType, theme)}
            alt={`${dataType} icon`}
            className='ml-1 size-4'
          />
          <span
            ref={textRef}
            onMouseEnter={() => isOverflow && setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            className='file-name'
          >
            {name}
          </span>
          {showTooltip && <div className='tooltip'>{name}</div>}
        </div>
      </div>
    </li>
  );
}
