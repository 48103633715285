import { IfileNdirList } from '@/types/common/type';
import { useState, useEffect } from 'react';

/**
 * 파일 및 디렉토리 목록을 필터링하는 커스텀 훅
 *
 * @param {IfileNdirList[]} fileNdirList - 필터링할 파일 및 디렉토리 목록
 * @param {string} curDir - 현재 디렉토리 경로
 * @returns {{
 *   keyword: string,
 *   setKeyword: React.Dispatch<React.SetStateAction<string>>,
 *   filteredFileNdirList: IfileNdirList[]
 * }} 필터 키워드, 키워드 설정 함수, 필터링된 파일 및 디렉토리 목록
 */
export const useFileFilter = (
  fileNdirList: IfileNdirList[],
  curDir: string
) => {
  const [keyword, setKeyword] = useState('');
  const [filteredFileNdirList, setFilteredFileNdirList] = useState<
    IfileNdirList[]
  >([]);

  useEffect(() => {
    const newFileNdirList = fileNdirList.filter((v) =>
      v.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredFileNdirList(newFileNdirList);
  }, [keyword, curDir, fileNdirList]);

  return { keyword, setKeyword, filteredFileNdirList };
};
