import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import TrainSessionListItem from './TrainSessionListItem';

import { TrainSessionItem } from './TrainDetail';
import { CustomThemeContext } from '@/contexts/common/Context';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { TrainContext } from '@/contexts/TrainContext';

const TrainSessionListDiv = styled.div`
  flex-shrink: 0;

  width: 25%;
  height: calc(100vh - var(--72px));
  background-color: ${(props) =>
    props.$theme === 'light' ? '#E9EBF8' : ' #232334'};
  overflow-y: auto;

  header {
    position: sticky;
    top: 0;
    z-index: 10;

    width: 100%;
    background-color: ${(props) =>
      props.$theme === 'light' ? '#E9EBF8' : ' #232334'};
    padding: var(--12px);

    display: flex;
    justify-content: space-between;
    gap: var(--16px);

    button {
      padding: var(--12px);
      ${(props) =>
        props.$theme === 'light'
          ? `
        background-color:#bdbdbd;
        color: #d3d3d3;

        &.selected {
          background-color: #ffffff;
          color: black;
        }
      `
          : `
        background-color:'#232334';
        color: #525252;

        &.selected {
          background-color: #181837;
          color: white;
        }
      `}
    }
  }

  h3 {
    font-weight: bold;
    font-size: var(--12px);
  }

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;

    li {
      padding-left: var(--12px);
    }
  }
`;

export default function TrainSessionList() {
  const [trainSessionList, setTrainSessionList] = useState<TrainSessionItem[]>(
    []
  );
  const { theme } = useContext(CustomThemeContext);
  const { selected } = useContext(TrainContext);

  const api = useAPIwithCookies();

  const getTrainSessionList = async () => {
    const { data } = await api.get(`/mlops/train_sessions`);
    setTrainSessionList(data.reverse());
  };

  const getTrainSessionListToday = async () => {
    const date = new Date();
    const now = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    const { data } = await api.get(`/mlops/train_sessions/${now}`);
    setTrainSessionList(data.reverse());
  };

  const handleDateFilter = (e) => {
    e.target.classList.toggle('selected');
    if (e.target.classList.contains('selected')) {
      getTrainSessionListToday();
    } else {
      getTrainSessionList();
    }
  };

  useEffect(() => {
    getTrainSessionList();
  }, [selected?.status?.progress]);

  return (
    <TrainSessionListDiv className='train-session-list' $theme={theme}>
      <header>
        <button
          className='workspace-panel-button workspace-panel-left'
          onClick={() => {
            window.history.back();
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/canvas-back.svg'
                : '/images/canvas-back-white.svg'
            }
            alt=''
          />
          <span style={{ color: 'white' }}>Back</span>
        </button>
        <h3>Train Session List</h3>
        <button className='toggle btn' type='button' onClick={handleDateFilter}>
          filter: today
        </button>
      </header>
      <ul>
        {trainSessionList.map((trainSessionItem) => (
          <li key={trainSessionItem.key}>
            <TrainSessionListItem
              trainSessionItem={trainSessionItem}
              getTrainSessionList={getTrainSessionList}
            />
          </li>
        ))}
      </ul>
    </TrainSessionListDiv>
  );
}
