import React, { ChangeEvent, useRef } from 'react';

import PrimaryButton from '@/components/common/buttons/PrimaryButton';
import useDataHubStore from '@/stores/datahub/useDataHubStore';

import { dropFiles, uploadFiles } from '@/apis/datahub/fileApis';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { uploadChunkFile } from '@/utils/datahub/fileUtils';
import { useFileUpload } from '@/hooks/dataHub/useFileUpload';
// import ToggleButton from '../common/buttons/ToggleButton';

export default function DataHubLeftPanel({
  datahubTypeList,
  datahubType,
  setDatahubType,
  showProgress,
  setShowProgress,
}) {
  const api = useAPIwithCookies();
  const { curDir, getDirectory } = useDataHubStore();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    setLoading,
    setTotalFileLen,
    setProcessedFileLen,
    setProgress,
    progressList,
    setProgressList,
    setCurrentFilename,
  } = useFileUpload();

  const handleUploadFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadOptions = {
      setLoading,
      setTotalFileLen,
      setProcessedFileLen,
      progressList,
      setProgressList,
      setProgress,
      uploadChunkFile,
      api,
      curDir,
      setCurrentFilename,
    };
    const file = e.target.files as any; // FileList 객체

    await dropFiles(file, {
      setLoading,
      setProgress,
      uploadChunkFile,
      api,
      curDir,
    });

    // await uploadFiles(e.target.files, uploadOptions);
    getDirectory(curDir, api);
  };

  return (
    <div
      className='h-[calc(100vh-4.5rem)] w-[17rem] overflow-auto
      bg-gray100 pr-[10px] text-[16px] dark:bg-black100'
    >
      <>
        <PrimaryButton onClick={() => fileInputRef.current?.click()}>
          <input
            ref={fileInputRef}
            multiple
            type='file'
            onChange={handleUploadFiles}
            className='hidden'
          />
          <label className='btn-primary cursor-pointer' htmlFor='storage-upload'>
            Data Upload
          </label>
        </PrimaryButton>

        {/* <ToggleButton showProgress={showProgress} onToggle={() => setShowProgress(!showProgress)} /> */}
        {/* {!showProgress && (
          <ul className='ml-[18px] flex flex-col'>
            {datahubTypeList.map((listItem: string) => (
              <li
                key={listItem}
                className={`w-[94%] rounded-full p-[var(--12px)] text-[var(--16px)]
                    hover:text-gray300 dark:hover:text-gray200 ${
                      datahubType === listItem
                        ? 'font-black text-purple200'
                        : 'text-black300 dark:text-white'
                    } cursor-pointer`}
                onClick={() => setDatahubType(listItem)}
              >
                {listItem}
              </li>
            ))}
          </ul>
        )} */}
      </>
    </div>
  );
}
