import React from "react";
import styled from "styled-components";
import PrimaryButton from "./PrimaryButton";

const SecondaryButton = styled(PrimaryButton)`
  background-color: white;
  color: #8d59fc;
`;

export default SecondaryButton;
