import React from 'react';

const topNode = (resultNode: any) => {
  return (
    <>
      {/* Processing */}
      {resultNode.node &&
        (resultNode.node.custom_processing ||
          resultNode.node.patch_generation ||
          resultNode.node.image_processing || 
          resultNode.node.data_selector) && (
          <>
            <hr className='mt-2' style={{ border: '1px solid rgb(23, 24, 62)' }}></hr>
            <>
              <div
                style={{
                  border: '1px solid #33828c',
                  width: 'fit-content',
                  padding: '10px',
                  borderRadius: '7px',
                  // height: '40px',
                  marginTop: '5px',
                }}
              >
                <span>
                  <strong style={{ color: '#33828c' }}>0 {Object.keys(resultNode.node)}</strong>
                </span>
                {/* 여기서 나중에 노드 별로 분기처리 해야함 */}
                {JSON.stringify(resultNode.node.custom_processing, null, 2)}
              </div>
            </>
            <hr className='mt-2' style={{ border: '1px solid rgb(23, 24, 62)' }}></hr>
          </>
        )}
      {/* Custom */}
      {resultNode.node && resultNode.node.dataset_configuration && (
        <>
          <hr className='mt-2' style={{ border: '1px solid rgb(23, 24, 62)' }}></hr>
          <>
            <div
              style={{
                border: '1px solid #a2a200',
                width: 'fit-content',
                padding: '10px',
                borderRadius: '7px',
                // height: '40px',
                marginTop: '5px',
              }}
            >
              <span>
                <strong style={{ color: '#a2a200' }}>0 {Object.keys(resultNode.node)}</strong>
              </span>
              {JSON.stringify(resultNode.node.dataset_configuration, null, 2)}
            </div>
          </>
          <hr className='mt-2' style={{ border: '1px solid rgb(23, 24, 62)' }}></hr>
        </>
      )}
    </>
  );
};

export default topNode;
