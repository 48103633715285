import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import OptionInputItemControl from './OptionInputItemControl';

import { ILayerOption } from '../../flow';
import { isInt, isPositive, isPositiveInt } from './inputUtils';

const Container = styled.div`
  input {
    padding: var(--18px) var(--12px) !important;
  }
`

type OptionInputProps = {
  id?: string;
  label?: string; 
  initOption: { current: ILayerOption }; 
  type?: string;
  setValue: Dispatch<SetStateAction<number>> | Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>; 
  isValidInput?: (t:number) => boolean;
}

export default function OptionInput({ 
  id, 
  type,
  label, 
  initOption, 
  setValue, 
  setLoading,
  isValidInput,
}: OptionInputProps) {
  
  const inputRef = useRef<HTMLInputElement>(null);
  const [invalid, setInvalid] = useState<boolean>();

  const setValueAfter = (value, delay) => {
    setTimeout(() => {
      if (value === inputRef.current?.value) {
        setValue(type === "text" ? value : Number(value)); 
      } 
    }, delay);
  }

  const handleInputChange = () => {
    if (!inputRef.current) return;
    const value = inputRef.current.value;
    if (isValidInput && isValidInput(Number(value))) {
      setValueAfter(value, 400);
      setInvalid(false);
    } else if (isValidInput && !isValidInput(Number(value))) { 
      setInvalid(true);
      inputRef.current.value = "";
    } else {
      setValueAfter(value, 400);
    }
  }

  // 초기설정
  useEffect(() => {
    if (!inputRef.current) return;
    setLoading(true);

    // initValue 가져오기
    let initValue; 
    initValue = initOption.current[id];
    
    // 가져온 initValue 설정하기
    // console.log('가져온 initValue 설정하기', initOption.current)
    if (initValue!==undefined) {
      setValue(initValue);
      inputRef.current.value = initValue;
    }

    setLoading(false);
  }, [initOption.current[id]])

  // 경고문구
  const warningMessage = "※ " + 
  (isValidInput === isInt 
    ? "Enter a number 1 or higher." 
  : isValidInput === isPositive 
    ? "Enter a number 0 or higher." 
  : isValidInput === isPositiveInt
    ? "Enter a number 0 or higher."
  : '');

  return (
    <Container className='custom-inputwithlabel-container'>
      {label && <label htmlFor={id}><p>{label}</p></label>}

      <input 
        ref={inputRef} 
        id={id} 
        onChange={handleInputChange} 
        type={type === "text" ? "text" : "number"}
        placeholder={`${id}...`} 
      />

      {type === "text" || <OptionInputItemControl handleChange={handleInputChange} inputRef={inputRef} />}

      {invalid && type !== "text" 
      ? <p className='warning-message'>
          {warningMessage}
        </p> 
      : <></>}
    </Container>
  )
}
