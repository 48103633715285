import React from 'react';
import { DataHubCardProps, FileProgress, TaggingProgress } from '@/types/datahub/type';

export const DataHubCard = ({
  name,
  type,
  theme,
  keyword,
  thumbnail,
  updated_at,
  tagging_progress,
  file_progress,
}: DataHubCardProps) => {
  const getItemName = (name: string, keyword: string): JSX.Element | string => {
    if (!keyword) return name;

    const index = name.toLowerCase().indexOf(keyword.toLowerCase());
    if (index === -1) return name;

    return (
      <>
        {name.slice(0, index)}
        <span className='text-purple200'>{name.slice(index, index + keyword.length)}</span>
        {name.slice(index + keyword.length)}
      </>
    );
  };

  const getNameIcon = (type: string, theme: string) => {
    const basePath = process.env.PUBLIC_URL + '/images/datahub/card/title/';
    const themePath = theme === 'dark' ? '' : '-black';
    switch (type) {
      case 'dir':
      case 'dataset':
      case 'model':
      case 'form_output':
        return `${basePath}data-title-folder${themePath}.svg`;
      case 'image':
        return `${basePath}data-title-img${themePath}.svg`;
      case 'file':
        return `${basePath}data-title-file${themePath}.svg`;
      case 'svs':
        return `${basePath}data-title-img${themePath}.svg`;
      case 'vmeta':
        return `${basePath}data-title-layers${themePath}.svg`;
      default:
        return `${basePath}data-title-file${themePath}.svg`;
    }
  };

  const getThumbnailIcon = (type: string, thumbnail: string) => {
    const basePath = process.env.PUBLIC_URL + '/images/datahub/card/thumbnail/';
    if (thumbnail === 'thumbmail 생성중') {
      // 우선순위 제일 위에 적용
      return `/canvas-logo/simbol.png`;
    }
    if (type === 'dir' && thumbnail === 'empty') {
      return `${basePath}data-folder.svg`;
    }
    if (type === 'dir' || type === 'dataset') {
      return `${basePath}data-folder-file.svg`;
    }
    if (type === 'image') {
      return `data:image/jpeg;base64,${thumbnail}`;
    }
    if (type === 'svs') {
      return `data:image/jpeg;base64,${thumbnail}`;
    }
    if (type === 'vmeta') {
      return `/images/datahub/vmeta.svg`;
    }
    if (type === 'weight' || type === 'file') {
      return `${basePath}data-file.svg`;
    }
    if (type === 'form_output') {
      return `${basePath}data-form.svg`;
    }
    return '';
  };

  const renderTagProgress = (progress: TaggingProgress[]) =>
    progress.map((tagging: TaggingProgress, index: number) => (
      <div
        key={index}
        className='relative flex size-10 cursor-pointer
        rounded-full bg-gray200 text-[10px] text-black300 dark:text-gray100'
        title={tagging[0]}
        style={{
          background: `conic-gradient(${tagging[3]} ${360 * (tagging[1] / tagging[2])}deg, 
            white 0deg)`,
        }}
      >
        <div
          className='m-auto flex size-4/5 items-center justify-center
          bg-white100 text-[8px] dark:bg-black200'
          style={{ borderRadius: 'inherit' }}
        >
          <span>
            {tagging[1]}/{tagging[2]}
          </span>
        </div>
      </div>
    ));

  const renderFileProgress = (progress: FileProgress[]) =>
    progress.map((fileProcess: FileProgress) => (
      <div
        key={fileProcess[3]}
        className={`flex h-[20px] items-center justify-center
          rounded-custom-xl px-[10px] text-[12px]`}
        style={
          fileProcess[2] === 'complete'
            ? { backgroundColor: fileProcess[1] }
            : { border: `1px solid ${fileProcess[1]}` }
        }
      >
        {fileProcess[0]}
      </div>
    ));

  return (
    <>
      <div className='flex w-full items-center justify-between gap-[8px] px-[5px] pb-[5px]'>
        <div
          className='flex items-center justify-start gap-[10px]
            text-[17px] tracking-[var(--spacingDefalt)] text-black100 dark:text-gray100'
        >
          <img
            draggable='false'
            src={getNameIcon(type, theme)}
            alt={`icon`}
            className={`data-title-${type}`}
          />
          <div className='group relative'>
            <p
              className='line-clamp-1 max-w-14ch overflow-hidden
                text-ellipsis break-all group-hover:opacity-100'
            >
              {getItemName(name, keyword)}
            </p>
            <div
              className='absolute left-1/2 top-full mt-1 w-auto 
              max-w-xs -translate-x-1/2 rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 
              transition-opacity duration-300 group-hover:opacity-100'
            >
              {getItemName(name, keyword)}
            </div>
          </div>
        </div>
        <div
          className='hidden text-[16px] font-normal
          tracking-[var(--spacingDefalt)] text-black dark:text-white sm:block'
        >
          {updated_at.slice(0, 10)}
        </div>
      </div>
      <div
        className='border-1 mt-[10px] size-full
        rounded-lg border-2 border-gray100
        bg-white100 dark:border-black100 dark:bg-black200'
      >
        <div className='mt-[10px] flex h-1/4 items-center gap-3 pl-4 text-gray100'>
          {tagging_progress && renderTagProgress(tagging_progress)}
          {file_progress && renderFileProgress(file_progress)}
        </div>
        <div className='flex h-[35%] w-full flex-col items-center justify-center dark:bg-black200 sm:h-[70%]'>
          <img
            draggable='false'
            src={getThumbnailIcon(type, thumbnail)}
            alt={`${type} thumbnail`}
            className={`${
              type === 'dir' || type === 'dataset'
                ? 'w-[3.625rem] sm:w-[5.4375rem]'
                : type === 'image'
                  ? 'h-[2.9rem] w-[3.5625rem] rounded-custom-xl object-cover sm:h-[4.35975rem] sm:w-[5.34375rem]'
                  : type === 'file'
                    ? 'w-[2.97rem] sm:w-[4.4535rem]'
                    : 'h-[2.9rem] w-[3.5625rem] rounded-custom-xl object-cover sm:h-[4.35975rem] sm:w-[5.34375rem]'
            }`}
          />
          <div className='mt-[10px] flex h-1/4 items-center gap-3 pl-4 text-gray100'></div>
        </div>
      </div>
    </>
  );
};
