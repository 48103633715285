import React, { useEffect } from 'react';
import { ClassicPreset, NodeId } from 'rete';

import './CustomProcessing.scss';

import { CustomSocket } from '../../sockets';

import { CustomColor } from '../../style/CustomNode';
import CustomControl from '../../style/CustomControl';
// import { updateControl } from '../../../reteUtils';
import { NodeSize } from '../../style/CustomNode';
import { IDatasetConfiguration } from '../../filter';

export class DatasetConfigurationNode extends ClassicPreset.Node<
  { [key: string]: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: DatasetConfigurationControl }
> {
  color = CustomColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId, inputCount = 10) {
    super('DatasetConfiguration');

    for (let i = 0; i < inputCount; i++) {
      this.addInput(`input${i}`, new ClassicPreset.Input(new CustomSocket()));
    }

    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));

    this.addControl('ctrl', new DatasetConfigurationControl(process, nodeId ? nodeId : this.id));
  }
  data(inputs: { [key: string]: any[] }): { out: any } {
    if (Object.keys(inputs).length === 0) {
      return {
        out: {
          img_paths: {},
          effector: {
            input: [],
            node: {},
          },
        },
      };
    }

    const values = Object.values(inputs).flatMap((input) => input);
    const result = values.reduce((acc, item) => {
      if (item.img_paths) {
        Object.assign(acc, item.img_paths);
      }
      return acc;
    }, {});

    if (JSON.stringify(this.controls.ctrl.props.option.path) !== JSON.stringify(result)) {
      this.controls.ctrl.setValue({
        path: result,
        value: values,
        vmeta: this.controls.ctrl.props.option.vmeta,
        inputData: this.controls.ctrl.props.option.inputData,
        inputKey: this.controls.ctrl.props.option.inputKey,
        trigger: this.controls.ctrl.props.option.trigger,
      });
    }

    return {
      out: {
        img_paths: result,
        effector: {
          input: values.flatMap((value) => value.effector),
          node: {
            dataset_configuration: {
              input_keys: this.controls.ctrl.props.option.inputKey,
            },
          },
        },
      },
    };
  }
}

export class DatasetConfigurationControl extends ClassicPreset.Control {
  props: {
    option: IDatasetConfiguration;
  };
  contextOpen: boolean;
  status: string;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      option: {
        path: undefined,
        value: [],
        vmeta: {},
        inputData: [],
        inputKey: {},
        trigger: false,
      },
    };
    this.contextOpen = false;
    this.status = 'init';
  }

  setValue(val: any & { value?: any[] }) {
    this.props.option = val;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };
  setStatus = (status: string) => {
    this.status = status;
  };
}
export function DatasetConfigurationComp({ data }: { data: DatasetConfigurationControl }) {
  useEffect(() => {
    if (data.props.option.value) {
      return;
    }
  }, [data]);

  return (
    <>
      <CustomControl
        contextOpen={data.contextOpen}
        setContextOpen={data.setContextOpen}
        label='Dataset Configuration'
        nodeId={data.nodeId}
        markerSource={'node-custom'}
        iconSource='dataset'
        status={data.status}
        setStatus={data.setStatus}
      />
    </>
  );
}
