import React from 'react';

const LeftPanel = ({ children }) => {
  return (
    <div
      className='h-[calc(100vh-4.5rem)] w-[17rem] overflow-auto
      bg-gray100 pr-[10px] text-[16px] dark:bg-black100'
    >
      {children}
    </div>
  );
};

export default LeftPanel;
