import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ViewerControl } from '../ViewerNode';
import VmetaViewerPanel from './vmeta-panel';
import ImageViewerPanel from './image-panel';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
// import { NodeContext } from '@/components/rete/NodeContext';

export default function CommonViewerPanel({
  ctrl,
  expand,
  setPanelMode,
}: {
  ctrl: ViewerControl;
  expand: boolean;
  setPanelMode: any;
}) {
  const api = useAPIwithCookies();
  const params = useParams();

  // const { nodeId, setExpandDetection } = useContext(NodeContext);
  // const [nodeType, setNodeType] = useState<any>();
  // const [selectedType, setSelectedType] = useState<TSelectedType>(null);
  const [fileType, setFileType] = useState<string>('unknown');
  const [viewerKey, setViewerKey] = useState<string>('');
  const [currentNodeId, setCurrentNodeId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOpenPanel = () => {
    setPanelMode('full');
  };

  /**
   * 새로운 노드가 선택되었을 때 Viewer 데이터를 불러오는 함수
   */
  const getViewerKey = async () => {
    if (!ctrl.props.option) return;

    setLoading(true);

    try {
      const res = await api.post(`/module/execute`, {
        wor_id: params.wor_id,
        img_paths: ctrl.props.option.img_paths,
        effector: ctrl.props.option.effector,
      });

      const output_info = res.data;
      setViewerKey(output_info.key);
      setFileType(output_info.type);
    } catch (err) {
      console.error('Error fetching viewer key:', err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * 새로운 Viewer 노드를 클릭하면 기존 노드와 다름을 감지하고 새로운 데이터를 로드
   */
  useEffect(() => {
    if (ctrl.nodeId !== currentNodeId) {
      setCurrentNodeId(ctrl.nodeId);
      getViewerKey();
    }
  }, [ctrl.nodeId, ctrl.props.option]);

  // useEffect(() => {
  //   if (editor && nodeId) {
  //     const pickedNode = editor.getNode(nodeId);
  //     setNodeType(pickedNode);
  //   }
  //   setPanelMode('normal');
  //   setSelectedType(null);
  // }, [nodeId]);

  return (
    <>
      {fileType === 'vmeta' && viewerKey !== '' ? (
        <VmetaViewerPanel ctrl={ctrl} viewerKey={viewerKey} handleOpenPanel={handleOpenPanel} loading={loading}/>
      ) : (
        <ImageViewerPanel ctrl={ctrl} viewerKey={viewerKey} handleOpenPanel={handleOpenPanel} loading={loading}/>
      )}
    </>
  );
}
