import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Header from '@/components/common/layouts/header/Header';
import Input from '@/components/common/inputs/Input';
import ContextMenu from '@/components/common/menu/Menu';
import CustomModal from '@/components/common/CustomModal';
import { ShareModal } from '@/components/rete/workspace-panel.component';

import { CustomThemeContext } from '@/contexts/common/Context';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

import { TCategoryType, IWorkspaceList, TWorkspaceID } from './workspace';
import EmptyDiv from '../../mlops/train-detail/EmptyDiv';
import LeftPanel from '../../../components/leftPanel/LeftPanel';
import PrimaryButton from '@/components/common/buttons/PrimaryButton';
import CommingSoonAlert from '@/components/common/alerts/CommingSoonAlert';
import Swal from 'sweetalert2';

export default function MyWorkspace() {
  const navigate = useNavigate();
  const api = useAPIwithCookies();
  // const categoryList: TCategoryType[] = ['All', 'My', 'Shared', 'Archived', 'Trashed'];
  const categoryList: TCategoryType[] = ['All', 'My', 'Archived', 'Trashed'];

  const [category, setCategory] = useState<TCategoryType>('All');
  const [workspaceList, setWorkspaceList] = useState<IWorkspaceList[]>([]);
  const [contextTarget, setContextTarget] = useState<TWorkspaceID>('');
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const [contextEvent, setContextEvent] = useState<any>();
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [shareTarget, setShareTarget] = useState<string>('');
  const { theme } = useContext(CustomThemeContext);

  const getWorkspaceList = async () => {
    const response = await api.get(`/workspace/list/${category.toLowerCase()}`);
    setWorkspaceList(response.data);
  };

  const createNewWorkspace = async () => {
    const response = await api.post('/workspace/new');
    navigate(`/vience-canvas/canvas/${response.data}`);
  };

  const copyWorkspace = async (target: string) => {
    await api.post(`/workspace/copy/${target}`);
    getWorkspaceList();
  };

  const deleteWorkspace = async (target: string) => {
    try {
      await api.delete(`/workspace/delete/${target}`);
      getWorkspaceList();
    } catch ({ detail }: any) {
      if (detail === 'Permission Denied') {
        alert("You don't have permission to delete project");
      }
    }
  };

  const archiveWorkspace = async (target: string) => {
    await api.post(`/workspace/archive/${target}`);
    getWorkspaceList();
  };

  useEffect(() => {
    getWorkspaceList();
  }, [category]);

  const setContext = (e: any, wor_id: TWorkspaceID) => {
    setContextOpen(true);
    setContextEvent(e);
    setContextTarget(wor_id);
  };

  const [workspaceModalOpen, setWorkspaceModalOpen] = useState<boolean>(false);
  const handleWorkspaceModal = () => {
    setWorkspaceModalOpen(!workspaceModalOpen);
  };

  const [keyword, setKeyword] = useState('');
  const [filteredList, setFilteredList] = useState<any[]>([]);

  useEffect(() => {
    if (keyword.length) {
      setFilteredList(
        workspaceList.filter((v) => v.title?.toLowerCase().includes(keyword.toLowerCase())),
      );
    } else {
      getWorkspaceList();
      setFilteredList([]);
    }
  }, [keyword]);

  const handleWorkspaceRestore = async (wor_id: any) => {
    const response = api.put(`/workspace/restore/${wor_id}`);
    setCategory('All');

    Swal.fire({
      icon: 'success',
      title: '복구되었습니다!',
      text: '워크스페이스가 정상적으로 복구되었습니다.',
      confirmButtonText: '확인',
    });
  };

  const handleContext = async (e, wor_status, wor_id: any) => {
    if (wor_status !== 'trashed') {
      setContext(e, wor_id);
    }
  };
  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <Header />
      <div
        className='flex  overflow-hidden bg-gray100 dark:bg-black100'
        style={{ height: 'calc(100vh - 72px)' }}
      >
        <></>
        <LeftPanel>
          <PrimaryButton onClick={handleWorkspaceModal}>New Workspace</PrimaryButton>
          <CustomModal open={workspaceModalOpen} onClose={handleWorkspaceModal} bgShown='blur'>
            <div
              className=' bg-white200 rounded-lg bg-gray100
              p-6 text-white shadow-lg dark:bg-black100 '
            >
              <strong className='text-lg font-bold'>Workspace Option</strong>
              <CommingSoonAlert>
                <Link to='/vience-canvas/template'>
                  <button
                    className='mt-4 w-full rounded-md border
                  border-gray-300 bg-white100 px-4 py-2 font-semibold text-black'
                  >
                    Start with a template
                  </button>
                </Link>
              </CommingSoonAlert>
              <div className='mt-4 w-full cursor-pointer' onClick={createNewWorkspace}>
                <button
                  className='w-full rounded-md bg-purple200
                  px-4 py-2 font-semibold text-white dark:text-white100'
                >
                  Start with an empty workspace
                </button>
              </div>
            </div>
          </CustomModal>
          <div className='mt-6'>
            <ul className='ml-[18px] flex flex-col'>
              {categoryList.map((data) => (
                <li
                  key={data}
                  className={`w-[94%] rounded-full p-[var(--12px)] text-[var(--16px)]
                    hover:text-gray300 dark:hover:text-gray200 ${
                      category === data
                        ? 'font-black text-purple200'
                        : 'text-black300 dark:text-white'
                    } cursor-pointer`}
                  onClick={() => setCategory(data)}
                >
                  <p>{data}</p>
                </li>
              ))}
            </ul>
          </div>
        </LeftPanel>
        <div
          className='w-[calc(100%-17rem)] rounded-tl-[30px]
            bg-white100 p-[20px] dark:bg-black200'
        >
          {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
          <div
            className='relative
            overflow-x-hidden bg-white100 px-6 py-10 dark:bg-black200'
            onClick={() => {
              setContextOpen(false);
            }}
          >
            <Input setKeyword={setKeyword} />
            <div id='canvas-card-frame' className='mt-[40px] h-[calc(100vh-16.25rem)] w-full overflow-auto'>
              <div className='fit-content'>
                <ul
                  className='relative mt-[var(--40px)] grid size-full
                grid-cols-[repeat(auto-fill,minmax(30rem,1fr))] 
                justify-start gap-[var(--20px)] overflow-auto'
                >
                  {keyword.length ? (
                    filteredList.length ? (
                      filteredList.map((workspace) => {
                        let title;
                        if (keyword) {
                          const index = workspace.title
                            .toLowerCase()
                            .indexOf(keyword.toLowerCase());
                          title = (
                            <strong>
                              {workspace.title.slice(0, index)}
                              <span className='text-[#8D59FC]'>
                                {workspace.title.slice(index, index + keyword.length)}
                              </span>
                              {workspace.title.slice(index + keyword.length)}
                            </strong>
                          );
                        }
                        return (
                          <li
                            key={workspace.wor_id}
                            className={`h-[19rem] w-full 
                              cursor-pointer rounded-lg border-2 border-gray100 px-[15px] pb-[18px] pt-[20px] dark:border-black100
                              `}
                            onContextMenu={(e) => setContext(e, workspace.wor_id)}
                          >
                            <Link to={`/vience-canvas/canvas/${workspace.wor_id}`}>
                              <div className='rounded-custom-xl p-[5px]'>
                                <div className='flex items-start justify-start gap-[10px]'>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + theme === 'dark'
                                        ? '/images/ico-tree.svg'
                                        : '/images/ico-tree-black.svg'
                                    }
                                    alt=''
                                    className='w-[24px]'
                                  />
                                  <strong
                                    className='inline-block w-fit truncate break-words
                                    text-[17px] font-bold text-black dark:text-[#eee]'
                                  >
                                    {keyword.length ? title : workspace.title}
                                  </strong>
                                </div>
                                <div
                                  className='mt-[5px] flex items-center justify-between
                                  text-[16px] text-[#a5a4ae]'
                                >
                                  {workspace.updated_at && (
                                    <span>
                                      {workspace.updated_at.slice(0, 10)}{' '}
                                      {workspace.updated_at.slice(11, 16)}
                                    </span>
                                  )}
                                  <span
                                    className='box-border rounded-[30px] border border-[#cccccc]
                                    px-[12px] py-[2px] text-[15px] text-white'
                                  >
                                    {workspace.category}
                                  </span>
                                </div>
                              </div>
                              <div className='relative mt-[10px] h-[190px] w-full'>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + theme === 'dark'
                                      ? '/images/test-img.png'
                                      : '/images/test-img-white.png'
                                  }
                                  alt=''
                                  className='h-[190px] w-full select-none
                                  rounded-custom-xl object-cover'
                                />
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <EmptyDiv label='No search results found.' />
                    )
                  ) : workspaceList.length ? (
                    workspaceList.map((workspace) => (
                      <li
                        id='workspace-card-container'
                        key={workspace.wor_id}
                        className={`h-[19rem] cursor-pointer 
                        rounded-lg border-2 border-gray100 px-[15px] pb-[18px] pt-[20px] dark:border-black100 
                        `}
                        onContextMenu={(e) => {
                          handleContext(e, workspace.status, workspace.wor_id);
                        }}
                      >
                        {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
                        {workspace.status !== 'trashed' && (
                          <Link to={`/vience-canvas/canvas/${workspace.wor_id}`}>
                            <div className='rounded-custom-xl'>
                              <div className='flex items-start  justify-between gap-[10px]'>
                                <div className='flex'>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + theme === 'dark'
                                        ? '/images/ico-tree.svg'
                                        : '/images/ico-tree-black.svg'
                                    }
                                    alt=''
                                    className='w-[24px]'
                                  />
                                  <strong
                                    className='ml-2 inline-block w-fit truncate
                                break-words text-[17px] font-bold text-black dark:text-[#eee]'
                                  >
                                    {workspace.title}
                                  </strong>
                                </div>
                                <div className='flex '>
                                  {/* {workspace.status === 'trashed' && (
                                  <button
                                    className='rounded-[7px] bg-[#000000] dark:bg-white text-white dark:text-black px-2 mr-2'
                                    onClick={() => handleWorkspaceRestore(workspace.wor_id)}
                                  >
                                    restore
                                  </button>
                                )} */}

                                  {workspace.status && (
                                    <strong
                                      className='inline-block w-fit truncate break-words
                                  rounded-full border border-purple200 px-2 
                                  text-[13px] font-bold text-purple200 dark:border-purple200 dark:text-purple200'
                                    >
                                      {workspace.status}
                                    </strong>
                                  )}
                                </div>
                              </div>
                              <div
                                className='mt-[5px] flex items-center justify-between
                              text-[16px] text-[#a5a4ae]'
                              >
                                {workspace.updated_at && (
                                  <span>
                                    {workspace.updated_at.slice(0, 10)}{' '}
                                    {workspace.updated_at.slice(11, 16)}
                                  </span>
                                )}
                                {/* <span
                              className='box-border rounded-[30px] border border-[#cccccc]
                                px-[12px] py-[2px] text-[15px] text-white100'
                            >
                              {workspace.category} **카테고리 기능 비활성화 상태이므로 임시 주석 처리
                            </span> */}
                              </div>
                            </div>
                            <div className='relative mt-[10px] h-[190px] w-full'>
                              <img
                                src={
                                  process.env.PUBLIC_URL + theme === 'dark'
                                    ? '/images/test-img.png'
                                    : '/images/test-img-white.png'
                                }
                                alt=''
                                className='h-[190px] w-full select-none rounded-custom-xl object-cover'
                              />
                            </div>
                          </Link>
                        )}
                        {workspace.status === 'trashed' && (
                          <>
                            <div className='rounded-custom-xl'>
                              <div className='flex items-start  justify-between gap-[10px]'>
                                <div className='flex'>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + theme === 'dark'
                                        ? '/images/ico-tree.svg'
                                        : '/images/ico-tree-black.svg'
                                    }
                                    alt=''
                                    className='w-[24px]'
                                  />
                                  <strong
                                    className='ml-2 inline-block w-fit truncate
                                break-words text-[17px] font-bold text-black dark:text-[#eee]'
                                  >
                                    {workspace.title}
                                  </strong>
                                </div>
                                <div className='flex '>
                                  {workspace.status === 'trashed' && (
                                    <button
                                      className='mr-2 rounded-[7px] bg-black300 px-2 text-white hover:bg-[#bfbfbf] dark:bg-white dark:text-black hover:dark:bg-[#bfbfbf]'
                                      onClick={() => handleWorkspaceRestore(workspace.wor_id)}
                                    >
                                      restore
                                    </button>
                                  )}

                                  {workspace.status && (
                                    <strong
                                      className='inline-block w-fit truncate break-words
                                  rounded-full border border-purple200 px-2 
                                  text-[13px] font-bold text-purple200 dark:border-purple200 dark:text-purple200'
                                    >
                                      {workspace.status}
                                    </strong>
                                  )}
                                </div>
                              </div>
                              <div
                                className='mt-[5px] flex items-center justify-between
                              text-[16px] text-[#a5a4ae]'
                              >
                                {workspace.updated_at && (
                                  <span>
                                    {workspace.updated_at.slice(0, 10)}{' '}
                                    {workspace.updated_at.slice(11, 16)}
                                  </span>
                                )}
                                {/* <span
                              className='box-border rounded-[30px] border border-[#cccccc]
                                px-[12px] py-[2px] text-[15px] text-white100'
                            >
                              {workspace.category} **카테고리 기능 비활성화 상태이므로 임시 주석 처리
                            </span> */}
                              </div>
                            </div>
                            <div className='relative mt-[10px] h-[190px] w-full'>
                              <img
                                src={
                                  process.env.PUBLIC_URL + theme === 'dark'
                                    ? '/images/test-img.png'
                                    : '/images/test-img-white.png'
                                }
                                alt=''
                                className='h-[190px] w-full select-none rounded-custom-xl object-cover'
                              />
                            </div>
                          </>
                        )}
                        {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
                      </li>
                    ))
                  ) : (
                    <EmptyDiv label='No workspace available.' />
                  )}
                </ul>
              </div>
              {contextOpen && (
                <ContextMenu
                  containerId='canvas-card-frame'
                  event={contextEvent}
                  onClose={() => setContextOpen(false)}
                  functions={[
                    {
                      label: 'delete',
                      on: () => {
                        deleteWorkspace(contextTarget);
                      },
                    },
                    {
                      label: 'copy',
                      on: () => {
                        copyWorkspace(contextTarget);
                      },
                    },
                    {
                      label: 'archive',
                      on: () => {
                        archiveWorkspace(contextTarget);
                      },
                    },
                    // {
                    //   label: 'share',
                    //   on: () => {
                    //     setShareOpen(true);
                    //   },
                    // },
                  ]}
                />
              )}
            </div>
          </div>
          {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
        </div>
      </div>
      <ShareModal
        open={shareOpen}
        onClose={() => {
          setShareOpen(false);
        }}
        wor_id={shareTarget}
      />
    </div>
  );
}
