import React, { useContext } from "react";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import PrimaryButton from "../../../components/button/PrimaryButton";
import { StorageContext } from "../../../context/StorageContext";
import ToggleButton from "../../../components/button/ToggleButton";

export default function StorageLeftPanel({
  storageTypeList,
  storageType,
  setStorageType,
}) {
  const { handleUpload, showProgress, setShowProgress } =
    useContext(StorageContext);
  const handleToggle = () => {
    setShowProgress(!showProgress);
  };

  return (
    <LeftPanel id="storage-grid-left-panel" className="workspace-menu left">
      <ToggleButton showProgress={showProgress} onToggle={handleToggle} />

      {!showProgress && (
        <>
          <PrimaryButton>
            <input
              id="storage-upload"
              multiple
              type="file"
              onChange={handleUpload}
            />
            <label className="btn-primary" htmlFor="storage-upload">
              Data Upload
            </label>
          </PrimaryButton>
          <ul id="storage-type-list">
            {storageTypeList.map((data) => {
              return (
                <li
                  className={`storage-type ${storageType === data ? "selected" : ""}`}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setStorageType(data);
                    }}
                  >
                    {data}
                  </button>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </LeftPanel>
  );
}
