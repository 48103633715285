import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import AnnotationDiv from './annotation-app/AnnotationDiv';
import CorrectedImgList from './CorrectedImgList';
import DataHubBody from '@/components/datahub/datahub-body';
import LoadingAnimation from '@/components/loadingAnimation/LoadingAnimation';
import ProgressCircleDiv from '@/components/progressCircle/ProgressCirclediv';

import { CustomThemeContext } from '@/contexts/common/Context';
import { TrainContext } from '@/contexts/TrainContext';
import { DataHubContext } from '@/contexts/datahub/Context';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

const Container = styled.div<{ $theme: 'light' | 'dark' }>`
  width: 100%;
  height: 100%;

  .slider,
  .annotation,
  .corrected-img-list-container,
  .storage-main-container {
    ${(props) =>
      props.$theme === 'light'
        ? `
      border: 2px solid #D9D9E5;
    `
        : `
      border: 2px solid #1B1B47;
    `}
  }

  .corrected-img-item {
    img {
      ${(props) =>
        props.$theme === 'light'
          ? `
        background: #D9D9E4;
      `
          : `
        background: #00000d;
      `}
    }
  }

  button.back-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--10px);

    color: #d7d2dd;
    font-size: var(--16px);
    font-weight: 600;
    letter-spacing: var(--spacingDefalt);
    padding: var(--16px);
  }

  #storage-grid-main {
    height: calc(70vh - var(--72px) - var(--72px) - 286px);
    overflow-y: scroll;
  }

  .annotation {
    width: 100%;
    height: max-content;
  }
`;

export default function FineTunerCorrectionTab() {
  const { theme } = useContext(CustomThemeContext);
  const api = useAPIwithCookies();

  const { trainerControl } = useContext(TrainContext);
  const option = trainerControl?.props.option;

  const [step, setStep] = useState(0);

  // step = 0 dataset explorer
  const baseDir = '/MLOps';
  const { loading, dirLoading, progress } = useContext(DataHubContext);

  // step == 1 : correction
  const [annotationData, setAnnotationData] = useState({
    foreground: [],
    background: [],
  });

  useEffect(() => {
    setAnnotationData({ foreground: [], background: [] });
  }, [step]);

  const [corrections, setCorrections] = useState<string[][]>([]);
  const [selectedDataset, setSelectedDataset] = useState<{
    path: string;
    name: string;
    thumbnail: string;
  }>({ path: '', name: '', thumbnail: '' });

  const handleReset = () => {
    setCorrections([]);
  };

  const handleAdd = async () => {
    const t_node_id = option?.nodeId;
    const response = await api.get('/mlops_finetuning/correction_list/' + t_node_id);

    const correction_list = response.data;
    const imgData = correction_list.map((data) => [
      [data['correction_key'], data['initial_img']],
      [' ', data['corrected_img']],
    ]);
    setCorrections(imgData);
  };

  useEffect(() => {
    if (option?.nodeId) {
      handleAdd();
    }
  }, [option?.nodeId]);

  const handleChangeSelected = (label) => {
    // const { data } = api.get(`/mlops/~~`, label);
    // setSelected({path: data.path, name: data.name, thumbnail: ''})
    // setAnnotationData({
    //   foreground: [],
    //   background: [],
    // })
  };

  return (
    <Container $theme={theme}>
      <h4 className='a11y-hidden'>correction tab</h4>
      {step > 0 && (
        <button
          className={'back-btn'}
          onClick={() => {
            setStep(0);
            setSelectedDataset({ path: '', name: '', thumbnail: '' });
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/canvas-back.svg'
                : '/images/canvas-back-white.svg'
            }
            alt=''
          />
          <span>Back</span>
        </button>
      )}
      {dirLoading && <LoadingAnimation label={'loading...'} />}
      {step === 0 && (
        <div className='storage-main-container' style={{ position: 'relative' }}>
          <DataHubBody baseDir={baseDir} setStep={setStep} setSelectedFile={setSelectedDataset} />
        </div>
      )}
      {step === 1 && (
        <>
          <AnnotationDiv
            fns={{
              handleReset,
              setAnnotationData,
              handleAdd,
            }}
            selected={selectedDataset}
            annotationData={annotationData}
          />
        </>
      )}
      <CorrectedImgList
        imgData={corrections}
        handleAdd={handleAdd}
        handleChangeSelected={handleChangeSelected}
      />
      {loading && (
        <ProgressCircleDiv
          label={progress?.filename ?? ''}
          percentage={Number(progress?.progress.toFixed(2))}
        />
      )}
    </Container>
  );
}
