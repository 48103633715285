import React from 'react';
import { createNode } from '../rete';

import 'rsuite/dist/rsuite.min.css';

const ControlPanel = () => {
  return (
    <div
      className='h-full overflow-y-auto border-r border-gray100
      pb-[150px] dark:border-black100 dark:bg-black200'
    >
      {/* ====== 각 패널 공통 컴포넌트 ====== */}
      {[
        {
          title: 'Data',
          step: 'STEP 01',
          description: 'Cloud-based big data selector',
          buttons: [
            {
              label: 'Data Selector',
              image: '/images/rete/node/icon/datamanage.svg',
              bgColor: 'bg-purple300',
              textColor: 'text-[#dacdeb]',
              nodeId: 7,
            },
          ],
        },
        {
          title: 'Custom',
          step: 'STEP 02',
          description: 'Combines multiple inputs',
          buttons: [
            {
              label: 'Dataset Configuration',
              image: '/images/rete/node/icon/dataset.svg',
              bgColor: 'bg-[#a2a200]',
              textColor: 'text-[#f4feff]',
              nodeId: 18,
            },
          ],
        },
        {
          title: 'Processing',
          step: 'STEP 03',
          description: 'High-performance GPU-based parallel data processing',
          buttons: [
            {
              label: 'Patch Generation',
              image: '/images/rete/node/icon/patch.svg',
              bgColor: 'bg-[#4C7DCC]',
              textColor: 'text-[#f4feff]',
              nodeId: 17,
            },
            {
              label: 'Image Processing',
              image: '/images/rete/node/icon/chart.svg',
              bgColor: 'bg-[#4C7DCC]',
              textColor: 'text-[#f4feff]',
              nodeId: 20,
            },
            {
              label: 'Custom Processing',
              image: '/images/canvas-edge-code.svg',
              bgColor: 'bg-[#4C7DCC]',
              textColor: 'text-[#f4feff]',
              nodeId: 0,
            },
            // {
            //   label: 'Crop',
            //   image: '/images/canvas-crop-crop.svg',
            //   bgColor: 'bg-[#33828c]',
            //   textColor: 'text-[#f4feff]',
            //   nodeId: 1,
            // },
            // {
            //   label: 'Edge Detection',
            //   image: '/images/canvas-crop-edge.svg',
            //   bgColor: 'bg-[#33828c]',
            //   textColor: 'text-[#f4feff]',
            //   nodeId: 2,
            // },
            // {
            //   label: 'Blender',
            //   image: '/images/canvas-viewer-blend.svg',
            //   bgColor: 'bg-[#33828c]',
            //   textColor: 'text-[#f4feff]',
            //   nodeId: 12,
            // },
          ],
        },
        {
          title: 'Add-on',
          step: 'STEP 04',
          description: 'Expand functionality',
          buttons: [
            // {
            //   label: 'Feature',
            //   image: '/images/canvas-crop-cell.svg',
            //   bgColor: 'bg-[#0f8d49]',
            //   textColor: 'text-[#e7f6ea]',
            //   nodeId: 13,
            // },
            {
              label: 'Form Generater',
              image: '/images/rete/node/icon/clipboard.svg',
              bgColor: 'bg-[#0f8d49]',
              textColor: 'text-[#e7f6ea]',
              nodeId: 19,
            },
          ],
        },
        {
          title: 'Result',
          step: 'STEP 05',
          description: 'Visualize and generate results',
          buttons: [
            {
              label: 'Viewer',
              image: '/images/canvas-viewer-viewer.svg',
              bgColor: 'bg-[#b92931]',
              textColor: 'text-[#e7f6ea]',
              nodeId: 8,
            },
            // {
            //   label: 'Pipe Generation',
            //   image: '/images/canvas-viewer-process.svg',
            //   bgColor: 'bg-[#b92931]',
            //   textColor: 'text-[#e7f6ea]',
            //   nodeId: 10,
            // },
          ],
        },
      ].map((section, idx) => (
        <div key={idx} className='border-b border-[#1b1b47] p-[20px]'>
          <div className='mb-[20px]'>
            <div className='flex items-center justify-between'>
              <strong className='text-[16px] font-semibold text-black dark:text-[#e3d8f1]'>
                {section.title}
              </strong>
              <span className='text-[14px] font-normal text-black dark:text-[#d1ced9]'>
                {section.step}
              </span>
            </div>
            <p className='mt-[5px] text-[14px] font-normal text-[#9d97a5]'>{section.description}</p>
          </div>

          {/* ===== 버튼 목록 ===== */}
          {section.buttons.map((button, bIdx) => (
            <button
              key={bIdx}
              className={`mb-[10px] flex w-full items-center gap-[5px] rounded-md
                px-[20px] py-[10px] ${button.bgColor}`}
              onClick={(e) => createNode(button.nodeId, e.clientY)}
            >
              <div className='size-[24px]'>
                <img src={process.env.PUBLIC_URL + button.image} alt='' />
              </div>
              <span className={`text-[14px] font-medium tracking-tight ${button.textColor}`}>
                {button.label}
              </span>
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ControlPanel;
