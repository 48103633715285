import React, { useState, useEffect } from 'react';
import { default as OSD } from 'openseadragon';

import { PatchGenerationControl } from '@/components/rete/components/nodes/patch-generation';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

import '@/components/rete/components/nodes/patch-generation/PatchGeneration.scss';

interface PatchGenerationPanalProps {
  ctrl: PatchGenerationControl;
  expand: boolean;
  nodeId?: string | number;
  setPanelMode: any;
}

/**
 * * Patch Generation Panel 로직 요약
 * * 1. 마운트 시 → generate_osd_frame API를 호출해 key를 발급받는다. (key는 항상 동일)
 * * 2. 사용자가 patch/resize/overlap value 변경 시 → handleViewImage()에서 Viewer를 업데이트 한다.
 * * 3. Viewer open-failed 시 → 새 key를 요청해 다시 open 한다.
 */

export function PatchGenerationPanel({ ctrl, setPanelMode }: PatchGenerationPanalProps) {
  const [resize, setResize] = useState<number>(ctrl.props.option.resizeFactor);
  const [resizeInput, setResizeInput] = useState<string>(
    ctrl.props.option.resizeFactor?.toString(),
  );
  const [patch, setPatch] = useState<number>(ctrl.props.option.patchSize);
  const [overlap, setOverlap] = useState<number>(ctrl.props.option.overlapSize);
  const [imgSize, setImgSize] = useState(ctrl.props.option.imgSize);

  const [viewer, setViewer] = useState<OSD.Viewer | null>(null);
  const [isVaild, setIsVaild] = useState<boolean>(false);
  const [key, setkey] = useState<string | null>(null);

  const api = useAPIwithCookies();

  const handleOpenPanel = () => {
    setPanelMode('expand');
  };

  const initViewer = async (imgPath: string) => {
    try {
      const nodeId = ctrl.nodeId;
      // const width = ctrl.props.option.imgSize.width;
      // const height = ctrl.props.option.imgSize.height;

      const resizeFactor = ctrl.props.option.resizeFactor;
      const patchSize = ctrl.props.option.patchSize;
      const overlapSize = ctrl.props.option.overlapSize;

      // imgPath가 있으면 기본 Viewer API를 호출한다.
      if (imgPath !== '') {
        const response = await api.get(
          `/viewer/generate_osd_frame/${nodeId}/` +
            `${resizeFactor}/${patchSize}/${overlapSize}/${imgPath}`,
        );
        const key = response.data;

        if (!key) {
          console.error('No default key returned.', response.data);
          return;
        }
        setkey(key);
      }
    } catch (err) {
      console.error('Error while fetching default patch:', err);
    }
  };

  useEffect(() => {
    const imgPath = ctrl.props.option.path;
    if (!imgPath) return;

    initViewer(imgPath);

    if (viewer) {
      viewer.world.resetItems();
      viewer.world.update();
      viewer.navigator.destroy();
    }
  }, [ctrl.props.option.path]);

  useEffect(() => {
    if (!viewer && key) {
      const tileSource =
        process.env.REACT_APP_VIENCE_API_KEY + `/viewer/patch_generation/${key}/patch_img.dzi`;

      const osdViewer = new OSD.Viewer({
        id: 'osd-viewer',
        prefixUrl: '/images/openseadragon/',
        tileSources: tileSource,
        crossOriginPolicy: 'Anonymous',
        animationTime: 0.5,
        blendTime: 0.1,
        constrainDuringPan: true,
        maxZoomPixelRatio: 50,
        minZoomLevel: 0.1,
        visibilityRatio: 1,
        zoomPerClick: 2,
        zoomPerScroll: 2,
        showNavigator: true,
        navigatorWidth: '250px',
        navigatorHeight: '250px',
        showFullPageControl: true,
        showHomeControl: true,
        showZoomControl: true,
        degrees: 0,
        showRotationControl: true,
      });

      osdViewer.addHandler('canvas-contextmenu', (event) => {
        event.originalEvent.preventDefault();
      });

      osdViewer.addHandler('open-failed', async (event) => {
        console.error('Tile loading failed.', event.message);

        try {
          const nodeId = ctrl.nodeId;
          // const width = ctrl.props.option.imgSize.width;
          // const height = ctrl.props.option.imgSize.height;
          const resizeFactor = resize;
          const patchSize = patch;
          const overlapSize = overlap;
          const imgPath = ctrl.props.option.path;

          // 백엔드에서 새 key를 다시 받아온다.
          // prettier-ignore
          const response = await api.get(`/viewer/generate_osd_frame/${nodeId}/${resizeFactor}/${patchSize}/${overlapSize}/${imgPath}`);
          const refreshedKey = response.data;
          if (!refreshedKey) {
            console.error('No new key returned.', response.data);
            return;
          }

          // 새 key를 이용해 tileSource를 다시 구성한다.
          const newTileSource =
            process.env.REACT_APP_VIENCE_API_KEY +
            `/viewer/patch_generation/${refreshedKey}/patch_img.dzi`;

          // 기존 viewer에 다시 open한다.
          osdViewer.open(newTileSource);
        } catch (err) {
          console.error('Failed to refresh key on open-failed:', err);
        }
      });

      setViewer(osdViewer);
      setIsVaild(true);
    }
  }, [key]);

  useEffect(() => {
    setResize(ctrl.props.option.resizeFactor);
    setResizeInput(ctrl.props.option.resizeFactor?.toString());
    setPatch(ctrl.props.option.patchSize);
    setOverlap(ctrl.props.option.overlapSize);
    setImgSize(ctrl.props.option.imgSize);

    const imgPath = ctrl.props.option.path;
    if (!imgPath) {
      setIsVaild(false);
      return;
    }
    setIsVaild(true);

    initViewer(imgPath);

    if (viewer) {
      viewer.world.resetItems();
      viewer.world.update();
      viewer.navigator.destroy();
    }
  }, [ctrl.id]);

  const handleResizeFactorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const floatRegex = /^[-+]?\d*\.?\d+(e[-+]?\d+)?$/i;
    if (floatRegex.test(e.target.value) && !isNaN(parseFloat(e.target.value))) {
      setResize(Number(e.target.value));
    }
    setResizeInput(e.target.value);
  };
  const handlePatchSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatch(Number(e.target.value));
  };
  const handleOverlapSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOverlap(Number(e.target.value));
  };

  // 사용자가 각 옵션의 value를 변경하면 Viewer를 업데이트한다.
  const handleViewImage = async () => {
    if (!viewer) {
      console.warn('Viewer is not ready yet');
      return;
    }
    if (!key) {
      console.warn('No key was assigned. Cannot partial update');
      return;
    }

    const nodeId = ctrl.nodeId;
    // const width = ctrl.props.option.imgSize.width;
    // const height = ctrl.props.option.imgSize.height;
    const resizeFactor = resize;
    const patchSize = patch;
    const overlapSize = overlap;
    const imgPath = ctrl.props.option.path;

    const response = await api.get(
      `/viewer/generate_osd_frame/${nodeId}/` +
        `${resizeFactor}/${patchSize}/${overlapSize}/${imgPath}`,
    );

    if (!response.data) {
      console.error('No new key returned.', response.data);
      return;
    }

    viewer.world.resetItems();
    const refreshedKey = response.data;

    // 새 key를 이용해 tileSource를 다시 구성한다.
    const newTileSource =
      process.env.REACT_APP_VIENCE_API_KEY +
      `/viewer/patch_generation/${refreshedKey}/patch_img.dzi?` +
      Math.random().toString(); // TODO 캐시 방지용 랜덤 쿼리스트링: 추후 최적화 필요

    // 기존 viewer에 다시 open한다.
    viewer.open(newTileSource);
    viewer.world.update();

    ctrl.setValue({
      path: ctrl.props.option.path,
      type: ctrl.props.option.type,
      imgSize: ctrl.props.option.imgSize,
      resizeFactor: resizeFactor,
      patchSize: patchSize,
      overlapSize: overlapSize,
    });
  };

  return (
    <>
      <div className='side-panel-normal-code'>
        <div className='side-panel-normal-code-title'>Patch Generation & Viewer</div>
        <div className='origin-size-wrapper'>
          <div className='origin-size-group-width'>
            <div className='origin-size-label'>Source Width</div>
            <input
              className='side-panel-normal-code-input origin-size-input'
              placeholder='0'
              value={imgSize.width}
              readOnly
            />
          </div>
          <div className='origin-size-group-height'>
            <div className='origin-size-label'>Source Height</div>
            <input
              className='side-panel-normal-code-input origin-size-input'
              placeholder='0'
              value={imgSize.height}
              readOnly
            />
          </div>
        </div>
        <div className='side-panel-normal-code-subtitle'>Resize Factor</div>
        <input
          className='side-panel-normal-code-input'
          value={resizeInput}
          onChange={handleResizeFactorChange}
          placeholder='1.0'
        />
        <div className='side-panel-normal-code-subtitle'>Patch Size</div>
        <input
          className='side-panel-normal-code-input'
          value={patch}
          onChange={handlePatchSizeChange}
          placeholder='0'
        />
        <div className='side-panel-normal-code-subtitle'>Overlap Size</div>
        <input
          className='side-panel-normal-code-input'
          value={overlap}
          onChange={handleOverlapSizeChange}
          placeholder='0'
        />
        <button
          onClick={() => {
            handleViewImage();
            handleOpenPanel();
          }}
          className='apply-patch'
        >
          View
        </button>
      </div>
      <div className='side-panel-expand'>
        {isVaild ? (
          <div id='osd-viewer' className='filled' style={{ display: 'block' }} />
        ) : (
          <div id='osd-viewer' className='empty' style={{ display: 'none' }} />
        )}
      </div>
    </>
  );
}
