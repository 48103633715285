import React, { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import API from '@/apis/common/apis';
import { ClassicPreset, NodeId } from 'rete';

import { IPatchGeneration } from '../../filter';
// import { updateControl } from '@/components/rete/reteUtils';
import { CustomSocket } from '../../sockets';
import { IDataflow } from '../flow';

import CustomControl from '@/components/rete/components/style/CustomControl';
import { EffectorColor } from '@/components/rete/components/style/CustomNode';
import { NodeSize } from '@/components/rete/components/style/CustomNode';
import '@/components/rete/components/nodes/patch-generation/PatchGeneration.scss';

export class PatchGenerationNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: PatchGenerationControl }
> {
  color = EffectorColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super('Patch Generation');
    this.addInput('in', new ClassicPreset.Input(new CustomSocket())); // 입력 소켓 in
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket())); // 출력 소켓 out
    // 컨트롤 등록
    this.addControl('ctrl', new PatchGenerationControl(process, nodeId ? nodeId : this.id));
  }

  // ***핵심: 노드 로직 (어떻게 in을 처리하고 out을 내보낼지)
  data(inputs: { in: IDataflow[] }): { out: IDataflow } {
    if (!inputs['in']) {
      return {
        out: {
          img_paths: {},
          effector: {
            input: [],
            node: {},
          },
        },
      };
    }

    const inputDataflow = inputs['in'][0]; // inputs의 첫번째 데이터를 가져옴
    const effectorNode = inputDataflow.img_paths; // 가져온 데이터의 img_paths를 가져옴
    const nodeKey = Object.keys(effectorNode)[0]; // img_paths의 첫번째 키를 가져옴
    const basePath = effectorNode[nodeKey]; // img_paths의 첫번째 키의 값을 가져옴
    const imgSize = inputDataflow.effector.node.data_selector.img_size; // **변경한 부분

    const effector = {
      input: [inputDataflow.effector], // 이전 노드도 계속 연결
      // effector.node에 patchGeneration 정보를 추가
      // 여기에 resize, patch, overlap 정보를 추가
      // 이렇게 하면 다음 노드가 패치 생성 정보를 이어받아 활용 가능
      // 만약 더 필요하다면 아래에 추가하면 됨
      node: {
        patch_generation: {
          node_id: this.id,
          type: this.controls.ctrl.props.option.type,
          resize_factor: this.controls.ctrl.props.option.resizeFactor,
          patch_size: this.controls.ctrl.props.option.patchSize,
          overlap_size: this.controls.ctrl.props.option.overlapSize,
        },
      },
    };
    // const setStatus = async () => {
    //   const params = new URLSearchParams(location.search);

    //   const cookie = new Cookies();
    //   const api = new API(cookie);
    //   const response = await api.post('/module/check_status', {
    //     wor_id: params['wor_id'],
    //     img_paths: inputDataflow.img_paths,
    //     effector: effector,
    //   });
    //   this.controls.ctrl.setStatus(response.data);
    // }
    // setStatus();

    // 노드 내부 컨트롤의 path가 basePath와 다르다면 컨트롤의 상태를 업데이트
    // 즉, 이 노드에서도 어떤 파일이 현재 선택 되었는지 알고 있어야 하므로
    // 이미지 파일이 선택되었을 때 같은 경로로 맞춰주어야 함
    if (this.controls.ctrl.props.option.path !== basePath) {
      this.controls.ctrl.setValue({
        path: basePath,
        type: 'image',
        imgSize: imgSize,
        resizeFactor: this.controls.ctrl.props.option.resizeFactor,
        patchSize: this.controls.ctrl.props.option.patchSize,
        overlapSize: this.controls.ctrl.props.option.overlapSize,
      });
    }

    // out에 IDataFlow 형태를 반환
    return {
      out: {
        img_paths: inputDataflow.img_paths, // 이전 노드에서 받은 경로 그대로 유지
        effector: effector,
      },
    };
  }
}

// 노드 내부에서 사용자의 설정 값을 보관하는 객체
export class PatchGenerationControl extends ClassicPreset.Control {
  props: {
    option: IPatchGeneration; // props.option에 resize, patch 등 필드를 저장
  };
  contextOpen: boolean;

  // status => 백그라운드에서 처리 상태
  // init: 처음 생성 / in-progress: 처리 중 / completed: 처리 완료 / error: 에러 발생생
  status: string;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      option: {
        path: '',
        type: '',
        imgSize: { width: 0, height: 0 }, // 이미지 원래 사이즈를 일단 보여주기
        resizeFactor: 1,
        patchSize: 512,
        overlapSize: 0,
      },
    };
    this.contextOpen = false;
    this.status = 'init';
  }

  // setValue를 통해 props.option에 값을 업데이트하고 onChange를 호출 -> 현재 상태 변경
  // 만약 UI에서 resize 100 -> 200으로 변경하면 setValue()를 통해 값이 들어가고
  // onChange()를 통해 rete쪽이 '노드 내부 값이 바뀌었구나' 인식할 것임
  setValue(value: IPatchGeneration) {
    this.props.option = value;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };

  setStatus = (status: string) => {
    this.status = status;
  };
}

// Rete 노드의 UI 컴포넌트
export function PatchGeneration({ data }: { data: PatchGenerationControl }) {
  return (
    <>
      <CustomControl
        contextOpen={data.contextOpen}
        setContextOpen={data.setContextOpen}
        label='Patch Generation'
        nodeId={data.nodeId}
        markerSource='node-effector'
        iconSource='patch'
        status={data.status}
        setStatus={data.setStatus}
      />
    </>
  );
}
