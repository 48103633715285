import React, { useContext } from 'react';
import styled from 'styled-components';

import { TrainSessionItem } from './TrainDetail';
import StopTrainBtn
  from '@/components/rete-mlops/components/panel-contents/Viewer/train-result/StopTrainBtn';
import { CustomThemeContext } from '@/contexts/common/Context';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { TrainContext } from '@/contexts/TrainContext';

const ContainerBtn = styled.button<{
  $isSelected: boolean;
  $theme: 'light' | 'dark';
}>`
  width: 100%;
  border-radius: var(--10px) 0 0 var(--10px);
  padding: var(--8px) var(--4px) var(--8px) var(--8px);

  display: flex;
  align-items: center;
  gap: var(--8px);

  ${(props) =>
    props.$theme === 'light'
      ? `
    border-bottom: 2px solid #D9D9E5;
    `
      : `
    border-bottom: 2px solid #1B1B47;
  `};

  ${(props) =>
    !props.$isSelected
      ? `
    background-color: transparent;
  `
      : props.$theme === 'dark'
        ? `
    background-color: #010115;
    box-shadow: 0 0 var(--12px) #9397a6;
    `
        : `
    background-color: #f0f2fd;
    box-shadow: 0 0 var(--12px) #9397a6;
  `}

  h4 {
    width: 100%;
    word-break: break-all;
    text-align: left;
    font-size: var(--13px);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-height: 1.5em;

    margin-bottom: var(--4px) !important;
  }

  div.text-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0;
    font-size: var(--10px);
  }
  ${(props) =>
    props.$theme === 'light'
      ? `
    color: #000000;
    `
      : `
    color: #ffffff;
  `};
`;

type TrainSessionListItemProps = {
  trainSessionItem: TrainSessionItem;
  getTrainSessionList: () => void;
};

export default function TrainSessionListItem({
  trainSessionItem,
  getTrainSessionList,
}: TrainSessionListItemProps) {
  const api = useAPIwithCookies();
  const { setIsTraining, selected, setSelected } = useContext(TrainContext);
  const { theme } = useContext(CustomThemeContext);

  const handleStopBtn = async () => {
    if (
      window.confirm(
        `are you sure to stop ${trainSessionItem?.key} train-session?`
      )
    ) {
      await api.get(`/mlops/train_session_stop/${trainSessionItem?.key}`);
      alert(`${trainSessionItem?.key} will be stopped`);
      setIsTraining(false);
      getTrainSessionList();
    }
  };

  return (
    <ContainerBtn
      onClick={() => setSelected(trainSessionItem)}
      $isSelected={selected?.key === trainSessionItem.key}
      $theme={theme}
    >
      <StopTrainBtn
        handleStopBtn={handleStopBtn}
        resultData={trainSessionItem}
      />
      <div className='text-info'>
        <h4>{trainSessionItem.key}</h4>
        <p>{trainSessionItem.status.time}</p>
      </div>
    </ContainerBtn>
  );
}
