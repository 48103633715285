import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

export const saveCanvasData = async (
  api: ReturnType<typeof useAPIwithCookies>,
  wor_id: any,
  nodes: string,
  title: string,
  category: string,
  description: string,
) => {
  try {
    await api.put(`/workspace/save`, {
      wor_id,
      nodes,
      title,
      category,
      description,
    });
    // 해당콘솔은 지우지 말아주세요
    console.log('save canvas');
    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};
