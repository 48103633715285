import React, { ReactNode, useContext, useEffect, useState } from 'react';

import Menu from '@/components/common/menu/Menu';
import { IModelList } from '@/components/rete/components/nodes/effector/deeplearning/deeplearning';

import { CustomThemeContext } from '@/contexts/common/Context';

import useDataHubStore from '@/stores/datahub/useDataHubStore';
import useContextMenuStore from '@/stores/datahub/useDataHubMenuStore';

import { createDir, renameItem, deleteItem } from '@/apis/datahub/directoryApis';
import {
  arrangeFileDirectory,
  fetchModelList,
  switchDataset,
  switchModel,
  switchWeight,
} from '@/apis/datahub/menuApis';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

import { IfileNdirList } from '@/types/common/type';

export default function DataHubMenu({ children }: { children: ReactNode }) {
  const api = useAPIwithCookies();
  const { theme } = useContext(CustomThemeContext);
  const { curDir, getDirectory, selected } = useDataHubStore();

  const { contextOpen, contextEvent, contextTarget, setContext, setContextOpen } =
    useContextMenuStore();

  const tags = contextTarget?.tagging_progress.map((item) => item[0]);

  const [modelList, setModelList] = useState<{ [key: string]: IModelList[] }>({});

  const handleDataset = async (target: IfileNdirList) => {
    await switchDataset(api, target);
  };

  const handleModel = async (target: IfileNdirList, node: string) => {
    await switchModel(api, target, node);
  };

  const handleWeight = async (target: IfileNdirList, dmo_seq: number) => {
    await switchWeight(api, target, dmo_seq);
  };

  // ** 베타테스트 배포를 위해 태그 관련 기능은 임시 주석 처리
  // const handleTagDelete = async (target: IfileNdirList, tag: string) => {
  //   const response = await api.post(`/tag/untagging/${target.name}/${tag}`);
  //   if (response.status === 200) {
  //     getDirectory(curDir, api);
  //   }
  // };

  const handleCreateDir = async () => {
    await createDir(api, curDir, theme);
    getDirectory(curDir, api);
  };

  const handleRenameItem = async () => {
    await renameItem(api, curDir, theme, selected);
    getDirectory(curDir, api);
  };

  const handleDeleteItem = async () => {
    await deleteItem(api, curDir, theme, selected);
    getDirectory(curDir, api);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Delete') {
        handleDeleteItem();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleDeleteItem]);

  const arrangeFileDir = async (mode: string) => {
    try {
      await arrangeFileDirectory(api, mode);
    } catch (err) {
      console.error(err);
    } finally {
      getDirectory(curDir, api);
    }
  };

  const getModelList = async () => {
    const data = await fetchModelList(api);
    setModelList(data);
  };

  // useEffect(() => {
  //   getModelList();
  // }, []);
  
  const ContextMenufileNdir = () => {
    if (contextTarget === undefined) return null;
    switch (contextTarget.type) {
      case 'dir':
      case 'dataset':
      case 'model':
        return (
          <Menu
            event={contextEvent}
            containerId='canvas-card-frame'
            onClose={setContextOpen}
            functions={[
              { label: 'Delete', on: handleDeleteItem },
              {
                label: 'Rename',
                on: () => {
                  handleRenameItem();
                },
              },
              {
                label: 'Category',
                sub: [
                  {
                    label: 'Add Dataset',
                    on: () => {
                      handleDataset(contextTarget);
                    },
                  },
                  {
                    label: 'Add Model',
                    sub: [
                      {
                        label: '2D Segmentation',
                        on: () => {
                          handleModel(contextTarget, 'segmentation_2d');
                        },
                      },
                      {
                        label: '3D Segmentation',
                        on: () => {
                          handleModel(contextTarget, 'segmentation_3d');
                        },
                      },
                      {
                        label: 'Classification',
                        on: () => {
                          handleModel(contextTarget, 'classification');
                        },
                      },
                      {
                        label: 'Denoising',
                        on: () => {
                          handleModel(contextTarget, 'denoising');
                        },
                      },
                    ],
                  },
                ],
              },
              // ** 베타테스트 배포를 위해 태그 관련 기능은 임시 주석 처리
              // {
              //   label: 'Tag Delete',
              //   sub: tags.map((tag: string) => ({
              //     label: tag,
              //     on: () => {
              //       handleTagDelete(contextTarget, tag);
              //     },
              //   })),
              // },
            ]}
          />
        );
      case 'weight':
        return (
          <Menu
            event={contextEvent}
            containerId='canvas-card-frame'
            onClose={setContextOpen}
            functions={[
              { label: 'Delete', on: handleDeleteItem },
              {
                label: 'Rename',
                on: () => {
                  handleRenameItem();
                },
              },
              {
                label: 'Category',
                sub: [
                  {
                    label: 'Add Weight',
                    sub: [
                      {
                        label: '2D Segmentation',
                        sub: modelList['segmentation_2d'].map((data) => {
                          return {
                            label: data.dmo_name,
                            on: () => {
                              handleWeight(contextTarget, data.dmo_seq);
                            },
                          };
                        }),
                      },
                      {
                        label: '3D Segmentation',
                        sub: modelList['segmentation_3d'].map((data) => {
                          return {
                            label: data.dmo_name,
                            on: () => {
                              handleWeight(contextTarget, data.dmo_seq);
                            },
                          };
                        }),
                      },
                      {
                        label: 'Classification',
                        sub: modelList['classification'].map((data) => {
                          return {
                            label: data.dmo_name,
                            on: () => {
                              handleWeight(contextTarget, data.dmo_seq);
                            },
                          };
                        }),
                      },
                      {
                        label: 'Denoising',
                        sub: modelList['denoising'].map((data) => {
                          return {
                            label: data.dmo_name,
                            on: () => {
                              handleWeight(contextTarget, data.dmo_seq);
                            },
                          };
                        }),
                      },
                    ],
                  },
                ],
              },
            ]}
          />
        );
      default:
        return (
          <Menu
            event={contextEvent}
            containerId='canvas-card-frame'
            onClose={setContextOpen}
            functions={[
              { label: 'Delete', on: handleDeleteItem },
              {
                label: 'Rename',
                on: () => {
                  handleRenameItem();
                },
              },
            ]}
          />
        );
    }
  };

  return (
    <div
      onContextMenu={(e) => {
        setContext(e, undefined);
      }}
      onClick={() => {
        setContextOpen(false);
      }}
      id='canvas-card-frame'
      className='h-full overflow-auto'
    >
      {children}
      {contextOpen &&
        (contextTarget === undefined ? (
          <Menu
            event={contextEvent}
            containerId='canvas-card-frame'
            onClose={setContextOpen}
            functions={[
              { label: 'Make Directory', on: handleCreateDir },
              {
                label: 'Sort By',
                sub: [
                  {
                    label: 'name_asc',
                    on: () => {
                      arrangeFileDir('name_asc');
                    },
                  },
                  {
                    label: 'name_desc',
                    on: () => {
                      arrangeFileDir('name_desc');
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <ContextMenufileNdir />
        ))}
    </div>
  );
}
