import React, { useContext, useEffect, useState } from 'react';

import TrainingAnimation from '../Viewer/train-control/TrainingAnimation';

import OptionInput from '../PanelOptionComponent/OptionInput';
import CustomSelect from '../PanelOptionComponent/CustomSelect';
import { styled } from 'styled-components';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { TrainContext } from '@/contexts/TrainContext';
import PrimaryButton from '@/components/common/buttons/PrimaryButton';

const Container = styled.form`
  .assistvie-text {
    margin: var(--8px) 0 var(--16px) 0 !important;
    color: #9d97a5;
    font-size: var(--14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
  }

  button {
    margin: 0 0 var(--24px) 0;
    width: 100%;
  }

  .trainer-control-btn {
    display: flex;
    gap: var(--16px);
    justify-content: center;
  }
`;

export default function FineTunerOptionSection({
  nodeId,
  initOption,
  option,
  setOption,
  loading,
  setLoading,
  setPanelMode,
  setSelectedTab,
}) {
  const { setIsTraining, isTraining, functions } = useContext(TrainContext);

  const [modelName, setModelName] = useState<string>('');
  const [newWeightName, setNewWeightName] = useState<string>('');
  const [tunerType, setTunerType] = useState('');
  const [selectedTrainedWeight, setSelectedTrainedWeight] = useState('');
  const { startTrain } = functions;

  const [trainedWeightList, setTrainedWeightList] = useState([]);
  const [tunerTypeList, setTunerTypeList] = useState([]);

  // api 연결
  const api = useAPIwithCookies();

  // 각 input에 초기값 세팅되기 전까지 로딩상태여야함
  useEffect(() => {
    setLoading(true);
    setNewWeightName('');
    setTunerType('');
    setSelectedTrainedWeight('');
  }, [nodeId]);

  useEffect(() => {
    if (initOption.current.modelName) {
      setModelName(initOption.current.modelName);
    } else {
      setModelName('');
    }
  }, [initOption.current.modelName]);

  useEffect(() => {
    if (!modelName) return;
    api.get(`/mlops/trained_weight_list/${modelName}`).then((res) => {
      setTrainedWeightList(res.data);
      setSelectedTrainedWeight(res.data[0]);
    });
    api.get(`/mlops_finetuning/finetuner_type_list`).then((res) => {
      setTunerTypeList(res.data);
      setTunerType(res.data[0]);
    });
  }, [modelName]);

  useEffect(() => {
    if (!loading) {
      setOption((prev) => ({
        ...prev,
        modelName: modelName,
        newWeightName: newWeightName,
        tunerType: tunerType,
        selectedTrainedWeight: selectedTrainedWeight,
      }));
    }
  }, [modelName, newWeightName, tunerType, selectedTrainedWeight, setOption]);

  // api 통신
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPanelMode('expand');
    setSelectedTab('trainResult');
    setIsTraining(true);
    startTrain();
  };

  return (
    <Container>
      {isTraining ? (
        <PrimaryButton className='trainer-control-btn' disabled={true}>
          <TrainingAnimation />
          Training...
        </PrimaryButton>
      ) : (
        <PrimaryButton
          className='fine-tuner start-btn'
          type='submit'
          onClick={handleSubmit}
        >
          Start Fine-Tuning
        </PrimaryButton>
      )}

      <Container name='convolution2dOption'>
        <div>
          <p>ModelName</p>
          <p className='assistvie-text'>{modelName}</p>
        </div>
        <CustomSelect
          id={'selectedTrainedWeight'}
          setValue={setSelectedTrainedWeight}
          label={'Base Weight'}
          options={trainedWeightList}
          initOption={initOption}
          setLoading={setLoading}
        />
        <CustomSelect
          id={'tunerType'}
          label={'Fine Tuner Type'}
          options={tunerTypeList}
          keyword=''
          setValue={setTunerType}
          initOption={initOption}
          setLoading={setLoading}
        />
        <OptionInput
          initOption={initOption}
          setValue={setNewWeightName}
          id={'newWeightName'}
          label='New Weight Name = Session Key'
          type='text'
          setLoading={setLoading}
        />
      </Container>
    </Container>
  );
}
