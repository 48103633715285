import React, { useEffect } from "react";
import { ClassicPreset, NodeId } from "rete";
import { IDataflow } from "../../nodes/flow";
import "./DataManagement.scss";
import { CustomSocket } from "../../sockets";
import { filedirName } from "../../../../routes/datamanage/storage/storage.type";
import "../../../side-panel/side-panel.styles.scss";
import { NodeSize, SourceColor } from "../../style/CustomNode";
import CustomControl from "../../style/CustomControl";
import { updateControl } from "../../../reteUtils";

export class DataManageNode extends ClassicPreset.Node<
  {},
  { out: ClassicPreset.Socket },
  { ctrl: DataManageControl }
> {
  color = SourceColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super("DataManage");
    this.addOutput("out", new ClassicPreset.Output(new CustomSocket()));
    this.addControl(
      "ctrl",
      new DataManageControl(process, nodeId ? nodeId : this.id)
    );
  }

  data(): { out: IDataflow } {
    return {
      out: {
        img_paths: {
          [this.id]: this.controls.ctrl.props.option.path,
        },
        effector: {
          input: [this.id],
          node: {},
        },
      },
    };
  }
}

export class DataManageControl extends ClassicPreset.Control {
  props: {
    option: {
      path: string;
      thumbnail: string;
    };
  };
  contextOpen: boolean;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId
  ) {
    super();
    this.props = {
      option: {
        path: "",
        thumbnail: "",
      },
    };
    this.contextOpen = false;
  }
  setValue(data: { path: filedirName; thumbnail: string }) {
    this.props.option = data;
    this.onChange();
  }
  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    updateControl(this.id);
  };
}

export function DataManageComp({ data }: { data: DataManageControl }) {
  useEffect(() => {
    console.log("DataManagement", data);
  }, [data]);
  return (
    <CustomControl
      label="Data Management"
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
      nodeId={data.nodeId}
      markerSource={"node-source"}
      iconSource="datamanage"
    />
  );
}
