import React from 'react';

import Header from '@/components/common/layouts/header/Header';
import DataHubBody from '@/components/datahub/datahub-body';
import DataHubProcess from '@/components/datahub/DataHubProgress';

function MobileDataHub({ showProgress }) {
  return (
    <div>
      <Header />
      <div
        className='h-[calc(100vh-72px)] overflow-hidden bg-white100 dark:bg-black200'
        onContextMenu={(e) => e.preventDefault()}
      >
        {/* Left/Right Panel 없이 Main Content만 보임 */}
        <div className='w-full rounded-tl-[30px] bg-white100 dark:bg-black200'>
          <div className='relative w-full bg-white100 px-4 py-1 dark:bg-black200'>
            {!showProgress && <DataHubBody />}
            {showProgress && <DataHubProcess />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileDataHub;
