import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { IfileNdirList } from '@/types/common/type';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const DataHubContext = createContext<{
  dirLoading?: boolean;
  loading?: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  progress?: { filename: string; progress: number };
  setProgress: (data: { filename: string; progress: number }) => void;
  getDirectory: (trimmedPath?: any) => void;
  setCurDir: (a: string) => void;
  curDir: string;
  fileNdirList: IfileNdirList[];
  setEndIndex: (n: number | undefined) => void; // DataHubItem 사용중
  setStartIndex: (n: number) => void;
  selected: string[];
  setSelected: (f: string[]) => void;
  selectedFile: { [key in string]: string };
  setSelectedFile: (e: object) => void;
  target: any;
  setTarget: (s: string | undefined) => void;
  setSelectOpen: (a: boolean) => void;
  selectOpen: boolean;
  dragTag: { tagName: string; key: string; valid: boolean; color: string }; // DataHubRightPanel 사용중
  setDragTag: Dispatch<
    SetStateAction<{
      tagName: string;
      key: string;
      valid: boolean;
      color: string;
    }>
  >; // DataHubRightPanel 사용중
}>({
  fileNdirList: [],
  curDir: '',
  loading: false,
  setProgress: (data: { filename: string; progress: number }) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurDir: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setEndIndex: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStartIndex: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getDirectory: (trimmedPath?: any) => {},
  selected: [''],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelected: () => {},
  selectedFile: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedFile: () => {},
  target: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTarget: () => {},
  selectOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectOpen: () => {},
  dragTag: { tagName: '', key: '', valid: false, color: '' },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDragTag: () => {},
});

const DataHubContextProvider = ({ children }: { children: ReactNode }) => {
  const api = useAPIwithCookies();
  const [curDir, setCurDir] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<{
    filename: string;
    progress: number;
  }>({ filename: '', progress: 0 });
  const [dirLoading, setDirLoading] = useState(false);
  const [fileNdirList, setFileNdirList] = useState<IfileNdirList[]>([]);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number | undefined>(-1);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [target, setTarget] = useState<string | undefined>(undefined);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [dragTag, setDragTag] = useState<{
    tagName: string;
    key: string;
    valid: boolean;
    color: string;
  }>({ tagName: '', key: '', valid: false, color: '' });

  useEffect(() => {
    if (endIndex !== undefined) {
      if (startIndex < endIndex)
        setSelected([
          ...fileNdirList.slice(startIndex, endIndex + 1).map((data) => {
            return data.name;
          }),
        ]);
      else
        setSelected([
          ...fileNdirList.slice(endIndex, startIndex + 1).map((data) => {
            return data.name;
          }),
        ]);
    }
  }, [endIndex]);

  const getDirectory = async (trimmedPath?: any) => {
    setDirLoading(true);

    if (trimmedPath === undefined) {
      const response = await api.get('/cloud/list_with_tag_info', {
        email: '',
        mem_id: '',
        cur_dir: curDir,
        names: [],
        change: '',
        arrange: '',
      });

      setFileNdirList(response.data);
      setDirLoading(false);

      return;
    }
    const response = await api.get('/cloud/list_with_tag_info', {
      email: '',
      mem_id: '',
      cur_dir: trimmedPath,
      names: [],
      change: '',
      arrange: '',
    });
    setFileNdirList(response.data);
    setDirLoading(false);

    return response.data;
  };

  return (
    <DataHubContext.Provider
      value={{
        curDir,
        setCurDir,
        progress,
        loading,
        setProgress,
        setLoading,
        dirLoading,
        fileNdirList,
        getDirectory,
        setEndIndex,
        setStartIndex,
        selected,
        setSelected,
        selectedFile,
        setSelectedFile,
        target,
        setTarget,
        selectOpen,
        setSelectOpen,
        dragTag,
        setDragTag,
      }}
    >
      {children}
    </DataHubContext.Provider>
  );
};

export default DataHubContextProvider;
