import './diagram.styles.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRete } from 'rete-react-plugin';
import { area, createEditor, editor } from '../../../rete/rete';

import CanvasHeader from '../../../components/canvasHeader/CanvasHeader';
import ControlPanel from '../../../rete/control-panel/control-panel.component';
import SidePanel from '../../../rete/side-panel/side-panel.component';
import WorkspacePanel from '../../../rete/workspace-panel.component';

import { handleReteTheme } from '../../../utils/Context';
import { NodeContext } from '../../../rete/NodeContext';
import { closeAllContext, process } from '../../../rete/reteUtils';
import { createByJson, createToJson } from '../../../rete/workspace';
import { useAPIwithCookies } from '../../../hooks/useApiCookies';

import { IWorkspaceSetting } from '../workspace/workspace';

function App() {
  const [editable, setEditable] = useState<boolean>(true);
  const params = useParams();
  const api = useAPIwithCookies();

  const [ref] = useRete(createEditor);
  const { setNodeId } = useContext(NodeContext);
  const [title, setTitle] = useState<string>('untitled');
  const [setting, setSetting] = useState<IWorkspaceSetting>({
    category: '',
    description: '',
    ipAddress: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const getCanvas = async () => {
    setLoading(true);
    const response = await api.get(`/workspace/${params.wor_id}`);
    const data = response.data;
    setTitle(data.title);
    setEditable(data.edit_yn);
    setSetting({
      category: data.category ?? '',
      description: data.description ?? '',
      ipAddress: data.ipAddress ?? '',
    });
    if (data.nodes !== undefined) {
      try {
        await createByJson(JSON.parse(data.nodes));
      } catch (err) {
        console.error(err);
      }
    }
    handleReteTheme();
    setLoading(false);
  };

  const saveCanvas = async () => {
    console.log('save canvas');
    if (!editable) return;
    // if (setting.ipAddress && !checkIpAddress(setting.ipAddress)) return;
    const json = await createToJson();
    await api.put(`/workspace/save`, {
      wor_id: params.wor_id,
      nodes: json,
      title: title === null ? 'undefined' : title,
      category: setting.category,
      // ipAddress: setting.ipAddress,
      description: setting.description,
    });
  };

  if (area) {
    area.addPipe((context) => {
      if (context.type === 'nodepicked') {
        const pickedId = context.data.id;
        setNodeId(pickedId);
      }
      return context;
    });
    // grid snap 기능 구현
    area.addPipe(async (context) => {
      if (['nodedragged'].includes(context.type)) {
        if (context.type === 'nodedragged') {
          const x = area.nodeViews.get(context.data.id)?.position.x;
          const y = area.nodeViews.get(context.data.id)?.position.y;
          if (x && y) {
            let new_x, new_y;
            if (x >= 0) {
              if (x % 100 >= 50) new_x = x - (x % 100) + 100;
              else new_x = x - (x % 100);
            } else {
              if (x % 100 < -50) new_x = x - (x % 100) - 100;
              else new_x = x - (x % 100);
            }
            if (y >= 0) {
              if (y % 100 >= 50) new_y = y - (y % 100) + 100;
              else new_y = y - (y % 100);
            } else {
              if (y % 100 < -50) new_y = y - (y % 100) - 100;
              else new_y = y - (y % 100);
            }
            await area.translate(context.data.id, { x: new_x, y: new_y });
            await saveCanvas();
          }
        }
      }
      return context;
    });
  }
  if (editor) {
    editor.addPipe((context) => {
      if (
        [
          'nodecreated',
          'noderemoved',
          'connectioncreated',
          'connectionremoved',
        ].includes(context.type)
      ) {
        if (!loading) {
          process();
          saveCanvas();
          setNodeId('');
        }
      }
      return context;
    });
  }

  useEffect(() => {
    getCanvas();
    setNodeId('');
  }, [params]);

  useEffect(() => {
    const interval = setInterval(() => {
      saveCanvas();
    }, 5000); //테스트용 30초, 서비스 시 5초
    return () => clearInterval(interval);
  }, [params, title, setting]);

  return (
    <div className='canvas-edit'>
      <CanvasHeader />
      <WorkspacePanel
        title={title}
        setTitle={setTitle}
        save={saveCanvas}
        setSetting={setSetting}
        setting={setting}
      />
      <div className='main-canvas-content'>
        <div className='main-canvas-left'>
          <ControlPanel />
        </div>
        <div className='main-canvas-middle'>
          <div className='rete' ref={ref} onClick={closeAllContext} />
        </div>
        <div className='main-canvas-right'>
          <SidePanel />
        </div>
      </div>
    </div>
  );
}

export default App;
