import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import Swal from 'sweetalert2';

// 정보 조회
export const fetchMyInfo = async (
  api: ReturnType<typeof useAPIwithCookies>,
  setJob: any,
  setName: any,
  setNickName: any,
  setOrgName: any,
  setEmail: any,
  setOranization: any,
  setPrupose: any,
  setReceiveEmail: any,
) => {
  try {
    const response = await api.get('/member/get_info');
    setJob(response.data.job);
    setName(response.data.name);
    setNickName(response.data.nickname);
    setOrgName(response.data.org_name);
    setOranization(response.data.organization);
    setPrupose(response.data.purpose);
    setEmail(response.data.user_email);
    setReceiveEmail(response.data.recieve_yn);
  } catch (error) {
    console.error('Error fetching pipe list', error);
  }
};

// 정보 업데이트
export const updateMyInfo = async (
  api: ReturnType<typeof useAPIwithCookies>,
  email: any,
  job: any,
  name: any,
  nickName: any,
  orgName: any,
  oranization: any,
  purpose: any,
  setReceiveEmail: any,
) => {
  try {
    const response = await api.post('/member/update_info', {
      email: email,
      name: name,
      nickname: nickName,
      google_yn: false,
      organization: oranization,
      org_name: orgName,
      job: job,
      purpose: purpose,
      recieve_yn: setReceiveEmail,
    });
  } catch (error) {
    console.error('Error fetching pipe list', error);
  }
};

// 인증 코드 전송 api
export const sendAuthenticationCode = async (api: ReturnType<typeof useAPIwithCookies>) => {
  try {
    const response = await api.post('/member/request_authentication_code');
    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: 'A verification code has been sent. Check your email',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }
  } catch (error) {
    console.error('Error fetching pipe list', error);
  }
};

// 비밀번호 변경
export const changePassword = async (
  api: ReturnType<typeof useAPIwithCookies>,
  passwordVerificationCode: any,
  newPassword: any,
) => {
  try {
    const response = await api.post('/member/update_password', {
      auth_code: passwordVerificationCode,
      new_password: newPassword,
    });

    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: 'Thank you',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  } catch (error) {
    Swal.fire({
      title: 'Failed',
      text: 'Failed to change password',
      icon: 'error',
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

// 회원 탈퇴 api
export const requestDelete = async (
  api: ReturnType<typeof useAPIwithCookies>,
  deleteAccountVerificationCode: any,
) => {
  try {
    const response = await api.post(
      `/member/request_delete?auth_code=${deleteAccountVerificationCode}`,
    );

    if (response.status === 200) {
      Swal.fire({
        title: 'Success',
        text: 'Withdrawal will be completed within 24 hours, and you will be notified by email when withdrawal is complete. Thank you for being with us so far.',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  } catch (error) {
    Swal.fire({
      title: 'Failed',
      text: 'Membership withdrawal failed',
      icon: 'error',
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
