import React, {
  ReactNode,
  createContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { editor } from '@/components/rete/rete';

import { editor as mlopsEditor } from '@/components/rete-mlops/editor';
import { updateNode } from '@/components/rete/reteUtils';

import { updateNode as updateMlopsNode } from '@/components/rete-mlops/reteMlopsUtils';

// Login 관리 Context
export const LoginContext = createContext<{
  user?: any;
  isAuthReady?: boolean;
  dispatch?: any;
}>({});

const authReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return { ...state, user: action.payload, isAuthReady: true };
    case 'logout':
      return { ...state, user: null };
    case 'authIsReady':
      return { ...state, user: action.payload, isAuthReady: true };
    default:
      return state;
  }
};

export function LoginProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    isAuthReady: false,
  });

  useEffect(() => {
    if (!state.isAuthReady) {
      const access = localStorage.getItem('access');
      const email = localStorage.getItem('email');
      const user = {
        access,
        email,
      };
      dispatch({ type: 'authIsReady', payload: user });
    }
  }, []);

  return (
    <LoginContext.Provider value={{ ...state, dispatch }}>
      {children}
    </LoginContext.Provider>
  );
}

export const CustomThemeContext = createContext<{
  theme: 'dark' | 'light';
  toggleTheme: () => void;
}>({ theme: 'dark', toggleTheme: () => {
  //
} });

export const handleReteTheme = () => {
  const minimapDOM = document.getElementById('minimap');
  const viewportDOM = document.getElementById('minimap-viewport');
  const nodes = editor?.getNodes();
  const mlopsNodes = mlopsEditor?.getNodes();
  const theme = localStorage.getItem('theme');

  if (theme === 'dark') {
    minimapDOM?.style.setProperty('background', '#010418');
    minimapDOM?.style.setProperty('border', '2px solid #3C2857');
    viewportDOM?.style.setProperty('background', '#090E2CCC');
    viewportDOM?.style.setProperty('border', '1px solid #121D64');

    nodes?.forEach((el) => {
      updateNode(el.id);
    });
  } else {
    minimapDOM?.style.setProperty('background', '#E9EBF8');
    minimapDOM?.style.setProperty('border', '2px solid #D2BEEC');
    viewportDOM?.style.setProperty('background', '#E6E8F9CC');
    viewportDOM?.style.setProperty('border', '1px solid #BDC0D4');

    nodes?.forEach((el) => {
      updateNode(el.id);
    });
  }
  mlopsNodes?.forEach((el) => {
    updateMlopsNode(el.id);
  });
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<'dark' | 'light'>('dark');
  const body = document.body;

  const toggleTheme = () => {
    const minimapDOM = document.getElementById('minimap');
    const viewportDOM = document.getElementById('minimap-viewport');
    const nodes = editor?.getNodes();
    const mlopsNodes = mlopsEditor?.getNodes();

    if (theme === 'dark') {
      localStorage.setItem('theme', 'light');
      body.classList.replace('dark', 'light');
      minimapDOM?.style.setProperty('background', '#E9EBF8');
      minimapDOM?.style.setProperty('border', '2px solid #D2BEEC');
      viewportDOM?.style.setProperty('background', '#E6E8F9CC');
      viewportDOM?.style.setProperty('border', '1px solid #BDC0D4');

      nodes?.forEach((el) => {
        updateNode(el.id);
      });
    } else {
      localStorage.setItem('theme', 'dark');
      body.classList.replace('light', 'dark');
      minimapDOM?.style.setProperty('background', '#010418');
      minimapDOM?.style.setProperty('border', '2px solid #3C2857');
      viewportDOM?.style.setProperty('background', '#090E2CCC');
      viewportDOM?.style.setProperty('border', '1px solid #121D64');

      nodes?.forEach((el) => {
        updateNode(el.id);
      });
    }
    mlopsNodes?.forEach((el) => {
      updateMlopsNode(el.id);
    });
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) body.classList.add(theme);
    else body.classList.add('dark');
    if (theme === 'light') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }, []);

  return (
    <CustomThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </CustomThemeContext.Provider>
  );
};
