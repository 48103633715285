import React, { useEffect, useState } from "react";
import { default as OSD, Overlay, Point, Rect } from "openseadragon";
import { useLocation } from "react-router-dom";
import "./image.styles.scss";

export default function TaskImage() {
  const [viewer, setViewer] = useState<OSD.Viewer>();
  const location = useLocation();
  const fileDir = useLocation().pathname.slice(15);
  console.log(fileDir);
  const token = localStorage.getItem("access");

  useEffect(() => {
    const src = process.env.REACT_APP_VIENCE_API_KEY + "/" + fileDir;
    const osdViewer = new OSD.Viewer({
      id: "full-image",
      prefixUrl: "openseadragon-images/",
      tileSources: src,
      crossOriginPolicy: "Anonymous",
      animationTime: 0.5,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 50,
      minZoomLevel: 0.1,
      visibilityRatio: 1,
      zoomPerClick: 1,
      zoomPerScroll: 2,
      showNavigator: true,
      navigatorWidth: "250px",
      navigatorHeight: "250px",
      showFullPageControl: false,
      showHomeControl: false,
      showZoomControl: false,
    });

    osdViewer.addHandler("canvas-contextmenu", (e) => {
      e.originalEvent.preventDefault();
    });
    osdViewer.addHandler("open-failed", (e) => {
      if (e.message.includes("402")) {
        console.log("402");
      }
    });
    setViewer(osdViewer);
    return () => osdViewer.destroy();
  }, []);

  return (
    <div className="image-system">
      <div className="image-osd" id="full-image" />
    </div>
  );
}
