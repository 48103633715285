import { NodeEditor, NodeId } from 'rete';
import { Schemes, area, editor, engine } from './rete';
import { NodeTypes } from './rete';
import { DataSelectorNode } from './components/nodes/data-selector';
import { CustomProcessingNode } from './components/nodes/custom/CustomProcessing';
import { ImageCropNode } from './components/nodes/effector/ImageCrop';
import { EdgeDetectNode } from './components/nodes/effector/EdgeDectection';
import { DenoisingNode } from './components/nodes/effector/deeplearning/Denoising';
import { ViewerNode } from './components/nodes/viewer/ViewerNode';
import { BlenderNode } from './components/nodes/effector/Blender';
import { GearNode } from './components/nodes/addon/Gear';
import { DatasetConfigurationNode } from './components/nodes/custom/DatasetConfiguration';
import { PatchGenerationNode } from './components/nodes/patch-generation';
import { FormGeneraterNode } from './components/nodes/addon/FormGenerator';
import { ImageProcessingNode } from './components/nodes/custom/ImageProcessing';
export function getConnectionSockets(
  editor: NodeEditor<Schemes>,
  connection: Schemes['Connection'],
) {
  const source = editor.getNode(connection.source);
  const target = editor.getNode(connection.target);
  const output = source && (source.outputs as Record<string, any>)[connection.sourceOutput];
  const input = target && (target.inputs as Record<string, any>)[connection.targetInput];
  return {
    source: output?.socket,
    target: input?.socket,
  };
}
// 각 Node의 data() 실행
let isProcessing = false;
export const process = async () => {
  if (isProcessing) {
    console.warn('Process is already running.');
    return;
  }
  isProcessing = true;

  try {
    engine.reset();
    const nodes = editor.getNodes();
    await Promise.all(
      nodes.map((n) =>
        engine.fetch(n.id).catch((error) => console.error('Error fetching node:', error)),
      ),
    );
  } finally {
    isProcessing = false;
  }
};

// Control update
export const updateNode = async (nodeId: NodeId) => {
  try {
    await area.update('node', nodeId);
  } catch (error) {
    console.warn('Node 업데이트 중 취소됨:', error);
  }
};
// Node가 가지고 있는 모든 ContextMenu 닫기

// export const closeAllContext = async () => {
//   await editor.getNodes().filter((n) => {
//     n.controls.ctrl?.setContextOpen(false);
//   });
// };

// Node 삭제, Conn제거 => Node 제거
export const deleteNode = async (nodeId: NodeId) => {
  removeConnection(nodeId).then(() => editor.removeNode(nodeId));
};
// target Node에 연결된 모든 Conn 제거
const removeConnection = async (nodeId: NodeId) => {
  const connections = editor.getConnections();
  for (const connection of connections) {
    if (connection.source === nodeId || connection.target === nodeId) {
      await editor.removeConnection(connection.id);
    }
  }
};
// target Node 복사
export const copyNode = async (nodeId: NodeId) => {
  const targetNode = editor.getNode(nodeId);
  const newNode = createNode(targetNode);
  if (newNode) {
    await editor.addNode(newNode);
  }
};
// target Node lable에 해당하는 번호 줌
export const copyNodeKeyE = async (nodeLable: string) => {
  if (nodeLable === 'CustomProcessing') {
    return 0;
  } else if (nodeLable === 'ImageCrop') {
    return 1;
  } else if (nodeLable === 'Edge Detection') {
    return 2;
  } else if (nodeLable === 'Data Selector') {
    return 7;
  } else if (nodeLable === 'Viewer') {
    return 8;
  } else if (nodeLable === 'PipeGeneration') {
    return 10;
  } else if (nodeLable === 'Blender') {
    return 12;
  } else if (nodeLable === 'Feature') {
    return 13;
  } else if (nodeLable === 'Patch Generation') {
    return 17;
  } else if (nodeLable === 'DatasetConfiguration') {
    return 18;
  } else if (nodeLable === 'FormGenerater') {
    return 19;
  } else if (nodeLable === 'ImageProcessing') {
    return 20;
  }
};
// target Node 복사 생성
const createNode = (node: any): NodeTypes | undefined => {
  let newNode;
  if ('ctrl' in node.controls) {
    const ctrl = node.controls['ctrl'];
    if (node.label === 'DataSelector') {
      newNode = new DataSelectorNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'DatasetConfiguration') {
      newNode = new DatasetConfigurationNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'Patch Generation') {
      newNode = new PatchGenerationNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'CustomProcesssing') {
      newNode = new CustomProcessingNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'ImageCrop') {
      newNode = new ImageCropNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'Edge Detection') {
      newNode = new EdgeDetectNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'Denoising') {
      newNode = new DenoisingNode(process);
    } else if (node.label === 'Viewer') {
      newNode = new ViewerNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'Blender') {
      newNode = new BlenderNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'PipeGeneration') {
      newNode = new GearNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'FormGenerater') {
      newNode = new FormGeneraterNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    } else if (node.label === 'ImageProcessing') {
      newNode = new ImageProcessingNode(process);
      newNode.controls.ctrl.setValue(ctrl.props.option);
    }
  }
  return newNode;
};
