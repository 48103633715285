import { vh, vw } from '../../../src/App';

/**
 * Viewport와 픽셀(px)을 조합하여 새로운 Viewport 값을 반환하는 함수
 *
 * @param type - 변환할 축의 타입 ('height' 또는 'width')
 * @param viewport - 기준이 되는 Viewport 값
 * @param px - 변환에 사용될 픽셀 값
 * @returns 변환된 Viewport 값
 */
export function AddVxPxToVx(
  type: 'height' | 'width',
  viewport: number,
  px: number
): number {
  if (type === 'height') {
    return px / vh + viewport;
  } else return px / vw + viewport;
}

/**
 * 이미지 로드 실패 시 대체 이미지를 설정하는 함수
 *
 * @param e - 이미지 로드 에러 이벤트
 */
export const handleImgError = (
  e: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  const imgEl = e.target as HTMLImageElement;
  imgEl.setAttribute('src', `${process.env.PUBLIC_URL}/images/image-error.png`);
};
