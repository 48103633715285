import React, { Dispatch, SetStateAction } from 'react';
import File from '../../../../rete/components/nodes/data-selector/panel/items/File';
import Directory from '../../../../rete/components/nodes/data-selector/panel/items/Directory';

type DatasetExplorerProps = {
  fileNdirList: any[];
  setSelected: Dispatch<
    SetStateAction<{ path: string; name: string; thumbnail: string }>
  >;
  selected;
};

export default function DatasetExplorer({
  fileNdirList,
  selected,
  setSelected,
}: DatasetExplorerProps) {
  // style 재사용을 위해 class 에는 manage 유지
  return (
    <div className='datamanage-section data-explorer'>
      <ul id='datamanage-filedirlist'>
        {fileNdirList.map((data) => {
          if (
            data.type === 'dataset' ||
            data.type === 'dir' ||
            data.type === 'model'
          ) {
            return (
              <Directory
                key={data.path + data.name}
                depth={0}
                name={data.name}
                path={''}
                selected={selected}
                setSelected={setSelected}
              />
            );
          } else {
            return (
              <File
                key={data.path + data.name}
                thumbnail={data.thumbnail}
                path={''}
                depth={0}
                name={data.name}
                selected={selected}
                setSelected={setSelected}
              />
            );
          }
        })}
      </ul>
    </div>
  );
}
