import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import API from '@/apis/common/apis';
import { GearControl } from './Gear';
import { useParams } from 'react-router-dom';
import './Gear.scss';
import Swal from 'sweetalert2';
import renderTree from '../../../utils/renderTree';
import topNode from '../../../utils/topNode';

export function GearPanel({ ctrl, blend }: { ctrl: GearControl; blend?: boolean }) {
  const [cookies, setCookie] = useCookies(['refresh']);
  const api = new API(cookies);
  const params = useParams();
  const [title, setTitle] = useState(ctrl.props.option.title);
  const [description, setDescription] = useState(ctrl.props.option.description);
  const [inputLayout, setInputLayout] = useState('_0');
  const fileRule = {};
  useEffect(() => {
    ctrl.setValue({
      title,
      description,
    });
  }, [title, description]);

  useEffect(() => {
    setTitle(ctrl.props.option.title);
    setDescription(ctrl.props.option.description);
  }, [ctrl.props.option.title, ctrl.props.option.description]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };
  const saveGear = async () => {
    try {
      const modRes = await api.post(`/module/mod_seq`, {
        wor_id: params.wor_id,
        effector: ctrl.props.option?.result,
      });
      await api.post(`/module/usa_seq`, {
        mod_seq: modRes.data,
        img_paths: ctrl.props.option?.path,
      });
      const input_formats = {};
      if (ctrl.props.option?.img_paths) {
        for (const name of Object.keys(ctrl.props.option.img_paths)) {
          const t = ctrl.props.option.img_paths[name]?.split('.');
          if (t && t.length > 0) {
            input_formats[name] = t[t.length - 1];
          }
        }
      }
      const response = await api.post('/pipe/create', {
        pipe_id: '',
        mod_seq: modRes.data,
        email: '',
        name: title,
        description: description,
        input_format: input_formats,
        file_name_rule: fileRule,
        created_at: new Date().toISOString(),
      });
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'The pipe has been created',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err: any) {
      console.error(err);
      Swal.fire({
        icon: 'error',
        text: err.detail,
      });
    }
  };
  const cnt = {};

  return (
    <>
      {/* 사이드패널 1 */}
      <div className='gear-panel-normal'>
        <div className='gear-panel-normal-title'>Pipe Generation</div>
        <div className='gear-description'>You can create pipes or view the overall process.</div>
        <hr className='mt-4'></hr>
        <div className='gear-panel-normal-create-label mt-4'>Title</div>
        <input
          className='gear-side-panel-normal-input'
          type='text'
          value={title}
          onChange={handleTitleChange}
          placeholder='Please enter a title.'
        />
        <hr className='mt-4'></hr>
        <div className='gear-panel-normal-create-label mt-4'>Description</div>
        <textarea
          className='gear-side-panel-normal-textarea'
          value={description}
          onChange={handleDescriptionChange}
          placeholder='Please enter a title.'
        />
        <hr className='mt-4'></hr>

        <div className='gear-panel-normal-create-label mt-4 mb-4'>File Rule</div>

        {ctrl.props.option.img_paths &&
          Object.entries(ctrl.props.option.img_paths).map(([key, path]) => (
            <div key={key} className='gear-file-rule-container'>
              {path as string}
              {inputLayout === '_0' && (
                <div>
                  {(() => {
                    const pathStr = path as string;
                    const extension = pathStr.split('.').pop();

                    if (extension) {
                      if (extension in cnt) {
                        cnt[extension] += 1;
                      } else {
                        cnt[extension] = 0;
                      }
                      fileRule[key] = `myfile_${cnt[extension]}.${extension}`;
                      return (
                        <div style={{ color: '#00ffaa' }}>
                          myfile_{cnt[extension]}.{extension}
                        </div>
                      );
                    }
                  })()}
                </div>
              )}
            </div>
          ))}

        {/* 옵션 */}
        <select
          // id='input-layout-select'
          // className='layout-select'
          style={{
            width: '100%',
            height: '45px',
            marginTop: '20px',
          }}
          value={inputLayout}
          onChange={(e) => setInputLayout(e.target.value)}
        >
          <option value='_0'>_0</option>
          <option value='_t'>_t</option>
        </select>
        <hr></hr>

        <button onClick={saveGear} id='save-gear'>
          Save Pipe
        </button>
      </div>

      {/* 사이드패널2 */}
      <div className='gear-panel-expand'>
        {ctrl.props.option.result ? (
          <>
            {/* 최상위 노드 */}
            {topNode(ctrl.props.option.result)}

            {/* 자식 노드 */}
            {Array.isArray(ctrl.props.option.result.input) &&
              ctrl.props.option.result.input.map((inputNode, idx) => (
                <>
                  <div key={idx}>{renderTree(inputNode)}</div>
                  <hr style={{ border: '1px solid rgb(23, 24, 62)' }}></hr>
                </>
              ))}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}
