import React from 'react';

type EmptyDirImgProps = {
  label: string;
};

export default function EmptyDirImg({ label }: EmptyDirImgProps) {
  return (
    <div className='flex w-full flex-col items-center justify-center gap-[var(--12px)]'>
      <img
        src={`${process.env.PUBLIC_URL}/images/empty_div_2.png`}
        alt='The Directory is empty'
        className='aspect-[1/1] w-[var(--150px)] object-contain'
      />
      <p className='text-gray200 w-full text-center font-bold'>{label}</p>
    </div>
  );
}
