import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import Header from '@/components/common/layouts/header/Header';
import TrainSessionList from './TrainSessionList';
import TrainResultTab from '@/components/rete-mlops/components/panel-contents/Viewer/train-result/TrainResultTab';

import { useLocation } from 'react-router-dom';
import { TrainContext } from '@/contexts/TrainContext';
import { DataCombined } from '@/components/rete-mlops/components/panel-contents/Viewer/train-result/train-result-board/TrainResultGraph';
import EmptyDiv from './EmptyDiv';

const Container = styled.main<{ $isDetail: boolean }>`
  display: flex;

  div.train-result-tab {
    width: 100%;
  }

  button.update-btn {
    margin: var(--24px) auto;
    width: var(--150px);
    padding: var(--12px);
    color: white;
    background-color: #b273f1;
  }

  div.train-detail {
    display: flex;
    justify-content: center;

    height: calc(100vh - var(--72px));

    overflow-y: hidden;
    position: relative;
    ${(props) =>
      props.$isDetail
        ? `
      display: flex;
      flex-direction: column;
      width: 100%;
    `
        : `
    `}
  }
`;

export type TrainSessionItemStatus = {
  progress: number;
  cur_step: number;
  metric_list: string[];
  loss: DataCombined[];
  iou?: DataCombined[];
  accuracy?: DataCombined[];
  psnr?: DataCombined[];
  stop_signal: boolean;
  time: string;
};

export type TrainSessionItem = {
  key: string;
  status: TrainSessionItemStatus;
};

export default function TrainDetail() {
  const { trainerControl, trainData, selected, setSelected } = useContext(TrainContext);
  const { pathname } = useLocation();
  const isDetail = pathname.includes('detail');

  useEffect(() => {
    setSelected({
      key: trainerControl?.props.option.newWeightName ?? '',
      status: trainData?.trainResultData,
    });
  }, []);

  return (
    <>
      <Header />
      <Container $isDetail={isDetail} onContextMenu={(e) => e.preventDefault()}>
        <h2 className='a11y-hidden'>Train Detail Main</h2>
        <TrainSessionList />
        <div className='train-detail'>
          {selected?.key ? (
            <TrainResultTab />
          ) : (
            <EmptyDiv label='Select a training session on the left side panel' />
          )}
        </div>
      </Container>
    </>
  );
}
