import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import API from '../../../utils/api';
import './canvasTemplate.scss'
import { TCategoryType, IWorkspaceList, TWorkspaceID } from '../workspace/workspace';
import CustomModal from '../../../common/CustomModal';
import { ShareModal } from '../../../rete/workspace-panel.component';

import CanvasHeader from '../../../components/canvasHeader/CanvasHeader';
import CanvasSearch from '../../../components/canvasSearch/CanvasSearch';
import { Link } from "react-router-dom";
import ContextMenu from '../../datamanage/storage/ContextMenu';
import { CustomThemeContext } from '../../../utils/Context';

const CanvasTemplate = () => {
    const [cookies, , ] = useCookies(['refresh']);
    const api = new API(cookies);
    const [category, setCategory] = useState<TCategoryType>('All');
    const categoryList: TCategoryType[] = ['All', 'My', 'Shared', 'Archived', 'Trahsed'];
    const [workspaceList, setWorkspaceList] = useState<IWorkspaceList[]>([]);
    const [shareOpen, setShareOpen] = useState<boolean>(false);
    const [shareTarget, setShareTarget] = useState<string>('');

    const [contextTarget, setContextTarget] = useState<TWorkspaceID>('');
    const [contextOpen, setContextOpen] = useState<boolean>(false);
    const [contextEvent, setContextEvent] = useState<any>();

    const { theme } = useContext(CustomThemeContext);

    const getWorkspaceList = async () => {
        const response = await api.get(`/workspace/list/${category.toLowerCase()}`);
        setWorkspaceList(response.data);
    }

    const createNewWorkspace = async () => {
        const response = await api.post('/workspace/new');
        getWorkspaceList();
    }

    const copyWorkspace = async (target: string) => {
        const response = await api.post(`/workspace/copy/${target}`);
        getWorkspaceList();
    }

    const deleteWorkspace = async (target: string) => {
        try {
            const response = await api.delete(`/workspace/delete/${target}`);
            console.log(response);
            getWorkspaceList();
        } catch ({ detail }: any) {
            if (detail === 'Permission Denied') {
                alert('You don\'t have permission to delete project')
            }
        }
    }

    const archiveWorkspace = async (target: string) => {
        const response = await api.post(`/workspace/archive/${target}`);
        getWorkspaceList();
    }

    useEffect(() => {
        getWorkspaceList();
    }, [category])

    const setContext = (e: any, wor_id: TWorkspaceID) => {
        setContextOpen(true);
        setContextEvent(e);
        setContextTarget(wor_id);
    }
    const [workspaceModalOpen, setWorkspaceModalOpen] = useState<boolean>(false);

    const handleWorkspaceModal = () => {
        setWorkspaceModalOpen(!workspaceModalOpen);
    }
    return (
        <div className='template-container'
        onContextMenu={(e) => { e.preventDefault(); }}
    >
        <CanvasHeader />
        <div className="main-content">
            <div className='workspace-menu left'>
                <button onClick={handleWorkspaceModal} id='workspace-new' className="workspace-new">New Workspace</button>

                <CustomModal open={workspaceModalOpen} onClose={handleWorkspaceModal} bgShown='blur' bgColor={'#010116'}>
                    <div className='workspace-option-frame'>
                        <strong>Workspace option</strong>
                        {/* 링크 교체 */}
                        <Link to="/vience-canvas/template">
                            <button className='fill-white-outline'>Start with a template</button>
                        </Link>
                        <Link to="/vience-canvas/canvas">
                            <button className='fill-primary'>Start with an empty workspace</button>
                        </Link>
                    </div>
                </CustomModal>
                <div className='workspace-category-container'>
                    <ul>
                        {
                            categoryList.map(data =>
                                <li >
                                    <button
                                        style={{ color: category === data ? '#B28EFF' : theme === 'dark' ? '#EEEEEE' : '#4E4D51' }}
                                        className='workspace-category-card'
                                        id={`workspace-category-${data}`}
                                        onClick={() => { setCategory(data) }}>
                                        {data} Workspace
                                    </button>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>

            <div className="workspace-card-right">
                <div id="workspace-card-container" className="inner"
                    onClick={() => { setContextOpen(false); }}
                >
                    <CanvasSearch />
                    <div className="template-card-content">
                        <ul>
                            <li>
                                <Link to="/vience-canvas/canvas">
                                    <div className="template-card-frame">
                                        <div className="template-card-img">
                                            <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/test-img.png':'/images/test-img-white.png'} alt="" className="" />
                                            <span>shared</span>
                                        </div>
                                        <div className="template-card-title">
                                            <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-tree.svg' : '/images/ico-tree-black.svg'} alt="" className="" />
                                            <strong>title_01</strong>
                                        </div>
                                        <div className="template-card-text">
                                        <p>cell analysi s1ssfaasfsa fafasffdsfdsd safasfsdafsas sdffsfdsfsdfsdfsdfsdfdsfs</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                        </ul>
                        {contextOpen &&
                        <ContextMenu containerId='workspace-card-container' event={contextEvent} onClose={() => { setContextOpen(false) }}
                            functions={[
                                { label: 'delete', on: () => { deleteWorkspace(contextTarget) } },
                                { label: 'copy', on: () => { copyWorkspace(contextTarget) } },
                                { label: 'archive', on: () => { archiveWorkspace(contextTarget) } },
                                { label: 'share', on: () => { setShareOpen(true) } }
                            ]} />
                        }
                    </div>
                </div>
            </div>

        </div>
        <ShareModal open={shareOpen} onClose={() => { setShareOpen(false) }} wor_id={shareTarget} />
        </div>
    )
}

export default CanvasTemplate