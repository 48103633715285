import React, { useState, useEffect } from 'react';
import { ViewerControl } from '../../../ViewerNode';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

// imgInfo
// [[process.env.REACT_APP_VIENCE_API_KEY + '/viewer/overlay_image/${base_key}/${overlay_key}',...],
// [process.env.REACT_APP_VIENCE_API_KEY + '/viewer/overlay_image/${base_key}/${overlay_key}',...]]

export default function Type2ViewerPanel({
  ctrl,
  viewerKey,
}: {
  ctrl: ViewerControl;
  viewerKey: string;
}) {
  const [imgInfo, setImgInfo] = useState<any>([]);
  const [colNum, setColNum] = useState<any>(0);
  const [colLabels, setColLabels] = useState<any>([]);
  const [rowLabels, setRowLabels] = useState<any>([]);

  const api = useAPIwithCookies();

  // * Type2 패널이 최초 마운트 되었을 때 초기 keys 가져오기
  const fetchKeyList = async () => {
    try {
      const newImgInfo = [] as any;
      let newColNum = 0;
      let row_keys = [] as any;
      let col_keys = [] as any;
      const response = await api.get(`/viewer/output_data_info/` + viewerKey);
      const data = response.data.result;

      if (data.type === 'vmeta') {
        const firstKeys = Object.keys(data.structure);
        if (firstKeys.includes('origin')) {
          row_keys = Object.keys(data.structure['origin']);
          for (let i = 0; i < row_keys.length; i++) {
            col_keys = [] as any;
            const col_data = [] as any;
            if (firstKeys.includes('gt')) {
              col_keys.push('gt');
              const secondKeys = Object.keys(data.structure['gt']);
              if (secondKeys.includes(row_keys[i])) {
                col_data.push(
                  process.env.REACT_APP_VIENCE_API_KEY +
                    `/viewer/overlay_image/${viewerKey}/` +
                    `${data.structure['origin'][row_keys[i]]}/` +
                    `${data.structure['gt'][row_keys[i]]}`,
                );
              } else {
                col_data.push('');
              }
            }
            for (let j = 0; j < firstKeys.length; j++) {
              if (firstKeys[j].startsWith('pred')) {
                col_keys.push(firstKeys[j]);
                const secondKeys = Object.keys(data.structure[firstKeys[j]]);
                if (secondKeys.includes(row_keys[i])) {
                  col_data.push(
                    process.env.REACT_APP_VIENCE_API_KEY +
                      `/viewer/overlay_image/${viewerKey}/` +
                      `${data.structure['origin'][row_keys[i]]}/` +
                      `${data.structure[firstKeys[j]][row_keys[i]]}`,
                  );
                } else {
                  col_data.push('');
                }
              }
            }
            newColNum = col_data.length;
            newImgInfo.push(col_data);
          }
        }
      }
      setColNum(newColNum);
      setColLabels(col_keys);
      setRowLabels(row_keys);
      setImgInfo(newImgInfo);
    } catch (error) {
      console.error('Failed to fetch keyList:', error);
    }
  };

  useEffect(() => {
    fetchKeyList();
  }, [ctrl.nodeId, viewerKey]);

  return (
    <div className='mb-[var(--20px)] flex flex-col rounded-custom-lg'>
      <div className='flex h-[calc(100vh-12rem)] flex-row overflow-auto p-4'>
        <div
          className='grid gap-6 p-6 dark:bg-black100'
          style={{
            gridTemplateRows: `40px repeat(${imgInfo.length}, 1fr)`,
            gridTemplateColumns: `120px repeat(${colNum}, minmax(300px, 1fr))`,
          }}
        >
          {/* 빈 헤더 공간 */}
          <div className='flex items-center justify-center font-semibold'></div>

          {/* column 라벨 (상단) */}
          {colLabels.map((label, colIndex) => (
            <div
              key={`col-label-${colIndex}`}
              className='flex h-[40px] items-center justify-center rounded-lg bg-white100
                text-[18px] font-light text-black300 dark:bg-black200 dark:text-white'
            >
              {label}
            </div>
          ))}

          {/* 데이터 렌더링 */}
          {imgInfo.map((row, rowIndex) => {
            const rowLabel = rowLabels[rowIndex];

            return (
              <React.Fragment key={`row-${rowIndex}`}>
                {/* row 라벨 (왼쪽) */}
                <div
                  className='flex items-center justify-center rounded-lg bg-white100
                    p-3 text-[18px] font-light text-black dark:bg-black200 dark:text-white'
                >
                  {rowLabel}
                </div>

                {Array.from({ length: colNum }, (_, colIndex) => {
                  const imgUrl = row[colIndex] || '';

                  return (
                    <div
                      key={`cell-${rowIndex}-${colIndex}`}
                      className='flex flex-col items-center justify-center rounded-lg
                        bg-white p-3 shadow-md dark:bg-black300'
                    >
                      {imgUrl ? (
                        <img
                          src={imgUrl}
                          alt={`Overlayed ${rowIndex}-${colIndex}`}
                          className='h-[200px] w-full rounded-md object-cover'
                        />
                      ) : (
                        <div
                          className='dark:bg-dark:bg-black200 flex h-[200px]
                          w-full items-center justify-center rounded-md bg-white100 text-[16px]
                          font-light text-black300 dark:bg-black300 dark:text-white100'
                        >
                          No Data
                        </div>
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
