import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CommingSoonAlert from '@/components/common/alerts/CommingSoonAlert';
import Mode from '@/components/mode/Mode';
import { useLogout } from '@/hooks/common/useLogout';


function DesktopHeader({ pathname, theme }) {
  const [show, setShow] = useState(false);
  const { logout } = useLogout();

  return (
    <div
      className='flex h-[72px]
        items-center justify-between bg-gray100 px-[20px] dark:bg-black100'
    >
      {/* Left Header */}
      <div className='flex items-center gap-10'>
        {/* Vience Canvas logo */}
        <h1 className='flex h-[72px] items-center justify-center'>
          <Link to='/vience-canvas'>
            <img
              src={
                process.env.PUBLIC_URL +
                (theme === 'dark'
                  ? '/canvas-logo/logo-light-small.png'
                  : '/canvas-logo/logo-small.png')
              }
              alt='vience'
              className='h-[48px] w-[180px] max-w-[180px]'
            />
          </Link>
        </h1>

        {/* Navigation */}
        <nav className='mt-1'>
          <ul className='ml-[50px] flex items-center gap-[50px] text-[16px]'>
            <CommingSoonAlert>
              <li
                className={`pointer-events-none opacity-50 
                  ${pathname === 'overview' ? 'border-b-[3px] border-purple200' : ''}`}
              >
                <Link to='/vience-canvas/overview' className='text-gray300 hover:text-purple200'>
                  Overview
                </Link>
              </li>
            </CommingSoonAlert>

            <li
              className={`box-border flex h-[68px] items-center transition-all 
                ${pathname === 'datahub' ? 'border-b-[3px] border-purple200' : ''}`}
            >
              <Link
                to='/vience-canvas/datahub'
                className='text-black300 hover:text-purple200 dark:text-white100'
              >
                DataHub
              </Link>
            </li>
            <li
              className={`box-border flex h-[68px] items-center transition-all 
                ${pathname === 'workspace' ? 'border-b-[3px] border-purple200' : ''}`}
            >
              <Link
                to='/vience-canvas/workspace'
                className='text-black300 hover:text-purple200 dark:text-white100'
              >
                Workspace
              </Link>
            </li>

            <CommingSoonAlert>
              <li
                className={`pointer-events-none box-border
                  flex h-[68px] items-center opacity-50 transition-all 
                  ${pathname === 'mlops' ? 'border-b-[3px] border-purple200' : ''}`}
              >
                <Link to='/vience-canvas/mlops' className='text-gray300 hover:text-purple200'>
                  MLOps
                </Link>
              </li>
            </CommingSoonAlert>
            <CommingSoonAlert>
              <li
                className={`pointer-events-none box-border
                  flex h-[68px] items-center opacity-50 transition-all 
                  ${pathname === 'market' ? 'border-b-[3px] border-purple200' : ''}`}
              >
                <Link to='/vience-canvas' className='text-gray300 hover:text-purple200'>
                  Market
                </Link>
              </li>
            </CommingSoonAlert>
            <CommingSoonAlert>
              <li
                className={`pointer-events-none box-border flex
                  h-[68px] items-center opacity-50 transition-all
                  ${pathname === 'documents' ? 'border-b-[3px] border-purple200' : ''}`}
              >
                <Link to='/vience-canvas' className='text-gray300 hover:text-purple200'>
                  Documents
                </Link>
              </li>
            </CommingSoonAlert>
          </ul>
        </nav>
      </div>

      {/* Right Header */}
      <div className='flex items-center gap-5'>
        <div
          className='flex items-center
            gap-2.5 border-r border-gray300 pr-[20px] dark:border-black300'
        >
          <Link to='/vience-canvas/helpcenter' className='flex items-center'>
            {/* 네비게이션의 항목 간 일관성을 위해 아이콘을 임시 삭제 */}
            {/* <img
              src={
                process.env.PUBLIC_URL + `/images/helpcenter${theme === 'dark' ? '-white' : ''}.svg`
              }
              alt='Help Center'
            /> */}
            <span className='ml-2 text-[14px] text-black300 dark:text-white100'>Help Center</span>
          </Link>
        </div>

        {/* Mode Switch */}
        <div>
          <Mode />
        </div>

        {/* User Dropdown */}
        <div className='relative'>
          <div
            className='flex cursor-pointer items-center gap-2'
            onClick={() => setShow((prev) => !prev)}
          >
            <img
              src={process.env.PUBLIC_URL + '/images/user.svg'}
              alt='User Icon'
              className='w-10'
            />
          </div>
          {show && (
            <ul
              className='absolute right-[10%] top-[20px] z-[999]
                w-[150px] cursor-pointer overflow-visible rounded-custom-xl
                p-[20px] text-[14px] text-black300 dark:text-white100'
            >
              <Link to='/vience-canvas/mypage'>
                <li
                  className='z-[999] mt-[10px]
                    rounded-t-custom-lg border-x
                    border-t border-gray200 bg-white100
                    p-[20px] py-[15px] hover:bg-gray100 dark:border-black100
                  dark:bg-black200 dark:hover:bg-gray300'
                >
                  My Page
                </li>
              </Link>
              <li
                className='z-[999] rounded-b-custom-lg border-x
                  border-b border-gray200
                  bg-white100 p-[20px] py-[15px]
                  hover:bg-gray100 dark:border-black100 dark:bg-black200 dark:hover:bg-gray300'
                onClick={logout}
              >
                Logout
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default DesktopHeader;
