import React, { useContext, useEffect, useState } from 'react';

import TrainingAnimation from './TrainingAnimation';

import { TrainContext } from '@/contexts/TrainContext';
import { useLocation } from 'react-router-dom';
import { editor } from '../../../../editor';
import styled from 'styled-components';
import PrimaryButton from '@/components/common/buttons/PrimaryButton';

const Container = styled.div`
  button.trainer-control-btn.before-strat:disabled:hover::after {
    content: 'To Start, Resolve Error(s)';
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    padding: var(--12px);
    border-radius: 8px;

    font-weight: 700;

    ${({ $theme }) =>
      $theme === 'light'
        ? `
      color: #7b7b7b;
      background-color: #c1c1c1 ;
      `
        : `
      color: #525252;
      background-color:#232335;
    `};
  }

  .trainer-control-btn {
    display: flex;
    gap: var(--16px);
    justify-content: center;
    margin: 0 0 var(--24px);
    width: 100%;
  }
`;

export default function TrainControl({ setPanelMode, setSelectedTab }) {
  const { setIsTraining, isTraining, functions } = useContext(TrainContext);

  const { startTrain } = functions;

  // ---- sample page용 변수들 ----
  const { pathname } = useLocation();
  const isSample = pathname.includes('sample');

  const [error, setError] = useState(false);

  const cat = editor.getNode('8e9f1049571a8c39');
  const convTranspose2d = editor.getNode('110c21c743d9c5f5');
  const relu = editor.getNode('d1e20d1b0a51ac34');

  useEffect(() => {
    if (cat?.error || convTranspose2d?.error || relu?.error) {
      setError(true);
    }
  }, [cat?.error, convTranspose2d?.error, relu?.error]);
  // ---- sample page용 끝 ----

  const handleStartTrainBtn = () => {
    setSelectedTab('trainResult');
    setPanelMode('expand');
    if (!isSample) {
      setIsTraining(true);
      startTrain();
    }
  };

  return (
    <Container className='train-control'>
      <h4 className='a11y-hidden'>Train Control</h4>
      {isTraining ? (
        <PrimaryButton className='trainer-control-btn' disabled={true}>
          <TrainingAnimation />
          Training...
        </PrimaryButton>
      ) : (
        <PrimaryButton
          className='trainer-control-btn before-strat'
          type='button'
          onClick={handleStartTrainBtn}
          disabled={error}
        >
          Start training
        </PrimaryButton>
      )}
    </Container>
  );
}
