import React, { useContext, useEffect, useState } from 'react';
import { useRete } from 'rete-react-plugin';
import { useLocation } from 'react-router-dom';
import { NodeTypes, editor as mlopsEditor } from '@/components/rete-mlops/editor';

import Header from '@/components/common/layouts/header/Header';
import MlopsControlPanel from './mlops-control-panel/MlopsControlPanel';
import MlopsSidePanel from './mlops-side-panel/MlopsSidePanel';
import WorkspacePanel from '@/components/rete/workspace-panel.component';
import LoadingAnimation from '@/components/loadingAnimation/LoadingAnimation';
import { createEditor } from '@/components/rete-mlops/editor';

import { mockNode } from './mockNode';
import { IconBtn } from './toolbar/components/IconBtn';
import { Toolbar } from './toolbar/Toolbar';

import { CustomThemeContext, handleReteTheme } from '@/contexts/common/Context';
import { WorkspaceContext } from '@/contexts/WorkspaceContext';

import {
  closeAllContext,
  createByDataStructure,
  createByJson,
  process,
} from '@/components/rete-mlops/reteMlopsUtils';
import { getTimeString } from '@/components/rete-mlops/components/panel-contents/PanelOptionComponent/inputUtils';

import { type IWorkspaceSetting } from '../../canvas/workspace/workspace';

export default function MlopsEditorSample() {
  const [ref, editor] = useRete(createEditor);
  const [title, setTitle] = useState<string>('');
  const [setting, setSetting] = useState<IWorkspaceSetting>({
    category: '',
    description: '',
    ipAddress: '',
  });
  const [editable, setEditable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const location = useLocation();

  const { theme } = useContext(CustomThemeContext);
  const { setSelectedWorkspace } = useContext(WorkspaceContext);

  const getCanvas = async () => {
    const data = mockNode;
    if (data) {
      setTitle(data.title);
      setEditable(data.edit_yn);
      setSetting({
        category: data.category ?? '',
        description: data.description ?? '',
        ipAddress: data.ipAddress ?? '',
      });

      if (data.nodes !== undefined) {
        await createByJson(JSON.parse(data.nodes));
      }
    }
    handleReteTheme();
  };

  const setCanvas = async () => {
    try {
      await getCanvas();
    } catch (error) {
      if (location.state) {
        await createByDataStructure(location.state.dataStructure);
        const workspace = location.state.workspace;
        const entries: string[][] = Object.entries(workspace?.description ?? [['', '']]);
        const desc = entries.map(([key, value]) => `${key}: ${value}`).join('\n');

        setSelectedWorkspace(workspace);
        setTitle(`${workspace?.title ?? location.state.title}`);
        setSetting({
          category: workspace?.category,
          description: desc,
          ipAddress: '0.0.0.0',
        });
      } else {
        setTitle(`untitled_workspace_${getTimeString()}`);
        setSetting({
          category: 'category',
          description: 'description',
          ipAddress: '0.0.0.0',
        });
      }
      setEditable(true);
      setTimeout(() => {
        editor?.layout(true, 'simple');
      }, 0);
    }
  };

  const setInit = async () => {
    setLoading(true);
    try {
      await setCanvas();
      await process();
      mlopsEditor.addPipe(async (context) => {
        if (['connectioncreated', 'connectionremoved'].includes(context.type)) {
          await process();
        }
        return context;
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editor) {
      setInit();
    }
  }, [editor]);

  useEffect(() => {
    editor?.zoomAt();
  }, [loading]);

  const [trainers, setTrainers] = useState<NodeTypes[]>();
  const handleFindTrainer = (e, node = null) => {
    const newTrainers = editor?.zoomAtTrainer(node);
    setTrainers(newTrainers);
  };

  return (
    <>
      {loading && <LoadingAnimation label={'loading...'} />}
      <div className='canvas-edit' onContextMenu={(e) => e.preventDefault()}>
        <Header />
        <WorkspacePanel
          title={title}
          setTitle={setTitle}
          save={() => {
            alert('This is Sample Workspace!');
          }}
          setSetting={setSetting}
          setting={setting}
        />

        <Toolbar $theme={theme}>
          <IconBtn
            property1='align-unet'
            handleClick={() => {
              editor?.layout(true, 'unet');
            }}
          />
          <IconBtn
            property1='align-center'
            handleClick={() => {
              editor?.layout(true, 'simple');
            }}
          />
          <IconBtn
            property1='reset-view'
            handleClick={() => {
              editor?.zoomAt();
            }}
          />
          <IconBtn property1='find-trainer' handleClick={handleFindTrainer} items={trainers} />
        </Toolbar>

        <div
          className='main-canvas-content'
          style={{
            height: 'calc(100vh - var(--72px) - 55px)',
          }}
        >
          <MlopsControlPanel />
          <div ref={ref} className='rete main-canvas-middle' onClick={closeAllContext} />
          <MlopsSidePanel />
        </div>
      </div>
    </>
  );
}
