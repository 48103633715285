// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#feature-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.feature-card {
  margin: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feature-card img {
  object-fit: contain;
}

#visualization-list {
  margin-top: 200px;
}

body.light select {
  margin-top: var(--10px);
  padding: var(--5px) 0 !important;
  box-sizing: border-box;
  outline: none;
}
body.light .custom-textarea-input {
  color: #4e4e4e;
  background: #ffffff;
}
body.light .custom-inputwithlabel-container input {
  color: #4e4e4e !important;
}`, "",{"version":3,"sources":["webpack://./src/components/rete/components/nodes/addon/Feature.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,qCAAA;EACA,kCAAA;AACF;;AACA;EACE,YAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEF;AADE;EACE,mBAAA;AAGJ;;AACA;EACE,iBAAA;AAEF;;AAGI;EACE,uBAAA;EACA,gCAAA;EAEA,sBAAA;EACA,aAAA;AADN;AAGI;EACE,cAAA;EACA,mBAAA;AADN;AAIM;EAEE,yBAAA;AAHR","sourcesContent":["#feature-container {\n  width: 100%;\n  height: 100%;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-template-rows: repeat(3, 1fr);\n}\n.feature-card {\n  margin: 20px;\n  border-radius: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  img {\n    object-fit: contain;\n  }\n}\n\n#visualization-list {\n  margin-top: 200px;\n}\n\nbody {\n  &.light {\n    select {\n      margin-top: var(--10px);\n      padding: var(--5px) 0 !important;\n      // background: #fff !important;\n      box-sizing: border-box;\n      outline: none;\n    }\n    .custom-textarea-input {\n      color: #4e4e4e;\n      background: #ffffff;\n    }\n    .custom-inputwithlabel-container {\n      input {\n        // background: #fff !important;\n        color: #4e4e4e !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
