import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { AxiosResponse } from 'axios';

const noop = () => {
  // 빈 함수 기본값
};

/**
 * 파일 업로드를 처리하는 함수
 *
 * @param files - 업로드할 파일 목록
 * @param options - 함수 실행에 필요한 상태 및 메서드들
 * @param options.setLoading - 로딩 상태를 설정하는 함수
 * @param options.setTotalFileLen - 전체 파일 개수를 설정하는 함수
 * @param options.setProcessedFileLen - 처리된 파일 개수를 설정하는 함수
 * @param options.progressList - 업로드 진행 상태를 추적하는 객체
 * @param options.setProgressList - 파일 업로드 진행 상태를 설정하는 함수
 * @param options.setProgress - 개별 파일의 업로드 진행률을 설정하는 함수
 * @param options.uploadChunkFile - 파일을 청크 단위로 업로드하는 함수
 * @param options.api - API 요청을 처리하는 인스턴스
 * @param options.curDir - 현재 디렉토리 경로
 * @param options.setCurrentFilename - 현재 처리 중인 파일 이름을 설정하는 함수
 */

export const uploadFiles = async (
  files: FileList | null,
  options?: {
    setLoading?: (loading: boolean) => void;
    setTotalFileLen?: (len: number) => void;
    setProcessedFileLen?: (len: number | ((prev: number) => number)) => void;
    progressList?: Record<string, number>;
    setProgressList?: (list: Record<string, number>) => void;
    setProgress?: (data: { filename: string; progress: number }) => void;
    uploadChunkFile?: (
      file: File,
      curDir: string,
      promises: Promise<AxiosResponse<{ filename: string }>>[],
      api: ReturnType<typeof useAPIwithCookies>,
      onProgress: (res: AxiosResponse) => void,
    ) => void;
    api?: ReturnType<typeof useAPIwithCookies>;
    curDir?: string;
    setCurrentFilename?: (name: string) => void;
  },
): Promise<void> => {
  const {
    setLoading = noop,
    setTotalFileLen = noop,
    setProcessedFileLen = noop,
    progressList = {},
    setProgressList = noop,
    setProgress = noop,
    uploadChunkFile = noop,
    api,
    curDir = '',
    setCurrentFilename = noop,
  } = options || {};

  if (!files || !api) return;

  setLoading(true);
  setTotalFileLen(files.length);
  setProcessedFileLen(0);

  const newProgressList: Record<string, number> = {};
  for (const file of files) {
    newProgressList[file.name] = 0;
  }
  setProgressList(newProgressList);

  // 파일별 업로드 처리
  for (const file of files) {
    const promises: Promise<AxiosResponse<{ filename: string }>>[] = [];
    uploadChunkFile(
      file,
      curDir,
      promises,
      api,
      (res: AxiosResponse<{ filename: string }>) =>
        setProgress({ filename: res.data.filename, progress: 0 }),
    );

    try {
      await Promise.all(promises);

    } catch (err) {
      console.error(err);
    }
  }

  setLoading(false);
};

/**
 * 파일 드롭 이벤트를 처리하는 함수
 *
 * @param files - 드롭된 파일 목록
 * @param options - 함수 실행에 필요한 상태 및 메서드들
 * @param options.setLoading - 로딩 상태를 설정하는 함수
 * @param options.setProgress - 개별 파일의 업로드 진행률을 설정하는 함수
 * @param options.uploadChunkFile - 파일을 청크 단위로 업로드하는 함수
 * @param options.api - API 요청을 처리하는 인스턴스
 * @param options.curDir - 현재 디렉토리 경로
 */

export const dropFiles = async (
  files: File[],
  options: {
    setLoading: (loading: boolean) => void;
    setProgress: (data: { filename: string; progress: number }) => void;
    uploadChunkFile: (
      file: File,
      curDir: string,
      promises: Promise<AxiosResponse<{ filename: string }>>[],
      api: ReturnType<typeof useAPIwithCookies>,
      onProgress: (
        res: AxiosResponse<{
          progress: number;
          filename: string;
        }>,
      ) => void,
    ) => void;
    api: ReturnType<typeof useAPIwithCookies>;
    curDir: string;
  },
): Promise<void> => {
  const { setLoading, setProgress, uploadChunkFile, api, curDir } = options;

  setLoading(true);

  for (const file of files) {
    const promises: Promise<AxiosResponse<{ filename: string }>>[] = [];
    uploadChunkFile(
      file,
      curDir,
      promises,
      api,
      (res) => setProgress({ filename: res.data.filename, progress: res.data.progress }),
    );

    try {
      await Promise.all(promises);
    } catch (err) {
      console.error(err);
    } finally {
      setProgress({ filename: '', progress: 0 });
    }
  }

  setLoading(false);
};
