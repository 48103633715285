import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OpenSeaDragon, { type Viewer } from "openseadragon";
import { Toggle } from "rsuite";

import Annotator from "./Annotator";
import AnnotatorList from "./AnnotationList";

import AnnotationOpacitySlider from './AnnotationOpacitySlider';

const Container = styled.div`
  display: flex;

  .shape-list-container {
    width: 40%;

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 15vh;
      overflow-y: auto;
      margin-top: var(--12px);
      padding: var(--8px);

      li {
        display: flex;
        justify-content: space-between;

        p { 
          font-weight: 700;
        }

        p.foreground {
          color: blue;
        }

        p.background {
          color: red;
        }

        div.btn-delete {
          width: var(--32px);
          height: var(--32px);
          padding: var(--5px);
        }
      }
    }
  }

  .osd-container {
    position: relative;
    width: 100%;

    .dim {
      width: 100%;
      height: 100%;
      
      position: absolute;
      transition: all 0.3s;
      pointer-events: none !important;
    }
  }
`
export default function AnnotationApp({
  shapeList,
  setShapeList,
  selected,
  generatePrediction,
  modelKey,
}) {
  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [isForeground, setIsForeground] = useState(true);
  const [reverseType, setReverseType] = useState(false);
  const [blendFactor, setBlendFactor] = useState(1);
  const [isHover, setIsHover] = useState(false);
  const token = localStorage.getItem('access');

  function saveCurrentView(): {center: OpenSeaDragon.Point, zoom: number } | undefined {
    if (!viewer) return;
    const viewport = viewer.viewport;
    return {
      center: viewport.getCenter(true),
      zoom: viewport.getZoom(true)
    };
  }

  const InitOpenseadragon = async () => {
    viewer && viewer?.destroy();

    await generatePrediction();

    const src = [
      process.env.REACT_APP_VIENCE_API_KEY + "/mlops_annotator/" + token + selected.path + "/" + selected.name + "/img.dzi",
      process.env.REACT_APP_VIENCE_API_KEY + "/mlops_annotator/" + token + "/" + modelKey + "++/" + selected.path + "/" + selected.name +  "/label.dzi"
    ];

    const osd: Viewer = OpenSeaDragon({
        id: "openSeaDragon-annotator",
        prefixUrl: "openseadragon-images/",
        tileSources: src,
        crossOriginPolicy: "Anonymous",
        animationTime: 0.5,
        blendTime: 0.1,
        constrainDuringPan: true,
        maxZoomPixelRatio: 10,
        minZoomLevel: 0.1,
        visibilityRatio: 1,
        zoomPerScroll: 1.4,
        showNavigator: true,
        zoomInButton: "pv_zoom-in",
        zoomOutButton: "pv_zoom-out",
        homeButton: "pv_home",
        alwaysBlend: true,
    });

    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    setViewer(osd);
  };

  useEffect(() => {
    if (viewer === null){
        InitOpenseadragon();
    } 
    else if (viewer) {
      generatePrediction();

      const src = [
        process.env.REACT_APP_VIENCE_API_KEY + "/mlops_annotator/" + token + selected.path + "/" + selected.name + "/img.dzi",
        process.env.REACT_APP_VIENCE_API_KEY + "/mlops_annotator/" + token + "/" + modelKey + "++/" + selected.path + "/" + selected.name +  "/label.dzi"
      ];
  
      const savedView = saveCurrentView();
      viewer.open(src);
      viewer.addHandler('open', 
      function() {
        const tiledImage_region = viewer.world?.getItemAt(1);
        if(tiledImage_region){
          tiledImage_region.setOpacity(blendFactor);
          viewer.world?.update();
        }
        viewer.viewport.zoomTo(savedView.zoom);
        viewer.viewport.panTo(savedView.center);
        viewer.removeHandler('open');
      });
    }
  }, [selected, viewer]);


  const handleOnKeyUp = (event) => {
    if (event.key === 'Control') setReverseType(prev => !prev);
  };

  useEffect(()=>{
    window.addEventListener('keyup', handleOnKeyUp);
    setIsForeground(true);
    setReverseType(false);
    return () => {
      window.removeEventListener('keyup', handleOnKeyUp);
    }
  }, []);

  useEffect(() => {
    const toggleEl = document.querySelector('#foreground-toggle');
    toggleEl?.click();
  }, [reverseType])

  useEffect(() => {
    if(viewer){
      const tiledImage = viewer.world.getItemAt(1);
      tiledImage?.setOpacity(blendFactor);
      viewer.world.update();
    }
  }, [blendFactor])


  return (
    <Container>
      {/* shape list */}
      <div className="shape-list-container">
        <div style={{"display":"flex","marginBottom":"10px"}}>
          <Toggle 
            id="foreground-toggle"
            size="lg" 
            defaultChecked={false} 
            checkedChildren="Foreground"
            unCheckedChildren="Background" 
            onChange={(checked, event)=>{
              if (checked)
                setIsForeground(true);
              else
                setIsForeground(false);
            }} 
          />
          <p style={{"margin":"auto","marginLeft":"10px"}}>
            Change: Control key
          </p>
        </div>
        <AnnotationOpacitySlider 
          setOpacity={setBlendFactor}
          opacity={ blendFactor }
          label={'Prediction Opacity'}
        />

        <p style={{"marginTop":"20px"}}>
          Draw Annotation: Shift + Drag
        </p>

        <AnnotatorList 
          shapeList={shapeList} 
          setShapeList={setShapeList} 
          category={'foreground'}
        />
        <AnnotatorList 
          shapeList={shapeList} 
          setShapeList={setShapeList} 
          category={'background'}
        />
      </div>

      <div 
        className="osd-container" 
        onMouseEnter={() => setIsHover(true)} 
        onMouseLeave={() => setIsHover(false)}
      >
        {viewer && <Annotator 
          osd={viewer} 
          shapeList={shapeList} 
          setShapeList={setShapeList} 
          fb_type={isForeground ? 'foreground' : 'background'} 
        />}
        <div
          id="openSeaDragon-annotator"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
        {/* <div className="dim" style={{
          opacity: !isHover ? '0.7' : '0',
          textAlign: 'right',
          alignItems: 'end',
          paddingRight: '50px'
        }}>
          Annotation: Shift key + Mouse Drag
          <br />
          Change Type: Control key
        </div> */}
      </div>
    </Container>
  );
}
