import React, { useContext, useEffect, useState } from 'react';
import './side-panel.styles.scss';
import { NodeContext } from '../NodeContext';
import { editor } from '../rete';
import { DataManageNode } from '../components/nodes/source/DataManagement';
import DataManagePanel from '../components/nodes/source/DataManagePanel';
import { ViewerNode } from '../components/nodes/sink/Viewer';
import { ViewerPanel } from '../components/nodes/sink/ViewerPanel';
import {
  CustomProcessingPanel,
  CustomProcessingNode,
} from '../components/nodes/custom/CustomProcessing';
import {
  ImageCropPanel,
  ImageCropNode,
} from '../components/nodes/effector/ImageCrop';
import {
  EdgeDetectNode,
  EdgeDetectPanel,
} from '../components/nodes/effector/EdgeDectection';
import {
  BlenderNode,
  BlenderPanel,
} from '../components/nodes/effector/Blender';
import { ChartNode, ChartPanel } from '../components/nodes/sink/Chart';
import { GearNode } from '../components/nodes/addon/Gear';
import DeepLearningPanel from '../components/nodes/effector/deeplearning/DeepLearningPanel';
import ClassificationPanel from '../components/nodes/effector/deeplearning/ClassificationPanel';
import { CustomThemeContext } from '../../utils/Context';
import { FeatureNode, FeaturePanel } from '../components/nodes/addon/Feature';
import { GearPanel } from '../components/nodes/addon/GearPanel';

type TPanelMode = 'normal' | 'expand' | 'shrink';

export default function SidePanel() {
  const { theme } = useContext(CustomThemeContext);
  const { nodeId } = useContext(NodeContext);
  const [panelMode, setPanelMode] = useState<TPanelMode>('normal');
  const [translate, setTranslate] = useState<string>(
    'translateX(calc(100% - 24rem))'
  );

  const handlePanelMode = () => {
    switch (panelMode) {
      case 'normal':
        setPanelMode('expand');
        break;
      case 'expand':
        setPanelMode('normal');
        break;
      // case 'shrink':
      //   setPanelMode('normal');
      //   break;
    }
  };

  useEffect(() => {
    setPanelMode('normal');
  }, [nodeId]);

  useEffect(() => {
    const minimapDOM = document.getElementById('minimap');
    switch (panelMode) {
      case 'normal':
        minimapDOM?.style.setProperty('transform', 'translateX(-175%)');
        setTranslate('translateX(calc(100% - 24rem))');
        break;
      case 'expand':
        minimapDOM?.style.setProperty('transform', 'none');
        setTranslate('none');
        break;
      // case 'shrink':
      //   minimapDOM?.style.setProperty('transform', 'none');
      //   setTranslate('translateX(calc(100% - 28px))');
      //   break;
    }
  }, [panelMode]);

  return (
    <div
      className='side-panel-box'
      style={{
        transform: translate,
      }}
    >
      <span className='side-panel-arrow'>
        <img
          src={
            process.env.PUBLIC_URL + theme === 'dark'
              ? '/images/canvas-panel-arrow.svg'
              : '/images/canvas-panel-arrow-white.svg'
          }
          alt=''
          onClick={handlePanelMode}
          style={{
            transform: panelMode === 'expand' ? 'rotate(180deg)' : '',
            // animation: panelMode === 'shrink' ? `glow 2s infinite` : '',
            paddingRight: panelMode === 'expand' ? '9px' : '',
            paddingLeft: panelMode === 'normal' ? '5px' : '',
          }}
        />
      </span>
      <div className='side-panel'>
        <div
          className='side-panel-header'
          style={{ width: panelMode === 'expand' ? '100%' : 350 }}
        >
          <p className='panel-title-comm'>Panel size</p>

          <div>
            <button
              onClick={() => {
                setPanelMode('expand');
              }}
            >
              {panelMode === 'expand' ? (
                <img
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/panel-icon-max-active.svg'
                      : '/images/panel-icon-max-white-active.svg'
                  }
                  alt=''
                />
              ) : (
                <img
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/panel-icon-max.svg'
                      : '/images/panel-icon-max-white.svg'
                  }
                  alt=''
                />
              )}
            </button>
            <button
              onClick={() => {
                setPanelMode('normal');
              }}
            >
              {panelMode === 'normal' ? (
                <img
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/panel-icon-middle-active.svg'
                      : '/images/panel-icon-middle-white-active.svg'
                  }
                  alt=''
                />
              ) : (
                <img
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/panel-icon-middle.svg'
                      : '/images/panel-icon-middle-white.svg'
                  }
                  alt=''
                />
              )}
            </button>
            <button
              onClick={() => {
                setPanelMode('shrink');
              }}
            >
              {panelMode === 'shrink' ? (
                <img
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/panel-icon-minimum-active.svg'
                      : '/images/panel-icon-minimum-white-active.svg'
                  }
                  alt=''
                />
              ) : (
                <img
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/panel-icon-minimum.svg'
                      : '/images/panel-icon-minimum-white.svg'
                  }
                  alt=''
                />
              )}
            </button>
          </div>
        </div>
        <NodesComp panelMode={panelMode} />
      </div>
    </div>
  );
}

function NodesComp({ panelMode }: { panelMode: TPanelMode }) {
  const { nodeId } = useContext(NodeContext);
  const [nodeType, setNodeType] = useState<any>();
  // console.log("노드 아이디", nodeId);
  useEffect(() => {
    if (editor) {
      const pickedNode = editor.getNode(nodeId);
      setNodeType(pickedNode);
    }
  }, [nodeId]);

  if (nodeType?.type === 'deeplearning') {
    return <DeepLearningPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType?.type === 'classification') {
    return <ClassificationPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof DataManageNode) {
    return <DataManagePanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof CustomProcessingNode) {
    return (
      <CustomProcessingPanel
        ctrl={nodeType.controls['ctrl']}
        expand={panelMode === 'expand'}
        nodeId={nodeId}
      />
    );
  }
  if (nodeType instanceof ImageCropNode) {
    return (
      <ImageCropPanel
        ctrl={nodeType.controls['ctrl']}
        expand={panelMode === 'expand'}
      />
    );
  }
  if (nodeType instanceof EdgeDetectNode) {
    if (panelMode === 'expand')
      return <EdgeDetectPanel ctrl={nodeType.controls['ctrl']} expand={true} />;
    else
      return (
        <EdgeDetectPanel ctrl={nodeType.controls['ctrl']} expand={false} />
      );
  }
  if (nodeType instanceof ViewerNode) {
    return <ViewerPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof BlenderNode) {
    return <BlenderPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof ChartNode) {
    return <ChartPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof GearNode) {
    return <GearPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof FeatureNode) {
    return <FeaturePanel ctrl={nodeType.controls['ctrl']} />;
  }

  return null;
}
