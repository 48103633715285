import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDataHubStore from '@/stores/datahub/useDataHubStore';

/**
 * 라우팅 경로에 따라 `curDir` 상태를 업데이트하고 잘못된 경로를 교정하는 커스텀 훅
 */
export const useNavigatePath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { curDir, setCurDir } = useDataHubStore();
  useEffect(() => {
    if (!location.pathname.includes('/vience-canvas/datahub')) {
      return;
    }

    let currentPath = decodeURIComponent(
      location.pathname.replace('/vience-canvas/datahub', '')
    );

    if (currentPath[0] === '/') {
      currentPath = currentPath.slice(1);
    }

    if (location.pathname === '/vience-canvas/datahub') {
      if (curDir !== '') {
        setCurDir('');
      }
    } else {
      if (currentPath !== curDir) {
        setCurDir(currentPath);
      }
    }

    if (location.pathname === '/vience-canvas/datahub/') {
      navigate('/vience-canvas/datahub');
    } else if (location.pathname.endsWith('/') && location.pathname !== '/vience-canvas/datahub/') {
      const correctedPath = location.pathname.slice(0, -1);
      navigate(correctedPath);
    }
  }, [location, curDir, setCurDir, navigate]);
};
