import React, { CSSProperties, ChangeEvent } from 'react';

interface SelectBoxProps {
  options: { id: string; name: any }[];
  optionStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  children: string;
  onChange: (e: any) => void;
}

export default function SelectBox({
  options,
  optionStyle,
  containerStyle,
  children,
  onChange,
}: SelectBoxProps) {
  return (
    <div className='pb-2'>
      <select
        onChange={(e) => {
          onChange(e.target.value as any);
        }}
        className='w-full h-[60px] border border-[#5818A9] text-[#5818A9] bg-[#F0F2FE] dark:bg-[#010116] dark:text-[#5818A9]  dark:border-[#5818A9] rounded-md'
      >
        <option value='' disabled selected>
          {children}
        </option>
        {options.map((data) => (
          <option key={data.id} value={data.name}>
            {data.name}
          </option>
        ))}
      </select>
    </div>
  );
}
