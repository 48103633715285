// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-osd {
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: black;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/common/image.styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,0BAAA;EACA,uBAAA;EACA,YAAA;AACF","sourcesContent":[".image-osd {\n  width: 100vw;\n  height: calc(100vh - 50px);\n  background-color: black;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
