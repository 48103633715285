import React, { useContext } from 'react';
import { LuMousePointer2 } from 'react-icons/lu';
import { FaRegHandPaper } from 'react-icons/fa';
import { NodeContext } from '../NodeContext';

interface ToolBarProps {
  button: number;
  setButton: (value: number) => void;
}

const ToolBar: React.FC<ToolBarProps> = ({ button, setButton }) => {
  const { expandDetection } = useContext(NodeContext);

  return (
    <div
      className={`fixed bottom-8 left-1/2 transform -translate-x-1/2 
        ${expandDetection ? '-ml-[23%]' : 'ml-0'} 
        z-[999] w-[90%] max-w-[140px] h-[70px] rounded-lg flex items-center justify-evenly
        shadow-md bg-[#d3d3d3] dark:bg-[#282A2C]`}
    >
      <button
        onClick={() => setButton(0)}
        className={`h-[50px] w-[50px] rounded-md flex items-center justify-center cursor-pointer 
          ${button === 0 ? 'bg-[#78519E] border border-[#78519E]' : ''}`}
      >
        <LuMousePointer2 size={27} className='text-white' />
      </button>
      <button
        onClick={() => setButton(1)}
        className={`h-[50px] w-[50px] rounded-md flex items-center justify-center cursor-pointer 
          ${button === 1 ? 'bg-[#78519E] border-[#78519E]' : ''}`}
      >
        <FaRegHandPaper size={27} className='text-white' />
      </button>
    </div>
  );
};

export default ToolBar;
