import React, { useState, useEffect, useContext } from 'react';
import { Cookies } from 'react-cookie';
import API from '@/apis/common/apis';
import { ClassicPreset, NodeId } from 'rete';
import { RadioGroup, Radio } from 'rsuite';
import { ICustomProcessing, IImageProcessing } from '../../filter';
import { IDataflow } from '../flow';
import './CustomProcessing.scss';
import { CustomSocket } from '../../sockets';
import { EffectorColor } from '../../style/CustomNode';
import CustomControl from '../../style/CustomControl';
// import { updateControl } from '../../../reteUtils';
import { NodeSize } from '../../style/CustomNode';
import { isTypedArray } from 'util/types';

export class ImageProcessingNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: ImageProcessingControl }
> {
  color = EffectorColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super('ImageProcessing');
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new ImageProcessingControl(process, nodeId ? nodeId : this.id));
  }

  data(inputs: { in: IDataflow[] }): { out: IDataflow } {
    if (!inputs['in']) {
      return {
        out: {
          img_paths: {},
          effector: {
            input: [],
            node: {},
          },
        },
      };
    }

    const value = inputs['in'][0];
    const effector = {
      input: [value.effector],
      node: {
        image_processing: {
          node_id: this.id,
          method: this.controls.ctrl.props.option.method,
          params: this.controls.ctrl.props.option.params,
          inputImage: this.controls.ctrl.props.option.inputImage,
          outputImage: this.controls.ctrl.props.option.outputImage,
        },
      },
    };

    // const setStatus = async () => {
    //   const params = new URLSearchParams(location.search);

    //   const cookie = new Cookies();
    //   const api = new API(cookie);
    //   const response = await api.post('/module/check_status', {
    //     wor_id: params['wor_id'],
    //     img_paths: value.img_paths,
    //     effector: effector,
    //   });
    //   this.controls.ctrl.setStatus(response.data);
    // };
    // setStatus();

    const baseInput = value.img_paths;
    const baseInputKey = Object.keys(baseInput)[0];
    const basePath = baseInput[baseInputKey];

    // CustomProcessingControl에 value 설정
    if (this.controls.ctrl.props.option.path !== basePath) {
      this.controls.ctrl.setValue({
        value: value,
        path: basePath,
        method: this.controls.ctrl.props.option.method,
        params: this.controls.ctrl.props.option.params,
        inputImage: this.controls.ctrl.props.option.inputImage,
        outputImage: this.controls.ctrl.props.option.outputImage,
      });
    }

    return {
      out: {
        img_paths: value.img_paths,
        effector: effector,
      },
    };
  }
}

export class ImageProcessingControl extends ClassicPreset.Control {
  props: {
    option: IImageProcessing;
  };
  contextOpen: boolean;
  // status => 백그라운드에서 처리 상태
  // init: 처음 생성 / in-progress: 처리 중 / completed: 처리 완료 / error: 에러 발생생
  status: string;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      option: {
        value: { img_paths: [], effector: { input: [], node: {} } },
        path: undefined,
        method: 'Resize',
        params: { new_width: 2, new_height: 2, interpolation_method: 'nearest' },
        inputImage: [],
        outputImage: [],
      },
    };
    this.contextOpen = false;
    this.status = 'init';
  }

  setValue(val: IImageProcessing) {
    this.props.option = val;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };
  setStatus = (status: string) => {
    this.status = status;
  };
}

export function ImageProcessingComp({ data }: { data: ImageProcessingControl }) {
  return (
    <>
      <CustomControl
        contextOpen={data.contextOpen}
        setContextOpen={data.setContextOpen}
        label='Image Processing'
        nodeId={data.nodeId}
        markerSource={'node-effector'}
        iconSource='canvas-edge-code'
        status={data.status}
        setStatus={data.setStatus}
      />
    </>
  );
}
