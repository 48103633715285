import React, { useContext } from 'react'
import { TrainContext } from '@/contexts/TrainContext';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: var(--32px);

  width: 100%;
  padding: var(--8px) var(--24px);

  h4 {
    width: 30%;
    font-size: var(--12px);
    text-align: right;
  }

  progress {
    width: 60%;
    padding: var(--12px);
  }
`

export default function TrainResultProgress() {
  const { trainData = {}, selected } = useContext(TrainContext);
  const trainProgress =  selected?.status?.progress || (trainData as any).trainProgress || 0;

  return (
    <Container>
      <h4>Training Progress</h4>
      <progress value={trainProgress} max={100} />
    </Container>
  )
}
