import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './landingHeader.scss';

import { useLogout } from '@/hooks/common/useLogout';
import { useAuthContext } from '@/hooks/common/useAuthContext';

export const LandingHeader: React.FC = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [hambergerMenuOpen, setHambergerMenuOpen] = useState(false);
  const { user } = useAuthContext();
  const { logout } = useLogout();

  const hambergerMenuOpenClick = () => {
    setHambergerMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
      <header>
        <h1 data-aos='fade-right'>
          <Link to='/vience-canvas'>
            <img
              className='w-[245px]'
              src={process.env.PUBLIC_URL + '/canvas-logo/logo-light-small.png'}
              alt='vience canvas'
            />
          </Link>
        </h1>
        <nav data-aos='fade-down' className='mt-8'>
          <ul>
            <li>
              <Link to='/main' target='_blank'>
                About Us
              </Link>
            </li>
            <li>
              <Link to='/vience-canvas/workspace'>Platform</Link>
            </li>
            <li>
              <Link to='/vience-canvas/project'>Project</Link>
            </li>
            <li className='disabled'>
              <Link to='#' onClick={(e) => e.preventDefault}>
                Support
              </Link>
            </li>
            <li className='disabled'>
              <Link to='/vience-canvas/example'>Example</Link>
            </li>
          </ul>
        </nav>

        <div className='right-frame' data-aos='fade-left'>
          {user?.access ? (
            <div className='login-user'>
              <div className='user-name' onClick={() => setShow((prev) => !prev)}>
                <img src={process.env.PUBLIC_URL + '/images/user.svg'} alt='' />
                <span>{user.email}</span>
              </div>
              {show && (
                <div className={'login-content-frame'}>
                  <ul>
                    <Link to='/vience-canvas/mypage'>
                      {/* <li onClick={() => { navigate('/vience-canvas/overview') }}> */}
                      <span className='text-white100'>My page</span>
                    </Link>
                    <li onClick={logout}>
                      <span>Logout</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className='login-frame mt-8'>
              <button
                onClick={() => {
                  navigate('/vience-canvas/login');
                }}
                type='button'
              >
                Sign in
              </button>
              <button
                onClick={() => {
                  navigate('/vience-canvas/register');
                }}
                type='button'
              >
                Sign up
              </button>
            </div>
          )}
        </div>

        {/* 반응형 메뉴 */}
        <div className={`mobile-menu`} onClick={hambergerMenuOpenClick}>
          <img src={process.env.PUBLIC_URL + '/images/menu.svg'} alt='menu' />
        </div>
        <div className={`mobile-menu-list ${hambergerMenuOpen ? ' active' : ''}`}>
          <div className='bg' onClick={hambergerMenuOpenClick}></div>
          <div className='mobile-list-frame'>
            <div className='mobile-list-inner'>
              <div className='menu-close-frame' onClick={hambergerMenuOpenClick}>
                <img
                  src={process.env.PUBLIC_URL + '/images/menu-close.svg'}
                  alt=''
                  className='menu-ico-close'
                />
              </div>

              <div className='mobile-list-ul'>
                <ul>
                  <li>
                    <Link to='/main' target='_blank'>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to='/vience-canvas/workspace'>Platform</Link>
                  </li>
                  <li>
                    <Link to='/vience-canvas/project'>Project</Link>
                  </li>
                  <li className='disabled'>
                    <Link to='#' onClick={(e) => e.preventDefault}>
                      Support
                    </Link>
                  </li>
                  <li className='disabled'>
                    <Link to='/vience-canvas/example'>Example</Link>
                  </li>
                </ul>
              </div>
              <div className='mobile-login-frame'>
                {user?.access ? (
                  <div className='login-user'>
                    <div className='user-name'>
                      <img src={process.env.PUBLIC_URL + '/images/user.svg'} alt='' />
                      <span>{user.email}</span>
                    </div>
                    <div className='login-frame'>
                      <button type='button'>My Page</button>
                      {/* <button onClick={() => { navigate('/vience-canvas/overview') }} type="button">My Page</button> */}
                      <button onClick={logout} type='button'>
                        Logout
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='login-frame'>
                    <button
                      onClick={() => {
                        navigate('/vience-canvas/login');
                      }}
                      type='button'
                    >
                      Sign in
                    </button>
                    <button
                      onClick={() => {
                        navigate('/vience-canvas/register');
                      }}
                      type='button'
                    >
                      Sign up
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
