import { useEffect, useState } from 'react';

/**
 * ResizeObserver를 사용하여 HTML 요소의 크기를 감지하고 스타일을 동적으로 업데이트하는 커스텀 훅
 *
 * @param {React.RefObject<HTMLElement>} elementRef - 감지할 HTML 요소의 참조 객체
 * @returns {{ justifyContent: string, alignItems: string }} 요소 크기에 따른 동적 스타일 객체
 */
export const useResizeObserver = (elementRef: React.RefObject<HTMLElement>) => {
  const [style, setStyle] = useState({ justifyContent: '', alignItems: '' });

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const width = entry.contentRect.width;
        setStyle({
          justifyContent: width <= 1241 ? 'center' : '',
          alignItems: width <= 1241 ? 'center' : '',
        });
      });
    });

    observer.observe(element);

    return () => observer.unobserve(element);
  }, [elementRef]);

  return style;
};
