import React, { useState, useEffect } from "react";
import { ClassicPreset, NodeId } from "rete";
import { RadioGroup, Radio } from "rsuite";
import {
  ICanny,
  IEdge,
  IImageFilter,
  ILaplacian,
  ISobel,
  TEdgeType,
} from "../../filter";
import { IDataflow } from "../flow";
import "./EdgeDectection.scss";
import "../../../side-panel/side-panel.styles.scss";
import { CustomSocket } from "../../sockets";
import { InputWithLabel } from "../../../../common/CustomInput";
import { EffectorColor } from "../../style/CustomNode";
import CustomControl from "../../style/CustomControl";
import { updateControl } from "../../../reteUtils";
import { NodeSize } from "../../style/CustomNode";

export class EdgeDetectNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: EdgeDetectControl }
> {
  color = EffectorColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super("Edge Detection");
    this.addInput("in", new ClassicPreset.Input(new CustomSocket()));
    this.addOutput("out", new ClassicPreset.Output(new CustomSocket()));
    this.addControl(
      "ctrl",
      new EdgeDetectControl(process, nodeId ? nodeId : this.id)
    );
  }

  data(inputs: { in: IDataflow[] }): { out: IDataflow } {
    const value = inputs["in"][0];
    if (this.controls.ctrl.props.option.path !== value.path) {
      this.controls.ctrl.setValue({
        edge: undefined,
      });
    }
    return {
      out: {
        img_paths: value.img_paths,
        effector: {
          input: [value.effector],
          node: {
            edge: this.controls.ctrl.props.option.edge,
          },
        },
      },
    };
  }
}

export class EdgeDetectControl extends ClassicPreset.Control {
  props: {
    option: IImageFilter;
  };
  contextOpen: boolean;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId
  ) {
    super();
    this.props = {
      option: {
        edge: undefined,
      },
    };
    this.contextOpen = false;
  }
  setValue(val: IImageFilter) {
    this.props.option = val;
  }
  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    updateControl(this.id);
  };
}

export function EdgeDetectComp({ data }: { data: EdgeDetectControl }) {
  console.log("edge 노드", data);

  return (
    <CustomControl
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
      label="Edge Detection"
      nodeId={data.nodeId}
      markerSource={"node-effector"}
      iconSource="edgedetect"
    />
  );
}

interface EdgeDetectPanelProps {
  ctrl: EdgeDetectControl;
  expand: boolean;
}

export const EdgeDetectPanel = ({ ctrl, expand }: EdgeDetectPanelProps) => {
  const [type, setType] = useState<TEdgeType>(
    ctrl.props.option.edge?.name || "sobel"
  );
  const [filter, setFilter] = useState<IEdge>(
    ctrl.props.option.edge || {
      name: "sobel",
      option: { dx: 1, dy: 0, ksize: 3 },
    }
  );
  let src: string;

  switch (type) {
    case "sobel":
      src = "/images/filter/example_sobel.jpg";
      break;
    case "laplacian":
      src = "/images/filter/example_laplacian.jpg";
      break;
    case "canny":
      src = "/images/filter/example_canny.jpg";
      break;
  }

  const loadData = () => {
    if (ctrl.props.option.edge) {
      setType(ctrl.props.option.edge?.name);
    }
  };

  useEffect(() => {
    loadData();
  }, [ctrl.id]);

  useEffect(() => {
    if (type == "sobel") {
      if (ctrl.props.option.edge && ctrl.props.option.edge.name == "sobel")
        setFilter(ctrl.props.option.edge);
      else setFilter({ name: "sobel", option: { dx: 1, dy: 0, ksize: 3 } });
    } else if (type == "laplacian") {
      if (ctrl.props.option.edge && ctrl.props.option.edge.name == "laplacian")
        setFilter(ctrl.props.option.edge);
      else setFilter({ name: "laplacian", option: { ksize: 3 } });
    } else {
      if (ctrl.props.option.edge && ctrl.props.option.edge.name == "canny")
        setFilter(ctrl.props.option.edge);
      else
        setFilter({
          name: "canny",
          option: { threshold1: 100, threshold2: 255 },
        });
    }
  }, [type]);

  useEffect(() => {
    ctrl.props.option.edge = filter;
    ctrl.onChange();
  }, [filter]);

  return (
    <>
      <div className="side-panel-normal">
        <p>Edge Detection</p>
        <p>Selector</p>
        <div className="edge-selector">
          <RadioGroup
            value={type}
            onChange={(e) => {
              setType(e as TEdgeType);
            }}
          >
            <Radio name="filter" value="sobel">
              Sobel Filter
            </Radio>
            <Radio name="filter" value="laplacian">
              Laplacian Filter
            </Radio>
            <Radio name="filter" value="canny">
              Canny Filter
            </Radio>
          </RadioGroup>
        </div>
        <p>Parameter</p>
        <div className="edge-input">
          {
            {
              sobel: (
                <div className="edge-input-group">
                  <InputWithLabel
                    id="sobel-dx"
                    label={`dx : ${(filter.option as ISobel).dx}`}
                    type="range"
                    value={(filter.option as ISobel).dx}
                    onChange={(e) => {
                      setFilter({
                        name: "sobel",
                        option: {
                          dx: Number(e),
                          dy: (filter.option as ISobel).dy,
                          ksize: (filter.option as ISobel).ksize,
                        },
                      });
                    }}
                    min={-1}
                    max={1}
                  />
                  <InputWithLabel
                    id="sobel-dy"
                    label={`dy : ${(filter.option as ISobel).dy}`}
                    type="range"
                    value={(filter.option as ISobel).dy}
                    onChange={(e) => {
                      setFilter({
                        name: "sobel",
                        option: {
                          dx: (filter.option as ISobel).dx,
                          dy: Number(e),
                          ksize: (filter.option as ISobel).ksize,
                        },
                      });
                    }}
                    min={-1}
                    max={1}
                  />
                  <InputWithLabel
                    id="sobel-ksize"
                    label="Kernel Size (1 ~ 31)"
                    type="range"
                    value={(filter.option as ISobel).ksize}
                    onChange={(e) => {
                      setFilter({
                        name: "sobel",
                        option: {
                          dx: (filter.option as ISobel).dx,
                          dy: (filter.option as ISobel).dy,
                          ksize: Number(e),
                        },
                      });
                    }}
                    min={1}
                    max={31}
                    step={2}
                  />
                </div>
              ),
              laplacian: (
                <div className="edge-input-group">
                  <InputWithLabel
                    id="laplacian-ksize"
                    label={`Kernel Size: ${(filter.option as ILaplacian).ksize} (1 ~ 31)`}
                    type="range"
                    value={(filter.option as ILaplacian).ksize}
                    onChange={(e) => {
                      setFilter({
                        name: "laplacian",
                        option: { ksize: Number(e) },
                      });
                    }}
                    min={1}
                    max={31}
                    step={2}
                  />
                </div>
              ),
              canny: (
                <div className="edge-input-group">
                  <InputWithLabel
                    id="canny-threshold1"
                    label={`threshold1 : ${(filter.option as ICanny).threshold1}`}
                    type="number"
                    value={(filter.option as ICanny).threshold1}
                    min={0}
                    max={255}
                    onChange={(e) => {
                      setFilter({
                        name: "canny",
                        option: {
                          threshold1: Number(e),
                          threshold2: (filter.option as ICanny).threshold2,
                        },
                      });
                    }}
                  />
                  <InputWithLabel
                    id="canny-threshold2"
                    label={`threshold2 : ${(filter.option as ICanny).threshold2}`}
                    type="number"
                    value={(filter.option as ICanny).threshold2}
                    min={0}
                    max={255}
                    onChange={(e) => {
                      setFilter({
                        name: "canny",
                        option: {
                          threshold1: (filter.option as ICanny).threshold1,
                          threshold2: Number(e),
                        },
                      });
                    }}
                  />
                </div>
              ),
            }[filter.name]
          }
        </div>
      </div>
      <div className="side-panel-expand">
        <div className="side-panel-expand-description">
          이미지 테두리 검출 cv2에서 제공하는 Soble, Laplacian, Canny Filter
          적용
        </div>
        <div className="side-panel-expand-section">
          <img
            src="/images/filter/example_ref.jpg"
            style={{ width: "45%", height: "auto" }}
          />
          <img src={src} style={{ width: "45%", height: "auto" }} />
        </div>
      </div>
    </>
  );
};
