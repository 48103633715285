import { ClassicPreset, NodeId } from 'rete';

import '@/components/rete/components/nodes/viewer/panel/side-panel.styles.scss';
import './Gear.scss';

import { IPipeGeneration } from '../../filter';
import { CustomSocket } from '../../sockets';
import { NodeSize } from '../../style/CustomNode';
import CustomControl from '../../style/CustomControl';

// import { updateControl } from '../../../reteUtils';
import { IDataflow } from '../flow';
import { SinkColor } from '../../style/CustomNode';

export class GearNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  Record<string, never>,
  { ctrl: GearControl }
> {
  color = SinkColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: string) {
    super('PipeGeneration');
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addControl('ctrl', new GearControl(process, nodeId ? nodeId : this.id));
  }

  data(inputs: { in: IDataflow[] }) {
    if (!inputs['in']) {
      return {
        out: {
          img_paths: {},
          effector: {
            input: [],
            node: {
            },
          },
        },
      };
    }

    // 이부분 무한루프 없애도록 잘 바꿔야함
    // 첫번째 값 말고 바뀔때도 적용되도록
    const value = inputs['in'][0];
    const baseInput = value.img_paths;
    const baseInputKey = Object.keys(baseInput)[0];
    const basePath = baseInput[baseInputKey];

    // CustomProcessingControl에 value 설정
    // if (this.controls.ctrl.props.option.path !== basePath) {

    if (JSON.stringify(this.controls.ctrl.props.option.img_paths) !== JSON.stringify(baseInput)) {
      this.controls.ctrl.setValue({
        path: basePath,
        img_paths: baseInput,
        title: this.controls.ctrl.props.option.title,
        description: this.controls.ctrl.props.option.description,
        result: inputs['in'][0].effector,
      });
    }
    return {};
  }
}

export class GearControl extends ClassicPreset.Control {
  props: {
    option: IPipeGeneration;
  };
  contextOpen: boolean;
  // status => 백그라운드에서 처리 상태
  // init: 처음 생성 / in-progress: 처리 중 / completed: 처리 완료 / error: 에러 발생생
  status: string; 

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      option: {
        path: undefined,
        img_paths: undefined,
        title: '',
        description: '',
        result: [],
      },
    };
    this.contextOpen = false;
    this.status = 'init';
  }

  setValue(val: IPipeGeneration) {
    this.props.option = val;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };

  setStatus = (status: string) => {
    this.status = status;
  };
}

export function GearComp({ data }: { data: GearControl }) {
  return (
    <CustomControl
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
      nodeId={data.nodeId}
      label='Pipe Generation'
      iconSource='gear'
      markerSource='node-sink'
      status={data.status}
      setStatus={data.setStatus}
    />
  );
}
