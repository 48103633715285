import React from 'react';

type Node = {
  dataset_configuration?: any;
  blender: any;
  custom_processing?: {
    node_id: string;
    input_layout: string;
    input_type: string;
    output_layout: string;
    output_type: string;
  };
};

type InputNode = {
  input: InputNode[] | any[];
  node: Node;
};

const renderTree = (inputNode: InputNode, level = 1) => {
  const { node, input } = inputNode;

  return (
    <div
      key={node?.custom_processing?.node_id || `level-${level}`}
      style={{ marginLeft: `${level + 40}px`, marginTop: `${level + 20}px`, marginBottom: '20px' }}
    >
      {/* node가 빈 객체일 때 datamanagement 처리 */}
      <>
        {/* custom_processing 노드 처리 */}
        {node?.custom_processing && (
          <>
            <div
              style={{
                border: '1px solid #33828c',
                width: 'fit-content',
                padding: '10px',
                borderRadius: '7px',
              }}
            >
              <strong style={{ color: '#33828c' }}>{level} Custom_processing</strong>{' '}
              {JSON.stringify(node.custom_processing, null, 2)}
            </div>
          </>
        )}

        {/* blender 속성 처리 */}
        {node?.blender && (
          <>
            <div
              style={{
                border: '1px solid #33828c',
                width: 'fit-content',
                padding: '10px',
                borderRadius: '7px',
                // height: '40px',
              }}
            >
              <strong style={{ color: '#33828c' }}>{level} Blender</strong>:{' '}
              {JSON.stringify(node.blender, null, 2)}
            </div>
          </>
        )}
        {node?.dataset_configuration && (
          <>
            <div
              style={{
                border: '1px solid #a2a200',
                width: 'fit-content',
                padding: '10px',
                borderRadius: '7px',
                // height: '40px',
              }}
            >
              <strong style={{ color: '#a2a200' }}>{level} Dataset_configuration</strong>:{' '}
              {JSON.stringify(node.dataset_configuration, null, 2)}
            </div>
          </>
        )}

        {/* 나중에 추가로 들어올 노드들은 여기에 적으면 ㄱ*/}
      </>

      {/* 재귀적으로 input 노드 처리 */}
      {Array.isArray(input) &&
        input.map((child, idx) =>
          typeof child === 'string' ? (
            <div
              key={child}
              style={{
                border: '1px solid #6320b8',
                width: 'fit-content',
                padding: '10px',
                borderRadius: '7px',
                height: '40px',
              }}
            >
              <strong style={{ color: '#6320b8' }}> {level} Data Selector</strong>

              <strong> : {child}</strong>
            </div>
          ) : (
            renderTree(child, level + 1)
          ),
        )}
    </div>
  );
};

export default renderTree;
