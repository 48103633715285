import { GroupedItems, Item, OnEditTagTagParams } from '@/types/datahub/type';

/**
 * 태그를 편집하거나 새 태그를 생성하는 로직을 처리하는 함수
 *
 * @param {OnEditTagTagParams} params - 태그 편집 및 UI 상태 업데이트에 필요한 매개변수
 */
export const onEditTag = ({
  item,
  nowTag,
  sidePanelText,
  pipeList,
  setNowTag,
  setSidePanelText,
  setCreateTitle,
  setColor,
  setSelectPipeList,
  setPostBtnText,
  setSelectTag,
}: OnEditTagTagParams) => {
  setNowTag(item.title);

  if (nowTag === item.title && sidePanelText === 'Modify Tag') {
    setSidePanelText('Create New Tag');
    setCreateTitle('');
    setColor('#000000');
    setSelectPipeList([]);
    setPostBtnText('Add');
    return;
  }

  if (nowTag !== item.title && sidePanelText === 'Modify Tag') {
    setSidePanelText('Modify Tag');
    setCreateTitle(item.title);
    setColor(item.color);
    const transformedPipes = Object.entries(item.pipes).map(([pipeKey]) => {
      return pipeList.find((pipe) => pipe['pipe_key'] === pipeKey);
    });
    setSelectPipeList(transformedPipes);
    setPostBtnText('Edit');
    return;
  }

  if (sidePanelText === 'Create New Tag') {
    setSidePanelText('Modify Tag');
    setSelectTag(item.key);
    setCreateTitle(item.title);
    setColor(item.color);
    const transformedPipes = Object.entries(item.pipes).map(([pipeKey]) => {
      return pipeList.find((pipe) => pipe['pipe_key'] === pipeKey);
    });
    setSelectPipeList(transformedPipes);
    setPostBtnText('Edit');
    return;
  }
};

/**
 * 데이터를 태그별로 그룹화하여 반환하는 함수
 *
 * @param {{ [key: string]: Item }} data - 태그로 그룹화할 데이터 객체
 * @returns {GroupedItems} 태그별로 그룹화된 아이템 객체
 */
export const groupByTag = (data: { [key: string]: Item }): GroupedItems => {
  return Object.values(data).reduce<GroupedItems>((acc, item) => {
    if (!acc[item.tag]) {
      acc[item.tag] = [];
    }
    acc[item.tag].push(item);
    return acc;
  }, {});
};
