import './Login.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Mode from '../../components/mode/Mode';

import { Button, Input, InputGroup } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { useLogin } from '@/hooks/common/useLogin';
import useHandleGoogleLogin from '@/hooks/useHandleGoogleLogin';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

export default function Login() {
  const { login } = useLogin();
  const { googleLogin } = useHandleGoogleLogin();
  const navigate = useNavigate();
  const api = useAPIwithCookies();

  const [form, setForm] = useState({ username: '', password: '' });
  const [visible, setVisible] = useState(false);

  const [emailAlert, setEmailAlert] = useState<{
    alert: boolean;
    alertMessage: string;
  }>({ alert: false, alertMessage: '' });
  const [passwordAlert, setPasswordAlert] = useState<{
    alert: boolean;
    alertMessage: string;
  }>({ alert: false, alertMessage: '' });

  // 이메일 저장 로직
  const [saveEmail, setSaveEmail] = useState(false);

  useEffect(() => {
    const storedEmail = localStorage.getItem('savedEmail');
    if (storedEmail) {
      setForm((prev) => ({ ...prev, username: storedEmail }));
      setSaveEmail(true);
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true); // 로딩 시작

    if (form.username.length === 0) {
      setEmailAlert({ alert: true, alertMessage: 'Please enter your email' });
      setIsLoading(false); // 로딩 종료
      return;
    }
    if (form.password.length === 0) {
      setPasswordAlert({
        alert: true,
        alertMessage: 'Please enter your password',
      });
      setIsLoading(false); // 로딩 종료
      return;
    }

    if (saveEmail) {
      localStorage.setItem('savedEmail', form.username);
    } else {
      localStorage.removeItem('savedEmail');
    }

    const formData = new FormData();
    formData.append('username', form.username);
    formData.append('password', form.password);

    const response = await login(formData);
    if (response) {
      navigate('/vience-canvas/datahub');
    }

    if (!response) {
      setPasswordAlert({
        alert: true,
        alertMessage: 'Login failed',
      });
    }
    setIsLoading(false); // 로딩 종료
    return;
  };

  useEffect(() => {
    if (emailAlert) {
      setTimeout(() => {
        setEmailAlert({ ...emailAlert, alert: false });
      }, 3000);
    }
  }, [emailAlert]);

  useEffect(() => {
    if (passwordAlert) {
      setTimeout(() => {
        setPasswordAlert({ ...passwordAlert, alert: false });
      }, 3000);
    }
  }, [passwordAlert]);

  return (
    <div className='login'>
      <Mode />

      <div className='frame'>
        <div className='prev-frame'>
          <p
            className='prev'
            onClick={() => {
              window.history.back();
            }}
          >
            <svg
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M19.9702 12H5.97021'
                stroke='white'
                strokeWidth='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M12.9702 19L5.97021 12L12.9702 5'
                stroke='white'
                strokeWidth='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </p>
        </div>

        <Link to='/vience-canvas' className='logo'>
          VIENCE CANVAS
        </Link>

        <form className='form'>
          <div className='inputFrame'>
            <InputGroup className='form-input'>
              <img src={process.env.PUBLIC_URL + '/images/ico-mail.svg'} alt='' className='' />
              <Input
                placeholder='Email'
                value={form.username}
                onChange={(e) => {
                  setForm({ ...form, username: e });
                }}
              />
            </InputGroup>
            <p className='input-comment' style={{ display: 'none' }}>
              {emailAlert.alert ? emailAlert.alertMessage : ''}
            </p>

            <InputGroup className='form-input'>
              <img src={process.env.PUBLIC_URL + '/images/ico-pw.svg'} alt='' className='' />
              <Input
                type={visible ? 'text' : 'password'}
                placeholder='Password'
                onChange={(e) => {
                  setForm({ ...form, password: e });
                }}
              />
              <InputGroup.Button
                style={{ width: '5%' }}
                onClick={() => {
                  setVisible(!visible);
                }}
              >
                <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />
              </InputGroup.Button>
            </InputGroup>
            <p className='input-comment'>{passwordAlert.alert ? passwordAlert.alertMessage : ''}</p>
          </div>

          <div className='checkboxFrame'>
            <input
              type='checkbox'
              id='emailSave'
              checked={saveEmail}
              onChange={() => setSaveEmail(!saveEmail)} // 체크박스 상태 토글
            />
            <label htmlFor='emailSave'>Save Email</label>
          </div>

          <button
            onClick={handleLogin}
            className='mt-4 bg-[#673cbd] text-white px-4 py-2 rounded hover:bg-[#5a34a5] flex items-center justify-center'
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg
                  className='animate-spin h-5 w-5 mr-3 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 00-8 8h4l-3 3-3-3h4z'
                  ></path>
                </svg>
              </>
            ) : (
              'Sign in'
            )}
          </button>

          {/* <div className='sectionLine'>or</div> */}

          {/* <div className='google-btn' onClick={() => { googleLogin(); navigate('/vience-canvas/workspace'); }}> */}
          {/* <div className='google-btn'>
            <img
              src={process.env.PUBLIC_URL + '/images/google-logo.svg'}
              alt=''
            />
            <span>Sign in with Google</span>
          </div> */}
          <div className='userComent'>
            <span>New member?</span>
            <Link to='/vience-canvas/register'>
              <strong>Sign up</strong>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
