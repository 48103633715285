export const adjustMinimapStyle = () => {
  const minimapElement = document.querySelector('[data-testid="minimap"]');

  if (minimapElement) {
    const minimapStyle = minimapElement as HTMLElement;
    minimapStyle.style.position = 'absolute';
    minimapStyle.style.bottom = '20px';
    minimapStyle.style.right = '365px';
    minimapStyle.style.width = '250px';
    minimapStyle.style.height = '250px';

    minimapStyle.style.background = 'rgba(184, 184, 184, 0.2)';
    minimapStyle.style.border = '1px solid #666';
  }
};
