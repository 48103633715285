import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '@/components/common/inputs/Input';
import { createDir, renameItem } from '@/apis/datahub/directoryApis';

import { CustomThemeContext } from '@/contexts/common/Context';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
// import { fetchTaggingProgressList } from '@/apis/datahub/tagApis';
import useDataHubStore from '@/stores/datahub/useDataHubStore';

export default function DataHubBreadCrumb({ baseDir, setKeyword }) {
  const navigate = useNavigate();
  const { theme } = useContext(CustomThemeContext);

  const { curDir, selected, setCurDir, getDirectory, selectOpen, setSelectOpen } =
    useDataHubStore();

  const api = useAPIwithCookies();
  const [dirStatus, setDirStatus] = useState<[string, number, number, string][]>([]);

  const handleCreateDir = async () => {
    await createDir(api, curDir, theme);
    getDirectory(curDir, api);
  };

  const handleRenameItem = async () => {
    await renameItem(api, curDir, theme, selected);
    getDirectory(curDir, api);
  };

  // ** 베타테스트 배포를 위해 태그 관련 기능은 임시 주석 처리
  // const getFetchData = async () => {
  //   await fetchTaggingProgressList(api, curDir, setDirStatus);
  // };

  // useEffect(() => {
  //   getFetchData();
  // }, [curDir]);

  return (
    <>
      {' '}
      <div
        className='mb-[15px] ml-[4px] flex h-[50px] w-full
          items-center justify-between gap-[15px]'
      >
        <div className='flex'>
          <div
            onClick={() => {
              setCurDir('');
              navigate('/vience-canvas/datahub');
            }}
            className='relative flex w-auto cursor-pointer flex-row'
          >
            <span
              className='block w-full font-pretendard text-[20px] font-medium
                tracking-[-0.5px] text-black300 dark:text-white100'
            >
              My Hub
            </span>
            {curDir !== '' && (
              <svg
                className='size-6 stroke-black300 dark:stroke-white100'
                width='25'
                height='25'
                viewBox='0 2 30 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.5791 18.7734L15.5791 12.7734L9.5791 6.77344'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            )}
            {curDir === '' && (
              <>
                <img
                  className='cursor-pointer'
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/data-root-down.svg'
                      : '/images/data-root-down-white.svg'
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectOpen(!selectOpen);
                  }}
                  alt=''
                />
                {selectOpen && (
                  <ul
                    className='absolute bottom-0 left-0 z-30
                      translate-y-full rounded-custom-xl'
                  >
                    <li
                      className='mt-[10px] w-[230px] rounded-t-custom-lg
                        border-x border-t border-gray200
                        bg-white100 p-[20px] py-[15px]
                        text-black300 hover:bg-gray100
                        dark:border-black100 dark:bg-black200
                        dark:text-white100 dark:hover:bg-gray300'
                      onClick={handleCreateDir}
                    >
                      Create New Directory
                    </li>
                    <li
                      className='w-[230px] rounded-b-custom-lg
                        border-x border-b border-gray200
                        bg-white100 p-[20px]
                        py-[15px] text-black300 hover:bg-gray100
                        dark:border-black100 dark:bg-black200
                        dark:text-white100 dark:hover:bg-gray300'
                    >
                      Delete Current Directory
                    </li>
                  </ul>
                )}
              </>
            )}
          </div>
          {curDir !== baseDir &&
            curDir.split('/').map((dir, index) => {
              if (index !== curDir.split('/').length - 1) {
                let targetPath = '';

                for (let i = 0; i <= index; i++) {
                  targetPath += `${i === 0 ? '' : '/'}${curDir.split('/')[i]}`;
                }

                return (
                  <div
                    onClick={() => {
                      setCurDir(targetPath);
                      navigate(`/vience-canvas/datahub/${targetPath}`);
                    }}
                    className='relative flex w-auto cursor-pointer flex-row'
                    key={index}
                  >
                    <span
                      className='block w-full font-pretendard text-[20px] font-medium
                        tracking-[-0.5px] text-black300 dark:text-white100'
                    >
                      {dir}
                    </span>
                    <svg
                      className='size-6 stroke-black300 dark:stroke-white100'
                      width='25'
                      height='25'
                      viewBox='0 2 30 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9.5791 18.7734L15.5791 12.7734L9.5791 6.77344'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                );
              } else
                return (
                  <div className='relative flex w-auto cursor-pointer flex-row' key={index}>
                    <span
                      className='block w-full font-pretendard
                        text-[20px] font-medium tracking-[-0.5px] text-black300 dark:text-white100'
                    >
                      {dir}
                    </span>
                    <img
                      className='cursor-pointer'
                      src={
                        process.env.PUBLIC_URL + theme === 'dark'
                          ? '/images/data-root-down.svg'
                          : '/images/data-root-down-white.svg'
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectOpen(!selectOpen);
                      }}
                      alt=''
                    />
                    {selectOpen && (
                      <ul
                        className='absolute bottom-0 left-0 z-30
                          translate-y-full rounded-custom-xl'
                      >
                        <li
                          className='mt-[10px] w-[230px]
                            rounded-t-custom-lg border-x border-t
                            border-gray200 bg-white100
                            p-[20px] py-[15px]
                            text-black300 hover:bg-gray100
                            dark:border-black100 dark:bg-black200
                            dark:text-white100 dark:hover:bg-gray300'
                          onClick={handleCreateDir}
                        >
                          Create New Directory
                        </li>
                        <li
                          className='w-[230px] border-x border-gray200
                              bg-white100 p-[20px] py-[15px]
                              text-black300 hover:bg-gray100
                              dark:border-black100 dark:bg-black200
                              dark:text-white100 dark:hover:bg-gray300'
                          onClick={handleRenameItem}
                        >
                          Rename Current Directory
                        </li>
                        <li
                          className='w-[230px] rounded-b-custom-lg border-x border-b
                            border-gray200 bg-white100
                            p-[20px] py-[15px]
                            text-black300 hover:bg-gray100
                            dark:border-black100 dark:bg-black200
                            dark:text-white100 dark:hover:bg-gray300'
                        >
                          Delete Current Directory
                        </li>
                      </ul>
                    )}
                  </div>
                );
            })}
        </div>
        <div className='flex h-full items-center gap-[10px] text-center'>
          {curDir !== '' &&
            dirStatus.map((status, index) => (
              <div key={index}>
                <div className='p-0 text-[12px] text-black300 dark:text-white100'>{status[0]}</div>
                <div
                  className='relative mt-[5px] h-[20px] w-[160px]
                      overflow-hidden rounded-custom-lg
                      bg-gray100 dark:bg-black100'
                >
                  <div
                    className='absolute right-0 top-0 mr-[10px]
                        flex h-full items-center text-[10px]'
                  >
                    {status[1]}/{status[2]}
                  </div>
                  <span
                    style={{
                      background: `${status[3]}`,
                      width: `${100 * (status[1] / status[2])}%`,
                    }}
                    className='absolute left-0 top-0 h-full'
                  ></span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Input setKeyword={setKeyword} />
    </>
  );
}
