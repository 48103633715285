import React, { useState, useEffect } from 'react'
import "./example.scss";
import { Link } from "react-router-dom";
import { LandingHeader } from "../../../components/landingHeader/LandingHeader";
import { LineGrid } from "../../../components/lineGrid/LineGrid";
import { LandingFooter } from '../../../components/landingFooter/LandingFooter';
import { LandingSearch } from '../../../components/landingSearch/LandingSearch';
import AOS from "aos";
import "aos/dist/aos.css";

const Example:React.FC = () => {

    const [isHovering01, setIsHovering01] = useState(0);
    const [isHovering02, setIsHovering02] = useState(0);
    const [isHovering03, setIsHovering03] = useState(0);
    const [isHovering04, setIsHovering04] = useState(0);
    const toggleList01 = isHovering01 ? 'active' : '';
    const toggleList02 = isHovering02 ? 'active' : '';
    const toggleList03 = isHovering03 ? 'active' : '';
    const toggleList04 = isHovering04 ? 'active' : '';

    useEffect(() => {
        AOS.init({ duration: 1500 });
    },[]);

    return (
        <div className="example">
            <LineGrid />
            <div className="frame">
                <LandingHeader />
                <main>
                    <h2 data-aos="fade-left">Example</h2>
                    <div data-aos="fade-right">
                        <LandingSearch />
                    </div>

                    <div className="example-list">
                        <ul data-aos="fade-up">
                            {
                                isHovering01 ?
                                    <li
                                        className={`${toggleList01}`}
                                        onMouseOver={() => setIsHovering01(1)}
                                        onMouseOut={() => setIsHovering01(0)}
            
                                    >
                                        <Link to="/">
                                            <div className="top">
                                                <img src={process.env.PUBLIC_URL + '/images/test-img.png'} alt="" className="" />
                                            </div>
                                            <div className="bottom">
                                                <span>Category1</span>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + '/images/ico-tree.svg'} alt="" className="" />
                                                    <strong>Example01</strong>
                                                </div>
                                                <p>This example is...</p>
                                            </div>

                                            <div className="list-hover">
                                                <div>
                                                    <button type="button">
                                                        <strong>Go to Example Canvas</strong>
                                                        <img src={process.env.PUBLIC_URL + '/images/ico-leftArrow.svg'} alt="" className="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    :
                                    <li
                                        className={`${toggleList01}`}
                                        onMouseOver={() => setIsHovering01(1)}
                                        onMouseOut={() => setIsHovering01(0)}
                                    >
                                        <Link to="/">
                                            <div className="top">
                                                <img src={process.env.PUBLIC_URL + '/images/test-img.png'} alt="" className="" />
                                            </div>
                                            <div className="bottom">
                                                <span>Category1</span>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + '/images/ico-tree.svg'} alt="" className="" />
                                                    <strong>Example01</strong>
                                                </div>
                                                <p>This example is...</p>
                                            </div>
                                        </Link>
                                    </li>
                            }
                            {
                                isHovering02 ?
                                    <li
                                        className={`${toggleList02}`}
                                        onMouseOver={() => setIsHovering02(1)}
                                        onMouseOut={() => setIsHovering02(0)}
                                    >
                                        <Link to="/">
                                            <div className="top">
                                                <img src={process.env.PUBLIC_URL + '/images/test-img.png'} alt="" className="" />
                                            </div>
                                            <div className="bottom">
                                                <span>Category1</span>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + '/images/ico-tree.svg'} alt="" className="" />
                                                    <strong>Example01</strong>
                                                </div>
                                                <p>This example is...</p>
                                            </div>

                                            <div className="list-hover">
                                                <div>
                                                    <button type="button">
                                                        <strong>Go to Example Canvas</strong>
                                                        <img src={process.env.PUBLIC_URL + '/images/ico-leftArrow.svg'} alt="" className="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    :
                                    <li
                                        className={`${toggleList02}`}
                                        onMouseOver={() => setIsHovering02(1)}
                                        onMouseOut={() => setIsHovering02(0)}
                                    >
                                        <Link to="/">
                                            <div className="top">
                                                <img src={process.env.PUBLIC_URL + '/images/test-img.png'} alt="" className="" />
                                            </div>
                                            <div className="bottom">
                                                <span>Category1</span>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + '/images/ico-tree.svg'} alt="" className="" />
                                                    <strong>Example01</strong>
                                                </div>
                                                <p>This example is...</p>
                                            </div>
                                        </Link>
                                    </li>
                            }

                            {
                                isHovering03 ?
                                    <li
                                        className={`${toggleList03}`}
                                        onMouseOver={() => setIsHovering03(1)}
                                        onMouseOut={() => setIsHovering03(0)}
                                    >
                                        <Link to="/">
                                            <div className="top">
                                                <img src={process.env.PUBLIC_URL + '/images/test-img.png'} alt="" className="" />
                                            </div>
                                            <div className="bottom">
                                                <span>Category1</span>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + '/images/ico-tree.svg'} alt="" className="" />
                                                    <strong>Example01</strong>
                                                </div>
                                                <p>This example is...</p>
                                            </div>

                                            <div className="list-hover">
                                                <div>
                                                    <button type="button">
                                                        <strong>Go to Example Canvas</strong>
                                                        <img src={process.env.PUBLIC_URL + '/images/ico-leftArrow.svg'} alt="" className="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    :
                                    <li
                                        className={`${toggleList03}`}
                                        onMouseOver={() => setIsHovering03(1)}
                                        onMouseOut={() => setIsHovering03(0)}
                                    >
                                        <Link to="/">
                                            <div className="top">
                                                <img src={process.env.PUBLIC_URL + '/images/test-img.png'} alt="" className="" />
                                            </div>
                                            <div className="bottom">
                                                <span>Category1</span>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + '/images/ico-tree.svg'} alt="" className="" />
                                                    <strong>Example01</strong>
                                                </div>
                                                <p>This example is...</p>
                                            </div>
                                        </Link>
                                    </li>
                            }

                            {
                                isHovering04 ?
                                    <li
                                        className={`${toggleList04}`}
                                        onMouseOver={() => setIsHovering04(1)}
                                        onMouseOut={() => setIsHovering04(0)}
                                    >
                                        <Link to="/">
                                            <div className="top">
                                                <img src={process.env.PUBLIC_URL + '/images/test-img.png'} alt="" className="" />
                                            </div>
                                            <div className="bottom">
                                                <span>Category1</span>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + '/images/ico-tree.svg'} alt="" className="" />
                                                    <strong>Example01</strong>
                                                </div>
                                                <p>This example is...</p>
                                            </div>

                                            <div className="list-hover">
                                                <div>
                                                    <button type="button">
                                                        <strong>Go to Example Canvas</strong>
                                                        <img src={process.env.PUBLIC_URL + '/images/ico-leftArrow.svg'} alt="" className="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    :
                                    <li
                                        className={`${toggleList04}`}
                                        onMouseOver={() => setIsHovering04(1)}
                                        onMouseOut={() => setIsHovering04(0)}
                                    >
                                        <Link to="/">
                                            <div className="top">
                                                <img src={process.env.PUBLIC_URL + '/images/test-img.png'} alt="" className="" />
                                            </div>
                                            <div className="bottom">
                                                <span>Category1</span>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + '/images/ico-tree.svg'} alt="" className="" />
                                                    <strong>Example01</strong>
                                                </div>
                                                <p>This example is...</p>
                                            </div>
                                        </Link>
                                    </li>
                            }

                        </ul>
                    </div>
                </main>
            </div>
            <div data-aos="fade-up" data-aos-delay="400">
                <LandingFooter />
            </div>
        </div>
    )
}

export default Example