import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ width: "100%", margin: "0 auto", fontSize: "12px" }}>
      {/* 개인정보 처리방침 */}
      {/* <h1>개인정보처리방침 (Privacy Policy)</h1> */}
      <h2  style={{fontWeight: "800"}}>제1조 (목적)</h2>
      <p>
        이 개인정보처리방침은 ㈜바이언스(이하 “회사”라 함)가 운영하는 VIENCE CANVAS(이하
        “서비스”라 함)에서 이용자의 개인정보를 어떠한 방법과 목적으로
        수집·이용·보관·파기하는지, 이용자의 권리를 어떤 방식으로 보호하는지를
        안내하기 위함입니다.
      </p>

      <h2  style={{fontWeight: "800"}}>제2조 (수집하는 개인정보 및 수집 방법)</h2>
      <ol>
        <li>
          <strong>수집하는 개인정보 항목</strong>
          <ul>
            <li>
              회원가입 시: 이메일(아이디 용도), 비밀번호, 이름, 닉네임(공개
              식별자), 소속 타입, 소속 이름, 직업 종류, 서비스 가입 목적,
              마케팅 이메일 수신 동의 여부
            </li>
            <li>
              서비스 이용 과정에서: 이용자가 업로드하는 데이터, 분석 요청 및
              결과 데이터, 서비스 이용 로그, 접속 IP, 쿠키, 기기정보 등(서비스
              안정성 및 품질 개선 목적)
            </li>
          </ul>
        </li>
        <li>
          <strong>개인정보 수집 방법</strong>
          <ul>
            <li>회원가입 및 서비스 이용 과정에서 이용자가 직접 입력</li>
            <li>쿠키, 이용 로그, 기기정보 등은 서비스 이용 과정에서 자동 생성·수집</li>
          </ul>
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제3조 (개인정보의 이용 목적)</h2>
      <p>
        회사는 수집한 개인정보를 아래의 목적을 위해 이용합니다.
      </p>
      <ol>
        <li>
          <strong>회원관리</strong>: 회원 식별, 가입 및 탈퇴 의사 확인, 부정
          이용 방지, 분쟁 조정 등
        </li>
        <li>
          <strong>서비스 제공</strong>: 데이터 분석 및 처리, 맞춤형 분석 결과
          제공, 신규 기능 알림 등
        </li>
        <li>
          <strong>마케팅 및 광고</strong>: 서비스 관련 정보, 이벤트·프로모션
          안내 등(단, 마케팅 이메일 수신 동의자 대상)
        </li>
        <li>
          <strong>서비스 개선</strong>: 접속 빈도 파악, 회원의 서비스 이용 분석,
          기능 개선, 품질 유지 및 보수
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제4조 (개인정보의 보유 및 이용기간)</h2>
      <ol>
        <li>
          회사는 원칙적으로 개인정보의 수집 및 이용 목적이 달성되면 지체 없이
          해당 개인정보를 파기합니다.
        </li>
        <li>
          단, 아래의 경우에는 관련 법령에서 정한 기간 동안 예외적으로 보관할 수
          있습니다.
          <ul>
            <li>상법 등 법령에 따라 보존이 요구되는 경우</li>
            <li>분쟁 발생 시 분쟁 해결을 위해 보관할 필요가 있는 경우</li>
            <li>그 밖에 이용자로부터 별도의 동의를 받은 경우</li>
          </ul>
        </li>
        <li>
          관련 법령에서 예외적으로 보존을 요구하는 경우 예시는 다음과 같습니다.
          <br />
          (예: 전자상거래 등에서의 소비자보호에 관한 법률, 국세기본법, 법인세법,
          부가가치세법 등)
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제5조 (개인정보의 파기 절차 및 방법)</h2>
      <ol>
        <li>
          <strong>파기 절차</strong>: 개인정보 보유기간의 경과 또는 처리 목적
          달성 후 별도의 DB(종이 서류의 경우 별도의 서류함)에 옮겨져 내부 방침
          및 기타 관련 법령에 따라 일정 기간 보관 후 파기합니다.
        </li>
        <li>
          <strong>파기 방법</strong>: 전자적 파일 형태의 정보는 재생 불가능한
          방법으로 영구 삭제하며, 종이 문서의 경우 파쇄 또는 소각 등의 방법으로
          파기합니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제6조 (개인정보의 제3자 제공 및 위탁)</h2>
      <ol>
        <li>
          회사는 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다. 단,
          아래의 경우는 예외로 합니다.
          <ul>
            <li>이용자가 사전에 동의한 경우</li>
            <li>
              법령의 규정에 의하거나, 수사·조사 목적으로 법령에 정해진 절차와
              방법에 따라 수사기관의 요구가 있는 경우
            </li>
          </ul>
        </li>
        <li>
          회사는 서비스 운영에 필요한 일부 업무를 제3자에게 위탁할 수 있습니다.
          이 경우 위탁받은 업체와의 계약을 통해 개인정보 보호 관련 법령의 준수,
          개인정보 안전성 확보 조치, 재위탁 제한, 책임 부담 등을 규정하며,
          위탁 내역을 이용자에게 알립니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제7조 (이용자 및 법정대리인의 권리와 행사 방법)</h2>
      <ol>
        <li>
          이용자는 언제든지 개인정보 열람, 수정, 삭제, 처리정지 등을 요구할 수
          있습니다.
        </li>
        <li>
          이용자가 개인정보 오류 정정을 요청한 경우, 회사는 정정을 완료하기
          전까지 해당 개인정보를 이용하거나 제3자에게 제공하지 않습니다.
        </li>
        <li>
          만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보에 대하여 열람,
          정정, 삭제 등의 권리를 행사할 수 있습니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제8조 (쿠키의 사용 및 거부)</h2>
      <ol>
        <li>
          회사는 개인화된 서비스를 제공하기 위해 쿠키를 사용할 수 있습니다.
          쿠키란 웹사이트가 이용자의 컴퓨터 브라우저로 전송하는 소량의
          정보입니다.
        </li>
        <li>
          이용자는 웹 브라우저의 설정을 통해 쿠키 저장을 거부하거나 삭제할 수
          있습니다. 다만, 쿠키 저장을 거부할 경우 일부 서비스 이용이 제한될 수
          있습니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제9조 (개인정보 보호를 위한 기술적·관리적 대책)</h2>
      <ol>
        <li>
          회사는 개인정보를 안전하게 취급하기 위하여 다음과 같은 대책을
          마련하고 있습니다.
          <ul>
            <li>개인정보의 암호화: 비밀번호 등 민감 정보는 암호화하여 보관 및 전송</li>
            <li>
              해킹 등에 대비한 대책: 방화벽, 침입탐지시스템 등을 이용하여
              외부로부터의 접근 통제
            </li>
            <li>
              개인정보 취급자의 최소화 및 교육: 개인정보를 취급하는 직원을
              최소화하고, 정기적인 교육 및 점검 실시
            </li>
          </ul>
        </li>
        <li>
          회사는 개인정보 유출, 훼손 등이 발생하지 않도록 최대한 노력합니다.
          다만, 회사가 기술적·관리적 보호조치를 다 했음에도 불구하고 이용자
          본인의 부주의나 회사의 귀책사유가 아닌 불가항력(예: 천재지변)
          으로 인해 발생한 문제에 대해서는 책임을 지지 않습니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제10조 (개인정보 보호책임자 및 문의처)</h2>
      <ol>
        <li>
          회사는 개인정보 처리와 관련한 이용자의 불만 처리 및 피해구제 등을
          위하여 아래와 같이 개인정보 보호책임자를 지정·운영하고 있습니다.
          <br />
          - 개인정보 보호책임자: 최준영 / CTO / jychoi@vience.co.kr
        </li>
        <li>
          이용자는 서비스 이용 중 발생하는 모든 개인정보 보호 관련 문의, 불만
          처리, 피해 구제 등을 위 연락처로 신고할 수 있으며, 회사는 이용자의
          문의에 신속하고 성실하게 답변하겠습니다.
        </li>
      </ol>

      <h2  style={{fontWeight: "800"}}>제11조 (개인정보처리방침의 변경)</h2>
      <ol>
        <li>본 방침은 관련 법령 및 회사 정책에 따라 변경될 수 있습니다.</li>
        <li>
          회사는 변경 내용이 있을 경우, 최소 7일 전(중대한 변경의 경우 30일
          전)부터 서비스 내 공지사항 또는 이메일 등을 통해 고지합니다.
        </li>
      </ol>
      <br />
      <p>
        <strong>부칙</strong>
        <br />
        이 개인정보처리방침은 2025년 3월 6일부터 적용됩니다.
      </p>

    </div>
  );
};

export default PrivacyPolicy;