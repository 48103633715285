import React from "react";
import { ClassicPreset, NodeId } from "rete";

import { CustomSocket, EffectorColor_step2, NodeSize } from "../../style";
import CustomControl from "../../style/CustomControl";
import { ILayerOption, IMlopsflow } from "../flow";

export class BatchNorm2dNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: BatchNorm2dControl }
> {
  color = EffectorColor_step2;
  width = NodeSize.width;
  height = NodeSize.height;
  error = '';

  constructor(
    process: () => void, 
    update: (control: BatchNorm2dControl) => void, 
    nodeId: NodeId
  ) {
    super('BatchNorm 2D');
    this.id = nodeId === "" ? this.id : nodeId;
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new BatchNorm2dControl(
      process, 
      update,
      this.id
    ));
  }
  data(input): { out?: IMlopsflow | undefined } {
    let output: IMlopsflow = {
      datasetPath: {},
      layer: {
        input: [],
        node: {
          "batchNorm2d": this.controls.ctrl.props.option
        },
      }
    };

    if (input.in) {
      const inputIn = input.in ?? [undefined];
      const inputNode = inputIn[0];

      if (inputNode){
        const data1_key = Object.keys(inputNode.layer.node)[0];
        if(data1_key!=="dataset"){
          if(inputNode.layer.node[data1_key].outChannel){
            this.controls.ctrl.props.option.numFeatures = inputNode.layer.node[data1_key].outChannel;
          }
          else if(inputNode.layer.node[data1_key].numFeatures){
            this.controls.ctrl.props.option.numFeatures = inputNode.layer.node[data1_key].numFeatures;
          }
        }
        if (data1_key === 'reluActivation') {
          this.error = 'Nodes are not connected in the recommended order';
        } else {
          this.error = '';
        }

      }

      if (inputNode && inputNode.datasetPath) {
        const data1_key = Object.keys(inputNode.layer.node)[0];
        let convertedResolution = 
          inputNode.layer.node[data1_key].datasetResolution;
        if (Number.isNaN(convertedResolution)) {
          convertedResolution = 66
        }

        this.controls.ctrl.props.option.datasetResolution = convertedResolution;
        this.controls.ctrl.datasetResolution = convertedResolution;
        if (localStorage.getItem('dynamic') === 'true') {
          this.height = convertedResolution < 66 ? 66 : convertedResolution;
          this.width = (
            50 
            * Math.log2(Number(this.controls.ctrl.props.option.numFeatures))) < 222 
          ? 222 
          : (50 * Math.log2(Number(this.controls.ctrl.props.option.numFeatures)));
        }
        else{
          this.color = { base: '#296871', sub1: '#2F7781', sub2: '#226D78' };
        }

        output = {
          datasetPath: inputNode?.datasetPath ?? '',
          layer: {
            input: [inputNode?.layer],
            node: {
              "batchNorm2d": this.controls.ctrl.props.option
            }
          }
        }
      }
    } 
    return {
      out: output
    }
  }
}

export class BatchNorm2dControl extends ClassicPreset.Control {
  contextOpen: boolean;
  props: {
    option: ILayerOption;
  };
  modelFlow: IMlopsflow;
  datasetResolution: number;

  constructor(
    public onChange: () => void, 
    public update: (c: BatchNorm2dControl) => void, 
    public nodeId: NodeId
  ) {
    super();
    this.contextOpen = false;
    this.props = {
      option: {
        nodeId,
        name: "BatchNorm2d",
        numFeatures: 1,
      }
    };
    this.modelFlow = {
      datasetPath: {},
      layer: undefined,
    };
    this.datasetResolution = 66;
  }

  setValue(data: ILayerOption) {
    this.props.option = data;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    this.update(this);
  }
}

export function BatchNorm2dComponent({ data }: { 
  data: BatchNorm2dControl 
}) {
  if (!data.nodeId) return <p>Error</p>;
  return (
    <CustomControl 
      nodeId={data.nodeId}
      label="BatchNorm 2D" 
      iconSource="denoising" 
      markerSource='node-effector' 
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
    />
  )
}