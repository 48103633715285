import React, { useState, useEffect, useRef, memo, useContext } from 'react';

import { useParams } from 'react-router-dom';
import { default as OSD } from 'openseadragon';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';

import { ViewerControl } from '../../../ViewerNode';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { CustomThemeContext } from '@/contexts/common/Context';

import '@/components/rete/components/nodes/viewer/viewer.scss';

export default memo(function Type1ViewerPanel({
  ctrl,
  viewerKey,
}: {
  ctrl: ViewerControl;
  viewerKey: string;
}) {
  const [selectedSet, setSelectedSet] = useState<string>('');
  const [selectedImgKey, setSelectedImgKey] = useState<string>('');
  const [selectedTextKey, setSelectedTextKey] = useState<string>('');
  const [textData, setTextData] = useState<string>('');
  // const [metaData, setMetadata] = useState<string>('');
  const [answers, setAnswers] = useState(ctrl.addon?.option.value || []);
  console.log(answers);
  const [osdKey, setOsdKey] = useState<string>('');
  const [keys, setKeys] = useState<Record<string, string[]>>({});

  const [rightPanelWidth, setRightPanelWidth] = useState(400); // pixel 단위
  const [textPanelHeight, setTextPanelHeight] = useState(50); // % 단위

  const [selectedAnswer, setSelectedAnswer] = useState<string>('');

  let startDragPos = 0;

  const api = useAPIwithCookies();
  const params = useParams();
  const { theme } = useContext(CustomThemeContext);

  // TODO
  // Type1 패널이 최초 마운트 되었을 때 초기 keys 가져오기
  const fetchKeyList = async () => {
    try {
      const response = await api.get(`/viewer/output_data_info/` + viewerKey);
      const data = response.data.result;

      if (data.type === 'vmeta') {
        setKeys(data.structure);
        const firstKeys = Object.keys(data.structure);
        if (firstKeys.length > 0) {
          const secondKeys = Object.keys(data.structure[firstKeys[0]]);
          if (secondKeys.length > 0) {
            for (let i = 0; i < secondKeys.length; i++) {
              if (secondKeys[i].includes('img-')) {
                // prettier-ignore
                const imageResponse = await api.get(
                  `/viewer/generate_osd_meta/${viewerKey}/${data.structure[firstKeys[0]][secondKeys[i]]}`,
                );

                const generatedOsdKey = imageResponse.data;
                setOsdKey(generatedOsdKey);

                setSelectedSet(firstKeys[0]);
                setSelectedImgKey(secondKeys[i]);
                break;
              }
            }
            for (let i = 0; i < secondKeys.length; i++) {
              if (secondKeys[i].includes('text-')) {
                const textResponse = await api.get(
                  `/viewer/vmeta_load_text/${viewerKey}/${data.structure[firstKeys[0]][secondKeys[i]]}`,
                );

                setTextData(textResponse.data);
                setSelectedTextKey(secondKeys[i]);
                break;
              }
            }
          }
        }
      } else {
        console.warn('Invalid data format received:', data);
      }
    } catch (error) {
      console.error('Failed to fetch keyList:', error);
    }
  };

  useEffect(() => {
    fetchKeyList();
  }, [ctrl.nodeId, viewerKey]);

  // target 버튼으로 스크롤
  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById('img-slider');
      if (!container) return;

      const targetButton = container.querySelector(
        `li[data-key='${selectedSet}-${selectedImgKey}']`,
      ) as HTMLElement | null;
      if (targetButton) {
        const offset =
          targetButton.offsetTop -
          container.offsetTop -
          container.clientHeight / 2 +
          targetButton.clientHeight / 2;

        container.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }, 500);
  }, [selectedSet, selectedImgKey]);

  // target 버튼으로 스크롤
  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById('text-slider');
      if (!container) return;

      const targetButton = container.querySelector(
        `button[data-key='${selectedSet}-${selectedTextKey}']`,
      ) as HTMLElement | null;
      if (targetButton) {
        const offset =
          targetButton.offsetLeft -
          container.offsetLeft -
          container.clientWidth / 2 +
          targetButton.clientWidth / 2;

        container.scrollTo({
          left: offset,
          behavior: 'smooth',
        });
      }
    }, 500);
  }, [selectedSet, selectedTextKey]);

  // TODO
  // 좌측 normal 패널에서 특정 key 선택 시, 관련 이미지와 텍스트 불러오기
  const handleImgKeySelection = async (firstKey: string, secondKey: string, directClick = true) => {
    try {
      const imageResponse = await api.get(
        `/viewer/generate_osd_meta/${viewerKey}/${keys[firstKey][secondKey]}`,
      );
      const generatedOsdKey = imageResponse.data;
      setOsdKey(generatedOsdKey);

      if (directClick && selectedSet !== firstKey) {
        // 같은 set에 있는 첫번째 text 구하기
        const secondKeys = Object.keys(keys[firstKey]);
        for (let i = 0; i < secondKeys.length; i++) {
          if (secondKeys[i].includes('text-')) {
            handleTextKeySelection(firstKey, secondKeys[i], false);
            break;
          }
        }
      }

      setSelectedSet(firstKey);
      setSelectedImgKey(secondKey);
    } catch (error) {
      setOsdKey('');
      console.error('Error loading image or text data:', error);
    }
  };

  const handleTextKeySelection = async (
    firstKey: string,
    secondKey: string,
    directClick = true,
  ) => {
    try {
      // 해당 firstKey의 텍스트 데이터 요청
      const textResponse = await api.get(
        `/viewer/vmeta_load_text/${viewerKey}/${keys[firstKey][secondKey]}`,
      );

      // 받아온 text 데이터 처리
      setTextData(textResponse.data);

      if (directClick && selectedSet !== firstKey) {
        // 같은 set에 있는 첫번째 img 구하기
        const secondKeys = Object.keys(keys[firstKey]);
        for (let i = 0; i < secondKeys.length; i++) {
          if (secondKeys[i].includes('img-')) {
            handleImgKeySelection(firstKey, secondKeys[i], false);
            break;
          }
        }
      }

      setSelectedSet(firstKey);
      setSelectedTextKey(secondKey);
    } catch (error) {
      console.error('Error loading text data for tab selection:', error);
      setTextData('');
    }
  };

  const handleAnswerSelection = async (input: string) => {
    setSelectedAnswer(input);
  };

  // 오른쪽 텍스트, form 패널에 뜰 list (key tab) 에는 vmeta에서 convention이 text인 것들을 가져오면 됨 => first_key
  // + form data에서 id 들을 가져와야 함. => vemta의 first key랑 중복되는게 있으면 제외
  // * form: addon.option.value 변화를 감지하여 answers를 업데이트
  useEffect(() => {
    setAnswers(ctrl.addon?.option.value || []);
  }, [ctrl.addon?.option.value]);

  // * form: 사용자가 입력/선택한 값을 업데이트하는 함수
  const handleAnswerChange = (groupIndex: number, index: number, value: string) => {
    setAnswers((prevAnswers) => {
      const newAnswers = prevAnswers.map((group, gIdx) =>
        gIdx === groupIndex
          ? {
              ...group,
              inputs: group.inputs.map((item, i) =>
                i === index ? { ...item, answer: value } : item,
              ),
            }
          : group,
      );
      ctrl.addon.option.value = newAnswers;
      ctrl.setAddon(ctrl.addon);
      return newAnswers;
    });
  };

  // inputs = [
  //   { type: 'text', question: '22', contents: null, answer: 'ㄱ3' },
  //   { type: 'text', question: '23', contents: null, answer: 'ㄴㄴ3' },
  // ];

  // [{type: 'choice', question: '선택', contents: ['1', '2', '3'], answer: '1'}, ]

  // 최종 제출
  const handleSubmitViewerMeataData = async (id: any) => {
    const selectedGroup = answers.find((group: any) => group.listName === id);

    // "contents" 데이터 생성
    const contents = selectedGroup.inputs.map((item: any) => {
      let answer = item.answer;

      if (Array.isArray(answer)) {
        if (answer.length > 1) {
          return [item.question, item.contents[0]];
        }
        answer = answer[0]; // 첫 번째 요소만 사용
      }

      return [item.question, answer];
    });

    // 현재 화면 캡처
    const screenshot = await captureScreen();

    const title = 'id: ' + selectedSet + ' | ' + 'case: ' + selectedTextKey;
    // const title = window.prompt(name + '\n답변 정보(제목, 작성자 등)를 입력해 주세요.');
    // if (!title) {
    //   alert('답변 정보를 입력해야 합니다.');
    //   return;
    // }
    const name = window.prompt(title + '\n답변 정보(제목, 작성자 등)를 입력해 주세요.');
    if (!name) {
      alert('답변 정보를 입력해야 합니다.');
      return;
    }

    // 백엔드로 전송할 데이터
    const payload = {
      title: title,
      name: name,
      contents: contents,
      created_at: new Date().toISOString().replace('T', ' ').split('.')[0],
      screenshot: screenshot,
    };

    try {
      const response = await api.post(`/viewer/save_form`, payload);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '제출 성공!',
          text: '응답이 정상적으로 제출되었습니다.',
          confirmButtonText: '확인',
        });
      } else {
        throw new Error('서버 오류');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '제출 실패!',
        text: '서버와의 통신 중 오류가 발생했습니다.',
        confirmButtonText: '확인',
      });
      console.error(error);
    }
  };

  const captureRef = useRef<HTMLDivElement>(null);

  const captureScreen = async () => {
    if (!captureRef.current) return;

    const canvas = await html2canvas(captureRef.current);
    return canvas.toDataURL('image/png').split(',')[1];
  };



  //publish
  const [publishOpen, setPublishOpen] = useState<boolean>(false);

  const [sharedUrl, setSharedUrl] = useState(''); // 받아온 URL 저장
  const [loading, setLoading] = useState(false); // 로딩 상태
  const [copied, setCopied] = useState(false); // 복사 상태
  const [error, setError] = useState(false); // api 에러 상태

  const handleGetUrl = async () => {
    const response = await api.post(`/publish/new/${ctrl.nodeId}`, {
      wor_id: params.wor_id,
      mod_seq: '',
      usa_seq: '',
      effector: ctrl.props.option.effector,
      img_paths: ctrl.props.option.img_paths,
    });

    console.log(response.data);
    setSharedUrl(response.data);
  };

  useEffect(() => {
    if (publishOpen) {
      console.log(ctrl.props.option.effector);
      console.log(ctrl.props.option.img_paths);
      setLoading(true);
      setError(false);
      try{
        handleGetUrl();
      } catch (err) {
        console.error('Error', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [publishOpen]);


  const handleCopy = () => {
    navigator.clipboard.writeText(sharedUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };


  return (
    <div
      className='flex w-full flex-col'
    >
      {/*publish 버튼*/}
      <div
        className='px-4'
      >
        <button
          className='flex items-center px-4 py-2 cursor-pointer rounded-lg
            border border-solid border-black dark:border-white
            text-[16px] text-black100 hover:text-black100 hover:brightness-75 
            dark:text-white100 dark:hover:text-white100'
          onClick={() => {
            setPublishOpen(true);
          }}
        >
          <img
            className='w-[24px]'
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/canvas-play.svg'
                : '/images/canvas-play-white.svg'
            }
            alt=''
          />
          Publish 
        </button>
        {/* 퍼블리시 모달 */}
        {publishOpen && (
          <div className='absolute  w-screen h-screen bg-black bg-opacity-80 z-50'>
            <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-[#282A2C] p-6 rounded-lg shadow-lg min-w-[300px] w-[450px] min-h-[200px] flex flex-col items-center justify-center space-y-4'>
              <h2 className='text-lg font-semibold dark:text-white'>
                {error? '서버 에러가 발생했습니다. 잠시후 다시 시도해 주세요' :
                    loading? 'URL을 생성중입니다. 잠시만 기다려 주세요.':'URL로 결과를 공유하세요.'}
              </h2>

              <div className='w-full flex flex-col items-center'>
                <div className='flex items-center justify-evenly space-x-2 w-full'>
                  <input
                    type='text'
                    className='w-[75%] border rounded px-3 py-2 text-center text-black dark:text-white'
                    readOnly
                    value={loading ? 'loading...' : sharedUrl}
                  />
                  <button
                    className='bg-purple200 text-white px-3 py-2 rounded hover:bg-purple300 hover:dark:bg-purple300 transition w-[20%]'
                    onClick={handleCopy}
                    disabled={loading || !sharedUrl}
                  >
                    {copied ? 'copyed!' : 'copy'}
                  </button>
                </div>
              </div>

              <button
                className='bg-[#e3e3e3] dark:bg-[#131313] text-[black] dark:text-white px-4 py-2 rounded hover:bg-[#d2d2d2] hover:dark:bg-[#000000]'
                onClick={() => setPublishOpen(false)}
              >
                닫기
              </button>
            </div>
          </div>
        )}

      </div>

      {/*viewer 파트*/}
      <div
        className='rounded-custom-lg mb-[var(--20px)] flex w-full flex-row overflow-auto'
        ref={captureRef}
      >
        <div className='flex h-[calc(100vh-12rem-30px)] w-full flex-row overflow-auto p-4'>
          {/* 왼쪽 이미지 리스트 패널 */}
          <div
            className='rounded-custom-lg bg-white100
              dark:bg-black200 mr-4 w-[245px] p-6
              shadow-lg transition-all duration-300'
          >
            <div className='flex items-center gap-2.5'>
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/rete/node/icon/viewer.svg'
                    : '/images/rete/node/icon/viewer-black.svg'
                }
                alt='Image List Icon'
                className='size-6'
              />
              <div
                className='maintitle text-black300 dark:text-white100
                break-all text-[24px] font-bold tracking-[var(--spacingDefalt)]'
              >
                Image List
              </div>
            </div>
            <div
              className='description text-gray300 dark:text-white100
              mb-4 mt-1 flex text-[16px] font-light'
            >
              Select the image to view
            </div>
            <div id='img-slider' className='h-full overflow-y-auto'>
              <ul className='list-none space-y-2'>
                {Object.entries(keys).map(([firstKey, secondKeys]) =>
                  Object.entries(secondKeys)
                    // path 값이 이미지 파일 확장자를 포함하는지 필터링해서 이미지만 가져옴
                    .filter(([secondKey, path]) => secondKey.includes('img-'))
                    .map(([secondKey, path]) => (
                      <li
                        key={`${firstKey}-${secondKey}`}
                        data-key={`${firstKey}-${secondKey}`}
                        className={`cursor-pointer rounded-md px-3 py-1
                          text-[14px] transition-all duration-200 
                      ${
                        selectedSet === firstKey
                          ? selectedImgKey === secondKey
                            ? 'bg-purple200 text-white100 shadow-md'
                            : 'bg-purple100 text-white100 shadow-md'
                          : 'text-black100 hover:bg-purple100 hover:text-black100 dark:text-white100 ' +
                            'dark:hover:bg-purple100'
                      }`}
                        onClick={() => handleImgKeySelection(firstKey, secondKey, true)}
                      >
                        {firstKey}: {secondKey}
                      </li>
                    )),
                )}
              </ul>
            </div>
          </div>

          {/* 가운데: 탭 + OpenSeadragon */}
          <div
            style={{ width: `calc(100% - 245px - ${rightPanelWidth.toString()}px)` }}
            className='bg-white100 dark:bg-black200 mr-2 rounded-[7px] p-4
              text-[24px] shadow-lg transition-all duration-300'
          >
            <OSDTestViewer keys={osdKey} />
          </div>

          {/* 세로 핸들 */}
          <div
            style={{
              width: 5,
              margin: 5,
              backgroundColor: '#444',
              cursor: 'ew-resize',
            }}
            draggable
            onDragStart={(e) => {
              startDragPos = e.clientX;
            }}
            onDrag={(e) => {
              // if (e.clientX === 0) return;
              // const delta = e.clientX - startDragPos;
              // const newWidth = rightPanelWidth - delta;
              // setRightPanelWidth(newWidth);
            }}
            onDragEnd={(e) => {
              if (e.clientX === 0) return;

              const delta = e.clientX - startDragPos;
              const newWidth = rightPanelWidth - delta;
              setRightPanelWidth(newWidth);
            }}
          />

          {/* 오른쪽 Wrapper */}
          <div className='flex size-full flex-col' style={{ width: `${rightPanelWidth}px` }}>
            {/* 탭 영역 */}
            {Object.keys(keys).length > 0 && (
              <div id='text-slider' className='overflow-x-auto'>
                <div className='mb-2 flex w-fit items-center'>
                  {Object.entries(keys).map(([firstKey, secondKeys]) =>
                    Object.entries(secondKeys)
                      .filter(([secondKey, path]) => secondKey.includes('text-'))
                      .map(([secondKey, path]) => (
                        <button
                          key={`${firstKey}-${secondKey}`}
                          data-key={`${firstKey}-${secondKey}`}
                          className={`rounded-custom-lg z-[100] mr-2 h-[70px]
                            w-[145px] cursor-pointer border-b
                            px-4 py-1 text-[14px] transition-all
                            duration-200 hover:bg-purple-100
                            ${
                              selectedSet === firstKey
                                ? selectedTextKey === secondKey
                                  ? 'bg-purple200 text-white100 shadow-md'
                                  : 'bg-purple100 text-white100 shadow-md'
                                : 'bg-white100 dark:bg-black300 text-black100 hover:bg-purple100 hover:text-black100 dark:text-white100 ' +
                                  'dark:hover:bg-purple100'
                            }`}
                          onClick={() => handleTextKeySelection(firstKey, secondKey, true)}
                        >
                          {firstKey}: {secondKey}
                        </button>
                      )),
                  )}
                </div>
              </div>
            )}

            {/* Text 데이터 영역 */}
            <div
              style={{
                height: `${textPanelHeight.toString()}%`,
                // width: `${rightPanelWidth.toString()}px`,
                whiteSpace: 'pre-line',
                lineHeight: 1.6,
                fontSize: '1.2rem',
              }}
              className='bg-white100 text-black100 dark:bg-black200
                dark:text-white100 mb-4 w-full overflow-y-auto
                rounded-[7px] p-6 text-[24px] shadow-lg
                transition-all duration-300'
            >
              {textData ? (
                textData.split('\n').map((line, index) => (
                  <div key={index}>
                    <span style={{ marginRight: '0.5rem' }}>• </span>
                    {line}
                  </div>
                ))
              ) : (
                <div className='text-gray200 dark:text-gray200 text-[20px] italic'>
                  No text data available
                </div>
              )}
            </div>

            {/* 가로 핸들 */}
            <div
              style={{
                height: 5,
                margin: 5,
                backgroundColor: '#444',
                cursor: 'ns-resize',
              }}
              draggable
              onDragStart={(e) => {
                startDragPos = e.clientY;
              }}
              onDrag={(e) => {
                // if (e.clientX === 0) return;
                // const delta = e.clientX - startDragPos;
                // const newWidth = rightPanelWidth - delta;
                // setRightPanelWidth(newWidth);
              }}
              onDragEnd={(e) => {
                if (e.clientX === 0) return;

                const delta = e.clientY - startDragPos;
                const delta_percent = (delta / window.innerHeight) * 100;
                const newHeight = textPanelHeight + delta_percent;
                setTextPanelHeight(newHeight);
              }}
            />

            {/* 질문 영역 */}
            <div
              className='bg-white100 text-black300 dark:bg-black200
              dark:text-white100 w-full grow overflow-y-auto
              rounded-[7px] px-5 py-6 text-lg shadow-lg
            transition-all duration-300'
            >
              {answers.length > 0 ? (
                <>
                  <div className='mb-6 flex w-fit items-center'>
                    {answers.map((group: any, groupIndex: number) => (
                      <button
                        key={`${answers[groupIndex].listName}`}
                        className={`rounded-custom-lg z-[100] mr-2 h-[50px]
                          w-[145px] cursor-pointer border-b
                          px-4 py-1 text-[14px] transition-all
                          duration-200 hover:bg-purple-100
                          ${
                            selectedAnswer === answers[groupIndex].listName
                              ? 'bg-purple200 text-white100 shadow-md'
                              : 'bg-white100 dark:bg-black300 text-black100 hover:bg-purple100 hover:text-black100 dark:text-white100 ' +
                                'dark:hover:bg-purple100'
                          }`}
                        onClick={() => handleAnswerSelection(answers[groupIndex].listName)}
                      >
                        {answers[groupIndex].listName}
                      </button>
                    ))}
                  </div>

                  <form className='space-y-6'>
                    {answers.map(
                      (group: any, groupIndex: number) =>
                        answers[groupIndex].listName === selectedAnswer && (
                          <div key={groupIndex} className='mb-6 flex flex-col space-y-4 pb-4'>
                            <div className='flex items-center gap-2.5'>
                              <img
                                src={
                                  process.env.PUBLIC_URL + theme === 'dark'
                                    ? '/images/rete/node/icon/viewer.svg'
                                    : '/images/rete/node/icon/viewer-black.svg'
                                }
                                alt='Question List Icon'
                                className='size-6'
                              />
                              <div
                                className='maintitle text-black300 dark:text-white100 mb-2
                                  break-all text-[24px]
                                  font-bold tracking-[var(--spacingDefalt)]'
                              >
                                {'Form.  ' + group.listName}
                              </div>
                            </div>
                            {/* 그룹 내 질문 목록 */}
                            {group.inputs.map((item: any, index: number) => (
                              <div key={index} className='mb-6 flex flex-col'>
                                {/* 질문 */}
                                <label
                                  className='text-black100 dark:text-white100 mb-2 font-medium'
                                  style={{ whiteSpace: 'pre-line' }}
                                >
                                  {'Q.  ' + item.question}
                                </label>
                                {/* Text 타입 질문 */}
                                {item.type === 'text' ? (
                                  <input
                                    type='text'
                                    value={item.answer || ''}
                                    onChange={(e) =>
                                      handleAnswerChange(groupIndex, index, e.target.value)
                                    }
                                    className='border-gray200 dark:border-white100 dark:bg-black200
                                    dark:text-white100 rounded-lg border
                                      p-2 transition-all duration-200'
                                  />
                                ) : null}
                                {/* Choice 타입 질문 */}
                                {item.type === 'choice' ? (
                                  <select
                                    value={item.answer || ''}
                                    onChange={(e) =>
                                      handleAnswerChange(groupIndex, index, e.target.value)
                                    }
                                    className='border-gray200 dark:border-white100 dark:bg-black200
                                    dark:text-white100 rounded-lg border
                                      p-2 transition-all duration-200'
                                  >
                                    <option value='' disabled>
                                      Select option
                                    </option>
                                    {item.contents.map((choice: string, choiceIndex: number) => (
                                      <option key={choiceIndex} value={choice}>
                                        {choice}
                                      </option>
                                    ))}
                                  </select>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        ),
                    )}
                    <div
                      className='bg-purple200 flex h-16 w-full items-center justify-center rounded-[7px]
                        text-white hover:bg-[#4e2382]'
                      onClick={() => handleSubmitViewerMeataData(selectedAnswer)}
                    >
                      Submit
                    </div>
                  </form>
                </>
              ) : (
                <p className='text-gray200 dark:text-gray200 text-[20px] italic'>
                  No questions available
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

// OpenSeadragon Viewer 영역 -------------------------------------------
function OSDTestViewer(props) {
  const [viewer, setViewer] = useState<OSD.Viewer | null>(null);
  useEffect(() => {
    if (props.keys === '') return;
    if (viewer) {
      viewer.open(
        process.env.REACT_APP_VIENCE_API_KEY + `/viewer/osd_meta/${props.keys}/viewer_img.dzi`,
      );
      return;
    }

    const osdViewer = new OSD.Viewer({
      id: 'osd-viewer',
      prefixUrl: '/images/openseadragon/',
      tileSources:
        process.env.REACT_APP_VIENCE_API_KEY + `/viewer/osd_meta/${props.keys}/viewer_img.dzi`,
      crossOriginPolicy: 'Anonymous',
      animationTime: 0.5,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 50,
      minZoomLevel: 0.1,
      visibilityRatio: 1,
      zoomPerClick: 2,
      zoomPerScroll: 2,
      showNavigator: true,
      navigatorWidth: '250px',
      navigatorHeight: '250px',
      showFullPageControl: true,
      showHomeControl: true,
      showZoomControl: true,
      degrees: 0,
      showRotationControl: true,
    });

    setViewer(osdViewer);
  }, [viewer, props.keys]);

  return <div id='osd-viewer' />;
}
