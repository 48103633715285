export const tasksProcess = [
    { 
        tag : 'temp_process1',
        color: 'rgb(128, 0, 255)',
        pipe_name : 'pipe1',
        pipe_name_list: ['test.svs + test.xml', 'test.svs + test.xml', 'test.svs + test.xml'],
        id: 'unique0' 
    },
    { 
        tag : 'temp_process1',
        color: 'rgb(128, 0, 255)',
        pipe_name : 'pipe2',
        pipe_name_list: ['test.svs + test.xml'],
        id: 'unique0' 
    },
    { 
        tag : 'temp_process2',
        color: 'rgb(245, 66, 111)',
        pipe_name : 'pipe3',
        pipe_name_list: ['test.svs + test.xml', 'test.svs + test.xml', 'test.svs + test.xml'],
        id: 'unique0' 
    },
    { 
        tag : 'temp_process3',
        color: 'rgb(66, 245, 164)',
        pipe_name : 'pipe3',
        pipe_name_list: ['test.svs + test.xml', 'test.svs + test.xml', 'test.svs + test.xml'],
        id: 'unique0' 
    },
]