import React, { useCallback, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import AnnotationApp from './AnnotationApp';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { useAuthContext } from '@/hooks/common/useAuthContext';
import LoadingAnimation from '@/components/loadingAnimation/LoadingAnimation';
import TrainingAnimation from '../../Viewer/train-control/TrainingAnimation';
import { TrainContext } from '@/contexts/TrainContext';

const Container = styled.div`
  height: 100%;
  padding: var(--12px);

  div.btn-container {
    display: flex;
    gap: var(--8px);

    button.fine-tuner {
      margin-bottom: var(--4px);
      display: flex;
      justify-content: center;
      gap: var(--12px);
    }
  }
`;

export default function AnnotationDiv({ fns, selected, annotationData }) {
  const { handleAdd, setAnnotationData } = fns;
  const { user } = useAuthContext();
  const api = useAPIwithCookies();

  const { trainerControl } = useContext(TrainContext);
  const initOption = trainerControl?.props.option;

  const [loading, setLoading] = useState(false);
  const [correctionKey, setCorrectionKey] = useState(Date.now().toString());

  const [modelKey, setModelKey] = useState('');

  useEffect(() => {
    const t_model_path = `/cloud/member/${user.email}/MLOps/model/${initOption?.modelName}.py`;
    const t_weight = initOption?.selectedTrainedWeight;

    setModelKey(t_model_path + '+' + t_weight);
    setCorrectionKey(Date.now().toString());
  }, [selected]);

  const handleAnnotationSave = async (e) => {
    setLoading(true);
    try {
      const t_node_id = initOption?.nodeId;
      // var t_correction_key = Date.now().toString();
      const t_model_path = `/cloud/member/${user.email}/MLOps/model/${initOption?.modelName}.py`;
      const t_image_path = selected.path + '/' + selected.name;
      const t_weight = initOption?.selectedTrainedWeight;

      const data = {
        image_path: t_image_path,
        model_path: t_model_path,
        base_weight: t_weight,
        foreground: annotationData['foreground'],
        background: annotationData['background'],
      };

      const response = await api.post(
        '/mlops_finetuning/add_correction/' + t_node_id + '/' + correctionKey,
        data
      );

      await handleAdd();
      alert(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetAnnotation = async (e) => {
    setAnnotationData({ foreground: [], background: [] });
  };

  const handleGeneratePrediction = useCallback(
    async (e) => {
      setLoading(true);
      try {
        const t_model_path = `/cloud/member/${user.email}/MLOps/model/${initOption?.modelName}.py`;
        const t_image_path = selected.path + '/' + selected.name;
        const t_weight = initOption?.selectedTrainedWeight;

        const data = {
          image_path: t_image_path,
          model_path: t_model_path,
          base_weight: t_weight,
          foreground: annotationData['foreground'],
          background: annotationData['background'],
        };
        await api.post('/mlops_finetuning/inference', data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [initOption?.modelName, initOption?.selectedTrainedWeight]
  );

  useEffect(() => {
    const t_model_path = `/cloud/member/${user.email}/MLOps/model/${initOption?.modelName}.py`;
    const t_weight = initOption?.selectedTrainedWeight;

    setModelKey(t_model_path + '+' + t_weight);
  }, [initOption?.modelName, initOption?.selectedTrainedWeight]);

  return (
    <Container className='annotation'>
      <div className='annotation-workspace'>
        <AnnotationApp
          shapeList={annotationData}
          setShapeList={setAnnotationData}
          selected={selected}
          generatePrediction={handleGeneratePrediction}
          modelKey={modelKey}
        />
        {loading && <LoadingAnimation label={'loading...'} />}
      </div>
      <div className='btn-container'>
        <button
          type='button'
          className='fine-tuner'
          onClick={handleResetAnnotation}
        >
          Reset
        </button>
        <button
          type='button'
          className='fine-tuner'
          onClick={handleAnnotationSave}
          disabled={loading}
        >
          {loading && <TrainingAnimation />} <span>Save</span>
        </button>
      </div>
    </Container>
  );
}
