import React from "react";
import styled from "styled-components";

const PrimaryButton = styled.button`
  display: block;
  text-align: center;
  width: calc(100% - var(--40px));
  padding: var(--14px) var(--20px);
  margin: var(--20px);
  background: #8d59fc;
  border-radius: 10px;
  color: #fff;
  font-size: var(--16px);
  font-weight: 700;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    color: white;
  }
`;

export default PrimaryButton;
