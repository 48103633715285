/**
 * 문자열을 주어진 최대 길이로 자르고, 초과하는 경우 '...'을 추가하는 함수
 *
 * @param {string} text - 잘라야 할 원본 문자열
 * @param {number} maxLength - 문자열의 최대 길이
 * @returns {string} 잘린 문자열. 최대 길이를 초과하지 않으면 원본 문자열 그대로 반환
 */
export const truncateText = (text: string, maxLength: number): string => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};
