import React from 'react';
import styled from 'styled-components';

const Container = styled.li`
  button {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    position: relative;
    color: #ffffff;
    p {
      min-width: 100px;
    }
  }
`

type TipItemProps = {
  checked: boolean;
  icon?: any;
  text: string;
  handleClick?: any;
}

export default function TipItem({checked, icon, text, handleClick}: TipItemProps) {
  return (
    <Container>
      <button type='button' onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}>
        <div className="icon-checked" style={checked ? {} : {opacity: 0}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
            <path d="M9 1L3.5 6.5L1 4" stroke="#65646A" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        {icon}
        <p>{text}</p>
      </button>
    </Container>
  )
}
