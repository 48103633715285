import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Mode from '@/components/mode/Mode';
import { useLogout } from '@/hooks/common/useLogout';

function MobileHeader({ theme }) {
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const { logout } = useLogout();

  return (
    <div
      className='flex h-[72px]
        items-center justify-between bg-gray100 px-[20px] dark:bg-black100'
    >
      {/* Left Header */}
      <div className='flex items-center gap-2'>
        {/* Vience Canvas logo */}
        <h1 className='flex h-[72px] items-center justify-center'>
          <Link to='/vience-canvas'>
            <img
              src={
                process.env.PUBLIC_URL +
                (theme === 'dark'
                  ? '/canvas-logo/logo-light-small.png'
                  : '/canvas-logo/logo-small.png')
              }
              alt='vience'
              className='h-[36px] w-[135px] object-contain'
            />
          </Link>
        </h1>
      </div>

      {/* Right Header Icons */}
      <div className='flex items-center gap-2'>
        {/* Mode Switch */}
        <Mode />

        {/* User Dropdown (Help Center를 드롭다운 안으로 이동) */}
        <div className='relative'>
          <div
            className='flex cursor-pointer items-center gap-2'
            onClick={() => setShowUserDropdown((prev) => !prev)}
          >
            <img
              src={process.env.PUBLIC_URL + '/images/user.svg'}
              alt='User Icon'
              className='w-8'
            />
          </div>
          {showUserDropdown && (
            <ul
              className='absolute right-0 top-[40px] z-[999]
                w-[150px] cursor-pointer overflow-visible rounded-custom-xl
                p-[10px] text-[14px] text-black300 dark:text-white100'
            >
              <Link to='/vience-canvas/helpcenter'>
                <li
                  className='z-[999] rounded-t-custom-lg border-x
                    border-t border-gray200 bg-white100
                    p-[10px] hover:bg-gray100 dark:border-black100
                  dark:bg-black200 dark:hover:bg-gray300'
                >
                  Help Center
                </li>
              </Link>
              <Link to='/vience-canvas/mypage'>
                <li
                  className='z-[999] border-x border-gray200 bg-white100
                    p-[10px] hover:bg-gray100 dark:border-black100
                    dark:bg-black200 dark:hover:bg-gray300'
                >
                  My Page
                </li>
              </Link>
              <li
                className='z-[999] rounded-b-custom-lg border-x
                  border-b border-gray200
                  bg-white100 p-[10px] hover:bg-gray100 dark:border-black100
                  dark:bg-black200 dark:hover:bg-gray300'
                onClick={logout}
              >
                Logout
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
