import React, { useState } from 'react'
import { useGoogleLogin } from "@react-oauth/google";
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { useAuthContext } from './common/useAuthContext';

export default function useHandleGoogleLogin() {
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);
  
  const { dispatch } = useAuthContext();
  const api = useAPIwithCookies();

  const handleGoogleLogin = async (res: any) => {
    try {
        const response = await api.post('/member/google_login', { token: res.credential });
        
        const user = {
          email: response.data.email, 
          access: response.data.access_token
        };

        localStorage.setItem('access', response.data.access_token);
        localStorage.setItem('email', response.data.email);

        dispatch({ type: 'login', payload: user});
        setIsPending(false);
        
    } catch (errormsg) {
      setError(JSON.stringify(errormsg));
      setIsPending(false);
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      handleGoogleLogin(tokenResponse);
    },
    onError: () => { console.error(error); }
  });

  return { error, isPending, googleLogin, handleGoogleLogin }
}
