import { create } from 'zustand';
import { IfileNdirList } from '@/types/common/type';

interface ContextMenuState {
  contextOpen: boolean;
  contextEvent: any;
  contextTarget: IfileNdirList | undefined;
  setContext: (e: any, target: IfileNdirList | undefined) => void;
  setContextOpen: (b: boolean) => void;
}

const useDataHubMenuStore = create<ContextMenuState>((set) => ({
  contextOpen: false,
  contextEvent: undefined,
  contextTarget: undefined,
  setContext: (e, target) => set({ contextOpen: true, contextEvent: e, contextTarget: target }),
  setContextOpen: (b) => set({ contextOpen: b }),
}));

export default useDataHubMenuStore;
