import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import API from '@/apis/common/apis';
import './DeepLearningPanel.scss';
import '@/components/rete/components/nodes/viewer/panel/side-panel.styles.scss';
import { InputWithLabel } from '@/components/common/CustomInput';
import { ClassificationControl } from './Classification';
import { IModelList, IWeightList } from './deeplearning';

const dynamicInputs: {
  label: string;
  type: 'range' | 'number' | 'checkbox' | 'text';
  min?: number;
  max?: number;
  step?: number;
  key: string;
  [key: string]: any;
}[] = [
  { label: 'Device', type: 'number', key: 'device' },
  { label: 'Weight Path', type: 'text', key: 'weight_path' },
  { label: 'Class Names', type: 'text', key: 'class_names' },
];

export default function ClassificationPanel({ ctrl }: { ctrl: ClassificationControl }) {
  const [cookies, setCookie, removeCookie] = useCookies(['refresh']);
  const api = new API(cookies);
  const [modelList, setModelList] = useState<IModelList[]>([]);
  const [model, setModel] = useState<IModelList>();
  const [weightList, setWeightList] = useState<IWeightList[]>([]);
  const [weight, setWeight] = useState<IWeightList>();
  const [options, setOptions] = useState<{ [key: string]: any }>({});

  const consoleRef = useRef<HTMLDivElement>(null);
  const [newWeight, setNewWeight] = useState<string>('');

  const getModelList = async () => {
    const response = await api.get('/deep_learning/model_list/classification');
    setModelList(response.data);
  };

  const getWeightList = async () => {
    if (!model?.dmo_seq) return;
    const response = await api.get(`/deep_learning/weight_list/${model?.dmo_seq}`);
    setWeightList(response.data);
  };

  const getProgress = async () => {
    let count = 0;
    const interval: NodeJS.Timer = setInterval(() => {
      if (count <= 10) {
        if (consoleRef.current) {
          const log = document.createElement('p');
          log.style.margin = '0';
          if (count === 7 || count === 8) {
            log.style.color = 'red';
            log.textContent = `image${count} processing failed`;
          } else {
            log.style.color = 'black';
            log.textContent = `image${count} successfully processed`;
          }
          consoleRef.current.appendChild(log);
          consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
        }
        count++;
      } else return clearInterval(interval);
    }, 1500);
  };

  const handleWeightChange = (e) => {
    const selectedWeight = weightList.filter((el) => el.wei_name === e.target.value)[0];
    setWeight(selectedWeight);
    setOptions({ ...options, weight_path: selectedWeight?.path });
    ctrl.setWeight(selectedWeight.wei_name);
  };

  const handleModelChange = (e) => {
    const selectedModel = modelList.filter((el) => el.dmo_name === e.target.value)[0];
    setModel(selectedModel);
    ctrl.setModel(selectedModel.dmo_name);
  };

  useEffect(() => {
    getModelList();
  }, []);

  useEffect(() => {
    if (ctrl.model === '' || ctrl.model === undefined) setModel(modelList[0]);
    else {
      setModel(modelList.filter((el) => el.dmo_name === ctrl.model)[0]);
    }
  }, [modelList]);

  useEffect(() => {
    if (model) ctrl.setModel(model.dmo_name);
    getWeightList();
  }, [model]);

  useEffect(() => {
    if (ctrl.weight === '') setWeight(weightList[0]);
    else setWeight(weightList.filter((el) => el.wei_name === ctrl.weight)[0]);
  }, [weightList]);

  useEffect(() => {
    const optionObj: { [key: string]: any } = {};
    for (let i = 0; i < dynamicInputs.length; i++) {
      if (dynamicInputs[i].type === 'checkbox') {
        optionObj[dynamicInputs[i].key] = false;
      } else if (dynamicInputs[i].type === 'text')
        optionObj[dynamicInputs[i].key] = dynamicInputs[i].key;
      else optionObj[dynamicInputs[i].key] = 0;
    }
    setOptions(optionObj);
  }, []);

  const loadData = () => {
    if (ctrl.props.option.classification) {
      setOptions(ctrl.props.option.classification);
      const selectedWeight = weightList.filter(
        (el) => el.path === ctrl.props.option.classification.weight_path,
      );
    }
  };

  useEffect(() => {
    loadData();
  }, [ctrl.id]);

  useEffect(() => {
    ctrl.setValue({ classification: options });
  }, [options]);

  return (
    <>
      <div className='side-panel-normal'>
        <p>Classification</p>
        <div className='deeplearning-section'>
          <div className='custom-select'>
            <p>Model Structure</p>
            <select style={{ width: '100%' }} value={model?.dmo_name} onChange={handleModelChange}>
              {modelList.map((data) => (
                <option>{data.dmo_name}</option>
              ))}
            </select>
          </div>

          <div className='custom-select'>
            <p>Model Weight</p>
            <select
              style={{ width: '100%' }}
              value={weight?.wei_name}
              onChange={handleWeightChange}
            >
              {weightList.map((data) => (
                <option>{data.wei_name}</option>
              ))}
            </select>
          </div>

          <div className='deeplearning-input-container'>
            <p>Options</p>
            {dynamicInputs.map((data) => (
              <div className='deeplearning-input'>
                <label>{data.label}</label>
                <input
                  type={data.type}
                  value={options[data.key]}
                  onChange={(e) => {
                    if (data.type === 'checkbox') {
                      setOptions({ ...options, [data.key]: e.target.checked });
                    } else
                      setOptions({
                        ...options,
                        [data.key]: data.type === 'text' ? e.target.value : Number(e.target.value),
                      });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className='deeplearning-train-section'>
          <p>
            <b>Train</b> &#40;Generate Weight&#41;
          </p>
          <InputWithLabel
            label='New Weight Filename'
            value={newWeight}
            onChange={(e) => {
              setNewWeight(e);
            }}
          />
          <InputWithLabel
            label='Dataset Directory'
            onChange={(e) => {
              //
            }}
            defaultValue={'/'}
          />
          <div className='deeplearning-input-container'>
            <p>Options</p>
            {dynamicInputs.map((data) => (
              <div className='deeplearning-input'>
                <label>{data.label}</label>
                <input
                  type={data.type}
                  min={data.min}
                  max={data.max}
                  value={options[data.label]}
                  onChange={(e) => {
                    if (data.type === 'checkbox') {
                      setOptions({
                        ...options,
                        [data.label]: e.target.checked,
                      });
                    } else
                      setOptions({
                        ...options,
                        [data.label]: Number(e.target.value),
                      });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className='deeplearning-progress'>
          <div ref={consoleRef} className='deeplearning-console'></div>
          <button id='deeplearning-run-btn' onClick={getProgress}>
            run
          </button>
        </div>
      </div>
      <div className='side-panel-expand'></div>
    </>
  );
}
