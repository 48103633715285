import React, { useEffect, useState } from 'react'
import { DataManageControl } from './DataManagement';
import { useCookies } from 'react-cookie';
import API from '../../../../utils/api';
import { IfileNdirList, filedirName } from '../../../../routes/datamanage/storage/storage.type';
import File from './File';
import CanvasSearch from '../../../../components/canvasSearch/CanvasSearch';
import StorageCard from './StorageCard';
import Directory from './Directory';

export default function DataManagePanel({ ctrl }: { ctrl: DataManageControl }) {
const [cookies, setCookie] = useCookies(['refresh']);
const api = new API(cookies);

const [curDir, setCurDir] = useState<string>('');
const [fileNdirList_Normal, setFileNdirList_Normal] = useState<IfileNdirList[]>([]);
const [fileNdirList_Expand, setFileNdirList_Expand] = useState<IfileNdirList[]>([]);
const [selected, setSelected] = useState<{ path: filedirName, name: filedirName, thumbnail: string }>({ path: '', name: '', thumbnail: '' });

//operate once at start for normal
const getFileDirList = async () => {
    try {
        const response = await api.get('/cloud/list', { cur_dir: '' });
        setFileNdirList_Normal(response.data);
    } catch (error) {
        console.log(error)
    }
}

const getDirectory = async () => {
    try {
        const response = await api.get('/cloud/list', { cur_dir: curDir });
        setFileNdirList_Expand(response.data);
    } catch (error: any) {
    }
}

useEffect(() => {
    getDirectory();
}, [curDir])

useEffect(() => {
    getFileDirList();
}, [])

//node.id change => load saved data
useEffect(() => {
    const currentSelected = ctrl.props.option.path.split('/');
    if (currentSelected) {
        if (currentSelected.length === 1) setSelected({ path: '', name: currentSelected[0], thumbnail: ctrl.props.option.thumbnail });
        else {
            let path = '';
            for (let i = 0; i < currentSelected.length - 1; i++) {
                path += currentSelected[i];
            }
            const name = currentSelected[currentSelected.length - 1];
            setSelected({ path: path, name: name, thumbnail: ctrl.props.option.thumbnail });
        }
    }
}, [ctrl.id])

useEffect(() => {
    if (selected.name === '') return;
    if (selected.path === '') ctrl.setValue({ path: `${selected.name}`, thumbnail: selected.thumbnail });
    else ctrl.setValue({ path: `${selected.path}/${selected.name}`, thumbnail: selected.thumbnail });
}, [selected])

// 엑박
const handleImageError = () => {
    const thumbnailContainer = document.querySelector("#datamanage-thumbnail");
    thumbnailContainer?.setAttribute("src", `${process.env.PUBLIC_URL}/images/image-error.png`)
}

return (
    <>
        <div className='side-panel-normal'>
            <h3>Datamanage</h3>
            <div className="datamanage-section">
                <div id='datamanage-thumbnail-container'>
                    <img id='datamanage-thumbnail' src={`data:image/jpeg;base64,${selected.thumbnail}`} onError={handleImageError}/>
                </div>
                <div className="datamanage-file-name">
                    <img src={process.env.PUBLIC_URL + '/images/datamanage-icon-file.svg'} alt="" />
                    <span>{selected.name}</span>
                </div>
            </div>
            <div className="datamanage-section">
                <ul id='datamanage-filedirlist'>
                    {fileNdirList_Normal.map(data => {
                        if (data.type === 'dataset' || data.type === 'dir' || data.type === 'model') { return <Directory depth={0} name={data.name} path={''} setSelected={setSelected} /> }
                        else {
                            return <File thumbnail={data.thumbnail} path={''} depth={0} name={data.name} setSelected={setSelected} />
                        }
                    })}
                </ul>
            </div>
        </div >

        <div className='side-panel-expand'>
            <div className="inner">

                <div className="process-root">
                    <div onClick={() => setCurDir('')} className='dir-name'>
                        <span>My Drive</span>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5791 18.7734L15.5791 12.7734L9.5791 6.77344" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    </div>
                </div>

                <CanvasSearch />
                <div className="storage-card-frame">
                    {curDir !== '' &&
                        <StorageCard
                            selected={selected}
                            setSelected={setSelected}
                            info={{ name: '..', type: 'dir', is_dir: true, thumbnail: 'empty', updated_at: '', path: '' }}
                            curDir={curDir}
                            setCurDir={setCurDir}
                        />}

                    {
                        fileNdirList_Expand.map((data, index) =>
                            <StorageCard
                                selected={selected}
                                setSelected={setSelected}
                                curDir={curDir}
                                setCurDir={setCurDir}
                                info={data}
                            />)
                    }
                </div>

            </div>
        </div>
    </>
)
}