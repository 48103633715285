import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useAPIwithCookies } from '../hooks/useApiCookies';
import { uploadChunkFile } from '../routes/datamanage/storage/utils';

import {
  dirType,
  DragAreaPosition,
  filedirName,
  IfileNdirList,
  Position,
} from '../routes/datamanage/storage/storage.type';
import { Dict } from 'styled-components/dist/types';
import { getCloudList, postTaggingToDir } from '../routes/datamanage/apis/api';
import Swal from 'sweetalert2';

export const StorageContext = createContext<{
  handleUpload: (e: ChangeEvent<HTMLInputElement>, b?: boolean) => void;
  handleDrop: (f: FileList, b?: boolean) => void;
  dirLoading?: boolean;
  loading?: boolean;
  progress?: { filename: filedirName; progress: number };
  getDirectory: (trimmedPath?: any) => void;
  setCurDir: (a: dirType) => void;
  curDir: dirType;
  fileNdirList: IfileNdirList[];
  setEndIndex: (n: number | undefined) => void;
  setStartIndex: (n: number) => void;
  selected: filedirName[];
  setSelected: (f: filedirName[]) => void;
  selectedFile: { [key in string]: string };
  setSelectedFile: (e: {}) => void;
  setTarget: (s: string | undefined) => void;
  renameCurDirectory: MouseEventHandler<HTMLLIElement>;
  createDirectory: () => void;
  deleteDirectory: (a: string) => void;
  startProgressPolling: () => void;
  setSelectOpen: (a: boolean) => void;
  selectOpen: boolean;
  dragTag: { tagName: string; valid: boolean; color: string };
  setDragTag: Dispatch<
    SetStateAction<{
      tagName: string;
      key: string;
      valid: boolean;
      color: string;
    }>
  >;
  setPinnedIds: ([]) => void;
  pinnedIds: any;
  getPinnedDirectory: () => void;
  showProgress: boolean;
  setShowProgress: (a: boolean) => void;
}>({
  handleUpload: () => {},
  handleDrop: () => {},
  fileNdirList: [],
  curDir: '',
  setCurDir: () => {},
  setEndIndex: () => {},
  setStartIndex: () => {},
  getDirectory: (trimmedPath?: any) => {},
  selected: [''],
  setSelected: () => {},
  selectedFile: {},
  setSelectedFile: () => {},
  setTarget: () => {},
  renameCurDirectory: () => {},
  createDirectory: () => {},
  deleteDirectory: (a: string) => {},
  startProgressPolling: () => {},
  selectOpen: false,
  setSelectOpen: () => {},
  dragTag: { tagName: '', valid: false, color: '' },
  setDragTag: () => {},
  pinnedIds: [],
  setPinnedIds: () => {},
  getPinnedDirectory: () => {},
  showProgress: false,
  setShowProgress: () => {},
});

const StorageContextProvider = ({ children }: { children: ReactNode }) => {
  const api = useAPIwithCookies();
  //current directory
  const [curDir, setCurDir] = useState<dirType>('');
  const [loading, setLoading] = useState<boolean>(false);
  //upload progress
  const [progress, setProgress] = useState<{
    filename: filedirName;
    progress: number;
  }>({ filename: '', progress: 0 });
  //setDirectory loading
  const [dirLoading, setDirLoading] = useState(false);
  const [fileNdirList, setFileNdirList] = useState<IfileNdirList[]>([]);
  //multiple select by shift
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number | undefined>(-1);
  //selected
  const [selected, setSelected] = useState<filedirName[]>([]);
  const [selectedFile, setSelectedFile] = useState({});
  //destination directory
  const [target, setTarget] = useState<filedirName | undefined>(undefined);

  //경로 메뉴 상태 관리
  const [selectOpen, setSelectOpen] = useState<boolean>(false);

  //패널에서 현재 드래그 중인 태그
  const [dragTag, setDragTag] = useState<{
    tagName: string;
    key: string;
    valid: boolean;
    color: string;
  }>({ tagName: '', key: '', valid: false, color: '' });

  //패널의 pipes의 핀이 된 태그
  const [pinnedIds, setPinnedIds] = useState<any>([]);

  //패널의 pipes의 핀이 된 태그
  const [showProgress, setShowProgress] = useState<boolean>(false);

  //multiple select by shift
  useEffect(() => {
    if (endIndex !== undefined) {
      if (startIndex < endIndex)
        setSelected([
          ...fileNdirList.slice(startIndex, endIndex + 1).map((data) => {
            return data.name;
          }),
        ]);
      else
        setSelected([
          ...fileNdirList.slice(endIndex, startIndex + 1).map((data) => {
            return data.name;
          }),
        ]);
    }
  }, [endIndex]);

  //디렉토리 조회하는 함수 => 임시로 MOCK으로 설정
  const getDirectory = async (trimmedPath?: any) => {
    setDirLoading(true);
    console.log('보내기전 query string의 값', curDir);
    // const response = await getCloudList(curDir)

    if (trimmedPath === undefined) {
      const response = await api.get('/cloud/list_with_tag_info', {
        email: '',
        mem_id: '',
        cur_dir: curDir,
        names: [],
        change: '',
        arrange: '',
      });
  
      const data = response.data;
      setFileNdirList(response.data);
      setDirLoading(false);
      
      return;
    }
    const response = await api.get('/cloud/list_with_tag_info', {
      email: '',
      mem_id: '',
      cur_dir: trimmedPath,
      names: [],
      change: '',
      arrange: '',
    });
    console.log('지금 보는 api', response.data);
    setFileNdirList(response.data);
    console.log('불러온 파일 리스트 데이터 입니다.', response.data);
    setDirLoading(false);

    return response.data;
  };

  //패널의 pipes에서 핀 된 값을 조회하는 함수
  const getPinnedDirectory = async () => {
    console.log('핀 된 값 조회 함수 들어옴');
    // setDirLoading(true);
    // const response = await api.get('/cloud/list', { cur_dir: curDir });
    // setFileNdirList(response.data);
    // setDirLoading(false);
  };

  const [currentFilename, setCurrentFilename] = useState('');
  const [totalFileLen, setTotalFileLen] = useState(0);
  const [processedFileLen, setProcessedFileLen] = useState(0);
  const [progressList, setProgressList] = useState<Dict>({});

  const handleUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    isSmc = false
  ) => {
    setLoading(true);
    if (e.target.files) {
      setTotalFileLen(e.target.files.length);
      setProcessedFileLen(0);
      var new_list = {};
      for (let file of e.target.files) {
        new_list[file.name] = 0;
      }
      setProgressList(new_list);
      for (let file of e.target.files) {
        const promises: Promise<any>[] = [];
        uploadChunkFile(
          file,
          curDir,
          promises,
          api,
          (res) => {
            setProgress(res.data);
          },
          isSmc
        );
        try {
          await Promise.all(promises);
          if (isSmc) {
            api
              .post(`/smc/run_processing/${curDir}/${file.name}`)
              .then((res) => {
                setProcessedFileLen((prev) => prev + 1);
              });
            setProgressList({ ...progressList, [file.name]: 0 });
            setCurrentFilename(file.name);
          }
        } catch (err) {
          console.log(file.name, err);
        }
      }
    }
    if (isSmc) return;
    await getDirectory();
    setLoading(false);
  };

  const handleDrop = async (files, isSmc = false) => {
    setLoading(true);
    setTotalFileLen(files.length);
    setProcessedFileLen(0);
    var new_list = {};
    for (let file of files) {
      new_list[file.name] = 0;
    }
    setProgressList(new_list);
    for (let file of files) {
      const promises: Promise<any>[] = [];
      uploadChunkFile(
        file,
        curDir,
        promises,
        api,
        (res) => {
          setProgress(res.data);
        },
        isSmc
      );
      try {
        await Promise.all(promises);
        if (isSmc) {
          api.post(`/smc/run_processing/${curDir}/${file.name}`).then((res) => {
            setProcessedFileLen((prev) => prev + 1);
          });
          setCurrentFilename(file.name);
        }
      } catch (err) {
        console.log(file.name, err);
      }
    }
    if (isSmc) return;
    await getDirectory();
    setLoading(false);
  };

  useEffect(() => {
    console.log(loading);
    console.log(!!currentFilename);
    if (loading && currentFilename) {
      console.log('start get progress');
      startProgressPolling();
    }
  }, [loading, currentFilename]);

  const startProgressPolling = async () => {
    try {
      const response = await api.get(
        `/smc/progress/${curDir}/${currentFilename}`
      );
      console.log(response.data);

      if (response.data >= 99) {
      } else {
        setLoading(true);
        progressList[currentFilename] = response.data;

        setProgress({
          filename: 'Processing: ' + Object.keys(progressList).filter(key => progressList[key]<99).join(' / '),
          progress: (Object.values(progressList).reduce((acc, value) => acc + value, 0))/totalFileLen
        });
        setTimeout(() => {
          startProgressPolling();
        }, 1000);
      }
    } catch (err) {
      console.error('Error fetching progress:', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (totalFileLen === processedFileLen) {
      setProgress({ filename: '', progress: 0 });
      setLoading(false);
      setCurrentFilename('');
      getDirectory();
    }
  }, [processedFileLen]);

  //select item by drag area
  const gridAreaRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [positionS, setPositionS] = useState<Position | undefined>(undefined);
  const [dragAreaPos, setDragAreaPos] = useState<DragAreaPosition>();

  //drag start set mouse start position
  const handleDragStart: MouseEventHandler = (e) => {
    const gridDOM_pos = gridAreaRef.current?.getBoundingClientRect();
    const scrollTop = gridAreaRef.current?.scrollTop;
    if (!gridDOM_pos) return;
    if (scrollTop === undefined) return;
    setDragging(true);
    setPositionS({
      top: e.clientY - gridDOM_pos.y,
      left: e.clientX - gridDOM_pos.x,
    });
  };

  //drag end get selected item by drag area
  const handleDragEnd: MouseEventHandler = () => {
    if (dragging) {
      setDragging(false);
      let dragAreaSelected: string[] = [];
      for (let item of fileNdirList) {
        const dom = document.getElementById(item.name);
        if (dom) {
          const [left, top, height, width] = [
            dom.offsetLeft,
            dom.offsetTop,
            dom.offsetHeight,
            dom.offsetWidth,
          ];
          const centerPos: Position = {
            top: top + height / 2,
            left: left + width / 2,
          };
          if (dragAreaPos) {
            if (
              dragAreaPos.left < centerPos.left &&
              dragAreaPos.left + dragAreaPos.width > centerPos.left &&
              dragAreaPos.top < centerPos.top &&
              dragAreaPos.top + dragAreaPos.height > centerPos.top
            )
              dragAreaSelected.push(item.name);
          }
        }
      }
      setSelected(dragAreaSelected);
      setPositionS(undefined);
      setDragAreaPos(undefined);
    }
  };

  // dragging set drag area
  const handleDragging: MouseEventHandler = (e) => {
    const gridDOM_pos = gridAreaRef.current?.getBoundingClientRect();
    const scrollTop = gridAreaRef.current?.scrollTop;
    if (!gridDOM_pos) return;
    if (scrollTop === undefined) return;
    if (dragging && positionS) {
      //Mouse absolute position
      const { clientX, clientY } = e;
      //Mouse relative position to card list
      const [mouseTop, mouseLeft] = [
        clientY - gridDOM_pos.y,
        clientX - gridDOM_pos.x,
      ];
      const top = Math.min(mouseTop, positionS.top);
      const left = Math.min(mouseLeft, positionS.left);
      const height = Math.abs(mouseTop - positionS.top);
      const width = Math.abs(mouseLeft - positionS.left);
      setDragAreaPos({
        top: top + scrollTop,
        left: left,
        height: height,
        width: width,
      });
    }
  };

  const editDirectory = async () => {
    if (target === undefined) return;
    console.log('드래그 중인 정보', dragTag);
    //taggingDriectory
    if (dragTag.valid) {
      console.log('태그 드래그 들어옴 그리고 타겟', dragTag.key, target);
      Swal.fire({
        icon: 'success',
        title: 'The tag name is connected to the target',
        showConfirmButton: false,
        timer: 1500,
      });
      try {
        await api.post('/tag/tagging', {
          tag_key: dragTag.key,
          target: target,
        });
        // const response = await postTaggingToDir(
        //   dragTag.tagName,
        //   target,
        //   dragTag.color
        // );

        getDirectory();
      } catch (error: any) {}
      return;
    }

    //changeDirectory
    if (!dragTag.valid) {
      try {
        await api.post('/cloud/move', {
          cur_dir: curDir,
          names: selected,
          change: target,
        });
      } catch (error: any) {
        if (error.detail === 'Conflict detected') {
          Swal.fire({
            icon: 'success',
            title: 'The name already exists',
            showConfirmButton: true,
            timer: 1500,
          });
        }
      } finally {
        getDirectory();
      }
    }
  };

  const renameCurDirectory = async () => {
    const newName = prompt(target);
    const { path, dir } = splitPath(curDir);
    try {
      await api.post('/cloud/rename', {
        cur_dir: path,
        names: [dir],
        change: newName,
      });
    } catch (error: any) {
      if (error.detail === 'Conflict detected') {
        Swal.fire({
          icon: 'success',
          title: 'The name already exists',
          showConfirmButton: true,
          timer: 1500,
        });
      }
    } finally {
      await getDirectory();
      setCurDir(`${path}/${newName}`);
    }
  };

  const splitPath = (targetPath: string) => {
    const dirs = targetPath.split('/');
    let path = '';
    for (let i = 0; i < dirs.length - 1; i++) {
      path += `${i === 0 ? '' : '/'}${dirs[i]}`;
    }
    return { path: path, dir: dirs[dirs.length - 1] };
  };

  const createDirectory = async () => {
    const name = prompt('Set Directory Name');
    console.log('디렉토리 생성', curDir);
    try {
      console.log('디렉토리 생성', curDir);
      if (curDir[0] === '/') {
        await api.post('/cloud/make_dir', {
          cur_dir: curDir.slice(1),
          names: [name],
        });
      } else
        await api.post('/cloud/make_dir', { cur_dir: curDir, names: [name] });
    } catch (error: any) {
      if (error.detail === 'Conflict detected') {
        Swal.fire({
          icon: 'success',
          title: 'The name already exists',
          showConfirmButton: true,
          timer: 1500,
        });
      }
    } finally {
      getDirectory();
    }
  };

  const deleteDirectory = async (selectedDirectoryName) => {
    if (!window.confirm('delete?')) return;
    try {
      await api.post('/cloud/delete', {
        cur_dir: '',
        names: [selectedDirectoryName],
      });
    } catch (err) {
      console.log(err);
    } finally {
      getDirectory();
    }
  };

  return (
    <div
      ref={gridAreaRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDragging}
      onDragEnd={editDirectory}
      onMouseLeave={() => {
        setDragging(false);
        setPositionS(undefined);
        setDragAreaPos(undefined);
      }}
    >
      <StorageContext.Provider
        value={{
          // 경로 관련
          curDir,
          setCurDir,
          progress,
          loading,
          dirLoading,
          fileNdirList,
          getDirectory,
          // 업로드 관련
          handleUpload,
          handleDrop,
          // 선택 관련
          setEndIndex,
          setStartIndex,
          selected,
          setSelected,
          selectedFile,
          setSelectedFile,
          setTarget,
          // storage 작업 api
          renameCurDirectory,
          createDirectory,
          deleteDirectory,
          // smc upload
          startProgressPolling,

          selectOpen,
          setSelectOpen,

          dragTag,
          setDragTag,

          pinnedIds,
          setPinnedIds,
          getPinnedDirectory,
          showProgress,
          setShowProgress,
        }}
      >
        {children}
      </StorageContext.Provider>
      <div
        className='drag-area'
        style={{
          width: dragAreaPos?.width,
          height: dragAreaPos?.height,
          position: 'absolute',
          top: dragAreaPos?.top,
          left: dragAreaPos?.left,
        }}
      />
    </div>
  );
};

export default StorageContextProvider;
