import React from 'react';
import { styled } from 'styled-components';
import CloseIcon from '@rsuite/icons/Close';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { handleImgError } from '@/utils/common/imageUtils';

const Container = styled.div`
  width: 100%;
  padding: var(--12px);

  ul.corrected-img-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: var(--12px);
    row-gap: var(--12px);

    width: 100%;

    li.corrected-img-item {
      width: 48%;
      padding: var(--12px);

      display: flex;
      flex-direction: column;
      position: relative;
      gap: var(--4px);

      .item-header,
      .item-images {
        h6 {
          width: 100%;
        }
        display: flex;
        align-items: center;
        gap: var(--12px);
      }

      .item-images {
        width: 100%;
      }

      img {
        width: 48%;
        aspect-ratio: 1/1;
        object-fit: contain;
        border-radius: var(--4px);
      }

      button {
        flex-shrink: 0;
        width: var(--32px);
        height: var(--32px);

        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid red;
        border-radius: var(--4px);
        color: red;

        &:hover {
          background-color: red;
          color: white;
        }

        &:active {
          box-shadow: inset 0 0 8px #510000;
          background-color: #960000;
          color: #b8b8b8;
          border: 1px solid #960000;
        }
      }
    }
  }
`;

type CorrectedImgListProps = {
  imgData: string[][];
  handleAdd: () => void;
  handleChangeSelected: (a: string) => void;
};

export default function CorrectedImgList({
  imgData,
  handleAdd,
  handleChangeSelected,
}: CorrectedImgListProps) {
  const t_api = useAPIwithCookies();

  const handleDelete = async (label) => {
    const response = await t_api.post(
      '/mlops_finetuning/remove_correction/' + label
    );
    alert(response.data);
    handleAdd();
  };

  return (
    <Container className='corrected-img-list-container'>
      <h5 className='a11y-hidden'>corrected image list</h5>
      <ul className='corrected-img-list'>
        {imgData.map(([first, second]) => (
          <li
            className='corrected-img-item'
            onClick={() => handleChangeSelected(first[0])}
          >
            <div className='item-header'>
              <h6 className='item-title'>{first[0]}</h6>
              <button type='button' onClick={() => handleDelete(first[0])}>
                <CloseIcon />
              </button>
            </div>
            <div className='item-images'>
              <img
                src={`data:image/jpeg;base64,${first[1]}`}
                alt='initial-result'
                onError={handleImgError}
              />
              <img
                src={`data:image/jpeg;base64,${second[1]}`}
                alt='corrected-result'
                onError={handleImgError}
              />
            </div>
          </li>
        ))}
      </ul>
    </Container>
  );
}
