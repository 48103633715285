import { IfileNdirList } from '@/types/common/type';

export const DEFAULT_DIR_INFO: IfileNdirList = {
  abs_path: '',
  name: '..',
  type: 'dir',
  is_dir: true,
  thumbnail: 'empty',
  updated_at: '',
  path: '',
  tagging_progress: undefined,
  file_progress: undefined,
  map: () => {
    return null;
  },
};
