import React, { useContext } from 'react';
import TipItem from './TipItem';
import { styled } from 'styled-components';
import { DynamicTrue2 } from '../../icons/DynamicTrue2';
import { DynamicFalse2 } from '../../icons/DynamicFalse2';
import { CustomThemeContext } from '@/contexts/common/Context';

const Container = styled.div<{ $theme: 'light' | 'dark' }>`
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  .tool-list,
  .div.tail {
    background-color: ${({ $theme }) =>
      $theme === 'light' ? '#E9EBF8' : '#080821'};
  }

  .tool-list {
    border-radius: 10px;
    padding: 12px 28px 12px 12px !important;

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--8px);
    flex-direction: column;
    box-shadow: var(--4px) var(--4px) var(--8px) #00000012;
  }

  .div.tail {
    width: 19px;
    height: 19px;

    position: absolute;
    top: -0.5em;
    left: calc(50% - 0.5em);

    transform: rotate(45deg);
  }
`;

export default function ToolTip({
  property,
  handleClick,
  checkedItem,
  setIsTipHovering,
  items,
}) {
  const { theme } = useContext(CustomThemeContext);
  return (
    <Container
      $theme={theme}
      className='tooltip'
      onMouseEnter={() => setIsTipHovering(true)}
      onMouseLeave={() => setIsTipHovering(false)}
    >
      <ul className='tool-list'>
        {<TipItem checked={false} text={property} handleClick={handleClick} />}
        {property.includes('dynamic') && (
          <>
            <TipItem
              checked={checkedItem === 'true' ? true : false}
              icon={<DynamicTrue2 className={'1'} />}
              text={'dynamic nodes'}
              handleClick={handleClick}
            />
            <TipItem
              checked={checkedItem === 'false' ? true : false}
              icon={<DynamicFalse2 className={'1'} />}
              text={'static nodes'}
              handleClick={handleClick}
            />
          </>
        )}
        {items &&
          items.map((v) => {
            return (
              <TipItem
                checked={false}
                text={v.id}
                handleClick={(e) => {
                  handleClick(e, [v]);
                }}
              />
            );
          })}
      </ul>
      <div className='div tail' />
    </Container>
  );
}
