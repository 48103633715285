import React, { useState, useEffect, useContext } from 'react';
// import { useParams } from 'react-router-dom';
// import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

import { ViewerControl } from '../../ViewerNode';
import Type1ViewerPanel from './type1';
import Type2ViewerPanel from './type2';

// import { NodeContext } from '@/components/rete/NodeContext';
import { CustomThemeContext } from '@/contexts/common/Context';
import renderTree from '@/components/rete/utils/renderTree';
import topNode from '@/components/rete/utils/topNode';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { useParams } from 'react-router-dom';

export default function VmetaViewerPanel({
  ctrl,
  viewerKey,
  handleOpenPanel,
  loading
}: {
  ctrl: ViewerControl;
  viewerKey: string;
  handleOpenPanel: any;
  loading: boolean;
}) {
  const [viewerType, setViewerType] = useState(1);

  const { theme } = useContext(CustomThemeContext);
  const api = useAPIwithCookies();
  const params = useParams();

  const [inputThumbnail, setInputThumbnail] = useState('');


  const getThumbnail = () => {
    if(loading){
      setInputThumbnail(`/images/rete/node/icon/loading.png`);
    }
    else{
      setInputThumbnail(`/images/datahub/vmeta.svg`);
    }
  };

  useEffect(() => {
    getThumbnail();
  }, [viewerKey, loading]);


  return (
    <>
      <div
        className='mb-[var(--70px)] h-[calc(100vh-var(--72px)-55px-var(--72px)-50px-20px)]
          w-full overflow-y-auto p-[var(--20px)]'
      >
        <div className='flex items-center gap-2.5'>
          <img
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/rete/node/icon/viewer.svg'
                : '/images/rete/node/icon/viewer-black.svg'
            }
            alt='Viewer Icon'
            className='size-6'
          />
          <div
            className='maintitle break-all text-[24px]
              font-bold tracking-[var(--spacingDefalt)] text-black dark:text-white'
          >
            Viewer
          </div>
        </div>
        <div
          className='description mt-1 flex
            text-[16px] font-light text-gray300 dark:text-white100'
        >
          Click below to open the full viewer
        </div>
        <div
          className='subtitle mt-[var(--30px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          Check Output
        </div>
        <div
          className='mt-[var(--12px)] flex aspect-square 
            w-full cursor-pointer items-center
            justify-center rounded-lg hover:border hover:border-purple200'
          onClick={() => handleOpenPanel()}
        >
          <img
            src={inputThumbnail}
            className='size-full rounded-lg 
              bg-white100 object-contain dark:bg-black300'
            alt='Input thumbnail'
          />
        </div>
        <div
          className='subtitle mt-[var(--30px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          Panel Type
          <div className='flex gap-4'>
            <button
              className='mt-[var(--12px)] h-11 w-1/2 cursor-pointer rounded-lg bg-purple200
                px-1 text-[14px] text-white hover:text-white hover:brightness-75'
              onClick={() => {
                setViewerType(1), handleOpenPanel();
              }}
            >
              Report
            </button>
            <button
              className='mt-[var(--12px)] h-11 w-1/2 cursor-pointer rounded-lg bg-purple200
                px-1 text-[14px] text-white hover:text-white hover:brightness-75'
              onClick={() => {
                setViewerType(2), handleOpenPanel();
              }}
            >
              Model Comparison
            </button>
          </div>
        </div>

        <div
          className='subtitle mt-[var(--30px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          Module Info
        </div>
        <button
          className='mt-[var(--12px)] h-11
          w-full cursor-pointer rounded-lg bg-purple200
            text-[14px] text-white100 hover:text-white100 hover:brightness-75'
          onClick={() => {
            setViewerType(() => 5);
            handleOpenPanel();
          }}
        >
          Check Module Info
        </button>
      </div>
      <div
        className='grid-area-expand flex w-full
          flex-col rounded-md bg-gray100 dark:bg-black100'
      >
        {viewerType === 1 && <Type1ViewerPanel ctrl={ctrl} viewerKey={viewerKey} />}
        {viewerType === 2 && <Type2ViewerPanel ctrl={ctrl} viewerKey={viewerKey} />}
        {viewerType === 5 &&
          (ctrl.props.option?.effector ? (
            <>
              {topNode(ctrl.props.option.effector)}
              {Array.isArray(ctrl.props.option.effector.input) &&
                ctrl.props.option.effector.input.map((inputNode, idx) => (
                  <React.Fragment key={idx}>
                    <div>{renderTree(inputNode)}</div>
                  </React.Fragment>
                ))}
            </>
          ) : (
            <div></div>
          ))}
      </div>
    </>
  );
}
