import React, { useContext, useEffect, useState } from 'react';
import { editor } from '@/components/rete/rete';

import { DataSelectorNode } from '../components/nodes/data-selector';
import DataSelectorPanel from '../components/nodes/data-selector/panel';

import { DatasetConfigurationNode } from '../components/nodes/custom/DatasetConfiguration';
import { DatasetConfigurationPanel } from '../components/panels/DatasetConfigurationPanel';

import { PatchGenerationNode } from '../components/nodes/patch-generation';
import { PatchGenerationPanel } from '../components/nodes/patch-generation/panel';

import { CustomProcessingNode } from '../components/nodes/custom/CustomProcessing';
import { CustomProcessingPanel } from '../components/panels/CustomProcessingPanel';

import { ImageCropPanel, ImageCropNode } from '../components/nodes/effector/ImageCrop';
import { EdgeDetectNode, EdgeDetectPanel } from '../components/nodes/effector/EdgeDectection';
import { BlenderNode, BlenderPanel } from '../components/nodes/effector/Blender';

import DeepLearningPanel from '../components/nodes/effector/deeplearning/DeepLearningPanel';
import ClassificationPanel from '../components/nodes/effector/deeplearning/ClassificationPanel';

import { GearNode } from '../components/nodes/addon/Gear';
import { GearPanel } from '../components/nodes/addon/GearPanel';
import { FeatureNode, FeaturePanel } from '../components/nodes/addon/Feature';
import { FormGeneraterNode } from '../components/nodes/addon/FormGenerator';
import { FormGeneraterPanel } from '../components/panels/FormGeneraterPanel';

import { ViewerNode } from '../components/nodes/viewer/ViewerNode';
import CommonViewerPanel from '../components/nodes/viewer/panel';
import { ChartNode, ChartPanel } from '../components/nodes/viewer/chart';

import { NodeContext } from '@/components/rete/NodeContext';
import { CustomThemeContext } from '@/contexts/common/Context';

import { TPanelMode } from '@/types/common/type';
import { monacoState } from '../components/style/CustomNode';
import { tabState } from '../components/style/CustomNode';

import './side-panel.styles.scss';
import { ImageProcessingNode } from '../components/nodes/custom/ImageProcessing';
import { ImageProcessingPanel } from '../components/panels/ImageProcessingPanel';
import '@/components/rete/components/nodes/viewer/panel/side-panel.styles.scss';

/**
 * * Side Panel 전체 컴포넌트
 * * ViewerNode의 경우 normal/full 모드를 지원
 */

export default function SidePanel() {
  const { theme } = useContext(CustomThemeContext);
  const { nodeId, setExpandDetection } = useContext(NodeContext);

  // 기존에는 'normal' | 'expand' 였으나, ViewerNode 전용으로 'full' 모드를 추가
  const [panelMode, setPanelMode] = useState<TPanelMode>('normal');
  const [translate, setTranslate] = useState<string>('translateX(calc(100% - 24rem))');

  // 패널 선택 FOCUS
  const { isFocused, setIsFocused } = useContext(NodeContext);

  // 현재 선택된 노드
  const [nodeType, setNodeType] = useState<any>();
  useEffect(() => {
    if (editor && nodeId) {
      const pickedNode = editor.getNode(nodeId);
      setNodeType(pickedNode);
    }
    setPanelMode('normal');
  }, [nodeId]);

  const isViewerNode = nodeType instanceof ViewerNode;
  const isDarkTheme = theme === 'dark';

  // Arrow 버튼 클릭 시: ViewerNode는 normal↔full, 일반 노드는 normal↔expand
  const handleArrowClick = () => {
    if (isViewerNode) {
      if (panelMode === 'normal') {
        setPanelMode('full');
        setExpandDetection?.(true);
      } else if (panelMode === 'full') {
        setPanelMode('normal');
        setExpandDetection?.(false);
      }
    } else {
      if (panelMode === 'normal') {
        setPanelMode('expand');
        setExpandDetection?.(true);
      } else if (panelMode === 'expand') {
        setPanelMode('normal');
        setExpandDetection?.(false);
      }
    }
  };

  // panel에서 마우스 진입/이탈 시 monacoState 조정
  const keyEventStateValid = () => {
    monacoState.isEnabled = true;
  };
  const keyEventStateInValid = () => {
    monacoState.isEnabled = false;
  };

  const handleChangeTabState = () => {
    setIsFocused(true);
  };

  // panelMode 변경에 따른 Tab 키로 열고 닫기
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code !== 'Tab') return;
      const target = e.target as HTMLElement | null;
      // patch generation panel 안의 input이라면, tab으로 패널을 열고/닫는 로직은 무시
      if (target && target.classList.contains('side-panel-normal-code-input')) {
        return;
      }
      e.preventDefault();
      handleArrowClick();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [panelMode, isViewerNode, isFocused]);

  // panelMode 변경에 따른 위치 변환 (minimap 이동 등)
  useEffect(() => {
    const minimapDOM = document.getElementById('minimap');
    if (isViewerNode) {
      switch (panelMode) {
        case 'normal':
          minimapDOM?.style.setProperty('transform', 'translateX(-175%)');
          setTranslate('translateX(calc(100% - 24rem))');
          break;
        case 'full':
          minimapDOM?.style.setProperty('transform', 'none');
          setTranslate('translateX(0)');
          break;
      }
    } else {
      switch (panelMode) {
        case 'normal':
          minimapDOM?.style.setProperty('transform', 'translateX(-175%)');
          setTranslate('translateX(calc(100% - 24rem))');
          break;
        case 'expand':
          minimapDOM?.style.setProperty('transform', 'none');
          setTranslate('none');
          break;
      }
    }
  }, [panelMode, isViewerNode]);

  // 아이콘 경로 헬퍼 함수
  function getNormalIconPath(isActive: boolean, isDark: boolean) {
    if (isActive) {
      return isDark
        ? '/images/panel-icon-middle-active.svg'
        : '/images/panel-icon-middle-white-active.svg';
    } else {
      return isDark ? '/images/panel-icon-middle.svg' : '/images/panel-icon-middle-white.svg';
    }
  }
  function getExpandIconPath(isActive: boolean, isDark: boolean) {
    if (isActive) {
      return isDark
        ? '/images/panel-icon-max-active.svg'
        : '/images/panel-icon-max-white-active.svg';
    } else {
      return isDark ? '/images/panel-icon-max.svg' : '/images/panel-icon-max-white.svg';
    }
  }
  function getFullIconPath(isActive: boolean, isDark: boolean) {
    if (isActive) {
      return isDark
        ? '/images/panel-icon-max-active.svg'
        : '/images/panel-icon-max-white-active.svg';
    } else {
      return isDark ? '/images/panel-icon-max.svg' : '/images/panel-icon-max-white.svg';
    }
  }

  return (
    <div
      className={`side-panel-box ${panelMode === 'full' ? 'full-panel' : ''}`}
      style={{ transform: translate }}
      onMouseEnter={keyEventStateValid}
      onMouseLeave={keyEventStateInValid}
      onClick={handleChangeTabState} // 클릭 시 패널이 포커스됨
    >
      {/* Arrow 버튼 */}
      <span className='side-panel-arrow'>
        <img
          src={
            theme === 'dark'
              ? '/images/canvas-panel-arrow.svg'
              : '/images/canvas-panel-arrow-white.svg'
          }
          alt='If click this arrow, the panel will be expanded or shrinked'
          onClick={handleArrowClick}
          className='hover:cursor-pointer'
          style={{
            transform:
              (isViewerNode && panelMode === 'full') || (!isViewerNode && panelMode === 'expand')
                ? 'rotate(180deg)'
                : '',
            filter: 'invert(0.2) sepia(1) saturate(5) hue-rotate(240deg)',
          }}
        />
      </span>

      <div className='side-panel' style={{ width: panelMode === 'expand' ? '100%' : '100%' }}>
        {/* 상단 헤더 */}
        <div
          className='side-panel-header'
          style={{ width: panelMode === 'expand' || panelMode === 'full' ? '100%' : '350px' }}
        >
          <p className='panel-title-comm'>Panel size</p>
          <div>
            {isViewerNode ? (
              <>
                {/* viewer node: normal / full */}
                <button onClick={() => setPanelMode('normal')}>
                  <img
                    src={getNormalIconPath(panelMode === 'normal', isDarkTheme)}
                    alt='viewer normal mode icon'
                  />
                </button>
                <button
                  onClick={() => {
                    setPanelMode('full');
                    setExpandDetection?.(false);
                  }}
                >
                  <img
                    src={getFullIconPath(panelMode === 'full', isDarkTheme)}
                    alt='viewer full mode icon'
                  />
                </button>
              </>
            ) : (
              <>
                {/* 일반 노드: normal / expand */}
                <button
                  onClick={() => {
                    setPanelMode('expand');
                    setExpandDetection?.(true);
                  }}
                >
                  <img
                    src={getExpandIconPath(panelMode === 'expand', isDarkTheme)}
                    alt='expand mode icon'
                  />
                </button>
                <button
                  onClick={() => {
                    setPanelMode('normal');
                    setExpandDetection?.(false);
                  }}
                >
                  <img
                    src={getNormalIconPath(panelMode === 'normal', isDarkTheme)}
                    alt='normal mode icon'
                  />
                </button>
              </>
            )}
          </div>
        </div>
        {/* 일반 노드 */}
        <Nodes panelMode={panelMode} setPanelMode={setPanelMode} />
      </div>
    </div>
  );
}

function Nodes({
  panelMode,
  setPanelMode,
}: {
  panelMode: TPanelMode;
  setPanelMode: (mode: TPanelMode) => void;
}) {
  const { nodeId } = useContext(NodeContext);
  const [nodeType, setNodeType] = useState<any>();

  useEffect(() => {
    if (editor && nodeId) {
      const pickedNode = editor.getNode(nodeId);
      setNodeType(pickedNode);
    }
  }, [nodeId]);

  if (nodeType?.type === 'deeplearning') {
    return <DeepLearningPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType?.type === 'classification') {
    return <ClassificationPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof DataSelectorNode) {
    return <DataSelectorPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof DatasetConfigurationNode) {
    return (
      <DatasetConfigurationPanel ctrl={nodeType.controls['ctrl']} expand={panelMode === 'expand'} />
    );
  }
  if (nodeType instanceof PatchGenerationNode) {
    return (
      <PatchGenerationPanel
        ctrl={nodeType.controls['ctrl']}
        expand={panelMode === 'expand'}
        setPanelMode={setPanelMode}
      />
    );
  }
  if (nodeType instanceof CustomProcessingNode) {
    return (
      <CustomProcessingPanel ctrl={nodeType.controls['ctrl']} expand={panelMode === 'expand'} />
    );
  }
  if (nodeType instanceof ImageCropNode) {
    return <ImageCropPanel ctrl={nodeType.controls['ctrl']} expand={panelMode === 'expand'} />;
  }
  if (nodeType instanceof EdgeDetectNode) {
    return <EdgeDetectPanel ctrl={nodeType.controls['ctrl']} expand={panelMode === 'expand'} />;
  }
  if (nodeType instanceof ViewerNode) {
    return (
      <CommonViewerPanel
        ctrl={nodeType.controls['ctrl']}
        expand={panelMode === 'expand'}
        setPanelMode={setPanelMode}
      />
    );
  }
  if (nodeType instanceof BlenderNode) {
    return <BlenderPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof ChartNode) {
    return <ChartPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof GearNode) {
    return <GearPanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof FeatureNode) {
    return <FeaturePanel ctrl={nodeType.controls['ctrl']} />;
  }
  if (nodeType instanceof FormGeneraterNode) {
    return (
      <FormGeneraterPanel
        ctrl={nodeType.controls['ctrl']}
        expand={panelMode === 'expand'}
        nodeId={nodeId}
        setPanelMode={setPanelMode}
      />
    );
  }
  if (nodeType instanceof ImageProcessingNode) {
    return (
      <ImageProcessingPanel
        ctrl={nodeType.controls['ctrl']}
        expand={panelMode === 'expand'}
        nodeId={nodeId}
        setPanelMode={setPanelMode}
      />
    );
  }
  return null;
}
