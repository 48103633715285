import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Editor from '@monaco-editor/react';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

const CodeEditor = () => {
  const location = useLocation();
  const workspace = location.state?.workspace;
  const [code, setCode] = useState('미리보기를 지원하지 않는 파일입니다.');
  const api = useAPIwithCookies();

  const getCode = async () => {
    const response = await api.get(`/mlops/model_code/${workspace.model_path}`);

    setCode(response.data);
  };

  useEffect(() => {
    getCode();
  }, []);

  return (
    <div className='flex h-screen w-full flex-col bg-gray-900 text-white'>
      {/* Monaco Editor */}
      <div className='flex-1'>
        <Editor
          height='100%'
          defaultLanguage='python' // 기본 언어 (필요하면 변경 가능)
          theme='vs-dark'
          value={code}
          onChange={(value) => setCode(value || '')}
          options={{
            fontSize: 14,
            minimap: { enabled: false },
          }}
        />
      </div>
    </div>
  );
};

export default CodeEditor;
