import React from 'react';

interface PrimaryButtonProps {
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  disabled,
  children,
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`m-[var(--20px)] block w-[calc(100%-var(--35px))] cursor-pointer rounded-lg
        bg-purple200 p-[var(--14px)] px-[var(--20px)]
        text-center text-[var(--16px)] text-white hover:brightness-[80%] ${
          disabled ? 'cursor-not-allowed bg-[#ccc]' : ''
        }`}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
