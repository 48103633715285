import { TPanelMode } from '@/types/common/type';
import { useState, useEffect } from 'react';

/**
 * 패널 모드와 관련된 상태와 동작을 관리하는 커스텀 훅
 *
 * @param {string} curDir - 현재 디렉토리 경로
 * @returns {{
 *   panelMode: TPanelMode,
 *   translate: string,
 *   handlePanelMode: () => void,
 *   ensureExpandPanelMode: () => void
 * }} 패널 모드 상태와 관련된 함수 및 값
 */
export const usePanelMode = (curDir: string) => {
  const [panelMode, setPanelMode] = useState<TPanelMode>('init');
  const [translate, setTranslate] = useState<string>('');

  // curDir 값에 따라 panelMode 초기화
  useEffect(() => {
    if (curDir !== '') {
      setPanelMode('normal');
    }
  }, [curDir]);

  // Tab 키 입력에 따른 panelMode 변경
  // useEffect(() => {
  //   const handleKeyDown = (e: KeyboardEvent) => {
  //     if (e.code === 'Tab') {
  //       handlePanelMode();
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [panelMode]);

  // panelMode 값에 따라 translate 상태 업데이트
  useEffect(() => {
    setTranslate(panelMode === 'expand' ? '46rem' : '24.25rem');
  }, [panelMode]);

  /**
   * 패널 모드를 순환적으로 변경하는 함수
   */
  const handlePanelMode = () => {
    switch (panelMode) {
      case 'init':
        setPanelMode('normal');
        break;
      case 'normal':
        setPanelMode('expand');
        break;
      case 'expand':
        setPanelMode('normal');
        break;
    }
  };

  /**
   * 패널 모드를 'expand' 상태로 강제 변경하는 함수
   */
  const ensureExpandPanelMode = () => {
    if (panelMode !== 'expand') {
      setPanelMode('expand');
    }
  };

  return {
    panelMode,
    translate,
    handlePanelMode,
    ensureExpandPanelMode,
  };
};
