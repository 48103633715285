import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import { IGearList, IfileNdirList, dirType, filedirName } from '../datamanage/storage/storage.type';
import { uploadChunkFile } from '../datamanage/storage/utils';
import CanvasHeader from '../../components/canvasHeader/CanvasHeader';
import LoadingAnimation from '../../components/loadingAnimation/LoadingAnimation';
import StorageMain from '../datamanage/storage/StorageMain';
import SmcFolderList from './SmcFolderList';
import SmcSidePanel from './SmcSidePanel';
import { useAPIwithCookies } from '../../hooks/useApiCookies';
import { SmcContext } from '../../context/SmcContext';
import CustomModal from '../../common/CustomModal';
import SmcProofreader from './SmcProofreader';
import styled from 'styled-components';
import ProgressCircleDiv from '../../components/progressCircle/ProgressCirclediv';
import { StorageContext } from '../../context/StorageContext';

const Container = styled.div`
  div.modal-children-wrapper {
    width: 90%;
    height: 80%;
    padding: 20px;
    border-radius: 20px;
    box-sizing: border-box;
  }
`

export default function SmcPage() {
  const api = useAPIwithCookies();
  //setDirectory loading
  const { loading, dirLoading, progress, curDir } = useContext(StorageContext);
  const [baseDir, setBaseDir] = useState(curDir);

  const getInitDirectory = async () => {
    const response = await api.get('/cloud/list', { cur_dir: '/' });
    const root = response.data;
    const filteredList = root.filter(v => v.type === 'dir');
    setFolderList(filteredList);
  }

  useEffect(() => {
    setBaseDir('/');
    getInitDirectory();
  }, [])

  useEffect(() => {
    setBaseDir(curDir);
  }, [curDir])

  // SmcContext
  const {smcModal, setSmcModal} = useContext(SmcContext);
  const [folderList, setFolderList] = useState<IfileNdirList[]>([]);

  const createDirectory = async () => {
    const name = prompt('Set Directory Name');
    try {
      await api.post('/cloud/make_dir', { cur_dir: '', names: [name] });
    }
    catch (error: any) {
      if (error.detail === 'Conflict detected') {
        alert('The name already exists.')
      }
    }
    finally {
      getInitDirectory();
    }
  }

  const deleteDirectory = async (selectedDirectoryName) => {
    if (!window.confirm("Delete directory?")) return;
    try {
      await api.post('/cloud/delete', { cur_dir: '', names: [selectedDirectoryName] });
    }
    catch (err) {
      console.log(err);
    }
    finally {
      getInitDirectory();
    }
  }

  return (
    <Container className="storage">
      <CanvasHeader />
      <div id='storage-grid'>
        {
          smcModal 
          && 
          <CustomModal open={smcModal} onClose={() => setSmcModal(false)} bgShown='blur' bgColor='black' className="modal-children-wrapper workspace-panel-modal-wrapper">
            <button type="button" onClick={()=>setSmcModal(false)}>close</button>
            <SmcProofreader />
          </CustomModal>
        }
        <SmcFolderList 
          folderList={folderList} 
          createDirectory={createDirectory}
          deleteDirectory={deleteDirectory}
        />
        {dirLoading && <LoadingAnimation label={'loading...'} />}
        <div className='strage-main-container'>
          <StorageMain 
            baseDir={baseDir}
          />
        </div>
        <SmcSidePanel />
      </div>
      {
        loading 
        &&
        <ProgressCircleDiv 
          label={progress?.filename ?? ''}
          percentage={Number(progress?.progress.toFixed(2))}
        />
    }
    </Container>
  )
}
