import React from 'react';
import styled from 'styled-components';

const LoadingAnimation = styled.div`
  z-index: 999;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000099;

  div.animation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  [class^='loading'] {
    display: inline-block;
    padding: 5px;
    border-radius: 12px;
    background-color: #b472f7;
    text-align: center;
  }
  div.loading-1 {
    animation: move 2.5s infinite;
  }
  div.loading-2 {
    animation: move 2.5s infinite 0.2s;
  }
  div.loading-3 {
    animation: move 2.5s infinite 0.4s;
  }
  div.loading-4 {
    animation: move 2.5s infinite 0.6s;
  }
  div.loading-5 {
    animation: move 2.5s infinite 0.8s;
  }
  div.loading-6 {
    animation: move 2.5s infinite 1s;
  }
  p.loading-text {
    padding: 12px 32px;
    border-radius: 36px;
    background-color: transparent;
    color: #d2b7ed;
    font-weight: 700;
    font-size: var(--32px);
  }

  @keyframes move {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

export default function Loading({ label }) {
  return (
    <LoadingAnimation>
      <div className='animation'>
        <div className='loading-6'></div>
        <div className='loading-5'></div>
        <div className='loading-4'></div>
        <div className='loading-3'></div>
        <div className='loading-2'></div>
        <div className='loading-1'></div>
      </div>
      <p className='loading-text'>{label}</p>
    </LoadingAnimation>
  );
}
