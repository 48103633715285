import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Editor from '@monaco-editor/react';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { CustomThemeContext } from '@/contexts/common/Context';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { default as OSD } from 'openseadragon';
const aaa = {
  nodes: [
    {
      id: '3f3b91786268220d',
      label: 'DataSelector',
      outputs: {
        out: {
          id: '42457182cc4014eb',
          socket: { name: 'Custom' },
        },
      },
      inputs: {},
      controls: {
        ctrl: {
          type: 'DataSelectorControl',
          id: '37ae38aa78b9ba72',
          option: {
            path: '/withtest.vmeta',
            thumbnail: '',
            type: 'vmeta',
            imgSize: { width: 0, height: 0 },
          },
        },
      },
      position: { x: -1000, y: -1700 },
    },
    {
      id: '0fe21d265cc19169',
      label: 'Viewer',
      outputs: {},
      inputs: {
        addon: {
          id: '7960850a924b51f2',
          socket: { name: 'Addon' },
        },
        in: {
          id: 'b742512da128841e',
          socket: { name: 'Custom' },
        },
      },
      controls: {
        ctrl: {
          type: 'ViewerControl',
          id: '0c8af818773a2034',
          option: {
            img_paths: { '3f3b91786268220d': '/withtest.vmeta' },
            effector: {
              input: ['3f3b91786268220d'],
              node: {
                data_selector: {
                  thumbnail: '',
                  type: 'vmeta',
                  img_size: { width: 0, height: 0 },
                  width: 0,
                  height: 0,
                },
              },
            },
          },
        },
      },
      position: { x: -500, y: -1400 },
    },
    {
      id: '582d1e746325eb20',
      label: 'CustomProcessing',
      outputs: {
        out: {
          id: 'b67b8a780ac74d15',
          socket: { name: 'Custom' },
        },
      },
      inputs: {
        in: {
          id: 'd48a201077631e41',
          socket: { name: 'Custom' },
        },
      },
      controls: {
        ctrl: {
          type: 'CustomProcessingControl',
          id: '439527ab03c0aef2',
          option: {
            value: {
              input: [
                {
                  input: ['3f3b91786268220d'],
                  node: {
                    data_selector: {
                      thumbnail: '',
                      type: 'vmeta',
                      img_size: { width: 0, height: 0 },
                      width: 0,
                      height: 0,
                    },
                  },
                },
              ],
              node: {
                custom_processing: {
                  node_id: '582d1e746325eb20',
                  output_type: '',
                  version_id: '',
                  user_import_list: [],
                  path_list: [],
                  user_code: '',
                  console_log: '',
                  error: '',
                  img_path: { '3f3b91786268220d': '/withtest.vmeta' },
                  selected_category: [],
                  preveiw_img: [],
                  input_validity: true,
                  code_security: false,
                  code_completion: false,
                  recent_version_id: '',
                  version_list: {},
                },
              },
            },
            path: '/withtest.vmeta',
            outputType: '',
            version_id: '',
            user_import_list: [],
            path_list: [],
            user_code: '',
            console_log: '',
            error: '',
            img_path: { '3f3b91786268220d': '/withtest.vmeta' },
            selected_category: [],
            preview_img: [],
            input_validity: true,
            code_security: false,
            code_completion: false,
            recent_version_id: '',
            version_list: {},
          },
        },
      },
      position: { x: -700, y: -1900 },
    },
    {
      id: 'ec098bf08fc146f2',
      label: 'FormGenerater',
      outputs: {
        addon: {
          id: '34ee4f09d755fe6f',
          socket: { name: 'Addon' },
        },
      },
      inputs: {},
      controls: {
        ctrl: {
          type: 'FormGeneraterControl',
          id: 'cacad712f74271b2',
          option: {
            value: [
              {
                listName: 'm8h2e92w',
                inputs: [
                  {
                    type: 'text',
                    question: '',
                    contents: null,
                    answer: '',
                  },
                  {
                    type: 'choice',
                    question: '',
                    contents: [],
                    answer: [],
                  },
                  {
                    type: 'text',
                    question: '',
                    contents: null,
                    answer: '',
                  },
                ],
              },
              {
                listName: 'm8h2e9dy',
                inputs: [
                  {
                    type: 'text',
                    question: '',
                    contents: null,
                    answer: '',
                  },
                  {
                    type: 'text',
                    question: '',
                    contents: null,
                    answer: '',
                  },
                  {
                    type: 'choice',
                    question: '',
                    contents: [],
                    answer: [],
                  },
                ],
              },
            ],
          },
        },
      },
      position: { x: -500, y: -1700 },
    },
  ],
  connections: [
    {
      id: '768a44ab50bbd626',
      source: '3f3b91786268220d',
      sourceOutput: 'out',
      target: '0fe21d265cc19169',
      targetInput: 'in',
    },
    {
      id: '1d3fcc8b7acb0e0e',
      source: '3f3b91786268220d',
      sourceOutput: 'out',
      target: '582d1e746325eb20',
      targetInput: 'in',
    },
    {
      id: '6b5eedfbdbc02f2e',
      source: 'ec098bf08fc146f2',
      sourceOutput: 'addon',
      target: '0fe21d265cc19169',
      targetInput: 'addon',
    },
  ],
};

const ReportViewer = () => {
  const api = useAPIwithCookies();
  const [data, setData] = useState(null);
  const [keys, setKeys] = useState<Record<string, string[]>>({});

  const location = useLocation();
  const params = useParams();
  const [viewerKey, setViewerKey] = useState('');
  const [answers, setAnswers] = useState<any>([]);

  const fetchData = async () => {
    const viewerKey = params.node_id;

    try {
      const response = await api.get(`/publish/${params.wor_id}/${params.node_id}`);

      setData(response);

      setViewerKey(response.data.output.key);
      setKeys(response.data.structure);

      const data = JSON.parse(response.data.workspace.nodes);
      console.log(data);

      const formGeneraterNode = data.nodes.find((node) => node.label === 'FormGenerater');
      if(formGeneraterNode){
        const optionValue = formGeneraterNode?.controls?.ctrl?.option?.value;
        setAnswers(optionValue);
      }
      const firstKeys = Object.keys(response.data.structure);
      if (firstKeys.length > 0) {
        const secondKeys = Object.keys(response.data.structure[firstKeys[0]]);
        if (secondKeys.length > 0) {
          for (let i = 0; i < secondKeys.length; i++) {
            if (secondKeys[i].includes('img-')) {
              console.log(response.data.structure[firstKeys[0]][secondKeys[i]]);
              const imageResponse = await api.get(
                `/viewer/generate_osd_meta/${response.data.output.key}/${response.data.structure[firstKeys[0]][secondKeys[i]]}`,
              );
              // viewer/generate_osd_meta/1742452866_Tdqmre04/1742452911_H4wVSsB3

              const generatedOsdKey = imageResponse.data;
              setOsdKey(generatedOsdKey);

              setSelectedSet(firstKeys[0]);
              setSelectedImgKey(secondKeys[i]);
              break;
            }
          }
          for (let i = 0; i < secondKeys.length; i++) {
            if (secondKeys[i].includes('text-')) {
              const textResponse = await api.get(
                `/viewer/vmeta_load_text/${response.data.output.key}/${response.data.structure[firstKeys[0]][secondKeys[i]]}`,
              );

              setTextData(textResponse.data);
              setSelectedTextKey(secondKeys[i]);
              break;
            }
          }
        }
      }
      console.log(response);
    } catch (error) {
      console.error('API 호출 실패:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [selectedSet, setSelectedSet] = useState<string>('');
  const [selectedImgKey, setSelectedImgKey] = useState<string>('');
  const [selectedTextKey, setSelectedTextKey] = useState<string>('');
  const [textData, setTextData] = useState<string>('');
  // const [metaData, setMetadata] = useState<string>('');
  const [osdKey, setOsdKey] = useState<string>('');

  const [rightPanelWidth, setRightPanelWidth] = useState(400); // pixel 단위
  const [textPanelHeight, setTextPanelHeight] = useState(50); // % 단위

  const [selectedAnswer, setSelectedAnswer] = useState<string>('');

  let startDragPos = 0;

  const { theme } = useContext(CustomThemeContext);

  // Type1 패널이 최초 마운트 되었을 때 초기 keys 가져오기
  // const fetchKeyList = async () => {
  //   try {
  //     const response = await api.get(`/viewer/output_data_info/` + viewerKey);
  //     const data = response.data.result;

  //     if (data.type === 'vmeta') {
  //       setKeys(data.structure);
  //       const firstKeys = Object.keys(data.structure);
  //       if (firstKeys.length > 0) {
  //         const secondKeys = Object.keys(data.structure[firstKeys[0]]);
  //         if (secondKeys.length > 0) {
  //           for (let i = 0; i < secondKeys.length; i++) {
  //             if (secondKeys[i].includes('img-')) {
  //               const imageResponse = await api.get(
  //                 `/viewer/generate_osd_meta/${viewerKey}/
  //                 ${data.structure[firstKeys[0]][secondKeys[i]]}`,
  //               );

  //               const generatedOsdKey = imageResponse.data;
  //               setOsdKey(generatedOsdKey);

  //               setSelectedSet(firstKeys[0]);
  //               setSelectedImgKey(secondKeys[i]);
  //               break;
  //             }
  //           }
  //           for (let i = 0; i < secondKeys.length; i++) {
  //             if (secondKeys[i].includes('text-')) {
  //               const textResponse = await api.get(
  //                 `/viewer/vmeta_load_text/${viewerKey}/${data.structure[firstKeys[0]][secondKeys[i]]}`,
  //               );

  //               setTextData(textResponse.data);
  //               setSelectedTextKey(secondKeys[i]);
  //               break;
  //             }
  //           }
  //         }
  //       }
  //     } else {
  //       console.warn('Invalid data format received:', data);
  //     }
  //   } catch (error) {
  //     console.error('Failed to fetch keyList:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchKeyList();
  // }, [viewerKey]);

  // target 버튼으로 스크롤
  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById('img-slider');
      if (!container) return;

      const targetButton = container.querySelector(
        `li[data-key='${selectedSet}-${selectedImgKey}']`,
      ) as HTMLElement | null;
      if (targetButton) {
        const offset =
          targetButton.offsetTop -
          container.offsetTop -
          container.clientHeight / 2 +
          targetButton.clientHeight / 2;

        container.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }, 500);
  }, [selectedSet, selectedImgKey]);

  // target 버튼으로 스크롤
  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById('text-slider');
      if (!container) return;

      const targetButton = container.querySelector(
        `button[data-key='${selectedSet}-${selectedTextKey}']`,
      ) as HTMLElement | null;
      if (targetButton) {
        const offset =
          targetButton.offsetLeft -
          container.offsetLeft -
          container.clientWidth / 2 +
          targetButton.clientWidth / 2;

        container.scrollTo({
          left: offset,
          behavior: 'smooth',
        });
      }
    }, 500);
  }, [selectedSet, selectedTextKey]);

  // 좌측 normal 패널에서 특정 key 선택 시, 관련 이미지와 텍스트 불러오기
  const handleImgKeySelection = async (firstKey: string, secondKey: string, directClick = true) => {
    try {
      const imageResponse = await api.get(
        `/viewer/generate_osd_meta/${viewerKey}/${keys[firstKey][secondKey]}`,
      );

      const generatedOsdKey = imageResponse.data;
      setOsdKey(generatedOsdKey);

      if (directClick && selectedSet !== firstKey) {
        // 같은 set에 있는 첫번째 text 구하기
        const secondKeys = Object.keys(keys[firstKey]);
        for (let i = 0; i < secondKeys.length; i++) {
          if (secondKeys[i].includes('text-')) {
            handleTextKeySelection(firstKey, secondKeys[i], false);
            break;
          }
        }
      }

      setSelectedSet(firstKey);
      setSelectedImgKey(secondKey);
    } catch (error) {
      setOsdKey('');
      console.error('Error loading image or text data:', error);
    }
  };

  const handleTextKeySelection = async (
    firstKey: string,
    secondKey: string,
    directClick = true,
  ) => {
    try {
      // 해당 firstKey의 텍스트 데이터 요청
      const textResponse = await api.get(
        `/viewer/vmeta_load_text/${viewerKey}/${keys[firstKey][secondKey]}`,
      );

      // 받아온 text 데이터 처리
      setTextData(textResponse.data);

      if (directClick && selectedSet !== firstKey) {
        // 같은 set에 있는 첫번째 img 구하기
        const secondKeys = Object.keys(keys[firstKey]);
        for (let i = 0; i < secondKeys.length; i++) {
          if (secondKeys[i].includes('img-')) {
            handleImgKeySelection(firstKey, secondKeys[i], false);
            break;
          }
        }
      }

      setSelectedSet(firstKey);
      setSelectedTextKey(secondKey);
    } catch (error) {
      console.error('Error loading text data for tab selection:', error);
      setTextData('');
    }
  };

  const handleAnswerSelection = async (input: string) => {
    setSelectedAnswer(input);
  };

  // 오른쪽 텍스트, form 패널에 뜰 list (key tab) 에는 vmeta에서 convention이 text인 것들을 가져오면 됨 => first_key
  // + form data에서 id 들을 가져와야 함. => vemta의 first key랑 중복되는게 있으면 제외
  // * form: addon.option.value 변화를 감지하여 answers를 업데이트

  // * form: 사용자가 입력/선택한 값을 업데이트하는 함수
  const handleAnswerChange = (groupIndex: number, index: number, value: string) => {
    setAnswers((prevAnswers) => {
      const newAnswers = prevAnswers.map((group, gIdx) =>
        gIdx === groupIndex
          ? {
              ...group,
              inputs: group.inputs.map((item, i) =>
                i === index ? { ...item, answer: value } : item,
              ),
            }
          : group,
      );

      return newAnswers;
    });
  };

  // inputs = [
  //   { type: 'text', question: '22', contents: null, answer: 'ㄱ3' },
  //   { type: 'text', question: '23', contents: null, answer: 'ㄴㄴ3' },
  // ];

  // [{type: 'choice', question: '선택', contents: ['1', '2', '3'], answer: '1'}, ]

  // 최종 제출
  // 🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤🐤
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [metadata, setMetadata] = useState({ title: '', name: '' });

  const handleSubmitViewerMeataData = async (id: any) => {
    const selectedGroup = answers.find((group: any) => group.listName === id);

    // "contents" 데이터 생성
    const contents = selectedGroup.inputs.map((item: any) => {
      let answer = item.answer;

      if (Array.isArray(answer)) {
        if (answer.length > 1) {
          return [item.question, item.contents[0]];
        }
        answer = answer[0]; // 첫 번째 요소만 사용
      }

      return [item.question, answer];
    });

    // 현재 화면 캡처
    const screenshot = await captureScreen();
    console.log(metadata);

    // 백엔드로 전송할 데이터
    const payload = {
      wor_id: params.wor_id,
      title: metadata.title,
      name: metadata.name,
      contents: contents,
      created_at: new Date().toISOString().replace('T', ' ').split('.')[0],
      screenshot: screenshot,
    };

    try {
      const response = await api.post(`/publish/submit_form`, payload);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '제출 성공!',
          text: '응답이 정상적으로 제출되었습니다.',
          confirmButtonText: '확인',
        });
      } else {
        throw new Error('서버 오류');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '제출 실패!',
        text: '서버와의 통신 중 오류가 발생했습니다.',
        confirmButtonText: '확인',
      });
      console.error(error);
    }
  };

  const captureRef = useRef<HTMLDivElement>(null);

  const captureScreen = async () => {
    if (!captureRef.current) return;

    const canvas = await html2canvas(captureRef.current);
    return canvas.toDataURL('image/png').split(',')[1];
  };

  const handleMetadataChange = (key: string, value: string) => {
    setMetadata((prev) => ({ ...prev, [key]: value }));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  //test
  return (
    <>
      <div
        className='mb-[var(--20px)] flex w-full flex-row rounded-custom-lg h-[100vh] bg-[#000000]'
        ref={captureRef}
      >
        <div className='flex w-full flex-row p-4 h-[100vh]'>
          {/* 왼쪽 이미지 리스트 패널 */}
          <div
            className='mr-4 w-[300px]
              rounded-custom-lg bg-white100 p-6 shadow-lg
              transition-all duration-300 dark:bg-black200'
          >
            <div className='flex items-center gap-2.5'>
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/rete/node/icon/viewer.svg'
                    : '/images/rete/node/icon/viewer-black.svg'
                }
                alt='Image List Icon'
                className='size-6'
              />
              <div
                className='maintitle break-all text-[24px]
                font-bold tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
              >
                Image List
              </div>
            </div>
            <div
              className='description mb-4 mt-1
              flex text-[16px] font-light text-gray300 dark:text-white100'
            >
              Select the image below to view
            </div>
            <div id='img-slider' className='h-full overflow-y-auto'>
              <ul className='list-none space-y-2'>
                {Object.entries(keys).map(([firstKey, secondKeys]) =>
                  Object.entries(secondKeys)
                    // path 값이 이미지 파일 확장자를 포함하는지 필터링해서 이미지만 가져옴
                    .filter(([secondKey, path]) => secondKey.includes('img-'))
                    .map(([secondKey, path]) => (
                      <li
                        key={`${firstKey}-${secondKey}`}
                        data-key={`${firstKey}-${secondKey}`}
                        className={`cursor-pointer rounded-md px-3 py-1
                          text-[14px] transition-all duration-200 
                      ${
                        selectedSet === firstKey
                          ? selectedImgKey === secondKey
                            ? 'bg-purple200 text-white100 shadow-md'
                            : 'bg-purple100 text-white100 shadow-md'
                          : 'text-black100 hover:bg-purple100 hover:text-black100 dark:text-white100 ' +
                            'dark:hover:bg-purple100'
                      }`}
                        onClick={() => handleImgKeySelection(firstKey, secondKey, true)}
                      >
                        {firstKey}: {secondKey}
                      </li>
                    )),
                )}
              </ul>
            </div>
          </div>

          {/* 가운데: 탭 + OpenSeadragon */}
          <div
            style={{ width: `calc(100% - 300px - ${rightPanelWidth.toString()}px)` }}
            className='mr-2 rounded-[7px] bg-white100 p-4 text-[24px]
              shadow-lg transition-all duration-300 dark:bg-black200'
          >
            <OSDTestViewer keys={osdKey} />
          </div>

          {/* 세로 핸들 */}
          <div
            style={{
              width: 5,
              margin: 5,
              backgroundColor: '#444',
              cursor: 'ew-resize',
            }}
            draggable
            onDragStart={(e) => {
              startDragPos = e.clientX;
            }}
            onDrag={(e) => {
              // if (e.clientX === 0) return;
              // const delta = e.clientX - startDragPos;
              // const newWidth = rightPanelWidth - delta;
              // setRightPanelWidth(newWidth);
            }}
            onDragEnd={(e) => {
              if (e.clientX === 0) return;

              const delta = e.clientX - startDragPos;
              const newWidth = rightPanelWidth - delta;
              setRightPanelWidth(newWidth);
            }}
          />

          {/* 오른쪽 Wrapper */}
          <div className='flex w-full h-full flex-col' style={{ width: `${rightPanelWidth}px` }}>
            {/* 탭 영역 */}
            {Object.keys(keys).length > 0 && (
              <div id='text-slider' className='overflow-x-auto'>
                <div className='w-fit flex mb-2 items-center'>
                  {Object.entries(keys).map(([firstKey, secondKeys]) =>
                    Object.entries(secondKeys)
                      .filter(([secondKey, path]) => secondKey.includes('text-'))
                      .map(([secondKey, path]) => (
                        <button
                          key={`${firstKey}-${secondKey}`}
                          data-key={`${firstKey}-${secondKey}`}
                          className={`z-[100] mr-2 h-[70px] w-[145px]
                            cursor-pointer rounded-custom-lg border-b
                            px-4 py-1 text-[14px] transition-all
                            duration-200 hover:bg-purple-100
                            ${
                              selectedSet === firstKey
                                ? selectedTextKey === secondKey
                                  ? 'bg-purple200 text-white100 shadow-md'
                                  : 'bg-purple100 text-white100 shadow-md'
                                : 'bg-white100 dark:bg-black300 text-black100 hover:bg-purple100 hover:text-black100 dark:text-white100 ' +
                                  'dark:hover:bg-purple100'
                            }`}
                          onClick={() => handleTextKeySelection(firstKey, secondKey, true)}
                        >
                          {firstKey}: {secondKey}
                        </button>
                      )),
                  )}
                </div>
              </div>
            )}

            {/* Text 데이터 영역 */}
            <div
              style={{
                height: `${textPanelHeight.toString()}%`,
                // width: `${rightPanelWidth.toString()}px`,
                whiteSpace: 'pre-line',
                lineHeight: 1.6,
                fontSize: '1.2rem',
              }}
              className='mb-4 w-full overflow-y-auto
                rounded-[7px] bg-white100 p-6 text-[24px]
                text-black100 shadow-lg transition-all duration-300
                dark:bg-black200 dark:text-white100'
            >
              {textData
                ? textData.split('\n').map((line, index) => (
                    <div key={index}>
                      <span style={{ marginRight: '0.5rem' }}>• </span>
                      {line}
                    </div>
                  ))
                : 'No Text Data'}
            </div>

            {/* 가로 핸들 */}
            <div
              style={{
                height: 5,
                margin: 5,
                backgroundColor: '#444',
                cursor: 'ns-resize',
              }}
              draggable
              onDragStart={(e) => {
                startDragPos = e.clientY;
              }}
              onDrag={(e) => {
                // if (e.clientX === 0) return;
                // const delta = e.clientX - startDragPos;
                // const newWidth = rightPanelWidth - delta;
                // setRightPanelWidth(newWidth);
              }}
              onDragEnd={(e) => {
                if (e.clientX === 0) return;

                const delta = e.clientY - startDragPos;
                const delta_percent = (delta / window.innerHeight) * 100;
                const newHeight = textPanelHeight + delta_percent;
                setTextPanelHeight(newHeight);
              }}
            />

            {/* 질문 영역 */}
            <div
              className='w-full grow overflow-y-auto
              rounded-[7px] bg-white100 px-5 py-6
              text-lg text-black300 shadow-lg transition-all duration-300
            dark:bg-black200 dark:text-white100'
            >
              {answers.length > 0 ? (
                <>
                  <div className='w-fit flex mb-6 items-center'>
                    {answers.map((group: any, groupIndex: number) => (
                      <button
                        key={`${answers[groupIndex].listName}`}
                        className={`z-[100] mr-2 h-[50px] w-[145px]
                          cursor-pointer rounded-custom-lg border-b
                          px-4 py-1 text-[14px] transition-all
                          duration-200 hover:bg-purple-100
                          ${
                            selectedAnswer === answers[groupIndex].listName
                              ? 'bg-purple200 text-white100 shadow-md'
                              : 'bg-white100 dark:bg-black300 text-black100 hover:bg-purple100 hover:text-black100 dark:text-white100 ' +
                                'dark:hover:bg-purple100'
                          }`}
                        onClick={() => handleAnswerSelection(answers[groupIndex].listName)}
                      >
                        {answers[groupIndex].listName}
                      </button>
                    ))}
                  </div>

                  <form className='space-y-6'>
                    {answers.map(
                      (group: any, groupIndex: number) =>
                        answers[groupIndex].listName === selectedAnswer && (
                          <div key={groupIndex} className='mb-6 flex flex-col space-y-4 pb-4'>
                            <div className='flex items-center gap-2.5'>
                              <img
                                src={
                                  process.env.PUBLIC_URL + theme === 'dark'
                                    ? '/images/rete/node/icon/viewer.svg'
                                    : '/images/rete/node/icon/viewer-black.svg'
                                }
                                alt='Question List Icon'
                                className='size-6'
                              />
                              <div
                                className='maintitle mb-2 break-all text-[24px]
                                  font-bold tracking-[var(--spacingDefalt)]
                                  text-black300 dark:text-white100'
                              >
                                {'Form.  ' + group.listName}
                              </div>
                            </div>

                            {/* 그룹 내 질문 목록 */}
                            {group.inputs.map((item: any, index: number) => (
                              <div key={index} className='mb-6 flex flex-col'>
                                {/* 질문 */}
                                <label
                                  className='mb-2 font-medium text-black100 dark:text-white100'
                                  style={{ whiteSpace: 'pre-line' }}
                                >
                                  {'Q.  ' + item.question}
                                </label>
                                {/* Text 타입 질문 */}
                                {item.type === 'text' ? (
                                  <input
                                    type='text'
                                    value={item.answer || ''}
                                    onChange={(e) =>
                                      handleAnswerChange(groupIndex, index, e.target.value)
                                    }
                                    className='rounded-lg border border-gray200
                                    p-2 transition-all duration-200
                                      dark:border-white100 dark:bg-black200 dark:text-white100'
                                  />
                                ) : null}
                                {/* Choice 타입 질문 */}
                                {item.type === 'choice' ? (
                                  <select
                                    value={item.answer || ''}
                                    onChange={(e) =>
                                      handleAnswerChange(groupIndex, index, e.target.value)
                                    }
                                    className='rounded-lg border border-gray200
                                    p-2 transition-all duration-200
                                      dark:border-white100 dark:bg-black200 dark:text-white100'
                                  >
                                    <option value='' disabled>
                                      Select option
                                    </option>
                                    {item.contents.map((choice: string, choiceIndex: number) => (
                                      <option key={choiceIndex} value={choice}>
                                        {choice}
                                      </option>
                                    ))}
                                  </select>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        ),
                    )}
                    <div
                      className='flex h-16 w-full items-center justify-center rounded-[7px] bg-purple200
                        text-white hover:bg-[#4e2382]'
                      onClick={() => handleOpenModal()}
                    >
                      Submit
                    </div>
                  </form>
                </>
              ) : (
                <p className='text-[20px] text-gray200 dark:text-gray200'>No questions available</p>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {isModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='bg-white dark:bg-black200 p-6 rounded-lg shadow-lg w-[400px]'>
            <h2 className='text-lg font-bold mb-4 dark:text-[white] text-black'>
              정보를 입력해주세요!
            </h2>

            <label className='block font-medium text-black100 dark:text-white100'>Title</label>
            <input
              type='text'
              value={metadata.title}
              onChange={(e) => handleMetadataChange('title', e.target.value)}
              className='w-full border p-2 rounded-md mb-4 dark:border-white100 dark:bg-black200 dark:text-white100'
            />

            <label className='block font-medium text-black100 dark:text-white100'>Name</label>
            <input
              type='text'
              value={metadata.name}
              onChange={(e) => handleMetadataChange('name', e.target.value)}
              className='w-full border p-2 rounded-md mb-4 dark:border-white100 dark:bg-black200 dark:text-white100'
            />

            <div className='flex justify-end gap-2'>
              <button
                onClick={() => setIsModalOpen(false)}
                className='px-4 py-2 bg-gray-300 rounded-md'
              >
                취소
              </button>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  handleSubmitViewerMeataData(selectedAnswer);
                }}
                disabled={Object.values(metadata).some(
                  (value) => typeof value === 'string' && value.trim() === '',
                )}
                className={`px-4 py-2 rounded-md text-white ${
                  Object.values(metadata).some(
                    (value) => typeof value === 'string' && value.trim() === '',
                  )
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-purple200 hover:bg-[#4e2382]'
                }`}
              >
                제출
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// OpenSeadragon Viewer 영역 -------------------------------------------
function OSDTestViewer(props) {
  const [viewer, setViewer] = useState<OSD.Viewer | null>(null);
  useEffect(() => {
    if (props.keys === '') return;
    if (viewer) {
      viewer.open(
        process.env.REACT_APP_VIENCE_API_KEY + `/viewer/osd_meta/${props.keys}/viewer_img.dzi`,
      );
      return;
    }

    const osdViewer = new OSD.Viewer({
      id: 'osd-viewer',
      prefixUrl: '/images/openseadragon/',
      tileSources:
        process.env.REACT_APP_VIENCE_API_KEY + `/viewer/osd_meta/${props.keys}/viewer_img.dzi`,
      crossOriginPolicy: 'Anonymous',
      animationTime: 0.5,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 50,
      minZoomLevel: 0.1,
      visibilityRatio: 1,
      zoomPerClick: 2,
      zoomPerScroll: 2,
      showNavigator: true,
      navigatorWidth: '250px',
      navigatorHeight: '250px',
      showFullPageControl: true,
      showHomeControl: true,
      showZoomControl: true,
      degrees: 0,
      showRotationControl: true,
    });

    setViewer(osdViewer);
  }, [viewer, props.keys]);

  return <div id='osd-viewer' />;
}

export default ReportViewer;
