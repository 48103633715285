import { ChangeEvent } from 'react';

/**
 * 입력 필드의 값을 변경할 때 호출되는 핸들러를 생성하는 함수
 * 
 * @param {React.Dispatch<React.SetStateAction<string>>} setCreateTitle - 입력 필드의 값을 업데이트하는 상태 변경 함수
 * @returns {(event: ChangeEvent<HTMLInputElement>) => void} 입력 값 변경 핸들러
 */
export const handleTitleText = (
  setCreateTitle: React.Dispatch<React.SetStateAction<string>>
): (event: ChangeEvent<HTMLInputElement>) => void => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setCreateTitle(event.target.value);
  };
};

/**
 * 색상을 선택했을 때 호출되는 핸들러를 생성하는 함수
 * 
 * @param {React.Dispatch<React.SetStateAction<string>>} setColor - 선택된 색상 값을 업데이트하는 상태 변경 함수
 * @returns {(color: { hex: string }) => void} 색상 선택 핸들러
 */
export const handleSelectColorHex = (
  setColor: React.Dispatch<React.SetStateAction<string>>
): (color: { hex: string; }) => void => {
  return (color: { hex: string }) => {
    setColor(color.hex);
  };
};

/**
 * 색상 피커 입력 필드의 값을 변경할 때 호출되는 핸들러를 생성하는 함수
 * 
 * @param {React.Dispatch<React.SetStateAction<string>>} setColor - 색상 값을 업데이트하는 상태 변경 함수
 * @returns {(event: ChangeEvent<HTMLInputElement>) => void} 색상 값 변경 핸들러
 */
export const handleSelectColorPicker = (
  setColor: React.Dispatch<React.SetStateAction<string>>
): (event: ChangeEvent<HTMLInputElement>) => void => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };
};

/**
 * 검색 입력 필드의 값을 변경할 때 호출되는 핸들러를 생성하는 함수
 * 
 * @param {React.Dispatch<React.SetStateAction<string>>} setSearchPipe - 검색 값을 업데이트하는 상태 변경 함수
 * @returns {(event: ChangeEvent<HTMLInputElement>) => void} 검색 값 변경 핸들러
 */
export const handleSearchText = (
  setSearchPipe: React.Dispatch<React.SetStateAction<string>>
): (event: ChangeEvent<HTMLInputElement>) => void => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPipe(event.target.value);
  };
};

/**
 * 입력 필드의 값을 변경할 때 호출되는 핸들러를 생성하는 함수
 * 
 * @param {React.Dispatch<React.SetStateAction<string>>} setSearchInput - 검색 입력 값을 업데이트하는 상태 변경 함수
 * @returns {(event: ChangeEvent<HTMLInputElement>) => void} 입력 값 변경 핸들러
 */
export const handleInputChange = (
  setSearchInput: React.Dispatch<React.SetStateAction<string>>
): (event: ChangeEvent<HTMLInputElement>) => void => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };
};

/**
 * 카테고리를 선택했을 때 호출되는 핸들러를 생성하는 함수
 * 
 * @param {React.Dispatch<React.SetStateAction<string>>} setSelectedCategory
 * - 선택된 카테고리를 업데이트하는 상태 변경 함수
 * @returns {(tag: string) => void} 카테고리 선택 핸들러
 */
export const handleSelectCategory = (
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>
): (tag: string) => void => {
  return (tag: string) => {
    setSelectedCategory((prev) => (prev === tag ? 'Tag' : tag));
  };
};

/**
 * 상태를 변경했을 때 호출되는 핸들러를 생성하는 함수
 * 
 * @param {React.Dispatch<React.SetStateAction<string>>} setStatusFilter - 상태 값을 업데이트하는 상태 변경 함수
 * @returns {(status: string) => void} 상태 변경 핸들러
 */
export const handleStatusChange = (
  setStatusFilter: React.Dispatch<React.SetStateAction<string>>
): (status: string) => void => {
  return (status: string) => {
    setStatusFilter((prev) => (prev === status ? 'Status' : status));
  };
};

/**
 * boolean 상태를 토글할 때 호출되는 핸들러를 생성하는 함수
 * 
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setState - boolean 상태 값을 업데이트하는 상태 변경 함수
 * @returns {() => void} 상태 토글 핸들러
 */
export const handleToggleState = (
  setState: React.Dispatch<React.SetStateAction<boolean>>
): () => void => {
  return () => {
    setState((prev) => !prev);
  };
};
