import React from 'react';
import { ClassicPreset, NodeId } from 'rete';
import { CustomSocket, NodeSize, SourceColor } from '../../style';
import CustomControl from '../../style/CustomControl';
import { ILayerOption, IMlopsflow } from '../flow';

const socket = new CustomSocket();

export class DatasetNode extends ClassicPreset.Node<
  Record<string, never>,
  { out: ClassicPreset.Socket },
  { ctrl: DatasetControl }
> {
  color = SourceColor;
  width = NodeSize.width;
  height = NodeSize.height;
  datasetResolution = 1000;

  constructor(
    process: () => void,
    update: (control: DatasetControl) => void,
    nodeId: NodeId
  ) {
    super('Dataset');
    this.id = nodeId === '' ? this.id : nodeId;
    this.addOutput('out', new ClassicPreset.Output(socket));
    this.addControl(
      'ctrl',
      new DatasetControl(process, update, this.id, this.datasetResolution)
    );
  }

  data(): { out: IMlopsflow } {
    const output = {
      datasetPath: {
        [this.id]: this.controls.ctrl.props.option.path,
      },
      layer: {
        input: [this.id],
        node: {
          dataset: this.controls.ctrl.props.option,
        },
      },
    };

    this.datasetResolution =
      Number(this.controls.ctrl.props.option.datasetResolution) ?? 1000;

    if (localStorage.getItem('dynamic') === 'true') {
      this.height = this.datasetResolution;
      const newWidth = Number(this.controls.ctrl.props.option.outChannel);
      this.width =
        newWidth < 222 || Number.isNaN(newWidth)
          ? 222
          : Number(this.controls.ctrl.props.option.outChannel);
    } else {
      this.height = 66;
      this.width = 222;
    }
    return {
      out: output,
    };
  }
}

export class DatasetControl extends ClassicPreset.Control {
  props: {
    option: ILayerOption;
  };
  contextOpen: boolean;
  datasetResolution: number;

  constructor(
    public onChange: () => void,
    public update: (c: DatasetControl) => void,
    public nodeId: NodeId,
    public resolution: number
  ) {
    super();
    this.props = {
      option: {
        nodeId,
        name: 'dataset',
        datasetResolution: 1000,
        outChannel: 3,
      },
    };
    this.contextOpen = false;
    this.datasetResolution = resolution;
  }

  setValue(data) {
    this.props.option = data;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    this.update(this);
  };
}

export function DatasetComponent({ data }: { data: DatasetControl }) {
  return (
    <CustomControl
      nodeId={data.nodeId}
      label='Dataset'
      iconSource='datahub'
      markerSource='node-source'
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
    />
  );
}
