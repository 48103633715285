export const btnData = [
  {
    title: 'VIENCE CANVAS',
    version: 'Soft-launch ver 0.1.0',
    desc: '고품질 연구를 위한 컴퓨팅기술과\n의생명과학의 연결을 실현시키는\n노코드 대용량 바이오메디컬\n영상데이터 AI 시각분석 플랫폼',
    btnBG: 'canvasBtnBg.png',
    btnColor: '#714DFF',
    url: '/vience-canvas',
  },
  {
    title: 'CellView',
    version: 'ver 0.1.0',
    desc: `신약 개발을 위한\nHigh-content screening 기반\n대규모 현미경 영상\n초고속 딥러닝 분석 솔루션`,
    btnBG: 'cellviewBtnBg.png',
    btnColor: '#8B0029',
    url: '/cellview',
  },
  {
    title: 'PathoView',
    version: 'ver 0.2.0',
    desc: `진단의 효율과 신뢰도를 높이는 
    비주얼프로그래밍 기반 
    대규모 AI 병리 진단 솔루션`,
    btnColor: '#8B0029',
    btnBG: 'pathoviewBtnBg.png',
    url: '/pathoview',
  },
];
