import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from './utils/Context';
import { CookiesProvider } from 'react-cookie';
import { NodeProvider } from './rete/NodeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useAPIwithCookies } from './hooks/useApiCookies';

import Home from './routes/home/home.component';
import Project from './routes/home/project/Project';
import Example from './routes/home/example/Example';

import Overview from './routes/overview/Overview';

import Register from './routes/usermanage/Register';
import Login from './routes/usermanage/Login';
import PrivacyPolicy from './routes/usermanage/PrivacyPolicy';

import Storage from './routes/datamanage/storage/Storage';
import FullImage from './routes/datamanage/image/FullImage';

import MyWorkspace from './routes/canvas/workspace/MyWorkspace';
import Diagram1 from './routes/canvas/diagram/diagram1.component';
import CanvasTemplate from './routes/canvas/canvasTemplate/CanvasTemplate';

import IntroVideo from './routes/introvideo/introvideo.component';
import Front from './routes/front/front.component';
import Main from './routes/main/main.component';
import PathoView from './routes/demo/pathoview.component';
import CellView from './routes/demo/cellview.component';
import XROps from './routes/demo/xrops.component';
import BrainView from './routes/demo/brainview.component';
import HieraSlide from './routes/demo/hieraslide.component';
import Teravoxel from './routes/demo/teravoxel.component';
import Recruit from './routes/recruit/recruit.component';

import MlopsWorkspace from './routes/mlops/workspace/MlopsWorkspace';
import MlopsEditor from './routes/mlops/editor/MlopsEditor';
import TrainDetail from './routes/mlops/train-detail/TrainDetail';
import MlopsCodeEditor from './routes/mlops/code-editor/MlopsCodeEditor';
import { WorkspaceContextProvider } from './context/WorkspaceContext';
import { TrainContextProvider } from './context/TrainContext';
import { useAuthContext } from './hooks/useAuthContext';
import MlopsEditorSample from './routes/mlops/editor/MlopsEditorSample';
import SmcPage from './routes/smc/SmcPage';
import SmcContxtProvider from './context/SmcContext';
import StorageContextProvider from './context/StorageContext';
import TaskImage from './routes/datamanage/image/TaskImage';

export const vw = window.innerWidth;
export const vh = window.innerHeight;

const App = () => {
  const { user, isAuthReady } = useAuthContext();
  const MultiContextProvider = ({ contexts, children }) =>
    contexts.reduce(
      (prev, context) =>
        React.createElement(context, {
          children: prev,
        }),
      children
    );
  const api = useAPIwithCookies();

  const [isSmc, setIsSmc] = useState(false);
  const check_account = async () => {
    const response = await api.get(
      '/smc_proofreading/check_account/' + user?.email
    );
    if (response.data === 1) {
      setIsSmc(true);
    } else {
      setIsSmc(false);
    }
  };
  useEffect(() => {
    check_account();
  }, [user]);

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY as string}
    >
      <MultiContextProvider
        contexts={[
          ThemeProvider,
          CookiesProvider,
          NodeProvider,
          TrainContextProvider,
          WorkspaceContextProvider,
          SmcContxtProvider,
          StorageContextProvider,
        ]}
      >
        {isAuthReady ? (
          <BrowserRouter>
            <Routes>
              <Route path='/vience-canvas' element={<Home />} />
              <Route path='/vience-canvas/project' element={<Project />} />
              <Route path='/vience-canvas/example' element={<Example />} />

              <Route
                path='/vience-canvas/login'
                element={
                  user?.access ? (
                    <Navigate replace={true} to={'/vience-canvas/'} />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route
                path='/vience-canvas/register'
                element={
                  user?.access ? (
                    <Navigate replace={true} to={'/vience-canvas/'} />
                  ) : (
                    <Register />
                  )
                }
              />
              <Route
                path='/vience-canvas/register/privacy-policy'
                element={
                  user?.access ? (
                    <Navigate replace={true} to={'/vience-canvas/'} />
                  ) : (
                    <PrivacyPolicy />
                  )
                }
              />
              <Route
                path='/vience-canvas/canvas'
                element={
                  user?.access ? (
                    <Diagram1 />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/canvas/:wor_id'
                element={
                  user?.access ? (
                    <Diagram1 />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/workspace'
                element={
                  user?.access ? (
                    <MyWorkspace />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/template'
                element={
                  user?.access ? (
                    <CanvasTemplate />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/storage/*'
                element={
                  user?.access ? (
                    <Storage />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/overview'
                element={
                  user?.access ? (
                    <Overview />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/image/*'
                element={
                  user?.access ? (
                    <FullImage />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/scheduler/:task_key/img.dzi' // 별표
                element={
                  user?.access ? (
                    <TaskImage />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />

              <Route
                path='/vience-canvas/mlops'
                element={
                  user?.access ? (
                    <MlopsWorkspace />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mlops/sample'
                element={<MlopsEditorSample />}
              />
              <Route
                path='/vience-canvas/mlops/:wor_id'
                element={
                  user?.access ? (
                    <MlopsEditor />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mlops/:wor_id/detail'
                element={
                  user?.access ? (
                    <TrainDetail />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mlops/code-editor'
                element={
                  user?.access ? (
                    <MlopsCodeEditor />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />
              <Route
                path='/vience-canvas/mlops/template'
                element={
                  user?.access ? (
                    <MlopsCodeEditor />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />

              <Route
                path='/vience-canvas/smc'
                element={
                  user?.access && isSmc ? (
                    <SmcPage />
                  ) : (
                    <Navigate replace={true} to={'/vience-canvas/login'} />
                  )
                }
              />

              <Route path='/' element={<Front />} />
              <Route path='/main' element={<Main />} />
              <Route path='/intro' element={<Main />} />
              <Route path='/pathoview' element={<PathoView />} />
              <Route path='/cellview' element={<CellView />} />
              <Route path='/intro_video' element={<IntroVideo />} />
              <Route path='/xrops' element={<XROps />} />
              <Route path='/brainview' element={<BrainView />} />
              <Route path='/teravoxel' element={<Teravoxel />} />
              <Route path='/hieraslide' element={<HieraSlide />} />
              <Route path='/recruit' element={<Recruit />} />
            </Routes>
          </BrowserRouter>
        ) : (
          <>loading</>
        )}
      </MultiContextProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
