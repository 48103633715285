import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { TrainContext } from '@/contexts/TrainContext';
import { handleImgError } from '@/utils/common/imageUtils';

const Container = styled.div`
  div.img-container {
    overflow: hidden;
  }
`;

export default function TrainResultImageItem({ label, data, imgStyle = null }) {
  const { setOpenDetail } = useContext(TrainContext);

  const handleClick = () => {
    if (!imgStyle) return;
    setOpenDetail({
      label: label,
      data: data,
    });
  };

  return (
    <Container className='train-result-img-item'>
      <h6>{label}</h6>
      <div className='img-container'>
        <img
          className='train-result-img'
          src={`data:image/jpeg;base64,${data}`}
          alt=''
          onError={handleImgError}
          style={imgStyle ?? {}}
          onClick={handleClick}
        />
      </div>
    </Container>
  );
}
