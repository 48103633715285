import React, { useState } from 'react';

import Header from '@/components/common/layouts/header/Header';
import WorkspaceMenu from './WorkspaceMenu';
import WorkspaceCards from './WorkspaceCards';
import { ShareModal } from '@/components/rete/workspace-panel.component';
import TemplateModal from './template/TemplateModal';

import { WorkspaceList } from '@/contexts/WorkspaceContext';

export default function MlopsWorkspace() {
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [templateModal, setTemplateModal] = useState<object | null>();
  const [workspaceList, setWorkspaceList] = useState<WorkspaceList[]>([]);

  return (
    <>
      <div
        className='bg-[#D3D3D3] dark:bg-[#282A2C]'
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <Header />
        <div className='main-content' style={{ display: 'flex' }}>
          <WorkspaceMenu setWorkspaceList={setWorkspaceList} />
          <div className='rounded-tl-[30px] bg-white100 dark:bg-black200 w-[calc(100%-17rem)] p-[20px]'>
            <WorkspaceCards
              setShareOpen={setShareOpen}
              setTemplateModalOpen={setTemplateModal}
              workspaceList={workspaceList}
              setWorkspaceList={setWorkspaceList}
            />
          </div>
        </div>

        <ShareModal open={shareOpen} onClose={() => setShareOpen(false)} wor_id='' />
      </div>
      {templateModal && (
        <TemplateModal workspace={templateModal} setTemplateModalOpen={setTemplateModal} />
      )}
    </>
  );
}
