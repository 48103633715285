import React, { useState, useEffect } from 'react';
import { ClassicPreset } from 'rete';
import { AddonColor, NodeSize } from '../../style/CustomNode';
import { NodeId } from 'rete';
import { AddonSocket } from '../../sockets';
import CustomControl from '../../style/CustomControl';
// import { updateControl } from '../../../reteUtils';

type TFormGeneraterProps = {
  value: string;
};

export class FormGeneraterNode extends ClassicPreset.Node<
  Record<string, never>,
  { addon: ClassicPreset.Socket },
  { ctrl: FormGeneraterControl }
> {
  color = AddonColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super('FormGenerater');
    this.addOutput('addon', new ClassicPreset.Input(new AddonSocket()));
    this.addControl('ctrl', new FormGeneraterControl(process, nodeId ? nodeId : this.id));
  }
  data(): { addon: { name: string; option: TFormGeneraterProps } } {
    return {
      addon: {
        name: 'FormGenerater',
        option: {
          value: this.controls.ctrl.props.option.value,
        },
      },
    };
  }
}

export class FormGeneraterControl extends ClassicPreset.Control {
  props: {
    option: any;
  };
  contextOpen: boolean;
  // status => 백그라운드에서 처리 상태
  // init: 처음 생성 / in-progress: 처리 중 / completed: 처리 완료 / error: 에러 발생생
  status: string; 

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      option: {
        value: [],
      },
    };
    this.contextOpen = false;
    this.status = 'init';
  }

  setValue(val: any) {
    this.props.option = val;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };

  setStatus = (status: string) => {
    this.status = status;
  };
}

export function FormGeneraterComp({ data }: { data: FormGeneraterControl }) {
  return (
    <CustomControl
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
      label='Form Generater'
      nodeId={data.nodeId}
      markerSource={'node-addon'}
      iconSource='feature'
      status={data.status}
      setStatus={data.setStatus}
    />
  );
}
