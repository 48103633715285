import React, { Dispatch, SetStateAction, useEffect, useState, useContext } from 'react';
import './workspace-panel.styles.scss';
import { InputWithLabel } from '../common/CustomInput';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IWorkspaceSetting } from '@/routes/canvas/workspace/workspace';
import CustomTextarea from '../common/CustomTextarea';
import CustomModal from '../common/CustomModal';
import { useCookies } from 'react-cookie';
import API from '@/apis/common/apis';
import { CustomThemeContext } from '@/contexts/common/Context';
import Swal from 'sweetalert2';
import CommingSoonAlert from '../common/alerts/CommingSoonAlert';
import { saveCanvasData } from './saveCanvasData';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { createToJson, createToObject } from './workspace';

interface WorkspaceNameProps {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  save: () => void;
  setSetting: Dispatch<SetStateAction<IWorkspaceSetting>>;
  setting: IWorkspaceSetting;
}

interface IShareList {
  editable: string[];
  read_only: string[];
}

export default function WorkspacePanel({
  title,
  setTitle,
  save,
  setting,
  setSetting,
}: WorkspaceNameProps) {
  const { theme } = useContext(CustomThemeContext);
  const [settingOpen, setSettingOpen] = useState<boolean>(false);
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const pathArr = location.pathname.split('/');
  pathArr.pop();
  let pathStr = pathArr.join('/');
  pathStr = pathStr === '/vience-canvas/canvas' ? '/vience-canvas/workspace' : pathStr;

  const handleSubmitSetting = () => {
    handleSave();
    setSettingOpen(false);
  };

  //
  const api = useAPIwithCookies();

  const handleSave = async () => {
    const json = await createToJson();
    if (json === 'exit') {
      return;
    }
    if (json !== null && json !== undefined) {
      const result = await saveCanvasData(
        api,
        params.wor_id,
        json,
        title,
        setting.category,
        setting.description,
      );

      Swal.fire({
        title: 'Success',
        text: 'Saved',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      console.error('json is null, unable to save.');
    }
};

  return (
    <div className='workspace-panel-box'>
      <button
        className='workspace-panel-button workspace-panel-left'
        onClick={() => {
          navigate(pathStr);
        }}
      >
        <img
          src={
            process.env.PUBLIC_URL + theme === 'dark'
              ? '/images/canvas-back.svg'
              : '/images/canvas-back-white.svg'
          }
          alt=''
        />
        <span>Back</span>
      </button>

      <div className='workspace-panel-middle'>
        <span>Title /</span>
        <InputWithLabel value={title} onChange={setTitle} />
      </div>

      <div className='workspace-panel-button-wrapper workspace-panel-right'>
        <button className='workspace-panel-button' onClick={() => handleSave()}>
          <img
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/canvas-save.svg'
                : '/images/canvas-save-white.svg'
            }
            alt=''
          />
          <span>Save</span>
        </button>
        <button
          className='workspace-panel-button'
          onClick={() => {
            setSettingOpen(true);
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL + theme === 'dark'
                ? '/images/canvas-settings.svg'
                : '/images/canvas-settings-white.svg'
            }
            alt=''
          />
          <span>Setting</span>
        </button>
        <CommingSoonAlert>
          <button
            className='workspace-panel-button'
            onClick={() => {
              setShareOpen(true);
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL + theme === 'dark'
                  ? '/images/canvas-share.svg'
                  : '/images/canvas-share-white.svg'
              }
              alt=''
            />
            <span>Share</span>
          </button>
        </CommingSoonAlert>
        <CommingSoonAlert>
          <button
            className='workspace-panel-button'
            onClick={() => {
              setPublishOpen(true);
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL + theme === 'dark'
                  ? '/images/canvas-play.svg'
                  : '/images/canvas-play-white.svg'
              }
              alt=''
            />
            <span>Publish</span>
          </button>
        </CommingSoonAlert>
      </div>

      <CustomModal
        bgShown='blur'
        open={settingOpen}
        onClose={() => {
          setSettingOpen(false);
        }}
      >
        <div className='workspace-panel-modal-wrapper'>
          <div className='workspace-panel-modal-section bg-[#F0F2FE] dark:bg-[#060A20]'>
            <InputWithLabel
              value={setting.category}
              onChange={(e) => {
                setSetting({ ...setting, category: e });
              }}
              label='category'
            />
            {/* <InputWithLabel
              value={setting.ipAddress}
              onChange={(e) => {
                setSetting({ ...setting, ipAddress: e });
              }}
              label='ip address'
            /> */}
            <CustomTextarea
              label='description'
              value={setting.description}
              onChange={(e) => {
                setSetting({ ...setting, description: e });
              }}
            />
          </div>
          <div className='workspace-panel-modal-save cursor-pointer' onClick={handleSubmitSetting}>
            <button className='workspace-panel-modal-save-btn'>save</button>
          </div>
        </div>
      </CustomModal>

      {/* <CustomModal
        bgShown='blur'
        open={publishOpen}
        onClose={() => {
          setPublishOpen(false);
        }}
      >
        <div className='workspace-panel-modal-wrapper'>
          <div className='workspace-panel-modal-section'>
            <CustomTextarea
              label='request'
              onChange={(e) => {
                //
              }}
            />
          </div>
          <div className='workspace-panel-modal-save'>
            <button className='workspace-panel-modal-save-btn' onClick={save}>
              save
            </button>
          </div>
        </div>
      </CustomModal> */}
      <ShareModal
        open={shareOpen}
        onClose={() => {
          setShareOpen(false);
        }}
        wor_id={params.wor_id}
      />
    </div>
  );
}

interface UserNameProps {
  mem_id: string;
  shareList: IShareList;
  setShareList: Dispatch<SetStateAction<IShareList>>;
}

interface ShareModalProps {
  open: boolean;
  onClose: () => void;
  wor_id?: string;
}

export function ShareModal({ open, onClose, wor_id }: ShareModalProps) {
  const [cookies, setCookie, removeCookie] = useCookies(['refresh']);
  const api = new API(cookies);
  const [shareList, setShareList] = useState<IShareList>({
    editable: [],
    read_only: [],
  });
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [searchUser, setSearchUser] = useState<string>('');
  const [searchUserList, setSearchUserList] = useState<string[]>([]);
  const isSample = window.location.href.includes('sample');

  const shareWorkspace = async () => {
    if (isSample) return;
    if (deleteList.length !== 0) {
      const response_delete = await api.delete('/workspace/delete_share', {
        wor_id: wor_id,
        mem_list: deleteList.join(','),
      });
    }
    const response_share = await api.post('/workspace/share', {
      wor_id: wor_id,
      editable: shareList.editable,
      read_only: shareList.read_only,
    });
    getShareList();
  };

  const getShareList = async () => {
    if (isSample) return;
    const response = await api.get(`/workspace/share_list/${wor_id}`);
    setShareList(response.data);
  };

  const deleteSharedUser = (type: 'editable' | 'read_only', mem_id: string) => {
    if (isSample) return;
    const temp = shareList[type];
    setShareList({ ...shareList, [type]: temp.filter((el) => el !== mem_id) });
    setDeleteList([...deleteList, mem_id]);
  };

  const getUserList = async () => {
    if (isSample) return;
    const response = await api.get(`/member/search`, {
      keyword: searchUser,
    });
    setSearchUserList(response.data);
  };

  useEffect(() => {
    if (isSample) return;
    if (wor_id !== '') getShareList();
  }, [wor_id]);

  useEffect(() => {
    if (isSample) return;
    if (searchUser !== '') getUserList();
    else setSearchUserList([]);
  }, [searchUser]);

  useEffect(() => {
    setSearchUserList([]);
  }, [open]);

  return (
    <CustomModal bgShown='blur' open={open} onClose={onClose}>
      <div className='workspace-panel-modal-wrapper'>
        <div id='workspace-panel-handleuser'>
          <div className='workspace-panel-handleuser-section'>
            <h6>Editable Users</h6>
            <div className='workspace-panel-sharelist'>
              {shareList.editable.map((data) => (
                <button
                  onClick={() => {
                    deleteSharedUser('editable', data);
                  }}
                  className='workspace-panel-shareduser'
                >
                  {data}
                </button>
              ))}
            </div>
          </div>
          <div className='workspace-panel-handleuser-section'>
            <h6>ReadOnly Users</h6>
            <div className='workspace-panel-sharelist'>
              {shareList.read_only.map((data) => (
                <button
                  onClick={() => {
                    deleteSharedUser('read_only', data);
                  }}
                  className='workspace-panel-shareduser'
                >
                  {data}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div id='workspace-panel-searchuser'>
          <InputWithLabel
            label='Search User'
            onChange={(e) => {
              setSearchUser(e);
            }}
          />
          {searchUserList.length === 0 ? (
            <p>User Not Found</p>
          ) : (
            <>
              {searchUserList.map((mem_id) => (
                <UserName mem_id={mem_id} shareList={shareList} setShareList={setShareList} />
              ))}
            </>
          )}
        </div>
        <div className='workspace-panel-modal-save'>
          <button className='workspace-panel-modal-save-btn' onClick={shareWorkspace}>
            save
          </button>
        </div>
      </div>
    </CustomModal>
  );
}

const UserName = ({ mem_id, shareList, setShareList }: UserNameProps) => {
  const handleAddToList = (type: 'editable' | 'read_only') => {
    if (type === 'editable') {
      setShareList({
        read_only: [...shareList.read_only.filter((el) => el !== mem_id)],
        editable: [...shareList.editable, mem_id],
      });
    } else {
      setShareList({
        editable: [...shareList.editable.filter((el) => el !== mem_id)],
        read_only: [...shareList.read_only, mem_id],
      });
    }
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', margin: 5 }}>
      <p>{mem_id}</p>
      <div>
        <button
          onClick={() => {
            handleAddToList('editable');
          }}
        >
          Editable
        </button>
        <button
          onClick={() => {
            handleAddToList('read_only');
          }}
        >
          ReadOnly
        </button>
      </div>
    </div>
  );
};
