import React, { useContext, useState, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import { categoryList } from './mockData';

import styled from 'styled-components';
import NewWorkspaceBtn from './NewWorkspaceBtn';

import { CustomThemeContext } from '@/contexts/common/Context';
import { WorkspaceList } from '@/contexts/WorkspaceContext';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
// import LeftPanel from '../../../components/leftPanel/LeftPanel';
import PrimaryButton from '@/components/common/buttons/PrimaryButton';

const LeftPanel = styled.div`
  overflow: auto;
  width: 21.875rem;
  height: calc(100vh - 4.5rem);
  background: #080821;
  ul {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    li {
      &:first-child {
        margin-top: var(--20px);
      }
      &:hover {
        background-color: #8d59fc44;
      }
      width: 100%;
      padding: var(--12px);
      font-size: var(--16px);
      font-weight: 700;
      &.selected {
        color: #8d59fc;
      }
    }
  }
  input {
    display: none;
  }
`;

const Container = styled(LeftPanel)<{ $theme: 'light' | 'dark' }>`
  .btn-secondary {
    margin-bottom: var(--20px);
  }
  .btn-primary {
    margin-top: var(--20px);
  }
  .train-result-access {
    button {
      width: 100%;
      padding: var(--12px);
      padding-right: var(--24px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${(props) =>
        props.$theme === 'light' ? '#080821' : '#fff'} !important;
      font-weight: 700;
      span {
        transition: all 0.3s;
      }
      ${(props) =>
        props.$theme === 'light'
          ? `
        border-top: 1px solid #D9D9E5;
        border-bottom: 1px solid #D9D9E5;
      `
          : `
        border-top: 1px solid #322686;
        border-bottom: 1px solid #322686;
      `}
    }

    &:hover {
      background-color: #8d59fc44;

      span {
        transform: translateX(var(--12px));
      }
    }
  }
`;

export const workspaceCategory = 'All';

type WorkspaceMenuProps = {
  setWorkspaceList: Dispatch<SetStateAction<WorkspaceList[]>>;
};

function WorkspaceMenu({ setWorkspaceList }: WorkspaceMenuProps) {
  const api = useAPIwithCookies();
  const { theme } = useContext(CustomThemeContext);
  const [w_category, setWCategory] = useState('All');

  const getWorkspaceList = async (category: WorkspaceList) => {
    const { data } = await api.get(`/mlops_workspace/list/${category}`);
    return data;
  };

  const handleCategory = async (category) => {
    workspaceCategory = category;
    setWCategory(category);
    const workspaceInCategory = await getWorkspaceList(category.toLowerCase());
    setWorkspaceList(workspaceInCategory);
  };

  return (
    <div
      className='h-[calc(100vh-4.5rem)] w-[17rem] overflow-auto
     bg-gray100 pr-[10px] text-[16px] dark:bg-black100'
    >
      <h2 className='a11y-hidden'>workspace menu list</h2>
      <div className='new-workspace-buttons'>
        <h3 className='a11y-hidden'>new workspace buttons</h3>
        <NewWorkspaceBtn />
        <Link to={`/vience-canvas/mlops/code-editor`}>
          <PrimaryButton type='button' className='btn-primary'>
            Start with My Code
          </PrimaryButton>
        </Link>
      </div>

      <div className='flex justify-center items-center'>
        <Link to={`/vience-canvas/mlops/_/detail`}>
          <button
            type='button'
            className='flex justify-center items-center  py-2 bg-[#b8b8b8] dark:bg-[#131313] mt-4 px-4 ml-2 rounded-md'
          >
            <p>View Train Session List </p>
            <span className='ml-2 text-black dark:text-white'>&gt;</span>
          </button>
        </Link>
      </div>

      <div className='mt-4'>
        <h3 className='a11y-hidden'>new workspace category buttons</h3>
        <ul className='ml-[18px] flex flex-col'>
          {categoryList.map((category) => (
            <li
              key={category}
              className={`w-[94%] rounded-full p-[var(--12px)] text-[var(--16px)] cursor-pointer 
            ${
              category === w_category
                ? 'font-black text-purple200'
                : 'text-black300 dark:text-white'
            } 
            hover:text-gray300 dark:hover:text-gray200`}
            >
              <button
                className='workspace-category-card'
                id={`workspace-category-${category}`}
                onClick={() => handleCategory(category)}
              >
                {category} Workspace
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default React.memo(WorkspaceMenu);
