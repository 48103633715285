import React, { useContext } from 'react';
import { CustomThemeContext } from '@/contexts/common/Context';

export default function Mode() {
  const { theme, toggleTheme } = useContext(CustomThemeContext);

  return (
    <button onClick={toggleTheme} className='bg-transparent'>
      {theme === 'dark' ? (
        <div className='flex items-center justify-start gap-[var(--10px)]'>
          <img
            src={process.env.PUBLIC_URL + '/images/ico-dark-white.svg'}
            alt='Dark Mode'
            className='block'
          />
          <span className='font-medium text-[#f8f2ff] text-[var(--14px)]'></span>
        </div>
      ) : (
        <div className='flex items-center justify-start gap-[var(--10px)]'>
          <img
            src={process.env.PUBLIC_URL + '/images/ico-light-black.svg'}
            alt='Light Mode'
            className='block'
          />
          <span className='font-medium text-[#f8f2ff] text-[var(--14px)]'></span>
        </div>
      )}
    </button>
  );
}
