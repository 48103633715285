import React, { ReactNode, useEffect } from 'react';
import './CustomComp.scss';

interface InputWithChildrenProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  id?: string;
  onChange: (e: any) => void;
  alert?: boolean;
  alertLabel?: string;
  setAlert?: (e: boolean) => void;
  containerWidth?: string | number;
  childWidthRato?: [number | string, number | string];
  className: string;
}

export function InputWithChildren({
  onChange,
  className,
  children,
  ...rest
}: InputWithChildrenProps) {
  return (
    <div className={className} style={{ width: '100%', position: 'relative' }}>
      <input onChange={(e) => onChange(e.target.value)} {...rest} />
      <div>{children}</div>
    </div>
  );
}

interface InputWithLabelProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onChange: (e: any) => void;
  inputNum?: number;
  desc?: string;
}

export function InputWithLabel({ onChange, label, desc, inputNum, ...rest }: InputWithLabelProps) {
  return (
    <div className='custom-inputwithlabel-container'>
      {label && (
        <label className='custom-inputwithlabel-label text-[16px] text-[white] pb-3'>{label}</label>
      )}
      {desc && <p className='desc'>{desc}</p>}
      {inputNum ? (
        Array(inputNum)
          .fill(0)
          .map((_, index) => (
            <input
              key={index}
              style={{ width: '100%' }}
              onChange={(e) => onChange(e.target.value)}
              {...rest}
            />
          ))
      ) : (
        <input
          className='rounded-[7px] pl-2.5 dark:text-[red] w-full'
          onChange={(e) => onChange(e.target.value)}
          {...rest}
        />
      )}
    </div>
  );
}

interface InputWithAlertProps {
  children: ReactNode;
  alert: boolean;
  alertMessage: string;
  setAlert: (e: boolean) => void;
}

export const InputWithAlert = ({
  children,
  alert,
  alertMessage,
  setAlert,
}: InputWithAlertProps) => {
  useEffect(() => {
    if (alert && setAlert) {
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  }, [alert]);

  return (
    <>
      <div className='register-info-input'>{children}</div>
      <p className='input-comment'>{alert ? alertMessage : ''}</p>
    </>
  );
};
