import React from 'react';
import styled from 'styled-components';
import { copyNode, deleteNode } from '../reteMlopsUtils';
import { NodeId } from 'rete';
import './CustomNode.scss';
import NodeContextMenu from '../../rete/components/style/NodeContextMenu';

const ControlStyles = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img.label-icon {
    margin-left: 31px;
  }
  ${(props) => {
    return props.$styles;
  }}
`;

const Marker = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
`;

const VerticalMenu = styled.button`
  position: absolute;
  right: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 31px;
`;

interface CustomControlProps {
  label: string;
  nodeId: NodeId;
  markerSource: 'node-source' | 'node-effector' | 'node-sink' | 'node-addon';
  iconSource: string;
  contextOpen: boolean;
  setContextOpen: (e: boolean) => void;
}

export default function CustomControl({
  label,
  nodeId,
  markerSource,
  iconSource,
  contextOpen,
  setContextOpen,
}: CustomControlProps) {
  let theme = localStorage.getItem('theme');
  if (theme === null) theme = 'dark';

  return (
    <ControlStyles>
      <Marker
        src={
          process.env.PUBLIC_URL +
          `/images/rete/node/marker/${markerSource}.svg`
        }
        alt={`marker-${markerSource}`}
      />
      <img
        className='label-icon'
        src={
          process.env.PUBLIC_URL + theme === 'dark'
            ? `/images/rete/node/icon/${iconSource}.svg`
            : `/images/rete/node/icon/${iconSource}-black.svg`
        }
        alt={`icon-${iconSource}`}
      />
      <p
        style={{
          color: theme === 'dark' ? '#FFFFFF' : '#2D2626',
          marginLeft: 10,
          fontWeight: 800,
        }}
      >
        {label}
      </p>
      <VerticalMenu
        onPointerDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onClick={() => {
          setContextOpen(!contextOpen);
        }}
      >
        <img
          className='context-menu-icon'
          src={process.env.PUBLIC_URL + '/images/menu-vertical.svg'}
          alt='Node Menu Button'
        />
      </VerticalMenu>
      {contextOpen && (
        <NodeContextMenu
          nodeId={nodeId}
          onClose={setContextOpen}
          functions={[
            {
              label: 'Delete',
              on: () => {
                deleteNode(nodeId);
              },
            },
            {
              label: 'Copy',
              on: () => {
                copyNode(nodeId);
              },
            },
          ]}
        />
      )}
    </ControlStyles>
  );
}
