import React, { useEffect, useState } from 'react';
import { area, editor } from '@/components/rete-mlops/editor';
import { Control } from 'rete/_types/presets/classic';
import { ClassicPreset } from 'rete';
import {
  Convolution2dControl,
  Convolution2dPanelContent,
  DatasetControl,
  DatasetPanelContent,
  TrainerPanelContent,
  TrainerControl,
  MaxPool2dControl,
  MaxPool2dPanelContent,
  BatchNorm2dControl,
  BatchNorm2dPanelContent,
  ConvTranspose2dControl,
  ConvTranspose2dPanelContent,
  ReluActivationPanelContent,
  ReluActivationControl,
  CatControl,
  CatPanelContent,
  FullyConnectedControl,
  FullyConnectedPanelContent,
  FineTunerControl,
  FineTunerPanelContent,
  FeatureVisualizerControl,
  FVPanelContent,
} from '@/components/rete-mlops/components';

const getPickedNode = async (nodeId: string) => {
  if (!editor) return;
  const pickedNode = editor.getNode(nodeId);
  if (!pickedNode) return;
  const pickedNodeType: string = pickedNode.label;
  const pickedNodeCtrl: ClassicPreset.Control = pickedNode.controls.ctrl;
  return { pickedNodeType, pickedNodeCtrl };
};

export default function MlopsSidePanelContent({ setPanelMode }) {
  const [nodeId, setNodeId] = useState('');
  const [pickedNodeType, setPickedNodeType] = useState<string>();
  const [panelContent, setPanelContent] = useState<any>();

  const getPanelContent = async (
    pickedNodeType: string,
    pickedNodeCtrl: ClassicPreset.Control
  ) => {
    switch (pickedNodeType) {
      case 'Dataset':
        return <DatasetPanelContent ctrl={pickedNodeCtrl as DatasetControl} />;
      case 'Convolution 2D':
        return (
          <Convolution2dPanelContent
            ctrl={pickedNodeCtrl as Convolution2dControl}
          />
        );
      case 'Trainer':
        return (
          <TrainerPanelContent
            ctrl={pickedNodeCtrl as TrainerControl}
            setPanelMode={setPanelMode}
          />
        );
      case 'MaxPool 2D':
        return (
          <MaxPool2dPanelContent ctrl={pickedNodeCtrl as MaxPool2dControl} />
        );
      case 'Relu Activation':
        return (
          <ReluActivationPanelContent
            ctrl={pickedNodeCtrl as ReluActivationControl}
          />
        );
      case 'BatchNorm 2D':
        return (
          <BatchNorm2dPanelContent
            ctrl={pickedNodeCtrl as BatchNorm2dControl}
          />
        );
      case 'ConvTranspose 2D':
        return (
          <ConvTranspose2dPanelContent
            ctrl={pickedNodeCtrl as ConvTranspose2dControl}
          />
        );
      case 'Cat':
        return <CatPanelContent ctrl={pickedNodeCtrl as CatControl} />;
      case 'Fully Connected':
        return (
          <FullyConnectedPanelContent
            ctrl={pickedNodeCtrl as FullyConnectedControl}
          />
        );
      case 'FineTuner':
        return (
          <FineTunerPanelContent
            ctrl={pickedNodeCtrl as FineTunerControl}
            setPanelMode={setPanelMode}
          />
        );
      case 'FeatureVisualizer':
        return (
          <FVPanelContent ctrl={pickedNodeCtrl as FeatureVisualizerControl} />
        );
    }
  };

  if (area) {
    area.addPipe((context) => {
      if (context.type === 'nodepicked') {
        const pickedId = context.data.id;
        setNodeId(pickedId);
      }
      return context;
    });
  }

  useEffect(() => {
    const makePanelContent = async () => {
      const pickedNodeData = await getPickedNode(nodeId);
      const type = pickedNodeData?.pickedNodeType;
      const ctrl = pickedNodeData?.pickedNodeCtrl;
      const panelContentTemp = await getPanelContent(
        type as string,
        ctrl as Control
      );
      setPickedNodeType(type as string);
      setPanelContent(panelContentTemp);
    };
    makePanelContent();
    setPanelMode('normal');
  }, [nodeId]);

  return (
    <>
      {pickedNodeType && <h2 className='node-type'>{pickedNodeType} : </h2>}
      {panelContent}
    </>
  );
}
