import React, { useState, useEffect, useContext } from 'react';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { CustomThemeContext } from '@/contexts/common/Context';
import Swal from 'sweetalert2';
import CustomProcessingDirectory from './CustomProcessingDirectory';
import CustomProcessingFile from './CustomProcessingFile';
import { IfileNdirList } from '@/types/common/type';

interface ImageProcessingPanelProps {
  ctrl: any;
  expand?: any;
  nodeId?: any;
  setPanelMode?: any;
}
const methodDescriptions: Record<string, string> = {
  Resize:
    'This method changes the image dimensions to a specified width and height using a chosen interpolation technique.',
  Crop: 'This method extracts a specified rectangular region from the image.',
  'Median Filter':
    'This method replaces each pixel with the median of neighboring pixel values to reduce noise while preserving edges.',
  'Histogram Equalization':
    'This method adjusts the image intensities to better distribute the pixel intensity values.',
  'Gaussian Blur':
    'This method applies a Gaussian function to blur the image and reduce high-frequency components.',
  'Bilateral Filter':
    'This method smooths the image while preserving edges by considering both spatial and intensity differences.',
  'Canny Edge Detection':
    'This method detects edges by computing the gradient and using double-threshold hysteresis to refine the edge map.',
  Dilation:
    'This method grows the boundaries of foreground regions in a binary image, making objects more visible.',
  Erosion:
    'This method shrinks the boundaries of foreground regions, removing small noise and disconnecting narrow bridges.',
  'Morphological Opening':
    'This method erodes then dilates the image to remove small objects or noise while preserving overall shape.',
  'Morphological Closing':
    'This method dilates then erodes the image to fill small holes or gaps in the foreground objects.',
  'Sharpening (Unsharp Mask)':
    'This method highlights fine details by subtracting a blurred copy of the image from the original.',
  'Color Type Convert':
    'This method converts the image from one color space to another, such as RGB to HSV or BGR to LAB.',
  'Color Correction':
    'This method modifies color distribution (or luminance) for better color accuracy or specific aesthetic purposes.',
  'Color Transfer':
    'This method adjusts the color distribution of one image to match the color distribution of another, often by aligning means and standard deviations in a chosen color space.',
  Normalization:
    'This method rescales pixel values to a specified range (e.g. 0 to 1, or 0 to 255), often used to facilitate consistent input for machine learning or other algorithms.',
  Standardization:
    'This method transforms pixel values to have zero mean and unit variance (or a predefined mean and std), aiding in models sensitive to data scale.',
};

const methodConfig: Record<string, { name: string; type: string; options?: string[] }[]> = {
  Resize: [
    { name: 'new_width', type: 'number' },
    { name: 'new_height', type: 'number' },
    { name: 'interpolation_method', type: 'select', options: ['nearest', 'linear', 'cubic'] },
  ],
  Crop: [
    { name: 'x', type: 'number' },
    { name: 'y', type: 'number' },
    { name: 'width', type: 'number' },
    { name: 'height', type: 'number' },
  ],
  'Median Filter': [{ name: 'kernel_size', type: 'number' }],
  'Histogram Equalization': [],
  'Gaussian Blur': [
    { name: 'kernel_size', type: 'number' },
    { name: 'sigma', type: 'number' },
  ],
  'Bilateral Filter': [
    { name: 'd', type: 'number' },
    { name: 'sigma_color', type: 'number' },
    { name: 'sigma_space', type: 'number' },
  ],
  'Canny Edge Detection': [
    { name: 'threshold1', type: 'number' },
    { name: 'threshold2', type: 'number' },
  ],
  Dilation: [
    { name: 'kernel_size', type: 'number' },
    { name: 'iterations', type: 'number' },
  ],
  Erosion: [
    { name: 'kernel_size', type: 'number' },
    { name: 'iterations', type: 'number' },
  ],
  'Morphological Opening': [
    { name: 'kernel_size', type: 'number' },
    { name: 'iterations', type: 'number' },
  ],
  'Morphological Closing': [
    { name: 'kernel_size', type: 'number' },
    { name: 'iterations', type: 'number' },
  ],
  'Sharpening (Unsharp Mask)': [
    { name: 'kernel_size', type: 'number' },
    { name: 'amount', type: 'number' },
    { name: 'threshold', type: 'number' },
  ],
  'Color Type Convert': [
    { name: 'source_color_space', type: 'select', options: ['GRAY', 'BGR', 'RGB', 'HSV', 'LAB'] },
    { name: 'target_color_space', type: 'select', options: ['GRAY', 'BGR', 'RGB', 'HSV', 'LAB'] },
  ],
  'Color Correction': [
    { name: 'brightness', type: 'number' },
    { name: 'contrast', type: 'number' },
    { name: 'gamma', type: 'number' },
  ],
  'Color Transfer': [
    {
      name: 'method',
      type: 'select',
      options: [
        'Histogram Matching',
        'Reinhard',
        'H&E Structure-Preserving Color Normalization (SPCN)',
      ],
    },
    { name: 'color_space', type: 'select', options: ['RGB', 'LAB', 'LCH'] },
  ],
  Normalization: [
    { name: 'range_min', type: 'number' },
    { name: 'range_max', type: 'number' },
    { name: 'axis', type: 'select', options: ['channel-wise', 'global'] },
  ],
  Standardization: [
    { name: 'mean', type: 'number' },
    { name: 'std', type: 'number' },
    { name: 'axis', type: 'select', options: ['channel-wise', 'global'] },
  ],
};

export function ImageProcessingPanel({ ctrl, nodeId, setPanelMode }: ImageProcessingPanelProps) {
  const api = useAPIwithCookies();
  const { theme } = useContext(CustomThemeContext);

  // 모달 관련
  const [fileNdirList_Normal, setFileNdirList_Normal] = useState<IfileNdirList[]>([]);
  const [selected, setSelected] = useState<{
    path: string;
    name: string;
    thumbnail: any;
    dataType?: any;
  }>({ path: '', name: '', thumbnail: '', dataType: '' });

  const [curDir, setCurDir] = useState<string>('');
  const [fileAttribute, setFileAttribute] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetImg, setTargetImg] = useState(ctrl.props.option.params?.target_path);

  const getFileDirList = async () => {
    try {
      const response = await api.get('/cloud/list', { cur_dir: '' });
      setFileNdirList_Normal(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFileDirList();
  }, [curDir]);

  const handleConfirm = () => {
    // 여기서 나중에 사진인지 이런거 분기처리 하면 좋을듯 함 ㅇㅇ

    setIsModalOpen(false);

    if (curDir + '/' + selected.name) {
      setTargetImg(curDir + '/' + selected.name);
    } else {
      alert('정확한 이미지지 경로가 아닙니다.');
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [selectedMethod, setSelectedMethod] = useState<string>(ctrl.props.option.method);
  setPanelMode;
  const [params, setParams] = useState<Record<string, number | string>>(ctrl.props.option.params);

  const [inputImage, setInputImage] = useState<any>(ctrl.props.option.inputImage);
  const [outputImage, setOutputImage] = useState<any>(ctrl.props.option.outputImage);

  // 메서드 변경 시 기존 값 초기화
  const handleMethodChange = (method: string) => {
    setSelectedMethod(method);
    setParams({});
  };

  // 값 변경 핸들러
  const handleParamChange = (name: string, value: string | number) => {
    setParams((prev) => ({ ...prev, [name]: value }));
  };

  // 갱신
  useEffect(() => {
    ctrl.setValue({
      method: selectedMethod,
      params: params,
      inputImage: inputImage,
      outputImage: outputImage,
    });
  }, [selectedMethod, params, inputImage, outputImage]);

  useEffect(() => {
    if (selectedMethod) {
      const newParams: Record<string, any> = {};

      methodConfig[selectedMethod].forEach((param) => {
        if (!(param.name in params)) {
          newParams[param.name] = param.type === 'number' ? 1 : param.options?.[0] || '';
        }
      });

      if (Object.keys(newParams).length > 0) {
        setParams((prev) => ({
          ...prev,
          ...newParams,
        }));
      }
    }
  }, [selectedMethod, methodConfig]); // selectedMethod 변경 시 실행

  useEffect(() => {
    setSelectedMethod(ctrl.props.option.method);
    setParams(ctrl.props.option.params);
    setInputImage(ctrl.props.option.inputImage);
    setOutputImage(ctrl.props.option.outputImage);
  }, [ctrl.id]);

  const [isLoading, setIsLoading] = useState(false);

  const submitMethodData = async () => {
    setIsLoading(true); // 로딩 시작

    const updatedParams =
      selectedMethod === 'Color Transfer'
        ? { ...params, target_path: targetImg } // 타겟 이미지로 설정
        : params;

    setParams(updatedParams);

    try {
      const response = await api.post(`/viewer/image_processing`, {
        module: {
          wor_id: params['wor_id'],
          img_paths: ctrl.props.option.value.img_paths,
          effector: ctrl.props.option.value.effector,
        },
        methods: selectedMethod,
        parameters: updatedParams,
      });

      if (response.status === 200) {
        setInputImage(response.data.result.input);
        setOutputImage(response.data.result.output);
        setPanelMode('expand');
      }
    } catch (error: any) {
      if (error.detail) {
        Swal.fire({
          title: 'Failed',
          text: `${error.detail}`,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'Confirm',
        });
      }
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };

  const handleOpenModal = () => {
    setPanelMode('expand');
    setIsModalOpen(true);
  };

  return (
    <>
      <div className='flex flex-col w-full h-full p-5'>
        {/* <h2 className='text-lg font-semibold dark:text-white'>Image Processing</h2>
        <p className='text-sm text-gray-500 mt-2'>Select a method and configure its parameters.</p>

        <hr className='mt-4 border-r-2 dark:border-[#242627]' /> */}
        {/* 메서드 선택 */}
        {/* <h2 className='text-lg font-semibold dark:text-white mt-4'>Method</h2> */}
        <div className='mb-4'>
          <div className='flex items-center gap-2'>
            <img
              src={
                process.env.PUBLIC_URL +
                (theme === 'dark'
                  ? '/images/rete/node/icon/chart.svg'
                  : '/images/rete/node/icon/chart-black.svg')
              }
              alt='Data Selector Icon'
            />
            <div className='text-2xl font-extrabold dark:text-[white]'>Image Processing</div>
          </div>
          <div className='text-lg font-light mt-1 dark:text-[white]'>Manipulate the Image</div>
        </div>

        <div
          className='subtitle mt-[var(--10px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          {' '}
          Select Method
        </div>

        <select
          className='w-full mt-4 px-3 py-2 rounded-md dark:bg-[#131313] dark:text-white'
          value={selectedMethod}
          onChange={(e) => handleMethodChange(e.target.value)}
        >
          {Object.keys(methodConfig).map((method) => (
            <option key={method} value={method}>
              {method}
            </option>
          ))}
        </select>

        {/* 선택된 메서드에 대한 폼 생성 */}
        {selectedMethod && (
          <>
            <div className='mt-4 p-4 rounded-md bg-[#ffffff] dark:bg-[#131313] dark:text-white'>
              <h3 className='text-base font-semibold mb-4'>{methodDescriptions[selectedMethod]}</h3>
              {methodConfig[selectedMethod].map((param) => (
                <div key={param.name} className='mt-4'>
                  <label className='block text-sm mb-1'>{param.name}</label>
                  {param.type === 'select' ? (
                    <select
                      className='w-full px-2 py-2 border rounded-md bg-[#E9EBF8] border-black dark:border-white dark:bg-[#131313]'
                      value={params[param.name] || ''}
                      onChange={(e) => handleParamChange(param.name, e.target.value)}
                    >
                      {param.options?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type='number'
                      className='w-full px-2 py-2 border rounded-md bg-[#ffffff] border-black dark:border-white dark:bg-[#131313]'
                      value={params[param.name] ?? ''}
                      onChange={(e) => handleParamChange(param.name, Number(e.target.value))}
                    />
                  )}
                </div>
              ))}
              {selectedMethod === 'Color Transfer' && (
                <div className='mt-4'>
                  <label className='block text-sm mb-2'>target image</label>
                  <div
                    className='w-full h-[40px] bg-[#231f48] text-white flex items-center justify-center rounded-md mt-2'
                    onClick={() => handleOpenModal()}
                  >
                    select
                  </div>
                  {targetImg !== '' && (
                    <div className='w-full px-2 py-2 mt-2 border rounded-md bg-[#E9EBF8] border-black dark:border-white dark:bg-[#131313]'>
                      {targetImg}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {/* Submit 버튼 */}
        <button
          className='mt-[var(--12px)] h-11
          w-full cursor-pointer rounded-lg bg-purple200
            text-[16px] text-white100 hover:text-white100 hover:brightness-75'
          onClick={() => submitMethodData()}
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Preview'}
        </button>
      </div>
      <div className='flex flex-col w-full h-full p-5 mb-16 overflow-y-auto'>
        <div className='flex gap-4 overflow-auto p-4'>
          <div className='flex-1 space-y-8'>
            <p className='text-[25px] font-bold text-black dark:text-white'>
              Input(before) | Output(after)
            </p>
            {inputImage.map((img, idx) => {
              const outputImg = outputImage[idx];
              return (
                <>
                  <div key={idx} className='flex gap-4 flex-wrap items-center'>
                    {/* Input 이미지 */}
                    <div className='overflow-hidden flex-1 flex items-center justify-center min-h-[300px] max-h-[80vh] min-w-0'>
                      <img
                        src={`data:image/png;base64,${img[2]}`}
                        alt={img[0]}
                        className='max-h-full max-w-full object-contain'
                      />
                    </div>
                    <div className='flex gap-4 items-center text-[50px]'>
                      <div className='rounded p-3 px-4 bg-[#181818] text-[15px]'>▶</div>
                    </div>
                    {/* Output 이미지 */}
                    {outputImg && (
                      <div className='overflow-hidden flex-1 flex items-center justify-center min-h-[300px] max-h-[80vh] min-w-0'>
                        <img
                          src={`data:image/png;base64,${outputImg[2]}`}
                          alt={outputImg[0]}
                          className='max-h-full max-w-full object-contain'
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-[#3c3862] p-6 rounded-lg shadow-lg w-96'>
            <div className='w-[100%] flex justify-center items-center'>
              <div className='w-[300px] h-[300px]  flex justify-center items-center rounded-[5px] bg-[#181818]'>
                {(fileAttribute[1] === 'jpg' || 'png') && (
                  <img
                    id='datamanage-thumbnail'
                    src={`data:image/jpeg;base64,${selected.thumbnail}`}
                  />
                )}
              </div>
            </div>
            <div className='flex justify-center items-center text-[#ffffff] mt-4'>
              {curDir + '/' + selected.name}
            </div>
            <ul className='mt-4'>
              {fileNdirList_Normal.map((data) => {
                if (data.type === 'dataset' || data.type === 'dir' || data.type === 'model') {
                  return (
                    <CustomProcessingDirectory
                      depth={0}
                      name={data.name}
                      path={''}
                      setSelected={setSelected}
                      dataType={data.type}
                      curDir={curDir}
                      setCurDir={setCurDir}
                      // selected={selected}
                      fileAttribute={fileAttribute}
                      setFileAttribute={setFileAttribute}
                      info={{
                        name: '..',
                        type: 'dir',
                        is_dir: true,
                        thumbnail: 'empty',
                        updated_at: '',
                        path: '',
                      }}
                    />
                  );
                } else {
                  return (
                    <CustomProcessingFile
                      thumbnail={data.thumbnail}
                      path={''}
                      depth={0}
                      name={data.name}
                      setSelected={setSelected}
                      dataType={data.type}
                      curDir={curDir}
                      setCurDir={setCurDir}
                      fileAttribute={fileAttribute}
                      setFileAttribute={setFileAttribute}
                      info={{
                        name: '..',
                        type: 'dir',
                        is_dir: true,
                        thumbnail: 'empty',
                        updated_at: '',
                        path: '',
                      }}
                    />
                  );
                }
              })}
            </ul>

            <div className='flex justify-evenly space-x-2 mt-8'>
              <button onClick={handleCancel} className='bg-gray-500 text-white px-14 py-2 rounded'>
                취소
              </button>
              <button
                onClick={handleConfirm}
                className='bg-[#181837] text-white px-14 py-2 rounded'
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
