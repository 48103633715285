import React from 'react';

import DesktopHeader from './desktop/DesktopHeader';
import MobileHeader from './mobile/MobileHeader';
import MobileHeaderNav from './mobile/MobileHeaderNav';

function Header(props: any) {
  return (
    <>
      {/* 데스크탑용 헤더 */}
      <div className='hidden md:block'>
        <DesktopHeader {...props} />
      </div>

      {/* 모바일용 헤더*/}
      <div className='block md:hidden'>
        <MobileHeader {...props} />
        <MobileHeaderNav pathname={props.pathname} />
      </div>
    </>
  );
}

export default Header;
