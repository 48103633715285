import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import Swal from 'sweetalert2';

export const fetchAllQnAList = async (
  api: ReturnType<typeof useAPIwithCookies>,
  setAllQuestionList: any,
) => {
  try {
    const response = await api.get('/help/qna_list?filter=all');
    setAllQuestionList(response.data.data.reverse());
  } catch (error) {
    console.error('Error fetching pipe list', error);
  }
};

export const fetchMyQnAList = async (
  api: ReturnType<typeof useAPIwithCookies>,
  setMyQuestionList: any,
) => {
  try {
    const response = await api.get('/help/qna_list?filter=my');
    setMyQuestionList(response.data.data.reverse());
  } catch (error) {
    console.error('Error fetching pipe list', error);
  }
};

export const postNewQuestion = async (
  api: ReturnType<typeof useAPIwithCookies>,
  newTitle: string,
  newContent: string,
  isPublic: boolean,
) => {
  try {
    const response = await api.post('/help/submit_question', {
      title: newTitle,
      contents: newContent,
      public_yn: isPublic,
    });
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Your new question has been created',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
    }
  } catch (error) {
    console.error('Error fetching pipe list', error);
  }
};
