import React, { useState, useContext, useRef } from 'react';
import './canvasSearch.scss';
import { MouseEvent } from 'react';
import { CustomThemeContext } from '@/contexts/common/Context';
import styled from 'styled-components';

const Container = styled.ul`
  display: flex;
  gap: var(--20px);

  top: 0;
  left: calc(100% + var(--12px));

  li {
    margin-top: var(--12px);
    padding: var(--12px) !important;
    border-radius: var(--10px);
    border: 1px solid #dcdae8;

    &.selected {
      background-color: #dcdae8;
    }
  }
`;

type CanvasSearchProps = {
  setKeyword?: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory?: string[];
  setSelectedCategory?: React.Dispatch<React.SetStateAction<string[]>>;
};

const CanvasSearch = ({ setKeyword, selectedCategory, setSelectedCategory }: CanvasSearchProps) => {
  const { theme } = useContext(CustomThemeContext);
  const [show, setShow] = useState(false);
  const [showCategory, setShowCategory] = useState<string[] | null>(null);

  const category = ['segmentation', 'regression', 'denoising', 'super-resolution'];

  const handleClick = (event: MouseEvent) => {
    if (show === true) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const inputEl = useRef<HTMLInputElement>(null);
  const handleChange: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const value = (e.target as HTMLInputElement).value;
    setTimeout(() => {
      if (value === inputEl.current?.value && setKeyword) {
        setKeyword(value);
      }
    }, 400);
  };

  return (
    <>
      <div className='canvasSearch'>
        <div className='input-frame'>
          <button>
            <img
              src={
                process.env.PUBLIC_URL + theme === 'dark'
                  ? '/images/ico-search.svg'
                  : '/images/ico-search-white.svg'
              }
              alt=''
              className=''
            />
          </button>
          <input ref={inputEl} type='text' placeholder='Search...' onKeyUp={handleChange} />
        </div>
        <div className='filter-frame'>
          <button type='button' onClick={handleClick}>
            <img
              src={
                process.env.PUBLIC_URL + theme === 'dark'
                  ? '/images/ico-filter.svg'
                  : '/images/ico-filter-white.svg'
              }
              alt=''
              className=''
            />
            <span>filter</span>
          </button>
          {show && (
            <div className={`filter-content-frame`} onMouseLeave={() => setShow(false)}>
              <ul>
                <li>
                  <span onClick={() => setShowCategory((prev) => (prev ? null : category))}>
                    Category
                  </span>
                </li>
                <li>
                  <span>Date</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {showCategory && (
        <Container className='category'>
          {showCategory.map((v) => {
            return (
              <li className={selectedCategory?.includes(v) ? 'selected' : ''}>
                <button
                  type='button'
                  onClick={() => {
                    if (!setSelectedCategory) return;
                    setSelectedCategory((prev) => {
                      if (prev.includes(v)) {
                        return prev.filter((vv) => vv !== v);
                      } else {
                        return [...prev, v];
                      }
                    });
                  }}
                >
                  {v}
                </button>
              </li>
            );
          })}
        </Container>
      )}
    </>
  );
};

export default CanvasSearch;
