import { SelectedProps } from '@/types/datahub/type'
import { useEffect } from 'react'

/**
 * 선택된 항목을 패널 상태와 동기화하는 커스텀 훅
 * 
 * @param {SelectedProps} props - 선택된 항목 및 파일/디렉토리 목록과 관련된 속성
 */
export const useSyncSelectedPanel = ({
  selectedInPanel,
  setSelectedInPanel,
  fileNdirList,
  selected
}: SelectedProps) => {
  useEffect(() => {
    if (!selectedInPanel) return

    const selectedFile = fileNdirList.filter((v) => v.name === selected[0])
    if (selectedFile.length) {
      setSelectedInPanel(selectedFile[0])
    }
  }, [selectedInPanel, setSelectedInPanel, fileNdirList, selected])
}
