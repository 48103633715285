import React, { useEffect, useState } from 'react';
import { default as OSD } from 'openseadragon';
import { useLocation } from 'react-router-dom';

import useDataHubStore from '@/stores/datahub/useDataHubStore';
import Loading from '@/components/common/loadings/Loading';

import '@/styles/common/image.styles.scss';

export default function FullImage() {
  const setViewer = useState<OSD.Viewer>()[1];
  const fileDir = useLocation().pathname.slice(20);
  const token = localStorage.getItem('access');

  const { imageLoading, getImage, setImageLoading } = useDataHubStore();

  useEffect(() => {
    setImageLoading(true);
    getImage();

    const src = process.env.REACT_APP_VIENCE_API_KEY + '/image/' + token + fileDir + '/img.dzi';
    const osdViewer = new OSD.Viewer({
      id: 'full-image',
      prefixUrl: '/images/openseadragon/',
      tileSources: src,
      crossOriginPolicy: 'Anonymous',
      animationTime: 0.5,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 50,
      minZoomLevel: 0.1,
      visibilityRatio: 1,
      zoomPerClick: 2,
      zoomPerScroll: 2,
      showNavigator: true,
      navigatorWidth: '250px',
      navigatorHeight: '250px',
      showFullPageControl: true,
      showHomeControl: true,
      showZoomControl: true,
      degrees: 0,
      showRotationControl: true,
    });

    osdViewer.addHandler('open', () => {
      setImageLoading(false);
    });

    osdViewer.addHandler('open-failed', (e) => {
      console.error('Failed to open image:', e);
      setImageLoading(false);
    });

    setViewer(osdViewer);
    return () => osdViewer.destroy();
  }, [fileDir, getImage, setImageLoading]);

  return (
    <div className='image-system'>
      {imageLoading && <Loading label='Loading Image...' />}
      <div className='image-osd' id='full-image' />
    </div>
  );
}
