import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '@/components/common/layouts/header/Header';
import CodeEditorOption from './CodeEditorOption';
import LoadingAnimation from '../../../components/loadingAnimation/LoadingAnimation';
import CodeEditor from './CodeEditor';
import WorkspaceModal from '../workspace/WorkspaceModal';
import { ShareModal } from '@/components/rete/workspace-panel.component';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

const MemoizedCodeEditor = React.memo(CodeEditor, codeEditorPropsAreEqual);
const MemoizedCodeEditorOption = React.memo(CodeEditorOption, compareCodeEditorOptionProps);

function codeEditorPropsAreEqual(prevComponent, nextComponent) {
  return (
    prevComponent.modelPath === nextComponent.modelPath && prevComponent.code === nextComponent.code
  );
}

function compareCodeEditorOptionProps(prevComp, nextComp) {
  return prevComp.selected === nextComp.selected;
}

export default function MlopsCodeEditor() {
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState<boolean>(false);
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [optionComplte, setOptionComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [code, setCode] = useState('');
  const [selected, setSelected] = useState<{
    path: string;
    name: string;
    thumbnail: string;
  }>({ path: '', name: '', thumbnail: '' });
  const [prevSelected, setPrevSelected] = useState<{
    path: string;
    name: string;
    thumbnail: string;
  }>({ path: '', name: '', thumbnail: '' });
  const [modelPath, setModelPath] = useState('');

  const location = useLocation();

  const handleWorkspaceModal: () => void = () => {
    setWorkspaceModalOpen(!workspaceModalOpen);
  };

  const getCodeEditor = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const api = useAPIwithCookies();

  const getCode = async (modelPath) => {
    try {
      const { data } = await api.get(`/mlops/model_code/${modelPath}`);
      setCode(data);
      setPrevSelected(selected);
    } catch (err) {
      alert('unable to open with monaco editor');
      setSelected(prevSelected);
    }
  };

  useEffect(() => {
    if (location.state?.workspace ?? undefined) {
      const workspace = location.state?.workspace;
      setOptionComplete(true);
      getCodeEditor();
      getCode(workspace.model_path);

      if (workspace?.path !== undefined) {
        setSelected({
          path: workspace.path,
          name: workspace.name,
          thumbnail: '',
        });
        const userEmail = localStorage.getItem('email');
        setModelPath(
          `/cloud/member/${userEmail}${workspace.path ? '/' : ''}${workspace.path}/${workspace.name}`,
        );
      }
    }
  }, []);

  return (
    <div
      className='workspace-container'
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <Header />
      <main className='main-content'>
        <h2 className='a11y-hidden'>Code editor for initial workspace setup</h2>
        <MemoizedCodeEditorOption
          code={code}
          setOptionComplete={setOptionComplete}
          getCodeEditor={getCodeEditor}
          getCode={getCode}
          setModelPath={setModelPath}
          selected={selected}
          setSelected={setSelected}
        />
        <MemoizedCodeEditor
          code={code}
          setCode={setCode}
          modelPath={modelPath}
          setModelPath={setModelPath}
          setLoading={setLoading}
        />
        {optionComplte && loading && <LoadingAnimation label={'loading...'} />}
      </main>
      <WorkspaceModal
        workspaceModalOpen={workspaceModalOpen}
        handleWorkspaceModal={handleWorkspaceModal}
      />
      <ShareModal open={shareOpen} onClose={() => setShareOpen(false)} wor_id='' />
    </div>
  );
}
