import React from "react";
import { ClassicPreset, NodeId } from "rete";
import { CustomSocket, SinkColor, NodeSize } from "../../style";
import CustomControl from "../../style/CustomControl";
import { ILayerOption, IMlopsflow } from "../flow";
import { getTimeString } from "../panel-contents/PanelOptionComponent/inputUtils";

export class TrainerNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket},
  { ctrl: TrainerControl }
> {
  color = SinkColor;
  width = NodeSize.width;
  height = NodeSize.height;
  error = 'The training has not yet been conducted';
  
  constructor(
    process: () => void, 
    update: (control: TrainerControl) => void, 
    nodeId: NodeId
  ) {
    super('Trainer');
    this.id = nodeId === "" ? this.id : nodeId;
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Input(new CustomSocket()));
    this.addControl('ctrl', new TrainerControl(
      process, 
      update,
      this.id
    ));
  }
  data(input): { out: IMlopsflow } {
    let output: IMlopsflow = {
      datasetPath: {},
      layer: {
        input: [],
        node: {
          "trainer": this.controls.ctrl.props.option
        },
      }
    };

    if (input.in) {
      const inputNode = input.in[0];
      if (inputNode && inputNode.datasetPath) {
        const data1_key = Object.keys(inputNode.layer.node)[0];
        
        let convertedResolution = 
          inputNode.layer.node[data1_key].datasetResolution;
        if (Number.isNaN(convertedResolution)) {
          convertedResolution = 66
        }

        this.controls.ctrl.props.option.datasetResolution = convertedResolution;
        this.controls.ctrl.datasetResolution = convertedResolution;
        if (localStorage.getItem('dynamic') === 'true') {
          this.height = convertedResolution < 66 ? 66 : convertedResolution;
        }
        
        output = {
          datasetPath: inputNode?.datasetPath ?? "",
          layer: {
            input: [inputNode?.layer],
            node: {
              "trainer": this.controls.ctrl.props.option
            }
          }
        }
      }

      if (this.controls.ctrl.modelFlow !== output){
        this.controls.ctrl.modelFlow = output;
      }
    } 
    
    return { out: output }
  }
}

export class TrainerControl extends ClassicPreset.Control {
  contextOpen: boolean;
  props: {
    option: ILayerOption;
  };
  modelFlow: IMlopsflow;
  prevModelFlow: string;
  datasetResolution: number;

  constructor(
    public onChange: () => void, 
    public update: (c: TrainerControl) => void, 
    public nodeId: NodeId
  ) {
    super();
    this.contextOpen = false;
    this.props = {
      option: {
        nodeId,
        name: "Trainer",
        modelName: "untitled_modelFlow_" + getTimeString(),
        newWeightName: "untitled_train_session_" + getTimeString(),
      }
    };
    this.modelFlow = {
      datasetPath: {undefined},
      layer: undefined,
    };
    this.prevModelFlow = '';
    this.datasetResolution = 66;
  }
  
  setValue(data: ILayerOption) {
    this.props.option = data;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    this.update(this);
  }
}

export function TrainerComponent({ data }: { 
  data: TrainerControl 
}) {
  if (!data.nodeId) return <p>Error</p>;

  return (
    <CustomControl 
      nodeId={data.nodeId}
      label="Trainer" 
      iconSource="viewer" 
      markerSource='node-sink' 
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
    />
  )
}