import React from 'react';
import 'rsuite/dist/rsuite.min.css';
import './control-panel.styles.scss';
import { createNode } from '../rete';

const ControlPanel = () => {
  return (
    <div className='control-panel'>
      {/* source */}
      <div className='control-container'>
        <div className='inner'>
          <div className='panel-tool-title'>
            <div className='top'>
              <strong>Data</strong>
              <span>STEP 01</span>
            </div>
            <p>Cloud-based big data management</p>
          </div>

          <button
            className='control-button data-control-button'
            onClick={(e) => {
              createNode(7, e.clientY);
            }}
          >
            <div className='control-icon-file'>
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/images/canvas-data-datamanagement.svg'
                }
                alt=''
              />
            </div>
            <span className='button-text'>Data Management</span>
          </button>
        </div>
      </div>

      {/* effector */}
      <div className='control-container'>
        <div className='inner'>
          <div className='panel-tool-title'>
            <div className='top'>
              <strong>Processing</strong>
              <span>STEP 02</span>
            </div>
            <p>High-performance GPU-based parallel data processing</p>
          </div>

          <button
            className='control-button crop-control-button'
            onClick={(e) => {
              createNode(0, e.clientY);
            }}
          >
            <div className='control-icon-filter'>
              <img
                src={process.env.PUBLIC_URL + '/images/canvas-edge-code.svg'}
                alt=''
              />
            </div>
            <span className='button-text'>Custom Processing</span>
          </button>

          <button
            className='control-button crop-control-button'
            onClick={(e) => {
              createNode(1, e.clientY);
            }}
          >
            <div className='control-icon-filter'>
              <img
                src={process.env.PUBLIC_URL + '/images/canvas-crop-crop.svg'}
                alt=''
              />
            </div>
            <span className='button-text'>Crop</span>
          </button>

          <button
            className='control-button crop-control-button'
            onClick={(e) => {
              createNode(2, e.clientY);
            }}
          >
            <div className='control-icon-filter'>
              <img
                src={process.env.PUBLIC_URL + '/images/canvas-crop-edge.svg'}
                alt=''
              />
            </div>
            <span className='button-text'>Edge Detection</span>
          </button>

          <button
            className='control-button crop-control-button'
            disabled={true}
            onClick={(e) => {
              createNode(4, e.clientY);
            }}
          >
            <div className='control-icon-filter'>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/canvas-crop-denoising.svg'
                }
                alt=''
              />
            </div>
            <span className='button-text'>Denoising</span>
          </button>

          <button
            className='control-button crop-control-button'
            disabled={true}
            onClick={(e) => {
              createNode(15, e.clientY);
            }}
          >
            <div className='control-icon-filter'>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/canvas-crop-denoising.svg'
                }
                alt=''
              />
            </div>
            <span className='button-text'>Segmentation 2D</span>
          </button>

          <button
            className='control-button crop-control-button'
            disabled={true}
            onClick={(e) => {
              createNode(16, e.clientY);
            }}
          >
            <div className='control-icon-filter'>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/canvas-crop-denoising.svg'
                }
                alt=''
              />
            </div>
            <span className='button-text'>Classification</span>
          </button>

          <button
            className='control-button crop-control-button'
            onClick={(e) => {
              createNode(12, e.clientY);
            }}
          >
            <div className='control-icon-filter'>
              <img
                src={process.env.PUBLIC_URL + '/images/canvas-viewer-blend.svg'}
                alt=''
              />
            </div>
            <span className='button-text'>Blender</span>
          </button>
        </div>
      </div>

      {/* sink */}
      <div className='control-container'>
        <div className='inner'>
          <div className='panel-tool-title'>
            <div className='top'>
              <strong>Visualization</strong>
              <span>STEP 03</span>
            </div>
            <p>Visual analytics for getting scientific insight</p>
          </div>

          <button
            className='control-button viewer-control-button'
            onClick={(e) => {
              createNode(8, e.clientY);
            }}
          >
            <div className='control-icon-viewer'>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/canvas-viewer-viewer.svg'
                }
                alt=''
              />
            </div>
            <span className='button-text'>Viewer</span>
          </button>
        </div>
      </div>

      {/* addon */}
      <div className='control-container'>
        <div className='inner'>
          <div className='panel-tool-title'>
            <div className='top'>
              <strong>Add-on</strong>
              <span>STEP 04</span>
            </div>
            <p>Expand functionality</p>
          </div>

          <button
            className='control-button plugin-control-button'
            onClick={(e) => {
              createNode(10, e.clientY);
            }}
          >
            <div className='control-icon-addon'>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/canvas-viewer-process.svg'
                }
                alt=''
              />
            </div>
            <span className='button-text'>Pipe Generation</span>
          </button>

          <button
            className='control-button plugin-control-button'
            onClick={(e) => {
              createNode(13, e.clientY);
            }}
          >
            <div className='control-icon-addon'>
              <img
                src={process.env.PUBLIC_URL + '/images/canvas-crop-cell.svg'}
                alt=''
              />
            </div>
            <span className='button-text'>Feature</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
