import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IfileNdirList } from '@/types/common/type';
import useDataHubStore from '@/stores/datahub/useDataHubStore';
import useContextMenuStore from '@/stores/datahub/useDataHubMenuStore';

/**
 * 파일 및 디렉토리 클릭과 관련된 동작을 처리하는 커스텀 훅
 *
 * @param {{ info: IfileNdirList }} param - 클릭한 항목의 정보
 * @returns {{
 *   handleClickItem: (e: React.MouseEvent, index: number, name: string) => void,
 *   handleDoubleClickItem: () => void
 * }} 항목 클릭 및 더블 클릭 핸들러를 포함한 객체
 */
export const useItemClick = ({
  info,
}: {
  info: IfileNdirList;
}): {
  handleClickItem: (e: React.MouseEvent, index: number, name: string) => void;
  handleDoubleClickItem: () => void;
} => {
  const { name, type, thumbnail } = info;

  const { curDir, selected, setStartIndex, setEndIndex, setCurDir, setSelected, setSelectedFile } =
    useDataHubStore();

  const { setContextOpen } = useContextMenuStore();

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * 현재 디렉토리를 변경하는 함수
   */
  const changeCurDir = useCallback(() => {
    let newDir = '';
    if (name === '..') {
      const dirArray = curDir.split('/').filter(Boolean);
      dirArray.pop();
      newDir = dirArray.join('/');
    } else {
      newDir = curDir ? `${curDir}/${name}` : name;
    }

    setCurDir(newDir);
    setSelected([]);

    navigate(newDir ? `/vience-canvas/datahub/${newDir}` : '/vience-canvas/datahub');
  }, [curDir, name, setCurDir, navigate]);

  /**
   * 파일 유형에 따라 적절한 액션을 수행하는 함수
   */
  const handleFileAction = useCallback(() => {
    const userEmail = localStorage.getItem('email') || '';

    if (type === 'dir' || type === 'dataset' || type === 'model' || type === 'form_output') {
      changeCurDir(); //  디렉토리로 이동
    } else if (type === 'vmeta' || type === 'file' || type === 'weight') {
      navigate('/vience-canvas/codeEditor', {
        state: {
          workspace: {
            model_path: `/cloud/member/${userEmail}/${curDir}/${name}`,
            path: curDir,
            name,
          },
        },
      });
    } else if (type === 'image' || type === 'svs') {
      const targetUrl = curDir
        ? `/vience-canvas/image/${curDir}/${name}`
        : `/vience-canvas/image/${name}`;
      window.open(targetUrl, '_blank');
    } else {
      // 나머지 파일은 미리보기 불가
      return;
    }
  }, [changeCurDir, curDir, location, name, navigate, setSelectedFile, thumbnail, type]);

  /**
   * 항목 클릭 시 호출되는 이벤트 핸들러
   *
   * @param {React.MouseEvent} e - 마우스 클릭 이벤트
   * @param {number} index - 클릭한 항목의 인덱스
   * @param {string} name - 클릭한 항목의 이름
   */
  const handleClickItem = useCallback(
    (e: React.MouseEvent, index: number, name: string) => {
      e.stopPropagation();
      if (e.shiftKey) {
        setEndIndex(index);
      } else if (e.ctrlKey) {
        setSelected([...selected, name]);
      } else {
        setSelected([name]);
        setSelectedFile({ path: curDir, name, thumbnail });
        setStartIndex(index);
        setEndIndex(index);
        setContextOpen(false);
      }
    },
    [
      curDir,
      thumbnail,
      selected,
      setEndIndex,
      setSelected,
      setSelectedFile,
      setStartIndex,
      setContextOpen,
    ],
  );

  /**
   * 항목 더블 클릭 시 호출되는 이벤트 핸들러
   */
  const handleDoubleClickItem = useCallback(() => {
    handleFileAction();
  }, [handleFileAction]);

  return { handleClickItem, handleDoubleClickItem };
};
