import React, { useContext } from 'react';
import './overview.scss';
import Header from '@/components/common/layouts/header/Header';
import { Link } from 'react-router-dom';

import { CustomThemeContext } from '@/contexts/common/Context';
import OverviewLeftPanel from './OverviewLeftPanel';

export default function Overview() {
  const { theme } = useContext(CustomThemeContext);

  return (
    <div className='overview'>
      <Header />
      <div className='main-content'>
        <OverviewLeftPanel />
        <div className='right'>
          <div className='inner'>
            <section>
              <strong>My workspace</strong>
              <ul>
                <li>
                  <Link to=''>
                    <div className='top'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/overview-test-img.png'}
                        alt='vience'
                      />
                    </div>
                    <div className='bottom'>
                      <div className='title'>cell analysis1</div>
                      <div className='date'>2023.10.05</div>
                    </div>
                  </Link>
                </li>
              </ul>
            </section>
            <section>
              <strong>Shared workspace</strong>
              <ul>
                <li>
                  <Link to=''>
                    <div className='top'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/overview-test-img.png'}
                        alt='vience'
                      />
                    </div>
                    <div className='bottom'>
                      <div className='title'>cell analysis1</div>
                      <div className='date'>2023.10.05</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to=''>
                    <div className='top'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/overview-test-img.png'}
                        alt='vience'
                      />
                    </div>
                    <div className='bottom'>
                      <div className='title'>cell analysis1</div>
                      <div className='date'>2023.10.05</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to=''>
                    <div className='top'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/overview-test-img.png'}
                        alt='vience'
                      />
                    </div>
                    <div className='bottom'>
                      <div className='title'>cell analysis1</div>
                      <div className='date'>2023.10.05</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to=''>
                    <div className='top'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/overview-test-img.png'}
                        alt='vience'
                      />
                    </div>
                    <div className='bottom'>
                      <div className='title'>cell analysis1</div>{' '}
                      <div className='date'>2023.10.05</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to=''>
                    <div className='top'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/overview-test-img.png'}
                        alt='vience'
                      />
                    </div>
                    <div className='bottom'>
                      <div className='title'>cell analysis1</div>
                      <div className='date'>2023.10.05</div>
                    </div>
                  </Link>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
