import { useCookies } from 'react-cookie';
import API from '@/apis/common/apis';

/**
 * 쿠키 데이터를 기반으로 API 인스턴스를 생성하여 반환하는 커스텀 훅
 *
 * @returns {API} 쿠키를 포함한 API 인스턴스
 */
export function useAPIwithCookies(): API {
  const [cookies] = useCookies(['refresh']);
  const api = new API(cookies);

  return api;
}
