import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import CommingSoonAlert from '@/components/common/alerts/CommingSoonAlert';
import { CustomThemeContext } from '@/contexts/common/Context';

function MobileHeaderNav({ pathname }) {
  const { theme } = useContext(CustomThemeContext);
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  return (
    <nav className='w-full bg-gray100 px-4 dark:bg-black100'>
      <ul className='flex items-center justify-center space-x-5 py-1 text-[14px]'>
        <CommingSoonAlert>
          <li
            className={`pointer-events-none opacity-50 
          ${pathname === 'overview' ? 'border-b-[3px] border-purple200' : ''}`}
          >
            <Link to='/vience-canvas/overview' className='text-gray200'>
              Overview
            </Link>
          </li>
        </CommingSoonAlert>

        <li
          className={`box-border flex h-[44px] items-center transition-all 
        ${pathname === 'datahub' ? 'border-b-[3px] border-purple200' : ''}`}
        >
          <Link to='/vience-canvas/datahub' className='text-black300 dark:text-white100'>
            DataHub
          </Link>
        </li>

        <li
          className={`box-border flex h-[44px] items-center transition-all 
        ${pathname === 'workspace' ? 'border-b-[3px] border-purple200' : ''}`}
        >
          <Link to='/vience-canvas/workspace' className='text-black300 dark:text-white100'>
            Workspace
          </Link>
        </li>

        <CommingSoonAlert>
          <li
            className={`pointer-events-none box-border flex h-[44px] items-center opacity-50 transition-all 
          ${pathname === 'mlops' ? 'border-b-[3px] border-purple200' : ''}`}
          >
            <Link to='/vience-canvas/mlops' className='text-gray200'>
              MLOps
            </Link>
          </li>
        </CommingSoonAlert>

        {/* 나머지 항목들은 arrow 버튼 클릭 시 드롭다운 */}
        <li className='relative z-[99] ml-auto'>
          <button
            onClick={() => setShowMoreMenu((prev) => !prev)}
            className='flex items-center gap-1'
          >
            <img
              src={
                theme === 'dark'
                  ? '/images/canvas-header-arrow-dark.svg'
                  : '/images/canvas-header-arrow-light.svg'
              }
              alt='Navigation Drop Down Button'
              className='size-6 object-contain sm:size-5'
            />
          </button>
          {showMoreMenu && (
            <ul
              className='absolute left-[-70px] mt-2 w-[100px] rounded-md
              bg-white100 text-gray200 shadow-lg dark:bg-black200'
            >
              <CommingSoonAlert>
                <li
                  className='z-[999] rounded-t-custom-lg border-x
                  border-t border-gray200 bg-white100
                  p-[10px] dark:border-black100 dark:bg-black200 '
                >
                  <Link to='/vience-canvas'>Market</Link>
                </li>
              </CommingSoonAlert>
              <CommingSoonAlert>
                <li
                  className='z-[999] rounded-b-custom-lg border-x
                  border-b border-gray200 bg-white100
                  p-[10px] dark:border-black100 dark:bg-black200 '
                >
                  <Link to='/vience-canvas'>Documents</Link>
                </li>
              </CommingSoonAlert>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default MobileHeaderNav;
