import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import API from '../../../../utils/api';
import '../sink/viewer.styles.scss';
import { IUSAKey } from '../../../viewer/viewer';
import { default as OSD } from 'openseadragon';
import { GearControl } from './Gear';
import { useParams } from 'react-router-dom';
import { editor } from '../../../rete';
import { ParallelCoordinateChart, ScatterChart } from '../../charts';
import '../../../side-panel/side-panel.styles.scss';
import Swal from 'sweetalert2';

export function GearPanel({
  ctrl,
  blend,
}: {
  ctrl: GearControl;
  blend?: boolean;
}) {
  console.log('제발 실행좀 해라 ㅋ', ctrl);

  const [cookies, setCookie] = useCookies(['refresh']);
  const api = new API(cookies);
  const params = useParams();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const saveGear = async () => {
    console.log('기업패널의 노드 아이디', ctrl);
    try {
      const modRes = await api.post(`/module/mod_seq`, {
        wor_id: params.wor_id,
        effector: ctrl.props.option?.effector,
      });
      console.log('mod seq 받아온거: ', modRes.data);
      console.log(ctrl.props.option?.effector);

      const input_formats = {};
      if (ctrl.props.option?.img_paths) {
        for (let name of Object.keys(ctrl.props.option.img_paths)) {
          const t = ctrl.props.option.img_paths[name]?.split('.');
          if (t && t.length > 0) {
            input_formats[name] = t[t.length - 1];
          }
        }
      }

      const response = await api.post('/pipe/create', {
        pipe_id: '',
        mod_seq: modRes.data,
        email: '',
        name: title,
        description,
        input_format: input_formats,
        created_at: new Date().toISOString(),
      });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'The pipe has been created',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err: any) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        text: err.detail,
      });
    }
  };

  return (
    <div className='gear-panel'>
      <div className='gear-panel-input-layout'>
        <label>
          Title:
          <input
            type='text'
            value={title}
            onChange={handleTitleChange}
            className='input-title'
          />
        </label>
        <div style={{ marginTop: '30px' }}>
          <label>
            Description:
            <textarea
              value={description}
              onChange={handleDescriptionChange}
              className='input-description'
            />
          </label>
        </div>
      </div>
      <button onClick={saveGear} className='save-gear'>
        Save Pipe
      </button>
    </div>
  );
}
