import React, { useState, useMemo } from 'react';

const Carousel = React.memo(
  ({
    carouselImage,
    type,
  }: {
    carouselImage: [string, string, string][];
    type?: [string, string, string][];
  }) => {
    // key별로 이미지 그룹화
    const groupedThumbnails = useMemo(() => {
      return carouselImage.reduce<Record<string, string[]>>((acc, [key, , image]) => {
        acc[key] = acc[key] || [];
        acc[key].push(image);
        return acc;
      }, {});
    }, [carouselImage]);

    // key별로 타입 그룹화
    const groupedTypes = useMemo(() => {
      if (!type) return {}; // type이 없을 경우 빈 객체 반환

      return type.reduce<Record<string, string[]>>((acc, [key, , image]) => {
        acc[key] = acc[key] || [];
        acc[key].push(image);
        return acc;
      }, {});
    }, [type]);

    // ✅ useMemo를 사용해서 currentIndex 초기화 (렌더링 중에 한 번만 계산됨)
    const initialIndex = useMemo(
      () =>
        Object.keys(groupedThumbnails).reduce(
          (acc, key) => ({ ...acc, [key]: 0 }),
          {} as Record<string, number>,
        ),
      [groupedThumbnails],
    );

    const [currentIndex, setCurrentIndex] = useState<Record<string, number>>(initialIndex);

    // 이전 이미지로 이동
    const prevSlide = (key: string) => {
      setCurrentIndex((prev) => ({
        ...prev,
        [key]:
          ((prev[key] ?? 0) - 1 + (groupedThumbnails[key]?.length ?? 1)) %
          (groupedThumbnails[key]?.length ?? 1),
      }));
    };

    // 다음 이미지로 이동
    const nextSlide = (key: string) => {
      setCurrentIndex((prev) => ({
        ...prev,
        [key]: ((prev[key] ?? 0) + 1) % (groupedThumbnails[key]?.length ?? 1),
      }));
    };

    return (
      <div className='flex mt-2'>
        {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
        {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
        <div className='flex gap-x-4 mt-2 overflow-x-auto'>
          {Object.entries(groupedThumbnails).map(([key, images]) => (
            <div key={key} className='relative w-[145px] h-[145px] shrink-0'>
              <div className='w-[145px] h-[145px] overflow-hidden flex relative'>
                {/* <img
              src={`${images[currentIndex[key]]}`}
              alt={`Thumbnail ${key}`}
              className='w-full h-full object-cover rounded-[7px] transition-all duration-500'
            /> */}
                <img
                  // src={images[currentIndex[key] ?? 0]}
                  src={`data:image/png;base64,${images[currentIndex[key] ?? 0]}`}
                  alt={''}
                  className='w-full h-full object-cover rounded-[7px] transition-all duration-500 text-[10px]'
                />
                {/* Key 표시 */}
                {key !== 'default0' && (
                  <span className='absolute top-2 left-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded'>
                    {key}
                  </span>
                )}

                <span className='absolute top-2 right-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded'>
                  {groupedTypes[key]?.[currentIndex[key] ?? 0]}
                </span>
              </div>

              {/* 캐러셀 컨트롤 버튼 */}
              {images.length > 1 && (
                <>
                  <button
                    onClick={() => prevSlide(key)}
                    className='absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-1 px-2 opacity-75 hover:opacity-100'
                  >
                    ◀
                  </button>
                  <button
                    onClick={() => nextSlide(key)}
                    className='absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-1 px-2 opacity-75 hover:opacity-100'
                  >
                    ▶
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
        {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
      </div>
    );
  },
);

export default Carousel;
