import { ClassicPreset, NodeId } from 'rete';
import { ControlTypes, area, arrange, editor, engine } from './editor';

import {
  DatasetNode,
  Convolution2dNode,
  ReluActivationNode,
  BatchNorm2dNode,
  ConvTranspose2dNode,
  TrainerNode,
  MaxPool2dNode,
  CatNode,
  FullyConnectedNode,
  FineTunerNode,
  FeatureVisualizerNode,
  DatasetControl,
  MaxPool2dControl,
  Convolution2dControl,
  ConvTranspose2dControl,
  TrainerControl,
  BatchNorm2dControl,
  ReluActivationControl,
  CatControl,
  FullyConnectedControl,
  FineTunerControl,
  FeatureVisualizerControl,
} from './components';

import { Cookies } from 'react-cookie';
import API from '@/apis/common/apis';

import { Position } from 'rete-connection-plugin/_types/types';

export const processmini = async (n) => {
  engine.reset();
  await engine.fetch(n.id);
};

//각 Node의 data() 실행
export const process = async () => {
  if (localStorage.getItem('dynamic') === 'true') {
    try {
      engine.reset();
    } catch (errMsg) {
      console.error(errMsg);
    } finally {
      editor.getNodes().forEach(async (n) => {
        await engine.fetch(n.id);
      });
    }
  }

  try {
    engine.reset();
  } catch (errMsg) {
    console.error(errMsg);
  } finally {
    editor.getNodes().forEach(async (n) => {
      await engine.fetch(n.id);
      area.update('node', n.id);
      area.update('socket', n.inputs.in?.id);
      area.update('socket', n.inputs.in1?.id);
      area.update('socket', n.inputs.in2?.id);
      area.update('socket', n.outputs.out?.id);
    });
  }
};

// update
export const updateControl = async (c) => {
  await area.update('node', c.nodeId);
  await area.update('control', c.id);
};

export const updateNode = async (nodeId: NodeId) => {
  await area.update('node', nodeId);
};

// Node 삭제, Conn제거 => Node 제거
export const deleteNode = async (nodeId: NodeId) => {
  await removeConnection(nodeId);
  await editor.removeNode(nodeId);
};

// target Node에 연결된 모든 Conn 제거
const removeConnection = async (nodeId: NodeId) => {
  const connections = editor.getConnections();
  for (const connection of connections) {
    if (connection.source === nodeId || connection.target === nodeId) {
      await editor.removeConnection(connection.id);
    }
  }
};

// target Node 복사
export const copyNode = async (nodeId: NodeId) => {
  const targetNode = editor.getNode(nodeId);
  const newNode = await createNode(targetNode.label);

  if (newNode) {
    await editor.addNode(newNode);
  }
};

// ctrl btn click handler
export const createNode = async (
  label: string,
  nodeId = '',
  pos = { x: 0, y: 0 }
) => {
  let newNode;

  label = label.toLowerCase().replace(/ /g, '');

  switch (label) {
    case 'dataset':
      newNode = new DatasetNode(process, updateControl, nodeId);
      break;
    case 'convolution2d':
      newNode = new Convolution2dNode(process, updateControl, nodeId);
      break;
    case 'maxpool2d':
      newNode = new MaxPool2dNode(process, updateControl, nodeId);
      break;
    case 'reluactivation':
      newNode = new ReluActivationNode(process, updateControl, nodeId);
      break;
    case 'batchnorm2d':
      newNode = new BatchNorm2dNode(process, updateControl, nodeId);
      break;
    case 'convtranspose2d':
      newNode = new ConvTranspose2dNode(process, updateControl, nodeId);
      break;
    case 'cat':
      newNode = new CatNode(process, updateControl, nodeId);
      break;
    case 'fullyconnected':
      newNode = new FullyConnectedNode(process, updateControl, nodeId);
      break;
    case 'trainer':
      newNode = new TrainerNode(process, updateControl, nodeId);
      break;
    case 'finetuner':
      newNode = new FineTunerNode(process, updateControl, nodeId);
      break;
    case 'featurevisualizer':
      newNode = new FeatureVisualizerNode(process, updateControl, nodeId);
      break;
  }

  if (nodeId !== '') newNode.id = nodeId;
  await editor.addNode(newNode);

  const rete_obj = document.getElementsByClassName('rete');
  const orign_obj = rete_obj[0].firstChild;
  const transform_str = orign_obj.style.transform;
  const transform_x = parseFloat(
    transform_str
      .split('(')[1]
      .split(')')[0]
      .split(',')[0]
      .split('px')[0]
      .trim()
  );
  const transform_y = parseFloat(
    transform_str
      .split('(')[1]
      .split(')')[0]
      .split(',')[1]
      .split('px')[0]
      .trim()
  );
  const transform_scale = parseFloat(transform_str.split('(')[2].split(')')[0]);
  const rect = rete_obj[0].getBoundingClientRect();
  await area.translate(newNode.id, {
    x: -(transform_x - 400) / transform_scale,
    y: -(transform_y + rect.top - pos.y + 10) / transform_scale,
  });

  return newNode;
};

// Node가 가지고 있는 모든 ContextMenu 닫기
export const closeAllContext = async () => {
  await editor.getNodes().filter((n) => {
    n.controls.ctrl?.setContextOpen(false);
  });
};

// ---- 노드 저장을 위한 함수들 -----
function serializePort(
  port:
    | ClassicPreset.Input<ClassicPreset.Socket>
    | ClassicPreset.Output<ClassicPreset.Socket>
) {
  return {
    id: port.id,
    label: port.label,
    socket: {
      name: port.socket.name,
    },
  };
}

function serializeControl(control: ClassicPreset.Control) {
  let controlType;

  if (control instanceof DatasetControl) {
    controlType = 'DatasetControl';
  } else if (control instanceof Convolution2dControl) {
    controlType = 'Convolution2dControl';
  } else if (control instanceof CatControl) {
    controlType = 'CatControl';
  } else if (control instanceof ConvTranspose2dControl) {
    controlType = 'ConvTranspose2dControl';
  } else if (control instanceof MaxPool2dControl) {
    controlType = 'MaxPool2dControl';
  } else if (control instanceof TrainerControl) {
    controlType = 'TrainerControl';
  } else if (control instanceof BatchNorm2dControl) {
    controlType = 'BatchNorm2dControl';
  } else if (control instanceof ReluActivationControl) {
    controlType = 'ReluActivationControl';
  } else if (control instanceof FullyConnectedControl) {
    controlType = 'FullyConnectedControl';
  } else if (control instanceof FineTunerControl) {
    controlType = 'FineTunerControl';
  } else if (control instanceof FeatureVisualizerControl) {
    controlType = 'FeatureVisualizerControl';
  }

  return {
    type: controlType,
    id: control.id,
    option: control.props.option,
  };
}

export async function createToJson() {
  const data: any = { nodes: [], connections: [] };
  const nodes = editor.getNodes();
  const connections = editor.getConnections();
  for (const node of nodes) {
    const position = area.nodeViews.get(node.id)?.position;
    const inputsEntries = Object.entries(node.inputs).map(([key, input]) => {
      return [key, input && serializePort(input)];
    });
    const outputsEntries = Object.entries(node.outputs).map(([key, output]) => {
      return [key, output && serializePort(output)];
    });
    const controlsEntries = Object.entries(node.controls).map(
      ([key, control]) => {
        return [key, control && serializeControl(control)];
      }
    );
    data.nodes.push({
      id: node.id,
      label: node.label,
      outputs: Object.fromEntries(outputsEntries),
      inputs: Object.fromEntries(inputsEntries),
      controls: Object.fromEntries(controlsEntries),
      position: position,
    });
  }
  for (const connection of connections) {
    data.connections.push({
      id: connection.id,
      source: connection.source,
      sourceOutput: connection.sourceOutput,
      target: connection.target,
      targetInput: connection.targetInput,
    });
  }

  const json = JSON.stringify(data);
  return json;
}

// ---- 저장된 json을 노드로 만들어주는 함수들 ----
export async function getWorkspace() {
  const cookie = new Cookies();
  const api = new API(cookie);

  const response = await api.get('/workspace/');
  await createByJson(JSON.parse(response.data));
}

interface INode {
  controls: ControlTypes;
  id: string;
  inputs: {
    [key: string]: any;
  };
  label: string;
  outputs: {
    [key: string]: any;
  };
  position: Position;
}

interface IConnection {
  id: string;
  source: string;
  sourceOutput: string;
  target: string;
  targetInput: string;
}

export const createNodesByJson = async (nodes: {
  nodes: INode[];
  connections: IConnection[];
}) => {
  const nodeArr = nodes['nodes'];
  for (const node of nodeArr) {
    const newNode = await createNode(node.label, node.id);
    if (newNode) {
      newNode.controls.ctrl.props.option = node.controls.ctrl.option;
      await area.translate(newNode.id, node.position);
    }
  }
};

export const createConnsByJson = async (nodes: {
  nodes: INode[];
  connections: IConnection[];
}) => {
  if (!editor) return;
  const ConnArr = nodes['connections'];
  for (const connection of ConnArr) {
    const source = editor.getNode(connection.source);
    const target = editor.getNode(connection.target);
    await editor.addConnection(
      new ClassicPreset.Connection(
        source,
        connection.sourceOutput as never,
        target,
        connection.targetInput as never
      )
    );
  }
};

export const createByJson = async (nodes: {
  nodes: INode[];
  connections: IConnection[];
}) => {
  await createNodesByJson(nodes);
  await createConnsByJson(nodes);
};

// ---- data 구조 보고 노드 만들기 ----
const generatedNodesId: string[] = [];
const connectedInputsId: string[] = [];

const createNodesByDataStructure = async (
  thisLayer,
  prevLayerNode = 0,
  ind = 0
) => {
  if (!thisLayer.input) {
    return thisLayer;
  }

  const newNodeLabel = Object.keys(thisLayer.node)[0];

  let nodeId;

  if (newNodeLabel === 'dataset') {
    nodeId = thisLayer.input[0];
  } else {
    nodeId = thisLayer.node[`${newNodeLabel}`].nodeId;
  }

  let newNodeInfo;

  if (generatedNodesId.includes(nodeId)) {
    newNodeInfo = editor.getNode(nodeId);
  } else {
    newNodeInfo = await createNode(newNodeLabel, nodeId);
    // option 추가
    const newNodeOption = thisLayer.node[`${newNodeLabel}`];

    // padding, stride, kernelSize가 number로 전달되면 배열로 바꾸기
    // if (typeof newNodeOption.padding === "number") {
    //     newNodeOption.padding = [newNodeOption.padding];
    // }
    // if (typeof newNodeOption.stride === "number") {
    //     newNodeOption.stride = [newNodeOption.stride];
    // }
    // if (typeof newNodeOption.kernelSize === "number") {
    //     newNodeOption.kernelSize = [newNodeOption.kernelSize];
    // }

    // 변환시킨거 노드에 넣어주기
    newNodeInfo.controls.ctrl.props.option = {
      ...newNodeInfo.controls.ctrl.props.option,
      ...newNodeOption,
    };

    generatedNodesId.push(nodeId);
  }

  if (prevLayerNode !== 0) {
    const inputLabel = Object.keys(prevLayerNode.inputs);
    const outputLabel = Object.keys(newNodeInfo.outputs);
    if (
      connectedInputsId.includes(prevLayerNode.inputs[`${inputLabel[ind]}`].id)
    ) {
      //
    } else {
      const connection = new ClassicPreset.Connection(
        newNodeInfo,
        outputLabel[0],
        prevLayerNode,
        inputLabel[ind]
      );
      await editor.addConnection(connection);
      connectedInputsId.push(prevLayerNode.inputs[`${inputLabel[ind]}`].id);
    }
  }

  prevLayerNode = newNodeInfo;

  for (let i = 0; i < thisLayer.input.length; i++) {
    createNodesByDataStructure(thisLayer.input[i], prevLayerNode, i);
  }
};

export const createByDataStructure = async (thisLayer) => {
  // 들어온 데이터 확인
  await createNodesByDataStructure(thisLayer);
  generatedNodesId = [];
};
