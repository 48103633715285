import React, { useEffect, useState } from 'react';
import { reduce } from 'lodash';

export default function Overlay({
  shapeList, 
  currentShape,
  getImageViewport,
  openSeadragon,
  color
}) {
  const [viewportSize, setViewportSize] = useState({
    originX: '',
    originY: '',
    width: '',
    height: '',
  });
  const [svgStyle, setSvgStyle] = useState({});

  const handleResize = () => {
    const view = getImageViewport();
    if(view.width === 0 && view.height === 0){
      setTimeout(handleResize, 500);
    }
    else{
      setViewportSize(view);
    }
  };

  useEffect(() => {
    handleResize();
    openSeadragon.addHandler('animation', handleResize);
    return () => openSeadragon.removeAllHandlers('animation');
  }, [])

  useEffect(() => {
    if (!viewportSize) return;
    const svgStyle = {
      position: 'absolute',
      display: 'block',
      left: viewportSize.originX,
      top: viewportSize.originY,
      width: viewportSize.width,
      height: viewportSize.height,
    };
    setSvgStyle(svgStyle);
  }, [viewportSize])

  return (
    <svg
      id="annotation-overlay"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      width="100%"
      height="100%"
      style={svgStyle}
    >
      {
        shapeList.map((shape, index) => {
          return (
           <Annotation 
            key={index} 
            points={shape}
            color={color}
            zoom={viewportSize}
          />
          )
        })
      }
      {
        currentShape.length > 0 
        && <Annotation
          points={currentShape}
          color={color}
          zoom={viewportSize}
        />
      }
    </svg>
  );
}

export function Annotation({ points, color, zoom }) {
  const polygonPoints = reduce(
    points,
    (sum, point) => {
      return sum + `${point.x},${point.y} `;
    },
    ''
  );

  return (
    <polygon
      fill={color}
      fillOpacity="0.3"
      stroke={color}
      strokeWidth={(300.0 / zoom.width ?? 1).toString() + "px"}
      points={polygonPoints}
    />
  );
}
