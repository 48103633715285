import './ContextMenu.scss';
import React, { SetStateAction, Dispatch, useState } from 'react';

export interface IContextFnc {
  label: string;
  on?: () => void;
  sub?: IContextFnc[];
}

interface ContextMenuProps {
  event: MouseEvent;
  functions: IContextFnc[];
  onClose: Dispatch<SetStateAction<boolean>>;
  containerId: string;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  event,
  functions,
  onClose,
  containerId,
}) => {
  if (!event) return null;

  const { clientX, clientY } = event;
  const gridDOM = document.getElementById(containerId);
  const gridDOM_pos = gridDOM?.getBoundingClientRect();
  const scrollTop = gridDOM?.scrollTop || 0;

  if (gridDOM_pos) {
    const menuWidth = 200;
    const isRightSide = clientX > window.innerWidth * 0.5;

    return (
      <div
        className='context-menu-container'
        style={{
          top: clientY - gridDOM_pos.y + scrollTop,
          left: isRightSide
            ? clientX - gridDOM_pos.x - menuWidth
            : clientX - gridDOM_pos.x,
        }}
      >
        {functions.map((data, index) => (
          <ContextItem
            key={index}
            index={index}
            sub={data.sub}
            data={data}
            onClose={onClose}
            isRightSide={isRightSide}
          />
        ))}
      </div>
    );
  }
  return null;
};

interface ContextItemProps {
  data: IContextFnc;
  onClose: Dispatch<SetStateAction<boolean>>;
  sub?: IContextFnc[];
  index: number;
  isRightSide: boolean;
}

const ContextItem: React.FC<ContextItemProps> = ({
  sub,
  data,
  onClose,
  index,
  isRightSide,
}) => {
  const [subOpen, setSubOpen] = useState<boolean>(false);

  return (
    <p
      className='context-menu'
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation();
        if (sub) {
          setSubOpen(true);
        } else {
          data.on?.();
          onClose(false);
        }
      }}
    >
      {data.label}
      {subOpen && (
        <div
          className='context-menu-subcontext-container'
          style={{
            top: index * 40,
            left: isRightSide ? -265 : 265,
          }}
        >
          {sub?.map((subData, subIndex) => (
            <ContextItem
              key={subIndex}
              index={subIndex}
              sub={subData.sub}
              data={subData}
              onClose={onClose}
              isRightSide={isRightSide}
            />
          ))}
        </div>
      )}
    </p>
  );
};

export default ContextMenu;
