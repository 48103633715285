import './storage.styles.scss';

import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { CustomThemeContext } from '../../../utils/Context';
import { SmcContext } from '../../../context/SmcContext';
import { ContextMenuContext } from '../../../context/ContextMenuContext';

import { IfileNdirList } from './storage.type';
import { StorageContext } from '../../../context/StorageContext';

const Container = styled.div`
  img {
    -webkit-user-drag: none;
  }
`;

interface IStorageCard {
  info: IfileNdirList;
  keyword: string;
  index: number;
  setStep?: any;
}

export default function StorageCard({
  info,
  keyword,
  index,
  setStep = () => {},
}: IStorageCard) {
  const { name, type, thumbnail, updated_at, tagging_progress, file_progress } =
    info;

  const { theme } = useContext(CustomThemeContext);
  const { setSmcModal } = useContext(SmcContext);
  const {
    curDir,
    setCurDir,
    selected,
    setSelected,
    setSelectedFile,
    setStartIndex,
    setEndIndex,
    setTarget,
    dragTag,
  } = useContext(StorageContext);

  const { setContext, setContextOpen } = useContext(ContextMenuContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {    
    // smc 라우터용 임시 처리
    if(!location.pathname.includes('/vience-canvas/storage')){
      return;
    }

    let currentPath = decodeURIComponent(
      location.pathname.replace('/vience-canvas/storage', '')
    );

    if (currentPath[0] === '/') {
      currentPath = currentPath.slice(1);
    }

    // 다른 페이지를 다녀와도 루트 경로일 때 curDir을 초기화해 항상 루트로 유지
    if (location.pathname === '/vience-canvas/storage') {
      if (curDir !== '') {
        setCurDir('');
      }
    } else {
      if (currentPath !== curDir) {
        setCurDir(currentPath);
      }
    }

    if (location.pathname === '/vience-canvas/storage/') {
      navigate('/vience-canvas/storage');
    } else if (
      location.pathname.endsWith('/') &&
      location.pathname !== '/vience-canvas/storage/'
    ) {
      const correctedPath = location.pathname.slice(0, -1);
      navigate(correctedPath);
    }
  }, [location, curDir, setCurDir, navigate]);

  const changeCurDir = () => {
    let newDir = '';

    if (name === '..') {
      const dirArray = curDir.split('/').filter(Boolean);
      dirArray.pop();
      newDir = dirArray.join('/');
    } else {
      newDir = curDir ? `${curDir}/${name}` : name;
    }

    setCurDir(newDir);

    // smc 라우터용 임시 처리
    if(!location.pathname.includes('/vience-canvas/storage')){
      return;
    }
    
    const targetPath = newDir
      ? `/vience-canvas/storage/${newDir}`
      : `/vience-canvas/storage`;
    navigate(targetPath);
  };

  const handleDoubleClick = () => {
    if (type === 'dir' || type === 'dataset' || type === 'model') {
      changeCurDir();
    } else {
      if (name.includes('py')) {
        const userEmail = localStorage.getItem('email');
        navigate('/vience-canvas/mlops/code-editor', {
          state: {
            workspace: {
              model_path: `/cloud/member/${userEmail}/${curDir}/${name}`,
              path: curDir,
              name: name,
            },
          },
        });
      } else {
        if (curDir === '') {
          window.open(`/vience-canvas/image/${name}`, '_blank');
        } else {
          window.open(`/vience-canvas/image/${curDir}/${name}`, '_blank');
        }
      }
    }
  };

  const doubleClickFineTuner = () => {
    if (type === 'dir' || type === 'dataset') changeCurDir();
    else {
      if (name.includes('py')) {
        const userEmail = localStorage.getItem('email');
        navigate('/vience-canvas/mlops/code-editor', {
          state: {
            workspace: {
              model_path: `/cloud/member/${userEmail}/${curDir}/${name}`,
              path: curDir,
              name: name,
            },
          },
        });
      } else {
        // dataset 설정하기
        setStep(1);
        setSelectedFile({ path: curDir, name: name, thumbnail: thumbnail });
      }
    }
  };

  const doubleClickSMC = (e) => {
    if (type === 'dir') changeCurDir();
    else {
      if (name.includes('py')) {
        const userEmail = localStorage.getItem('email');
        navigate('/vience-canvas/mlops/code-editor', {
          state: {
            workspace: {
              model_path: `/cloud/member/${userEmail}/${curDir}/${name}`,
              path: curDir,
              name: name,
            },
          },
        });
      } else {
        // 모달 띄우기
        setSmcModal(true);
        setSelectedFile({ path: curDir, name: name, thumbnail: thumbnail });
      }
    }
  };

  let title: any = name;
  if (keyword) {
    const index = name.toLowerCase().indexOf(keyword.toLowerCase());
    title = (
      <strong>
        {name.slice(0, index)}
        <span className='keyword-in-title' style={{ color: '#8D59FC' }}>
          {name.slice(index, index + keyword.length)}
        </span>
        {name.slice(index + keyword.length)}
      </strong>
    );
  }

  console.log('하위 디렉토리', file_progress);

  return (
    <Container
      className={`storage-card${selected.includes(name) ? '-selected' : ''}`}
      id={name}
      draggable={true}
      onClick={(e) => {
        e.stopPropagation();
        if (e.shiftKey) {
          setEndIndex(index);
        } else if (e.ctrlKey) {
          setSelected([...selected, name]);
        } else {
          setSelected([name]);
          setSelectedFile({ path: curDir, name: name, thumbnail: thumbnail });
          setStartIndex(index);
          setEndIndex(index);
          setContextOpen(false);
        }
      }}
      onDragStart={(e) => {
        setSelected([name]);
      }}
      onDragEnd={(e) => {
        setSelected([]);
      }}
      onDragOver={(e) => {
        if (dragTag.valid) {
          console.log('패널의 태그가 현재 hover된 위치', name);
        }
        if (type === 'dir' || type === 'dataset') {
          setTarget(name);
        } else {
          setTarget(undefined);
        }
      }}
      onDoubleClick={(e) => {
        switch (true) {
          case location.pathname.includes('storage'):
            handleDoubleClick();
            break;
          case location.pathname.includes('smc'):
            doubleClickSMC(e);
            break;
          default:
            doubleClickFineTuner();
            break;
        }
      }}
      onDragLeaveCapture={(e) => {
        // 마우스가 디렉토리에 hover 되지 않았을때 실행되는 함수
        setTarget(undefined);
        console.log('패널의 태그가 영역을 벗어났습니다.');
      }}
      onMouseLeave={(e) => {
        setTarget(undefined);
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onContextMenu={(e) => {
        if (index === -1) return;
        e.preventDefault();
        e.stopPropagation();
        setSelected([...selected, name]);
        setContext(e, info);
      }}
    >
      <div className='storage-card-title'>
        <div className='storage-card-title-left'>
          {
            {
              dir: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/datamanage/card/title/data-title-folder.svg'
                      : '/images/datamanage/card/title/data-title-folder-black.svg'
                  }
                  alt=''
                  className='data-title-folder'
                />
              ),
              dataset: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/datamanage/card/title/data-title-folder.svg'
                      : '/images/datamanage/card/title/data-title-folder-black.svg'
                  }
                  alt=''
                  className='data-title-folder'
                />
              ),
              model: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/datamanage/card/title/data-title-folder.svg'
                      : '/images/datamanage/card/title/data-title-folder-black.svg'
                  }
                  alt=''
                  className='data-title-folder'
                />
              ),
              image: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/datamanage/card/title/data-title-img.svg'
                      : '/images/datamanage/card/title/data-title-img-black.svg'
                  }
                  alt=''
                  className='data-title-img'
                />
              ),
              weight: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/datamanage/card/title/data-title-file.svg'
                      : '/images/datamanage/card/title/data-title-file-black.svg'
                  }
                  alt=''
                  className='data-title-file'
                />
              ),
              file: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/datamanage/card/title/data-title-file.svg'
                      : '/images/datamanage/card/title/data-title-file-black.svg'
                  }
                  alt=''
                  className='data-title-file'
                />
              ),
            }[type]
          }

          <p>{keyword ? title : name}</p>
        </div>
        <div className='storage-card-title-right'>
          {updated_at.slice(0, 10)}
        </div>
      </div>
      <div className='storage-container'>
        <div className='storage-progress'>
          {/* 임시 더미 퍼블리싱 */}
          {curDir === '' && (
            <>
              {tagging_progress &&
                tagging_progress.map((tagging_progress, index) => (
                  <div
                    className='circle'
                    title={tagging_progress[0]}
                    style={{
                      background: `conic-gradient(${tagging_progress[3]} ${360 * (tagging_progress[1] / tagging_progress[2])}deg, white 0deg)`,
                    }}
                  >
                    <div className='inner-circle'>
                      <span>
                        {tagging_progress[1]}/{tagging_progress[2]}
                      </span>
                    </div>
                  </div>
                ))}
            </>
          )}
          {/* 하위 디렉토리 */}
          {file_progress !== '' && name !== '..' && (
            <>
              {file_progress &&
                file_progress.map((fp, index) => (
                  <div
                    className='job-circle-complete'
                    style={
                      fp[2] === 'complete'
                        ? { backgroundColor: fp[1] }
                        : { border: `1px solid ${fp[1]}` }
                    }
                    key={fp[3]}
                  >
                    {fp[0]}
                  </div>
                ))}
            </>
          )}
        </div>
        <div className='storage-card-icon-frame'>
          {
            {
              dir:
                thumbnail === 'empty' ? (
                  <img
                    draggable='false'
                    src={
                      process.env.PUBLIC_URL +
                      '/images/datamanage/card/thumbnail/data-folder.svg'
                    }
                    alt=''
                    className='data-type-folder'
                  />
                ) : (
                  <img
                    draggable='false'
                    src={
                      process.env.PUBLIC_URL +
                      '/images/datamanage/card/thumbnail/data-folder-file.svg'
                    }
                    alt=''
                    className='data-type-folder-file'
                  />
                ),
              dataset: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL +
                    '/images/datamanage/card/thumbnail/data-folder-file.svg'
                  }
                  alt=''
                  className='data-type-folder-file'
                />
              ),
              model: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL +
                    '/images/datamanage/card/thumbnail/data-folder-file.svg'
                  }
                  alt=''
                  className='data-type-folder-file'
                />
              ),
              image: (
                <img
                  draggable='false'
                  src={`data:image/jpeg;base64,${thumbnail}`}
                  alt=''
                  className='data-type-img'
                />
              ),
              weight: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL +
                    '/images/datamanage/card/thumbnail/data-file.svg'
                  }
                  alt=''
                  className='data-type-file'
                />
              ),
              file: (
                <img
                  draggable='false'
                  src={
                    process.env.PUBLIC_URL +
                    '/images/datamanage/card/thumbnail/data-file.svg'
                  }
                  alt=''
                  className='data-type-file'
                />
              ),
            }[type]
          }
          <p className='storage-card-name'>{name}</p>
        </div>
      </div>
    </Container>
  );
}
