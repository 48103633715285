import TermsOfUse from "@/routes/usermanage/TermsOfUse";
import React, {useEffect} from "react";

const TermsOfUsePage = () => {
  useEffect(() => {
    const current = document.querySelector(`body`);
    current?.classList.add('overflow-auto');
    return () => {
      current?.classList.remove('overflow-auto');
    }
  }, []);
  return (
    <div style={{maxWidth:"1360px", margin:"auto", paddingLeft:'50px', paddingRight:'50px', color:'white'}}>
      <h1 style={{ textAlign: 'center', fontSize: '28px', fontWeight: '800' }}>
        Terms of Use (서비스 이용약관)
      </h1>
      <div>
        <TermsOfUse />
      </div>
    </div>
  );
};

export default TermsOfUsePage;