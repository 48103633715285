import React from 'react';
import './CustomComp.scss';

interface CustomTextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  label?: string;
  onChange: (e: any) => void;
}

export default function CustomTextarea({
  onChange,
  children,
  label,
  ...rest
}: CustomTextareaProps) {
  return (
    <>
      {label && <div className='text-[black] dark:text-[white] text-[16px] pb-3'>{label}</div>}
      <textarea
        className='custom-textarea-input'
        onChange={(e) => {
          onChange(e.target.value as string);
        }}
        {...rest}
      />
    </>
  );
}
