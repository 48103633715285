import React, { useState, useEffect, useContext } from 'react';
import { Cookies } from 'react-cookie';
import API from '@/apis/common/apis';
import { ClassicPreset, NodeId } from 'rete';
import { RadioGroup, Radio } from 'rsuite';
import { ICustomProcessing } from '../../filter';
import { IDataflow } from '../flow';
import './CustomProcessing.scss';
import { CustomSocket } from '../../sockets';
import { EffectorColor } from '../../style/CustomNode';
import CustomControl from '../../style/CustomControl';
// import { updateControl } from '../../../reteUtils';
import { NodeSize } from '../../style/CustomNode';
import { isTypedArray } from 'util/types';
export class CustomProcessingNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: CustomProcessingControl }
> {
  color = EffectorColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super('CustomProcessing');
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new CustomProcessingControl(process, nodeId ? nodeId : this.id));
  }

  data(inputs: { in: IDataflow[] }): { out: IDataflow } {
    if (!inputs['in']) {
      return {
        out: {
          img_paths: {},
          effector: {
            input: [],
            node: {},
          },
        },
      };
    }

    const baseInput = inputs['in'][0].img_paths;

    const effector = {
      input: [inputs['in'][0].effector],
      node: {
        custom_processing: {
          node_id: this.id,
          output_layout: this.controls.ctrl.props.option.outputLayout,
          output_type: this.controls.ctrl.props.option.outputType,
          version_id: this.controls.ctrl.props.option.version_id,
          user_import_list: this.controls.ctrl.props.option.user_import_list,
          path_list: this.controls.ctrl.props.option.path_list,
          user_code: this.controls.ctrl.props.option.user_code,
          console_log: this.controls.ctrl.props.option.console_log,
          error: this.controls.ctrl.props.option.error,
          img_path: baseInput,
          selected_category: this.controls.ctrl.props.option.selected_category,
          preveiw_img: this.controls.ctrl.props.option.preview_img,
          input_validity: this.controls.ctrl.props.option.input_validity,
          code_security: this.controls.ctrl.props.option.code_security,
          code_completion: this.controls.ctrl.props.option.code_completion,
          recent_version_id: this.controls.ctrl.props.option.recent_version_id,
          version_list: this.controls.ctrl.props.option.version_list,
        },
      },
    };

    const value = effector;
    const baseInputKey = Object.keys(baseInput)[0];
    const basePath = baseInput[baseInputKey];
    // const effector =  {
    //   input: [value.effector],
    //   node: {
    //     custom_processing: {
    //       node_id: this.id,
    //       output_layout: this.controls.ctrl.props.option.outputLayout,
    //       output_type: this.controls.ctrl.props.option.outputType,
    //       version_id: this.controls.ctrl.props.option.version_id,
    //       user_import_list: this.controls.ctrl.props.option.user_import_list,
    //       path_list: this.controls.ctrl.props.option.path_list,
    //       user_code: this.controls.ctrl.props.option.user_code,
    //       console_log: this.controls.ctrl.props.option.console_log,
    //       error: this.controls.ctrl.props.option.error,
    //       img_path: this.controls.ctrl.props.option.img_path,
    //       selected_category: this.controls.ctrl.props.option.selected_category,
    //       preveiw_img: this.controls.ctrl.props.option.preview_img,
    //       input_validity: this.controls.ctrl.props.option.input_validity,
    //       code_security: this.controls.ctrl.props.option.code_security,
    //       code_completion: this.controls.ctrl.props.option.code_completion,
    //       recent_version_id: this.controls.ctrl.props.option.recent_version_id,
    //     },
    //   },
    // };

    // const setStatus = async () => {
    //   const params = new URLSearchParams(location.search);

    //   const cookie = new Cookies();
    //   const api = new API(cookie);
    //   const response = await api.post('/module/check_status', {
    //     wor_id: params['wor_id'],
    //     img_paths: baseInput,
    //     effector: effector,
    //   });
    //   this.controls.ctrl.setStatus(response.data);
    // }
    // setStatus();

    // CustomProcessingControl에 value 설정
    if (this.controls.ctrl.props.option.path !== basePath) {
      this.controls.ctrl.setValue({
        value: value,
        path: basePath,
        title: this.controls.ctrl.props.option.title,
        outputLayout: this.controls.ctrl.props.option.outputLayout,
        outputType: this.controls.ctrl.props.option.outputType,
        version_id: this.controls.ctrl.props.option.version_id,
        user_import_list: this.controls.ctrl.props.option.user_import_list,
        path_list: this.controls.ctrl.props.option.path_list,
        user_code: this.controls.ctrl.props.option.user_code,
        console_log: this.controls.ctrl.props.option.console_log,
        error: this.controls.ctrl.props.option.error,
        img_path: baseInput,
        selected_category: this.controls.ctrl.props.option.selected_category,
        preview_img: this.controls.ctrl.props.option.preview_img,
        input_validity: this.controls.ctrl.props.option.input_validity,
        code_security: this.controls.ctrl.props.option.code_security,
        code_completion: this.controls.ctrl.props.option.code_completion,
        recent_version_id: this.controls.ctrl.props.option.recent_version_id,
        version_list: this.controls.ctrl.props.option.version_list,
      });
    }

    return {
      out: {
        img_paths: baseInput,
        effector: effector,
      },
    };
  }
}

export class CustomProcessingControl extends ClassicPreset.Control {
  props: {
    option: ICustomProcessing;
  };
  contextOpen: boolean;
  // status => 백그라운드에서 처리 상태
  // init: 처음 생성 / in-progress: 처리 중 / completed: 처리 완료 / error: 에러 발생생
  status: string;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      option: {
        value: { effector: { input: [], node: {} } },
        path: undefined,
        title: '',
        outputLayout: 'Single',
        outputType: '',
        version_id: '',
        user_import_list: [],
        selected_category: [],
        path_list: [],
        user_code: '',
        console_log: '',
        error: '',
        img_path: '',
        preview_img: [],
        input_validity: false,
        code_security: false,
        code_completion: false,
        version_list: {},
        recent_version_id: '',
      },
    };
    this.contextOpen = false;
    this.status = 'init';
  }

  setValue(val: ICustomProcessing) {
    this.props.option = val;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };

  setStatus = (status: string) => {
    this.status = status;
  };
}

export function CustomProcessingComp({ data }: { data: CustomProcessingControl }) {
  return (
    <>
      <CustomControl
        contextOpen={data.contextOpen}
        setContextOpen={data.setContextOpen}
        label='Custom Processing'
        nodeId={data.nodeId}
        markerSource={'node-effector'}
        iconSource='canvas-edge-code'
        status={data.status}
        setStatus={data.setStatus}
      />
    </>
  );
}
