
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { IfileNdirList } from '@/types/common/type';

export const switchDataset = async (api: ReturnType<typeof useAPIwithCookies>, target: IfileNdirList) => {
  return api.post('/deep_learning/dataset/switch', {
    path: target.path,
    name: target.name,
  });
};

export const switchModel = async (
  api: ReturnType<typeof useAPIwithCookies>,
  target: IfileNdirList,
  node: string
) => {
  return api.post('/deep_learning/model/switch', {
    path: target.path,
    dmo_name: target.name,
    node_name: node,
  });
};

export const switchWeight = async (
  api: ReturnType<typeof useAPIwithCookies>,
  target: IfileNdirList,
  dmo_seq: number
) => {
  return api.post('/deep_learning/weight/switch', {
    path: target.path,
    wei_name: target.name,
    dmo_seq: dmo_seq,
  });
};

// ** 베타테스트 배포를 위해 태그 관련 기능은 임시 주석 처리
// export const deleteTag = async (
//   api: ReturnType<typeof useAPIwithCookies>,
//   target: IfileNdirList,
//   tag: string
// ) => {
//   return api.post(`/tag/untagging/${target.name}/${tag}`);
// };

export const fetchModelList = async (api: ReturnType<typeof useAPIwithCookies>) => {
  const response = await api.get('/deep_learning/model_list');
  return response.data;
};

export const arrangeFileDirectory = async (api: ReturnType<typeof useAPIwithCookies>, mode: string) => {
  return api.post('/cloud/arrange', { arrange: mode });
};
