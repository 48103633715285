import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { copyNode, deleteNode } from '../../reteUtils';
import { NodeId } from 'rete';
import './CustomNode.scss';
import NodeContextMenu from './NodeContextMenu';

const ControlStyles = styled.div`
  position: relative;
  width: 182px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

const Marker = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
`;

const VerticalMenu = styled.button`
  position: absolute;
  right: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

interface CustomControlProps {
  label: string;
  nodeId: NodeId;
  markerSource:
    | 'node-source'
    | 'node-checker'
    | 'node-effector'
    | 'node-sink'
    | 'node-addon'
    | 'node-custom';
  iconSource: string;
  contextOpen: boolean;
  setContextOpen: (e: boolean) => void;
  status: string;
  setStatus: (e: string) => void;
}

export default function CustomControl({
  label,
  nodeId,
  markerSource,
  iconSource,
  contextOpen,
  setContextOpen,
  status,
  setStatus,
}: CustomControlProps) {
  let theme = localStorage.getItem('theme');
  if (theme === null) theme = 'dark';

  return (
    <ControlStyles>
      <Marker
        src={process.env.PUBLIC_URL + `/images/rete/node/marker/${status}/${markerSource}.svg`}
        alt={`marker-${markerSource}`}
      />
      <div className='flex justify-between items-center h-[100%]'>
        <img
          src={
            process.env.PUBLIC_URL + theme === 'dark'
              ? `/images/rete/node/icon/${iconSource}.svg`
              : `/images/rete/node/icon/${iconSource}-black.svg`
          }
          alt={`icon-${iconSource}`}
        />
        <p
          style={{
            color: theme === 'dark' ? '#FFFFFF' : '#2D2626',
            marginLeft: 10,
          }}
        >
          {label}
        </p>
        <VerticalMenu
          onPointerDown={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          onClick={() => {
            setContextOpen(!contextOpen);
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/images/menu-vertical.svg'}
            alt='Show More'
            style={{ marginLeft: '20px' }}
          />
        </VerticalMenu>
      </div>
      {contextOpen && (
        <NodeContextMenu
          nodeId={nodeId}
          onClose={setContextOpen}
          functions={[
            {
              label: 'Delete',
              on: () => {
                deleteNode(nodeId);
              },
            },
            {
              label: 'Copy',
              on: () => {
                copyNode(nodeId);
              },
            },
          ]}
        />
      )}
    </ControlStyles>
  );
}
