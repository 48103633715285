import React, { useEffect, useRef, useState } from 'react';
import { MaxPool2dControl } from '../../nodes/MaxPool2dNode';
import { ILayerOption } from '../../flow';
import MaxPool2dOptionSection from './MaxPool2dOptionSection';

export function MaxPool2dPanelContent({ ctrl }: { ctrl: MaxPool2dControl }) {
  const [loading, setLoading] = useState(true);

  // down
  const initOption = useRef<ILayerOption>({ nodeId: '' });
  // up
  const [option, setOption] = useState<ILayerOption>({ nodeId: '' });

  // 선택된 ctrl.id 바뀔때마다 ctrl에 저장된 옵션으로 바꾸기
  useEffect(() => {
    setLoading(true);

    setOption(ctrl.props.option);
    initOption.current = ctrl.props.option;

    setLoading(false);
  }, [ctrl.nodeId]);

  // 옵션이 바뀌면 ctrl에 저장
  useEffect(() => {
    if (!loading) {
      ctrl.setValue(option);
    }
  }, [option]);

  return (
    <>
      <div className='side-panel-normal'>
        <MaxPool2dOptionSection
          nodeId={ctrl.nodeId}
          initOption={initOption}
          option={option}
          setOption={setOption}
          loading={loading}
          setLoading={setLoading}
        />
      </div>

      <div className='side-panel-expand' id='#storage-grid'>
        <div className='inner'></div>
      </div>
    </>
  );
}
