import React, { useState, useContext, useRef } from 'react';
import { CustomThemeContext } from '@/contexts/common/Context';
import CommingSoonAlert from '@/components/common/alerts/CommingSoonAlert';

type CanvasSearchProps = {
  setKeyword?: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory?: string[];
  setSelectedCategory?: React.Dispatch<React.SetStateAction<string[]>>;
};

const Input = ({ setKeyword, selectedCategory, setSelectedCategory }: CanvasSearchProps) => {
  const { theme } = useContext(CustomThemeContext);
  const [show, setShow] = useState(false);
  const [showCategory, setShowCategory] = useState<string[] | null>(null);

  const category = ['segmentation', 'regression', 'denoising', 'super-resolution'];

  const handleClick = () => {
    if (show === true) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const inputEl = useRef<HTMLInputElement>(null);
  const handleChange: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const value = (e.target as HTMLInputElement).value;
    setTimeout(() => {
      if (value === inputEl.current?.value && setKeyword) {
        setKeyword(value);
      }
    }, 400);
  };

  const placeholders = {
    '/vience-canvas/datahub': 'Search in DataHub',
    '/vience-canvas/workspace': 'Search in Workspace',
    '/vience-canvas/mlops': 'Search in MLOps',
  };

  const getPlaceholder = () => placeholders[location.pathname] || 'Search...';

  return (
    <>
      <div className='flex items-center gap-[var(--10px)]'>
        <div
          className='relative flex h-[52px] w-full items-center gap-[var(--15px)]
          rounded-[var(--10px)] border border-black100 p-[10px] dark:border-gray200'
        >
          <button className='bg-transparent '>
            <img
              src={
                process.env.PUBLIC_URL + theme === 'dark'
                  ? '/images/ico-search.svg'
                  : '/images/ico-search-white.svg'
              }
              alt=''
              className='pl-[10px]'
            />
          </button>
          <input
            ref={inputEl}
            className='size-full bg-transparent
            text-sm font-thin text-black100
              placeholder:text-sm placeholder:font-thin placeholder:text-black100
              dark:text-white100 dark:placeholder:text-white100'
            placeholder={getPlaceholder()}
            onKeyUp={handleChange}
          />
        </div>

        <div className='relative rounded-[var(--10px)] border border-black100 dark:border-gray200'>
          <CommingSoonAlert>
            <button
              onClick={handleClick}
              className='border-black100bg-transparent
            box-border flex h-[52px] w-[122px] items-center
              gap-[var(--15px)] px-[var(--20px)] py-[var(--12px)] dark:border-gray200'
            >
              <img
                src={
                  process.env.PUBLIC_URL + theme === 'dark'
                    ? '/images/ico-filter.svg'
                    : '/images/ico-filter-white.svg'
                }
                alt=''
              />
              <span
                className='font-semibold tracking-[var(--spacingDefalt)]
              text-[var(--16px)] text-black300 dark:text-white100'
              >
                filter
              </span>
            </button>
          </CommingSoonAlert>
          {show && (
            <div
              className='border-gray200bg-white100 absolute
                right-0 top-[62px] z-10
                w-[122px] rounded-t-custom-lg text-black300
              dark:border-black100 dark:bg-black200 dark:text-white100'
            >
              <ul>
                <li
                  className='cursor-pointer rounded-t-custom-lg
                    border-x border-t border-gray200 bg-white100 p-[15px]
                    hover:bg-gray100 dark:border-black100 dark:bg-black200 dark:hover:bg-gray300'
                >
                  <span onClick={() => setShowCategory((prev) => (prev ? null : category))}>
                    Category
                  </span>
                </li>
                <li
                  className='cursor-pointer rounded-b-custom-lg
                    border-x border-b border-gray200 bg-white100 p-[15px]
                  hover:bg-gray100 dark:border-black100 dark:bg-black200 dark:hover:bg-gray300'
                >
                  <span>Date</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {showCategory && (
        <ul className='left-[calc(100%+var(--2px))] flex gap-[var(--15px)]'>
          {showCategory.map((v) => (
            <li
              key={v}
              className={`mt-[var(--12px)] rounded-custom-xl
                border border-gray200 p-[var(--12px)] ${
                  selectedCategory?.includes(v) ? 'bg-white100' : ''
                }`}
            >
              <button
                className='cursor-pointer border border-black100
                text-black300 dark:border-gray200 dark:text-white100'
                onClick={() => {
                  if (!setSelectedCategory) return;
                  setSelectedCategory((prev) => {
                    if (prev.includes(v)) {
                      return prev.filter((vv) => vv !== v);
                    } else {
                      return [...prev, v];
                    }
                  });
                }}
              >
                {v}
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Input;
