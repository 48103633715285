import React from 'react';
import { Link } from 'react-router-dom';
import './landingFooter.scss';

export const LandingFooter: React.FC = () => {
  return (
    <>
      <footer>
        <Link to='/vience-canvas'>
          <img
            className='w-[200px]'
            src={process.env.PUBLIC_URL + '/canvas-logo/logo-light-small.png'}
            alt='vience canvas'
          />
        </Link>
        <address>
          <p style={{ color: 'white' }}>
            643, Korea University R&D Center, Anam-ro 145, Seongbuk-gu, Seoul, Republic of Korea
          </p>
          <p style={{ color: 'white' }}>Email : contact@vience.co.kr</p>
        </address>
        <div style={{display:"flex", color: "white", fontSize: "16px", fontWeight:"800"}}>
          <Link to='/vience-canvas/terms-of-use'>
            Terms of Use
          </Link>
          <span style={{marginLeft:"10px", marginRight:"10px"}}> | </span>
          <Link to='/vience-canvas/privacy-policy'>
            Privacy Policy
          </Link>
        </div>
      </footer>
    </>
  );
};
