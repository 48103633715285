import React, { useState, useEffect, useContext, useRef, memo } from 'react';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { CustomThemeContext } from '@/contexts/common/Context';

import { FormGeneraterControl } from '../nodes/addon/FormGenerator';
import { AnyARecord } from 'dns';
import { json } from 'd3-fetch';

interface FormGeneratoerPanelProps {
  ctrl: FormGeneraterControl;
  expand: boolean;
  nodeId?: any;
  setPanelMode: any;
}

export const FormGeneraterPanel = memo(function FormGeneraterPanel({
  ctrl,
  nodeId,
  setPanelMode,
}: FormGeneratoerPanelProps) {
  const { theme } = useContext(CustomThemeContext);

  const handleOpenPanel = () => {
    setPanelMode('expand');
  };

  const [formOpen, setFormOpen] = useState<boolean | null>(null);

  const [activeItem, setActiveItem] = useState<any>(null);

  const [formDataStructure, setFormDataStructure] = useState<
    {
      listName: string;
      inputs: {
        type: string;
        question: string;
        contents: null | string[];
        answer: string | boolean[];
      }[];
    }[]
  >(ctrl.props.option.value);

  useEffect(() => {
    setFormDataStructure(ctrl.props.option.value);
  }, [ctrl.id]);

  // 저장
  useEffect(() => {
    ctrl.setValue({
      value: formDataStructure,
    });
  }, [formDataStructure]);

  // 리스트 추가
  // const idCounter = useRef(0); // ID

  const addList = () => {
    setFormDataStructure((prev) => [
      ...prev,
      {
        listName: Date.now().toString(36),
        inputs: [],
      },
    ]);
  };

  // 리스트 삭제
  const deleteList = (index: number) => {
    setFormDataStructure((prev) => {
      const newFormDataStructure = prev.filter((_, i) => i !== index);
      if (newFormDataStructure.length === 0) {
        setFormDataStructure([]);
      }
      return newFormDataStructure.length === 0
        ? [{ listName: '', inputs: [] }]
        : newFormDataStructure;
    });
  };

  // 리스트 이름 변경
  const updateListName = (index: number, newName: string) => {
    setFormDataStructure((prev) =>
      prev.map((list, i) => (i === index ? { ...list, listName: newName } : list)),
    );
  };

  // 해당 창 오픈픈
  const handleListClick = (item: any) => {
    setFormOpen(true);
    setActiveItem(item);
  };

  // test

  // 관련 폼 생성 => 현재는 type이 text랑 option selecter뿐
  const addInputToList = (listIndex: number, type: string) => {
    setFormDataStructure((prev) =>
      prev.map((list, i) =>
        i === listIndex
          ? {
              ...list,
              inputs: [
                ...list.inputs,
                {
                  type,
                  question: '',
                  contents: type === 'text' ? null : [],
                  answer: type === 'text' ? '' : [],
                },
              ],
            }
          : list,
      ),
    );
  };

  // 해당 폼 추가
  const removeInputFromList = (listIndex: number, inputIndex: number) => {
    setFormDataStructure((prev) =>
      prev.map((list, i) =>
        i === listIndex
          ? { ...list, inputs: list.inputs.filter((_, idx) => idx !== inputIndex) }
          : list,
      ),
    );
  };

  // 옵션 추가
  const addOptionToInput = (listIndex: number, inputIndex: number) => {
    setFormDataStructure((prev: any) =>
      prev.map((list, i) =>
        i === listIndex
          ? {
              ...list,
              inputs: list.inputs.map((input, j) =>
                j === inputIndex
                  ? {
                      ...input,
                      contents: [...(input.contents || []), ''],
                      answer: [...(input.answer || []), true],
                    }
                  : input,
              ),
            }
          : list,
      ),
    );
  };

  // 옵션 업데이트
  const updateOptionContent = (
    listIndex: number,
    inputIndex: number,
    optionIndex: number,
    newContent: string,
  ) => {
    setFormDataStructure((prev: any) =>
      prev.map((list, i) =>
        i === listIndex
          ? {
              ...list,
              inputs: list.inputs.map((input, j) =>
                j === inputIndex
                  ? {
                      ...input,
                      contents: input.contents?.map((option, k) =>
                        k === optionIndex ? newContent : option,
                      ),
                    }
                  : input,
              ),
            }
          : list,
      ),
    );
  };

  // 옵션 삭제제
  const removeOptionFromInput = (listIndex: number, inputIndex: number, optionIndex: number) => {
    setFormDataStructure((prev: any) =>
      prev.map((list, i) =>
        i === listIndex
          ? {
              ...list,
              inputs: list.inputs.map((input, j) =>
                j === inputIndex
                  ? {
                      ...input,
                      contents: input.contents?.filter((_, k) => k !== optionIndex),
                      answer: input.answer?.slice(0, -1), // answer도 마지막 원소 제거
                    }
                  : input,
              ),
            }
          : list,
      ),
    );
  };

  return (
    <>
      {/* normal 상태일때의 패널 */}
      <div className='flex flex-col w-full h-full p-5 mb-16 overflow-y-auto border-r-[2px] border-[#e3e3e3] dark:border-[#0f0f34]'>
        {/* FormGenerater processing description */}
        {/* <div className='text-[#181818] text-base font-semibold tracking-normal break-words dark:text-[#ffffff]'>
          Form Generater
        </div>

        <div className='text-[#b7b2c6]' style={{ marginTop: '15px' }}>
          This node creates a form for getting feedback
        </div>
        <hr className='mt-4' /> */}
        <div className='mb-4'>
          <div className='flex items-center gap-2'>
            <img
              src={
                process.env.PUBLIC_URL +
                (theme === 'dark'
                  ? '/images/rete/node/icon/clipboard.svg'
                  : '/images/rete/node/icon/clipboard-black.svg')
              }
              alt='Data Selector Icon'
            />
            <div className='text-2xl font-extrabold dark:text-[white]'>Form Generater</div>
          </div>
          <div className='text-lg font-light mt-1 dark:text-[white]'>Create a questionnaire</div>
        </div>

        <div
          className='subtitle mt-[var(--30px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          {' '}
          Form List
        </div>

        {/* 동적으로 생성된 리스트 출력 */}
        <div className='mt-4 space-y-2'>
          {formDataStructure.map((list, listIndex) => (
            <div
              key={listIndex}
              className={`rounded-[7px] p-4 bg-gray-100 cursor-pointer  dark:bg-[#131313] ${
                activeItem?.[1] === listIndex ? 'dark:bg-[#131313]' : 'dark:bg-[#131313]'
              }`}
              onClick={() => {
                handleListClick([list.listName, listIndex]);
                handleOpenPanel();
              }}
            >
              <div className='flex items-center justify-between '>
                <input
                  type='text'
                  value={list.listName}
                  onChange={(e) => updateListName(listIndex, e.target.value)}
                  className='bg-[#D3D3D3] dark:bg-[#3a3a3a] rounded px-2 py-1 w-1/1.5 dark:text-white cursor-pointer'
                />
                <button
                  onClick={() => deleteList(listIndex)}
                  className='bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition'
                >
                  Delete List
                </button>
              </div>
            </div>
          ))}
          <button
            className='mt-[var(--12px)] h-11
                      w-full cursor-pointer rounded-lg bg-purple200
                        text-[16px] text-white100 hover:text-white100 hover:brightness-75'
            onClick={addList}
          >
            Generate new form
          </button>
        </div>
      </div>

      {/* expand 상태일때의 패널 */}
      <div className='flex flex-col w-full h-full p-5 mb-16 overflow-y-auto border-r-[1px] border-[#0f0f34] '>
        <div className='flex flex-wrap justify-between gap-4 pb-6'>
          {formOpen && activeItem && formDataStructure[activeItem[1]] ? (
            <div className='w-full p-4'>
              <div className='text-[32px] font-semibold text-[#181818] dark:text-[#ffffff]'>
                Create '{activeItem[0]}' Form
              </div>
              <button
                onClick={() => addInputToList(activeItem[1], 'text')}
                className='bg-[#131313] text-white text-[17px] px-5 py-2 rounded-custom-xl hover:bg-[#0d0d0d] transition mr-2 mt-2'
              >
                Add Text
              </button>
              <button
                onClick={() => addInputToList(activeItem[1], 'choice')}
                className='bg-[#131313] text-white text-[17px] px-5 py-2 rounded-custom-xl hover:bg-[#0d0d0d]  transition mt-2'
              >
                Add Choice
              </button>

              <div className='mt-4 space-y-4'>
                {formDataStructure[activeItem[1]].inputs.map((input, inputIndex) => (
                  <div
                    key={inputIndex}
                    className='bg-[#ffffff] dark:text-white dark:bg-[#131313] rounded-[7px] border-[#ffffff] p-4'
                  >
                    <div className='justify-between items-center'>
                      <div className='flex justify-between'>
                        <p className='font-medium text-[19px]'>Type: {input.type}</p>
                        <button
                          onClick={() => removeInputFromList(activeItem[1], inputIndex)}
                          className='bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition'
                        >
                          Delete
                        </button>
                      </div>
                      <div className='flex mt-6'>
                        <label className='block font-medium text-[19px] '>Question:</label>
                        {input.type==='choice'?
                          <input
                            type='text'
                            value={input.question}
                            placeholder='Enter question'
                            onChange={(e) => {
                              setFormDataStructure((prev) =>
                                prev.map((list, listIndex) =>
                                  listIndex === activeItem[1]
                                    ? {
                                        ...list,
                                        inputs: list.inputs.map((inp, inpIndex) =>
                                          inpIndex === inputIndex
                                            ? { ...inp, question: e.target.value }
                                            : inp,
                                        ),
                                      }
                                    : list,
                                ),
                              );
                            }}
                            className='border h-[30px] rounded px-2 py-1 w-1/2 ml-4'
                          />
                          :
                          <textarea
                          value={input.question}
                          placeholder='Enter question'
                          onChange={(e) => {
                            setFormDataStructure((prev) =>
                              prev.map((list, listIndex) =>
                                listIndex === activeItem[1]
                                  ? {
                                      ...list,
                                      inputs: list.inputs.map((inp, inpIndex) =>
                                        inpIndex === inputIndex
                                          ? { ...inp, question: e.target.value }
                                          : inp,
                                      ),
                                    }
                                  : list,
                              ),
                            );
                          }}
                          className='border h-[200px] rounded px-2 py-1 w-1/2 ml-4 text-[black]'
                        />
                      }
                      </div>
                    </div>
                    {input.type === 'choice' && (
                      <div className='flex mt-6'>
                        <h4 className='font-medium font-medium text-[19px]'>Options:</h4>
                        <div className='w-full '>
                          {input.contents?.map((option, optionIndex) => (
                            <div key={optionIndex} className='flex items-center space-x-2 w-full'>
                              <input
                                type='text'
                                value={option}
                                placeholder='Enter options'
                                onChange={(e) =>
                                  updateOptionContent(
                                    activeItem[1],
                                    inputIndex,
                                    optionIndex,
                                    e.target.value,
                                  )
                                }
                                className='border rounded px-2 py-1 w-1/2 ml-7 mb-4'
                              />
                              <button
                                onClick={() =>
                                  removeOptionFromInput(activeItem[1], inputIndex, optionIndex)
                                }
                                className='bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition ml-8 mb-4'
                              >
                                Delete
                              </button>
                            </div>
                          ))}
                          <button
                            onClick={() => addOptionToInput(activeItem[1], inputIndex)}
                            className='bg-[#8B51FF] text-white px-3 py-1 rounded hover:bg-[#703fd2] transition ml-7'
                          >
                            Add Option
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
});
