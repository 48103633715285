import { useState } from 'react';
import useDataHubStore from '@/stores/datahub/useDataHubStore';

/**
 * 파일 업로드와 관련된 상태와 동작을 관리하는 커스텀 훅
 *
 * 파일 업로드 상태와 동작을 포함한 객체를 반환
 */
export const useFileUpload = () => {
  const [totalFileLen, setTotalFileLen] = useState(0);
  const [processedFileLen, setProcessedFileLen] = useState(0);

  const { loading, setLoading, progress, setProgress } = useDataHubStore();

  const [progressList, setProgressList] = useState<Record<string, number>>({});
  const [currentFilename, setCurrentFilename] = useState('');

  return {
    loading,
    setLoading,
    totalFileLen,
    setTotalFileLen,
    processedFileLen,
    setProcessedFileLen,
    progress,
    setProgress,
    progressList,
    setProgressList,
    currentFilename,
    setCurrentFilename,
  };
};
