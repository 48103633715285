import React, { useState, useEffect, useContext } from 'react';
import { ClassicPreset, NodeId } from 'rete';
import { RadioGroup, Radio } from 'rsuite';
import {
  ICanny,
  IEdge,
  IImageFilter,
  ILaplacian,
  ISobel,
  TEdgeType,
} from '../../filter';
import { IDataflow } from '../flow';
import './CustomProcessing.scss';
// import "../../../side-panel/side-panel.styles.scss";
import { CustomSocket } from '../../sockets';
// import { InputWithLabel } from "../../../../common/CustomInput";
import { EffectorColor } from '../../style/CustomNode';
import CustomControl from '../../style/CustomControl';
import { updateControl } from '../../../reteUtils';
import { NodeSize } from '../../style/CustomNode';
import { Editor, useMonaco } from '@monaco-editor/react';
import { CustomThemeContext } from '../../../../utils/Context';
import Swal from 'sweetalert2';
import { useAPIwithCookies } from '../../../../hooks/useApiCookies';

export class CustomProcessingNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: CustomProcessingControl }
> {
  color = EffectorColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super('CustomProcessing');
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl(
      'ctrl',
      new CustomProcessingControl(process, nodeId ? nodeId : this.id)
    );
  }

  data(inputs: { in: IDataflow[] }): { out: IDataflow } {
    const value = inputs['in'][0];

    // 입력된 것 출력
    console.log('CustomProcessing Node에서 입력 들어온 것:', value);

    // CustomProcessingControl에 value 설정
    this.controls.ctrl.setValue({
      edge: this.controls.ctrl.props.option.edge,
      value: value, // value를 저장
    });

    if (this.controls.ctrl.props.option.path !== value.path) {
      this.controls.ctrl.setValue({
        edge: undefined,
      });
    }

    return {
      out: {
        img_paths: value.img_paths,
        effector: {
          input: [value.effector],
          node: {
            custom_processing: {
              node_id: this.id,
              input_layout: 'Single',
              input_type: 'Image',
              output_layout: 'Single',
              output_type: 'Image',
            },
          },
        },
      },
    };
  }
}

export class CustomProcessingControl extends ClassicPreset.Control {
  props: {
    option: IImageFilter & { value?: IDataflow };
  };
  contextOpen: boolean;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId
  ) {
    super();
    this.props = {
      option: {
        edge: undefined,
        value: undefined,
      },
    };
    this.contextOpen = false;
  }

  setValue(val: IImageFilter & { value?: IDataflow }) {
    this.props.option = val;
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    updateControl(this.id);
  };
}

export function CustomProcessingComp({
  data,
}: {
  data: CustomProcessingControl;
}) {
  useEffect(() => {
    // CustomProcessing Node에서 전달된 value 확인
    if (data.props.option.value) {
      console.log(
        'CustomProcessing Node에서 전달된 value',
        data.props.option.value
      );
    }
  }, [data]);

  return (
    <>
      <CustomControl
        contextOpen={data.contextOpen}
        setContextOpen={data.setContextOpen}
        label='Custom Processing'
        nodeId={data.nodeId}
        markerSource={'node-effector'}
        iconSource='canvas-edge-code'
      />
    </>
  );
}

interface CustomProcessingPanelProps {
  ctrl: CustomProcessingControl;
  expand: boolean;
  nodeId?: any;
}

export const CustomProcessingPanel = ({
  ctrl,
  expand,
  nodeId,
}: CustomProcessingPanelProps) => {
  const { theme } = useContext(CustomThemeContext);
  const monaco = useMonaco();
  console.log(nodeId);

  // side panel 제목 입력 출력 코드
  const [title, setTitle] = useState('');
  const [inputLayout, setInputLayout] = useState('Single');
  const [inputType, setInputType] = useState('Image');
  const [outputLayout, setOutputLayout] = useState('Single');
  const [outputType, setOutputType] = useState('Image');
  const [checkToggle, setCheckToggle] = useState(true);

  const [code, setCode] = useState(`
    def hello_world():
        print("Hello, World!")

    def add_numbers(a, b):
        return a + b

    def factorial(n):
        if n == 0 or n == 1:
            return 1
        else:
            return n * factorial(n - 1)

    if __name__ == "__main__":
        hello_world()
        result = add_numbers(5, 10)
        print(f"5 + 10 = {result}")
        print(f"Factorial of 5 is {factorial(5)}")
  `);
  console.log(
    title,
    inputLayout,
    inputType,
    outputLayout,
    outputType,
    code,
    nodeId
  );

  // Ready
  // NeedCheck
  // Problem
  // Complete
  const [codeStatus, setCodeStatus] = useState('Ready');

  const api = useAPIwithCookies();

  //코드 호출
  useEffect(() => {
    // const getCodeStatus = async () => {
    //   const response = await api.post(`경로`); // 나중에 코드 api 로 변경
    //   console.log(response);
    //   // 분기처리
    // };
    // getCodeStatus();
  }, []);

  //코드 호출
  useEffect(() => {
    const getCustomProcessingCode = async () => {
      const response = await api.post(`/module/custom_code`, {
        node_id: nodeId,
        title: '',
        input_layout: inputLayout,
        input_type: inputType,
        output_layout: outputLayout,
        output_type: outputType,
      });

      console.log('코드 호출하고 받아온 결과 값', response.data);
      setCode(response.data);
    };

    getCustomProcessingCode();
  }, [inputLayout, inputType, outputLayout, outputType]);
  // 여닫
  const handleCodeCheck = async () => {
    setCheckToggle(false);
    try {
      const response = await api.post('/module/check_custom_code', {
        node_id: nodeId,
        code: code,
        title: '',
        input_layout: inputLayout,
        input_type: inputType,
        output_layout: outputLayout,
        output_type: outputType,
      });

      console.log('코드 체크에 대한 결과값', response.status);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Your code has been applied',
          showConfirmButton: false,
          timer: 1500,
        });
        setCheckToggle(true);
        setCodeStatus('Complete');
      }
    } catch (err: any) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        html: `<div style="color: black; text-align: left;">${err.detail.replace(/\n/g, '<br>')}</div>`,
      });
      setCheckToggle(true);
      setCodeStatus('Problem');
    }
  };

  useEffect(() => {
    if (monaco) {
      // 모나코 다크 테마
      monaco.editor.defineTheme('mlops-dark', {
        base: 'vs-dark',
        inherit: true,
        rules: [{ token: 'keyword', foreground: 'b47ef7' }],
        colors: {
          'editor.foreground': '#EDF9FA',
          'editor.background': '#010115',
          'editorCursor.foreground': '#b47ef7',
          'editor.lineHighlightBackground': '#0000FF20',
          'editorLineNumber.foreground': '#b47ef777',
          'editorLineNumber.activeForeground': '#b47ef7',
          'editor.selectionBackground': '#b47ef799',
          'editor.inactiveSelectionBackground': '#b47ef777',
          'editor.wordHighlightBackground': '#b47ef755',
        },
      });

      // 모나코 라이트 테마
      monaco.editor.defineTheme('mlops-light', {
        base: 'vs',
        inherit: true,
        rules: [{ token: 'keyword', foreground: 'b47ef7' }],
        colors: {
          'editor.foreground': '#202021',
          'editor.background': '#f0f2fd',
          'editorCursor.foreground': '#b47ef7',
          'editor.lineHighlightBackground': '#0000FF20',
          'editorLineNumber.foreground': '#b47ef777',
          'editorLineNumber.activeForeground': '#b47ef7',
          'editor.selectionBackground': '#b57ef75c',
          'editor.inactiveSelectionBackground': '#d6b3ff5f',
          'editor.wordHighlightBackground': '#969adf7b',
        },
      });
      if (theme === 'dark') {
        monaco.editor.setTheme('mlops-dark');
      }
      if (theme === 'light') {
        monaco.editor.setTheme('mlops-light');
      }
    }
  }, [monaco, theme]);

  return (
    <>
      <div className='side-panel-normal-code'>
        <div className='side-panel-normal-code-title'>Custom Processing</div>
        {/* 처음 생성되었거나 옵션 변경 후 유저가 코드 수정을 한번도 하지 않았을 경우 */}
        {codeStatus === 'Ready' && <p>Ready to write code</p>}

        {/* 코드를 수정했는데 code check를 진행하지 않았을 경우 */}
        {codeStatus === 'NeedCheck' && <p>Please perform a code check</p>}

        {/* 결과 코드에 문제가 있을 경우 (추후 code check api 연결) */}
        {codeStatus === 'Problem' && <p>Some problem with your code</p>}

        {/* 결과 문제 없을 경우 (추후 code check api 연결) */}
        {codeStatus === 'Complete' && <p>code completed!</p>}

        <hr></hr>
        <div className='side-panel-normal-code-title'>Title</div>
        <input
          className='side-panel-normal-code-input'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder='Please enter a title.'
        />
        <hr></hr>
        <div className='side-panel-normal-code-title'>Input</div>
        <div className='side-panel-layout-dropdown'>
          <label htmlFor='input-layout-select' className='layout-label'>
            ※ Layout
          </label>
          <select
            id='input-layout-select'
            className='layout-select'
            value={inputLayout}
            onChange={(e) => setInputLayout(e.target.value)}
          >
            <option value='Single'>Single</option>
            <option value='List'>List</option>
            <option value='Grid'>Grid</option>
          </select>
        </div>
        <div className='side-panel-layout-dropdown'>
          <label htmlFor='input-type-select' className='layout-label'>
            ※ Type
          </label>
          <select
            id='input-type-select'
            className='layout-select'
            value={inputType}
            onChange={(e) => setInputType(e.target.value)}
          >
            <option value='Image'>Image</option>

            <option value='Number'>Number</option>
            <option value='Vector'>Vector</option>
          </select>
        </div>
        <hr></hr>
        <div className='side-panel-normal-code-title'>Output</div>
        <div className='side-panel-layout-dropdown'>
          <label htmlFor='output-layout-select' className='layout-label'>
            ※ Layout
          </label>
          <select
            id='output-layout-select'
            className='layout-select'
            value={outputLayout}
            onChange={(e) => setOutputLayout(e.target.value)}
          >
            <option value='Single'>Single</option>
            <option value='List'>List</option>
            <option value='Grid'>Grid</option>
          </select>
        </div>
        <div className='side-panel-layout-dropdown'>
          <label htmlFor='output-type-select' className='layout-label'>
            ※ Type
          </label>
          <select
            id='output-type-select'
            className='layout-select'
            value={outputType}
            onChange={(e) => setOutputType(e.target.value)}
          >
            <option value='Number'>Number</option>
            <option value='Vector'>Vector</option>
            <option value='Image'>Image</option>
          </select>
        </div>
      </div>

      <div className='side-panel-expand'>
        <Editor
          defaultLanguage='python'
          value={code}
          theme={theme === 'dark' ? 'mlops-dark' : 'mlops-light'}
          onChange={(value) => {
            setCode(value || '');
            setCodeStatus('NeedCheck');
          }}
          options={{
            fontSize: 16,
            lineHeight: 24,
            roundedSelection: true,
            contextmenu: true,
            wordWrap: 'on',
            fontLigatures: true,
          }}
        />
        {checkToggle && (
          <nav className='code-check' onClick={handleCodeCheck}>
            Code Check
          </nav>
        )}
      </div>
    </>
  );
};
