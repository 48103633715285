import React from 'react';

import { createNode } from '../../../../rete-mlops/reteMlopsUtils';

export default function MlopsControlBtn({data}: {
  data: {
    label: string;
    category: string;
    iconSource: string;
  }
}) {
  let bgColor: string = "";

  switch (data.category) {
    case "Source": 
      bgColor = "#6320B8";
      break;
    case "Layer":
      switch(data.label){
        case "Convolution 2D":
          bgColor = "#1F7781";
          break;
        case "BatchNorm 2D":
          bgColor = "#31AEBC";
          break;  
        case "Relu Activation":
          bgColor = "#50C8D2";
          break;
        case "Fully Connected":
          bgColor = "#5C7DCC";
          break;
        }
      break;
    case "Sink":
      bgColor = "#B92931";
      break;
    case "AddOn":
      bgColor = "#0F8D49";
      break;
    case "Resize":
      bgColor = "#ADAA5D";
      break;
  }

  return (
    <button 
      className="control-button data-control-button" 
      onClick={(e) => {createNode(data.label,undefined,{x:0,y:e.clientY})}}
      style={{backgroundColor: bgColor}}
    >
      <div className="control-icon-file">
        {/* 데이터에 url 추가하기 process.env.PUBLIC_URL + data.path */}
        <img 
          src={process.env.PUBLIC_URL + `/images/rete/node/icon/${data.iconSource}.svg`} 
          alt={data.label + "A button to create a node"} 
        />
      </div>
      <span className="button-text">
        {data.label}
      </span>
    </button>
  )
}
