import './Register.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Button } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import SelectBox from '@/components/common/SelectBox';
import { InputWithLabel, InputWithAlert } from '@/components/common/CustomInput';
import CustomModal from '@/components/common/CustomModal';
import Mode from '../../components/mode/Mode';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import useHandleGoogleLogin from '@/hooks/useHandleGoogleLogin';
import Swal from 'sweetalert2';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';

type TRegisterForm = {
  email: string;
  pw: string;
  name: string;
  mem_id: string;
  receive_yn: boolean;
  organization: string;
  org_name: string;
  job: string;
  purpose: string;
  access_code: string;
};

type TOrganization =
  | ''
  | 'Business & Company'
  | 'Medical Institution'
  | 'University'
  | 'School'
  | 'Individual'
  | 'Others';
type TJob = '' | 'Doctor' | 'Researcher' | 'Developer' | 'Student' | 'Employee' | 'Others';
type TPurpose =
  | ''
  | 'Data Management'
  | 'Data Processing'
  | 'Deep Learning'
  | 'Benchmarking'
  | 'Demo';

const organizationOption: { id: string; name: TOrganization }[] = [
  { id: 'register-organization-1', name: 'Business & Company' },
  { id: 'register-organization-2', name: 'Medical Institution' },
  { id: 'register-organization-3', name: 'University' },
  { id: 'register-organization-4', name: 'School' },
  { id: 'register-organization-5', name: 'Individual' },
  { id: 'register-organization-6', name: 'Others' },
];

const occupationOption: { id: string; name: TJob }[] = [
  { id: 'register-job-1', name: 'Doctor' },
  { id: 'register-job-2', name: 'Researcher' },
  { id: 'register-job-3', name: 'Developer' },
  { id: 'register-job-4', name: 'Student' },
  { id: 'register-job-5', name: 'Employee' },
  { id: 'register-job-6', name: 'Others' },
];

const purposeOption: { id: string; name: TPurpose }[] = [
  { id: 'register-purpose-1', name: 'Data Management' },
  { id: 'register-purpose-2', name: 'Data Processing' },
  { id: 'register-purpose-3', name: 'Deep Learning' },
  { id: 'register-purpose-4', name: 'Benchmarking' },
  { id: 'register-purpose-5', name: 'Demo' },
];

export default function Register() {
  const navigate = useNavigate();
  const api = useAPIwithCookies();
  const { googleLogin } = useHandleGoogleLogin();

  // const [step, setStep] = useState(0);
  const [step, setStep] = useState(0); // 지금은 잠시 작업을 위해서 기본값1로 함함

  const [name, setName] = useState<{ first: string; last: string }>({
    first: '',
    last: '',
  });
  // const [password, setPassword] = useState<{ pw: string; confirm: string }>({
  //   pw: '',
  //   confirm: '',
  // });
  const [passwordShown, setPasswordShown] = useState<{
    pw: boolean;
    confirm: boolean;
  }>({ pw: false, confirm: false });
  const [temp, setTemp] = useState<{ email: string; mem_id: string }>({
    email: '',
    mem_id: '',
  });
  const [availableCheck, setAvailableCheck] = useState<{
    email: boolean;
    mem_id: boolean;
  }>({ email: false, mem_id: false });

  // const [emailAlert, setEmailAlert] = useState<{
  //   alert: boolean;
  //   alertLabel: string;
  // }>({ alert: false, alertLabel: '' });
  // const [passwordAlert, setPasswordAlert] = useState<{
  //   alert: boolean;
  //   alertLabel: string;
  // }>({ alert: false, alertLabel: '' });

  // const [accessCode, setAccessCode] = useState<string>('');
  // const [accessCodeAlert, setAccessCodeAlert] = useState<{
  //   alert: boolean;
  //   alertLabel: string;
  // }>({ alert: false, alertLabel: '' });

  // Modal Handle
  const [termModalOpen, setTermModalOpen] = useState<boolean>(false);
  const [policyModalOpen, setPolicyModalOpen] = useState<boolean>(false);

  const checkRegExr = (email: string) => {
    const reg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

    return reg.test(email);
  };

  const [form, setForm] = useState<TRegisterForm>({
    email: '',
    pw: '',
    name: '',
    mem_id: '',
    receive_yn: true,

    organization: '',
    org_name: '',
    job: '',
    purpose: '',
    access_code: '',
  });

  // 나중에 여기서, Eamil 검증

  const checkAvailability = async (type: 'accessCode' | 'email' | 'mem_id') => {
    if (type === 'email') {
      if (!checkRegExr(temp.email)) {
        setEmailAlert({
          alert: true,
          alertLabel: 'Please re-enter your email.',
        });
        return;
      }
    }
    const response = await api.get('/member/available', { [type]: temp[type] });

    if (response.data === false) {
      setEmailAlert({
        alert: true,
        alertLabel:
          type === 'email' ? 'Please re-enter your email.' : 'Please re-enter your nickname.',
      });
    } else {
      setAvailableCheck({ ...availableCheck, [type]: true });
      setForm({ ...form, [type]: temp[type] });
      setEmailAlert({ alert: true, alertLabel: 'Available' });
    }
  };

  // const disability = (!availableCheck.email) || (password.pw !== password.confirm) || (form.pw.length === 0);

  // 나중에 여기서, AccessCode 검증
  const register = async () => {
    try {
      await api.post('/member/join', form);
      alert('Welcome to Vience-Canvas');
      navigate('/vience-canvas/overview');
    } catch (err) {
      console.error(err);
    }
  };

  const handleTermModal = () => {
    setTermModalOpen(!termModalOpen);
  };

  const handlePolicyModal = () => {
    setPolicyModalOpen(!policyModalOpen);
  };

  // ✅✅✅ 호출하는 부분 api
  const handleStep = () => {
    setStep(1);
  };

  // ✅✅✅ 가입하기
  const handleJoin = async () => {
    const result = await Swal.fire({
      title: 'Do you agree to provide personal information?',
      text: 'Your personal information will be used for registration purposes.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, I agree',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        const response = await api.post('/member/join', {
          email: email,
          pw: password.pw,
          name: name.first + name.last,
          nickname: nickname,
          google_yn: false,
          recieve_yn: form.receive_yn,
          organization: form.organization,
          org_name: form.org_name,
          job: form.job,
          purpose: form.purpose,
          access_code: accessCode,
        });

        if (response.statue == 200) {
          Swal.fire('Success!', 'You have successfully registered.', 'success');
          navigate('/vience-canvas');
        }
      } catch (error) {
        console.error('Join Failed:', error);
        Swal.fire('Error', 'Failed to register. Please try again.', 'error');
      }
    }
  };

  // useEffect(() => {
  //   if (password.pw === password.confirm) setForm({ ...form, pw: password.pw });
  //   else setForm({ ...form, pw: '' });
  // }, [password]);

  // useEffect(() => {
  //   setForm({ ...form, name: `${name.first} ${name.last}` });
  // }, [name]);

  // useEffect(() => {
  //   setForm({ ...form, access_code: accessCode });
  // }, [accessCode]);

  // ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅
  const [accessCode, setAccessCode] = useState('');
  const [accessCodeAlert, setAccessCodeAlert] = useState({ alert: false, alertLabel: '' });
  const [isAccessCodeValid, setIsAccessCodeValid] = useState(false);
  const [isCheckingAccessCode, setIsCheckingAccessCode] = useState(false);

  const [email, setEmail] = useState('');
  const [emailAlert, setEmailAlert] = useState({ alert: false, alertLabel: '' });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeAlert, setVerificationCodeAlert] = useState({
    alert: false,
    alertLabel: '',
  });

  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [password, setPassword] = useState({ pw: '', confirm: '' });
  const [passwordAlert, setPasswordAlert] = useState({ alert: false, alertLabel: '' });

  const handlePasswordChange = (key: 'pw' | 'confirm', value: string) => {
    setPassword((prev) => {
      const updatedPassword = { ...prev, [key]: value };

      if (updatedPassword.pw && updatedPassword.confirm) {
        if (updatedPassword.pw === updatedPassword.confirm) {
          setPasswordAlert({ alert: true, alertLabel: '✅ Success' });
        } else {
          setPasswordAlert({ alert: true, alertLabel: '❌ Passwords do not match.' });
        }
      } else {
        setPasswordAlert({ alert: false, alertLabel: '' });
      }

      return updatedPassword;
    });
  };

  const [isVerifying, setIsVerifying] = useState(false);

  const isContinueEnabled = isAccessCodeValid && isEmailVerified && password.pw && password.confirm;

  // access code 인증하는 로직
  const checkAccessCode = async () => {
    if (!accessCode.trim()) {
      setAccessCodeAlert({ alert: true, alertLabel: 'Access Code cannot be empty.' });
      return;
    }

    setIsCheckingAccessCode(true);
    try {
      const response = await api.post('/member/check_access_code', { code: accessCode });

      if (response.data) {
        setIsAccessCodeValid(true);
        setAccessCodeAlert({ alert: true, alertLabel: '✅ Success' });
      } else {
        setAccessCodeAlert({ alert: true, alertLabel: '❌ Invalid Access Code.' });
      }
    } catch (error) {
      console.error('API Error:', error);
      setAccessCodeAlert({ alert: true, alertLabel: '❌ An error occurred. Try again.' });
    } finally {
      setIsCheckingAccessCode(false);
    }
  };

  // 이메일 인증하는 로직
  const requestEmailVerification = async () => {
    if (!email.trim()) {
      setEmailAlert({ alert: true, alertLabel: 'Please enter your email.' });
      return;
    }

    // 정규표현식
    if (!email.trim()) {
      if (!checkRegExr(email)) {
        setEmailAlert({ alert: true, alertLabel: 'Please enter your email.' });
        return;
      }
    }

    setIsCheckingEmail(true);
    try {
      const response = await api.post('/member/request_authentication_code_for_register', {
        email: email,
      });
      if (response.data) {
        // 이거 상태코드 나중에 바꿔야할듯
        setIsEmailSent(true);
        setEmailAlert({ alert: true, alertLabel: '✅ Verification code sent. Check your email!' });
      } else {
        setEmailAlert({ alert: true, alertLabel: '❌ This email has already been registered!' });
      }
    } catch (error) {
      setEmailAlert({ alert: true, alertLabel: '❌ Failed to send verification code.' });
    } finally {
      setIsCheckingEmail(false);
    }
  };

  // 인증코드 적용 로직
  const verifyEmailCode = async () => {
    if (!verificationCode.trim()) {
      setVerificationCodeAlert({ alert: true, alertLabel: 'Please enter the verification code.' });
      return;
    }

    setIsVerifying(true);
    try {
      const response = await api.post('/member/check_authentication_code_for_register', {
        email: email,
        auth_code: verificationCode,
      });

      if (response.data) {
        setIsEmailVerified(true);
        setVerificationCodeAlert({ alert: true, alertLabel: '✅ Email verified successfully!' });
      } else {
        setVerificationCodeAlert({ alert: true, alertLabel: '❌ Invalid verification code.' });
      }
    } catch (error) {
      setVerificationCodeAlert({ alert: true, alertLabel: '❌ Verification failed.' });
    } finally {
      setIsVerifying(false);
    }
  };

  const [nickname, setNickname] = useState('');
  const [nicknameAlert, setNicknameAlert] = useState({ alert: false, alertLabel: '' });
  const [isNicknameChecked, setIsNicknameChecked] = useState(false);
  const [isCheckingNickname, setIsCheckingNickname] = useState(false);

  const checkNickname = async () => {
    if (!nickname.trim()) {
      setNicknameAlert({ alert: true, alertLabel: '❌ Nickname cannot be empty.' });
      return;
    }

    setIsCheckingNickname(true);
    try {
      const response = await api.get(`/member/available?email=${email}&mem_id=${nickname}`);
      if (response.data) {
        setNicknameAlert({ alert: true, alertLabel: '✅ Success' });
        setIsNicknameChecked(true);
      } else {
        setNicknameAlert({ alert: true, alertLabel: '❌ Nickname is already taken.' });
      }
    } catch (error) {
      console.error('API Error:', error);
      setNicknameAlert({ alert: true, alertLabel: '❌ Server error. Try again later.' });
    } finally {
      setIsCheckingNickname(false);
    }
  };

  return (
    <div id='register' className='register'>
      <Mode />
      {step === 0 ? (
        <div className='frame'>
          <div className='register-container'>
            <p
              className='prev'
              onClick={() => {
                window.history.back();
              }}
            >
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M19.9702 12H5.97021'
                  stroke='white'
                  strokeWidth='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.9702 19L5.97021 12L12.9702 5'
                  stroke='white'
                  strokeWidth='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </p>
            <div className='titleFrame'>
              <strong>Create your account</strong>
              <p>
                Note that phone or email verfication may be required for signup. Your information
                will only be used to verify your identity for security purposes.
                <br />* The current version is in beta. To sign up, you need a previously provided
                access code.
              </p>
            </div>

            <form
              className='register-form'
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* Access Code */}
              <div className='register-info-input'>
                <img src='/images/ico-key.svg' alt='key' />
                <InputWithLabel
                  value={accessCode}
                  onChange={(e) => setAccessCode(e)}
                  placeholder='Access Code'
                  disabled={isAccessCodeValid}
                />

                {!isAccessCodeValid && (
                  <button
                    className='duplicate'
                    onClick={checkAccessCode}
                    disabled={!accessCode.trim() || isCheckingAccessCode}
                  >
                    {isCheckingAccessCode ? 'Checking...' : 'Check'}
                  </button>
                )}
              </div>
              {accessCodeAlert.alert && (
                <div
                  className={`mt-2 pb-2 ${isAccessCodeValid ? 'text-green-500' : 'text-red-500'}`}
                >
                  {accessCodeAlert.alertLabel}
                </div>
              )}

              {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
              {/* Email Verification */}
              {isAccessCodeValid && (
                <>
                  <div className='register-info-input'>
                    <img src='/images/ico-mail.svg' alt='mail' />
                    <InputWithLabel
                      value={email}
                      onChange={(e) => setEmail(e)}
                      placeholder='Email'
                      disabled={isEmailSent}
                    />
                    {!isEmailSent && (
                      <button
                        className='duplicate'
                        onClick={requestEmailVerification}
                        disabled={!email.trim() || isCheckingEmail}
                      >
                        {isCheckingEmail ? 'Sending...' : 'Send Code'}
                      </button>
                    )}
                  </div>
                  {emailAlert.alert && (
                    <div className={`mt-2 pb-2 ${isEmailSent ? 'text-green-500' : 'text-red-500'}`}>
                      {emailAlert.alertLabel}
                    </div>
                  )}
                </>
              )}
              {/* Email Verification Code */}
              {isEmailSent && (
                <>
                  <div className='register-info-input'>
                    <img src='/images/ico-key.svg' alt='key' />

                    <InputWithLabel
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e)}
                      placeholder='Enter Code'
                      disabled={isEmailVerified}
                    />
                    {!isEmailVerified && (
                      <button
                        className='duplicate'
                        onClick={verifyEmailCode}
                        disabled={!email.trim() || isVerifying}
                      >
                        {isVerifying ? 'Verifying...' : 'Verify'}
                      </button>
                    )}
                  </div>
                  {verificationCodeAlert.alert && (
                    <div
                      className={`mt-2 pb-2 ${isEmailVerified ? 'text-green-500' : 'text-red-500'}`}
                    >
                      {verificationCodeAlert.alertLabel}
                    </div>
                  )}
                </>
              )}
              {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}

              {/* Password Fields */}
              {isEmailVerified && (
                <>
                  <div className='register-info-input'>
                    <img src='/images/ico-pw.svg' alt='password' />
                    <InputWithLabel
                      type='password'
                      value={password.pw}
                      onChange={(e) => handlePasswordChange('pw', e)}
                      placeholder='Password'
                    />
                  </div>

                  <div className='register-info-input'>
                    <img src='/images/ico-pw.svg' alt='password confirm' />
                    <InputWithLabel
                      type='password'
                      value={password.confirm}
                      onChange={(e) => handlePasswordChange('confirm', e)}
                      placeholder='Confirm Password'
                    />
                  </div>

                  {passwordAlert.alert && (
                    <div
                      className={`mt-2 ${passwordAlert.alertLabel === '✅ Success' ? 'text-green-500' : 'text-red-500'}`}
                    >
                      {passwordAlert.alertLabel}
                    </div>
                  )}
                </>
              )}

              {/* Continue Button */}
              {passwordAlert.alertLabel === '✅ Success' && (
                <button
                  className='mt-4 rounded-lg bg-[#8D59FC] px-6 py-3 text-white transition hover:bg-[#7e42ff] dark:bg-[#8D59FC] hover:dark:bg-[#7e42ff]'
                  onClick={handleStep}
                >
                  Continue
                </button>
              )}
              {/* <button
                className={`mt-4 px-6 py-3 text-white rounded-lg transition ${
                  isContinueEnabled
                    ? 'bg-[#8D59FC] dark:bg-[#8D59FC] hover:bg-[#7e42ff] dark:bg-[#7e42ff]'
                    : 'bg-[#b9b9b9] dark:bg-[#060A20] cursor-not-allowed'
                }`}
                onClick={handleStep}
                disabled={!isContinueEnabled}
              >
                Continue
              </button> */}

              <div className='userComent'>
                <span>Already have an account?</span>
                <Link to='/vience-canvas/login'>
                  <strong>Sign In</strong>
                </Link>
              </div>
            </form>
            {/* 
            <div className='sectionLine'>or</div> */}

            {/* <div className='googleButton'>
                                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY as string}>
                                    <GoogleLogin
                                        onSuccess={(res) => { handleGoogleLogin(res) }}
                                        onError={() => { console.error('error') }}
                                    />
                                </GoogleOAuthProvider>
                            </div> */}
            {/* <div className='google-btn' onClick={() => { googleLogin(); navigate('/vience-canvas/workspace'); }}> */}
            {/* <div className='google-btn'>
              <img src={process.env.PUBLIC_URL + '/images/google-logo.svg'} alt='' />
              <span>Sign in with Google</span>
            </div> */}
          </div>
        </div>
      ) : (
        <div className='frame'>
          <div className='register-container'>
            <p
              className='prev'
              onClick={() => {
                setStep(0);
              }}
            >
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M19.9702 12H5.97021'
                  stroke='white'
                  strokeWidth='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.9702 19L5.97021 12L12.9702 5'
                  stroke='white'
                  strokeWidth='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </p>
            <div className='titleFrame'>
              <strong>Tell us about you</strong>
            </div>

            <form
              className='register-form'
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* 이름받기 */}
              <div className='register-info-input'>
                <InputWithLabel
                  value={name.first}
                  onChange={(e) => {
                    setName({ ...name, first: e });
                  }}
                  placeholder='First Name'
                ></InputWithLabel>

                <InputWithLabel
                  value={name.last}
                  onChange={(e) => {
                    setName({ ...name, last: e });
                  }}
                  placeholder='Last Name'
                ></InputWithLabel>
              </div>
              {/* 


              {/* 닉네임 받기 */}
              <div className='register-info-input'>
                <InputWithLabel
                  value={nickname}
                  onChange={(e) => setNickname(e)}
                  placeholder='Nickname'
                  disabled={isNicknameChecked}
                />
                {!isNicknameChecked && (
                  <button
                    className='duplicate'
                    onClick={checkNickname}
                    disabled={!nickname.trim() || isCheckingNickname}
                  >
                    {isCheckingNickname ? 'Checking...' : 'Duplicate check'}
                  </button>
                )}
              </div>

              {nicknameAlert.alert && (
                <div
                  className={`mt-2 pb-2 ${isNicknameChecked ? 'text-green-500' : 'text-red-500'}`}
                >
                  {nicknameAlert.alertLabel}
                </div>
              )}

              {/* 조직 선택 */}
              <SelectBox
                options={organizationOption}
                onChange={(e) => {
                  setForm({ ...form, organization: e as TOrganization });
                }}
                containerStyle={{ width: '100%' }}
                children='Organization'
              />

              {/* 조직 입력 */}
              <div className='register-info-input'>
                <InputWithLabel
                  value={form.org_name}
                  onChange={(e) => {
                    setForm({ ...form, org_name: e });
                  }}
                  placeholder='Organization Name'
                ></InputWithLabel>
              </div>

              {/* 직업 선택 */}
              <SelectBox
                options={occupationOption}
                onChange={(e) => {
                  setForm({ ...form, job: e as TJob });
                }}
                containerStyle={{ width: '100%' }}
                children='job'
              />

              {/* 사용목적 */}
              <SelectBox
                options={purposeOption}
                onChange={(e) => {
                  setForm({ ...form, purpose: e as TPurpose });
                }}
                containerStyle={{ width: '100%' }}
                children='Purpose'
              />

              {/* 이메일 수신 동의 */}
              <div className='checkboxFrame mt-4'>
                <input
                  type='checkbox'
                  id='receive-advertising'
                  defaultChecked
                  value={String(form.receive_yn)}
                  onChange={() => {
                    setForm({ ...form, receive_yn: !form.receive_yn });
                  }}
                />
                <label htmlFor='receive-advertising'>Receive marketing emails</label>
              </div>

              {/* 가입하기 */}
              {name.first &&
              name.last &&
              isNicknameChecked &&
              form.organization &&
              form.org_name &&
              form.job &&
              form.purpose ? (
                <button onClick={handleJoin} className='primaryFill mt-4'>
                  Join
                </button>
              ) : (
                <div className='mt-2 text-[12px] text-black dark:text-white'>
                  <div>※ Complete your registration by filling out the information below.</div>
                  <div className='mt-2 flex w-full flex-wrap gap-1'>
                    {[
                      !name.first && 'First Name',
                      !name.last && 'Last Name',
                      !isNicknameChecked && 'Nickname Check',
                      !form.organization && 'Organization',
                      !form.org_name && 'Organization Name',
                      !form.job && 'Job',
                      !form.purpose && 'Purpose',
                    ]
                      .filter(Boolean)
                      .map((item, index) => (
                        <span
                          className='w-fit rounded-[12px] border border-red-500 px-2 text-[10px] text-red-500'
                          key={index}
                        >
                          {item}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </form>
            {/* <div className='mt-2 text-red-500 text-sm font-medium'>
              <ul className='list-disc list-inside mt-1'>
                {[
                  !name.first && 'First Name',
                  !name.last && 'Last Name',
                  !isNicknameChecked && 'Nickname Check',
                  !form.organization && 'Organization',
                  !form.org_name && 'Organization Name',
                  !form.job && 'Job',
                  !form.purpose && 'Purpose',
                ]
                  .filter(Boolean)
                  .map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
              </ul>
            </div> */}

            {/* 정책 */}
            <div className='use-frame'>
              <button className='normalOutline' onClick={handleTermModal}>
                Terms
              </button>
              <button className='normalOutline' onClick={handlePolicyModal}>
                Privacy Policy
              </button>
            </div>
            <CustomModal
              open={termModalOpen}
              onClose={handleTermModal}
              bgShown='dark'
              bgColor='#010116'
            >
              <h1 className='modalTitle' style={{ textAlign: 'center' }}>
                Terms of Use (서비스 이용약관)
              </h1>
              <div className='modalContent'>
                <TermsOfUse />
              </div>
              <button onClick={handleTermModal} className='primaryFill'>
                Confirm
              </button>
            </CustomModal>
            <CustomModal
              open={policyModalOpen}
              onClose={() => {
                setPolicyModalOpen(false);
              }}
            >
              <h1 className='modalTitle' style={{ textAlign: 'center', fontSize: '28px' }}>
                Privacy Policy (개인정보처리방침)
              </h1>
              <div className='modalContent'>
                <PrivacyPolicy />
              </div>
              <button onClick={handlePolicyModal} className='primaryFill'>
                Confirm
              </button>
            </CustomModal>
          </div>
        </div>
      )}
    </div>
  );
}
