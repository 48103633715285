import React, { useContext } from 'react';

import { DataHubCard } from '@/components/datahub/datahub-body/datahub-item/datahub-card';

import { CustomThemeContext } from '@/contexts/common/Context';
import useDataHubStore from '@/stores/datahub/useDataHubStore';
import useContextMenuStore from '@/stores/datahub/useDataHubMenuStore';

import { useNavigatePath } from '@/hooks/dataHub/useNavigatePath';
import { useItemClick } from '@/hooks/dataHub/useItemClick';

import { IDataHubItem } from '@/types/datahub/type';

export default function DataHubItem({ info, keyword, index }: IDataHubItem) {
  const { theme } = useContext(CustomThemeContext);
  const { selected, setSelected, setTarget } = useDataHubStore();

  const { setContext } = useContextMenuStore();
  const { handleDoubleClickItem, handleClickItem } = useItemClick({ info });
  useNavigatePath();

  const handleDrag = (name: string) => {
    if (!selected.includes(info.name)) {
      setSelected([info.name]);
    } else {
      setSelected([...selected, info.name]);
    }
  };

  return (
    <div
      className={`flex h-[12.66rem] w-full flex-col rounded-custom-xl px-[15px] pb-[18px] pt-[20px] sm:h-[19rem] ${
        selected.includes(info.name)
          ? 'rounded-lg border-2 border-purple200 bg-purple200'
          : 'rounded-lg border-2 border-gray100 dark:border-black100'
      }`}
      id={info.name}
      draggable={true}
      onClick={(e) => {
        handleClickItem(e, index, info.name);
      }}
      onDragStart={() => {
        handleDrag(info.name);
      }}
      onDragEnd={() => setSelected([])}
      onDragOver={() =>
        setTarget(info.type === 'dir' || info.type === 'dataset' ? info.name : undefined)
      }
      onDoubleClick={handleDoubleClickItem}
      onDragLeaveCapture={() => setTarget(undefined)}
      onMouseLeave={() => setTarget(undefined)}
      onMouseDown={(e) => e.stopPropagation()}
      onContextMenu={(e) => {
        if (index === -1) return;
        e.preventDefault();
        e.stopPropagation();
        setSelected([info.name]);
        setContext(e, info);
      }}
    >
      <DataHubCard
        name={info.name}
        type={info.type}
        theme={theme}
        keyword={keyword}
        thumbnail={info.thumbnail}
        updated_at={info.updated_at}
        tagging_progress={info.tagging_progress}
        file_progress={info.file_progress}
      />
    </div>
  );
}
