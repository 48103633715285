// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#osd-viewer {
  width: 100%;
  height: 100%;
  padding: var(--10px);
  border-radius: 10px;
  color: #eee;
  box-sizing: border-box;
  background: #181839;
}

.viewer-panel-image-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.viewer-panel-image {
  width: 300px;
  height: 300px;
  padding: var(--10px);
  border-radius: 10px;
  color: #eee;
  box-sizing: border-box;
  background: #181839;
  display: flex;
  margin: auto;
}

.viewer-osd {
  border: 1px blue solid;
}

.viewer-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px red solid;
}

.viewer-current-annotation {
  grid-area: currentAnno;
  border: 1px black solid;
}

.viewer-annotation {
  border: 1px blue solid;
  grid-area: annotation;
  border: 1px blue solid;
}

.viewer-annotation-input {
  display: flex;
  width: 40%;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/rete/components/nodes/sink/viewer.styles.scss"],"names":[],"mappings":"AAaA;EACE,WAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;AAZF;;AAcA;EACE,YAAA;EACA,aAAA;EACA,8BAAA;EACA,sBAAA;AAXF;;AAcA;EACE,YAAA;EACA,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AAXF;;AAcA;EACE,sBAAA;AAXF;;AAcA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AAXF;;AAcA;EACE,sBAAA;EACA,uBAAA;AAXF;;AAcA;EACE,sBAAA;EACA,qBAAA;EACA,sBAAA;AAXF;;AAcA;EACE,aAAA;EACA,UAAA;EACA,eAAA;AAXF","sourcesContent":["// .viewer-system {\n//     display: grid;\n//     width: 120vh;\n//     height: 80vh;\n//     border: 1px black solid;\n//     grid-template-rows: 3fr 4fr 3fr;\n//     grid-template-columns: 2fr 1fr;\n//     grid-template-areas:\n//     \"viewer gridinfo\"\n//     \"viewer currentAnno\"\n//     \"viewer annotation\";\n// }\n\n#osd-viewer {\n  width: 100%;\n  height: 100%;\n  padding: var(--10px);\n  border-radius: 10px;\n  color: #eee;\n  box-sizing: border-box;\n  background: #181839;\n}\n.viewer-panel-image-container {\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n\n.viewer-panel-image {\n  width: 300px;\n  height: 300px;\n  padding: var(--10px);\n  border-radius: 10px;\n  color: #eee;\n  box-sizing: border-box;\n  background: #181839;\n  display: flex;\n  margin: auto;\n}\n\n.viewer-osd {\n  border: 1px blue solid;\n}\n\n.viewer-grid {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border: 1px red solid;\n}\n\n.viewer-current-annotation {\n  grid-area: currentAnno;\n  border: 1px black solid;\n}\n\n.viewer-annotation {\n  border: 1px blue solid;\n  grid-area: annotation;\n  border: 1px blue solid;\n}\n\n.viewer-annotation-input {\n  display: flex;\n  width: 40%;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
