import React, { ReactNode, useEffect, useState } from 'react'
import { AreaExtensions } from 'rete-area-plugin';
import { area, editor } from '@/components/rete-mlops/editor';
import { NodeTypes } from '@/components/rete-mlops/editor';

import MlopsControlBtnCategory from './MlopsControlBtnCategory';
import { btnData, categories } from './btnData';
import NodeListItem from './NodeListItem';
import styled from 'styled-components';

const Container = styled.div`
  .total-number {
    padding: var(--16px);
  }
`;

export default function MlopsControlPanel() {
  const [selectedTab, setSelectedTab] = useState('Control Panel');
  const [nodesList, setNodesList] = useState<NodeTypes[]>();
  const [nodesListEls, setNodesListEls] = useState<ReactNode[]>();

  useEffect(() => {
    if (selectedTab === 'Layers') {
      const newNodesList = editor?.getNodes();
      setNodesList(newNodesList);
    }
  }, [selectedTab, editor])

  useEffect(() => {
    const newNodeListEls = nodesList?.map(v => {
      const iconSource = findIconSource(v.label);
      return (
        <NodeListItem 
          findNode={() => findNode(v.id)}
          iconSource={iconSource}
          data={v}
        />
      )
    })
    setNodesListEls(newNodeListEls);
  }, [nodesList])

  const handleClick = (e) => {
    setSelectedTab(e.target.textContent);
  }

  const findNode = (nodeId) => {
    const node = editor.getNode(nodeId);
    AreaExtensions.zoomAt(area, [node], {scale: 0.5});
  }

  const findIconSource = (type: string) => {
    const iconSource = btnData.find(v => v.label === type);
    return iconSource?.iconSource;
  }
  
  return (
    <Container 
      className="main-canvas-left"
    >
      <header className='control-panel-header'>
        <button 
          className={selectedTab === 'Control Panel' ? 'selected' : ''}
          type="button" 
          onClick={handleClick}
        >
          Control Panel
        </button>
        <button   
          className={selectedTab === 'Layers' ? 'selected' : ''}
          type="button" 
          onClick={handleClick}
        >
          Layers
        </button>
      </header>

      
      {selectedTab === 'Control Panel' && 
        <ul className='control-panel'>
          {
            categories.map(category => {
            const filteredData = btnData.filter(data=> data.category === category.name);
              return (
                <MlopsControlBtnCategory 
                  key={category.name}
                  categoryData={category} 
                  btnData={filteredData}
                />
              );
            })
          }
        </ul>
      }

      {selectedTab === 'Layers' && <ul className='control-panel'>
        <p className='total-number'>{nodesList?.length} layers</p>
        {nodesListEls}
      </ul>
      }
    </Container>
  )
}
