import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useCookies } from 'react-cookie';

import { DataSelectorControl } from '@/components/rete/components/nodes/data-selector';
import DataSelectorCard from './cards/DataSelectorCard';
import Directory from '@/components/rete/components/nodes/data-selector/panel/items/Directory';
import EmptyDirImg from '@/components/common/images/EmptyDirImg';

import API from '@/apis/common/apis';
import { CustomThemeContext } from '@/contexts/common/Context';
import { getThumbnailIcon } from '@/components/rete/utils/rete/utils';

// 실제 서버에서 내려오는 파일/디렉토리 목록 구조
export interface IfileNdirList {
  name: string; // 파일이나 디렉토리 이름
  type: string; // dir, file 등
  updated_at: string; // 날짜와 시간 (추후 정렬용)
  thumbnail?: string; // 썸네일 경로(있을 경우)
}

// 선택된 파일/디렉토리 정보
interface ISelected {
  path: string; // 선택된 파일/디렉토리의 부모 디렉토리 경로
  name: string; // 선택된 파일/디렉토리 이름
  thumbnail: string;
  type: string; // dir, file 등
  dataType?: string; // 추가적으로 구분할 항목 필요하면 사용
}

export default function DataSelectorPanel({ ctrl }: { ctrl: DataSelectorControl }) {
  const [cookies] = useCookies(['refresh']);
  const { theme } = useContext(CustomThemeContext);
  const api = new API(cookies);

  /**
   * path
   *   - expand panel에서 현재 표시중인 경로를 관리하는 상태
   *   - pngTest/1.png 처럼 맨 앞에 슬래시 없이 관리
   *   - 이렇게 해야 normal panel에서 열림/닫힘 로직과 호환하기 좋을듯
   *   - e.g. '', 'pngTest', 'pngTest/1.png' 식으로만 쓰면, join('pngTest','1.png')
   *   -> 'pngTest/1.png'로 단순하게 처리 가능
   *   - 내부적으로는 슬래시 없이 처리 -> 마지막에 Rete Control과 동기화할 때만 앞에 '/'를 붙여서 저장해 경로 관리!
   */
  const [path, setPath] = useState<string>(() => {
    if (!ctrl.props.option.path) return '';
    // 만약 ctrl.props.option.path가 '/pngTest/1.png'라면
    // 맨 앞의 슬래시를 제거해 => 'pngTest/1.png'
    const pathWithoutSlash = ctrl.props.option.path.replace(/^\/+/, '');
    return pathWithoutSlash;
  });

  /**
   * fileNdirList
   *   - 아래 fetchExpandData()로 expand panel에서 보여줄 디렉토리/파일 목록
   *   - path가 바뀔 때마다 서버에 요청해서 해당 디렉토리 내용을 가져온다 -> path를 depandancy array에 넣고 useEffect로 처리
   *   - normal panel(트리) 쪽은 Directory.tsx에서 별도 API를 호출하여 목록을 가져온다
   */
  const [fileNdirList, setFileNdirList] = useState<IfileNdirList[]>([]);

  /**
   * selected
   *   - 현재 선택된 파일 or 디렉토리 정보를 관리하는 상태
   *   - normal panel과 expand panel이 함께 공유하는 '전역적인 선택 상태'
   *   - 한쪽 Panel에서 setSelected를 호출하면 => 다른 Panel에서도 이 정보를 써서
   *     하이라이트(보라색) 표시를 동기화한다!
   */
  const [selected, setSelected] = useState<ISelected>({
    path: '',
    name: '',
    thumbnail: '',
    type: '',
  });

  /**
   * openedPaths
   *   - normal panel에서 펼쳐진 디렉토리 경로 목록을 관리하는 상태
   *   - e.g. ['', 'pngTest', 'pngTest/1.png'] => root와 'pngTest/1.png' 디렉토리가 열려 있다.
   *   - expand panel에서 어떤 파일을 선택하면 -> 아래 openParentDirs()로
   *     상위 디렉토리를 모두 openedPaths에 추가 -> normal panel에서도 해당 파일이 보이도록 함!
   */
  const [openedPaths, setOpenedPaths] = useState<string[]>([]);

  /**
   * isLoading
   * expand panel에서 데이터를 불러오는 동안 로딩 스피너를 표시할지 여부
   */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * tooltip, isOverflow, textRef
   * 파일/디렉토리 이름이 길 때 Tooltip을 띄우기 위한 관리용 상태들
   */
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const lastFetchedPathRef = useRef<string>('');

  // ---------------------------------------------------------------
  // fetchExpandData
  //   expand panel에서 'path가 변경될 때마다' 서버에 요청하여
  //   해당 디렉토리의 목록(파일/디렉토리들)을 가져오는 함수
  // ---------------------------------------------------------------
  const fetchExpandData = useCallback(
    async (dir: string) => {
      if (dir === lastFetchedPathRef.current && fileNdirList.length > 0) {
        return;
      }
      setIsLoading(true);
      try {
        const response = await api.get('/cloud/list', { cur_dir: dir });
        setFileNdirList(response.data);

        // 성공적으로 데이터를 불러왔으면 lastFetchedPath 업데이트
        lastFetchedPathRef.current = dir;
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [fileNdirList.length],
  );

  // ---------------------------------------------------------------
  // openParentDirs
  //   한쪽 panel(특히 expand)에서 특정 파일의 전체 경로('pngTest/1.png')를 선택했을 때
  //   normal panel에서도 그 파일이 보이도록 상위 디렉토리들을 모두 열어준다.
  //
  //   - fullPath: e.g. 'png상위/png하위/1.png'
  //   - seg: ['png상위', 'png하위', '1.png']
  //   - needed: ['', 'png상위', 'png상위/png하위'] (마지막인 '1.png'는 파일이므로 제외)
  //     => openPaths에 순차적으로 추가
  // ---------------------------------------------------------------
  const openParentDirs = async (fullPath: string) => {
    const seg = fullPath.split('/').filter(Boolean); // 경로 세그먼트
    const needed: string[] = ['']; // 항상 루트('')는 열려있다고 가정
    let cur = '';
    // 끝(파일명) 전까지 디렉토리만 누적
    for (let i = 0; i < seg.length - 1; i++) {
      cur = cur ? `${cur}/${seg[i]}` : seg[i];
      needed.push(cur);
    }

    setOpenedPaths((prev) => {
      // 기존에 열려있는 디렉토리 목록에 새로운 디렉토리들을 추가
      const newSet = new Set(prev);
      needed.forEach((dir) => newSet.add(dir));
      return Array.from(newSet);
    });
  };

  // ---------------------------------------------------------------
  // 상위 디렉토리 이동 (이전 디렉토리로 이동) 버튼 클릭 시 실행할 함수
  // ---------------------------------------------------------------
  const goBackDirectory = async () => {
    const seg = path.split('/').filter(Boolean); // 현재 path
    const upPath = seg.slice(0, -1).join('/'); // 마지막 요소 제외 -> 상위 경로

    // expand panel 경로 갱신
    setPath(upPath);
    await fetchExpandData(upPath);

    // normal panel 트리에서도 해당 상위 경로가 보이도록
    await openParentDirs(upPath);

    // selected도 상위 경로로 맞추거나, 혹은 해제
    const seg2 = upPath.split('/').filter(Boolean);
    const lastDir = seg2[seg2.length - 1] || '';

    setSelected({
      path: seg2.slice(0, -1).join('/'),
      name: lastDir,
      thumbnail: '',
      type: 'dir', // 상위 디렉토리는 기본적으로 'dir'로 취급
    });
  };

  // ---------------------------------------------------------------
  // 파일/디렉토리 타입별로 썸네일 클래스를 반환하는 함수
  // ---------------------------------------------------------------
  const getThumbnailClass = (type: string) => {
    switch (type) {
      case 'dir':
      case 'dataset':
      case 'form_output':
        return 'w-[5.4375rem] rounded-custom-xl object-cover';
      case 'image':
        return 'size-[18.75rem] rounded-custom-xl object-contain';
      case 'vmeta':
        return 'w-[5.4375rem] rounded-custom-xl object-cover';
      case 'file':
        return 'w-[4.4535rem]';
      default:
        // 기타 타입
        return 'size-[18.75rem] rounded-custom-xl object-contain';
    }
  };

  // ---------------------------------------------------------------
  // "어느 Data Selector"인지 바뀔 때(ctrl.id)
  // => 새 ctrl.props.option.path를 expand panel 상태에 반영
  // ---------------------------------------------------------------
  useEffect(() => {
    const newFullPanel = ctrl.props.option.path || '';
    const noSlash = newFullPanel.replace(/^\/+/, ''); // 우선 leading slash 제거
    const seg = noSlash.split('/').filter(Boolean);

    if (seg.length === 0) {
      setPath(''); // 루트
      fetchExpandData(''); // 파일목록 새로고침
      setOpenedPaths((prev) => (prev.includes('') ? prev : [...prev, '']));
      // selected도 루트로
      setSelected({
        path: '',
        name: '',
        thumbnail: ctrl.props.option.thumbnail || '',
        type: ctrl.props.option.type || '',
      });
    } else {
      // 마지막이 파일/디렉토리 이름
      const fileName = seg[seg.length - 1];
      const parentPath = seg.slice(0, seg.length - 1).join('/');

      // expand panel 경로
      setPath(parentPath);
      fetchExpandData(parentPath);
      // normal panel 트리도 열어주기
      openParentDirs(parentPath);

      setSelected({
        path: parentPath,
        name: fileName,
        thumbnail: ctrl.props.option.thumbnail || '',
        type: ctrl.props.option.type || '',
      });
    }
  }, [ctrl.id]);

  // ---------------------------------------------------------------
  // (1) 컴포넌트 마운트 시
  //   - normal panel에서 루트('') 디렉토리를 펼치도록 설정
  //   - expand panel에서 path === '' 이면 루트 목록을 가져옴
  // ---------------------------------------------------------------
  useEffect(() => {
    setOpenedPaths((prev) => (prev.includes('') ? prev : [...prev, '']));
    if (path === '') {
      fetchExpandData('');
    }
  }, []);

  // ---------------------------------------------------------------
  // (2) path가 바뀔 때 => expand panel 목록 재호출
  // ---------------------------------------------------------------
  useEffect(() => {
    fetchExpandData(path);
  }, [path]);

  // ---------------------------------------------------------------
  // (3) Rete Ctrl에서 넘어온 path(ctrl.props.option.path)가
  //     변경되었을 때 => selected 업데이트
  // ---------------------------------------------------------------
  useEffect(() => {
    const fullP = ctrl.props.option.path || '';
    // 만약 '/png상위/png하위/1.png' 라면 -> 'png상위/png하위/1.png'
    const noSlash = fullP.replace(/^\/+/, '');
    const seg = noSlash.split('/').filter(Boolean);

    if (seg.length === 0) {
      // 루트
      setSelected({
        path: '',
        name: '',
        thumbnail: ctrl.props.option.thumbnail || '',
        type: ctrl.props.option.type || '',
      });
    } else {
      // 마지막은 파일/디렉토리 이름
      const fileName = seg[seg.length - 1];
      // 나머지는 부모 경로
      const parentPath = seg.slice(0, seg.length - 1).join('/');
      setSelected({
        path: parentPath,
        name: fileName,
        thumbnail: ctrl.props.option.thumbnail || '',
        type: ctrl.props.option.type || '',
      });
    }
  }, [ctrl.id]);

  // ---------------------------------------------------------------
  // (4) selected가 바뀔 때 => Rete Control에도 저장 (슬래시 없는 경로에 '/'를 붙여서)
  // ---------------------------------------------------------------
  useEffect(() => {
    if (!selected.name) {
      ctrl.setValue({
        path: '',
        thumbnail: '',
        type: '',
        imgSize: {
          width: ctrl.props.option.imgSize.width,
          height: ctrl.props.option.imgSize.height,
        },
      });
      return;
    }
    // prettier-ignore
    const newFullPanelath = selected.path ? `${selected.path}/${selected.name}` : selected.name;
    // 'png상위/png하위/1.png'
    // Rete에서는 원래 '/png상위/png하위/1.png'로 저장했으므로, 앞에 '/' 추가
    ctrl.setValue({
      path: `/${newFullPanelath}`,
      thumbnail: selected.thumbnail,
      type: selected.dataType ?? selected.type,
      imgSize: { width: ctrl.props.option.imgSize.width, height: ctrl.props.option.imgSize.height },
    });
  }, [selected]);

  // ---------------------------------------------------------------
  // (tooltip) selected.name이 너무 길 때, 마우스 오버 시 Tooltip 표시
  // ---------------------------------------------------------------
  useEffect(() => {
    if (textRef.current) {
      setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [selected.name]);

  // ---------------------------------------------------------------
  // 렌더링
  // ---------------------------------------------------------------
  return (
    <>
      {/* --------------------- normal panel --------------------- */}
      <div className='side-panel-normal'>
        <div className='side-panel-normal-section'>
          <div className='datamanage-section'>
            <div className='datamanage-img-title-wrapper'>
              <div className='datamanage-img'>
                <img
                  src={
                    process.env.PUBLIC_URL + theme === 'dark'
                      ? '/images/rete/node/icon/datamanage.svg'
                      : '/images/rete/node/icon/datamanage-black.svg'
                  }
                  alt='Data Selector Icon'
                />
                <div className='datamanage-title'>Data Selector</div>
              </div>
              <div className='datamanage-subtitle'>Select the Data</div>
            </div>
            <div id='datamanage-thumbnail-container'>
              {getThumbnailIcon(selected.dataType ?? selected.type ?? '', selected.thumbnail) !==
              '' ? (
                <img
                  src={getThumbnailIcon(
                    selected.dataType ?? selected.type ?? '',
                    selected.thumbnail,
                  )}
                  alt={`${selected.dataType ?? selected.type} thumbnail`}
                  className={getThumbnailClass(selected.dataType ?? selected.type ?? '')}
                />
              ) : (
                <p className='text-gray300 text-sm'>No Thumbnail Available</p>
              )}
            </div>
            <div className='datamanage-file-name'>
              <span className='datamanage-file-name-title'>Selected:</span>
              <span
                ref={textRef}
                onMouseEnter={() => isOverflow && setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className='selected-file-name'
              >
                {selected.name}
                {showTooltip && <div className='tooltip'>{selected.name}</div>}
              </span>
            </div>
          </div>
        </div>

        <div className='side-panel-normal-filedirlist'>
          <ul id='datamanage-filedirlist'>
            {/* 루트 디렉토리 => (name='', path='') */}
            <Directory
              name=''
              path=''
              depth={0}
              dataType='dir'
              selected={{
                ...selected,
                dataType: selected.dataType ?? '',
              }}
              setSelected={async (sel: ISelected) => {
                // normal panel에서 어떤 항목을 클릭했을 때 => expand panel도 변경
                setSelected(sel);
                const fullPath = sel.path ? `${sel.path}/${sel.name}` : sel.name;

                // 디렉토리면 => expand panel도 해당 path로
                // 파일이면 => expand panel은 상위 디렉토리로
                if (sel.type === 'dir' || sel.type === 'dataset' || sel.type === 'model') {
                  setPath(fullPath);
                  await fetchExpandData(fullPath);
                } else {
                  setPath(sel.path);
                  await fetchExpandData(sel.path);
                }

                // normal panel 트리도 openParentDirs로 열어서 선택한 항목이 보이게
                await openParentDirs(fullPath);
              }}
              openPaths={openedPaths}
              setOpenPaths={setOpenedPaths}
            />
          </ul>
        </div>
      </div>

      {/* --------------------- expand panel --------------------- */}
      <div className='side-panel-expand'>
        <div className='inner'>
          {/* 상위 디렉토리로 이동 버튼 */}
          <div className='flex items-center'>
            <button
              className='rounded-custom-xl bg-white100 text-black100
              dark:bg-black300 dark:text-white100 flex cursor-pointer 
                items-center gap-2 p-2 px-3 py-1 text-[16px]'
              onClick={goBackDirectory}
            >
              &lt; {/* '<'를 표시하기 위한 이스케이프 문자열 */}
              <span className='text-black100 dark:text-white100 p-1 text-[16px]'>Go Back</span>
            </button>
          </div>
          {isLoading ? (
            <div
              className='text-gray300 dark:text-white100 flex size-full
                items-center justify-center text-xl font-thin'
            >
              ⌛ Loading ...
            </div>
          ) : (
            <div className='storage-card-frame'>
              {fileNdirList.length === 0 ? (
                <EmptyDirImg label='The Directory is empty' />
              ) : (
                fileNdirList.map((data) => (
                  <DataSelectorCard
                    key={data.name}
                    name={data.name}
                    path={path}
                    selected={selected}
                    setSelected={async (sel: ISelected) => {
                      // expand panel에서 파일/디렉토리 클릭 => normal panel도 연동
                      setSelected(sel);

                      const fullPath = sel.path ? `${sel.path}/${sel.name}` : sel.name;
                      // 해당 항목이 normal panel 트리에서도 보이도록 열기
                      await openParentDirs(fullPath);
                    }}
                    info={data}
                    onDirChange={async (newDir) => {
                      // expand panel에서 디렉토리 더블클릭
                      setPath(newDir);
                      await fetchExpandData(newDir);

                      // normal panel에서도 열어주기
                      await openParentDirs(newDir);

                      // 새로 선택된 디렉토리 => selected 업데이트
                      const seg = newDir.split('/').filter(Boolean);
                      const lastSeg = seg[seg.length - 1] || '';
                      setSelected({
                        path: seg.slice(0, -1).join('/'),
                        name: lastSeg,
                        thumbnail: data.thumbnail || '',
                        type: data.type,
                      });
                    }}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
