import React, { useState, useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import API from '@/apis/common/apis';
import '../nodes/custom/DatasetConfiguration.scss';
import { DatasetConfigurationControl } from '../nodes/custom/DatasetConfiguration';
import topNode from '../../utils/topNode';
import renderTree from '../../utils/renderTree';
import Swal from 'sweetalert2';
import { CustomThemeContext } from '@/contexts/common/Context';

interface DatasetConfigurationPanelProps {
  ctrl: DatasetConfigurationControl;
  expand: boolean;
  nodeId?: any;
}
interface ResponseData {
  [key: string]: { [key: string]: string };
}
export function DatasetConfigurationPanel({
  ctrl,
  expand,
  nodeId,
}: DatasetConfigurationPanelProps) {
  const [cookies, setCookie] = useCookies(['refresh']);
  const api = new API(cookies);
  const { theme } = useContext(CustomThemeContext);

  const [inputValues, setInputValues] = useState<any>(ctrl.props.option.inputData);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!ctrl.props.option.value?.length) {
        return;
      }
      const module_list = ctrl.props.option.value;
      console.log(module_list);
      try {
        const response = await api.post(`/dataset_config/combine_input`, module_list);

        setInputValues(response.data.result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [ctrl.props.option.value]);

  // useEffect(() => {
  //   ctrl.setValue({
  //     cloudPath: inputValues,
  //   });
  // }, [inputValues]);

  const [dataSetId, setDataSetId] = useState<Record<string, string>>(ctrl.props.option.inputKey);

  const handleDataSetIdChange = (key: string, value: string) => {
    setDataSetId((prev) => ({
      ...prev,
      [key]: value, // key 기반으로 저장
    }));
  };

  // useEffect(() => {
  //   const initialDataSetId: Record<string, string> = {};
  //   inputValues.forEach((_, index) => {
  //     initialDataSetId[index] = String(index); // 기본값으로 index를 사용
  //   });
  //   setDataSetId(initialDataSetId);
  // }, [inputValues]); // inputValues 변경 시 한 번 실행\

  // 이후 노드에 넘겨줄 데이터를 위한 상태
  const [resultVmeta, setResultVmeta] = useState<any>(ctrl.props.option.vmeta);
  const handleSubmit = async () => {
    const fileList = inputValues.map((obj) =>
      Object.values(obj).flatMap((innerObj: any) => Object.values(innerObj)),
    );

    const idList = Object.values(dataSetId);

    const { value: fileName } = await Swal.fire({
      title: '파일 이름 입력',
      input: 'text',
      inputPlaceholder: '파일명을 입력하세요',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      didOpen: () => {
        const input = document.querySelector('.swal2-input') as HTMLInputElement;
        if (input) {
          input.classList.add('dark:text-black');
        }
      },
    });

    if (!fileName) return;

    if (idList.length !== fileList.length) {
      alert('입력값을 채워주세요');
      return;
    }

    try {
      const response = await api.post('/dataset_config/generate_vmeta', {
        file_name: fileName,
        id_list: idList,
        file_list: fileList,
        target_dir: '/',
      });
      // 데이터가 있으면 상태 업데이트
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '파일 생성 완료',
          text: '데이터가 성공적으로 생성되었습니다!',
          confirmButtonText: '확인',
        });
        setResultVmeta(response.data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    ctrl.setValue({
      inputData: inputValues,
      vmeta: resultVmeta,
      inputKey: dataSetId,
    });
  }, [resultVmeta, dataSetId]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setDataSetId({ 0: new Date().toISOString() }); // 현재 시간을 저장
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [dataSetId]);

  // console.log(
  //   '🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯🎯',
  //   dataSetId,
  // );
  // 이렇게 말고 3초마다, setValue가 동작하게 하다록 새로운 지역상태 하나 만들어야함

  useEffect(() => {
    setResultVmeta(ctrl.props.option.vmeta);
    setInputValues(ctrl.props.option.inputData);
    setDataSetId(ctrl.props.option.inputKey);
  }, [ctrl.id]);

  return (
    <>
      <div className='flex size-full flex-col  p-5 dark:border-[#0f0f34] overflow-auto'>
        {/* <h2 className='text-lg font-semibold dark:text-white'>Dataset Configuration</h2>
        <p className='mt-2 text-sm text-gray-500'>
          This function combines multiple inputs. Additionally, each input can be saved to a
          designated storage.
        </p>

        <hr className='mt-4' />

        <p className='mt-4 text-sm text-gray-500'>
          If a node needs input refresh, click on the associated data selector and come back.
        </p>

        <hr className='mt-4' />

        <h2 className='mb-2 mt-4 text-lg font-semibold dark:text-white'>Input Data</h2> */}
        <div className='mb-4'>
          <div className='flex items-center gap-2'>
            <img
              src={
                process.env.PUBLIC_URL +
                (theme === 'dark'
                  ? '/images/rete/node/icon/dataset.svg'
                  : '/images/rete/node/icon/dataset-black.svg')
              }
              alt='Data Selector Icon'
            />
            <div className='text-2xl font-extrabold dark:text-[white]'>Dataset Configuration</div>
          </div>
          <div className='text-lg font-light mt-1 dark:text-[white]'>Integrate multiple inputs</div>
        </div>

        <div
          className='subtitle mt-[var(--10px)] break-all text-[18px]
            font-normal tracking-[var(--spacingDefalt)] text-black300 dark:text-white100'
        >
          {' '}
          Input Data
        </div>
        {inputValues.map((item, index) => (
          <div key={index} className='mt-4 rounded-md p-3 bg-[#ffffff] dark:bg-[#131313]'>
            <h3 className='text-lg font-bold dark:text-white text-black'>🟡 Input {index + 1}</h3>
            <div className='flex items-center'>
              <input
                className='w-70 mt-4 rounded-custom-xl border border-black text-[black] dark:bg-[#131313] p-2 py-1 dark:border-gray-300 dark:bg-[#131313] dark:text-white'
                placeholder={'입력하세요'} // key 기반으로 고유하게 유지됨
                value={dataSetId[index] ?? ''} // 기본값이 없으면 index 사용
                onChange={(e) => handleDataSetIdChange(index, e.target.value)}
              />
            </div>
            <div className='mt-2 space-y-2'>
              {Object.entries(item).map(([key, value]) => (
                <div key={key}>
                  <ul>
                    {Object.entries(value as Record<string, string>).map(([subKey, subValue]) => (
                      <li
                        key={subKey}
                        className='mt-2 rounded-md  dark:bg-black200 p-3 border border-black dark:border-white'
                      >
                        {subKey.startsWith('img') || subKey.startsWith('default') ? (
                          <div className='flex items-center justify-between'>
                            <p className='text-white'>{subKey} </p>
                            {/* <img
                              src={`data:image/png;base64,${subValue}`}
                              alt={subKey}
                              className='w-20 h-20 object-cover ml-2'
                            /> */}
                          </div>
                        ) : subKey.startsWith('text') ? (
                          <div className='flex items-center justify-between'>
                            <p className='text-white'>{subKey} : </p>
                            <p className='text-white'>{subValue} </p>
                          </div>
                        ) : (
                          <p>
                            {subKey} : {subValue}
                          </p>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
        <button
          className='mt-[var(--12px)] h-11
          w-full cursor-pointer rounded-lg bg-purple200
            text-[16px] text-white100 hover:text-white100 hover:brightness-75'
          onClick={handleSubmit}
        >
          File save
        </button>
        {Object.keys(resultVmeta).length === 0 ? (
          <div className='mt-2 flex items-center gap-2 rounded-lg border-l-4 border-red-500 bg-red-100 p-3 text-red-700'>
            ❗
            <p className='text-sm font-medium'>
              If you do not save the file, the data structure will not be created.
            </p>
          </div>
        ) : (
          <div className='mt-2 flex items-center gap-2 rounded-lg border-l-4 border-green-500 bg-green-100 p-3 text-green-700'>
            ✅<p className='text-sm font-medium'>Vmeta transfer is ready for the next node.</p>
          </div>
        )}
      </div>

      {/* expand */}
      <div className='side-panel-expand'>
        {ctrl.props.option.value ? (
          <>
            {/* 최상위 노드 */}
            {topNode(ctrl.props.option.value)}

            {/* 자식 노드 */}
            {Array.isArray(ctrl.props.option.value) &&
              ctrl.props.option.value.map((item: any, idx: number) => (
                <div key={idx}>
                  {item.effector && (
                    <>
                      <div>{renderTree(item.effector)}</div>
                      <hr style={{ border: '1px solid rgb(23, 24, 62)' }} />
                    </>
                  )}
                </div>
              ))}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}
