import './storage.styles.scss';

import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import StorageCard from './StorageCard';
import EmptyDiv from '../../mlops/train-detail/EmptyDiv';
import ContextMenuContextProvider from '../../../context/ContextMenuContext';

import { StorageContext } from '../../../context/StorageContext';
import StorageHeader from './StorageHeader';
import { IfileNdirList } from './storage.type';
import DropContextProvider from '../../../context/DropContext';

type StorageMainProps = {
  selectedInPanel?: any;
  setSelectedInPanel?: any;
  baseDir?: string;
  setStep?: any;
  setSelectedFile?: any;
};

export default function StorageMain({
  selectedInPanel,
  setSelectedInPanel,
  baseDir = '',
  setStep = () => {},
  setSelectedFile = () => {},
}: StorageMainProps) {
  const location = useLocation();
  const {
    fileNdirList,
    getDirectory,
    curDir,
    setCurDir,
    setEndIndex,
    selected,
    setSelectOpen,
    pinnedIds,
    setPinnedIds,
    getPinnedDirectory,
    dragTag,
  } = useContext(StorageContext);

  useEffect(() => {
    // smc 라우터용 임시 처리
    if(!location.pathname.includes('/vience-canvas/storage')){
      setSelectOpen(false); 
      getDirectory();
      return;
    }

    // location.pathname에서 /vience-canvas/storage/ 부분을 제거한 경로
    const trimmedPath = decodeURIComponent(
      location.pathname.replace(/^\/vience-canvas\/storage\/?/, '')
    );

    // 현재 경로와 같은 경로로 이동 시, 현재 경로에 따라 화면을 그려줌
    if (trimmedPath === curDir) {
      setCurDir(trimmedPath);
    }

    setSelectOpen(false); 
    getDirectory(trimmedPath);
  }, [location.pathname, curDir, setCurDir]);

  useEffect(() => {
    if (!selectedInPanel) return;
    const selectedFile = fileNdirList.filter((v) => v.name === selected[0]);
    if (selectedFile.length) setSelectedInPanel(selectedFile[0]);
  }, [selected, curDir]);

  // 핀 된값일 시 마운트
  useEffect(() => {
    console.log('전역으로 넘겨서, 핀된 값 확인', pinnedIds);
    getPinnedDirectory();
  }, [pinnedIds]);

  // 핀된 값 디렉토리 담는 상태 => 필터링 기능을 받지 않기 때문에 StorageContext의 fileNdirList처럼 전역으로 받아오는 독립적인 상태 변수 선언 필요, 현재는 더미로 대체
  const [pinnedNdirList, setPinnedNdirList] = useState<IfileNdirList[]>([]);

  //keyword
  const [keyword, setKeyword] = useState('');
  const [filteredFileNdirList, setFilteredFileNdirList] = useState<
    IfileNdirList[]
  >([]);

  useEffect(() => {
    const newFileNdirList = fileNdirList.filter((v) =>
      v.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredFileNdirList(newFileNdirList);
  }, [keyword, curDir]);

  //넓이 추정
  const elementRef = useRef(null);
  const [keyword1, setKeyword1] = useState('');

  const [justifyContent, setJustifyContent] = useState('');
  const [alignItmes, setAlignItmes] = useState('');

  useEffect(() => {
    const element = elementRef.current;

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const width = entry.contentRect.width;

        if (width <= 1241) {
          setJustifyContent('center');
          setAlignItmes('center');
        } else {
          setJustifyContent('');
          setAlignItmes('');
        }
      });
    });

    if (element) {
      observer.observe(element);
    }

    setCurDir('');
    console.log('디렉토리 정보 가져오는 동작 언마운트');

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <DropContextProvider>
      <div
        id='storage-grid-main'
        className={`center`}
        onClick={() => {
          setSelectedFile({ path: '', name: '', thumbnail: '' });
        }}
      >
        <div className='inner'>
          {(location.pathname.includes('storage') ||
            location.pathname.includes('smc')) && (
            <StorageHeader
              baseDir={baseDir}
              setKeyword={setKeyword}
              fileNdirList={fileNdirList}
            />
          )}
          <ContextMenuContextProvider>
            {/* 여기서 추가 핀 레이아웃 구성 필요 */}
            {[...Array(pinnedIds.length)].map(
              (
                _,
                idx // 우클릭 식별 필요
              ) =>
                pinnedIds.length !== 0 && (
                  <div id='pinned-frame' key={idx}>
                    <div id='storage-card-frame-pinned-layout'>
                      <div id='title'>
                        <img src={'/images/crosshair.svg'} alt='' />
                        <h4>{pinnedIds[idx]}</h4>
                      </div>
                      <div
                        id='storage-card-frame-pinned'
                        onClick={(e) => {
                          setEndIndex(undefined);
                        }}
                      >
                        {fileNdirList.map(
                          (data, index) =>
                            index < 2 && (
                              <StorageCard
                                key={index}
                                info={data}
                                keyword={keyword}
                                index={index}
                                setStep={setStep}
                              />
                            )
                        )}
                      </div>
                    </div>
                  </div>
                )
            )}

            {/* 기존의 일반 디렉토리 리스트 */}
            <div
              id='storage-card-frame'
              ref={elementRef}
              style={{ justifyContent: justifyContent, alignItems: alignItmes }}
              onClick={(e) => {
                setEndIndex(undefined);
              }}
            >
              {curDir !== baseDir && (
                <StorageCard
                  keyword={keyword}
                  index={-1}
                  info={{
                    abs_path: '',
                    name: '..',
                    type: 'dir',
                    is_dir: true,
                    thumbnail: 'empty',
                    updated_at: '',
                    path: '',
                    tagging_progress: '',
                    file_progress: '',
                    map: () => {},
                  }}
                />
              )}
              {keyword.length ? (
                filteredFileNdirList.length ? (
                  filteredFileNdirList.map((data, index) => (
                    <StorageCard
                      key={index}
                      keyword={keyword}
                      index={index}
                      setStep={setStep}
                      info={{
                        abs_path: '',
                        name: '..',
                        type: 'dir',
                        is_dir: true,
                        thumbnail: 'empty',
                        updated_at: '',
                        path: '',
                        tagging_progress: '',
                        file_progress: '',
                        map: () => {},
                      }}
                    />
                  ))
                ) : (
                  <EmptyDiv label='No results found' />
                )
              ) : fileNdirList.length ? (
                fileNdirList.map((data, index) => (
                  <StorageCard
                    key={index}
                    info={data}
                    keyword={keyword}
                    index={index}
                    setStep={setStep}
                  />
                ))
              ) : (
                <EmptyDiv label='The folder is empty.' />
              )}
            </div>
          </ContextMenuContextProvider>
        </div>
      </div>
    </DropContextProvider>
  );
}
