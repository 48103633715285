import React, { Fragment, ReactNode, useState } from 'react';

type CommingSoonAlertProps = {
  children: ReactNode;
};

const CommingSoonAlert = ({ children }: CommingSoonAlertProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <div className='relative  pointer-events-none'>
        {children}

        {isTooltipVisible && (
          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#f6ff00] dark:bg-[#f6ff00] text-[black] font-bold dark:text-[black] text-[10px] p-1 px-3 rounded-[7px] text-center z-50 whitespace-nowrap rotate-[-15deg] border-4 border-purple200'>
            🔐 Coming Soon
          </div>
        )}
      </div>
    </div>
  );
};

export default CommingSoonAlert;
