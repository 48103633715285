import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { CustomError } from '@/types/common/type';
import Swal from 'sweetalert2';

/**
 * 서버에 새로운 디렉토리를 생성하는 함수
 *
 * @param api - 요청을 보내기 위한 API 인스턴스
 * @param curDir - 현재 디렉토리 경로
 * @param theme - 테마 설정 (다크모드 또는 라이트모드)
 */
export const createDir = async (
  api: ReturnType<typeof useAPIwithCookies>,
  curDir: string,
  theme: 'dark' | 'light',
): Promise<void> => {
  const result = await Swal.fire({
    title: 'Create Directory',
    input: 'text',
    inputPlaceholder: 'Enter the directory name',
    showCancelButton: true,
    cancelButtonColor: '#707171',
    confirmButtonColor: '#8B51FF',
    confirmButtonText: 'Confirm',
    customClass: {
      popup: theme === 'dark' ? 'swal-dark' : 'swal-light',
    },
    inputValidator: (inputValue) => {
      if (!inputValue) {
        return 'Name is required';
      } else if (inputValue === curDir) {
        return 'Same as current name';
      }
    },
  });
  if (result.isConfirmed && result.value) {
    try {
      const formattedDir = curDir.startsWith('/') ? curDir.slice(1) : curDir;
      await api.post('/cloud/make_dir', {
        cur_dir: formattedDir,
        names: [result.value],
      });
      await Swal.fire({
        title: 'Success',
        text: 'Directory created successfully',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        title: 'Failed',
        text: 'Directory name already exists',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Confirm',
      });
    }
  }
};

/**
 * 선택된 파일 또는 디렉토리의 이름을 변경하는 함수
 *
 * @param api - 요청을 보내기 위한 API 인스턴스
 * @param curDir - 현재 디렉토리 경로
 * @param selected - 이름을 변경할 파일 또는 디렉토리의 이름
 * @param theme - 테마 설정 (다크모드 또는 라이트모드)
 */
export const renameItem = async (
  api: ReturnType<typeof useAPIwithCookies>,
  curDir: string,
  theme: 'dark' | 'light',
  selected: string[],
): Promise<void> => {
  const uniqueSelected = [...new Set(selected)];

  if (!uniqueSelected || uniqueSelected.length === 0) {
    throw new Error('No item selected for renaming');
  }
  if (uniqueSelected.length > 1) {
    await Swal.fire({
      title: 'Failed',
      text: 'Please select one item',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonText: 'Confirm',
    });
    return;
  }
  const fullName = uniqueSelected[0];
  const [originalFileName, originalFileExtension] = fullName.includes('.')
    ? [fullName.slice(0, fullName.lastIndexOf('.')), fullName.slice(fullName.lastIndexOf('.'))]
    : [fullName, ''];
  const result = await Swal.fire({
    title: 'Rename Item',
    input: 'text',
    inputValue: fullName,
    showCancelButton: true,
    cancelButtonColor: '#707171',
    confirmButtonColor: '#8B51FF',
    confirmButtonText: 'Confirm',
    customClass: {
      popup: theme === 'dark' ? 'swal-dark' : 'swal-light',
    },
    didOpen: () => {
      const inputElement = Swal.getInput() as HTMLInputElement;

      if (inputElement) {
        inputElement.setSelectionRange(0, originalFileName.length);
        inputElement.focus();
      }
    },
    inputValidator: (value) => {
      if (!value) {
        return 'Name is required';
      } else if (value === fullName) {
        return 'Same as current name';
      }
    },
  });
  if (result.isConfirmed && result.value) {
    let newFullName = result.value.trim();
    if (!newFullName.includes('.')) {
      newFullName += originalFileExtension;
    }
    try {
      await api.post('/cloud/rename', {
        cur_dir: curDir,
        names: [fullName],
        change: newFullName,
      });
      await Swal.fire({
        title: 'Success',
        text: 'The selected item has been renamed',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        title: 'Failed',
        text: 'Name already exists',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Confirm',
      });
    }
  }
};

/**
 * 선택된 파일 또는 디렉토리를 삭제하는 함수
 *
 * @param api - 요청을 보내기 위한 API 인스턴스
 * @param curDir - 현재 디렉토리 경로
 * @param selected - 삭제할 파일 또는 디렉토리의 이름
 * @param theme - 테마 설정 (다크모드 또는 라이트모드)
 */
export const deleteItem = async (
  api: ReturnType<typeof useAPIwithCookies>,
  curDir: string,
  theme: 'dark' | 'light',
  selected: string[],
): Promise<void> => {
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    cancelButtonColor: '#707171',
    confirmButtonColor: '#8B51FF',
    confirmButtonText: 'Confirm',
    customClass: {
      popup: theme === 'dark' ? 'swal-dark' : 'swal-light',
    },
  });
  if (result.isConfirmed) {
    try {
      await api.post('/cloud/delete', {
        cur_dir: curDir,
        names: selected,
      });

      await Swal.fire({
        title: 'Deleted',
        text: 'The selected directory has been deleted',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      await Swal.fire({
        title: 'Error',
        text: 'Failed to delete the directory',
        icon: 'error',
        showConfirmButton: true,
      });
    }
  }
};

/**
 * 디렉토리 혹은 파일의 위치를 이동하는 함수
 *
 * @param api - API 요청을 처리하는 인스턴스
 * @param curDir - 현재 디렉토리 경로
 * @param selected - 이동할 파일/디렉토리 이름 배열
 * @param target - 이동할 파일/디렉토리 경로 또는 식별자
 */
export const moveItem = async (
  api: ReturnType<typeof useAPIwithCookies>,
  curDir: string,
  selected: string[],
  target: string | undefined,
  getDirectory: any,
): Promise<void> => {
  if (!target) return;

  try {
    await api.post('/cloud/move', {
      cur_dir: curDir,
      names: selected,
      change: target,
    });
    getDirectory(curDir, api);
  } catch (error) {
    const typedError = error as CustomError;
    if (typedError.detail === 'Conflict detected') {
      Swal.fire({
        icon: 'error',
        title: 'The name already exists',
        showConfirmButton: true,
        timer: 1500,
      });
    }
  }
};

/**
 * 디렉토리에 태그를 추가하는 함수
 *
 * @param api - API 요청을 처리하는 인스턴스
 * @param target - 이동하거나 태그를 연결할 대상 경로 또는 식별자
 * @param dragTag - 드래그된 태그 정보 (태그 이름, 유효성, 색상, 키)
 */

// ** 베타테스트 배포를 위해 태그 관련 기능은 임시 주석 처리
// export const tagToDir = async (
//   api: ReturnType<typeof useAPIwithCookies>,
//   target: string | undefined,
//   dragTag: { tagName: string; key: string; valid: boolean; color: string },
// ): Promise<void> => {
//   if (!target) return;
//   if (dragTag.valid) {
//     Swal.fire({
//       icon: 'success',
//       title: 'The tag name is connected to the target',
//       showConfirmButton: false,
//       timer: 1500,
//     });
//     try {
//       await api.post('/tag/tagging', {
//         tag_key: dragTag.key,
//         target: target,
//       });
//     } catch (error) {
//       console.error('Failed to add tag to directory:', error);
//     }
//     return;
//   }
// };
