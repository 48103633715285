import React from "react";

interface ToggleButtonProps {
  showProgress: boolean;
  onToggle: () => void;
}

function ToggleButton({ showProgress, onToggle }: ToggleButtonProps) {
  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>
        {showProgress ? "Hide Progress" : "Show Progress"}
      </div>
      <div
        style={{
          width: "60px",
          height: "30px",
          backgroundColor: showProgress ? "#8d59fc" : "#dbdbdb",
          borderRadius: "15px",
          padding: "5px",
          cursor: "pointer",
          position: "relative",
          transition: "background-color 0.3s ease",
        }}
        onClick={onToggle}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "50%",
            position: "absolute",
            top: "50%",
            transform: `translate(${showProgress ? "30px" : "0px"}, -50%)`,
            transition: "transform 0.3s ease",
          }}
        />
      </div>
    </div>
  );
}

export default ToggleButton;
