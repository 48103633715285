import './diagram.styles.scss';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRete } from 'rete-react-plugin';
import { area, createEditor, editor } from '@/components/rete/rete';

import Header from '@/components/common/layouts/header/Header';
import ControlPanel from '@/components/rete/control-panel/control-panel.component';
import SidePanel from '@/components/rete/side-panel/side-panel-component';
import WorkspacePanel from '@/components/rete/workspace-panel.component';
import LoadingAnimation from '@/components/loadingAnimation/LoadingAnimation';

import { handleReteTheme } from '@/contexts/common/Context';
import { NodeContext } from '@/components/rete/NodeContext';
import { process } from '@/components/rete/reteUtils';
import { createByJson, createToJson } from '@/components/rete/workspace';
import { useAPIwithCookies } from '@/hooks/common/useApiCookies';

import { IWorkspaceSetting } from '../workspace/workspace';
import ToolBar from '@/components/rete/toolBar/ToolBar';
import { Shape } from '@/components/rete/selection';
import { tabState } from '@/components/rete/components/style/CustomNode';

import debounce from 'lodash/debounce';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveCanvasData } from '@/components/rete/saveCanvasData';

function App() {
  const { setIsFocused, setNodeId } = useContext(NodeContext);
  const [editable, setEditable] = useState<boolean>(true);
  const params = useParams();
  const api = useAPIwithCookies();
  const [ref, editorMoveControl] = useRete(createEditor);
  const [title, setTitle] = useState<string | null>(null);
  const [setting, setSetting] = useState<IWorkspaceSetting>({
    category: '',
    description: '',
    ipAddress: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [shape, setShape] = useState<Shape>('marquee');
  const [button, setButton] = useState<0 | 1>(0); // 초기 조작 상태
  const [isSaved, setIsSaved] = useState(false);
  const [savedAt, setSavedAt] = useState<number | null>(null);

  const getCanvas = async () => {
    setLoading(true);
    try{
      const response = await api.get(`/workspace/${params.wor_id}`);
      const data = response.data;
      setTitle(data.title);
      setEditable(data.edit_yn);
      setSetting({
        category: data.category ?? '',
        description: data.description ?? '',
        ipAddress: data.ipAddress ?? '',
      });
      if (data.nodes !== undefined) {
        try {
          if (typeof data.nodes === 'string') {
            const a = await createByJson(JSON.parse(data.nodes));
          } else {
            const b = await createByJson(data.nodes);
          }
        } catch (err) { // 기존 workspace 정보가 아직 초기화 되지 않아 노드 추가 에러 생기는 경우 1초뒤 다시 시도도
          console.error('Error parsing nodes:', err);
          setTimeout(async () => {
            if (typeof data.nodes === 'string') {
              const a = await createByJson(JSON.parse(data.nodes));
            } else {
              const b = await createByJson(data.nodes);
            }
          }, 1000);    
        }
      }
      handleReteTheme();
    } finally{
      // rete 엔진 세팅 시간 확보 
      setTimeout(() => setLoading(false), 1000);
    }
  };

  // 원래 saveCanvas 함수: editable 상태일 때 API 호출
  const [saveMessage, setSaveMessage] = useState(false);

  const saveCanvas = async () => {
    if (!editable || loading || title===null) return;

    const json = await createToJson();
    if (json === 'exit') {
      return;
    }
    if (json !== null && json !== undefined) {
      // json이 null이 아닐 경우에만 실행
      const result = await saveCanvasData(
        api,
        params.wor_id,
        json,
        title,
        setting.category,
        setting.description,
      );

      if (result.success) {
        setSaveMessage(true); // 저장 메시지 표시

        setSavedAt(Date.now());
        setTimeout(() => setSaveMessage(false), 1000);
      }
    } else {
      console.error('json is null, unable to save.');
    }
  };

  const debouncedSaveCanvas = useCallback(
    debounce(() => {
      saveCanvas();
    }, 2000),
    [editable, params.wor_id, title, setting],
  );

  if (area) {
    area.addPipe((context) => {
      if (context.type === 'nodepicked') {
        const pickedId = context.data.id;
        setNodeId(pickedId);
      }
      return context;
    });
    // grid snap 기능 구현
    area.addPipe(async (context) => {
      if (context.type === 'nodedragged') {
        const x = area.nodeViews.get(context.data.id)?.position.x;
        const y = area.nodeViews.get(context.data.id)?.position.y;
        if (x !== undefined && y !== undefined) {
          let new_x, new_y;
          if (x >= 0) {
            new_x = x % 100 >= 50 ? x - (x % 100) + 100 : x - (x % 100);
          } else {
            new_x = x % 100 < -50 ? x - (x % 100) - 100 : x - (x % 100);
          }
          if (y >= 0) {
            new_y = y % 100 >= 50 ? y - (y % 100) + 100 : y - (y % 100);
          } else {
            new_y = y % 100 < -50 ? y - (y % 100) - 100 : y - (y % 100);
          }
          await area.translate(context.data.id, { x: new_x, y: new_y });
          debouncedSaveCanvas();
        }
      }
      return context;
    });
  }

  if (editor) {
    let hasProcessed = false;

    // 중복 실행 방지를 위한 콜백 함수
    const handleProcessCallback = () => {
      if (!loading && !hasProcessed) {
        hasProcessed = true;
        process();
        setTimeout(() => {
          debouncedSaveCanvas();
          hasProcessed = false;
        }, 0);
        setNodeId('');
      }
    };

    editor.addPipe((context) => {
      if (
        ['nodecreated', 'noderemoved', 'connectioncreated', 'connectionremoved'].includes(
          context.type,
        )
      ) {
        handleProcessCallback(); // 콜백 호출
      }

      return context;
    });
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        editorMoveControl?.zoomAt();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [editorMoveControl]);

  useEffect(() => {
    editorMoveControl?.zoomAt();
  }, [loading, editorMoveControl]);

  useEffect(() => {
    getCanvas();
    setNodeId('');
  }, [params]);

  useEffect(() => {
    const interval = setInterval(() => {
      debouncedSaveCanvas();
    }, 5000); // 테스트용 5초, 필요에 따라 변경
    return () => clearInterval(interval);
  }, [debouncedSaveCanvas]);

  useEffect(() => {
    editorMoveControl?.setSelectionShape(shape);
  }, [editorMoveControl, shape]);

  useEffect(() => {
    editorMoveControl?.setSelectionButton(button);
  }, [editorMoveControl, button]);

  const handleChangeTabState = () => {
    setIsFocused(false);
  };

  // 경과 시간 계산
  const [timeAgo, setTimeAgo] = useState('');

  // useEffect(() => {
  //   if (!savedAt) return;

  //   const interval = setInterval(() => {
  //     const secondsAgo = Math.floor((Date.now() - savedAt) / 1000);
  //     setTimeAgo(`${secondsAgo}초 전`);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [savedAt]);
  return (
    <>
      {loading && <LoadingAnimation label={'loading...'} />}

      <div className='canvas-edit'>

        <Header />

        {saveMessage && (
          <div className='absolute right-[400px] top-[82px] z-50 px-4 py-2 text-purple-600 dark:text-[#00ffaa]'>
            Auto saved!
          </div>
        )}

        {/* )} */}
        <WorkspacePanel
          title={title}
          setTitle={setTitle}
          save={debouncedSaveCanvas}
          setSetting={setSetting}
          setting={setting}
        />
        <div className='main-canvas-content'>
          <div className='main-canvas-left'>
            <ControlPanel />
          </div>
          <div className='main-canvas-middle' onClick={handleChangeTabState}>
            <div className='rete' ref={ref} />
          </div>
          <div className='main-canvas-right'>
            <SidePanel />
          </div>
          <ToolBar button={button} setButton={setButton} />
        </div>
      </div>
    </>
  );
}

export default App;
