import React from 'react';

import Header from '@/components/common/layouts/header/Header';
import DataHubBody from '@/components/datahub/datahub-body';
import DataHubRightPanel from '@/components/datahub/DataHubRightPanel';
import DataHubLeftPanel from '@/components/datahub/DataHubLeftPanel';
import DataHubProcess from '@/components/datahub/DataHubProgress';
import Loading from '@/components/common/loadings/Loading';
import ProgressCircle from '@/components/common/loadings/ProgressCircle';

function DesktopDataHub({
  datahubTypeList,
  datahubType,
  setDatahubType,
  showProgress,
  setShowProgress,
  dirLoading,
  handleDragEnd,
  handleDragging,
  handleMoveItem,
  resetDrag,
  dragAreaPos,
  progress,
  loading,
  gridAreaRef,
}) {
  return (
    <div>
      <Header />
      <div
        className='flex h-[calc(100vh-72px)] justify-between
          overflow-hidden bg-gray100 dark:bg-black100'
        onContextMenu={(e) => e.preventDefault()}
      >
        {/* LeftPanel */}
        <div className='bg-gray100 dark:bg-black100'>
          <DataHubLeftPanel
            datahubTypeList={datahubTypeList}
            datahubType={datahubType}
            setDatahubType={setDatahubType}
            showProgress={showProgress}
            setShowProgress={setShowProgress}
          />
        </div>

        {dirLoading && <Loading label='loading...' />}

        {/* Main Content */}
        <div
          ref={gridAreaRef}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDragging}
          onDragEnd={handleMoveItem}
          onMouseLeave={resetDrag}
          className='w-full rounded-tl-[30px] bg-white100 p-5 dark:bg-black200'
        >
          <div className='relative w-[calc(100%-21.875rem)] bg-white100 px-6 py-2 dark:bg-black200'>
            {!showProgress && <DataHubBody />}
            {showProgress && <DataHubProcess />}
          </div>

          {/* Right Panel */}
          <DataHubRightPanel />

          {/* Drag Area */}
          <div
            className='absolute bg-purple200'
            style={{
              width: dragAreaPos?.width,
              height: dragAreaPos?.height,
              top: dragAreaPos?.top,
              left: dragAreaPos?.left,
            }}
          />
        </div>

        {loading && (
          <ProgressCircle
            label={progress?.filename ?? ''}
            percentage={Number(progress?.progress.toFixed(2))}
          />
        )}
      </div>
    </div>
  );
}

export default DesktopDataHub;
