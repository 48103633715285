export const getNameIcon = (type: string, theme: string) => {
  const basePath = process.env.PUBLIC_URL + '/images/datahub/card/title/';
  const themePath = theme === 'dark' ? '' : '-black';
  switch (type) {
    case 'dir':
    case 'dataset':
    case 'model':
    case 'form_output':
      return `${basePath}data-title-folder${themePath}.svg`;
    case 'image':
    case 'svs':
      return `${basePath}data-title-img${themePath}.svg`;
    case 'weight':
    case 'file':
      return `${basePath}data-title-file${themePath}.svg`;
    case 'vmeta':
      return `${basePath}data-title-vmeta${themePath}.svg`;
    default:
      return '';
  }
};

export const getThumbnailIcon = (type: string, thumbnail: string) => {
  const basePath = process.env.PUBLIC_URL + '/images/datahub/card/thumbnail/';

  if (thumbnail === 'none') {
    return 'none';
  }
  if (type === 'dir' || type === 'dataset') {
    return `${basePath}data-folder-file.svg`;
  }
  if (type === 'image' || type === 'svs') {
    return `data:image/jpeg;base64,${thumbnail}`;
  }
  if (type === 'vmeta') {
    return `${basePath}data-vmeta.svg`;
  }
  if (type === 'weight' || type === 'file' || thumbnail === 'empty') {
    return `${basePath}data-file.svg`;
  }
  if (type === 'form_output') {
    return `${basePath}data-form.svg`;
  }
  return '';
};
