import React from 'react';
import { createRoot } from 'react-dom/client';
import { NodeEditor, GetSchemes, ClassicPreset } from 'rete';
import { AreaPlugin, AreaExtensions } from 'rete-area-plugin';
import {
  ClassicFlow,
  ConnectionPlugin,
  getSourceTarget,
} from 'rete-connection-plugin';
import { ReactPlugin, Presets, ReactArea2D } from 'rete-react-plugin';
import { AutoArrangePlugin } from 'rete-auto-arrange-plugin';
import { MinimapExtra, MinimapPlugin } from 'rete-minimap-plugin';
import { DataflowEngine } from 'rete-engine';
import { ContextMenuExtra, ContextMenuPlugin } from 'rete-context-menu-plugin';
import {
  CustomProcessingComp,
  CustomProcessingControl,
  CustomProcessingNode,
} from './components/nodes/custom/CustomProcessing';
import {
  ImageCropComp,
  ImageCropControl,
  ImageCropNode,
} from './components/nodes/effector/ImageCrop';
import {
  EdgeDetectComp,
  EdgeDetectControl,
  EdgeDetectNode,
} from './components/nodes/effector/EdgeDectection';
import {
  ViewerControl,
  ViewerNode,
  ViewerComp,
} from './components/nodes/sink/Viewer';
import { CustomNode } from './components/style/CustomNode';
import { CustomConnection } from './components/style/CustomConnection';
import {
  AddonSocketComp,
  CustomSocketComp,
} from './components/style/CustomSocket';
import { AddonSocket, CustomSocket } from './components/sockets';
import {
  copyNode,
  deleteNode,
  getConnectionSockets,
  process,
} from './reteUtils';
import {
  DataManageComp,
  DataManageControl,
  DataManageNode,
} from './components/nodes/source/DataManagement';
import {
  DenoisingComp,
  DenoisingControl,
  DenoisingNode,
} from './components/nodes/effector/deeplearning/Denoising';
import { useMagneticConnection } from './magnetic-connection';
import { GearComp, GearControl, GearNode } from './components/nodes/addon/Gear';
import {
  BlenderComp,
  BlenderControl,
  BlenderNode,
} from './components/nodes/effector/Blender';
import {
  ChartComp,
  ChartControl,
  ChartNode,
} from './components/nodes/sink/Chart';
import {
  Segmentation2DComp,
  Segmentation2DControl,
  Segmentation2DNode,
} from './components/nodes/effector/deeplearning/Segmentation2D';
import {
  ClassificationComp,
  ClassificationControl,
  ClassificationNode,
} from './components/nodes/effector/deeplearning/Classification';
import {
  FeatureComp,
  FeatureControl,
  FeatureNode,
} from './components/nodes/addon/Feature';

class Connection<
  A extends NodeTypes,
  B extends NodeTypes,
> extends ClassicPreset.Connection<A, B> {}

// Canvas에서 사용할 Control 등록
export type ControlTypes =
  | CustomProcessingControl
  | ImageCropControl
  | EdgeDetectControl
  | BlenderControl
  | DataManageControl
  | DenoisingControl
  | Segmentation2DControl
  | ViewerControl
  | FeatureControl
  | ChartControl
  | ClassificationControl;
// Canvas에서 사용할 Node 등록
export type NodeTypes =
  | CustomProcessingNode
  | ImageCropNode
  | EdgeDetectNode
  | DataManageNode
  | DenoisingNode
  | Segmentation2DNode
  | BlenderNode
  | ViewerNode
  | GearNode
  | FeatureNode
  | ChartNode
  | ClassificationNode;

// Node와 Node를 잇는 Conn 등록
type ConnProps = Connection<NodeTypes, NodeTypes>;

// Canvas의 기본이 되는 Schemes 등록 (Node의 type, Conn의 type)
export type Schemes = GetSchemes<NodeTypes, ConnProps>;

// Area에 적용시킬 옵션들 기본 ReactArea2D, ContextMenu, Minimap 추가
type AreaExtra = ReactArea2D<any> | ContextMenuExtra | MinimapExtra;

// editor : Node를 생성하고 관리하는 전체 Canvas, area : Node의 이동, 선택을 담당
// 외부에서 호출을 위해 createEdtior 외부에서 선언
export let editor: NodeEditor<Schemes>;
export let engine: DataflowEngine<Schemes>;
export let area: AreaPlugin<Schemes, AreaExtra>;
export let arrange = new AutoArrangePlugin<Schemes>();

//Node 생성 함수 control-panel.component.tsx idx에 맞게 생성

async function setNodeInitPose(newNode: any, y_init_pos) {
  console.log('set position');
  var rete_obj = document.getElementsByClassName('rete');
  var orign_obj = rete_obj[0].firstChild;
  var transform_str = orign_obj.style.transform;
  var transform_x = parseFloat(
    transform_str
      .split('(')[1]
      .split(')')[0]
      .split(',')[0]
      .split('px')[0]
      .trim()
  );
  var transform_y = parseFloat(
    transform_str
      .split('(')[1]
      .split(')')[0]
      .split(',')[1]
      .split('px')[0]
      .trim()
  );
  var transform_scale = parseFloat(transform_str.split('(')[2].split(')')[0]);
  const rect = rete_obj[0].getBoundingClientRect();
  console.log(transform_x, transform_y);
  console.log(rect);
  await area.translate(newNode.id, {
    x: -(transform_x - 400) / transform_scale,
    y: -(transform_y + rect.top - y_init_pos + 10) / transform_scale,
  });
}

export async function createNode(idx: number, y_init_pos) {
  if (idx === 0) {
    const newNode = new CustomProcessingNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }

  if (idx === 1) {
    const newNode = new ImageCropNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 2) {
    const newNode = new EdgeDetectNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 4) {
    const newNode = new DenoisingNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 7) {
    const newNode = new DataManageNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 8) {
    const newNode = new ViewerNode();
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 10) {
    const newNode = new GearNode();
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 12) {
    const newNode = new BlenderNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 13) {
    const newNode = new FeatureNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 14) {
    const newNode = new ChartNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 15) {
    const newNode = new Segmentation2DNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
  if (idx === 16) {
    const newNode = new ClassificationNode(process);
    await editor.addNode(newNode);
    await setNodeInitPose(newNode, y_init_pos);
  }
}

//editor 생성 함수
export async function createEditor(container: HTMLElement) {
  editor = new NodeEditor<Schemes>();
  area = new AreaPlugin<Schemes, AreaExtra>(container);
  const connection = new ConnectionPlugin<Schemes, AreaExtra>();
  // rendering
  const render = new ReactPlugin<Schemes, AreaExtra>({ createRoot });
  // Node 정렬
  // arrange = new AutoArrangePlugin<Schemes>();
  engine = new DataflowEngine<Schemes>();

  //contextMenu 생성
  const contextMenu = new ContextMenuPlugin<Schemes>({
    items(context, plugin) {
      // 바닥을 우클릭 한 경우
      if (context === 'root') {
        return {
          searchBar: false,
          list: [
            // { label: 'Arrange', key: '1', handler: arrangeNode },
            // {
            //   label: 'Collection', key: '1', handler: () => null,
            //   subitems: [
            //     { label: 'Subitem', key: '1', handler: () => console.log('Subitem') }
            //   ]
            // }
          ],
        };
      }
      // Node를 우클릭 한 경우,
      return {
        searchBar: false,
        list: [
          {
            label: 'Delete',
            key: '1',
            handler: async () => {
              deleteNode(context.id);
            },
          },
          {
            label: 'Copy',
            key: '1',
            handler: async () => {
              copyNode(context.id);
            },
          },
        ],
      };
    },
  });
  // contextMenu를 area에 등록
  area.use(contextMenu);

  // minimap 생성
  const minimap = new MinimapPlugin<Schemes>({
    boundViewport: true,
  });

  // node 선택 가능 plugin으로 추정
  AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
    accumulating: AreaExtensions.accumulateOnCtrl(),
  });

  //render에 rendering을 위해 등록
  render.addPreset(Presets.minimap.setup({ size: 200 }));
  render.addPreset(Presets.contextMenu.setup());
  render.addPreset(
    Presets.classic.setup({
      customize: {
        // 아래의 ignore들 기본 Preset을 수정하는 과정에서 생긴 오류 추정
        // Preset 직접 변경 필요?
        //@ts-ignore
        node(context) {
          // 기본 Node가 아닌 CustomNode return
          return CustomNode;
        },
        //@ts-ignore
        socket(data) {
          //socket의 종류에 따라 다른 socket return
          if (data.payload instanceof CustomSocket) {
            return () => (
              <CustomSocketComp data={data.payload} nodeId={data.nodeId} />
            );
          }
          if (data.payload instanceof AddonSocket) {
            return AddonSocketComp;
          }
          return CustomSocketComp;
        },
        //@ts-ignore
        connection(data) {
          // CustomConnection return
          return CustomConnection;
        },
        //@ts-ignore
        control(data) {
          // Control의 종류에 따라 다른 Comp return
          if ((data.payload as any) instanceof DataManageControl) {
            return () => (
              <DataManageComp data={data.payload as DataManageControl} />
            );
          }
          if ((data.payload as any) instanceof CustomProcessingControl) {
            return () => (
              <CustomProcessingComp
                data={data.payload as CustomProcessingControl}
              />
            );
          }
          if ((data.payload as any) instanceof ImageCropControl) {
            return () => (
              <ImageCropComp data={data.payload as ImageCropControl} />
            );
          }
          if ((data.payload as any) instanceof EdgeDetectControl) {
            return () => (
              <EdgeDetectComp data={data.payload as EdgeDetectControl} />
            );
          }
          if ((data.payload as any) instanceof DenoisingControl) {
            return () => (
              <DenoisingComp data={data.payload as DenoisingControl} />
            );
          }
          if ((data.payload as any) instanceof Segmentation2DControl) {
            return () => (
              <Segmentation2DComp
                data={data.payload as Segmentation2DControl}
              />
            );
          }
          if ((data.payload as any) instanceof ClassificationControl) {
            return () => (
              <ClassificationComp
                data={data.payload as ClassificationControl}
              />
            );
          }
          if ((data.payload as any) instanceof BlenderControl) {
            return () => <BlenderComp data={data.payload as BlenderControl} />;
          }
          if ((data.payload as any) instanceof FeatureControl) {
            return () => <FeatureComp data={data.payload as FeatureControl} />;
          }
          if ((data.payload as any) instanceof ViewerControl) {
            return () => <ViewerComp data={data.payload as ViewerControl} />;
          }
          if ((data.payload as any) instanceof ChartControl) {
            return () => <ChartComp data={data.payload as ChartControl} />;
          }
          if ((data.payload as any) instanceof GearControl) {
            return () => <GearComp data={data.payload as GearControl} />;
          }
          return null;
        },
      },
    })
  );

  connection.addPreset(
    () =>
      new ClassicFlow({
        canMakeConnection(from, to) {
          // this function checks if the old connection should be removed
          const [source, target] = getSourceTarget(from, to) || [null, null];

          if (!source || !target || from === to) return false;

          const sockets = getConnectionSockets(
            editor,
            new Connection(
              editor.getNode(source.nodeId),
              source.key as never,
              editor.getNode(target.nodeId),
              target.key as never
            )
          );
          console.log(sockets);

          if (!sockets.source.isCompatibleWith(sockets.target)) {
            connection.drop();
            return false;
          }

          return Boolean(source && target);
        },
        makeConnection(from, to, context) {
          const [source, target] = getSourceTarget(from, to) || [null, null];
          const { editor } = context;

          if (source && target) {
            editor.addConnection(
              new Connection(
                editor.getNode(source.nodeId),
                source.key as never,
                editor.getNode(target.nodeId),
                target.key as never
              )
            );
            return true;
          }
        },
      })
  );

  editor.use(engine);
  editor.use(area);
  area.use(connection);
  area.use(render);
  // area.use(arrange);
  area.use(minimap);

  // Connection Magnetic 등록
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMagneticConnection(connection, {
    async createConnection(from, to) {
      if (from.side === to.side) return;
      const [source, target] = from.side === 'output' ? [from, to] : [to, from];
      const sourceNode = editor.getNode(source.nodeId);
      const targetNode = editor.getNode(target.nodeId);

      await editor.addConnection(
        new ClassicPreset.Connection(
          sourceNode,
          source.key as never,
          targetNode,
          target.key as never
        )
      );
    },
    display(from, to) {
      return from.side !== to.side;
    },
    offset(socket, position) {
      const socketRadius = 10;

      return {
        x:
          position.x + (socket.side === 'input' ? -socketRadius : socketRadius),
        y: position.y,
      };
    },
  });

  AreaExtensions.simpleNodesOrder(area);
  AreaExtensions.showInputControl(area);

  editor.addPipe((context) => {
    if (context.type === 'connectioncreate') {
      const { data } = context;
      const { source, target } = getConnectionSockets(editor, data);

      if (!source.isCompatibleWith(target)) {
        return;
      }
    }
    return context;
  });

  // await arrange.layout();
  AreaExtensions.zoomAt(area, editor.getNodes());

  // destroy 등록 필수
  return {
    destroy: () => area.destroy(),
  };
}
