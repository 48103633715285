import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';

import File from './File';
import API from '@/apis/common/apis';
import { CustomThemeContext } from '@/contexts/common/Context';
import { IfileNdirList } from '@/types/common/type';

interface ISelected {
  path: string;
  name: string;
  thumbnail: string;
  type: string;
  dataType?: string;
}

interface DirectoryProps {
  name: string;
  path: string;
  depth: number; // 트리 깊이(들여쓰기용)
  dataType: string;
  selected: ISelected; // 전역적으로 공유되는 '선택된 항목' 상태
  setSelected: Dispatch<SetStateAction<ISelected>>; // 선택 변경 함수
  openPaths: string[]; // normal panel에서 '열려있는(펼친)' 디렉토리들의 경로
  setOpenPaths: Dispatch<SetStateAction<string[]>>;
}

export default function Directory({
  name,
  path,
  depth,
  dataType,
  selected,
  setSelected,
  openPaths,
  setOpenPaths,
}: DirectoryProps) {
  const [cookies] = useCookies(['refresh']);
  const api = new API(cookies);
  const { theme } = useContext(CustomThemeContext);

  // -------------------------------------------------------
  // 경로 합치기 (leading slash 없음)
  // name='', path='' 이면 => curDir=''
  // 예) path='pngTest', name='1.png' => 'pngTest/1.png'
  // -------------------------------------------------------
  function joinPath(base: string, child: string) {
    if (!base) return child;
    return base + '/' + child;
  }
  const curDir = name === '' && path === '' ? '' : joinPath(path, name);

  // listOpen: openPaths에 curDir가 포함되어 있으면 열림 상태
  const listOpen = openPaths.includes(curDir);

  // fileNdirList: 현재 디렉토리(curDir) 내부의 파일/디렉토리 목록
  const [fileNdirList, setFileNdirList] = useState<IfileNdirList[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // bgColor: 이 Directory를 보라색으로 표시할지 여부
  const [bgColor, setBgColor] = useState('');

  const [showTooltip, setShowTooltip] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  // --------------------------------------------------------------------------------
  // 만약 listOpen=true면 => 서버에서 curDir 목록을 가져온다
  // --------------------------------------------------------------------------------
  useEffect(() => {
    if (listOpen) {
      setIsLoading(true);
      api
        .get('/cloud/list', { cur_dir: curDir })
        .then((res) => {
          setFileNdirList(res.data);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [listOpen, curDir]);

  // --------------------------------------------------------------------------------
  // 보라색 하이라이트: selected에 저장된 'path + name'이 나와 일치할 때만 -> 배경색을 bgColor로 지정
  // --------------------------------------------------------------------------------
  useEffect(() => {
    if (!selected.name) {
      setBgColor('');
      return;
    }
    const selectedFullPath = selected.path ? `${selected.path}/${selected.name}` : selected.name;
    if (selectedFullPath === curDir) {
      // 내가 선택된 디렉토리
      setBgColor('#8B51FF');
    } else {
      setBgColor('');
    }
  }, [selected, theme, curDir]);

  // tooltip
  useEffect(() => {
    if (textRef.current) {
      setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [name]);

  // --------------------------------------------------------------------------------
  // handleSingleClick
  //  - 사용자가 이 디렉토리를 클릭했을 때 실행
  //  - 해당 디렉토리를 selected로 만들고, openPaths를 토글(열려있으면 닫고, 닫혀있으면 연다)
  // --------------------------------------------------------------------------------
  const handleSingleClick = async () => {
    // 디렉토리를 선택
    setSelected({
      path,
      name,
      thumbnail: '',
      type: dataType,
    });

    if (listOpen) {
      // 이미 열려 있으면 닫기
      setOpenPaths((prev) => prev.filter((p) => p !== curDir));
    } else {
      // 닫혀 있으면 열기
      setOpenPaths((prev) => [...prev, curDir]);
    }
  };

  return (
    <div className='relative'>
      {isLoading && <div className='z-5 bg-opacity/50 pointer-events-none absolute inset-0' />}

      {/* 리스트 렌더링 */}
      <li style={{ marginLeft: depth !== 0 ? 20 : 0 }}>
        <div
          className={`depth-directory ${
            isLoading ? 'opacity-50' : 'opacity-100'
          } transition-opacity`}
          onClick={handleSingleClick}
          style={{ backgroundColor: bgColor }}
        >
          {/* dir 또는 form_output일 때 동일한 화살표 아이콘 적용 */}
          {(dataType === 'dir' || dataType === 'form_output') && (
            <img
              src={
                process.env.PUBLIC_URL +
                (listOpen
                  ? '/images/datahub-icon-arrow-open.svg'
                  : '/images/datahub-icon-arrow-close.svg')
              }
              alt=''
              className={`${theme === 'light' ? 'invert' : ''}`}
            />
          )}

          <div className='datamanage-file-name flex items-center'>
            {/* dir 또는 form_output일 때 동일한 폴더 아이콘 적용 */}
            {dataType === 'dir' || dataType === 'form_output' ? (
              <img
                src={process.env.PUBLIC_URL + '/images/datahub-icon-folder.svg'}
                alt=''
                className='ml-1'
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + '/images/datahub-icon-file.svg'}
                alt=''
                className='ml-1'
              />
            )}

            <span
              ref={textRef}
              onMouseEnter={() => isOverflow && setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className='file-name'
            >
              {name === '' ? '' : name}
            </span>
            {showTooltip && <div className='tooltip'>{name}</div>}
          </div>
        </div>

        {/* 디렉토리가 열려 있으면 (listOpen=true) 하위 목록 표시 */}
        {listOpen && (
          <ul>
            {isLoading ? (
              <div
                className='rounded-custom-xl text-white100 dark:text-white100 absolute inset-0 z-10
                  flex items-center justify-center text-[16px] font-medium'
              >
                <span>⌛ Loading ...</span>
              </div>
            ) : (
              fileNdirList.map((data) => {
                if (
                  data.type === 'dir' ||
                  data.type === 'dataset' ||
                  data.type === 'model' ||
                  data.type === 'form_output'
                ) {
                  return (
                    <Directory
                      key={data.name}
                      name={data.name}
                      path={curDir}
                      depth={depth + 1}
                      dataType={data.type}
                      selected={selected}
                      setSelected={setSelected}
                      openPaths={openPaths}
                      setOpenPaths={setOpenPaths}
                    />
                  );
                } else {
                  return (
                    <File
                      key={data.name}
                      name={data.name}
                      path={curDir}
                      depth={depth + 1}
                      dataType={data.type}
                      thumbnail={data.thumbnail || ''}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  );
                }
              })
            )}
          </ul>
        )}
      </li>
    </div>
  );
}
