import React, { useContext, useEffect, useRef, useState } from 'react';
import { CirclePicker } from 'react-color';
import { CustomThemeContext } from '@/contexts/common/Context';

import { useAPIwithCookies } from '@/hooks/common/useApiCookies';
import { truncateText } from '@/utils/common/textUtils';

import '@/styles/datahub/datahub.styles.scss';
import { Pipe, Tag, Task } from '@/types/datahub/type';
import { groupByTag, onEditTag } from '@/utils/datahub/tagUtils';
// ** 베타테스트 배포를 위해 태그 관련 기능은 임시 주석 처리
// import { createTag, deleteTag, fetchTagList, modifyTag } from '@/apis/datahub/tagApis';
import { selectPipe, deletePipe } from '@/utils/datahub/pipeUtils';
import {
  handleTitleText,
  handleSelectColorHex,
  handleSelectColorPicker,
  handleSearchText,
} from '@/utils/datahub/handlers';
// import { tagToDir } from '@/apis/datahub/directoryApis';
import { usePanelMode } from '@/hooks/common/usePanelMode';
import { fetchPipeList, fetchSearchPipeList } from '@/apis/datahub/pipeApis';
import useDataHubStore from '@/stores/datahub/useDataHubStore';
import { DatasetComponent } from '../rete-mlops/components';
import Swal from 'sweetalert2';
import CommingSoonAlert from '../common/alerts/CommingSoonAlert';

export default function DataHubRightPanel() {
  const { theme } = useContext(CustomThemeContext);
  const api = useAPIwithCookies();

  const { dragTag, setDragTag, curDir, getDirectory, target, selected, setSelected, setTarget } =
    useDataHubStore();
  // 패널 동작 커스텀 훅
  const { panelMode, translate, handlePanelMode, ensureExpandPanelMode } = usePanelMode(curDir);

  // useState 초기화
  const [activeTab, setActiveTab] = useState('VMETAS');

  const [createTitle, setCreateTitle] = useState('');
  const [color, setColor] = useState('#000000');
  const [pipeList, setPipeList] = useState<Pipe[]>([]);
  const [taskList, setTaskList] = useState<Task[]>([]);

  const [searchPipe, setSearchPipe] = useState('');
  const [selectPipeList, setSelectPipeList] = useState<Pipe[]>([]);
  const [tagList, setTagList] = useState<Tag[]>([]);
  const [selectTag, setSelectTag] = useState<string>('');

  const [sidePanelText, setSidePanelText] = useState('Create New Tag');
  const [postBtnText, setPostBtnText] = useState('Add');
  const [nowTag, setNowTag] = useState('');

  // 태그 리스트 불러오는 api
  // const getFetchTagList = async () => {
  //   await fetchTagList(api, setTagList);
  // };

  // 파이프 리스트 불러오는 api
  // const getFetchPipeList = async () => {
  //   await fetchPipeList(api, searchPipe, setPipeList);
  // };

  // 검색한 파이프 리스트 불러오는 api
  // const getFetchSearchPipeList = async () => {
  //   await fetchSearchPipeList(api, searchPipe, setTaskList, groupByTag);
  // };

  // 초기 렌더링에 태그, 파이프 리스트 불러오기기
  // useEffect(() => {
  //   getFetchPipeList();
  //   getFetchTagList();
  // }, [searchPipe]);

  // useEffect(() => {
  //   getFetchSearchPipeList();
  // }, [curDir]);

  // 태깅 api
  // const handleTagToFileOrDir = async () => {
  //   await tagToDir(api, target, dragTag);
  //   getDirectory(curDir, api);
  // };

  // 태그에 대한 pipe 선택
  // const handleSelectPipe = (item: Pipe) => {
  //   setSelectPipeList((prevItems) => selectPipe(prevItems, item));
  // };

  // 태그에 대한 pipe 삭제
  // const handleDeletePipe = (item: Pipe) => {
  //   setSelectPipeList((prevItems) => deletePipe(prevItems, item));
  // };

  // 파이프 생성
  // const handleCreateTag = async () => {
  //   const pipeKeys = selectPipeList.map((pipe: Pipe) => pipe.pipe_key);
  //   const tagData = {
  //     title: createTitle,
  //     color: color,
  //     pipes: pipeKeys,
  //   };

  //   await createTag(api, tagData, {
  //     getFetchTagList,
  //     setSidePanelText,
  //     setCreateTitle,
  //     setColor,
  //     setSelectPipeList,
  //     setPostBtnText,
  //   });
  // };

  // 파이프 수정
  // const handleModifyTag = async () => {
  //   const pipeKeys = selectPipeList.map((pipe: Pipe) => pipe.pipe_key);
  //   const tagData = {
  //     title: createTitle,
  //     color: color,
  //     pipes: pipeKeys,
  //   };

  //   await modifyTag(api, selectTag, tagData, {
  //     getFetchTagList,
  //     setSidePanelText,
  //     setCreateTitle,
  //     setColor,
  //     setSelectPipeList,
  //     setPostBtnText,
  //   });
  // };

  // 파이프 삭제
  // const handleDeleteTag = async (tagKey: string) => {
  //   await deleteTag(curDir, api, tagKey, {
  //     getDirectory,
  //     getFetchTagList,
  //     setSidePanelText,
  //     setCreateTitle,
  //     setColor,
  //     setSelectPipeList,
  //     setPostBtnText,
  //     setTagList,
  //   });
  // };

  // 현재 파이프 정보 불러오는 유틸
  const handleEditTag = (item: Tag) => {
    ensureExpandPanelMode();

    onEditTag({
      item,
      nowTag,
      sidePanelText,
      pipeList,
      setNowTag,
      setSidePanelText,
      setCreateTitle,
      setColor,
      setSelectPipeList,
      setPostBtnText,
      setSelectTag,
    });
  };

  // ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅
  const [dataSetElements, setDataSetElements] = useState<string[][]>([[]]); // 초기값을 빈 배열의 배열로 설정
  const [dataSetId, setDataSetId] = useState<string[]>(['0']); // 초기값을 빈 배열의 배열로 설정
  const [dragOverSet, setDragOverSet] = useState<any>('');
  const [fileName, setFileName] = useState<string>(''); // 초기값을 빈 배열의 배열로 설정

  const containerRef = useRef<HTMLDivElement>(null);

  const addNewSet = () => {
    setDataSetElements((prev) => [...prev, []]); // 새로운 빈 배열 추가
    setDataSetId((prev) => [...prev, `${dataSetId.length}`]); // dataSetId에 '' 추가
    setTimeout(() => {
      containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 0);
  };

  const handleDeleteSet = (index: number) => {
    setDataSetElements((prev) => prev.filter((_, i) => i !== index));
    setDataSetId((prev) => prev.filter((_, i) => i !== index));
  };

  const handleDataSetIdChange = (index: number, value: string) => {
    const updatedFileNames = [...dataSetId];
    updatedFileNames[index] = value;
    setDataSetId(updatedFileNames);
  };

  const handleDrop = (selected: string | string[], targetIndex: number) => {
    setDataSetElements((prev) => {
      const updated = [...prev];
      const element = updated[targetIndex] ?? [];
      const items = Array.isArray(selected) ? selected : [selected];

      items.forEach((item) => {
        const fullPath = `${curDir}/${item}`;
        if (!element.includes(fullPath)) {
          element.push(fullPath);
        }
      });

      updated[targetIndex] = element;
      return updated;
    });
  };

  const handleDelete = (index: number, i: number) => {
    setDataSetElements((prev) => {
      const updated = [...prev];
      updated[index] = updated[index].filter((_, itemIndex) => itemIndex !== i);
      return updated;
    });
  };

  const handleSubmitDataSet = async () => {
    if (fileName == '') {
      return;
    }
    const response = await api.post('/vmeta/generate_vmeta', {
      file_name: fileName,
      id_list: dataSetId,
      file_list: dataSetElements,
      target_dir: curDir,
    });

    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: '파일 생성 완료',
        text: '데이터가 성공적으로 생성되었습니다!',
        confirmButtonText: '확인',
      });
      setDataSetElements([[]]); // 데이터셋 초기화
      setFileName(''); // 파일 이름 초기화
      setDataSetId(['0']); // ID 초기화
      const selectedVmeta = fileName + '.vmeta';
      setSelected([selectedVmeta]);
    }

    getDirectory(curDir, api);
  };

  // ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅

  return (
    <>
      {/* 공간 차지 하며, 레이아웃 유지하기 위함 */}
      <div
        className='invisible absolute bottom-0 right-0 
        h-[calc(100vh-4.5rem)] w-[21.875rem]'
      ></div>
      {/* 사이드 패널*/}
      <div
        className='absolute right-0 top-0 z-[991] mt-[4.5rem] flex
        h-16 flex-row items-center transition-[width]
        duration-[0.5s] ease-in-out'
        style={{ width: translate }}
      >
        <div
          className='ml-[2.17rem] flex size-full items-center justify-between 
      p-4'
        >
          <CommingSoonAlert>
            <button
              onClick={() => setActiveTab('TAGS')}
              className={`w-[150px] rounded px-4 py-2 text-white focus:outline-none  
          ${activeTab === 'TAGS' ? 'border-2 border-white bg-purple200' : 'bg-[#c3c3c3] hover:bg-[#9b9b9b] dark:bg-[#2e3033] dark:hover:bg-[#34373a]'}`}
            >
              Tags
            </button>
          </CommingSoonAlert>
          <button
            onClick={() => setActiveTab('VMETAS')}
            className={`w-[150px] rounded px-4 py-2 text-white focus:outline-none 
          ${activeTab === 'VMETAS' ? 'border-2 border-white bg-purple200' : 'bg-[#c3c3c3] hover:bg-[#9b9b9b] dark:bg-[#2e3033] dark:hover:bg-[#34373a]'}`}
          >
            Dataset
          </button>
        </div>
      </div>
      <div
        className='absolute bottom-0 right-0 z-[990] flex h-[calc(100vh-4.5rem)]
          flex-row items-center transition-[width] duration-[0.5s] ease-in-out'
        style={{ width: translate }}
      >
        {/* 루트 디렉토리일 때는 expand 됨 && ">" 버튼이 보임 */}
        {curDir === '' && activeTab === 'TAGS' && (
          <span
            className='flex h-[var(--70px)] w-[2.375rem] items-center 
              justify-center rounded-l-[40px] rounded-r-none bg-gray100 dark:bg-black100'
            onClick={curDir === '' ? handlePanelMode : undefined}
            style={{ cursor: curDir === '' ? 'pointer' : 'default' }}
          >
            <img
              src={
                theme === 'dark'
                  ? '/images/canvas-panel-arrow.svg'
                  : '/images/canvas-panel-arrow-white.svg'
              }
              alt='If click this arrow, the panel will be expanded or shrinked'
              style={{
                transform: panelMode === 'expand' ? 'rotate(180deg)' : '',
                filter: 'invert(0.2) sepia(1) saturate(5) hue-rotate(240deg)',
              }}
            />
          </span>
        )}
        {/* 루트 디렉토리 아닐때는 expand 안됨 && ">" 버튼이 안보임 */}
        {(curDir !== '' || activeTab === 'VMETAS') && (
          <span
            className='flex h-[var(--70px)] w-[2.375rem] items-center
              justify-center rounded-l-[40px] rounded-r-none bg-[#080821]'
            style={{ opacity: 0 }}
          >
            <img
              src={
                theme === 'dark'
                  ? '/images/canvas-panel-arrow.svg'
                  : '/images/canvas-panel-arrow-white.svg'
              }
              alt='If click this arrow, the panel will be expanded or shrinked'
              style={{
                transform: panelMode === 'expand' ? 'rotate(180deg)' : '',
              }}
            />
          </span>
        )}
        <div
          className='z-[100] box-border flex size-full overflow-hidden
            rounded-br-none bg-gray100 dark:bg-black100'
        >
          {/* 좌측 컨테이너 */}
          <div className='mt-12'>
            {curDir === '' && activeTab === 'TAGS' && (
              <div className='h-full w-[21.875rem] p-5'>
                <div className='flex h-full flex-col justify-center text-white100'>
                  <h2 className='text-[20px] font-semibold text-black300 dark:text-white100'>
                    Tags
                  </h2>
                  <div
                    className='size-full rounded-lg border
                    border-gray200 text-[14px] hover:cursor-pointer'
                  >
                    {tagList.length === 0 && (
                      <div className='mt-5 pl-5 text-sm' onClick={() => handlePanelMode()}>
                        Please register tags
                      </div>
                    )}
                    {tagList.map((tagList: Tag, index: number) => (
                      <div
                        key={index}
                        className='mx-3 mt-4
                          flex justify-between rounded-lg p-1
                          hover:cursor-pointer hover:bg-gray200
                        dark:hover:bg-gray300'
                        draggable='true'
                        onDragStart={() => {
                          setDragTag({
                            tagName: tagList.title,
                            valid: true,
                            color: tagList.color,
                            key: tagList.key,
                          });
                        }}
                        onDragEnd={() => {
                          setDragTag({
                            tagName: '',
                            valid: false,
                            color: tagList.color,
                            key: tagList.key,
                          });
                          // handleTagToFileOrDir();
                        }}
                        onClick={() => handleEditTag(tagList)}
                      >
                        <ul className='ml-5 flex items-center p-1'>
                          <li
                            className='size-4 rounded-full'
                            style={{ background: `${tagList.color}` }}
                          ></li>
                          <li className='pl-3 text-black dark:text-white'>{tagList.title}</li>
                        </ul>
                        <div className='flex w-1/4 items-center justify-between pr-5'>
                          <img
                            src={
                              theme === 'dark'
                                ? '/images/settings.svg'
                                : '/images/settings-white.svg'
                            }
                            alt=''
                            className={`size-5 ${
                              createTitle === tagList.title ? 'animate-spin' : ''
                            }`}
                          />
                          <img
                            src={
                              theme === 'dark' ? '/images/delete2.svg' : '/images/delete2-white.svg'
                            }
                            alt=''
                            className='size-5 cursor-pointer'
                            // onClick={() => handleDeleteTag(tagList.key)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {curDir !== '' && activeTab === 'TAGS' && (
              <div className='h-full w-[21.875rem] p-5'>
                <div className='flex h-full flex-col justify-center pb-8'>
                  <h2 className='text-[20px] font-semibold text-black dark:text-white100'>Tasks</h2>
                  <div
                    className='size-full cursor-pointer overflow-auto
                    rounded-lg text-xs dark:text-white100'
                  >
                    {taskList.length === 0 && (
                      <div className='mt-5 pl-5 text-[15px] text-black dark:text-white100'>
                        There is no tasks
                      </div>
                    )}
                    {Object.keys(taskList).map((key) => (
                      <ul key={key}>
                        {taskList[key].map((task: Task, index: number) => (
                          <div
                            className='mt-5 border-b border-gray200
                              pb-5 pl-5 text-[15px] dark:border-gray300'
                            key={index}
                          >
                            {/* 파이프 구분 */}
                            <div className='flex items-center pb-4'>
                              <div
                                className='size-[15px] rounded-full'
                                style={{ background: task.color }}
                              ></div>
                              <div
                                className='ml-2 text-[15px] font-bold'
                                style={{ color: task.color }}
                              >
                                {task.pipe}
                              </div>
                            </div>
                            <div className='flex items-center justify-between'>
                              {/* 파이프 속성에 영향을 받는 파이프 */}
                              <div>
                                {task.rule &&
                                  Object.values(task.rule).map((r, idx, arr) => (
                                    <span key={idx}>
                                      {task.file_name.split('/').pop()}
                                      {r}
                                      {idx < arr.length - 1 && ' + '}
                                    </span>
                                  ))}
                              </div>
                              {/* 상태, viewer, download */}
                              <div className='mt-2.5 flex justify-between'>
                                <div className='flex w-1/5 justify-between gap-2'>
                                  {task.status === 'complete' && (
                                    <img src={'/images/check.svg'} alt='' />
                                  )}
                                  {task.status === 'deleted' && (
                                    <img src={'/images/-.svg'} alt='' />
                                  )}
                                  <img
                                    src={
                                      theme === 'dark'
                                        ? '/images/play.svg'
                                        : '/images/play-white.svg'
                                    }
                                    alt=''
                                  />
                                  <img
                                    src={
                                      theme === 'dark'
                                        ? '/images/download-cloud.svg'
                                        : '/images/download-cloud-white.svg'
                                    }
                                    alt=''
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
            {activeTab === 'VMETAS' && (
              <div
                className='h-full w-[21.875rem] overflow-auto p-5'
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDragOverSet(undefined);
                }}
              >
                <div className='flex h-full flex-col justify-center '>
                  <h2 className='text-[18px] font-semibold text-black dark:text-white100'>
                    Dataset (vmeta)
                  </h2>
                  <div className='size-full cursor-pointer overflow-auto rounded-lg pb-6 text-xs dark:text-white100'>
                    <div className='mt-4 space-y-4'>
                      {dataSetElements.map((element, index) => (
                        <div
                          key={index}
                          className={`h-fit min-h-[200px] w-full rounded-[7px] p-4 text-black bg-[#c3c3c3] dark:text-white dark:bg-[#3E4143] shadow-lg ${
                            dragOverSet == `${index}` ? 'shadow-purple-500/50' : ''
                          }`}
                          onDrop={() => {
                            handleDrop(selected, index);
                            setDragOverSet(undefined);
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDragOverSet(index);
                          }}
                        >
                          <div className='flex items-center justify-between'>
                            <p className='text-[14px]'>ID</p>
                            <input
                              type='text'
                              placeholder={` ${index}`}
                              className='ml-2 w-[70px] rounded-none border-b border-black bg-[#c3c3c3] p-2 dark:rounded-none dark:border-gray-300 dark:bg-[#3E4143]'
                              value={dataSetId[index]}
                              onChange={(e) => handleDataSetIdChange(index, e.target.value)}
                            />
                            <button
                              onClick={() => handleDeleteSet(index)}
                              className='ml-2 rounded bg-red-500 px-3 py-1 text-white hover:bg-red-600'
                            >
                              Delete
                            </button>
                          </div>

                          {/* 요소 리스트 출력 */}
                          <div className='mt-2 space-y-2'>
                            {dataSetElements[index]?.length > 0 ? (
                              dataSetElements[index].map((item, i) => (
                                <div className='flex justify-between'>
                                  <div
                                    key={i}
                                    className='flex w-[78%] justify-between rounded bg-gray-200 p-2 text-sm text-gray-800 dark:bg-[#2B2C2E] dark:text-gray-200'
                                  >
                                    <div>{truncateText(item, 20)}</div>
                                    <div
                                      onClick={() => handleDelete(index, i)}
                                      className='cursor-pointer'
                                    >
                                      X
                                    </div>
                                  </div>
                                  <div className='w-1/5 rounded bg-gray-200 p-2 text-center text-sm text-gray-800 dark:bg-[#2B2C2E] dark:text-gray-200'>
                                    {item.split('.').pop()}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p className='mt-14 text-center text-sm text-gray-500 dark:text-gray-500'>
                                Drag Files!
                              </p>
                            )}
                          </div>
                        </div>
                      ))}

                      <div ref={containerRef}></div>
                    </div>
                  </div>
                  <div className='rounded-custom-xl bg-[#C3C3C3] dark:bg-[#3e4143] p-5 shadow-lg'>
                    <div
                      className='rounded bg-[#adadad] py-2 text-center text-[15px] text-gray-100 cursor-pointer
                    dark:bg-[#2b2c2e] dark:text-gray-100
                    hover:bg-[#c3c3c3] dark:hover:bg-[#4f5052]'
                      onClick={addNewSet}
                    >
                      + create new set
                    </div>
                    <div className='mt-5 flex items-center text-black dark:text-white'>
                      <input
                        type='text'
                        className='ml-2 w-[200px] rounded-custom-xl border border-black bg-gray100 p-2 py-1 dark:border-gray-300 dark:bg-black100'
                        placeholder='create filename'
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                      />
                      <p className='pt-3'>.vmeta</p>
                    </div>
                    {/* 활성화 create */}
                    {fileName !== '' && (
                      <div
                        className='mt-5 flex h-10 w-full shrink-0 cursor-pointer items-center justify-center rounded-custom-xl bg-purple200
                      text-[15px] font-bold text-white hover:brightness-[80%]'
                        onClick={handleSubmitDataSet}
                      >
                        Create
                      </div>
                    )}
                    {/* 비활성화 create */}
                    {fileName === '' && (
                      <div
                        className='mt-5 flex h-10 w-full shrink-0 items-center justify-center rounded-custom-xl bg-gray-400
                      text-[15px] font-bold text-white'
                      >
                        Create
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
          </div>
          {/* 우측 컨테이너 */}
          <div className='mt-12 w-[21.875rem]'>
            <div className='h-full w-[21.875rem] p-5'>
              <div className='flex h-full flex-col justify-center text-white'>
                <h2 className='text-[20px] font-semibold text-black dark:text-white100'>
                  {sidePanelText}
                </h2>
                <div
                  className='flex h-full flex-col justify-between overflow-auto rounded-lg
                  border border-gray200 p-5 dark:border-gray300'
                >
                  {/* 이름*/}
                  <div>
                    <h4 className='text-[17px] font-semibold text-black dark:text-white'>Name</h4>
                    <input
                      className='flex h-[30px] w-full items-center rounded-lg border
                        border-gray200 bg-gray100 pl-2.5 dark:border-gray300'
                      onChange={handleTitleText(setCreateTitle)}
                      value={createTitle}
                    />
                    <div className='flex items-end'>
                      <h4 className='mt-10 text-[17px] font-semibold text-black dark:text-white'>
                        Tag Color
                      </h4>
                      <input
                        type='color'
                        className='ml-[10px] mt-[30px]'
                        name='head'
                        value={color}
                        onChange={handleSelectColorPicker(setColor)}
                      />
                    </div>
                    <div className='mt-2.5 flex items-center justify-center'>
                      <CirclePicker
                        onChangeComplete={handleSelectColorHex(setColor)}
                        width='100%'
                        circleSize={30}
                      />
                    </div>
                    <div className='mt-7'>
                      <h4 className='mt-10 text-[17px] font-semibold text-black dark:text-white'>
                        Connect Module
                      </h4>
                      <div className='flex w-full justify-between'>
                        <input
                          placeholder='Search for Pipes'
                          className='h-[30px] w-full rounded-lg border border-gray200
                            bg-gray100 pl-2.5 dark:border-gray300'
                          onChange={handleSearchText(setSearchPipe)}
                        />
                      </div>
                      <div
                        className='mx-0 my-2.5
                          h-[250px] w-full overflow-auto rounded-lg border
                        border-gray200 p-2.5 hover:cursor-pointer dark:border-gray300'
                      >
                        {pipeList.map((pipeList: Pipe, index: number) => {
                          const pipeIdentifier = pipeList;
                          return (
                            <div
                              className={`mt-1 flex rounded-md p-1 pl-2.5 hover:brightness-[90%] ${
                                selectPipeList.includes(pipeIdentifier)
                                  ? 'bg-purple200 text-white100'
                                  : 'bg-purple100 text-black100'
                              }`}
                              key={index}
                              // onClick={() => handleSelectPipe(pipeList)}
                              title={`${pipeList.pipe_name} | ${pipeList.pipe_description}`}
                            >
                              <div>
                                {truncateText(pipeList.pipe_name, 10)} |{' '}
                                {truncateText(pipeList.pipe_description, 10)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <h4 className='mt-10 text-[17px] font-semibold text-black dark:text-white'>
                        Selected Pipes
                      </h4>
                      <ul>
                        {selectPipeList.map((selectPipeList: Pipe, index: number) => (
                          <li
                            className='ml-2.5 mt-2.5 flex h-[27.3px] w-[90%] justify-between
                                rounded-md bg-purple200 px-3 py-0.5 text-sm'
                            key={index}
                          >
                            <p className='flex items-center justify-center'>
                              {truncateText(selectPipeList.pipe_name, 10)} |{' '}
                              {truncateText(selectPipeList.pipe_description, 10)}
                            </p>
                            <img
                              className='cursor-pointer'
                              src={theme === 'dark' ? '/images/x.svg' : '/images/x-white.svg'}
                              alt=''
                              // onClick={() => handleDeletePipe(selectPipeList)}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {postBtnText === 'Add' && (
                    <button
                      className='mt-5 h-10 w-full shrink-0 rounded-custom-xl
                        bg-purple200 font-bold text-white hover:brightness-[80%]'
                      // onClick={handleCreateTag}
                    >
                      {postBtnText}
                    </button>
                  )}
                  {postBtnText === 'Edit' && (
                    <button
                      className='mt-5 h-10 w-full shrink-0 bg-purple200
                        font-bold text-white hover:brightness-[80%]'
                      // onClick={handleModifyTag}
                    >
                      {postBtnText}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
