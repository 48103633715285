// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal-container {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  background: red;
  z-index: 105;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-modal-children {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  z-index: 110;
}

.custom-inputwithlabel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  position: relative;
}

.custom-textarea-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  position: relative;
}

.custom-textarea-input {
  resize: none;
  width: 100%;
  outline: none;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/CustomComp.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,aAAA;EACA,OAAA;EACA,MAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,UAAA;EACA,kBAAA;AAAJ;;AAOA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;AAJJ","sourcesContent":["\n.custom-modal-container {\n    position: fixed;\n    display: flex;\n    left: 0;\n    top: 0;\n    background: red;\n    z-index: 105;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.custom-modal-children {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    min-height: 300px;\n    min-width: 300px;\n    display: flex;\n    flex-direction: column;\n    z-index: 110;\n}\n\n.custom-inputwithlabel-container {\n    width: 100%;\n    display: flex; \n    flex-direction: column;\n    justify-content: center;\n    padding: 0;\n    position: relative;\n}\n\n.custom-inputwithlabel-label {\n\n}\n\n.custom-textarea-container {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    padding: 0;\n    position: relative;\n}\n\n.custom-textarea-input {\n    resize: none;\n    width: 100%;\n    outline: none;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
