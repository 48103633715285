import React from 'react';
import { ClassicPreset, NodeId } from 'rete';

import { IDataflow, IDataSelector } from '@/components/rete/components/nodes/flow';
import { CustomSocket } from '@/components/rete/components/sockets';
// import { updateControl } from '@/components/rete/reteUtils';

import CustomControl from '@/components/rete/components/style/CustomControl';
import { NodeSize, SourceColor } from '@/components/rete/components/style/CustomNode';
import './data-selector.scss';
import '@/components/rete/components/nodes/viewer/panel/side-panel.styles.scss';

export class DataSelectorNode extends ClassicPreset.Node<
  Record<string, never>,
  { out: ClassicPreset.Socket },
  { ctrl: DataSelectorControl }
> {
  color = SourceColor;
  width = NodeSize.width;
  height = NodeSize.height;

  constructor(process: () => void, nodeId?: NodeId) {
    super('DataSelector');
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new DataSelectorControl(process, nodeId ? nodeId : this.id));
  }

  data(): { out: IDataflow } {
    const path = this.controls.ctrl.props.option.path;

    if (path === '' || path === undefined) {
      this.controls.ctrl.setStatus('init');
    } else {
      this.controls.ctrl.setStatus('completed');
    }

    return {
      out: {
        img_paths: {
          [this.id]: path,
        },
        effector: {
          input: [this.id],
          node: {
            data_selector: {
              thumbnail: this.controls.ctrl.props.option.thumbnail,
              type: this.controls.ctrl.props.option.type,
              img_size: this.controls.ctrl.props.option.imgSize,
              width: this.controls.ctrl.props.option.imgSize.width,
              height: this.controls.ctrl.props.option.imgSize.height,
            },
          },
        },
      },
    };
  }
}

export class DataSelectorControl extends ClassicPreset.Control {
  props: {
    option: IDataSelector;
  };
  contextOpen: boolean;
  // status => 백그라운드에서 처리 상태
  // init: 처음 생성 / in-progress: 처리 중 / completed: 처리 완료 / error: 에러 발생
  status: string;

  constructor(
    public onChange: () => void,
    public nodeId: NodeId,
  ) {
    super();
    this.props = {
      option: {
        path: '',
        type: '',
        thumbnail: '',
        imgSize: { width: 0, height: 0 },
      },
    };
    this.contextOpen = false;
    this.status = 'init';
  }

  setValue(value: IDataSelector) {
    this.props.option = value;
    this.onChange();
  }

  getValue() {
    return this.props.option;
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    // updateControl(this.id);
  };

  setStatus = (status: string) => {
    this.status = status;
  };
}

export function DataSelector({ data }: { data: DataSelectorControl }) {
  return (
    <CustomControl
      label='Data Selector'
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
      nodeId={data.nodeId}
      markerSource={'node-source'}
      iconSource='datamanage'
      status={data.status}
      setStatus={data.setStatus}
    />
  );
}
